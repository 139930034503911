import {
    getPunnettSquareEntryAllele,
    getPunnettSquareEntryPhenotype,
    getPunnettSquareTraits
} from "../../../store/punnettSquares/selectors";
import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {useDrop} from "react-dnd";
import {setPunnettSquareEntryAllele, setPunnettSquareEntryPhenotype} from "../../../store/punnettSquares/actions";
import {DOMINANT_TRAIT, RECESSIVE_TRAIT, UNKNOWN_SELECTION} from "../../../constants/Zygosities";
import classNames from "class-names";
import {DRAGGABLE_ALLELE} from "./DraggableHeader";
import {getTraitLetter} from "./utility";

const stateMap = props => state => ({
    phenotype: getPunnettSquareEntryPhenotype(state, props.id, props.row, props.col),
    alleleA: getPunnettSquareEntryAllele(state, props.id, props.row, props.col, 0),
    alleleB: getPunnettSquareEntryAllele(state, props.id, props.row, props.col, 1),
    trait: getPunnettSquareTraits(state, props.id)[0],
});

export default props => {
    const { phenotype, alleleA, alleleB, trait } = useSelector(stateMap(props));
    const dispatch = useDispatch();
    const [selectingPhenotype, setSelectingPhenotype] = useState(false);

    const [{cardOverLeft}, leftDropArea] = useDrop({
        accept: [DRAGGABLE_ALLELE],
        drop: (item, monitor) => {
            dispatch(setPunnettSquareEntryAllele(props.id, props.row, props.col, 0, item.alleleValue));
        },
        collect: monitor => {
            return {
                cardOverLeft: monitor.isOver({shallow: true}),
            };
        },
    });

    const [{cardOverRight}, rightDropArea] = useDrop({
        accept: [DRAGGABLE_ALLELE],
        drop: (item, monitor) => {
            dispatch(setPunnettSquareEntryAllele(props.id, props.row, props.col, 1, item.alleleValue));
        },
        collect: monitor => {
            return {
                cardOverRight: monitor.isOver({shallow: true}),
            };
        },
    });

    let alleleATraitClass = "";
    const hasAlleleA = alleleA !== null;
    if(hasAlleleA) {
        const alleleAZygosity = alleleA ? "recessive" : "dominant";
        alleleATraitClass = `punnett-square-allele--${trait}__${alleleAZygosity}`;
    }

    let alleleBTraitClass = "";
    const hasAlleleB = alleleB !== null;
    if(hasAlleleB) {
        const alleleBZygosity = alleleB ? "recessive" : "dominant";
        alleleBTraitClass = `punnett-square-allele--${trait}__${alleleBZygosity}`;
    }

    const startPhenotypeSelection = () => !selectingPhenotype ? setSelectingPhenotype(true) : null;
    const setPhenotypeSelection = phenotype => e => {
        e.stopPropagation();
        dispatch(setPunnettSquareEntryPhenotype(props.id, props.row, props.col, phenotype));
        setSelectingPhenotype(false);
    };

    const hasPhenotype = phenotype && phenotype !== UNKNOWN_SELECTION;
    const shouldSelectPhenotype = !hasPhenotype && hasAlleleA && hasAlleleB;
    const isSelectingPhenotype = selectingPhenotype || shouldSelectPhenotype;

    return (
        <div className="punnett-square--entry">
            <div className={classNames({
                "punnett-square--entry-phenotype-text": true,
                "punnett-square--entry-phenotype-text__disabled": !isSelectingPhenotype
            })}>Choose phenotype:</div>
            <button onClick={startPhenotypeSelection}
                    disabled={!hasAlleleA || !hasAlleleB}
                    className={classNames({
                        "punnett-square--entry-phenotype": true,
                        "punnett-square--entry-phenotype__no-selection": !hasPhenotype,
                        "punnett-square--entry-phenotype__selecting": isSelectingPhenotype,
                    })}>
                <div className={classNames({
                    'punnett-square--entry-phenotype-selection': true,
                    [`entry-phenotype--${trait}-dominant`]: true,
                    [`punnett-square--entry-phenotype-selection__selected`]: phenotype === DOMINANT_TRAIT,
                })}
                     onClick={setPhenotypeSelection(DOMINANT_TRAIT)} />
                <div className={classNames({
                    'punnett-square--entry-phenotype-selection': true,
                    [`entry-phenotype--${trait}-recessive`]: true,
                    [`punnett-square--entry-phenotype-selection__selected`]: phenotype === RECESSIVE_TRAIT,
                })}
                     onClick={setPhenotypeSelection(RECESSIVE_TRAIT)} />
            </button>
            <div className={classNames({
                "punnett-square--entry-genotype-container": true,
                "punnett-square--entry-genotype-container__complete": hasAlleleA && hasAlleleB,
            })}>
                <div ref={leftDropArea}
                     className={classNames({
                         "punnett-square--entry-genotype": true,
                         "punnett-square--entry-genotype__no-selection": !hasAlleleA,
                         "punnett-square--entry-genotype__hovered": cardOverLeft,
                         "punnett-square--entry-genotype__selection": hasAlleleA,
                         "punnett-square--entry-genotype__top": selectingPhenotype
                     })}>
                    {hasAlleleA && <div className={`punnett-square-allele--trait-symbol ${alleleATraitClass}`} />}
                    {hasAlleleA && <div className="punnett-square-allele--trait-letter">{getTraitLetter(trait, alleleA)}</div> }
                </div>
                <div ref={rightDropArea}
                     className={classNames({
                         "punnett-square--entry-genotype": true,
                         "punnett-square--entry-genotype__no-selection": !hasAlleleB,
                         "punnett-square--entry-genotype__hovered": cardOverRight,
                         "punnett-square--entry-genotype__selection": hasAlleleB,
                     })}>
                    {hasAlleleB && <div className={`punnett-square-allele--trait-symbol ${alleleBTraitClass}`} />}
                    {hasAlleleB && <div className="punnett-square-allele--trait-letter">{getTraitLetter(trait, alleleB)}</div> }
                </div>
            </div>
        </div>
    );
};
