import {call, takeEvery} from 'redux-saga/effects';
import {sendGraphQLSaga} from "../../DataService";
import {SET_SCROLLER_SECTION_BACKGROUND_COLOR} from "../../../store/scrollerSections/actions";

const updateQuery = `mutation UpdateScrollingSectionContent($input: UpdateScrollingSectionContentInput!) {
    updateScrollingSectionContent(input: $input) {
        section {
            id
        }
    }
}`;

function* execute(action) {
    const variables = {
        input: {
            curriculum: action.curriculumId,
            section: action.sectionId,
            backgroundColor: action.color,
        },
    };

    yield call(sendGraphQLSaga, updateQuery, variables);
}

export default [takeEvery(SET_SCROLLER_SECTION_BACKGROUND_COLOR, execute)];
