import {call, put, select, takeEvery} from 'redux-saga/effects';
import {
    CREATE_PARTNER_REQUEST, END_PARTNERSHIP,
    removePartnerFromLearner,
    RESPOND_TO_PARTNER_REQUEST
} from "../../../store/learners/actions";
import {sendGraphQLSaga} from "../../DataService";
import {getActiveLearner} from "../../../store/navigation/selectors";

const requestMutation = `mutation RequestPartnership($input: RequestPartnershipInput!) {
    requestPartnership(input: $input) {
        errors
    }
}`;
function* executeRequestPartnership(action) {
    const {curriculumId, partnerId} = action;

    const variables = {
        input: {
            curriculum : curriculumId,
            requestedPartner: partnerId,
        },
    };
    const response = yield call(sendGraphQLSaga, requestMutation, variables);
    //todo check for errors
}

const respondToRequestMutation = `mutation AnswerPartnershipRequest($input: AnswerPartnershipRequestInput!) {
    answerPartnershipRequest(input: $input) {
        errors
    }
}`;
function* executeRespondToRequest(action) {
    const {curriculumId, requesterId, approve} = action;

    const variables = {
        input: {
            curriculum: curriculumId,
            requester: requesterId,
            approve,
        },
    };
    const response = yield call(sendGraphQLSaga, respondToRequestMutation, variables);
    // TODO check for errors
}

const endPartnerMutation = `mutation EndPartnership($input: EndPartnershipInput!) {
    endPartnership(input: $input) {
        errors
    }
}`;
function* executeEndPartnerMutation(action) {
    const {curriculumId, otherLearner} = action;
    const variables = {
        input: {
            curriculum: curriculumId,
            otherLearner: otherLearner,
        },
    };

    const response = yield call(sendGraphQLSaga, endPartnerMutation, variables);
    if(!response.endPartnership.errors) {
        const learnerId = yield select(state => getActiveLearner(state));
        yield put(removePartnerFromLearner(curriculumId, learnerId, otherLearner));
    }
}

export default [
    takeEvery(CREATE_PARTNER_REQUEST, executeRequestPartnership),
    takeEvery(RESPOND_TO_PARTNER_REQUEST, executeRespondToRequest),
    takeEvery(END_PARTNERSHIP, executeEndPartnerMutation),
];
