import * as styles from './organelle-debris-styles';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    canLysosomeEat,
    getDogmaLysosomeIds,
    getLysosomeTargetOrganelleId,
    getLysosomeTargetX,
    getObjectForm,
    getObjectsInOrganelle,
    getObjectType,
    getOrganelleDestroyedObjects
} from "../../../../store/interactions/dogmaDash/selectors";
import {ProteinForm_Denatured} from "../../../../constants/DogmaDash";
import Protein from "./Protein";
import {getActiveLearner} from "../../../../store/navigation/selectors";
import {
    changeCurrentDogmaDashScore,
    setLysosomeTarget,
    setLysosomeTargetXY
} from "../../../../store/interactions/dogmaDash/actions";
import {getPendingScoreEventFor} from "../../../../store/interactions/scoring/selectors";
import {pendingScoringEventCompleted} from "../../../../store/interactions/scoring/actions";
import {scoreUp} from "./microtubule-styles";
import {Transition, TransitionGroup} from "react-transition-group";
import {DOGMA_DASH_ORGANELLE_DEBRIS} from "../../../../store/tutorial/configuration/dogma-strand-1";
import PopUpTutorial from "../../../Tutorial/PopUpTutorial";
import {shallowOrderedArrayCompare} from "../../../../utility";

const DebrisScore = ({scoreAmount, dogmaId, ...rest}) => {
    const dispatch = useDispatch();
    const transitionRef = useRef();

    useEffect(() => {
        return () => dispatch(changeCurrentDogmaDashScore(dogmaId, scoreAmount));
    }, []);

    return (
        <Transition nodeRef={transitionRef} timeout={2000} {...rest}>
            <div ref={transitionRef} css={scoreUp}>+{scoreAmount}</div>
        </Transition>
    );
};

const OrganelleDebris = ({organelleId, maxShown, dogmaId, curriculumId}) => {
    const learnerId = useSelector(getActiveLearner);
    const organelleDebris = useSelector(state => getObjectsInOrganelle(state, organelleId).filter(o => getObjectForm(state, o) === ProteinForm_Denatured), shallowOrderedArrayCompare);
    const destroyedObjects = useSelector(state => getOrganelleDestroyedObjects(state, organelleId).filter(o => getObjectForm(state, o) === ProteinForm_Denatured));
    const scoringObjects = useSelector(state => destroyedObjects.map(o => getPendingScoreEventFor(state, o)).filter(e => !!e), shallowOrderedArrayCompare);
    const debrisTypes = useSelector(state => organelleDebris.map(o => getObjectType(state, o)), shallowOrderedArrayCompare);
    const lysosomeId = useSelector(state => getDogmaLysosomeIds(state, dogmaId)[0]);
    const lysosomeCanEat = useSelector(state => canLysosomeEat(state, lysosomeId));
    const lysosomeTarget = useSelector(state => getLysosomeTargetOrganelleId(state, lysosomeId));
    const lysosomeX = useSelector(state => getLysosomeTargetX(state, lysosomeId));
    const tutorialConfig = {location: DOGMA_DASH_ORGANELLE_DEBRIS, organelleId };
    const [scoreTally, setScoreTally] = useState(0);
    const [tallying, setTallying] = useState(false);
    const [toScore, setToScore] = useState(0);
    const trashRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if(lysosomeX || lysosomeX === 0 || lysosomeTarget !== organelleId || !trashRef.current) { return; }
        const {x,y} = trashRef.current.getBoundingClientRect();
        dispatch(setLysosomeTargetXY(lysosomeId, x, y));
    }, [lysosomeTarget]);

    useEffect(() => {
        if(scoringObjects.length === 0) { return; }
        setTallying(true);
        const newScore = scoringObjects.reduce((agg, s) => agg + s.deltaScore, 0);
        setScoreTally(scoreTally + newScore);
        for(let i = 0; i < scoringObjects.length; ++i) {
            dispatch(pendingScoringEventCompleted(scoringObjects[i].scorer));
        }
        setTimeout(() => setTallying(false), 500);
    }, [scoringObjects]);

    useEffect(() => {
        if(tallying) { return; }
        setToScore(scoreTally);
        setScoreTally(0);
    }, [tallying]);

    useEffect(() => {
        setToScore(0);
    }, [toScore]);

    const denaturedClick = e => (lysosomeCanEat && !lysosomeTarget) ? dispatch(setLysosomeTarget(curriculumId, lysosomeId, learnerId, e.clientX, e.clientY, organelleId)) : null;

    return (
        <div css={styles.debrisContainer}>
            {Array.from({length: Math.min(maxShown, organelleDebris.length)}, (_, i) =>
            <div ref={organelleId === lysosomeTarget ? trashRef : null} key={organelleDebris[i]} css={styles.debris} onClick={denaturedClick}>
                <Protein form={ProteinForm_Denatured} type={debrisTypes[i]} interactable={false} id={organelleDebris[i]} />
            </div>)}
            <div css={styles.tutorial}><PopUpTutorial positionData={tutorialConfig} /></div>
            <TransitionGroup component={null}>{toScore > 0 && <DebrisScore scoreAmount={toScore} dogmaId={dogmaId} />}</TransitionGroup>
        </div>
    )
};

export default OrganelleDebris;
