import React from 'react';
import {useSelector} from "react-redux";
import {getInteractionWorkBoardId} from "../../../store/interactions/selectors";
import WorkBoard from "../../../WorkBoard";
import {getScrollerWorkBoardDefinition} from "../../../store/scrollerWorkBoards/selectors";
import {css} from "@emotion/core";
import {PEA_PLANT_CARD_HEIGHT} from "../../../style-variables";

const inlineWorkBoardStyle = css({
    display: "flex",
    height: (PEA_PLANT_CARD_HEIGHT * 2) + 32, // 8 px margin + 8px padding
});

const InlineWorkBoard = ({id}) => {
    const definitionId = useSelector(state => getScrollerWorkBoardDefinition(id, state));
    const workBoardId = useSelector(state => definitionId ? getInteractionWorkBoardId(state, definitionId) : null);

    if(!workBoardId) { return <div/>; }

    return (
        <div css={inlineWorkBoardStyle}>
            <WorkBoard boardId={workBoardId} workBoardDefinition={definitionId} />
        </div>
    );
}

export default InlineWorkBoard;
