import * as styles from "./item-context-menu-style";
import React from 'react';
import {
    curriculumItemToBottom,
    curriculumItemToTop,
    reorderCurriculumItem
} from "../../../../../store/curriculumTask/actions";
import {useParams} from "@reach/router";
import {openModalFor} from "../../../../../store/navigation/actions";
import {SCROLLER_ITEM_CONTEXT} from "../../../../../constants/modalTypes";
import {css} from "@emotion/core";
import {useDispatch, useSelector} from "react-redux";
import {modalAdditionalData} from "../../../../../store/navigation/selectors";
import {getScrollingItemDraggable} from "../../../../../store/scrollingTaskItemPositions/selectors";
import {setScrollingItemDraggable} from "../../../../../store/scrollingTaskItemPositions/actions";
import combineStyles from "../../../../../Utility/combineStyles";
import Check from '../../../../../inline-assets/check.svg';

const getAdditionalData = id => state => modalAdditionalData(state, SCROLLER_ITEM_CONTEXT, id);
const capture = e => {
    e.preventDefault();
    e.stopPropagation();
}

export default props => {
    const {item} = props;
    const {curriculumId, taskId} = useParams();
    const {x: left, y: top} = useSelector(getAdditionalData(item));
    const draggable = useSelector(getScrollingItemDraggable.bind(null, item));
    const dispatch = useDispatch();

    const captureAndClose = e => {
        capture(e);
        dispatch(openModalFor(null, item));
    }

    const moveFront = e => {
        captureAndClose(e);
        dispatch(curriculumItemToTop(curriculumId, taskId, item));
    };
    const moveDown = e => {
        capture(e)
        dispatch(reorderCurriculumItem(curriculumId, taskId, item, -1));
    };
    const moveUp = e => {
        capture(e);
        dispatch(reorderCurriculumItem(curriculumId, taskId, item, 1));
    };
    const moveBottom = e => {
        captureAndClose(e);
        dispatch(curriculumItemToBottom(curriculumId, taskId, item));
    }

    const toggleDraggable = e => {
        capture(e);
        dispatch(setScrollingItemDraggable(curriculumId, item, !draggable));
    }

    const menuStyle = css(styles.menu, css({
        left,
        top,
    }));

    return (
        <div css={menuStyle}>
            <button css={styles.button} onClick={moveFront}>Bring to front</button>
            <button css={styles.button} onClick={moveUp}>Bring forward</button>
            <button css={styles.button} onClick={moveDown}>Push backward</button>
            <button css={styles.button} onClick={moveBottom}>Send to back</button>
            <div css={styles.separator} />
            <button onClick={toggleDraggable} css={combineStyles(styles, {
                button: true,
                buttonActive: draggable,
            })}>
                {draggable && <Check css={styles.buttonCheck} />}
                Allow Dragging
            </button>
        </div>
    );
};
