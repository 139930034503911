import draggableDropped from "./draggableDropped";
import workBoard from "./workBoard";
import crossCard from "./crossCard";
import stickyNote from "./stickyNote";
import punnettSquare from "./punnettSquare";
import explainer from "./explainer";
import explainerPrediction from "./explainerPrediction";
import mrnaActivity from "./mrnaActivity";
import dogmaDash from "./dogmaDash";
import centralDogma from './centralDogmaWidget';

export default [
    ...draggableDropped,
    ...workBoard,
    ...crossCard,
    ...stickyNote,
    ...punnettSquare,
    ...explainer,
    ...explainerPrediction,
    ...mrnaActivity,
    ...dogmaDash,
    ...centralDogma,
];
