import {applyMiddleware, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import DataService from "../graphql/DataService";
import DataServiceMiddleware from "./DataServiceMiddleware";
import {setDataService} from "./data/actions";
import reducers from './reducers';
import rootSaga from '../graphql/sagas';

export default function () {
    const dataService = new DataService();

    const dataServiceMiddleware = DataServiceMiddleware(dataService);
    const reduxSagaMiddleware = createSagaMiddleware();

    const store = createStore(reducers, applyMiddleware(dataServiceMiddleware, reduxSagaMiddleware));
    reduxSagaMiddleware.run(rootSaga);

    dataService.start(store);
    setDataService(store.dispatch)(dataService);
    return store;
}
