import {
    ADD_ITEM_TO_BOARD_ACTION,
    CLONE_PLANT_CARD_AS_PHENOTYPE,
    CLONE_WORK_BOARD_ITEM,
    SET_ALL_BOARDS_ACTION, WORK_BOARD_ITEMS_LOADED
} from "../workBoards/actions";
import {CREATE_STICKY_NOTE} from "../stickyNotes/actions";
import {PEA_PLANT_CARD, PUNNETT_SQUARE, STICKY_NOTE} from "../../constants/WorkBoardItemTypes";
import {CREATE_PUNNETT_SQUARE} from "../punnettSquares/actions";
import {CREATE_PEA_PLANT_CARD} from "../peaPlantCards/actions";
import {LEARNER_WORK_BOARDS_LOADED} from "../learners/actions";
import {INTERACTIONS_LOADED} from "../interactions/actions";
import {
    DROP_AREA_INTERACTION,
    WORK_BOARD_CROSS_CARD_INTERACTION,
    WORK_BOARD_DROP_SLOT
} from "../../constants/TaskTypes";

export default (state = {}, action) => {
    if(action.type === SET_ALL_BOARDS_ACTION || action.type === LEARNER_WORK_BOARDS_LOADED) {
        const boards = action.allBoards || action.workBoards;
        return boards.reduce((agg, wb) => ({
            ...agg,
            ...wb.items.reduce((itemAgg, i) => ({
                ...itemAgg,
                [i.item.id]: {
                    id: i.item.id,
                    type: i.item.__typename,
                }
            }), {}),
        }), state)
    }
    if(action.type === WORK_BOARD_ITEMS_LOADED) {
        return action.items.reduce((agg, i) => ({
            ...agg,
            [i.item.id]: {
                id: i.item.id,
                type: i.item.__typename,
            },
        }), state);
    }
    if(action.type === INTERACTIONS_LOADED) {
        const items = action.interactions.reduce((agg, i) => {
            if(i.__typename === DROP_AREA_INTERACTION) {
                return {
                    ...agg,
                    ...(i.draggables.reduce((itemAgg, item) => ({
                        ...itemAgg,
                        [item.item.id]: {
                            id: item.item.id,
                            type: item.item.__typename,
                        }
                    }), {})),
                };
            } else if(i.__typename === WORK_BOARD_CROSS_CARD_INTERACTION) {
                return {
                    ...agg,
                    ...(i.parents.concat(i.offspring).filter(item => !!item).reduce((itemAgg, item) => ({
                        ...itemAgg,
                        [item.id]: {
                            id: item.id,
                            type: PEA_PLANT_CARD, // TODO: this will need to change to allow for punnett squares
                        },
                    }), {})),
                }
            }
            return agg;
        }, {});
        return {
            ...state,
            ...items,
        };
    }
    // if(action.type === ADD_ITEM_TO_BOARD_ACTION) {
    //     return {
    //         ...state,
    //         [action.item.item]: {
    //             id: action.item.item,
    //             type: action.item.type,
    //         },
    //     };
    // }
    if(action.type === CREATE_PEA_PLANT_CARD) {
        return {
            ...state,
            [action.cardId]: {
                id: action.cardId,
                type: PEA_PLANT_CARD,
            }
        }
    }
    if(action.type === CREATE_STICKY_NOTE) {
        return {
            ...state,
            [action.id]: {
                id: action.id,
                type: STICKY_NOTE,
            },
        };
    }
    if(action.type === CREATE_PUNNETT_SQUARE) {
        return {
            ...state,
            [action.id]: {
                id: action.id,
                type: PUNNETT_SQUARE,
            },
        };
    }
    if(action.type === CLONE_WORK_BOARD_ITEM) {
        const itemToClone = state[action.itemId];
        return {
            ...state,
            [action.newId]: {
                ...itemToClone,
                id: action.newId,
            },
        };
    }
    if(action.type === CLONE_PLANT_CARD_AS_PHENOTYPE) {
        const itemToClone = state[action.cardId];
        return {
            ...state,
            [action.newId]: {
                ...itemToClone,
                id: action.newId,
            },
        };
    }
    return state;
};
