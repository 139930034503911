import * as styles from './drop-slot-style';
import React from 'react';
import {css} from "@emotion/core";
import {
    getDraggablesInDropArea,
    getInteractionWorkBoardId,
    getSlottedItemsOfTemplateItem
} from "../../store/interactions/selectors";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useDrop} from "react-dnd";
import {PEA_PLANT_CARD} from "../../constants/WorkBoardItemTypes";
import {draggablePlacedInDropArea} from "../../store/interactions/actions";
import {useParams} from "@reach/router";
import {PHOTOGRAPH, PLANT_CARD, PLANT_PHENOTYPE} from "../../constants/DropItemTypes";
import {getDropSlotAccepts, getDropSlotLabel} from "../../store/dropSlots/selectors";
import {clonePlantCardIntoMode, cloneWorkBoardItem} from "../../store/workBoards/actions";
import uuid from 'uuid/v4';
import combineStyles from "../../Utility/combineStyles";
import {getPeaPlantCardDisplayMode} from "../../store/peaPlantCards/selectors";
import {PHENOTYPE, PLANT} from "../../constants/peaPlantCardDisplayModes";
import {
    PEA_PLANT_CARD_HEIGHT,
    PEA_PLANT_CARD_WIDTH,
    PHENOTYPE_CARD_HEIGHT,
    PHENOTYPE_CARD_WIDTH
} from "../../style-variables";
import {getBoardItemIds} from "../../store/workBoards/selectors";
import WorkBoardItem from '../Items/WorkBoardItem';
import {getWorkBoardTemplateItemIds} from "../../store/curriculumTaskItems/workBoardDefinition/selectors";

const acceptsMap = {
    [PLANT_PHENOTYPE]: [PLANT_PHENOTYPE, PEA_PLANT_CARD],
    [PLANT_CARD]: [PEA_PLANT_CARD],
    [PHOTOGRAPH]: [], // TODO
};

const createDisplayModeGetter = state => id => getPeaPlantCardDisplayMode(id, state);

// TODO readonly (and send to workboarditem)
export default ({id, workBoardDefinition, scaleFactor}) => {
    scaleFactor = scaleFactor || 1;
    const {curriculumId} = useParams();
    const itemInSlot = useSelector(state => getDraggablesInDropArea(state, id)[0]);
    const acceptedType = useSelector(state => getDropSlotAccepts(id, state));
    const label = useSelector(state => getDropSlotLabel(id, state));
    const boardId = useSelector(state => getInteractionWorkBoardId(state, workBoardDefinition));
    const itemsOnBoard = useSelector(state => getBoardItemIds(state, boardId));
    const itemsOnWorkBoardTemplateItems = useSelector(state => getWorkBoardTemplateItemIds(workBoardDefinition, state).flatMap(i => getSlottedItemsOfTemplateItem(workBoardDefinition, i, state)), shallowEqual);
    const getDisplayMode = useSelector(createDisplayModeGetter); // TODO: this will always cause a rerender
    const dispatch = useDispatch();

    const [{hovered}, dropRef] = useDrop({
        accept: acceptsMap[acceptedType],
        drop: (item) => {
            const {itemId, currentDropArea} = item;
            if(itemsOnBoard.includes(itemId) || itemsOnWorkBoardTemplateItems.includes(itemId)) {
                return dispatch(draggablePlacedInDropArea(curriculumId, id, item.itemId, item.type, currentDropArea));
            }
            const newId = uuid();
            if(item.type === PLANT_PHENOTYPE) {
                dispatch(clonePlantCardIntoMode(item.plantCardId, newId, boardId, -1, item.metadata.trait));
            } else {
                dispatch(cloneWorkBoardItem(item.itemId, newId, boardId, -1));
            }
            dispatch(draggablePlacedInDropArea(curriculumId, id, newId, item.type));
        },
        canDrop: item => {
            if(itemInSlot) { return false; }
            if(acceptedType === PLANT_CARD && item.type === PLANT_CARD) {
                return getDisplayMode(item.itemId) === PLANT;
            } else if(acceptedType === PLANT_PHENOTYPE && item.type === PLANT_CARD) {
                return getDisplayMode(item.itemId) === PHENOTYPE;
            }
            return true;
        },
        collect: monitor => ({
            hovered: monitor.canDrop() && monitor.isOver(),
        }),
    });

    let slotStyle = combineStyles(styles, {
        dropSlot: true,
        dropSlotHovered: hovered,
    });
    if(acceptedType) {
        slotStyle = css(slotStyle, {
            width: (acceptedType === PLANT_CARD ? PEA_PLANT_CARD_WIDTH : PHENOTYPE_CARD_WIDTH) * scaleFactor,
            height: (acceptedType === PLANT_CARD ? PEA_PLANT_CARD_HEIGHT : PHENOTYPE_CARD_HEIGHT) * scaleFactor,
        });
    }

    return (
        <div css={styles.container}>
            <div css={slotStyle} ref={dropRef}>
                {itemInSlot && <WorkBoardItem id={itemInSlot} scaleFactor={scaleFactor} currentDropArea={id} />}
            </div>
            {label && <div css={styles.label}>{label}</div>}
        </div>
    )
};
