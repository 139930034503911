import {css} from "@emotion/core";

export const handle = css({
    width: 10,
    height: 10,
    backgroundColor: "#329044",
    position: "absolute",
    opacity: 0,
});

export const selected = css({
    opacity: 1,
});

export const rightHandle = css({
    right: 0,
    top: "50%",
    transform: 'translate(50%, -50%)',
    cursor: "ew-resize",
});

export const bottomHandle = css({
    bottom: 0,
    left: "50%",
    transform: 'translate(-50%, 50%)',
    cursor: "ns-resize",
});

export const cornerHandle = css({
    bottom: 0,
    right: 0,
    cursor: "nwse-resize",
    transform: "translate(50%, 50%)",
});
