export const CURRICULUM_LOADED = "Curriculum loaded";
export const curriculumLoaded = curriculum => ({
    type: CURRICULUM_LOADED,
    curriculum
});

export const SET_CURRICULUM_TASK_STATUS = "Set curricula task status";
export const setCurriculumTaskState = (taskId, status) => ({
    type: SET_CURRICULUM_TASK_STATUS,
    task: taskId,
    status,
});

export const CURRICULUM_TASK_STATUS_UPDATED = "Curriculum task status updated";
export const curriculumTaskStatusUpdated = (learnerId, taskId, status) => ({
    type: CURRICULUM_TASK_STATUS_UPDATED,
    task: taskId,
    status,
});
