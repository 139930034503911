import {takeEvery, call} from 'redux-saga/effects';
import {sendGraphQLSaga} from "../../DataService";
import {REMOVE_INTERACTABLE_FROM_TASK} from "../../../store/curriculumTaskItems/actions";

const query = `mutation RemoveItemFromTask($input: RemoveItemFromTaskInput!) {
    removeItemFromTask(input: $input) {
        task {
            id
        }
    }
}`;

function* execute(action) {
    const variables = {
        input: {
            curriculum: action.curriculumId,
            task: action.taskId,
            item: action.interactableId,
        },
    };
    yield call(sendGraphQLSaga, query, variables);
}

export default [takeEvery(REMOVE_INTERACTABLE_FROM_TASK, execute)];
