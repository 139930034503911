import {SET_SCROLLING_ITEM_EDITING, SET_SCROLLING_ITEM_SELECTED} from "../scrollingTaskItemPositions/actions";

const defaultState = {
    editing: null,
    selected: null,
}

export default (state = defaultState, action) => {
    if(action.type === SET_SCROLLING_ITEM_EDITING) {
        return {
            ...state,
            editing: action.editing ? action.itemId : null,
            selected: action.editing ? action.itemId : state.selected,
        }
    }
    if(action.type === SET_SCROLLING_ITEM_SELECTED) {
        return {
            ...state,
            selected: action.selected ? action.itemId : null,
        };
    }
    return state;
}
