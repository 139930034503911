// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/logo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".learner-login--container{position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);box-sizing:border-box;padding:20px;box-shadow:0 2px 3px 1px rgba(33,33,33,.25);background-color:#fff}.learner-login--logo{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:90px;height:90px;background-size:100% 100%;margin:0 auto}.learner-login--header{width:300px;margin:30px 0 30px 0;padding:0;font-size:1.25em;text-align:center}.learner-login--input-container{width:300px;margin-bottom:15px}.input-container--label{width:300px;font-size:.725em;color:#707070;display:block}.input-container--input{width:300px;color:#222;border:none;border-bottom:1px solid #b9e7c1;transition:border .2s,color .2s;height:26px;line-height:26px;box-sizing:border-box;padding:0 0 0 1px;display:block}.input-container--input:focus{border-bottom:1px solid #329044}.input-container--input:disabled{color:#bdbdbd;border-bottom-color:#bdbdbd}.learner-login--login-button{width:300px;height:50px;line-height:50px;text-align:center;border:none;background-color:#329044;color:#fff;font-size:1.125em;cursor:pointer;padding:0;transition:background-color .2s}.learner-login--login-button .load-spinner{height:35px;width:35px;display:inline-block}.learner-login--login-button:hover{background-color:#2b7a3a}.learner-login--login-button:disabled{background-color:#b9e7c1}.learner-login--validation-error{color:red;font-size:.875em;width:300px;margin-bottom:5px;transition:max-height .3s;max-height:50px}.learner-login--validation-error:empty{max-height:0}", ""]);
// Exports
module.exports = exports;
