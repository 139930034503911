import "./structure-editor.scss";
import React, {useState} from 'react';
import classNames from 'class-names';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import CurriculumCase from "./CurriculumCase";
import {useParams} from "@reach/router";
import {getNumberOfCurriculumCases} from "../../../store/curriculumCases/selectors";
import {addCaseToCurriculum} from "../../../store/curriculumCases/actions";

const getCases = (curriculumId, state) => Array.from(new Array(getNumberOfCurriculumCases(curriculumId, state)), (_, i) => i);

export default () => {
    const [expanded, setExpanded] = useState(false);
    const {curriculumId} = useParams();
    const caseIndexes = useSelector(getCases.bind(null, curriculumId), shallowEqual);
    const dispatch = useDispatch();

    const addCase = () => dispatch(addCaseToCurriculum(curriculumId));
    const toggleExpanded = () => setExpanded(e => !e);

    return (
        <div className="admin-curriculum--structure-editor">
            <div className="structure-editor--header" onClick={toggleExpanded}>
                <h3 className="structure-editor--title">Curriculum Structure</h3>
                <button className={classNames({
                    "structure-editor--expander": true,
                    "structure-editor--expander__expanded": expanded,
                })} />
            </div>
            <div className={classNames({
                "structure-editor--container": true,
                "structure-editor--container__expanded": expanded,
            })}>
                <h2 className="structure-editor--cases-header">Cases</h2>
                <div className="structure-editor--cases-container">
                    {caseIndexes.map(c => <CurriculumCase index={c} key={`${curriculumId}-${c}`} />)}
                </div>
                <button onClick={addCase} className="structure-editor--create-case">+ case</button>
            </div>
        </div>
    );
}
