import './cohort-curriculum.scss';
import React, {useState, useEffect} from 'react';
import {useSelector, shallowEqual} from "react-redux";
import {getCurriculumName, getCurriculumParent} from "../../store/curricula/selectors";
import {getDataService} from "../../store/data/selectors";
import {curriculaDifferences, resolveAllCurriculumDifferences} from "../../graphql/Curriculum";

const stateMap = props => state => ({
    name: getCurriculumName(state, props.id),
    parent: getCurriculumParent(state, props.id),
    dataService: getDataService(state),
});

export default props => {
    const {id} = props;
    const {name, parent, dataService} = useSelector(stateMap(props), shallowEqual);
    const [differencesExist, setDifferencesExist] = useState(null);
    const [resolvingDifferences, setResolvingDifferences] = useState(false);

    useEffect(() => {
        curriculaDifferences(dataService, id, parent)
            .then(data => {
                if(!data.curriculaDifference) {
                    return;
                }
                setDifferencesExist(data.curriculaDifference.changesExist);
            });
    }, [id]);

    const resolveDifferences = () => {
        setResolvingDifferences(true);
        resolveAllCurriculumDifferences(dataService, id, parent)
            .then(result => {
                if(!result.resolveAllCurriculumDifferences) {
                    return;
                }
                setDifferencesExist(false);
                setResolvingDifferences(false);
            });
    };

    return (
        <div className="cohort-curriculum">
            <div className="cohort-curriculum--name">{name}</div>
            <div className="cohort-curriculum--differences-container">
                {differencesExist === null && <div className="load-spinner" /> }
                {differencesExist &&
                <div className="cohort-curriculum--differences-text">pending changes</div>
                }
                {differencesExist && !resolvingDifferences &&
                <button onClick={resolveDifferences} className="cohort-curriculum--differences-accept-all">Update to latest</button>
                }
                {differencesExist && resolvingDifferences && <div className="load-spinner" /> }
            </div>
        </div>
    )
}
