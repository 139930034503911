export const SET_INPUT_TABLE_PROMPT = "Set input table prompt";
export const setInputTablePrompt = (curriculumId, interactableId, prompt) => ({
    type: SET_INPUT_TABLE_PROMPT,
    curriculumId,
    interactableId,
    prompt,
});

export const ADD_ROW_TO_INPUT_TABLE = "Add row to table input";
export const addRowToInputTable = (curriculumId, inputTableId) => ({
    type: ADD_ROW_TO_INPUT_TABLE,
    curriculumId,
    inputTableId,
});

export const REMOVE_ROW_FROM_INPUT_TABLE = "Remove row from table input";
export const removeRowFromInputTable = (curriculumId, inputTableId, rowIndex) => ({
    type: REMOVE_ROW_FROM_INPUT_TABLE,
    curriculumId,
    inputTableId,
    rowIndex,
});

export const SET_INPUT_TABLE_CELL_CONTENT = "Set table input cell content";
export const setInputTableCellContent = (curriculumId, inputTableId, cellRow, cellColumn, cellContent) => ({
    type: SET_INPUT_TABLE_CELL_CONTENT,
    curriculumId,
    inputTableId,
    cellRow,
    cellColumn,
    cellContent,
});

export const ADD_COLUMN_TO_INPUT_TABLE = "Add column to table input";
export const addColumnToInputTable = (curriculumId, inputTableId) => ({
    type: ADD_COLUMN_TO_INPUT_TABLE,
    curriculumId,
    inputTableId,
});

export const REMOVE_COLUMN_FROM_INPUT_TABLE = "Remove column from table input";
export const removeColumnFromInputTable = (curriculumId, inputTableId, columnIndex) => ({
    type: REMOVE_COLUMN_FROM_INPUT_TABLE,
    curriculumId,
    inputTableId,
    columnIndex,
});
