import {EMPTY_INTERACTIONS, INTERACTIONS_LOADED} from "../actions";
import {EXPLAINER_INTERACTION} from "../../../constants/TaskTypes";
import {
    ADD_EVIDENCE_TO_EXPLAINER,
    REMOVE_EVIDENCE_FROM_EXPLAINER,
    SET_EXPLAINER_CLAIM,
    SET_EXPLAINER_REASONING, UPDATE_EVIDENCE_ORDER
} from "./actions";
import {SET_AUTHENTICATION_STATE} from "../../identity/actions";
import {NOT_LOGGED_IN} from "../../../constants/AuthenticationState";
import rearrangeArray from "../../../Utility/rearrangeArray";

const createDefaultInteraction = () => ({
    claim: "",
    evidence: [],
    reasoning: "",
});

export default (state = {}, action) => {
    if(action.type === INTERACTIONS_LOADED) {
        const loadedInteractions = action.interactions.filter(i => i.__typename === EXPLAINER_INTERACTION).reduce((agg, i) => ({
            ...agg,
            [i.interactable.id]: {
                ...(state[i.interactable.id] || createDefaultInteraction()),
                claim: i.claim,
                evidence: (i.canvases[0]) ? i.canvases[0].items : [],
                reasoning: (i.canvases[0]) ? i.canvases[0].reasoning : "",
            }
        }), {});
        return {
            ...state,
            ...loadedInteractions,
        };
    }
    if(action.type === SET_EXPLAINER_CLAIM) {
        const interaction = state[action.explainerId] || createDefaultInteraction();
        return {
            ...state,
            [action.explainerId]: {
                ...interaction,
                claim: action.claim,
            },
        };
    }
    if(action.type === ADD_EVIDENCE_TO_EXPLAINER) {
        const interaction = state[action.explainerId] || createDefaultInteraction();
        return {
            ...state,
            [action.explainerId]: {
                ...interaction,
                evidence: interaction.evidence.concat(action.evidenceIds),
            },
        };
    }
    if(action.type === UPDATE_EVIDENCE_ORDER) {
        const currentOrder = state[action.explainerId].evidence;
        return {
            ...state,
            [action.explainerId]: {
                ...state[action.explainerId],
                evidence: rearrangeArray(currentOrder, action.evidenceId, action.newIndex),
            }
        }
    }
    if(action.type === REMOVE_EVIDENCE_FROM_EXPLAINER) {
        const interaction = state[action.explainerId];
        return {
            ...state,
            [action.explainerId]: {
                ...interaction,
                evidence: interaction.evidence.filter(id => !action.evidenceIds.includes(id)),
            },
        };
    }
    if(action.type === SET_EXPLAINER_REASONING) {
        const interaction = state[action.explainerId] || createDefaultInteraction();
        return {
            ...state,
            [action.explainerId]: {
                ...interaction,
                reasoning: action.reasoning,
            },
        };
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    if(action.type === EMPTY_INTERACTIONS) {
        return {};
    }
    return state;
};
