import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getUtilityButtonBehavior,
    getUtilityButtonGlobalItemId,
    getUtilityButtonText
} from "../../../store/curriculumTaskItems/utilityButton/selectors";
import {UTILITY_BUTTON_GLOBAL_ITEM, UTILITY_BUTTON_HEADSET, UTILITY_BUTTON_PARTNER} from "../../../constants/TaskTypes";
import {openModalFor, setGlobalInteractiveOpen} from "../../../store/navigation/actions";
import {FAKE_HEADSET_PAIRING_MODAL, PARTNERS_MODAL} from "../../../constants/modalTypes";
import {useTheme} from "@emotion/react";
import {themedBodyButton} from "../../../style-variables";

const UtilityButton = ({id}) => {
    const buttonText = useSelector(state => getUtilityButtonText(state, id));
    const buttonType = useSelector(state => getUtilityButtonBehavior(state, id));
    const globalItemId = useSelector(state => getUtilityButtonGlobalItemId(state, id));
    const dispatch = useDispatch();
    const theme = useTheme();

    const clicked = () => {
        if(buttonType === UTILITY_BUTTON_HEADSET) {
            dispatch(openModalFor(FAKE_HEADSET_PAIRING_MODAL));
        } else if(buttonType === UTILITY_BUTTON_PARTNER) {
            dispatch(openModalFor(PARTNERS_MODAL));
        } else if(buttonType === UTILITY_BUTTON_GLOBAL_ITEM && globalItemId) {
            dispatch(setGlobalInteractiveOpen(globalItemId));
        }
    };

    return (
        <button onClick={clicked} css={themedBodyButton(theme)}>{buttonText}</button>
    )
};

export default UtilityButton;
