import {css} from "@emotion/core";
import blueAminoImage from './assets/AminoAcidBlue.png';
import greenAminoImage from './assets/AminoAcidGreen.png';
import orangeAminoImage from './assets/AminoAcidOrange.png';
import pinkAminoImage from './assets/AminoAcidPink.png';
import {LIGHT_GRAY} from "../../../style-variables";

export const container = css({
    width: "100%",
    height: "100%",
});

export const objectsContainer = css({
    width: "100%",
    display: "flex",
    flexDirection: "column",
});

export const objectContainer = css({
    margin: "4px 0 0 0",
    border: `1px solid ${LIGHT_GRAY}`,
    boxSizing: "border-box",
    position: "relative",
});

export const inventoryObject = css({
    width: "80px",
    height: "80px",
});

export const objectCount = css({
    position: "absolute",
    right: "3px",
    bottom: "3px",
    fontSize: "0.75em",
});

export const pinkAminoAcid = css(inventoryObject, {
    backgroundImage: `url(${pinkAminoImage})`,
    backgroundSize: "calc(100% - 4px)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "2px 2px",
});

export const orangeAminoAcid = css(inventoryObject, {
    backgroundImage: `url(${orangeAminoImage})`,
    backgroundSize: "calc(100% - 4px)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "2px 2px",
});

export const blueAminoAcid = css(inventoryObject, {
    backgroundImage: `url(${blueAminoImage})`,
    backgroundSize: "calc(100% - 4px)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "2px 2px",
});

export const greenAminoAcid = css(inventoryObject, {
    backgroundImage: `url(${greenAminoImage})`,
    backgroundSize: "calc(100% - 4px)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "2px 2px",
});
