import {
    START_LOADING_LEARNER,
    START_LOADING_LEARNER_WORK_BOARDS,
    START_LOADING_WORK_BOARD_ITEMS,
} from "./actions";
import {LEARNER_WORK_BOARDS_LOADED} from "../learners/actions";
import {WORK_BOARD_ITEMS_LOADED} from "../workBoards/actions";
import {SET_LEARNER_DATA} from "../identity/actions";

const defaultState = {
    learnersLoadingWorkBoards: [],
    workBoardsLoadingItems: [],
    loadingLearner: null,
};

export default (state = defaultState, action) => {
    if(action.type === START_LOADING_LEARNER_WORK_BOARDS) {
        return Object.assign({}, state, {
            learnersLoadingWorkBoards: state.learnersLoadingWorkBoards.concat(action.learnerId),
        });
    }
    if(action.type === LEARNER_WORK_BOARDS_LOADED) {
        return Object.assign({}, state, {
            learnersLoadingWorkBoards: state.learnersLoadingWorkBoards.filter(id => id !== action.learnerId),
        });
    }
    if(action.type === START_LOADING_WORK_BOARD_ITEMS) {
        return Object.assign({}, state, {
            workBoardsLoadingItems: state.workBoardsLoadingItems.concat(action.workBoardId),
        });
    }
    if(action.type === WORK_BOARD_ITEMS_LOADED) {
        return Object.assign({}, state, {
            workBoardsLoadingItems: state.workBoardsLoadingItems.filter(id => id !== action.boardId),
        });
    }
    if(action.type === START_LOADING_LEARNER) {
        return Object.assign({}, state, {
            loadingLearner: action.learnerId,
        });
    }
    if(action.type === SET_LEARNER_DATA) {
        return Object.assign({}, state, {
            loadingLearner: null,
        });
    }
    return state;
}
