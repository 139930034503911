import {
    getPeaPlantTraitGenotype
} from "../peaPlants/selectors";
import {getTraitZygosity} from "../../WorkBoard/Items/PunnettSquare/utility";

const getPunnettSquare = (state, punnettSquareId) => state.punnettSquares[punnettSquareId];
export const getPunnettSquareTraits = (state, punnettSquareId) => {
    if(!getPunnettSquare(state, punnettSquareId)) { return []; }
    return state.punnettSquares[punnettSquareId].traits;
}
export const getPunnettSquareLeftPlant = (state, punnettSquareId) => {
    if(!getPunnettSquare(state, punnettSquareId)) { return null; }
    return getPunnettSquare(state, punnettSquareId).leftPlant;
}
export const getPunnettSquareTopPlant = (state, punnettSquareId) => {
    if(!getPunnettSquare(state, punnettSquareId)) { return null; }
    return getPunnettSquare(state, punnettSquareId).topPlant;
}
export const getPunnettSquareGenotype = (state, punnettSquareId, left, trait) => {
    if(!getPunnettSquare(state, punnettSquareId)) { return null; }
    const plantId = left ? getPunnettSquareLeftPlant(state, punnettSquareId) : getPunnettSquareTopPlant(state, punnettSquareId);
    if(!plantId) { return null; }
    const traitGenotype = getPeaPlantTraitGenotype(state, plantId, trait);
    return getTraitZygosity(traitGenotype);
};

export const getPunnettSquareNumberOfRows = (state, punnettSquareId) => 2;

export const getPunnettSquareEntryAllele = (state, punnettSquareId, entryX, entryY, alleleIndex) =>
    getPunnettSquare(state, punnettSquareId).entries[entryY * 2 + entryX][alleleIndex ? "alleleB" : "alleleA"];
export const getPunnettSquareEntryPhenotype = (state, punnettSquareId, entryX, entryY) =>
    getPunnettSquare(state, punnettSquareId).entries[entryY * 2 + entryX].phenotype;

export const getPunnettSquareAnswers = (state, punnettSquareId) => getPunnettSquare(state, punnettSquareId).questionAnswers;
export const getPunnettSquareQuestionAnswer = (state, punnettSquareId, questionIndex) => {
    const answer = getPunnettSquare(state, punnettSquareId).questionAnswers[questionIndex];
    return answer === null ? "" : answer.toString();
};
