import {FLOWER_COLOR, FLOWER_POSITION, HEIGHT, POD_COLOR, POD_SHAPE, SEED_COLOR, SEED_SHAPE} from "./TraitNames";

export const TRAIT_SYMBOL_MAP = {
    [HEIGHT]: "t",
    [FLOWER_COLOR]: "p",
    [FLOWER_POSITION]: "a",
    [SEED_COLOR]: "y",
    [SEED_SHAPE]: "r",
    [POD_COLOR]: "g",
    [POD_SHAPE]: "i",
};
