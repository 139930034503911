import {CURRICULA_LOADED} from "../../curricula/actions";
import {INPUT_TABLE} from "../../../constants/TaskTypes";
import {ADD_INTERACTABLE_TO_TASK, REMOVE_INTERACTABLE_FROM_TASK} from "../actions";
import {omit} from "lodash";
import {CELL_TYPE_NONE} from "../../../constants/InputTableCellTypes";
import {
    ADD_COLUMN_TO_INPUT_TABLE,
    ADD_ROW_TO_INPUT_TABLE,
    REMOVE_COLUMN_FROM_INPUT_TABLE,
    REMOVE_ROW_FROM_INPUT_TABLE,
    SET_INPUT_TABLE_CELL_CONTENT,
    SET_INPUT_TABLE_PROMPT
} from "./actions";

const createDefaultItem = (data) => ({
    prompt: "",
    data: data || [{type: CELL_TYPE_NONE}],
    numberOfColumns: 1,
});

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const newTables = action.curricula.reduce((agg, c) => ({
            ...agg,
            ...(c.tasks || []).reduce((taskAgg, t) => ({
                ...taskAgg,
                ...t.items.filter(i => i.__typename === INPUT_TABLE).reduce((itemsAgg, i) => {
                    let omits = ['__typename'];
                    let data;
                    if(!i.numberOfColumns) {
                        omits = omits.concat(['data', 'numberOfColumns']);
                    } else {
                        data = i.data.map(c => JSON.parse(c));
                    }
                    return {
                        ...itemsAgg,
                        [i.id]: {
                            ...createDefaultItem(data),
                            ...omit(i, omits),
                        },
                    };
                }, {}),
            }), {}),
        }), {});
        return {
            ...state,
            ...newTables,
        };
    }
    if(action.type === ADD_INTERACTABLE_TO_TASK && action.interactableType === INPUT_TABLE) {
        return {
            ...state,
            [action.interactableId]: {
                ...createDefaultItem(),
            },
        };
    }
    if(action.type === REMOVE_INTERACTABLE_FROM_TASK) {
        return omit(state, action.interactableId);
    }
    if(action.type === SET_INPUT_TABLE_PROMPT) {
        return {
            ...state,
            [action.interactableId]: {
                ...state[action.interactableId],
                prompt: action.prompt,
            },
        };
    }
    if(action.type === ADD_ROW_TO_INPUT_TABLE) {
        const {numberOfColumns, data} = state[action.inputTableId];
        const updatedData = data.concat(Array.from(new Array(numberOfColumns), () => ({ type: CELL_TYPE_NONE })));
        return {
            ...state,
            [action.inputTableId]: {
                ...state[action.inputTableId],
                data: updatedData,
            }
        };
    }
    if(action.type === REMOVE_ROW_FROM_INPUT_TABLE) {
        const {numberOfColumns, data} = state[action.inputTableId];
        const updatedData = data.filter((d, i) => Math.floor(i / numberOfColumns) !== action.rowIndex);
        return {
            ...state,
            [action.inputTableId]: {
                ...state[action.inputTableId],
                data: updatedData,
            },
        };
    }
    if(action.type === ADD_COLUMN_TO_INPUT_TABLE) {
        const {numberOfColumns, data} = state[action.inputTableId];
        const numberOfRows = data.length/numberOfColumns;
        const newNumberOfColumns = numberOfColumns + 1;
        const updatedData = Array.from(new Array(data.length + numberOfRows), (_, i) => {
            const newRow = Math.floor(i / newNumberOfColumns);
            const newColumn = i % newNumberOfColumns;
            return newColumn === numberOfColumns // 0 indexing means that the new column's index will be the old number of columns
                ? {type: CELL_TYPE_NONE}
                : data[newRow * numberOfColumns + newColumn];
        });
        return {
            ...state,
            [action.inputTableId]: {
                ...state[action.inputTableId],
                numberOfColumns: newNumberOfColumns,
                data: updatedData,
            }
        };
    }
    if(action.type === REMOVE_COLUMN_FROM_INPUT_TABLE) {
        const {numberOfColumns, data} = state[action.inputTableId];
        const updatedData = data.filter((d, i) => i % numberOfColumns !== action.columnIndex);
        return {
            ...state,
            [action.inputTableId]: {
                ...state[action.inputTableId],
                numberOfColumns: numberOfColumns - 1,
                data: updatedData,
            }
        };
    }
    if(action.type === SET_INPUT_TABLE_CELL_CONTENT) {
        const {numberOfColumns, data} = state[action.inputTableId];
        const index = action.cellRow * numberOfColumns + action.cellColumn;
        const updatedData = data.map((d, i) => i === index ? action.cellContent : d);
        return {
            ...state,
            [action.inputTableId]: {
                ...state[action.inputTableId],
                data: updatedData,
            },
        };
    }
    return state;
}
