import {takeEvery, call} from 'redux-saga/effects';
import {sendGraphQLSaga} from "../../DataService";
import {SET_CROSS_CARD_NUMBER_OF_OFFSPRING} from "../../../store/crossCards/actions";

const updateConfiguration = `mutation UpdateCrossCardTemplate($input: UpdateCrossCardTemplateInput!) {
    updateCrossCardTemplate(input: $input) {
        crossCard {
            id
        }
    }
}`

function* execute(action) {
    const variables = {
        input: {
            curriculum: action.curriculumId,
            crossCard: action.crossCardId,
            numberOfOffspring: action.numberOfOffspring,
        },
    };

    yield call(sendGraphQLSaga, updateConfiguration, variables);
}

export default [takeEvery(SET_CROSS_CARD_NUMBER_OF_OFFSPRING, execute)];
