const getExplainerInteraction = (state, explainerId) => state.explainerInteractions[explainerId];
export const getExplainerClaim = (state, explainerId) => {
    const interaction = getExplainerInteraction(state, explainerId);
    if(!interaction) { return ""; }
    return interaction.claim;
};
export const getExplainerEvidenceIds = (state, explainerId) => {
    const interaction = getExplainerInteraction(state, explainerId);
    if(!interaction) { return []; }
    return interaction.evidence;
};
export const getExplainerReasoning = (state, explainerId) => {
    const interaction = getExplainerInteraction(state, explainerId);
    if(!interaction) { return []; }
    return interaction.reasoning;
};
