import {SET_DROP_SLOT_ACCEPTS, SET_DROP_SLOT_LABEL, SET_DROP_SLOT_VALIDATION_DATA} from "./actions";
import {CURRICULA_LOADED} from "../curricula/actions";
import {SCROLLING_DROP_SLOT_ITEM, WORK_BOARD_DEFINITION, WORK_BOARD_DROP_SLOT} from "../../constants/TaskTypes";
import {ADD_ITEM_TO_SCROLLING_TASK} from "../scrollingTaskItemPositions/actions";
import {ADD_ITEM_TO_WORK_BOARD_TEMPLATE} from "../workBoardTemplate/actions";

const defaultDropSlot = {
    id: "",
    accepts: null,
    label: "",
    validationData: "",
};

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const newItems = action.curricula.reduce((agg, c) => ({
            ...agg,
            ...(c.tasks || []).reduce((taskAgg, t) =>
                ({
                    ...taskAgg,
                    ...t.items.filter(i => i.__typename === SCROLLING_DROP_SLOT_ITEM).reduce((itemsAgg, i) => ({
                        ...itemsAgg,
                        [i.id]: {
                            ...defaultDropSlot,
                            id: i.id,
                            accepts: i.accepts,
                            label: i.label,
                        },
                    }), {}),
                    ...t.items.filter(i => i.__typename === WORK_BOARD_DEFINITION).reduce((wbAgg, i) => ({
                        ...wbAgg,
                        ...i.templateItems.filter(t => t.__typename === WORK_BOARD_DROP_SLOT).reduce((tAgg, t) => ({
                            ...tAgg,
                            [t.id]: {
                                ...defaultDropSlot,
                                id: t.id,
                                accepts: t.accepts,
                                label: t.label,
                                validationData: t.validationData,
                            },
                        }), {}),
                    }), {}),
                }), {}),
        }), {});
        return {
            ...state,
            ...newItems,
        };
    }
    if((action.type === ADD_ITEM_TO_SCROLLING_TASK && action.itemType === SCROLLING_DROP_SLOT_ITEM) ||
        (action.type === ADD_ITEM_TO_WORK_BOARD_TEMPLATE && action.itemType === WORK_BOARD_DROP_SLOT)){
        return {
            ...state,
            [action.itemId]: {
                ...defaultDropSlot,
                id: action.itemId,
            },
        };
    }
    if(action.type === SET_DROP_SLOT_ACCEPTS) {
        return {
            ...state,
            [action.dropSlotId]: {
                ...state[action.dropSlotId],
                accepts: action.acceptsType,
            },
        };
    }
    if(action.type === SET_DROP_SLOT_LABEL) {
        return {
            ...state,
            [action.dropSlotId]: {
                ...state[action.dropSlotId],
                label: action.label,
            },
        };
    }
    if(action.type === SET_DROP_SLOT_VALIDATION_DATA) {
        return {
            ...state,
            [action.dropSlotId]: {
                ...state[action.dropSlotId],
                validationData: action.validationData,
            },
        };
    }
    return state;
};
