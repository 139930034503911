import {
    getVerdantLearnerQuery,
    getVerdantLearnerVariables,
    learnerProgressUpdatedSubscription,
    learnerProgressUpdatedVariables,
    setInputTableLearnerInputs,
    setInputTableLearnerInputsVariables,
    setLearnerNameQuery,
    setLearnerNameVariables,
    setLongTextInputTextQuery,
    setLongTextInputTextVariables,
    setMultipleChoiceInputSelectionQuery,
    setMultipleChoiceInputSelectionVariables
} from "./queries/verdantLearner";
import {parseWorkBoard} from "./WorkBoard";
import {setAllBoards} from "../store/workBoards/actions";
import {importBounties} from "../store/bounties/actions";
import {setLearnerData} from "../store/identity/actions";
import {assignCurriculumToLearner} from "./Curriculum";
import {curriculumLoaded, curriculumTaskStatusUpdated} from "../store/curriculum/actions";
import {selectActiveLearner} from "../store/navigation/actions";
import {getActiveLearner} from "../store/navigation/selectors";
import {getSubscriptionClient} from "../store/data/selectors";
import gql from 'graphql-tag';
import {
    addPartnerToLearner,
    bountyProgressUpdated,
    partnerRequestLoaded,
    vrStartTimeUpdated
} from "../store/learners/actions";
import {getLoadingLearner} from "../store/dataAccess/selectors";
import {startLoadingLearner} from "../store/dataAccess/actions";
import {curriculaLoaded} from "../store/curricula/actions";
import learnerEvent from "../graphql/subscriptions/learnerEvent";

let learnerUpdatedSubscription;
export const retrieveVerdantLearner = (dataService, learnerId = null, subscribe = true) => {
    if(getLoadingLearner(dataService.store.getState())) { return; }
    dataService.store.dispatch(startLoadingLearner(learnerId || "authenticated"));

    return dataService.sendGraphQL(getVerdantLearnerQuery, getVerdantLearnerVariables(learnerId))
        .then(data => {
            if(!data || !data.verdantLearner) {
                return;
            }
            parseVerdantLearner(dataService, data.verdantLearner);
            if(subscribe) {
                if (learnerUpdatedSubscription) {
                    learnerUpdatedSubscription.unsubscribe();
                }
                learnerUpdatedSubscription = subscribeToLearnersUpdated(dataService, [data.verdantLearner.id]);
            }
        });
};

let learnerWorkboardsSubscription;
// TODO: clean up old curriculum stuff (bounties, etc.) - the code is still here to provide a template for that clean up
export const parseVerdantLearner = (dataService, learner, subscribe = true) => {
    const {id, givenName, familyName, email, cohort, groups, partnershipRequests} = learner;//, bounties, curricula
    const dispatch = dataService.store.dispatch;
    // const parsed = bounties.reduce((agg, bountyAssignment) => {
    //     agg.bountyAssignments[bountyAssignment.bounty.id] = {
    //         progress: bountyAssignment.progress,
    //         workBoards: [],
    //     };
    //     bountyAssignment.workBoards.forEach(wb => {
    //         const {workBoard: parsedWorkBoard} = parseWorkBoard(wb, dispatch);
    //         agg.workBoards.push(parsedWorkBoard);
    //         agg.bountyAssignments[bountyAssignment.bounty.id].workBoards.push(parsedWorkBoard.id);
    //     });
    //     agg.bounties.push(bountyAssignment.bounty);
    //
    //
    //
    //     return agg;
    // }, {
    //     "workBoards": [],
    //     "bounties": [],
    //     "bountyAssignments": {},
    // });

    // dispatch(importBounties(parsed.bounties));
    // dispatch(setAllBoards(parsed.workBoards));
    // if(subscribe) {
    //     if(learnerWorkboardsSubscription) { learnerWorkboardsSubscription.unsubscribe(); }
    //     learnerWorkboardsSubscription = dataService.subscribeToWorkBoards(parsed.workBoards.map(wb => wb.id));
    // }

    // dispatch(curriculumLoaded(curricula[0]));

    dispatch(curriculaLoaded(cohort.curricula));
    dispatch(setLearnerData(id,
        givenName,
        familyName,
        email,
        [],
        []));
        // parsed.workBoards.map(wb => wb.id),
        // parsed.bountyAssignments)); // if learner id is set, bounty assignments need to be set before curricula

    const cohortLearners = cohort.learners;
    for(let i = 0; i < cohortLearners.length; ++i) {
        const {givenName, familyName, email, id} = cohortLearners[i];
        dispatch(setLearnerData(id, givenName, familyName, email));
    }
    for(let i = 0; i < groups.length; ++i) {
        const curriculum = groups[i].curriculum.id;
        let learners = groups[i].learners;
        for(let j = 0; j < learners.length; ++j) {
            dispatch(addPartnerToLearner(id, curriculum, learners[j].id));
        }
    }
    for(let i = 0; i < partnershipRequests.length; ++i) {
        let request = partnershipRequests[i];
        const curriculum = request.curriculum.id;
        dispatch(partnerRequestLoaded(curriculum, request.requester.id, request.partner.id));
    }

    if(getActiveLearner(dataService.store.getState()) !== id) {
        dispatch(selectActiveLearner(id));
    }

    if(subscribe) {
        const client = getSubscriptionClient(dataService.store.getState());
        learnerEvent(client, dispatch, id);
    }
};

export const setLearnerName = (dataService, learnerId, givenName, familyName) => {
    const variables = setLearnerNameVariables(learnerId, givenName, familyName);
    return dataService.sendGraphQL(setLearnerNameQuery, variables);
};

export const subscribeToLearnersUpdated = (dataService, learnerIds) => {
    const client = getSubscriptionClient(dataService.store.getState());
    const dispatch = dataService.store.dispatch;
    return client.subscribe({
        query: gql`${learnerProgressUpdatedSubscription}`,
        variables: learnerProgressUpdatedVariables(learnerIds),
    }).subscribe({
        next(data) {
            if(!data.data.learnerProgressUpdated) { return; }
            const { bounty, task, status } = data.data.learnerProgressUpdated;
            const { id: learnerId, vrSessionStartTime } = data.data.learnerProgressUpdated.learner;

            if(bounty) {
                dispatch(bountyProgressUpdated(learnerId, bounty, status));
            } else if(task) {
                dispatch(curriculumTaskStatusUpdated(learnerId, task, status));
            } else {
                dispatch(vrStartTimeUpdated(learnerId, vrSessionStartTime));
            }
        }
    });
};

export const setLongTextInputText = (dataService, learnerId, curriculumId, interactableId, text) => {
    const variables = setLongTextInputTextVariables(learnerId, curriculumId, interactableId, text);
    return dataService.sendGraphQL(setLongTextInputTextQuery, variables);
};

export const setMultipleChoiceInputSelection = (dataService, learnerId, curriculumId, multipleChoiceId, selection) => {
    const variables = setMultipleChoiceInputSelectionVariables(learnerId, curriculumId, multipleChoiceId, selection);
    return dataService.sendGraphQL(setMultipleChoiceInputSelectionQuery, variables);
};

export const setInputTableInputs = (dataService, learnerId, curriculumId, inputTableId, inputs) => {
    const variables = setInputTableLearnerInputsVariables(learnerId, curriculumId, inputTableId, inputs);
    return dataService.sendGraphQL(setInputTableLearnerInputs, variables);
};
