import "./draggable.scss";
import React from 'react';
import classNames from "class-names";
import {getDraggableContent} from "../../../store/curriculumTaskItems/draggable/selectors";
import {useDispatch, useSelector} from "react-redux";
import RichTextDisplay from "../RichTextDisplay";
import {useDrag} from "react-dnd";
import {useParams} from "@reach/router";
import {removeDraggableFromDropArea} from "../../../store/interactions/actions";
import {getDropAreasForDraggable} from "../../../store/interactions/selectors";

export const DRAGGABLE_BANK_DRAGGABLE = "DRAGGABLE-BANK-DRAGGABLE";

const getContent = id => state => getDraggableContent(state, id);
const getDropArea = id => state => getDropAreasForDraggable(state, id)[0];

export default props => {
    const {id} = props;
    const content = useSelector(getContent(id));
    const dropArea = useSelector(getDropArea(id))
    const dispatch = useDispatch();
    const {curriculumId} = useParams();

    const [{isDragging}, dragRef] = useDrag({
        item: { type: DRAGGABLE_BANK_DRAGGABLE, itemId: props.id },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (_, monitor) => {
            if(!monitor.didDrop()) {
                dispatch(removeDraggableFromDropArea(curriculumId, dropArea, id));
            }
        }
    });

    return (
        <div ref={dragRef} className={classNames({
            "learner-curriculum-task--draggable": true,
            "learner-curriculum-task--draggable__dragging": isDragging,
        })}>
            <RichTextDisplay content={content} />
        </div>
    );
}
