import {css} from "@emotion/core";
import {bbPadding, DARK_GRAY, inlineBlockTop, LIGHT_GRAY} from "../../../../style-variables";
import closeX from "../../../../assets/gray-square-x.svg";

export const title = css({
    padding: 0,
    marginBottom: "6px",
    fontSize: "1em",
});

export const interactablesContainer = css({
    width: "100%",
});

export const expandButton = css({
    width: "100%",
    height: "30px",
    border: `1px solid ${DARK_GRAY}`,
    borderRadius: "3px",
    backgroundColor: "white",
    transition: "background-color 0.2s",
    cursor: "pointer",

    "&:hover": {
        backgroundColor: "0.2s",
    },
});

export const interactableCreationContainer = css(bbPadding("0 10px 0 10px"), {
    height: 0,
    width: "100%",
    overflow: "hidden",
    position: "relative",
    border: `0 solid ${DARK_GRAY}`,
    transition: "height 0.3s, padding 0.3s, margin 0.3s, border-width 0.3s step-start",
});

export const interactableCreationContainerVisisble = css(interactableCreationContainer, {
    height: "211px",
    borderWidth: "1px",
    overflow: "auto",
    margin: "0 0 5px 0",
    paddingTop: "5px",
});

export const close = css({
    width: "15px",
    height: "15px",
    background: `url(${closeX})`,
    backgroundSize: "100% 100%",
    border: "none",
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
});

export const interactableCreationDescription = css({
    fontSize: "0.875em",
    margin: "0 0 5px 0",
});

export const newInteractable = css(inlineBlockTop, {
    width: "80px",
    height: "80px",
    margin: "0 10px 10px 0",
    border: `1px solid ${DARK_GRAY}`,
    backgroundColor: "white",
    position: "relative",
    transition: "background-color 0.2s",
    cursor: "pointer",

    "&:hover": {
        backgroundColor: LIGHT_GRAY,
    },
});
