import './cohort.scss';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getCohortCurricula, getCohortLearnerIds, getCohortName,} from "../../store/cohorts/selectors";
import {isAddLeanerToCohortModalOpen} from "../../store/addLearnerModal/selectors";
import {getDataService} from "../../store/data/selectors";
import {setCohortName} from "../../store/cohorts/actions";
import {closeAddLearnerModal, openAddLearnerToCohortModal, setModalLoading} from "../../store/addLearnerModal/action";
import AddLearnerModal from "./AddLearnerModal";
import CohortLearner from './CohortLearner';
import {subscribeToLearnersUpdated} from "../../graphql/VerdantLearner";
import EditLearner from "../Learner/EditLearner";
import {shallowUnorderedArrayCompare} from "../../utility";
import InlineEditor from "../../Utility/InlineEditor";
import CohortCurriculum from "./CohortCurriculum";
import {isModalOpenFor} from "../../store/navigation/selectors";
import {openModalFor} from "../../store/navigation/actions";
import AddCurriculumModal from "./AddCurriculumModal";
import {ADD_CURRICULA_TO_COHORT_MODAL} from "../../constants/modalTypes";

const stateMap = props => state => ({
    learners: getCohortLearnerIds(state, props.cohortId),
    curricula: getCohortCurricula(state, props.cohortId),
    name: getCohortName(state, props.cohortId) || '',
    isAddingLearner: isAddLeanerToCohortModalOpen(state),
    isAddingCurriculum: isModalOpenFor(state, ADD_CURRICULA_TO_COHORT_MODAL, props.cohortId),
    dataService: getDataService(state),
});

const comparer = (a, b) => {
    if(a.name !== b.name) { return false; }
    if(a.isAddingLearner !== b.isAddingLearner) { return false; }
    if(a.isAddingCurriculum !== b.isAddingCurriculum) { return false; }
    if(!shallowUnorderedArrayCompare(a.curricula, b.curricula)) { return false; }
    return shallowUnorderedArrayCompare(a.learners, b.learners);
};

const NAME_PLACEHOLDER = 'Name this class';

export default React.memo(props => {
    const { learners, curricula, name, isAddingLearner, isAddingCurriculum, dataService } = useSelector(stateMap(props), comparer);
    const dispatch = useDispatch();

    const openAddModal = () => dispatch(openAddLearnerToCohortModal());
    const addLearners = emails => {
        dispatch(setModalLoading(true));
        dataService.addLearnersToCohort(props.cohortId, emails)
            .then(() => {
                dispatch(closeAddLearnerModal());
            });
    };

    useEffect(() => {
        const subscription = subscribeToLearnersUpdated(dataService, learners);
        return () => subscription.unsubscribe();
    });

    const saveName = name => setCohortName(dispatch)(props.cohortId, name);
    const openAddCurriculumToCohort = () => dispatch(openModalFor(ADD_CURRICULA_TO_COHORT_MODAL, props.cohortId));

    return (
        <React.Fragment>
            { isAddingLearner && <AddLearnerModal close={addLearners} /> }
            { isAddingCurriculum && <AddCurriculumModal cohortId={props.cohortId} /> }
            { <EditLearner/> }
            <div className='cohort-container'>
                <div className='cohort--name-container'>
                    <InlineEditor name={name || NAME_PLACEHOLDER} setName={saveName} />
                </div>
                <div className="cohort--curricula-header">Assigned curricula:</div>
                <div className="cohort--curricula-list">
                    {curricula.map(c => <CohortCurriculum key={c} id={c} />)}
                    <button onClick={openAddCurriculumToCohort} className="cohort--add-curriculum">Assign curriculum</button>
                </div>
                <div className="cohort--learners-header">Students:</div>
                <div className='cohort--learners-container'>
                    <button onClick={openAddModal} className="cohort--add-learner">Add learner</button>
                    {learners.map(l => <CohortLearner key={l} id={l} />)}
                </div>
            </div>
        </React.Fragment>
    );
});
