import {FLOWER_COLOR, FLOWER_POSITION, HEIGHT, POD_COLOR, POD_SHAPE, SEED_COLOR, SEED_SHAPE} from "./TraitNames";
import {DOMINANT, HETEROZYGOUS, RECESSIVE} from "./Zygosities";

export const HYBRID_TYPES = [
    "Monohybrid",
    "Dihybrid",
    "Trihybrid",
];

export const TRAIT_TYPES = [
    HEIGHT,
    FLOWER_COLOR,
    FLOWER_POSITION,
    POD_COLOR,
    POD_SHAPE,
    SEED_COLOR,
    SEED_SHAPE,
];

export const ZYGOSITY = [
    DOMINANT,
    RECESSIVE,
    HETEROZYGOUS,
];
