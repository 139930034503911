import {css, keyframes} from "@emotion/core";
import {dogmashBlue, dogmashLightOrange} from "./dogma-dash-styles";
import {inlineBlockTop} from "../../../../style-variables";
import debrisSprite from './Assets/debrisSprite.png';
import {LysosomeEatTime} from "./Lysosome";

export const container = css({
    marginBottom: "6px",
    textAlign: "center",

    "&:last-of-type": {
        marginBottom: 0,
    },
});

export const iconBlue = css(inlineBlockTop, {
    width: "38px",
    height: "38px",
    fill: dogmashBlue,
    stroke: `${dogmashBlue}!important`,
    zIndex: 1,

    "& > svg": {
        width: "100%",
        height: "100%",
    },
});
export const iconYellow = css(iconBlue, {
    fill: dogmashLightOrange,
    stroke: `${dogmashLightOrange}!important`,
});

export const label = css({
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: "700",
    fontSize: '.75em',
    color: dogmashBlue,
    textTransform: 'uppercase',
});

export const debrisLabel = css({
    color: "#fff",
    fontFamily: "Rajdhani, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    textTransform: "uppercase",
});

const breakdownFrames = 26;
const breakdown = keyframes({
    "0%": {backgroundPositionX: 0},
    "33%": {backgroundPositionX: 0},
    "66%": {backgroundPositionX: `-${26 * 54}px`},
    "100%": {backgroundPositionX: `-${26 * 54}px`},
});
export const denatured = css({
    width: "54px",
    height: "54px",
    position: "relative",
    backgroundImage: `url(${debrisSprite})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: 0,
});
export const denaturedEaten = css(denatured, {
    animation: `${breakdown} ${LysosomeEatTime/3}ms steps(${breakdownFrames}) forwards`,
    animationDelay: `${LysosomeEatTime/3}ms`
});
