import * as styles from './item-reference-selector-styles';
import React, {useState} from 'react';
import {shallowEqual, useSelector} from "react-redux";
import {
    getCurriculumTaskItemIds,
    getCurriculumTaskTitle,
    getCurriculumTaskType
} from "../../../store/curriculumTask/selectors";
import {getCurriculumTaskItemType} from "../../../store/curriculumTaskItems/selectors";
import {getCurriculumTaskIds} from "../../../store/curricula/selectors";
import {DRAGGABLE_BANK, LONG_TEXT_INPUT, WORK_BOARD_DEFINITION} from "../../../constants/TaskTypes";

const interactableTypeDisplayMap = {
    [LONG_TEXT_INPUT]: "Long text",
    [WORK_BOARD_DEFINITION]: "Workboard",
    [DRAGGABLE_BANK]: "Draggable",
};

const ItemReferenceSelector = ({modalCloser, selectedCallback, curriculumId, allowedInteractableTypes, allowedTaskTypes}) => {
    const [selectedTask, setSelectedTask] = useState(null);
    const [selectedInteractable, setSelectedInteractable] = useState(null);
    const taskIds = useSelector(state => {
        const ids = getCurriculumTaskIds(state, curriculumId);
        if(!allowedTaskTypes) { return ids; }
        return ids.filter(id => {
            const type = getCurriculumTaskType(id, state);
            return allowedTaskTypes.some(t => t === type);
        });
    }, shallowEqual);
    const taskNames = useSelector(state => taskIds.map(t => getCurriculumTaskTitle(state, t)), shallowEqual);
    // TODO: filter out unallowed interactable types
    const interactableIds = useSelector(state => {
        if(!selectedTask) { return []; }
        const ids = getCurriculumTaskItemIds(state, selectedTask);
        if(!allowedInteractableTypes) { return ids; }
        return ids.filter(id => {
            const type = getCurriculumTaskItemType(state, id);
            return allowedInteractableTypes.some(t => t === type);
        });
    }, shallowEqual);
    const interactableTypes = useSelector(state => interactableIds.map(i => getCurriculumTaskItemType(state, i)), shallowEqual);


    const selectTask = e => {
        if(e.target.checked) { setSelectedTask(e.target.value); }
    };
    const selectInteractable = e => {
        if(e.target.checked) { setSelectedInteractable(e.target.value); }
    };

    const itemSelected = e => selectedCallback({
        eventData: e,
        interactableId: selectedInteractable,
        interactableType: interactableTypes[interactableIds.indexOf(selectInteractable)],
        taskId: selectedTask,
    });

    return (
        <div css={styles.blocker}>
            <div css={styles.modalContainer}>
                <h3 css={styles.modalHeader}>1. Select a task</h3>
                <div css={styles.taskContainer}>
                    {taskIds.map((t, i) =>
                        <div key={t} css={styles.task}>
                            <input css={styles.selectableCheck} id={`task-check-${t}`} type="radio" onChange={selectTask} value={t} checked={t === selectedTask}/>
                            <label css={styles.selectableLabel} htmlFor={`task-check-${t}`}>{taskNames[i] || `Unnamed task ${i}`}</label>
                        </div>
                    )}
                </div>
                <div css={selectedTask ? styles.interactableContainerShown : styles.interactableContainer}>
                    <h3 css={styles.modalHeader}>2. Select an interactable:</h3>
                    {interactableIds.length === 0 && <div css={styles.noInteractables}>This task has no available interactables to reference</div> }
                    <div css={styles.interactableList}>
                        {interactableIds.map((id, i) =>
                            <div key={id} css={styles.interactable}>
                                <input css={styles.selectableCheck} id={`interactable-check-${id}`} type="radio" onChange={selectInteractable} value={id} checked={id === selectedInteractable} />
                                <label css={styles.selectableLabel} htmlFor={`interactable-check-${id}`}>{interactableTypeDisplayMap[interactableTypes[i]] || interactableTypes[i]}</label>
                            </div>
                        )}
                    </div>
                </div>
                <div css={styles.buttonContainer}>
                    <button css={styles.selectButton} onMouseDown={itemSelected} disabled={!selectedInteractable}>Select Task</button>
                    <button css={styles.cancelButton} onMouseDown={modalCloser} className="reference-input--cancel">Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default ItemReferenceSelector;
