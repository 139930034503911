// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/trash.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/edit.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".admin-task--input-table{width:100%;border:1px solid #6f6f6f;margin:0 0 5px 0;box-sizing:border-box;padding:10px;overflow-y:auto}.input-table--prompt-container{width:100%;height:150px;margin:0 0 4px 0}.input-table--table{border-collapse:collapse;border-spacing:0;table-layout:fixed;margin:0 0 4px 0}.input-table--table td{border:1px solid #333;height:100%}.input-table--remove-column,.input-table--remove-row{width:15px;height:15px;padding:0;margin:auto;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;border:none;cursor:pointer}.input-table--cell-none{min-width:40px;min-height:40px}.input-table--add-column{width:70px;height:100%}.input-table--add-row{width:100%}.input-table--edit-cell{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:100% 100%;border:none;width:10px;height:10px;cursor:pointer}", ""]);
// Exports
module.exports = exports;
