import "./podulatorReceipt.scss";

import React from 'react';
import classNames from 'class-names'
import {useSelector} from "react-redux";
import {
    getPodulatorReceiptLabel,
    getPodulatorReceiptGenomes,
    getPodulatorReceiptSampleSize,
    getPodulatorReceiptPlantGenotypeLetter,
    getPodulatorReceiptTraitTypes,
    getPodulatorReceiptEntries
} from "../../store/podulatorReceipts/selectors";
import {
    FLOWER_COLOR,
    FLOWER_POSITION,
    HEIGHT,
    POD_COLOR,
    POD_SHAPE, SEED_COLOR, SEED_SHAPE,
    TRAIT_DISPLAY_NAMES
} from "../../constants/TraitNames";
import {TRAIT_TYPES} from "../../constants/PodulatorReceiptTypes";
import {TRAIT_SYMBOL_MAP} from "../../constants/TraitGeneSymbol";

const stateMap = props => state => ({
    label: getPodulatorReceiptLabel(state, props.id),
    sampleSize: getPodulatorReceiptSampleSize(state, props.id),
    traits: getPodulatorReceiptTraitTypes(state, props.id),
    genomes: getPodulatorReceiptGenomes(state, props.id),
});

const formatPercentHundreth = number => `${(number*100).toFixed(2)}%`;

const TRAIT_KEY_MESSAGE = {
    [HEIGHT]: "tall/short plant",
    [FLOWER_COLOR]: "purple/white flower",
    [FLOWER_POSITION]: "axial/terminal flower position",
    [POD_COLOR]: "green/yellow pod color",
    [POD_SHAPE]: "inflated/constricted pod",
    [SEED_COLOR]: "yellow/green pea color",
    [SEED_SHAPE]: "round/wrinkled pea",
};

export default props => {
    const { label, sampleSize, traits, genomes } = useSelector(stateMap(props));

    const crossType = traits.length === 1 ? "Monohybrid" : "Dihybrid";
    const traitsString = traits.map(t => TRAIT_DISPLAY_NAMES[TRAIT_TYPES[t]]).join(", ");

    return (
        <div className={classNames({
            "podulator-receipt": true,
            "podulator-receipt__dihybrid": traits.length === 2,
        })}>
            <h1 className="podulator-receipt--title">{label}</h1>
            <div className="podulator-receipt--cross-type">{crossType}</div>
            <div className="podulator-receipt--sample-size">{`${sampleSize} plants`}</div>
            <div className="podulator-receipt--trait-list">{`Traits: ${traitsString}`}</div>
            {traits.map((t, i) => <Square traitType={t} id={props.id} key={`square-${i}`} />)}
            <div className="podulator-receipt--genome-container">
                {genomes.map((g, i) => (
                    <div key={`${props.id}-g-${i}`} className="podulator-receipt--genome">
                        <span className="podulator-receipt--genome-value">{`${g.genotypeString}:`}</span>
                        <span className="podulator-receipt--genome-percent">{formatPercentHundreth(g.percent)}</span>
                    </div>
                ))}
            </div>
            <div className="podulator-receipt--genome-key">
                <h4>Key:</h4>
                {traits.map((t, i) => {
                    const trait = TRAIT_TYPES[t];
                    const letter = TRAIT_SYMBOL_MAP[trait];
                    return (<div key={`trait-key-${t}-${i}`} className="">{`${letter.toUpperCase()}/${letter}: ${TRAIT_KEY_MESSAGE[trait]}`}</div>);
                })}
            </div>
        </div>
    )
}

const squareStateMap = props => state => ({
    data: getPodulatorReceiptEntries(state, props.id, props.traitType),
    topLeftLabel: getPodulatorReceiptPlantGenotypeLetter(state, props.id, props.traitType, false, 0),
    topRightLabel: getPodulatorReceiptPlantGenotypeLetter(state, props.id, props.traitType, false, 1),
    leftTopLabel: getPodulatorReceiptPlantGenotypeLetter(state, props.id, props.traitType, true, 0),
    leftBottomLabel: getPodulatorReceiptPlantGenotypeLetter(state, props.id, props.traitType, true, 1),
});

const Square = props => {
    const {data, topLeftLabel, topRightLabel, leftTopLabel, leftBottomLabel } = useSelector(squareStateMap(props));

    return (
        <table className="podulator-receipt--cross">
            <tbody>
                <tr>
                    <td className="podulator-receipt--cross-corner" />
                    <th>{topLeftLabel}</th>
                    <th>{topRightLabel}</th>
                </tr>
                <tr>
                    <th scope="row">{leftTopLabel}</th>
                    <td>
                        <div className="podulator-receipt--cross-entry-label">{[topLeftLabel,leftTopLabel].sort().join('')}</div>
                        <div className="podulator-receipt--cross-entry-percent">{formatPercentHundreth(data[0][0].percent)}</div>
                        <div className="podulator-receipt--cross-entry-count">{data[0][0].count}</div>
                    </td>
                    <td>
                        <div className="podulator-receipt--cross-entry-label">{[topRightLabel,leftTopLabel].sort().join('')}</div>
                        <div className="podulator-receipt--cross-entry-percent">{formatPercentHundreth(data[0][1].percent)}</div>
                        <div className="podulator-receipt--cross-entry-count">{data[0][1].count}</div>
                    </td>
                </tr>
                <tr>
                    <th scope="row">{leftBottomLabel}</th>
                    <td>
                        <div className="podulator-receipt--cross-entry-label">{[topLeftLabel,leftBottomLabel].sort().join('')}</div>
                        <div className="podulator-receipt--cross-entry-percent">{formatPercentHundreth(data[1][0].percent)}</div>
                        <div className="podulator-receipt--cross-entry-count">{data[1][0].count}</div>
                    </td>
                    <td>
                        <div className="podulator-receipt--cross-entry-label">{[topRightLabel,leftBottomLabel].sort().join('')}</div>
                        <div className="podulator-receipt--cross-entry-percent">{formatPercentHundreth(data[1][1].percent)}</div>
                        <div className="podulator-receipt--cross-entry-count">{data[1][1].count}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
