import {call, takeEvery} from 'redux-saga/effects'
import {
    SET_DOGMA_DASH_MAX_CELL_MEMBRANES,
    SET_DOGMA_DASH_MAX_GOLGI_BODIES,
    SET_DOGMA_DASH_MAX_LYSOSOMES,
    SET_DOGMA_DASH_MAX_MICROTUBULES,
    SET_DOGMA_DASH_MAX_MITOCHONDRIA,
    SET_DOGMA_DASH_MAX_MRNA,
    SET_DOGMA_DASH_MAX_RIBOSOMES
} from "../../../store/curriculumTaskItems/dogmaDash/actions";
import {sendGraphQLSaga} from "../../DataService";

const setMaxOrganellesQuery = `mutation setMaxDogmaDashOrganelles($input: SetMaxDogmaDashOrganellesInput!) {
    setMaxDogmaDashOrganelles(input: $input) {
        dogmaDash {
            id
        }
    }
}`;

function* executeSetMaxOrganelles(action) {
    const organelle = action.type === SET_DOGMA_DASH_MAX_MRNA
        ? "MrnaSlot"
        : action.type === SET_DOGMA_DASH_MAX_MITOCHONDRIA
        ? "Mitochondria"
        : action.type === SET_DOGMA_DASH_MAX_CELL_MEMBRANES
        ? "CellMembrane"
        : action.type === SET_DOGMA_DASH_MAX_LYSOSOMES
        ? "Lysosome"
        : action.type === SET_DOGMA_DASH_MAX_GOLGI_BODIES
        ? "GolgiBody"
        : action.type === SET_DOGMA_DASH_MAX_RIBOSOMES
        ? "Ribosome"
        : action.type === SET_DOGMA_DASH_MAX_MICROTUBULES
        ? "Microtubule"
        : "";

    const variables = {
        input: {
            dogmaDash: action.dogmaDashId,
            organelleType: organelle,
            maxNumber: action.number,
            curriculum: action.curriculum,
        }
    };

    yield call(sendGraphQLSaga, setMaxOrganellesQuery, variables);
}

export default [
    takeEvery(SET_DOGMA_DASH_MAX_CELL_MEMBRANES, executeSetMaxOrganelles),
    takeEvery(SET_DOGMA_DASH_MAX_GOLGI_BODIES, executeSetMaxOrganelles),
    takeEvery(SET_DOGMA_DASH_MAX_LYSOSOMES, executeSetMaxOrganelles),
    takeEvery(SET_DOGMA_DASH_MAX_MITOCHONDRIA, executeSetMaxOrganelles),
    takeEvery(SET_DOGMA_DASH_MAX_MRNA, executeSetMaxOrganelles),
    takeEvery(SET_DOGMA_DASH_MAX_RIBOSOMES, executeSetMaxOrganelles),
    takeEvery(SET_DOGMA_DASH_MAX_MICROTUBULES, executeSetMaxOrganelles),
];

