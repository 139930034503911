import {call, put, select, takeEvery} from 'redux-saga/effects'
import {getLoadingLearner} from "../../../store/dataAccess/selectors";
import {sendGraphQLSaga} from "../../DataService";
import {startLoadingLearner} from "../../../store/dataAccess/actions";
import {getVerdantLearnerQuery, getVerdantLearnerVariables} from "../../queries/verdantLearner";
import {curriculaLoaded} from "../../../store/curricula/actions";
import {
    SET_AUTHENTICATION_STATE,
    SET_LTI_KEY,
    setAuthenticationState,
    setLearnerData,
    TRY_AUTHENTICATE
} from "../../../store/identity/actions";
import {addPartnerToLearner, partnerRequestLoaded} from "../../../store/learners/actions";
import {selectActiveLearner} from "../../../store/navigation/actions";
import {getAuthenticationAs} from "../../../store/identity/selectors";
import {LEARNER, NOT_LOGGED_IN} from "../../../constants/AuthenticationState";
import {getDataClientSaga} from "../../../store/data/selectors";

function* executeGetLearner(action) {
    const currentlyLoading = yield select(getLoadingLearner);
    if(currentlyLoading) {return;}

    // TODO: need to double check that the person has the correct role here...
    let authenticatedAs = action.authenticateAs;
    if(!authenticatedAs) {
        authenticatedAs = yield select(getAuthenticationAs);
    }
    if(action.type === TRY_AUTHENTICATE) {
        const client = yield call(getDataClientSaga);
        if(!client) {
            return;
        }
    }

    if(authenticatedAs !== LEARNER) {return;}

    yield put(startLoadingLearner("authenticated"));
    const learnerData = yield call(sendGraphQLSaga, getVerdantLearnerQuery, getVerdantLearnerVariables(null));
    if(!learnerData || !learnerData.verdantLearner) {return;}

    const {id, givenName, familyName, email, cohort, groups, partnershipRequests} = learnerData.verdantLearner;

    yield put(curriculaLoaded(cohort.curricula));
    yield put(setLearnerData(id, givenName, familyName, email));

    const cohortLearners = cohort.learners;
    for(let i = 0; i < cohortLearners.length; ++i) {
        const {givenName, familyName, email, id} = cohortLearners[i];
        yield put(setLearnerData(id, givenName, familyName, email));
    }
    for(let i = 0; i < groups.length; ++i) {
        const curriculum = groups[i].curriculum.id;
        let learners = groups[i].learners;
        for(let j = 0; j < learners.length; ++j) {
            yield put(addPartnerToLearner(id, curriculum, learners[j].id));
        }
    }
    for(let i = 0; i < partnershipRequests.length; ++i) {
        let request = partnershipRequests[i];
        const curriculum = request.curriculum.id;
        yield put(partnerRequestLoaded(curriculum, request.requester.id, request.partner.id));
    }

    yield put(selectActiveLearner(id));

    // this is where I could change the entire learner subscription over to using a saga - TODO!
    // ALSO - if getting user data this way for teacher, do not subscribe?
    // if(subscribe) {
    // const subscriptionClient = yield select(getSubscriptionClient);
    //
    // learnerEvent(client, dispatch, id);
    // }
}

// const learnerSubscriptionChannel = (subscriptionClient, learnerId) => eventChannel(emitter => {
//     const subscription = subscriptionClient.subscribe({
//         query: gql`${learnerEventSubscription}`
//     })
// });

export default [
    takeEvery(SET_LTI_KEY, executeGetLearner),
    takeEvery(SET_AUTHENTICATION_STATE, executeGetLearner),
    takeEvery(TRY_AUTHENTICATE, executeGetLearner),
];
