import "./menu.scss";
import React, {useEffect, useState} from 'react';
import classNames from 'class-names';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Link, useMatch, useNavigate, useParams} from "@reach/router";
import {
    getCurriculumTaskItemIds,
    getCurriculumTaskTitle,
    getCurriculumTaskType
} from "../../store/curriculumTask/selectors";
import {
    getCurriculumCaseDescription,
    getCurriculumCaseTasks,
    getNumberOfCurriculumCases,
    getTasksInCaseWithoutWorkBoard
} from "../../store/curriculumCases/selectors";
import {getCurriculumTaskIds, getGlobalTaskId} from "../../store/curricula/selectors";
import {DOGMA_DASH, INVENTORY_TASK, SCROLLING_TASK} from "../../constants/TaskTypes";
import {getCurriculumTaskItemType} from "../../store/curriculumTaskItems/selectors";
import {setGlobalInteractiveOpen} from "../../store/navigation/actions";

const getCases = (curriculumId, state) => Array.from({ length: getNumberOfCurriculumCases(curriculumId, state) }, (_, i) => i);
const getAllTasks = curriculumId => state => getCurriculumTaskIds(state, curriculumId).filter(t => getCurriculumTaskType(t, state) === SCROLLING_TASK);
const getTaskTitles = taskIds => state => taskIds.map(t => getCurriculumTaskTitle(state, t));
const getFirstTask = (state, curriculumId, caseIndexes) => {
    let actualFirst;
    for(let i = 0; i < caseIndexes.length; ++i) {
        const caseTasks = getTasksInCaseWithoutWorkBoard(curriculumId, caseIndexes[i], state);
        if(!actualFirst) {
            actualFirst = caseTasks[0];
        }
        if(caseTasks.length) {
            return caseTasks[0];
        }
    }
    return actualFirst;
}
const getGlobalInteractives = (state, curriculumId) => {
    const globalTask = getGlobalTaskId(state, curriculumId);
    if(!globalTask) { return []; }
    return getCurriculumTaskItemIds(state, globalTask)
        .filter(i => getCurriculumTaskItemType(state, i) !== INVENTORY_TASK);
};

const taskItems = (tasks, taskTitles, selectedTask) => tasks.map((id, i) =>
    <Link to={`../${id}`} key={id} className={classNames({
        "learner-curriculum-menu--task": true,
        "learner-curriculum-menu--task__selected": id === selectedTask,
        "learner-curriculum-menu--task__complete": false,
    })}>
        <div className="learner-curriculum-menu--task-status" />
        <div className="learner-curriculum-menu--task-title">{taskTitles[i]}</div>
    </Link>);

const typeToLabel = {
    [DOGMA_DASH]: "Dogma Dash",
};
const GlobalInteractive = ({id}) => {
    const dispatch = useDispatch();
    const type = useSelector(state => getCurriculumTaskItemType(state, id));

    const setOpen = () => dispatch(setGlobalInteractiveOpen(id));

    return (
        <div className="learner-curriculum-menu--global-interactive" onClick={setOpen}>{typeToLabel[type]}</div>
    )
}

export default () => {
    const {curriculumId, taskId} = useParams();
    const caseIndexes = useSelector(getCases.bind(null, curriculumId), shallowEqual);
    const firstTask = useSelector(state => getFirstTask(state, curriculumId, caseIndexes));
    const allTasks = useSelector(getAllTasks(curriculumId), shallowEqual);
    const allTaskTitles = useSelector(getTaskTitles(allTasks), shallowEqual);
    const globalInteractives = useSelector(state => getGlobalInteractives(state, curriculumId), shallowEqual);
    const [expanded, setExpanded] = useState(expanded);
    const navigate = useNavigate();
    const isPreview = useMatch('/admin/preview/*');
    useEffect(() => {
        const prefix = isPreview ? "/admin/preview" : "/learn";
        if(!taskId && firstTask) {
            navigate(`${prefix}/${curriculumId}/${firstTask}`, {replace: true});
        } else if(!taskId && allTasks[0]) {
            navigate(`${prefix}/${curriculumId}/${allTasks[0]}`, {replace: true});
        }
    });

    const toggleExpand = () => setExpanded(e => !e);

    return (
        <div className={classNames({
            "learner-curriculum-menu--container": true,
            "learner-curriculum-menu--container__expanded": expanded,
        })}>
            <button className="learner-curriculum-menu--toggle-expand" onClick={toggleExpand} />
            {globalInteractives.map(i => <GlobalInteractive key={i} id={i} />)}
            {caseIndexes.length > 0 && caseIndexes.map(i => <Case key={i} index={i} />)}
            {caseIndexes.length === 0 && taskItems(allTasks, allTaskTitles, taskId)}
        </div>
    )
}

const getCaseTasks = (curriculumId, index, state) => getCurriculumCaseTasks(curriculumId, index, state)
    .filter(t => getCurriculumTaskType(t, state) === SCROLLING_TASK);
const Case = props => {
    const {index} = props;
    const {curriculumId, taskId} = useParams();
    const description = useSelector(getCurriculumCaseDescription.bind(null, curriculumId, index), shallowEqual);
    const tasks = useSelector(getCaseTasks.bind(null, curriculumId, index), shallowEqual);
    const taskTitles = useSelector(getTaskTitles(tasks), shallowEqual);

    return (
        <div className="learner-curriculum-menu--case">
            <div className="learner-curriculum-menu--case-container">
                <div className={classNames({
                    "learner-curriculum-menu--case-label": true,
                    "learner-curriculum-menu--case-label__selected": tasks.includes(taskId),
                })}>
                    <div className="learner-curriculum-menu--case-label-top">case</div>
                    <div className="learner-curriculum-menu--case-label-number">{index+1}</div>
                </div>
                <div className="learner-curriculum-menu--case-description">
                    {description}
                </div>
            </div>
            {taskItems(tasks, taskTitles, taskId)}
        </div>
    );
}
