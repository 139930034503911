import {
    FLOWER_COLOR,
    FLOWER_POSITION,
    HEIGHT,
    POD_COLOR,
    POD_SHAPE,
    SEED_COLOR,
    SEED_SHAPE
} from "../../constants/TraitNames";

export const peaPlantFragment = `
id
name
${HEIGHT} {
    AlleleA
    AlleleB
}
${FLOWER_COLOR} {
    AlleleA
    AlleleB
}
${FLOWER_POSITION} {
    AlleleA
    AlleleB
}
${SEED_COLOR} {
    AlleleA
    AlleleB
}
${SEED_SHAPE} {
    AlleleA
    AlleleB
}
${POD_COLOR} {
    AlleleA
    AlleleB
}
${POD_SHAPE} {
    AlleleA
    AlleleB
}
parentA {
    id
    name
}
parentB {
    id
    name
}`;

export const plantCardFragment = `
id
cardDisplayMode
displayTrait
peaPlant {
    ${peaPlantFragment}
}`;
