import './interactables.scss';
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    CENTRAL_DOGMA_WIDGET,
    CPR, DOGMA_DASH,
    DRAGGABLE_BANK,
    INPUT_TABLE, INVENTORY_TASK,
    LONG_TEXT_INPUT, MRNA_ACTIVITY,
    MULTIPLE_CHOICE_INPUT, OBJECT_SCANNING_TASK,
    SCROLLER_EXPLAINER, SCROLLER_EXPLAINER_PREDICTION, UTILITY_BUTTON,
    WORK_BOARD_DEFINITION
} from "../../../../constants/TaskTypes";
import WorkBoard from "./WorkBoard";
import LongTextInput from "./LongTextInput";
import MultipleChoiceInput from "./MultipleChoiceInput";
import {removeInteractableFromTask} from "../../../../store/curriculumTaskItems/actions";
import InputTable from "./InputTable";
import DraggableBank from "./DraggableBank";
import CPRView from './CPR';
import Explainer from "../Types/ScrollingItems/Explainer";
import ObjectScanningTask from "./ObjectScanningTask";
import InventoryTask from './InventoryTask';
import {getCurriculumTaskItemType} from "../../../../store/curriculumTaskItems/selectors";
import ExplainerPrediction from "../Types/ScrollingItems/ExplainerPrediction";
import MrnaActivity from "./MrnaActivity";
import DogmaDash from './DogmaDash';
import CentralDogmaWidget from "./CentralDogmaWidget";
import UtilityButton from "./UtilityButton";

const interactableMap = {
    [WORK_BOARD_DEFINITION]: WorkBoard,
    [LONG_TEXT_INPUT]: LongTextInput,
    [MULTIPLE_CHOICE_INPUT]: MultipleChoiceInput,
    [INPUT_TABLE]: InputTable,
    [DRAGGABLE_BANK]: DraggableBank,
    [CPR]: CPRView,
    [SCROLLER_EXPLAINER]: Explainer,
    [SCROLLER_EXPLAINER_PREDICTION]: ExplainerPrediction,
    [OBJECT_SCANNING_TASK]: ObjectScanningTask,
    [INVENTORY_TASK]: InventoryTask,
    [MRNA_ACTIVITY]: MrnaActivity,
    [DOGMA_DASH]: DogmaDash,
    [CENTRAL_DOGMA_WIDGET] : CentralDogmaWidget,
    [UTILITY_BUTTON]: UtilityButton,
};

export default props => {
    const {curriculum, task, id} = props;
    const type = useSelector(state => getCurriculumTaskItemType(state, id));
    const dispatch = useDispatch();

    const Tag = interactableMap[type];

    const removeInteractable = () => dispatch(removeInteractableFromTask(curriculum, task, id));

    return (
        <div className="admin-task--interactable">
            <button className="interactable--remove" onClick={removeInteractable}/>
            <Tag {...props} />
        </div>
    )
}
