export const ADD_CASE_TO_CURRICULUM = "Add case to curricula";
export const addCaseToCurriculum = curriculumId => ({
    type: ADD_CASE_TO_CURRICULUM,
    curriculumId,
});

export const REMOVE_CASE_FROM_CURRICULUM = "Remove case from curricula";
export const removeCaseFromCurriculum = (curriculumId, caseIndex) => ({
    type: REMOVE_CASE_FROM_CURRICULUM,
    curriculumId,
    caseIndex,
});

export const SET_CURRICULUM_CASE_DESCRIPTION = "Set curricula case description";
export const setCurriculumCaseDescription = (curriculumId, caseIndex, description) => ({
    type: SET_CURRICULUM_CASE_DESCRIPTION,
    curriculumId,
    caseIndex,
    description,
});

export const ADD_TASK_TO_CURRICULUM_CASE = "Add task to curricula case";
export const addTaskToCurriculumCase = (curriculumId, caseIndex, taskId) => ({
    type: ADD_TASK_TO_CURRICULUM_CASE,
    curriculumId,
    caseIndex,
    taskId,
});

export const REMOVE_TASK_FROM_CURRICULUM_CASE = "Remove task from curricula case";
export const removeTaskFromCurriculumCase = (curriculumId, caseIndex, taskId) => ({
    type: REMOVE_TASK_FROM_CURRICULUM_CASE,
    curriculumId,
    caseIndex,
    taskId,
});

export const REORDER_TASK_IN_CURRICULUM_CASE = "Reorder task in curriculum case";
export const reorderTaskInCurriculumCase = (curriculumId, caseIndex, taskId, newTaskIndex) => ({
    type: REORDER_TASK_IN_CURRICULUM_CASE,
    curriculumId,
    caseIndex,
    taskId,
    newTaskIndex,
});
