import React from 'react';
import { Router } from "@reach/router";
import Login from './Identity';
import Learn from './Learn';
import Teach from './Teach';
import Landing from './Landing';
import Admin from './Admin';
import {useDispatch} from "react-redux";
import {escPressed} from "./store/navigation/actions";
import LtiDeepLink from "./lti-deep-link";

export default () => {
    const dispatch = useDispatch();
    document.addEventListener('keydown', e => {
        if(e.code === 'Escape' || e.keyCode === 27 || e.which === 27) {
            dispatch(escPressed());
        }
    });

    return (
        <div className='app-root' >
            <div className="content-container">
                <Router className="router">
                    <Landing path="/"/>
                    <Login path="login/*" />
                    <Learn path="learn/*" />
                    <Teach path="teach/*" />
                    <Admin path="admin/*" />
                    <LtiDeepLink path="lti-curriculum-selector/*" />
                </Router>
            </div>
        </div>
    );
}
