import {call, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {getActiveLearner} from "../../../store/navigation/selectors";
import {sendGraphQLSaga} from "../../DataService";
import {CREATE_WORK_BOARD_FOR_DEFINITION} from "../../../store/interactions/actions";
import {
    ADD_ITEM_TO_BOARD_ACTION,
    CLONE_PLANT_CARD_AS_PHENOTYPE,
    CLONE_WORK_BOARD_ITEM,
    SET_ITEM_POSITION_ACTION
} from "../../../store/workBoards/actions";
import {PUNNETT_SQUARE, STICKY_NOTE, WORK_BOARD} from "../../../constants/WorkBoardItemTypes";
import {PLANT_CARD} from "../../../constants/DropItemTypes";
import {getPeaPlantCardPlantId} from "../../../store/peaPlantCards/selectors";
import {executeCreateStickyNote, executeSetStickyNoteText} from "./stickyNote";
import {getStickyNoteText} from "../../../store/stickyNotes/selectors";
import {
    executeCreatePunnettSquare,
    executeSetPunnettSquareEntry,
    executeSetPunnettSquarePlant,
    executeSetPunnettSquareTrait
} from "./punnettSquare";
import {
    getPunnettSquareLeftPlant,
    getPunnettSquareTopPlant,
    getPunnettSquareTraits
} from "../../../store/punnettSquares/selectors";
import {PHENOTYPE} from "../../../constants/peaPlantCardDisplayModes";
import {getLearnerItemType} from "../../../store/learnerItems/selectors";
import {REMOVE_LEARNER_ITEM} from "../../../store/learnerItems/actions";
import {getBoardItemIds, getDropAreaType, getWorkBoard} from "../../../store/workBoards/selectors";
import {WORK_BOARD_CROSS_CARD, WORK_BOARD_DROP_SLOT} from "../../../constants/TaskTypes";
import {setCrossCardCards} from "./crossCard";
import {setDropAreaDraggableItems} from "./draggableDropped";

const setWorkBoardForDefinition = `mutation SetWorkBoardDefinitionLearnerWorkBoard($input: SetWorkBoardDefinitionLearnerWorkBoardInput!) {
    setWorkBoardDefinitionLearnerWorkBoard(input: $input) {
        interaction {
            workBoard {
                id
            }
        }
    }
}`;

function* assignWorkBoard(action) {
    const learnerId = yield select(state => getActiveLearner(state));
    if(!learnerId) { return; }
    const variables = {
        input: {
            learner: learnerId,
            curriculum: action.curriculumId,
            interactable: action.workBoardDefinitionId,
            workBoard: action.workBoardId,
        },
    };

    yield call(sendGraphQLSaga, setWorkBoardForDefinition, variables);
}

// TODO: call when items are moved. - this needs to use a different mutation.
const setWorkBoardItemsOrder = `mutation SetWorkBoardItemsOrder($input: SetWorkBoardItemsOrderInput!) {
    setWorkBoardItemsOrder(input: $input) {
        workBoard {
            id
        }
    }
}`;
function* moveItem(action) {
    const learnerId = yield select(state => getActiveLearner(state));
    if(!learnerId) { return; }

    yield call(updateWorkBoardItemOrder, action.boardId);

    if(action.startingLocation && action.startingLocation === action.boardId) { return; }
    const itemType = yield select(state => getDropAreaType(state, action.startingLocation));
    if(itemType === WORK_BOARD) {
        yield call(updateWorkBoardItemOrder, action.startingLocation);
    } else if(itemType === WORK_BOARD_DROP_SLOT) {
        yield call(setDropAreaDraggableItems, action.startingLocation, learnerId, action.curriculumId);
    } else if(itemType === WORK_BOARD_CROSS_CARD) {
        yield call(setCrossCardCards, action.startingLocation, learnerId, action.curriculumId);
    }
}
export function* updateWorkBoardItemOrder(workBoardId) {
    const workBoardItems = yield select(state => getBoardItemIds(state, workBoardId));
    const updatedItems = yield select(state => workBoardItems.map(i => ({
        id: i,
        type: getLearnerItemType(i, state),
    })));

    const variables = {
        input: {
            workBoard: workBoardId,
            items: updatedItems,
        },
    };

    yield call(sendGraphQLSaga, setWorkBoardItemsOrder, variables);
}

const addStickyNoteToWorkBoardQuery = `mutation AddStickyNoteToWorkBoard($input: AddStickyNoteToWorkBoardInput!) {
    addStickyNoteToWorkBoard(input: $input) {
        x
    }
}`;
const addPunnettSquareToWorkBoardQuery = `mutation AddPunnettSquareToWorkBoard($input: AddPunnettSquareToWorkBoardInput!) {
    addPunnettSquareToWorkBoard(input: $input) {
        x
    }
}`;
const addPeaPlantCardQuery = `mutation AddPeaPlantCardToWorkBoard($input: AddPeaPlantCardToWorkBoardInput!) {
    addPeaPlantCardToWorkBoard(input: $input) {
        x
    }
}`;
function* addItemToBoard(action) {
    const learnerId = yield select(state => getActiveLearner(state));
    if(!learnerId) { return; }
    yield call(updateWorkBoardItemOrder, action.boardId);
}

const createPlantCardQuery = `mutation CreatePeaPlantCard($input: CreatePeaPlantCardInput!) {
    createPeaPlantCard(input: $input) {
        card {
            id
        }
    }
}`;

function* cloneItem(action) {
    const learnerId = yield select(state => getActiveLearner(state));
    if(!learnerId) { return; }
    const { newId, newWorkBoardId } = action;
    const type = yield select(state => getLearnerItemType(newId, state));
    if(type === PLANT_CARD) {
        const peaPlant = yield select(state => getPeaPlantCardPlantId(state, newId));
        const createVariables = {
            input: {
                id: newId,
                peaPlant,
            }
        };
        yield call(sendGraphQLSaga, createPlantCardQuery, createVariables);
    } else if(type === PUNNETT_SQUARE) {
        yield call(executeCreatePunnettSquare, {id: newId});
        const leftPlant = yield select(state => getPunnettSquareLeftPlant(state, newId));
        yield call(executeSetPunnettSquarePlant, {id: newId, plantId: leftPlant, isOnLeft: true});
        const topPlant = yield select(state => getPunnettSquareTopPlant(state, newId));
        yield call(executeSetPunnettSquarePlant, {id: newId, plantId: topPlant, isOnLeft: false});
        const trait = yield select(state => getPunnettSquareTraits(state, newId)[0]);
        yield call(executeSetPunnettSquareTrait, {id: newId, trait});
        for(let i = 0; i < 4; ++i) {
            const x = Math.floor(i/2);
            const y = i % 2;
            yield call(executeSetPunnettSquareEntry, {id: newId, x, y});
        }
    } else if(type === STICKY_NOTE) {
        yield call(executeCreateStickyNote, {id: newId});
        const text = yield select(state => getStickyNoteText(state, newId));
        yield call(executeSetStickyNoteText, {id: newId, text});
    }
    yield call(updateWorkBoardItemOrder, newWorkBoardId);
}

function* clonePlantCardAsPhenotype(action) {
    const learnerId = yield select(state => getActiveLearner(state));
    if(!learnerId) { return; }
    const { newId, workBoardId, trait } = action;

    const peaPlant = yield select(state => getPeaPlantCardPlantId(state, newId));
    const createVariables = {
        input: {
            id: newId,
            peaPlant,
            cardDisplayMode: PHENOTYPE,
            displayTrait: trait,
        },
    };
    yield call(sendGraphQLSaga, createPlantCardQuery, createVariables);

    yield call(updateWorkBoardItemOrder, workBoardId);
}

export const removeItemFromWorkBoardQuery = `mutation RemoveItemFromWorkBoard($input: RemoveItemFromWorkBoardInput!) {
    removeItemFromWorkBoard(input: $input) {
        workBoard {
            id
        }
    }
}`;

function *removeItem(action) {
    const workBoard = yield select(state => getWorkBoard(state, action.currentLocation))
    if(!workBoard) { return; } // TODO: drop areas, cross cards.
    const learnerId = yield select(state => getActiveLearner(state));
    if(!learnerId) { return; }

    const variables = {
        input: {
            workBoard: action.currentLocation,
            item: action.itemId,
        },
    };

    yield call(sendGraphQLSaga, removeItemFromWorkBoardQuery, variables);
}

export default [
    takeEvery(CREATE_WORK_BOARD_FOR_DEFINITION, assignWorkBoard),
    takeLatest(SET_ITEM_POSITION_ACTION, moveItem),
    takeEvery(ADD_ITEM_TO_BOARD_ACTION, addItemToBoard),
    takeEvery(CLONE_WORK_BOARD_ITEM, cloneItem),
    takeEvery(CLONE_PLANT_CARD_AS_PHENOTYPE, clonePlantCardAsPhenotype),
    takeEvery(REMOVE_LEARNER_ITEM, removeItem),
];
