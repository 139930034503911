import {CPR} from "../../../constants/TaskTypes";
import {SET_CPR_ASSESSMENT} from "./actions";
import {ADD_INTERACTABLE_TO_TASK, REMOVE_INTERACTABLE_FROM_TASK} from '../actions';
import {omit} from 'lodash';
import {CURRICULA_LOADED} from "../../curricula/actions";

const defaultItem = {
    assessment: false,
};

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const newCPRs = action.curricula.reduce((agg, c) => ({
            ...agg,
            ...(c.tasks || []).reduce((taskAgg, t) => ({
                ...taskAgg,
                ...t.items.filter(i => i.__typename === CPR).reduce((itemsAgg, i) => ({
                    ...itemsAgg,
                    [i.id]: {
                        ...defaultItem,
                        ...omit(i, '__typename'),
                    },
                }), {}),
            }), {}),
        }), {});
        return {
            ...state,
            ...newCPRs,
        };
    }
    if(action.type === ADD_INTERACTABLE_TO_TASK && action.interactableType === CPR) {
        return {
            ...state,
            [action.interactableId]: {
                ...defaultItem,
            },
        };
    }
    if(action.type === REMOVE_INTERACTABLE_FROM_TASK) {
        return omit(state, action.interactableId);
    }
    if(action.type === SET_CPR_ASSESSMENT) {
        return {
            ...state,
            [action.cprId]: {
                ...state[action.cprId],
                assessment: action.assessment,
            },
        };
    }
    return state;
};
