import {DOGMA_DASH_ALERT_LIST} from "./dogma-strand-1";
import {
    getCurrentDogmaDashLevel,
    getDogmaDashAvailableProteins,
    getDogmaDashLevelUnlockingProtein,
    getDogmaLysosomeIds,
    getDogmaMrnaSlotIds,
    getObjectsInOrganelle,
    getObjectType,
    getOrganelleTimerStart,
    getOrganelleTimerType
} from "../../interactions/dogmaDash/selectors";
import {Recycle} from "../../../constants/DogmaDash";
import {mrnaInSlot} from "./utility";
import {MRNA_INVENTORY_PROTEIN_TUTORIAL} from "./dogma-strand-antibody-unlock";

export default {
    id: "66a0de20-283b-418c-afa3-ae3e99406506",
    items: [
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== DOGMA_DASH_ALERT_LIST) {return false;}
                return !getDogmaDashLevelUnlockingProtein(state, dogmaDashId)
                    && getDogmaDashAvailableProteins(state, dogmaDashId).length === 2
                    && getDogmaLysosomeIds(state, dogmaDashId).some(o => getOrganelleTimerStart(state, o) && getOrganelleTimerType(state, o) === "Alert")
                    && getCurrentDogmaDashLevel(state, dogmaDashId) === 2;
            },
            header: "New Alert Type",
            text: "The lysosome is unable to recycle debris. Replenish its enzyme.",
            forceCompletion: (state, {dogmaDashId}) => mrnaInSlot(state, dogmaDashId, Recycle),
        },
        {
            condition: (state, {location, dogmaDashId, protein}) => location === MRNA_INVENTORY_PROTEIN_TUTORIAL && protein === Recycle,
            width: 460,
            header: "Send mRNA",
            text: "The Recycler Enzyme needs to be delivered to the lysosome so it can continue to break down debris in the cell.",
            callToAction: "Send the mRNA to the pilot.",
            completionCondition: (state, {dogmaDashId}) => {
                return getDogmaMrnaSlotIds(state, dogmaDashId).some(s => getObjectsInOrganelle(state, s).filter(o => getObjectType(state, o) === Recycle).length > 0);
            },
        },
    ],
};
