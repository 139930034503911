import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getMaxCellMembranes, getMaxGolgiBodies, getMaxLysosomes, getMaxMicrotubules,
    getMaxMitochondria,
    getMaxMrnaSlots, getMaxRibosomes
} from "../../../../store/curriculumTaskItems/dogmaDash/selectors";
import {
    setDogmaDashMaxCellMembranes, setDogmaDashMaxGolgiBodies, setDogmaDashMaxLysosomes, setDogmaDashMaxMicrotubules,
    setDogmaDashMaxMitochondria,
    setDogmaDashMaxMrna, setDogmaDashMaxRibosomes
} from "../../../../store/curriculumTaskItems/dogmaDash/actions";

export default ({curriculum, id}) => {
    const dispatch = useDispatch();
    const maxMrnaSlots = useSelector(state => getMaxMrnaSlots(state, id));
    const maxMitochondria = useSelector(state => getMaxMitochondria(state, id));
    const maxCellMembranes = useSelector(state => getMaxCellMembranes(state, id));
    const maxRibosomes = useSelector(state => getMaxRibosomes(state, id));
    const maxLysosomes = useSelector(state => getMaxLysosomes(state, id));
    const maxGolgiBodies = useSelector(state => getMaxGolgiBodies(state, id));
    const maxMicrotubules = useSelector(state => getMaxMicrotubules(state, id));

    const setMax = (actionCreator, currentValue) => e => {
        const newMax = Number.parseInt(e.target.value);
        if(!e.target.value || Number.isNaN(newMax)) {
            e.target.value = currentValue;
            return;
        }
        dispatch(actionCreator(id, newMax, curriculum));
    }

    return (
        <div>
            <h2>Dogma Dash</h2>
            <div>
                <span>Max mRNA slots:</span>
                <input type="number" defaultValue={maxMrnaSlots} onBlur={setMax(setDogmaDashMaxMrna, maxMrnaSlots)} />
            </div>
            <div>
                <span>Max mitochondria:</span>
                <input type="number" defaultValue={maxMitochondria} onBlur={setMax(setDogmaDashMaxMitochondria, maxMitochondria)} />
            </div>
            <div>
                <span>Max cell membrane slots:</span>
                <input type="number" defaultValue={maxCellMembranes} onBlur={setMax(setDogmaDashMaxCellMembranes, maxCellMembranes)} />
            </div>
            <div>
                <span>Max ribosomes:</span>
                <input type="number" defaultValue={maxRibosomes} onBlur={setMax(setDogmaDashMaxRibosomes, maxRibosomes)} />
            </div>
            <div>
                <span>Max lysosomes:</span>
                <input type="number" defaultValue={maxLysosomes} onBlur={setMax(setDogmaDashMaxLysosomes, maxLysosomes)} />
            </div>
            <div>
                <span>Max golgi body slots:</span>
                <input type="number" defaultValue={maxGolgiBodies} onBlur={setMax(setDogmaDashMaxGolgiBodies, maxGolgiBodies)} />
            </div>
            <div>
                <span>Max Microtubules:</span>
                <input type="number" defaultValue={maxMicrotubules} onBlur={setMax(setDogmaDashMaxMicrotubules, maxMicrotubules)} />
            </div>
        </div>
    )
}
