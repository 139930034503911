import "./draggable-bank.scss";
import React from 'react';
import {shallowEqual, useSelector} from "react-redux";
import RichTextDisplay from "../RichTextDisplay";
import Draggable from "./Draggable";
import {
    getDraggableBankDraggables,
    getDraggableBankPrompt
} from "../../../store/curriculumTaskItems/draggableBank/selectors";
import {getDropAreasForDraggable} from "../../../store/interactions/selectors";

const getDraggables = (state, bankId) => getDraggableBankDraggables(state, bankId).filter(d => getDropAreasForDraggable(state, d).length === 0);

export default ({id}) => {
    const prompt = useSelector(state => getDraggableBankPrompt(state, id));
    const draggables = useSelector(state => getDraggables(state, id), shallowEqual);

    return (
        <div className="learner-curriculum-task--draggable-bank">
            { prompt.length > 0 &&
            <div className="learner-draggable-bank--prompt-container">
                <RichTextDisplay content={prompt}/>
            </div>
            }
            <div className="learner-draggable-bank--draggable-container">
                {draggables.map(d => <Draggable key={d} id={d} />)}
            </div>
        </div>
    );
}
