export const PEA_PLANTS_LOADED = "Pea plants loaded";
export const peaPlantsLoaded = plants => ({
    type: PEA_PLANTS_LOADED,
    plants,
});

export const CREATE_PEA_PLANT = "Create pea plant";
export const createPeaPlant = (plantId, traits = {}) => {
    return Object.keys(traits).reduce((agg, traitName) => ({
        ...agg,
        [traitName]: traits[traitName],
    }), {
        type: CREATE_PEA_PLANT,
        plantId,
    });
};

export const SET_PLANT_NAME = "Set plant name";
export const setPlantName = (plantId, name) => ({
    type: SET_PLANT_NAME,
    plantId,
    name
});

export const SET_PLANT_GENOTYPE = "Set plant genotype";
export const setPlantGenotype = (plantId, traitName, genotype) => ({
    type: SET_PLANT_GENOTYPE,
    plantId,
    traitName,
    genotype,
});

