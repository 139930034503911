import "./curriculum-choice.scss";
import React, {useEffect} from 'react';
import {getAllCurriculaIds, getCurriculumName, getCurriculumType} from "../store/curricula/selectors";
import {getCurriculumId} from "../store/curriculum/selectors";
import {shallowUnorderedArrayCompare} from "../utility";
import {getFamilyNameForLearner, getGivenNameForLearner} from "../store/learners/selectors";
import {getActiveLearner} from "../store/navigation/selectors";
import {useDispatch, useSelector} from "react-redux";
import {attemptLogout} from "../Identity/utility";
import {navigate, useParams} from "@reach/router";
import {Link} from "@reach/router";
import {VISCERAL} from "../constants/curriculumTypes";

const stateMap = state => {
    const learnerId = getActiveLearner(state);
    const curriculaIds = getAllCurriculaIds(state);
    return {
        learnerId,
        givenName: getGivenNameForLearner(state, learnerId),
        familyName: getFamilyNameForLearner(state, learnerId),
        verdantV1Id: getCurriculumId(state),
        curriculaIds,
        curriculaNames: curriculaIds.map(c => getCurriculumName(state, c)),
        curriculaTypes: curriculaIds.map(c => getCurriculumType(state, c)),
    };
};
const getVisceralId = state => getAllCurriculaIds(state).find(c => getCurriculumType(state, c) === VISCERAL);

const compare = (a, b) => {
    if(a.learnerId !== b.learnerId) { return false; }
    if(a.verdantV1Id !== b.verdantV1Id) { return false; }
    return shallowUnorderedArrayCompare(a.curriculaIds, b.curriculaIds);
};

export default props => {
    const { givenName, familyName, verdantV1Id, curriculaIds, curriculaNames, curriculaTypes } = useSelector(stateMap, compare);
    const dispatch = useDispatch();
    const {curriculumId} = useParams();
    const visceralId = useSelector(state => getVisceralId(state));

    // useEffect(() => {
    //     if(!visceralId || visceralId === curriculumId) { return; }
    //     navigate(`/learn/${visceralId}`);
    // }, [visceralId]);

    if(curriculaIds.length === 1 && !verdantV1Id) {
        props.navigate(`./${curriculaIds[0]}`, {replace: true});
    } else if(verdantV1Id && curriculaIds.length === 0) {
        props.navigate(`./${verdantV1Id}`, {replace: true});
    }

    const doLogout = () => {
        attemptLogout(dispatch);
        navigate("/");
    };

    return (
    <>
        <div className="learner-curriculum-choice--header">
            <h1 className="learner-header--title">VR Science</h1>
            <div className="header--identity">
                <div className="header--identity-name">{`${givenName} ${familyName}`}</div>
                <button className="logout-button" onClick={doLogout}>Logout</button>
            </div>
        </div>
        <div className="learner-curriculum-choice--container">
            {/*{verdantV1Id &&*/}
            {/*<Link key={verdantV1Id} to={`./${verdantV1Id}`} className="learner--curriculum-choice learner--curriculum-choice__verdant">*/}
            {/*    <div className="learner--curriculum-choice-container">*/}
            {/*        <div className="curriculum-choice--name">Verdant V.1</div>*/}
            {/*        <div className="curriculum-choice--type">Verdant</div>*/}
            {/*    </div>*/}
            {/*</Link>}*/}
            {curriculaIds.map((id, i) =>
                <Link key={id} to={`./${id}`} className="learner--curriculum-choice">
                    <div className="learner--curriculum-choice-container">
                        <div className="curriculum-choice--name">{curriculaNames[i]}</div>
                        <div className="curriculum-choice--type">{curriculaTypes[i]}</div>
                    </div>
                </Link>
            )}
        </div>
    </>
    );
}
