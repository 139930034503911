import "./add-curriculum-modal.scss";
import React, {useState} from 'react';
import classNames from 'class-names';
import {useDispatch, useSelector} from "react-redux";
import {
    getCurriculumName,
    getCurriculumParent,
    getCurriculumType,
    getMasterCurriculaIds
} from "../../store/curricula/selectors";
import {shallowUnorderedArrayCompare} from "../../utility";
import {openModalFor} from "../../store/navigation/actions";
import {getDataService} from "../../store/data/selectors";
import {addCurriculumToCohort} from "../../graphql/Cohort";
import {getCohortCurricula} from "../../store/cohorts/selectors";

const stateMap = props => state => {
    const curricula = getMasterCurriculaIds(state);
    const assignedCurriculaParents = getCohortCurricula(state, props.cohortId).map(c => getCurriculumParent(state, c));
    const availableCurricula = curricula.filter(c => !assignedCurriculaParents.includes(c));
    return {
        curricula: availableCurricula,
        names: availableCurricula.map(c => getCurriculumName(state, c)),
        types: availableCurricula.map(c => getCurriculumType(state, c)),
        dataService: getDataService(state),
    };
};

const compare = (a, b) => shallowUnorderedArrayCompare(a.curricula, b.curricula);

export default props => {
    const {cohortId} = props;
    const {curricula, names, types, dataService} = useSelector(stateMap(props), compare);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);

    const close = () => dispatch(openModalFor());
    const toggle = e => {
        if(e.target.checked) {
            setSelected(selected.concat(e.target.value));
        } else {
            setSelected(selected.filter(v => v !== e.target.value));
        }
    };
    const addCurricula = () => {
        setLoading(true);
        let promise = Promise.resolve();
        promise = selected.reduce((p, selectedCurriculum) =>
            promise.then(() => addCurriculumToCohort(dataService, cohortId, selectedCurriculum)),
        promise);
        promise.then(() => close());
    };

    return (
        <div className="add-cohort-curriculum--blocker">
            <div className="add-cohort-curriculum--modal">
                <div className={classNames({
                    "add-cohort-curriculum--container": true,
                    "add-cohort-curriculum--container__hide": loading,
                })}>
                    <div className="add-cohort-curriculum--prompt">Choose curricula to add</div>
                    <div className="add-cohort-curriculum--curriculum-container">
                        {curricula.map((c,i) => (
                        <div className="add-cohort-curriculum--curriculum" key={c}>
                            <input type="checkbox" id={`curriculum-${c}`} className="add-cohort-curriculum--checkbox" value={c} onChange={toggle} />
                            <label htmlFor={`curriculum-${c}`} className="add-cohort-curriculum--label">
                                <span className="add-cohort-curriculum--name">{names[i]}</span>
                                <span className="add-cohort-curriculum--type">{types[i]}</span>
                            </label>
                        </div>
                        ))}
                    </div>
                    <div className="add-cohort-curriculum--button-container">
                        <button onClick={addCurricula} className="add-cohort-curriculum--finish" disabled={selected.length === 0}>Add</button>
                        <button onClick={close} className="add-cohort-curriculum--cancel">Cancel</button>
                    </div>
                </div>
                {loading &&
                <div className="add-cohort-curriculum--loading-container">
                    <div className="add-cohort-curriculum--prompt">{`Adding curricul${selected.length === 1 ? "um" : "a"}`}</div>
                    <div className="load-spinner" />
                </div>}
            </div>
        </div>
    );
}
