import {FLOWER_COLOR, FLOWER_POSITION, HEIGHT, POD_COLOR, POD_SHAPE, SEED_COLOR, SEED_SHAPE} from "./TraitNames";
import {DOMINANT_TRAIT, RECESSIVE_TRAIT} from "./Zygosities";
import {
    AXIAL_FLOWERS, CONSTRICTED_PODS, GREEN_PODS, GREEN_SEEDS, INFLATED_PODS,
    PURPLE_FLOWERS, ROUND_SEEDS,
    SHORT_PLANT,
    TALL_PLANT,
    TERMINAL_FLOWERS,
    WHITE_FLOWERS, WRINKLED_SEEDS, YELLOW_PODS, YELLOW_SEEDS
} from "./PlantPhenotypes";

export default {
    [FLOWER_COLOR]: {
        [DOMINANT_TRAIT]: PURPLE_FLOWERS,
        [RECESSIVE_TRAIT]: WHITE_FLOWERS,
    },
    [FLOWER_POSITION]: {
        [DOMINANT_TRAIT]: AXIAL_FLOWERS,
        [RECESSIVE_TRAIT]: TERMINAL_FLOWERS,
    },
    [HEIGHT]: {
        [DOMINANT_TRAIT]: TALL_PLANT,
        [RECESSIVE_TRAIT]: SHORT_PLANT,
    },
    [SEED_COLOR]: {
        [DOMINANT_TRAIT]: YELLOW_SEEDS,
        [RECESSIVE_TRAIT]: GREEN_SEEDS,
    },
    [SEED_SHAPE]: {
        [DOMINANT_TRAIT]: ROUND_SEEDS,
        [RECESSIVE_TRAIT]: WRINKLED_SEEDS,
    },
    [POD_COLOR]: {
        [DOMINANT_TRAIT]: GREEN_PODS,
        [RECESSIVE_TRAIT]: YELLOW_PODS,
    },
    [POD_SHAPE]: {
        [DOMINANT_TRAIT]: INFLATED_PODS,
        [RECESSIVE_TRAIT]: CONSTRICTED_PODS,
    },
};
