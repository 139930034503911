import './teach.scss';

import React from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {getAuthenticationState, getTeacherId} from "../store/identity/selectors";
import {Router, navigate} from "@reach/router";
import Learner from './Learner';
import Root from './Root';
import {attemptLogout} from "../Identity/utility";
import {NOT_LOGGED_IN} from "../constants/AuthenticationState";

const mapState = state => ({
    authenticationState: getAuthenticationState(state),
    teacherId: getTeacherId(state),
});

export default () => {
    const { authenticationState, teacherId } = useSelector(mapState, shallowEqual);
    const dispatch = useDispatch();

    if(authenticationState === NOT_LOGGED_IN) {
        navigate("/login/teach");
        return null;
    }

    const doLogout = () => {
        attemptLogout(dispatch);
        navigate("/");
    };

    return (
        <div className="teach-container">
            <div className="teach-container--header">
                <div className="header--logo" />
                <h1>Lighthaus Web Station</h1>
                <button className="logout-button" onClick={doLogout}>Logout</button>
            </div>
            {!teacherId && <div>Loading teacher...<div className="load-spinner" /></div>}
            {!!teacherId &&
            <Router className="teach-flex">
                <Root path="/"/>
                <Root path="/cohort/:cohortId"/>
                <Learner path="/cohort/:cohortId/learner/:learnerId" />
                <Learner path="/cohort/:cohortId/learner/:learnerId/:curriculumId" />
                <Learner path="/cohort/:cohortId/learner/:learnerId/:curriculumId/:taskId" />
            </Router>
            }
        </div>
    );
};
