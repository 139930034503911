import React from 'react';
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import WorkBoard from "../WorkBoard";
import {
    getTaskAdditionalText,
    getTaskBounty,
    getTaskDescription,
    getTaskStatus,
    getTaskType,
    getTaskUrl
} from "../../store/curriculum/selectors";
import classNames from "class-names";
import {setCurriculumTaskState} from "../../store/curriculum/actions";
import {COMPLETE, NOT_STARTED} from "../../constants/TaskStatus";
import {getWorkBoardIdForBounty} from "../../store/learners/selectors";
import {getActiveLearner, getTaskHovered} from "../../store/navigation/selectors";
import {endTaskHover, startTaskHover} from "../../store/navigation/actions";

const stateMap = props => state => {
    return {
        description: getTaskDescription(state, props.id),
        url: getTaskUrl(state, props.id),
        complete: getTaskStatus(state, props.id) === COMPLETE,
        activityType: getTaskType(state, props.id),
        workBoardId: getWorkBoardIdForBounty(state, getActiveLearner(state), getTaskBounty(state, props.id)),
        additionalText: getTaskAdditionalText(state, props.id),
        hovered: getTaskHovered(state, props.id),
    };
};

export default React.memo(props => {
    const { description, url, complete, activityType, workBoardId, additionalText, hovered } = useSelector(stateMap(props), shallowEqual);
    const dispatch = useDispatch();

    const isVideo = !!url.match(/youtube.com/);
    const vr = activityType === "VR";
    const additionalTextElements = additionalText || [];

    const markAsDone = () => dispatch(setCurriculumTaskState(props.id, complete ? NOT_STARTED : COMPLETE));
    const startHover = () => dispatch(startTaskHover(props.id));
    const endHover = () => dispatch(endTaskHover(props.id));

    return (
        <div className={classNames({
            "activity-viewport--task": true,
            "activity-viewport--task__highlit": hovered,
        })}>
            {!vr && (
            <div onMouseEnter={startHover} onMouseLeave={endHover} className="task-container">
                <div className={classNames({
                    "task-items": true,
                    "task-items__finished": complete,
                })}>
                    <button onClick={markAsDone} className={classNames({
                        "task-items--finish": true,
                        "task-items--finish__finished": complete,
                    })}>{complete ? "Done" : "Mark as Done"}</button>
                    <div className={classNames({
                        "task-items--content": true,
                        "task-items--content__row": additionalTextElements.length > 0,
                    })}>
                        <div className="task-items--details">
                            {(url && !isVideo) && <a target="_blank" className="task-details--link" href={url}>{description}</a>}
                            {(!url || isVideo) && <div className="task-details--description">{description}</div>}
                            <div className="task-details--additional-text">
                                {additionalTextElements.map((t,i) => <div key={`add-tex-${i}`} className="task-details--additional-text-entry" dangerouslySetInnerHTML={{__html: t}} /> )}
                            </div>
                        </div>
                        {isVideo &&
                        <iframe src={url} frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen className="task-content--video" />
                        }
                    </div>
                    <div className="task-items--complete-message">
                        <div className="task-items--complete-message-start">Complete</div>
                        <div className="task-items--complete-message-end">Web Activity</div>
                    </div>
                </div>
            </div>
            )}
            {workBoardId && <WorkBoard id={workBoardId} />}
        </div>
    )
});
