import {getCurriculumTaskItemIds} from "../../../store/curriculumTask/selectors";
import {call, takeEvery, select} from 'redux-saga/effects';
import {sendGraphQLSaga} from "../../DataService";
import {
    CURRICULUM_ITEM_TO_BOTTOM,
    CURRICULUM_ITEM_TO_TOP,
    REORDER_CURRICULUM_ITEM
} from "../../../store/curriculumTask/actions";

const query = `mutation SetTaskItemOrder($input: SetTaskItemOrderInput!) {
    setTaskItemOrder(input: $input) {
        task {
            id
        }
    }
}`;

function* execute(action) {
    const order = yield select(state => getCurriculumTaskItemIds(state, action.taskId));
    const variables = {
        input: {
            curriculum: action.curriculumId,
            task: action.taskId,
            itemOrder: order,
        },
    };
    yield call(sendGraphQLSaga, query, variables);
}

export default [
    takeEvery(REORDER_CURRICULUM_ITEM, execute),
    takeEvery(CURRICULUM_ITEM_TO_TOP, execute),
    takeEvery(CURRICULUM_ITEM_TO_BOTTOM, execute),
];
