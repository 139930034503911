import "./punnett-square.scss";
import React from 'react';
import {
    getPunnettSquareGenotype,
    getPunnettSquareLeftPlant,
    getPunnettSquareNumberOfRows,
    getPunnettSquareTopPlant,
    getPunnettSquareTraits
} from "../../store/punnettSquares/selectors";
import {useDispatch, useSelector} from "react-redux";
import classNames from "class-names";
import {useDrop} from "react-dnd";
import {PEA_PLANT_CARD} from "../../constants/WorkBoardItemTypes";
import {setPunnettSquarePlant, setPunnettSquareTrait} from "../../store/punnettSquares/actions";
import {HETEROZYGOUS, RECESSIVE} from "../../constants/Zygosities";
import {
    FLOWER_COLOR,
    FLOWER_POSITION,
    HEIGHT,
    POD_COLOR,
    POD_SHAPE,
    SEED_COLOR,
    SEED_SHAPE,
    TRAIT_DISPLAY_NAMES
} from "../../constants/TraitNames";
import {getPeaPlantCardPlantId} from "../../store/peaPlantCards/selectors";
import PunnettSquareEntry from './PunnettSquare/Entry';
import DraggableHeader from "./PunnettSquare/DraggableHeader";
import Questions from "./PunnettSquare/Questions";
import PlantDisplay from "./PlantCard/PlantDisplay";
import {PEA_PLANT_CARD_WIDTH} from "../../style-variables";

const stateMap = props => state => {
    const traits = getPunnettSquareTraits(state, props.id);
    return {
        numberOfRows: getPunnettSquareNumberOfRows(state, props.id),
        leftPlant: getPunnettSquareLeftPlant(state, props.id),
        leftGenotype: getPunnettSquareGenotype(state, props.id, true, traits[0]),
        topPlant: getPunnettSquareTopPlant(state, props.id),
        topGenotype: getPunnettSquareGenotype(state, props.id, false, traits[0]),
        trait: traits[0],
        getPlantCardId: id => getPeaPlantCardPlantId(state, id),
    }
};

const comparison = (a, b) => {
    if(a.numberOfRows !== b.numberOfRows) { return false; }
    if(a.leftPlant !== b.leftPlant) { return false; }
    if(a.leftGenotype !== b.leftGenotype) { return false; }
    if(a.topPlant !== b.topPlant) { return false; }
    if(a.topGenotype !== b.topGenotype) { return false; }
    return a.trait === b.trait;
};

export default React.memo(props => {
    const { numberOfRows, leftPlant, leftGenotype, topPlant, topGenotype, trait, getPlantCardId } = useSelector(stateMap(props), comparison);
    const dispatch = useDispatch();

    const createGenotypeIndicatorForIndex = (genotype, index) => {
        let alleleValue = 0;
        if(genotype === RECESSIVE || (genotype === HETEROZYGOUS && index === 1)) {
            alleleValue = 1;
        }
        return <DraggableHeader alleleValue={alleleValue} trait={trait} />
    };

    const [{cardOverLeft}, leftDropArea] = useDrop({
        accept: [PEA_PLANT_CARD],
        drop: (item, monitor) => {
            if(leftPlant) { return; }
            dispatch(setPunnettSquarePlant(props.id, getPlantCardId(item.itemId), true));
        },
        collect: monitor => {
            return {
                cardOverLeft: monitor.isOver({shallow: true}),
            };
        },
    });

    const [{cardOverTop}, topDropArea] = useDrop({
        accept: [PEA_PLANT_CARD],
        drop: (item, monitor) => {
            if(topPlant) { return; }
            dispatch(setPunnettSquarePlant(props.id, getPlantCardId(item.itemId), false));
        },
        collect: monitor => {
            return {
                cardOverTop: monitor.isOver({shallow: true}),
            };
        },
    });

    const selectTrait = e => dispatch(setPunnettSquareTrait(props.id, e.target.value));
    const removeLeftCard = () => dispatch(setPunnettSquarePlant(props.id, null, true));
    const removeTopCard = () => dispatch(setPunnettSquarePlant(props.id, null));

    const rows = Array.from({length: numberOfRows}, (_, i) => i);
    const width = PEA_PLANT_CARD_WIDTH * 2.5 * (props.scaleFactor || 1);

    return (
        <div className="punnett-square" style={{width}}>
            <div className="punnett-square--trait">
                <label className="punnett-square--trait-label" htmlFor="punnett-square-trait-selection">Predicting Trait:</label>
                <select defaultValue={trait} id="punnett-square-trait-selection" onChange={selectTrait}>
                    <option value={SEED_COLOR}>{TRAIT_DISPLAY_NAMES[SEED_COLOR]}</option>
                    <option value={FLOWER_COLOR}>{TRAIT_DISPLAY_NAMES[FLOWER_COLOR]}</option>
                    <option value={POD_SHAPE}>{TRAIT_DISPLAY_NAMES[POD_SHAPE]}</option>
                    <option value={POD_COLOR}>{TRAIT_DISPLAY_NAMES[POD_COLOR]}</option>
                    <option value={SEED_SHAPE}>{TRAIT_DISPLAY_NAMES[SEED_SHAPE]}</option>
                    <option value={HEIGHT}>{TRAIT_DISPLAY_NAMES[HEIGHT]}</option>
                    <option value={FLOWER_POSITION}>{TRAIT_DISPLAY_NAMES[FLOWER_POSITION]}</option>
                </select>
            </div>
            <table className="punnett-square--square">
                <tbody>
                    <tr className="topPlantCardRow">
                        <td className="punnett-square-corner" colSpan={1} rowSpan={1} />
                        {topPlant
                            ? <th colSpan={2} scope="col" className="punnett-square--allele-header">
                                <button className="punnett-square--remove-top-card" aria-label="Remove top card" onClick={removeTopCard} />
                                <div className="punnett-square--plant-display-container">
                                    <PlantDisplay plantId={topPlant} />
                                </div>
                                <div className="punnett-square--top-genotype-container">
                                    {createGenotypeIndicatorForIndex(topGenotype, 0)}
                                    {createGenotypeIndicatorForIndex(topGenotype, 1)}
                                </div>
                            </th>
                            : <th colSpan={2}
                                ref={topDropArea}
                                className={classNames({
                                    'punnett-square--allele-header': true,
                                    'punnett-square-header__hovered': cardOverTop,
                                })}>
                                <div className="punnett-square--top-header-filler">
                                    <span className="punnett-square--call-out">Drop plant card here</span>
                                </div>
                            </th>
                        }
                    </tr>
                    {rows.map((rowIndex) => (
                        <tr key={`row-${rowIndex}`}>
                            {rowIndex === 0
                                ? leftPlant
                                ? <th rowSpan={2} scope="row" className="punnett-square--allele-header">
                                    <button className="punnett-square--remove-left-card" aria-label="Remove left card" onClick={removeLeftCard}/>
                                        <div className="punnett-square--plant-display-container">
                                            <PlantDisplay plantId={leftPlant} />
                                        </div>
                                    <div className="punnett-square--left-genotype-container">
                                        {createGenotypeIndicatorForIndex(leftGenotype, 0)}
                                        {createGenotypeIndicatorForIndex(leftGenotype, 1)}
                                    </div>
                                </th>
                                :<th ref={leftDropArea}
                                     scope="row"
                                     rowSpan={rows.length}
                                     className={classNames({
                                         'punnett-square--allele-header': true,
                                         'punnett-square-header__hovered': cardOverLeft,
                                     })}>
                                    <div className="punnett-square--left-header-filler">
                                        <span className="punnett-square--call-out">Drop plant card here</span>
                                    </div>
                                </th>
                                : null
                            }
                            {rows.map((colIndex) => (
                                <td key={`${rowIndex}-${colIndex}`}>
                                    {leftPlant && topPlant && <PunnettSquareEntry row={rowIndex} col={colIndex} id={props.id} />}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            {/*<Questions id={props.id} />*/}
        </div>
    )
});
