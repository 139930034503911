import * as styles from './work-board-tray-styles';
import React, {useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {getNumberOfCurriculumCases} from "../../../store/curriculumCases/selectors";
import {useParams} from "@reach/router";
import combineStyles from "../../../Utility/combineStyles";
import CaseContainer from "./CaseContainer";
import PunnettSquare from "../../../WorkBoard/ToolBox/PunnettSquare";
import StickyNote from "../../../WorkBoard/ToolBox/StickyNote";

const WorkBoardTray = React.memo(() => {
    const {curriculumId} = useParams();
    const numberOfCases = useSelector(state => getNumberOfCurriculumCases(curriculumId, state));
    const trayRef = useRef();
    const [selectedCase, setSelectedCase] = useState(0);

    return (
        <div css={styles.tray} ref={trayRef}>
            <div css={styles.trayTitle}>Inventory</div>
            <div css={styles.trayContents}>
                <div css={styles.casesContainer}>
                    <div css={styles.caseSelectionContainer}>
                        {Array.from(new Array(numberOfCases), (_, i) =>
                            <button onClick={() => setSelectedCase(i)} key={i} css={combineStyles(styles, {
                                caseButton: true,
                                caseButtonSelected: selectedCase === i,
                            })}>
                                {`Case ${i+1}`}
                            </button>
                        )}
                    </div>
                    <CaseContainer caseIndex={selectedCase} trayRef={trayRef} />
                </div>
                <div css={styles.resourceContainer}>
                    <div css={styles.tile}>
                        <span css={styles.tileLabel}>Tools</span>
                        <div css={styles.toolsContainer}>
                            <PunnettSquare />
                            <StickyNote />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

WorkBoardTray.displayName = "workBoardTray";
export default WorkBoardTray;
