import {LTI_PLATFORM_REGISTRATION_REQUEST_ANSWERED, REQUEST_LTI_PLATFORM_REGISTRATION} from "./actions";

export const NO_REQUEST = "no request";
export const PENDING_REQUEST = "pending request";
export const SUCCESS = "successful request";
export const FAILURE = "errored request";

const defaultState = {
    requestState: NO_REQUEST,
    errors: [],
};

export default (state = defaultState, action ) => {
    if(action.type === REQUEST_LTI_PLATFORM_REGISTRATION) {
        return {
            ...state,
            errors: [],
            requestState: PENDING_REQUEST,
        };
    }
    if(action.type === LTI_PLATFORM_REGISTRATION_REQUEST_ANSWERED) {
        return {
            ...state,
            requestState: action.errors.length > 0 ? FAILURE : SUCCESS,
            errors: action.errors ?? [],
        };
    }
    return state;
};
