import * as styles from './gene-selection-styles';
import * as alertStyles from '../alert-panel-styles';
import React, {useState} from 'react';
import {css} from "@emotion/core";
import {
    Antibody, CELL_VIEW,
    EnergyProteinRotor,
    EnergyProteinStabilizer,
    Motor,
    Recycle,
    Structural,
    TRANSCRIPTION_VIEW,
    Transport
} from "../../../../../constants/DogmaDash";
import motorCardImage from './Assets/delivery.jpg';
import rotorCardImage from './Assets/energy1.jpg';
import stabilizerCardImage from './Assets/energy2.jpg';
import recyclerCardImage from './Assets/recycler.jpg';
import transportCardImage from './Assets/channel.jpg';
import structuralCardImage from './Assets/strucural.jpg';
import antibodyCardImage from './Assets/antibody.jpg';
import CloseX from '../../../../../inline-assets/close.svg';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    setDogmaDashHighlightedGene,
    setDogmaDashPortalNucleotide,
    setDogmaDashPortalTrna,
    setDogmaDashView
} from "../../../../../store/interactions/dogmaDash/actions";
import {
    getDogmaCellMembraneIds,
    getDogmaDashAvailableProteins,
    getDogmaDashHighlightedGene,
    getDogmaDashLevelUnlockingProtein,
    getDogmaLysosomeIds,
    getDogmaMicrotubuleIds,
    getDogmaMitochondriaIds,
    getOrganelleAlert,
    getOrganelleTimerStart,
    getOrganelleTimerType
} from "../../../../../store/interactions/dogmaDash/selectors";
import {useParams} from "@reach/router";
import {GENE_SELECTION_NUCLEUS} from "../../../../../store/tutorial/configuration/gene-selection-strand1";
import PopUpTutorial from "../../../../Tutorial/PopUpTutorial";
import {AlertIconMap, CellMembrane, Lysosome, Microtubule, Mitochondria} from "../AlertPanel";
import InfoIcon from "../../../../Tutorial/inline-assets/tutorial_info_icon.svg";
import {openModalFor} from "../../../../../store/navigation/actions";
import {FIRST_TIME_TRANSCRIPTION, FIRST_TIME_TRANSLATION} from "../../../../../constants/modalTypes";
import ArrowAsset from "../../../../../inline-assets/right-arrow-1.svg";

const cards = {
    [Motor]: {
        header: "Delivery",
        text: "delivers cellular cargo to export out of the cell",
        image: motorCardImage,
        size: css({width: "65px", height: "65px"}),
        dotPosition: {left: "511px", top: "473px"},
    },
    [EnergyProteinRotor]: {
        header: "Energy Protein 1",
        text: "creates ATP energy molecules",
        image: rotorCardImage,
        size: css({width: "66px", height: "66px"}),
        dotPosition: {left: "345px", top: "423px"},
    },
    [EnergyProteinStabilizer]: {
        header: "Energy Protein 2",
        text: "creates ATP energy molecules",
        image: stabilizerCardImage,
        size: css({width: "69px", height: "69px"}),
        dotPosition: {left: "368px", top: "75px"},
    },
    [Recycle]: {
        header: "Recycler Enzyme",
        text: "recycles cell debris into molecular building blocks",
        image: recyclerCardImage,
        size: css({width: "66px", height: "66px"}),
        dotPosition: {left: "166px", top: "78px"},
    },
    [Transport]: {
        header: "Channel Protein",
        text: "transport materials across the cell membrane",
        image: transportCardImage,
        size: css({width: "60px", height: "60px"}),
        dotPosition: {left: "620px", top: "60px"},
    },
    [Structural]: {
        header: "Structural Protein",
        text: "builds and repair the cell's infrastructure",
        image: structuralCardImage,
        size: css({width: "78px", height: "78px"}),
        dotPosition: {left: "793px", top: "170px" },
    },
    [Antibody]: {
        header: "Antibody",
        text: "attaches to spikes on a virus marking them for destruction",
        image: antibodyCardImage,
        size: css({width: "83px", height: "83px"}),
        dotPosition: {left: "650px", top: "380px"},
    },
};
export const GeneSelectionCardData = cards;

const GeneToOrganelle = {
    [Recycle]: Lysosome,
    [Structural]: Microtubule,
    [EnergyProteinRotor]: Mitochondria,
    [EnergyProteinStabilizer]: Mitochondria,
    [Transport]: CellMembrane,
}; // TODO Antibody?
const OrganelleToIdFunction = {
    [Lysosome]: getDogmaLysosomeIds,
    [Microtubule]: getDogmaMicrotubuleIds,
    [Mitochondria]: getDogmaMitochondriaIds,
    [CellMembrane]: getDogmaCellMembraneIds,
}; // TODO Antibody?

const getAlertTextForUnlockingGene = (state, organelle, dogmaId) => {
    let organelleIds = OrganelleToIdFunction[organelle]?.(state, dogmaId) ?? [];
    const alertOrganelle = organelleIds.find(oId => getOrganelleTimerStart(state, oId) && getOrganelleTimerType(state, oId) === "Alert");
    if(!alertOrganelle) {return "";}
    return getOrganelleAlert(state, alertOrganelle);
}

const geneMatchesUnlocking = (gene, unlockingGene) => {
    if(unlockingGene === EnergyProteinRotor || unlockingGene === EnergyProteinStabilizer) {
        return gene === EnergyProteinRotor || gene === EnergyProteinStabilizer;
    }
    return gene === unlockingGene;
}

const GeneSelection = ({id}) => {
    const {curriculumId} = useParams();
    const [selectedGene, setSelectedGene] = useState(null);
    const [incorrectlySelectedGene, setIncorrectlySelectedGene] = useState(false);
    const highlightedGene = useSelector(state => getDogmaDashHighlightedGene(state, id));
    const unlockingGene = useSelector(state => getDogmaDashLevelUnlockingProtein(state, id));
    const unlockingGeneOrganelle = GeneToOrganelle[unlockingGene];
    const genesUnlocked = useSelector(state => Object.keys(cards).reduce((agg, n) => {
        agg[n] = getDogmaDashAvailableProteins(state, id).indexOf(n) >= 0;
        return agg;
    }, {}), shallowEqual);
    const alertText = useSelector(state => getAlertTextForUnlockingGene(state, unlockingGeneOrganelle, id));
    const dispatch = useDispatch();
    const tutorialPositionConfig = {dogmaDashId: id, location: GENE_SELECTION_NUCLEUS};

    const selectGene = gene => {
        setSelectedGene(gene);
        setIncorrectlySelectedGene(false);
    };
    const closeIncorrectAlert = () => setIncorrectlySelectedGene(false);
    const goToTranscriptionView = () => {
        if(unlockingGene === Recycle || unlockingGene === Structural) {
            dispatch(openModalFor(FIRST_TIME_TRANSCRIPTION));
            dispatch(openModalFor(FIRST_TIME_TRANSLATION));
        }
        dispatch(setDogmaDashPortalNucleotide(id, curriculumId, null));
        dispatch(setDogmaDashPortalTrna(id, curriculumId, null));
        dispatch(setDogmaDashView(id, TRANSCRIPTION_VIEW))
    }
    const highlightGene = () => geneMatchesUnlocking(selectedGene, unlockingGene)
        ? dispatch(setDogmaDashHighlightedGene(id, curriculumId, selectedGene))
        : setIncorrectlySelectedGene(true);
    const unhighlightGene = () => dispatch(setDogmaDashHighlightedGene(id, curriculumId, null));
    const back = () => dispatch(setDogmaDashView(id, CELL_VIEW));

    const Icon = AlertIconMap[unlockingGeneOrganelle];

    return (
        <div css={styles.container}>
            <div css={styles.infoContainer}>
                <div css={styles.headerContainer}>
                    <button onClick={back} css={styles.backButton}><ArrowAsset /></button>
                    <h2 css={styles.header}>Nucleus</h2>
                </div>
                {alertText && <>
                    <h3 css={styles.alertHeader}>Alert:</h3>
                    <div css={styles.alert}>
                        <div css={alertStyles.alertContainer}>
                            <div css={alertStyles.clippingContainer}>
                                <div css={alertStyles.textContainer}>
                                    <div css={alertStyles.alertText}>{alertText}</div>
                                </div>
                            </div>
                            <div css={alertStyles.iconContainer}>
                                <Icon />
                            </div>
                        </div>
                    </div>
                </>}
                <div css={styles.tutorialContainer}><PopUpTutorial positionData={tutorialPositionConfig} /></div>
            </div>
            <div css={!selectedGene ? styles.geneSelector : styles.geneSelectorGeneSelected}>
                {Object.keys(cards).map((c, i) => (
                <div key={i} css={[c === selectedGene ? styles.geneLocationSelected : styles.geneLocation, cards[c].dotPosition]}>
                    <div css={[genesUnlocked[c] ? styles.locationIndicatorUnlocked : c === highlightedGene ? styles.locationIndicatorHighlighted : c === selectedGene ? styles.locationIndictatorSelected : styles.locationIndicatorUnselected, cards[c].size]}>
                        <button css={styles.geneSelectorButton} onClick={() => selectGene(c)} />
                        {c === highlightedGene && <button css={styles.unhighlightButton} onClick={unhighlightGene}><CloseX /></button>}
                    </div>
                    {(c === selectedGene || c === highlightedGene) && <div css={genesUnlocked[c] ? styles.geneNameUnlocked : c === highlightedGene ? styles.geneNameHighlighted : styles.geneName}>{cards[c].header}</div>}
                    {c === highlightedGene && <button css={styles.geneViewButton} onClick={goToTranscriptionView}>Enter gene view</button>}
                </div>
                ))}
            </div>
            <div css={selectedGene ? styles.cardContainerExpanded : styles.cardContainer}>
                <button css={styles.cardClose} onClick={() => selectGene(null)}><CloseX /></button>
                <h2 css={styles.cardHeader}>{cards[selectedGene]?.header}</h2>
                <img src={cards[selectedGene]?.image} css={styles.cardImage} />
                <h3 css={styles.functionHeader}>Function</h3>
                <div css={styles.cardDescriptionText}>{cards[selectedGene]?.text}</div>
                <div css={styles.cardButtonContainer}>
                    {incorrectlySelectedGene && <div css={styles.incorrectAlertArrow}>
                        <div css={styles.incorrectAlertContent}>
                            <button css={styles.incorrectClose} onClick={closeIncorrectAlert}><CloseX /></button>
                            <InfoIcon />
                            <div css={styles.incorrectAlertText}>Double check this gene's function, this one won't solve the alert</div>
                        </div>
                    </div>}
                    {!genesUnlocked[selectedGene] && selectedGene !== highlightedGene && <button css={styles.cardSelectionButton} onClick={highlightGene}>Spotlight gene</button>}
                    {selectedGene === highlightedGene && <div css={styles.cardSelectionHighlighted}>Gene spotlighted</div>}
                    {genesUnlocked[selectedGene] && <div css={styles.cardSelectionUnlocked}>In Inventory</div>}
                </div>
            </div>
        </div>
    )
};

export default GeneSelection;
