import {css} from "@emotion/core";

export const shape = css({
    width: "100%",
    height: "100%",

    "& svg": {
        width: "100%",
        height: "100%",
    },
});

export const shapeSelector = {
    width: 40,
    height: 40,
    backgroundColor: "transparent",
    border: "1px solid transparent",
    margin: "5px 5px 0 0",
    padding: 0,
    transition: "border-color 0.2s",
    cursor: "pointer",

    "&:hover": {
        borderColor: "#ccc",
    },
}

export const selectedShape = css({
    borderColor: "#45cf87",

    "&:hover": {
        borderColor: "#45cf87",
    },
});
