import * as styles from './cargo-counter-styles';
import React, {useEffect, useRef} from 'react';
import {useSelector} from "react-redux";
import {getAccumulatedCargoForAlert, getNumberOfCargo} from "../../../../store/interactions/dogmaDash/selectors";
import TooMuchCargo from './inline-assets/too-much-cargo.svg';

const CargoCounter = ({dogmaId}) => {
    const cargoCount = useSelector(state => getNumberOfCargo(state));
    // TODO: cargo count includes cytoplasm
    const countOnAlert = useSelector(state => getAccumulatedCargoForAlert(state, dogmaId));
    const barRef = useRef();

    useEffect(() => {
        const max = countOnAlert * 2;
        const width = Math.min(1, cargoCount / max);
        barRef.current.style.width = `${width * 100}%`;
    },[cargoCount])

    return (
        <div css={styles.cargoCounterContainer}>
            <div css={styles.cargoCounter}>
                <span css={styles.text}><span css={styles.boldText}>{cargoCount}</span> Cargo</span>
                <div ref={barRef} css={styles.bar} />
                <div css={styles.line} />
            </div>
            <div css={styles.alertContainer}>
                {cargoCount >= countOnAlert && <TooMuchCargo />}
            </div>
        </div>
    )
};

export default CargoCounter;
