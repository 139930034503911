import { call, select, takeLatest, takeEvery } from 'redux-saga/effects';
import {sendGraphQLSaga} from "../../DataService";
import {
    getCurriculumTaskContent, getCurriculumTaskHeight,
    getCurriculumTaskTitle,
    getCurriculumTaskType
} from "../../../store/curriculumTask/selectors";
import {
    ADD_SPACE_TO_SCROLLER_TASK,
    SET_CURRICULUM_TASK_CONTENT,
    SET_CURRICULUM_TASK_HEIGHT,
    SET_CURRICULUM_TASK_TITLE
} from "../../../store/curriculumTask/actions";
import {SCROLLING_TASK, SECTIONED_TASK} from "../../../constants/TaskTypes";

const sectionTaskQuery = `mutation updateCurriculumSectionTaskContent($input: UpdateCurriculumSectionTaskContentInput!) {
    updateCurriculumSectionTaskContent(input: $input) {
        task {
            id
        }
    }
}`;
const scrollingTaskQuery = `mutation UpdateCurriculumScrollingTaskContent($input: UpdateCurriculumScrollingTaskContentInput!) {
    updateCurriculumScrollingTaskContent(input: $input) {
        task {
            id
        }
    }
}`;

function* execute(action) {
    const {taskId: task, curriculumId: curriculum} = action;
    const title = yield select(state => getCurriculumTaskTitle(state, task));
    const taskType = yield select(state => getCurriculumTaskType(task, state));
    let variables, query;
    if(taskType === SECTIONED_TASK) {
        query = sectionTaskQuery;
        const textContent = yield select(state => getCurriculumTaskContent(state, task));
        variables = {
            input: {
                curriculum,
                task,
                title,
                textContent,
            },
        };
    } else if(taskType === SCROLLING_TASK) {
        query = scrollingTaskQuery;
        const height = yield select(state => getCurriculumTaskHeight(task, state));
        variables = {
            input: {
                curriculum,
                task,
                title,
                height,
            },
        };
    }
    yield call(sendGraphQLSaga, query, variables);
}

export default [
    takeLatest(SET_CURRICULUM_TASK_TITLE, execute),
    takeLatest(SET_CURRICULUM_TASK_CONTENT, execute),
    takeLatest(SET_CURRICULUM_TASK_HEIGHT, execute),
    takeEvery(ADD_SPACE_TO_SCROLLER_TASK, execute),
];
