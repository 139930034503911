import {call, takeEvery} from 'redux-saga/effects'
import {sendGraphQLSaga} from "../../DataService";
import {SET_CENTRAL_DOGMA_WIDGET_NUCLEOTIDE} from "../../../store/interactions/centralDogmaWidget/actions";

const setCentralDogmaWidgetStrandNucleotide = `mutation SetCentralDogmaWidgetStrandNucleotide($input: SetCentralDogmaWidgetStrandNucleotideInput!) {
    setCentralDogmaWidgetStrandNucleotide(input: $input) {
        centralDogmaWidget {
            id
        }
    }
}`;
function* executeSetStrandNucleotide(action) {
    const {widgetId, curriculumId, strandName, index, nucleotide} = action;

    const variables = {
        input: {
            centralDogmaWidget: widgetId,
            curriculum: curriculumId,
            index: index,
            nucleotides: [nucleotide],
            strand: strandName,
        },
    };

    yield call(sendGraphQLSaga, setCentralDogmaWidgetStrandNucleotide, variables);
}

export default [
    takeEvery(SET_CENTRAL_DOGMA_WIDGET_NUCLEOTIDE, executeSetStrandNucleotide),
];
