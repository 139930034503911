import "./link.scss"
import React, {useEffect, useRef, useState} from 'react';
import classNames from 'class-names';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {isModalOpenFor} from "../../store/navigation/selectors";
import {openModalFor} from "../../store/navigation/actions";
import {EDITOR_COMPONENT_LINK} from "../editorComponents/Link";
import {Editor, Range, Transforms} from 'slate';
import {ReactEditor, useSlate} from "slate-react";
import {ADD_LINK_TO_RICH_TEXT_MODAL} from "../../constants/modalTypes";

const urlInputOpen = props => state => isModalOpenFor(state, ADD_LINK_TO_RICH_TEXT_MODAL, props.editorId);

export default props => {
    const {editorId} = props;
    const editor = useSlate();
    const isUrlInputOpen = useSelector(urlInputOpen(props), shallowEqual);
    const dispatch = useDispatch();
    const urlInputRef = useRef();
    const [selection, setSelection] = useState(null);

    useEffect(() => {
        if(urlInputRef.current) {
            urlInputRef.current.focus();
        }
    }, [isUrlInputOpen]);

    const [nodes] = Editor.nodes(editor, { match: n => n.type === EDITOR_COMPONENT_LINK });
    const active = !!nodes;

    const openUrlInput = e => {
        e.preventDefault();
        e.stopPropagation();
        if(active) {
            Transforms.unwrapNodes(editor, { match: n => n.type === EDITOR_COMPONENT_LINK });
            return;
        }
        if(!editor.selection || Range.isCollapsed(editor.selection)) { return; } // todo: fix (insert text)
        setSelection(editor.selection);
        dispatch(openModalFor(ADD_LINK_TO_RICH_TEXT_MODAL, editorId));
    };
    const focusEditor = () => {
        ReactEditor.focus(editor);
        Transforms.select(editor, selection);
    };
    const closeUrlInput = () => {
        focusEditor();
        dispatch(openModalFor());
    };
    const setUrl = () => {
        focusEditor();
        const url = urlInputRef.current.value;
        Transforms.wrapNodes(editor, {
            type: EDITOR_COMPONENT_LINK,
            href: url,
        }, {
            split: true,
        });
        dispatch(openModalFor());
    };
    const confirmOnEnter = e => {
        if(e.which !== 13) { return; }
        e.preventDefault();
        e.stopPropagation();
        setUrl();
    };

    return (
        <>
            {isUrlInputOpen &&
            <div className="rich-editor--url-input__blocker">
                <div className="rich-editor--url-input">
                    <div className="url-input--url-container">
                        <label htmlFor="editor-url-input" className="url-input--url-label">URL:</label>
                        <input onKeyDown={confirmOnEnter} ref={urlInputRef} id="editor-url-input" className="url-input--url-input" placeholder="www.example.com" />
                    </div>
                    <div className="url-input--button-container">
                        <button onMouseDown={setUrl} className="url-input--insert-button">Insert</button>
                        <button onMouseDown={closeUrlInput} className="url-input--cancel-button">Cancel</button>
                    </div>
                </div>
            </div> }
            <button onMouseDown={openUrlInput} className={classNames({
                "rich-editor--style-button": true,
                "rich-editor--style-button__active": active,
                "rich-editor--style-button__hyperlink": true,
            })} />
        </>
    )
};
