import {css} from "@emotion/core";
import {inlineBlockBottom, inlineBlockTop} from "../../../style-variables";
import mrnaImage from "./assets/mrnaBackbone.svg";
import aImage from "./assets/base a.svg";
import uImage from './assets/base u.svg'
import cImage from './assets/base c.svg';
import gImage from './assets/base g.svg';
import trnaBlueImage from './assets/trna blue.svg';
import trnaGreenImage from './assets/trna green.svg';
import trnaPinkImage from './assets/trna pink.svg';
import trnaOrangeImage from './assets/trna orange.svg';

export const container = css({
    width: "100%",
    // height: "600px",
    position: "relative",
});

export const aminoDropArea = css({
    width: "100%",
    height: "100%",
});

export const mrna = css({
    margin: "260px 0 20px 0",
    width: "1200px",
    height: "274px",
    backgroundImage: `url(${mrnaImage})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: "no-repeat",
    position: "relative",

    "&:before,&:after": {
        content: '""',
        position: "absolute",
        display: "block",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backgroundImage: `url(${mrnaImage})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: "no-repeat",
    },
});

export const codon = css({
    position: "absolute",
    zIndex: 1,
});

export const dropArea = css({
    position: "absolute",
    left: "-3px",
    top: "-145px",
    width: "135px",
    height: "145px",
});

export const base = css(inlineBlockBottom, {
    width: "35px",
    height: "66px",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    position: "relative",

    "&:not(:last-child)": {
        marginRight: "12px",
    },

    "&:before,&:after": {
        content: '""',
        position: "absolute",
        display: "block",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backgroundSize: '100% 100%',
        backgroundRepeat: "no-repeat",
    },
});

export const baseA = css(base, {
    backgroundImage: `url(${aImage})`,

    "&:before,&:after": {
        backgroundImage: `url(${aImage})`,
    },
});

export const baseU = css(base, {
    backgroundImage: `url(${uImage})`,

    "&:before,&:after": {
        backgroundImage: `url(${uImage})`,
    },
});

export const baseC = css(base, {
    backgroundImage: `url(${cImage})`,

    "&:before,&:after": {
        backgroundImage: `url(${cImage})`,
    },
});

export const baseG = css(base, {
    backgroundImage: `url(${gImage})`,

    "&:before,&:after": {
        backgroundImage: `url(${gImage})`,
    },
});

export const trna = css(inlineBlockTop, {
    position: "relative",
    width: "135px",
    height: "105px",
    backgroundSize: '100% 100%',
    backgroundRepeat: "no-repeat",

    "&:before,&:after": {
        content: '""',
        position: "absolute",
        display: "block",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backgroundSize: '100% 100%',
        backgroundRepeat: "no-repeat",
    },
});

export const trnaBlue = css(trna, {
    backgroundImage: `url(${trnaBlueImage})`,

    "&:before,&:after": {
        backgroundImage: `url(${trnaBlueImage})`,
    },
});

export const trnaGreen = css(trna, {
    backgroundImage: `url(${trnaGreenImage})`,

    "&:before,&:after": {
        backgroundImage: `url(${trnaGreenImage})`,
    },
});

export const trnaOrange = css(trna, {
    backgroundImage: `url(${trnaOrangeImage})`,

    "&:before,&:after": {
        backgroundImage: `url(${trnaOrangeImage})`,
    },
});

export const trnaPink = css(trna, {
    backgroundImage: `url(${trnaPinkImage})`,

    "&:before,&:after": {
        backgroundImage: `url(${trnaPinkImage})`,
    },
});

export const trnaCodon = css({
    position: "absolute",
    left: "3px",
    top: "88px",
    zIndex: -1,
});

export const trnaBase = css({
    transform: "rotate(180deg)",
});

export const trnaAmino = css({
    position: "absolute",
    left: "50%",
    top: 0,
    transform: "translate(-50%, -50%)",
    zIndex: 1,
});

export const customDragLayerContainer = css({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 10000,
    pointerEvents: "none",
});
