import {getDataClient, getDataClientSaga, getSubscriptionClient} from "../store/data/selectors";
import {getVerdantTeacherQuery} from "./queries/verdantTeacher";
import {itemAddedToBoard, itemMovedOnWorkBoard, itemRemovedFromBoard, setAllBoards,} from "../store/workBoards/actions";
import {importPolaroids} from "../store/polaroids/actions";
import {
    addPolaroidToWorkBoardQuery,
    addPolaroidToWorkBoardVariables,
    workBoardUpdatedSubscription,
    workBoardUpdatedVariables
} from "./queries/workBoard";
import {setAuthenticationState, setTeacherId} from "../store/identity/actions";
import {
    addLearnersToCohortQuery,
    addLearnersToCohortVariables,
    createCohortQuery,
    createCohortVariables,
    setCohortNameQuery,
    setCohortNameVariables,
} from "./queries/cohort";
import {replaceLearnersInCohort, setAllCohorts} from "../store/cohorts/actions";
import {omit} from 'lodash';
import gql from 'graphql-tag';
import {peaPlantCardsLoaded} from "../store/peaPlantCards/actions";
import {parseWorkBoard} from "./WorkBoard";
import {
    PEA_PLANT_CARD,
    PODULATOR_RECEIPT,
    POLAROID,
    PUNNETT_SQUARE,
    STICKY_NOTE
} from "../constants/WorkBoardItemTypes";
import {stickyNotesLoaded, stickyNoteTextUpdated} from "../store/stickyNotes/actions";
import {podulatorReceiptsLoaded} from "../store/podulatorReceipts/actions";
import {NOT_LOGGED_IN} from "../constants/AuthenticationState";
import {punnettSquaresLoaded} from "../store/punnettSquares/actions";
import {peaPlantsLoaded} from "../store/peaPlants/actions";
import {assignCurriculumToLearner} from "./Curriculum";
import {teacherMasterCurriculaQuery} from "./queries/curriculum";
import {curriculaLoaded} from "../store/curricula/actions";
import {call, put} from 'redux-saga/effects';

export function* sendGraphQLSaga(query, variables) {
    const client = yield call(getDataClientSaga);
    if(!client) {
        yield put(setAuthenticationState(NOT_LOGGED_IN));
        return;
    }
    if(variables) {
        return yield call(client.request.bind(client), query, variables);
    } else {
        return yield call(client.request.bind(client), query);
    }
}

export default class {
    start(store) {
        this.store = store;
    }

    // TODO: this is still used by a few teacher related things
    async sendGraphQL(query, variables) {
        const client = await getDataClient(this.store.dispatch); // NOTE: THIS WILL NOT CHECK FOR AN LTI KEY
        if(!client) {
            this.store.dispatch(setAuthenticationState(NOT_LOGGED_IN));
            return;
        }
        if(variables) {
            return client.request(query, variables);
        } else {
            return client.request(query);
        }
    }

    getVerdantTeacher() {
        if(this.teacherExecutor) { return; }

        this.teacherExecutor = this.sendGraphQL(getVerdantTeacherQuery)
                .then(data => {
                if(!data || !data.verdantTeacher) {
                    this.teacherExecutor = null;
                    return;
                }
                const {verdantTeacher} = data;

                // hmm.. this could all happen in the various reducers from a "load" action instead of all of these actions..
                // one vs. many actions.
                const cohorts = verdantTeacher.cohorts.reduce((agg, cohort) => {
                    agg[cohort.id] = Object.assign({}, cohort, {
                        workBoards: cohort.workBoards.map(w => w.id),
                    });
                    return agg;
                }, {});
                setAllCohorts(this.store.dispatch)(cohorts);

                const allWorkBoards = verdantTeacher.cohorts.reduce((agg, c) => agg.concat(c.workBoards), []);
                const workBoards = parseWorkBoards(allWorkBoards, this.store.dispatch);

                this.store.dispatch(setAllBoards(workBoards));
                setTeacherId(this.store.dispatch)(verdantTeacher.id);
                this.teacherExecutor = null;
            })
            .then(() => this.sendGraphQL(teacherMasterCurriculaQuery))
            .then(data => {
                if(!data.masterCurricula) { return; }
                this.store.dispatch(curriculaLoaded(data.masterCurricula));
            });
    }

    createCohort(cohortId, teacherId) {
        // TODO: error handling?
        return this.sendGraphQL(createCohortQuery, createCohortVariables(cohortId, teacherId))
    }

    setCohortName(cohortId, name) {
        return this.sendGraphQL(setCohortNameQuery, setCohortNameVariables(cohortId, name));
    }

    subscribeToWorkBoards(workBoardIds) {
        const client = getSubscriptionClient(this.store.getState());
        const dispatch = this.store.dispatch;
        return client.subscribe({
            query: gql`${workBoardUpdatedSubscription}`,
            variables: workBoardUpdatedVariables(workBoardIds),
        }).subscribe({
            next(data) {
                if(!data.data.workBoardUpdated) { return; }
                const { workBoardId, updated: updatedItemPosition, type: updateType, updater } = data.data.workBoardUpdated;
                if(updateType === 'ITEM_ADDED') {
                    handleItemAddedToWorkBoard(dispatch, workBoardId, updatedItemPosition);
                } else if(updateType === 'ITEM_REMOVED') {
                    handleItemRemovedFromWorkBoard(dispatch, workBoardId, updatedItemPosition);
                } else if(updateType === 'ITEM_MOVED') {
                    handleItemMovedOnWorkBoard(dispatch, workBoardId, updatedItemPosition);
                } else if(updateType === 'ITEM_UPDATED') {
                    handleItemUpdated(dispatch, updatedItemPosition);
                }
            }
        });
    }

    addLearnersToCohort(cohortId, emails) {
        const dispatch = this.store.dispatch;
        const addPromise = this.sendGraphQL(addLearnersToCohortQuery, addLearnersToCohortVariables(cohortId, emails))
            .then(data => {
                const cohort = data.addLearnersToCohort.cohort;
                dispatch(replaceLearnersInCohort(dispatch)(cohortId, cohort.learners));
                return cohort.learners;
            });
        // const assignPromise = addPromise.then(learners =>
        //     learners.reduce((agg, l) => agg.then(() => assignCurriculumToLearner(this, l.id, "curricula-0001")), Promise.resolve()));

        return addPromise;
    }
}

function parseWorkBoards(workBoardData, dispatch) {
    return workBoardData.map(wb => parseWorkBoard(wb, dispatch).workBoard);
}

function handleItemAddedToWorkBoard(dispatch, workBoardId, itemPosition) {
    const type = itemPosition.item.__typename;
    const item = omit(itemPosition.item, ['__typename']);
    const addToWorkBoard = Object.assign(omit(itemPosition, '__typename'), {
        item: item.id,
        type
    });
    if(type === POLAROID) {
        dispatch(importPolaroids([item]));
    } else if(type === PEA_PLANT_CARD) {
        dispatch(peaPlantsLoaded([item.peaPlant]));
        item.peaPlant = item.peaPlant.id;
        dispatch(peaPlantCardsLoaded([item]));
    } else if(type === STICKY_NOTE) {
        dispatch(stickyNotesLoaded([item]));
    } else if(type === PODULATOR_RECEIPT) {
        dispatch(peaPlantsLoaded([item.topParent, item.leftParent]));
        item.leftParent = item.leftParent.id;
        item.topParent = item.topParent.id;
        dispatch(podulatorReceiptsLoaded([item]));
    } else if(type === PUNNETT_SQUARE) {
        parsePunnettSquare(dispatch, item);
    }
    dispatch(itemAddedToBoard(workBoardId, addToWorkBoard));
}

function handleItemRemovedFromWorkBoard(dispatch, workBoardId, updatedItemPosition) {
    dispatch(itemRemovedFromBoard(workBoardId, updatedItemPosition.item.id));
}

function handleItemMovedOnWorkBoard(dispatch, workBoardId, updatedItemPosition) {
    const { item, x, y, angle } = updatedItemPosition;
    dispatch(itemMovedOnWorkBoard(dispatch)(workBoardId, item.id, x, y, angle));
}

function handleItemUpdated(dispatch, updatedItemPosition) {
    const { item } = updatedItemPosition;
    if(item.__typename === STICKY_NOTE) {
        dispatch(stickyNoteTextUpdated(item.id, item.text));
    } else if(item.__typename === PUNNETT_SQUARE) {
        parsePunnettSquare(dispatch, item);
    }
}

function parsePunnettSquare(dispatch, item) {
    const plants = [];
    if(item.leftPlant) {
        plants.push(item.leftPlant);
        item.leftPlant = item.leftPlant.id;
    }
    if(item.topPlant) {
        plants.push(item.topPlant);
        item.topPlant = item.topPlant.id;
    }
    dispatch(peaPlantsLoaded(plants));
    dispatch(punnettSquaresLoaded([item]));
}
