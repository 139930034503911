import React from 'react';
import {TEXT_COLOR_MARK} from "./ScrollerItem/TextColorButton";
import {TEXT_SIZE_MARK} from "./editorButtons/TextSize";

export default ({attributes, children, leaf}) => {
    if(leaf.bold) {
        children = <strong>{children}</strong>;
    }
    if(leaf.italic) {
        children = <em>{children}</em>;
    }
    if(leaf.underline) {
        children = <u>{children}</u>;
    }
    if(leaf[TEXT_COLOR_MARK]) {
        children = <span style={{color: leaf[TEXT_COLOR_MARK]}}>{children}</span>;
    }
    if(leaf[TEXT_SIZE_MARK]) {
        children = <span style={{fontSize: `${leaf[TEXT_SIZE_MARK]}px`}}>{children}</span>;
    }

    return (
        <span {...attributes}>
            {children}
        </span>
    )
}
