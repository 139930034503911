export const SECTIONED_TASK = "CurriculumSectionTask";
export const SCROLLING_TASK = "CurriculumScrollingTask";
export const GLOBAL_TASK = "CurriculumGlobalTask";

export const LONG_TEXT_INPUT = "LongTextInput";
export const WORK_BOARD_DEFINITION = "WorkBoardDefinition";
export const PEA_PLANT_MATERIAL = "PeaPlantDefinition";
export const MULTIPLE_CHOICE_INPUT = "MultipleChoiceInput";
export const INPUT_TABLE = "InputTable";
export const DRAGGABLE_BANK = "DraggableBank";
export const DRAGGABLE = "Draggable";
export const CPR = "CPR";
export const OBJECT_SCANNING_TASK = "ObjectScanningTask"
export const INVENTORY_TASK = "InventoryTask";
export const MRNA_ACTIVITY = "MrnaActivity";
export const DOGMA_DASH = "DogmaDash";
export const CENTRAL_DOGMA_WIDGET = "CentralDogmaWidget";
export const UTILITY_BUTTON = "UtilityButton";

export const LONG_TEXT_INPUT_INTERACTION = "LongTextInputInteraction";
export const WORK_BOARD_INTERACTION = "WorkBoardInteraction";
export const MULTIPLE_CHOICE_INPUT_INTERACTION = "MultipleChoiceInputInteraction";
export const INPUT_TABLE_INTERACTION = "InputTableInteraction";
export const DROP_AREA_INTERACTION = "DropAreaInteraction";
export const CPR_INTERACTION = "CPRInteraction";
export const WORK_BOARD_CROSS_CARD_INTERACTION = "WorkBoardCrossCardInteraction";
export const EXPLAINER_INTERACTION = "ExplainerInteraction";
export const EXPLAINER_PREDICTION_INTERACTION = "ExplainerPredictionInteraction";
export const INVENTORY_TASK_INTERACTION = "InventoryTaskInteraction";
export const MRNA_ACTIVITY_INTERACTION = "MrnaActivityInteraction";
export const DOGMA_DASH_INTERACTION = "DogmaDashInteraction";
export const CENTRAL_DOGMA_INTERACTION = "CentralDogmaInteraction";

export const SCROLLING_TEXT_ITEM = "CurriculumScrollerTextItem";
export const SCROLLING_IMAGE_ITEM = "CurriculumScrollerImageItem";
export const SCROLLING_SHAPE_ITEM = "CurriculumScrollerShapeItem";
export const SCROLLING_DATA_ITEM = "CurriculumScrollerDataItem";
export const SCROLLING_DROP_SLOT_ITEM = "CurriculumScrollerDropSlotItem";
export const SCROLLER_EXPLAINER = "CurriculumScrollerExplainer";
export const SCROLLER_EXPLAINER_PREDICTION = "CurriculumScrollerExplainerPrediction";
export const SCROLLER_SECTION = "CurriculumScrollerSection";
export const SCROLLER_WORK_BOARD = "ScrollerWorkBoard";
export const RESIZEABLE_TYPES = {
    [SCROLLING_TEXT_ITEM]: {x: 1, y: 1},
    [SCROLLING_SHAPE_ITEM]: {x: 1, y: 1},
    [SCROLLING_IMAGE_ITEM]: {x: 1, y: 1},
    [SCROLLER_SECTION]: {x: 0, y: 1},
    [SCROLLER_WORK_BOARD]: {x: 1, y: 0},
};
export const FULL_WIDTH_TYPES = [SCROLLER_EXPLAINER, SCROLLER_SECTION];

export const WORK_BOARD_DROP_SLOT = "WorkBoardDefinitionDropSlot";
export const WORK_BOARD_CROSS_CARD = "WorkBoardDefinitionCrossCard";

export const UTILITY_BUTTON_PARTNER = "OpenPartnering";
export const UTILITY_BUTTON_HEADSET = "OpenHeadsetPairing";
export const UTILITY_BUTTON_GLOBAL_ITEM = "OpenGlobalItem";
