import React from 'react';
import {shallowEqual, useSelector} from "react-redux";
import {getCurriculumTaskTitle} from "../../store/curriculumTask/selectors";

export const EDITOR_COMPONENT_DRAGGABLE_BANK_REFERENCE = "DRAGGABLE_BANK_REFERENCE";

const stateMap = props => state => ({
    taskTitle: getCurriculumTaskTitle(state, props.taskId),
});

export default ({attributes, children, element}) => {
    const { taskTitle } = useSelector(stateMap(element), shallowEqual);

    return (
        <span {...attributes}>
            <span className="rich-editor-components--draggable-bank-reference" contentEditable={false}>
                {`Draggable item from: ${taskTitle}'s bank`}
            </span>
            {children}
        </span>
    );
};
