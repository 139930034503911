import * as styles from './text-style';
import React, {useRef} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {isItemEditing} from "../../../../../store/itemSelection/selector";
import {useParams} from "@reach/router";
import {getScrollingItemContent} from "../../../../../store/scrollingTaskItemPositions/selectors";
import {setScrollingItemContent} from "../../../../../store/scrollingTaskItemPositions/actions";
import combineStyles from "../../../../../Utility/combineStyles";
import Editor from '../../../../../RichText/ScrollerItem/Editor';
import Viewer from '../../../../../Learn/CurriculumTask/RichTextDisplay';

export default props => {
    const {id} = props;
    const {curriculumId} = useParams();
    const editing = useSelector(isItemEditing.bind(null, id), shallowEqual);
    const content = useSelector(getScrollingItemContent.bind(null, id), shallowEqual);
    const editorRef = useRef();
    const dispatch = useDispatch();

    const placeholder = !content && !editing;

    const setContent = newContent => dispatch(setScrollingItemContent(curriculumId, id, newContent));

    const styling = combineStyles(styles, {
        "text": true,
        "textPlaceholder": placeholder,
    });

    return (
        <div ref={editorRef} css={styling}>
            {editing && <Editor content={content} setContent={setContent} id={id} />}
            {!editing && <Viewer content={content || "Placeholder..."} />}
        </div>
    )
};
