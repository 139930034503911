import {css} from "@emotion/core";
import {bbPadding, inlineBlockTop} from "../../style-variables";
import cellImage from "./assets/cell background.png";

export const container = css({
    width: "100%",
    height: "100%",
    textAlign: "center",
    position: "relative",
    overflow: "hidden",

    "&:before": {
        content: '""',
        display: 'block',
        width: "1021px",
        height: "1021px",
        position: "absolute",
        right: "-189px",
        bottom: "-267px",
        background: `url(${cellImage})`,
        backgroundSize: "100% 100%",
    },
});

export const contentArea = css(inlineBlockTop, {
    width: "100%",
    minHeight: "100%",
    height: "1px", //needed so that children get the right height
    textAlign: "left",
    overflow: "auto",
});
