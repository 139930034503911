import {
    HEADSET_AUTHORIZED, REVOKE_AUTHORIZATION,
    SET_AUTHENTICATION_STATE, SET_CURRENT_USER_ROLES, SET_LTI_KEY,
    SET_TEACHER_ID, TRY_AUTHORIZE_HEADSET,
} from "./actions";
import {ATTEMPTING_LOG_IN, LOGGED_IN, NOT_LOGGED_IN, UNKNOWN} from "../../constants/AuthenticationState";

const defaultState = {
    authenticationState: UNKNOWN,
    authenticatedAs: null,
    teacherId: null,
    currentUserRoles: null,
    headsetAuthorized: null,
    headsetAuthorizedState: NOT_LOGGED_IN,
};

export default function(state = defaultState, action) {
    if(action.type === SET_AUTHENTICATION_STATE) {
        let update = {
            authenticationState: action.authenticationState,
            authenticatedAs: action.authenticatedAs,
        };
        if(action.authenticationState === NOT_LOGGED_IN) {
            update = {
                ...defaultState,
                ...update,
            };
        }
        if(state.authenticationState === LOGGED_IN && action.authenticationState === ATTEMPTING_LOG_IN) {
            return state;
        }
        return {
            ...state,
            ...update,
        };
    }
    if(action.type === SET_LTI_KEY) {
        return {
            ...state,
            authenticationState: LOGGED_IN,
            authenticatedAs: action.authenticatedAs,
        };
    }
    if(action.type === SET_TEACHER_ID) {
        return {
            ...state,
            teacherId: action.id,
        };
    }
    if(action.type === SET_CURRENT_USER_ROLES) {
        return {
            ...state,
            currentUserRoles: action.roles,
        };
    }
    if(action.type === TRY_AUTHORIZE_HEADSET) {
        return {
            ...state,
            headsetAuthorizedState: ATTEMPTING_LOG_IN,
        };
    }
    if(action.type === HEADSET_AUTHORIZED) {
        return {
            ...state,
            headsetAuthorized: action.pairingKey,
            headsetAuthorizedState: LOGGED_IN,
        };
    }
    if(action.type === REVOKE_AUTHORIZATION) {
        return {
            ...state,
            headsetAuthorized: null,
            headsetAuthorizedState: NOT_LOGGED_IN,
        };
    }
    return state;
}
