import addMaterials from "./addMaterials";
import removeMaterial from "./removeMaterial";
import addTemplateItem from "./addTemplateItem";
import moveItem from "./moveItem";
import setCrossCardConfiguration from "./setCrossCardConfiguration";
import removeTemplateItem from "./removeTemplateItem";
import setContent from './setContent';

export default [
    ...addMaterials,
    ...removeMaterial,
    ...addTemplateItem,
    ...removeTemplateItem,
    ...moveItem,
    ...setCrossCardConfiguration,
    ...setContent,
];
