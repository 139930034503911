import {css, keyframes} from "@emotion/core";
import {
    inlineBlockTop,
    modalBlocker,
    themedModalButton,
    themedModalClose,
    themedModalContainer,
    themedModalInput,
    themedModalTitle
} from "../../style-variables";
import spinner from '../../assets/loading.png';
import {
    headerButtonsFontColor,
    headerButtonsFontFamily,
    headerButtonsFontWeight,
    secondaryColor
} from "../themes/selectors";

export const dialog = modalBlocker;

export const dialogBody = themedModalContainer;

export const dialogTitle = themedModalTitle;

export const dialogInput = themedModalInput;

export const explanatoryText = theme => css({
    maxWidth: "420px",
    fontWeight: headerButtonsFontWeight(theme),
    fontFamily: headerButtonsFontFamily(theme),
    color: headerButtonsFontColor(theme),
    fontSize: '1.25em',
});

export const pairingKey = theme => css({
    color: secondaryColor(theme),
    fontWeight: headerButtonsFontWeight(theme),
    fontFamily: headerButtonsFontFamily(theme),
    fontSize: "3.125em",
});

export const secondaryText = theme => css({
    color: secondaryColor(theme),
    fontFamily: headerButtonsFontFamily(theme),
    fontSize: "0.9375rem",
    fontWeight: 600,
    margin: "27px 0 0 0",
});

export const buttonContainer = css({
    marginTop: "29px",
});

export const dialogPairButton = theme => css(themedModalButton(theme), inlineBlockTop, {
    marginRight: "44px",

    "&:last-of-type": {
        marginRight: "0",
    },
});

const spinAnimation = keyframes({
    "0%": { transform: "translate(-50%, -50%) rotate(0)" },
    "100%": { transform: "translate(-50%, -50%) rotate(360deg)" },
});
export const dialogPairingPlaceholder = theme => css(dialogPairButton(theme), {
    position: "relative",

    "&:before": {
        content: '""',
        display: 'block',
        width: "26px",
    },
    "&:after": {
        content: '""',
        display: "block",
        width: "26px",
        height: "26px",
        background: `url(${spinner})`,
        backgroundSize: "100% 100%",
        animation: `${spinAnimation} 0.75s infinite`,
        animationTimingFunction: "cubic-bezier(.39,.58,.57,1)",
        position: "absolute",
        top: "50%",
        left: "50%",
    },
});

export const dialogCancelButton = themedModalClose;
