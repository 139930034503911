// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/edit.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".admin-draggable--rich{display:inline-block;vertical-align:top;box-sizing:border-box;padding:8px 18px 8px 8px;box-shadow:1px 1px 4px 0 rgba(0,0,0,.5);margin:0 4px 4px 0;position:relative}.admin-draggable--rich p{margin:0}.admin-draggable-rich--placeholder{font-size:.875em;color:#555}.admin-draggable-rich--edit{width:10px;height:10px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;border:none;padding:0;position:absolute;top:50%;right:4px;transform:translateY(-50%);cursor:pointer}.admin-draggable-rich--modal{width:500px;background-color:#fff;box-sizing:border-box;padding:20px;position:absolute;left:50%;top:50%;transform:translate(-50%, -50%)}.admin-draggable-rich--modal__blocker{background-color:rgba(0,0,0,.5);position:fixed;left:0;right:0;top:0;bottom:0;z-index:1000}.admin-draggable-rich--title{font-size:1em;padding:0;margin:0 0 8px 0}.admin-draggable-rich--editor-container{width:100%;min-height:120px}.cell-editor--button-container{width:100%;text-align:right;margin-top:10px}.cell-editor--accept{width:90px;height:30px;padding:0;margin-right:10px;border:none;background-color:#e3c04b;transition:background-color .2s;cursor:pointer}.cell-editor--accept:disabled{background-color:#6f6f6f;cursor:not-allowed}.cell-editor--accept:not(:disabled):hover{background-color:#b7931c}.cell-editor--cancel{width:90px;height:30px;padding:0;border:1px solid #6f6f6f;background-color:#fff;transition:background-color .2s;cursor:pointer}.cell-editor--cancel:hover{background-color:#f2f2f2}", ""]);
// Exports
module.exports = exports;
