export const SET_AUTHENTICATION_STATE = "Set logged in";
export const setAuthenticationState = (authenticationState, authenticatedAs) => ({
    type: SET_AUTHENTICATION_STATE,
    authenticationState,
    authenticatedAs,
});

export const SET_LTI_KEY = "Set lti key";
export const setLTIKey = (ltiKey, authenticatedAs) => {
    sessionStorage.setItem("ltik", ltiKey);

    return {
        type: SET_LTI_KEY,
        ltiKey,
        authenticatedAs,
    };
}

export const SET_LEARNER_DATA = "Set Verdant Learner data";
export const setLearnerData = (learnerId, givenName, familyName, email, workBoards, bountyAssignments) => ({
    type: SET_LEARNER_DATA,
    id: learnerId,
    givenName,
    familyName,
    email,
    workBoards,
    bountyAssignments,
});

export const SET_TEACHER_ID = "Set Verdant Teacher Id";
export const setTeacherId = dispatch => id => dispatch({
    type: SET_TEACHER_ID,
    id
});

export const SET_CURRENT_USER_ROLES = "Set current user roles";
export const setCurrentUserRoles = roles => ({
    type: SET_CURRENT_USER_ROLES,
    roles,
});

export const TRY_AUTHORIZE_HEADSET = "Try authorize headset";
export const tryAuthorizeHeadset = pairingKey => ({
    type: TRY_AUTHORIZE_HEADSET,
    pairingKey,
});

export const HEADSET_AUTHORIZED = "Headset authorized";
export const headsetAuthorized = pairingKey => ({
    type : HEADSET_AUTHORIZED,
    pairingKey,
});

export const TRY_AUTHENTICATE = "Try authenticate";
export const tryAuthenticate = (authenticateAs) => ({
    type: TRY_AUTHENTICATE,
    authenticateAs,
});

export const TRY_REVOKE_AUTHORIZATION = "Try revoke authorization";
export const tryRevokeAuthorization = () => ({
    type : TRY_REVOKE_AUTHORIZATION,
});

export const REVOKE_AUTHORIZATION = "Revoke headset authorization";
export const headsetAuthorizationRevoked = () => ({
    type: REVOKE_AUTHORIZATION,
});
