export const EDIT_INPUT_TABLE_CELL_MODAL = "Edit input table cell modal";
export const ADD_CURRICULA_TO_COHORT_MODAL = "Add curricula to cohort modal";
export const ADD_IMAGE_TO_RICH_TEXT_MODAL = "Add image to rich text modal";
export const ADD_VIDEO_TO_RICH_TEXT_MODAL = "Add video to rich text modal";
export const ADD_LINK_TO_RICH_TEXT_MODAL = "Add link to rich text modal";
export const ADD_TASK_REFERENCE_TO_RICH_TEXT_MODAL = "Add task reference to rich text modal";
export const EDIT_LEARNER_MODAL = "Edit learner modal";
export const CASE_ADD_TASK_MODAL = "Case add task modal";
export const EDIT_DRAGGABLE_MODAL = "Edit draggable modal";
export const SCROLLER_MENU = "Scroller menu";
export const SCROLLER_ITEM_CONTEXT = "Scroller item context";
export const TEMPLATE_MENU = "Template menu";
export const TEMPLATE_ITEM_CONTEXT = "Template item context menu";
export const WORK_BOARD_MODAL = "Work Board modal";
export const WORK_BOARD_ITEM_FOCUS = "Work board item focus";
export const ADD_SPACE_TO_SCROLLLING_CANVAS = "Add space to scrolling canvas";
export const EXPLAINER_EVIDENCE = "Explainer evidence modal";
export const PARTNERS_MODAL = "Partners";
export const HEADSET_PAIRING_MODAL = "Headset Pairing";
export const FAKE_HEADSET_PAIRING_MODAL = "Fake Headset Pairing";
export const FIRST_TIME_TRANSCRIPTION = "First time transcription";
export const FIRST_TIME_TRANSLATION = "First time translation";
export const DOGMA_HEALTH_WARNING = "Dogma Dash health warning";
export const DOGMA_HEALTH_WARNING_2 = "Dogma Dash health warning 2";
