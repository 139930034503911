import "./edit-learner.scss";
import React, {useEffect, useRef} from 'react';
import {isModalOpenFor} from "../../store/navigation/selectors";
import {getFamilyNameForLearner, getGivenNameForLearner, getLearnerIds} from "../../store/learners/selectors";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {openModalFor} from "../../store/navigation/actions";
import {updateLearnerName} from "../../store/learners/actions";
import {EDIT_LEARNER_MODAL} from "../../constants/modalTypes";

const stateMap = state => {
    // TODO: this is UGLY!
    const learner = getLearnerIds(state).find(l => isModalOpenFor(state, EDIT_LEARNER_MODAL, l));
    if(!learner) { return null; }
    return {
        learner,
        givenName: getGivenNameForLearner(state, learner),
        familyName: getFamilyNameForLearner(state, learner),
    };
};

export default () => {
    const state = useSelector(stateMap, shallowEqual);
    const givenNameRef = useRef();
    const familyNameRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if(!givenNameRef.current) { return; }
        givenNameRef.current.focus();
        givenNameRef.current.select();
    });

    if(!state) {
        return null;
    }
    const { learner, givenName, familyName } = state;

    const close = () => dispatch(openModalFor());
    const save = () => {
        dispatch(updateLearnerName(learner, givenNameRef.current.value, familyNameRef.current.value));
        dispatch(openModalFor());
    };
    const saveOnEnter = e => {
        if(e.which !== 13) { return; }
        save();
    };

    return (
        <div className="edit-learner-container">
            <div className="edit-learner-modal">
                <button onClick={close} className="edit-learner-modal--close" />
                <h1>Edit student account</h1>
                <div className="edit-learner--given-name">
                    <label htmlFor="learner--given-name-input" className="edit-learner--label">Given name</label>
                    <input ref={givenNameRef} id="learner--given-name-input" className="edit-learner--input" defaultValue={givenName} onKeyPress={saveOnEnter} />
                </div>
                <div className="edit-learner--family-name">
                    <label htmlFor="learner--family-name-input" className="edit-learner--label">Family name</label>
                    <input ref={familyNameRef} id="learner--family-name-input" className="edit-learner--input" defaultValue={familyName} onKeyPress={saveOnEnter} />
                </div>
                <div className="edit-learner--button-container">
                    <button onClick={close} className="edit-learner--cancel">Cancel</button>
                    <button onClick={save} className="edit-learner--save">Save</button>
                </div>
            </div>
        </div>
    )
};
