export default {
    fonts: {
        body: {
            family: "Roboto Condensed, sans-serif",
            weight: "300",
            color: "#fff",
        },
        bodyHeader1: {
            family: "Orbitron, sans-serif",
            weight: "500",
            color: "#fff",
            size: "4.75rem",
        },
        bodyHeader2: {
            family: "Orbitron, sans-serif",
            weight: "500",
            color: "#ffa400",
            size: "3.75rem",
        },
        bodyHeader3: {
            family: "Rajdhani, sans-serif",
            weight: "600",
            color: "#77FAEE",
            size: "1.875rem",
        },
        title: {
            family: "Rajdhani, sans-serif",
            weight: "500",
            color: "#047066",
            size: "3em",
            textTransform: "lowercase"
        },
        userName: {
            family: "Rajdhani, sans-serif",
            weight: "400",
            color: "#fff",
            size: "1.5em"
        },
        headerButtons: {
            family: "Rajdhani, sans-serif",
            weight: "600",
            color: "#fff",
            size: "1.25em",
        },
        logoutButton: {
            family: "Rajdhani, sans-serif",
            weight: "600",
            color: "#121A1D",
            size: "1.25em",
        }
    },
    header: {
        background: "rgba(1, 17, 16, 0.5)",
        borderColor: "rgba(255, 255, 255, 0.19)",
        padding: "0 43px",
        buttonBackground: "transparent",
    },
    body: {
        background: "linear-gradient(246.37deg, #020303 0%, #012825 73.45%)",
    },
    continueButton: { // TODO: probably dump this?
        color: "#77FAEE",
        background: "transparent",
        borderColor: "#77FAEE",
    },
    primaryColor: "#77FAEE",
    secondaryColor: "#FFA400",
};
