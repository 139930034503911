import {call, takeEvery, select} from 'redux-saga/effects';
import {
    getUtilityButtonBehavior,
    getUtilityButtonGlobalItemId
} from "../../../store/curriculumTaskItems/utilityButton/selectors";
import {sendGraphQLSaga} from "../../DataService";
import {
    SET_UTILITY_BUTTON_BEHAVIOR,
    SET_UTILITY_BUTTON_GLOBAL_ITEM_ID, SET_UTILITY_BUTTON_TEXT
} from "../../../store/curriculumTaskItems/utilityButton/actions";

const setUtilityButtonBehavior = `mutation SetUtilityButtonBehavior($input: SetUtilityButtonBehaviorInput!) {
    setUtilityButtonBehavior(input: $input) {
        utilityButton {
            id
        }
    }
}`;
function* executeSetUtilityButtonBehavior(action) {
    const {buttonId, curriculum} = action;
    const behavior = yield select(state => getUtilityButtonBehavior(state, buttonId));
    const globalItemId = yield select(state => getUtilityButtonGlobalItemId(state, buttonId));

    const variables = {
        input: {
            utilityButton: buttonId,
            curriculum,
            behavior,
            globalItemId,
        },
    };

    yield call(sendGraphQLSaga, setUtilityButtonBehavior, variables);
}

const setUtilityButtonText = `mutation SetUtilityButtonText($input: SetUtilityButtonTextInput!) {
    setUtilityButtonText(input: $input) {
        utilityButton {
            id
        }
    }
}`;
function* executeSetUtilityButtonText(action) {
    const {buttonId, curriculum, text} = action;

    const variables = {
        input: {
            utilityButton: buttonId,
            curriculum,
            text,
        },
    };

    yield call(sendGraphQLSaga, setUtilityButtonText, variables);
}

export default [
    takeEvery(SET_UTILITY_BUTTON_BEHAVIOR, executeSetUtilityButtonBehavior),
    takeEvery(SET_UTILITY_BUTTON_GLOBAL_ITEM_ID, executeSetUtilityButtonBehavior),
    takeEvery(SET_UTILITY_BUTTON_TEXT, executeSetUtilityButtonText),
];
