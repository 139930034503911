import * as styles from './context-menu-style';
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getCrossCardNumberOfOffspring} from "../../../../../../store/crossCards/selectors";
import {setCrossCardNumberOfOffspring} from "../../../../../../store/crossCards/actions";
import {useParams} from "@reach/router";

export default props => {
    const {id} = props;
    const {curriculumId} = useParams();
    const numberOfOffspring = useSelector(getCrossCardNumberOfOffspring.bind(null, id));
    const dispatch = useDispatch();

    const increment = () => dispatch(setCrossCardNumberOfOffspring(curriculumId, id, numberOfOffspring+1));
    const decrement = () => dispatch(setCrossCardNumberOfOffspring(curriculumId, id, numberOfOffspring-1));

    return (
        <div css={styles.container}>
            <div css={styles.menuLabel}>Offspring:</div>
            <div css={styles.childrenContainer}>
                <button onClick={decrement} css={styles.childrenButton} disabled={numberOfOffspring <= 1}>-</button>
                <span css={styles.childrenDisplay}>{numberOfOffspring}</span>
                <button onClick={increment} css={styles.childrenButton}>+</button>
            </div>
        </div>
    );
}
