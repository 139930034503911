// please deprecate and remove
export const setPunnettSquareQuestionAnswersQuery = `mutation SetPunnettSquareQuestionAnswers($input: SetPunnettSquareQuestionAnswersInput!) {
    setPunnettSquareQuestionAnswers(input: $input) {
        punnettSquare {
            id
        }
    }
}`;
export const setPunnettSquareQuestionAnswersVariables = (punnettSquareId, answers) => ({
    input: {
        punnettSquare: punnettSquareId,
        questionAnswers: answers,
    },
});
