import {getEmailForLearner, getFamilyNameForLearner, getGivenNameForLearner} from "../learners/selectors";

export const getAllCohortIds = state => Object.keys(state.cohorts);
export const getCohort = (state, cohortId) => state.cohorts[cohortId];
export const getCohortName = (state, cohortId) => getCohort(state, cohortId).name;
export const getCohortLearnerIdentityInfo = (state, cohortId) => getCohort(state, cohortId).learners.map(id => ({
    id,
    email: getEmailForLearner(state, id),
    familyName: getFamilyNameForLearner(state, id),
    givenName: getGivenNameForLearner(state, id),
}));
export const getCohortLearnerIds = (state, cohortId) => getCohort(state, cohortId).learners;
export const getCohortWorkBoardIds = (state, cohortId) => getCohort(state, cohortId).workBoards;
export const getCohortCurricula = (state, cohortId) => getCohort(state, cohortId).curricula;
