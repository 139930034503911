import {
    Antibody, DefaultCargo,
    EnergyProteinConvertor,
    EnergyProteinRotor,
    EnergyProteinStabilizer, Motor, ProteinForm_Denatured,
    ProteinForm_ProteinInVesicle, Recycle, Structural, Transport
} from "../../../constants/DogmaDash";

const getDogmaDashInteraction = (state, dogmaId) => state.dogmaDashInteractions[dogmaId];
export const getDogmaMrnaSlotIds = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.dashMrnaSlots ?? [];
export const getDogmaMitochondriaIds = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.dashMitochondria ?? [];
export const getDogmaCellMembraneIds = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.dashCellMembrane ?? [];
export const getDogmaLysosomeIds = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.dashLysosome ?? [];
export const getDogmaGolgiBodyIds = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.dashGolgiBody ?? [];
export const getDogmaRibosomeIds = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.dashRibosome ?? [];
export const getDogmaMicrotubuleIds = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.dashMicrotubules ?? [];
export const getDogmaCentrioles = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.dashCentrioles ?? [];
export const getDogmaDashCytoplasm = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.dashCytoplasm;
export const getDogmaDashCurrentView = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.currentView;
export const getDogmaDashAvailableProteins = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.unlockedProteins ?? [];// [EnergyProteinRotor, EnergyProteinStabilizer, Motor, Transport, Antibody, Recycle, Structural];
export const getDogmaDashLevelUnlockingProtein = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.levelUnlockingProtein;
export const getCurrentDogmaDashScore = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.currentScore;
export const getDogmaDashFinalScore = (state, dogmaId) => {
    const interaction = getDogmaDashInteraction(state, dogmaId);
    return interaction.levelScores?.find(l => l.level === interaction.currentLevel && l.stage === interaction.currentStage)?.score ?? interaction.currentScore;
};
export const getDogmaDashProteinsDelivered = (state, dogmaId) => {
    const interaction = getDogmaDashInteraction(state, dogmaId);
    return interaction.levelScores?.find(l => l.level === interaction.currentLevel && l.stage === interaction.currentStage)?.deliveries ?? 0;
};
export const getDogmaDashLevelStars = (state, dogmaId) => {
    const interaction = getDogmaDashInteraction(state, dogmaId);
    return interaction.levelScores?.find(l => l.level === interaction.currentLevel && l.stage === interaction.currentStage)?.stars ?? 0;
};
export const getDogmaDashLevelsInStage = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId).currentLevelStageFinalLevel;
export const isDogmaDashProteinUnlockingNeeded = (state, dogmaId) => {
    const unlockProtein = getDogmaDashLevelUnlockingProtein(state, dogmaId);
    const unlockedProteins = getDogmaDashAvailableProteins(state, dogmaId);
    if(!unlockProtein || unlockedProteins.indexOf(unlockProtein) >= 0) { return false; }
    if(getDogmaDashAvailableProteins(state, dogmaId).indexOf(unlockProtein) >= 0) { return false; }
    if(unlockProtein === EnergyProteinRotor || unlockProtein === EnergyProteinConvertor || unlockProtein === EnergyProteinStabilizer) {
        return getDogmaMitochondriaIds(state, dogmaId).some(o => getOrganelleAlert(state, o));
    } else if(unlockProtein === Transport) {
        return getDogmaCellMembraneIds(state, dogmaId).some(o => getOrganelleAlert(state, o));
    } else if(unlockProtein === Recycle) {
        return getDogmaLysosomeIds(state, dogmaId).some(o => getOrganelleAlert(state, o));
    } else if(unlockProtein === Structural) {
        return getDogmaMicrotubuleIds(state, dogmaId).some(o => getOrganelleAlert(state, o));
    } else if(unlockProtein === Motor) {
        return getDogmaCentrioles(state, dogmaId).some(o => getOrganelleAlert(state, o));
    } else if(unlockProtein === Antibody) {
        return true;
    }
    return false;
};
export const getAccumulatedCargoForAlert = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.currentLevelCargoAlertThreshold;
export const getAccumulatedVirusForAlert = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.currentLevelViralAlertThreshold;
export const getLysosomeEatingThreshold = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.currentLevelLysosomeConsumptionThreshold;
export const getDogmaDashLevelStartTime = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.currentLevelStartTimeMs
export const getDogmaDashLevelDuration = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.currentLevelDurationMs
export const getCurrentDogmaDashLevel = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.currentLevel;
export const getCurrentDogmaDashStage = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.currentStage;
export const getDogmaDashHighlightedGene = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.highlightedGene;
export const getDogmaDashPortalNucleotide = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.portalNucleotide;
export const getDogmaDashPortalTrna = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId)?.portalTrna;
export const getDogmaDashTranscriptionNucleotides = (state, dogmaId) => {
    const translation = getDogmaDashTranslationNucleotides(state, dogmaId);
    if(translation && translation.length > 0) {
        const nucleotides = getDogmaDashInteraction(state, dogmaId).transcriptionNucleotides;
        return nucleotides.map(n => ({given: n.given, paired: null}));
    }
    return getDogmaDashInteraction(state, dogmaId).transcriptionNucleotides;
}
export const isTranscriptionComplete = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId).isTranscriptionComplete;
export const getDogmaDashTranslationNucleotides = (state, dogmaId) => getDogmaDashInteraction(state, dogmaId).translationNucleotides;

const getOrganelleState = (state, organelleId) => state.dogmaDashOrganelles[organelleId];
export const getObjectsInOrganelle = (state, organelleId) => getOrganelleState(state, organelleId)?.heldObjects ?? [];
export const getOrganelleDestroyedObjects = (state, organelleId) => getOrganelleState(state, organelleId)?.destroyedObjects ?? [];
export const getOrganelleTimerStart = (state, organelleId) => getOrganelleState(state, organelleId)?.timerStartTimeMs;
export const getOrganelleTimerDuration = (state, organelleId) => getOrganelleState(state, organelleId)?.timerDurationMs;
export const getOrganelleTimerType = (state, organelleId) => getOrganelleState(state, organelleId)?.timerType;
export const getOrganelleAlert = (state, organelleId) => getOrganelleState(state, organelleId)?.alertDescription;
export const getOrganelleBroken = (state, organelleId) => getOrganelleState(state, organelleId)?.broken;
export const isLysosomeEating = (state, dogmaId, objectId) => getDogmaLysosomeIds(state, dogmaId).some(l => {
    if(!getOrganelleState(state, l).eating) {return false;}
    const target = getLysosomeTargetOrganelleId(state, l);
    return target && getObjectsInOrganelle(state, target).indexOf(objectId) >= 0;
});

const getObjectState = (state, objectId) => state.dogmaDashObjects[objectId];
export const getObjectType = (state, objectId) => getObjectState(state, objectId)?.type;
export const getObjectForm = (state, objectId) => getObjectState(state, objectId)?.form;
// NOTE: this would not work with multiple dogma dashes in a curriculum
export const getNumberOfCargo = (state) => {
    return Object.keys(state.dogmaDashObjects).filter(o => {
        const object = getObjectState(state, o);
        return object.type === DefaultCargo && !object.destroyed && object.form !== ProteinForm_Denatured;
    }).length;
};

export const getFirstHeldObjectType = (state, organelleId) => {
    const objectId = getObjectsInOrganelle(state, organelleId)[0];
    return objectId
        ? getObjectType(state, objectId)
        : null;
};

export const getLysosomeTargetX = (state, lysosomeId) => getOrganelleState(state, lysosomeId)?.targetX;
export const getLysosomeTargetY = (state, lysosomeId) => getOrganelleState(state, lysosomeId)?.targetY;
export const getLysosomeTargetOrganelleId = (state, lysosomeId) => getOrganelleState(state, lysosomeId)?.targetOrganelle;
export const getLysosomeTargetStartTime = (state, lysosomeId) => getOrganelleState(state, lysosomeId)?.targetStartTime;
export const getLysosomeTargetDuration = (state, lysosomeId) => getOrganelleState(state, lysosomeId)?.targetDuration;
export const getLysosomeTargetSetBy = (state, lysosomeId) => lysosomeId ? getOrganelleState(state, lysosomeId).targetSetBy : null;
export const canLysosomeEat = (state, lysosomeId) => getObjectsInOrganelle(state, lysosomeId).filter(o => getObjectForm(state, o) === ProteinForm_ProteinInVesicle).length > 0;
export const getAmountLysosomeHasEaten = (state, lysosomeId) => getOrganelleState(state, lysosomeId).eaten ?? 0;

export const getMotorProteinDestination = (state, motorProteinId) => getObjectState(state, motorProteinId).movingTowards || 0;
export const getMotorProteinMovementStartTime = (state, motorProteinId) => getObjectState(state, motorProteinId).movementStartTime;
export const getMotorProteinMovementDuration = (state, motorProteinId) => getObjectState(state, motorProteinId).movementDuration;
