import {css} from "@emotion/core";
import {dogmashLightOrange, dogmashRed} from "./dogma-dash-styles";

export const container = css({
    flex: '0 0 auto',
    minWidth: "38px",
    position: 'relative',
    marginRight: "14px",

    "&:last-of-type": {
        marginRight: 0,
    },
});

export const timerContainer = css({
    width: "7px",
    height: "41px",
    border: `3px solid ${dogmashLightOrange}`,
    borderRadius: "12px",
    position: "absolute",
    left: "calc(100% + 3px)",
    top: "-3px",
    overflow: "hidden",
});

export const timer = css({
    width: "100%",
    backgroundColor: "#14FF00",
    borderRadius: "inherit",
    position: "absolute",
    bottom: 0,
    left: 0,
});
export const burnTimer = css(timer, {
    backgroundColor: dogmashRed,
});

export const tutorial = css({
    margin: "8px 0 0 18px",
});
