import {css} from "@emotion/core";
import {AVENIR_FONT} from "../../../../style-variables";

export const SCROLLING_CANVAS_WIDTH = 1226;

export const outerContainer = css({
    height: "100%",
});

export const scrollerContainer = css({
    display: "inline-block",
    width: SCROLLING_CANVAS_WIDTH,
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    border: "1px solid #dfdfdf",
    fontFamily: `${AVENIR_FONT}, sans-serif`,
});

export const content = css({
    width: "100%",
    position: "relative",
});
