import {getCognitoAccessToken, getCognitoAccessTokenSaga} from "../../Identity/utility";
import {GraphQLClient} from "graphql-request";
import {WebSocketLink} from "apollo-link-ws";
import ApolloClient from "apollo-client";
import {InMemoryCache, IntrospectionFragmentMatcher} from "apollo-cache-inmemory";
import {call, select, put} from 'redux-saga/effects';
import possibleTypes from '../../../build/possibleTypes.json';
import {getLTIKey} from "../identity/selectors";
import {setAuthenticationState} from "../identity/actions";
import {NOT_LOGGED_IN} from "../../constants/AuthenticationState";

const GRAPH_QL_ENDPOINT = "/graphql";

let graphQLClient;
export const getDataClient = async (dispatch) => {
    const accessToken = await getCognitoAccessToken(dispatch);
    if(!accessToken) { return null; }
    if(!graphQLClient) {
        graphQLClient = new GraphQLClient(GRAPH_QL_ENDPOINT, { headers: { access_token: accessToken } })
    } else {
        graphQLClient.options.headers.access_token = accessToken;
    }
    return graphQLClient;
};
export function* getDataClientSaga() {
    const accessToken = (yield select(getLTIKey)) ?? (yield call(getCognitoAccessTokenSaga));
    if(!accessToken) {
        yield put(setAuthenticationState(NOT_LOGGED_IN));
        return null;
    }
    if(!graphQLClient) {
        graphQLClient = new GraphQLClient(GRAPH_QL_ENDPOINT, { headers: { access_token: accessToken } })
    } else {
        graphQLClient.options.headers.access_token = accessToken;
    }
    return graphQLClient;
}

let graphQLSubscriptionClient;
export const getSubscriptionClient = () => {
    if(!graphQLSubscriptionClient) {
        const wsLink = new WebSocketLink({
            uri: GRAPHQL_WEBSOCKET_URI,
            options: { reconnect: true },
        });
        graphQLSubscriptionClient = new ApolloClient({
            link: wsLink,
            cache: new InMemoryCache({
                fragmentMatcher: new IntrospectionFragmentMatcher({introspectionQueryResultData: possibleTypes}),
            }),
        });
    }
    return graphQLSubscriptionClient;
};

export const getDataService = state => state.data.dataService;
