import * as styles from './scrolling-styles';
import React, {useEffect, useRef} from 'react';
import {useParams} from "@reach/router";
import {useDrop} from "react-dnd";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getCurriculumTaskHeight, getCurriculumTaskItemIds} from "../../../../store/curriculumTask/selectors";
import {setCurriculumTaskHeight} from "../../../../store/curriculumTask/actions";
import ScrollingItem, {SCROLLING_DRAG_ITEM} from "./ScrollingItems";
import {isModalOpenFor} from "../../../../store/navigation/selectors";
import {ADD_SPACE_TO_SCROLLLING_CANVAS, SCROLLER_MENU} from "../../../../constants/modalTypes";
import {closeModalType, openModalFor} from "../../../../store/navigation/actions";
import Menu from "./Menus/CreateMenu";
import {anyItemSelected} from "../../../../store/itemSelection/selector";
import {setScrollingItemSelected} from "../../../../store/scrollingTaskItemPositions/actions";
import {getScrollingItemPositions} from "../../../../store/scrollingTaskItemPositions/selectors";
import SpaceAdder from "./ScrollingItems/SpaceAdder";

export default () => {
    const {curriculumId, taskId} = useParams();
    const height = useSelector(state => getCurriculumTaskHeight(taskId, state));
    const items = useSelector(state => getCurriculumTaskItemIds(state, taskId), shallowEqual);
    const itemPositions = useSelector(state => items.map(i => getScrollingItemPositions(i, state)), shallowEqual);
    const showMenu = useSelector(state => isModalOpenFor(state, SCROLLER_MENU, taskId));
    const anythingSelected = useSelector(anyItemSelected, shallowEqual);
    const showAddSpace = useSelector(state => isModalOpenFor(state, ADD_SPACE_TO_SCROLLLING_CANVAS, taskId));
    const dispatch = useDispatch();
    const scroller = useRef();

    useEffect(() => {
        if(height === 0) {
            const {height} = scroller.current.getBoundingClientRect();
            dispatch(setCurriculumTaskHeight(curriculumId, taskId, height * 1.1));
        }
    });

    useEffect(() => {
        const increaseScrollingLength = () => {
            const {height: scrollerHeight} = scroller.current.getBoundingClientRect();
            const threshold = Math.min(0.9 * height, height - 100);
            if(scroller.current.scrollTop + scrollerHeight > threshold) {
                dispatch(setCurriculumTaskHeight(curriculumId, taskId, height + 200));
            }
        };

        scroller.current.addEventListener("scroll", increaseScrollingLength);

        return () => scroller.current && scroller.current.removeEventListener("scroll", increaseScrollingLength);
    }, [scroller.current, height]);

    const [_, dropRef] = useDrop({
        accept: SCROLLING_DRAG_ITEM,
        drop: (item, monitor) => {
            const {x: deltaX, y: deltaY} = monitor.getDifferenceFromInitialOffset();
            return {
                deltaX,
                deltaY,
            };
        }
    });

    const toggleCreateMenuShowing = e => {
        e.preventDefault();
        if(anythingSelected){
            return dispatch(setScrollingItemSelected(curriculumId));
        }
        if(!showMenu) {
            const {top, left} = scroller.current.getBoundingClientRect();
            const x = e.clientX - left;
            const y = e.clientY - top + scroller.current.scrollTop;
            dispatch(openModalFor(SCROLLER_MENU, taskId, { x, y }));
        } else {
            dispatch(closeModalType(SCROLLER_MENU));
        }
    }
    const closeCreateMenu = e => {
        if(anythingSelected){
            dispatch(setScrollingItemSelected(curriculumId));
        }
        if(showMenu) {
            dispatch(closeModalType(SCROLLER_MENU));
        }
    }

    const prune = () => {
        const lastY = itemPositions.reduce((y, p) => {
            let itemY = p[0].y + (p[0].height || 100);
            if(p[1].y) {
                itemY = Math.max(itemY, p[1].y + (p[1].height || 100));
            }
            return Math.max(y, itemY);
        }, 0);
        const minHeight = scroller.current.getBoundingClientRect().height * 1.1;
        dispatch(setCurriculumTaskHeight(curriculumId, taskId, Math.max(minHeight, lastY + 200)));
    };

    return (
        <div css={styles.outerContainer}>
            <div ref={scroller} css={styles.scrollerContainer}>
                <div ref={dropRef} onContextMenu={toggleCreateMenuShowing} onClick={closeCreateMenu} css={styles.content} style={{height}}>
                    {showMenu && <Menu />}
                    {items.map(i => <ScrollingItem key={i} id={i} scroller={scroller} />)}
                </div>
            </div>
            {showAddSpace && <SpaceAdder scroller={scroller}/>}
            <button onClick={prune}>Prune</button>
        </div>
    );
};
