import {css} from "@emotion/core";
import {FONT_BOLD_WEIGHT} from "../../../../../style-variables";
import adenineImage from "./Assets/adenine.png";
import guanineImage from "./Assets/guanine.png";
import thymineImage from "./Assets/thymine.png";
import cytosineImage from "./Assets/cytosine.png";
import uracilImage from "./Assets/uracil.png";

export const adenineColor = "#7de18a";
export const guanineColor = "#81c4e4";
export const thymineColor = "#dd5345";
export const cytosineColor = "#e5df5a";
export const uracilColor = "#e0857c";

const nucleotideHeader = css({
    fontFamily: "Rajdhani, sans-serif",
    fontSize: "1.5rem",
    lineHeight: "1em",
    fontWeight: 700,
    textAlign: "center",

    "&:first-of-type": {
        marginBottom: "2px",
    },
    "&:last-of-type": {
        marginTop: "10px",
    },
});
const nucleotideBase = css({
    width: "117.9%",
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
});
export const adenineHeader = css(nucleotideHeader, {color: adenineColor});
export const adenine = css(nucleotideBase, {
    height: "116.9%",
    backgroundImage: `url(${adenineImage})`,
});
export const fullSizeAdenineContainer = css({ width: "28px", height: "65px", marginBottom: "5px" });
export const guanineHeader = css(nucleotideHeader, {color: guanineColor});
export const guanine = css(nucleotideBase, {
    height: "116.9%",
    marginLeft: "-9%",
    backgroundImage: `url(${guanineImage})`,
});
export const fullSizeGuanineContainer = css({ width: "28px", height: "65px", marginBottom: "5px" });
export const thymineHeader = css(nucleotideHeader, {color: thymineColor});
export const thymine = css(nucleotideBase, {
    height: "133.3%",
    marginLeft: "-17.9%",
    backgroundImage: `url(${thymineImage})`,
});
export const fullSizeThymineContainer = css({ width: "28px", height: "33px", marginBottom: "5px" });
export const cytosineHeader = css(nucleotideHeader, {color: cytosineColor});
export const cytosine = css(nucleotideBase, {
    height: "133.3%",
    backgroundImage: `url(${cytosineImage})`,
    backgroundSize: "84.82% 100%"
});
export const fullSizeCytosineContainer = css({ width: "28px", height: "33px", marginBottom: "5px" });
export const uracilHeader = css(nucleotideHeader, {color: uracilColor});
export const uracil = css(nucleotideBase, {
    height: "133.3%",
    marginLeft: "-17.9%",
    backgroundImage: `url(${uracilImage})`,
});
export const fullSizeUracilContainer = css({ width: "28px", height: "33px", marginBottom: "5px" });
export const nucleotideBottom = css({transform: "scaleY(-1)"});
