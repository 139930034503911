import * as styles from "./create-menu-styles";
import React from 'react';
import {useParams} from "@reach/router";
import {modalAdditionalData} from "../../../../../store/navigation/selectors";
import {ADD_SPACE_TO_SCROLLLING_CANVAS, SCROLLER_MENU} from "../../../../../constants/modalTypes";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    FULL_WIDTH_TYPES,
    SCROLLER_EXPLAINER, SCROLLER_SECTION, SCROLLER_WORK_BOARD,
    SCROLLING_DATA_ITEM,
    SCROLLING_DROP_SLOT_ITEM,
    SCROLLING_IMAGE_ITEM,
    SCROLLING_SHAPE_ITEM,
    SCROLLING_TEXT_ITEM
} from "../../../../../constants/TaskTypes";
import {addItemToScrollingTask, setScrollingItemSelected} from "../../../../../store/scrollingTaskItemPositions/actions";
import uuid from 'uuid/v4';
import {css} from "@emotion/core";
import {openModalFor} from "../../../../../store/navigation/actions";

const getPosition = id => state => modalAdditionalData(state, SCROLLER_MENU, id);

const CREATABLE = [{
    type: SCROLLING_TEXT_ITEM,
    description: "Text",
}, {
    type: SCROLLING_IMAGE_ITEM,
    description: "Image",
}, {
    type: SCROLLING_SHAPE_ITEM,
    description: "Shape",
}, {
    type: SCROLLING_DROP_SLOT_ITEM,
    description: "Drop area"
}, {
    type: SCROLLING_DATA_ITEM,
    description: "VR Data Item",
}, {
    type: SCROLLER_EXPLAINER,
    description: "Explanation Builder",
}, {
    type: SCROLLER_SECTION,
    description: "Section",
},{
    type: SCROLLER_WORK_BOARD,
    description: "Workboard",
}];

export default () => {
    const {curriculumId, taskId} = useParams();
    const {x, y} = useSelector(getPosition(taskId), shallowEqual);
    const dispatch = useDispatch();

    const styling = css(styles.menu, {
        left: `${x}px`,
        top: `${y}px`,
    });

    const create = itemType => {
        const id = uuid();
        const xPosition = FULL_WIDTH_TYPES.includes(itemType) ? 0 : x;
        dispatch(addItemToScrollingTask(curriculumId, taskId, id, itemType, xPosition, y));
        dispatch(setScrollingItemSelected(curriculumId, id, true));
    };

    const openAddSpace = () => dispatch(openModalFor(ADD_SPACE_TO_SCROLLLING_CANVAS, taskId));

    return (
        <div css={styling}>
            {CREATABLE.map(({type, description}) => (
                <button key={`create-${type}-button`} onClick={create.bind(null, type)} css={styles.button}>
                    <div>{description}</div>
                </button>
            ))}
            <div css={styles.separator} />
            <button onClick={openAddSpace} css={styles.button}>
                <div>Add canvas space</div>
            </button>
        </div>
    );
}
