import {getCurriculumTaskType} from "../curriculumTask/selectors";
import {GLOBAL_TASK} from "../../constants/TaskTypes";

export const getMasterCurriculaIds = state => Object.keys(state.curricula).filter(id => isCurriculumMaster(state, id));
export const getAllCurriculaIds = state => Object.keys(state.curricula);
const getCurriculum = (state, id) => state.curricula[id];
const isCurriculumMaster = (state, id) => getCurriculum(state, id).master;
export const getCurriculumName = (state, id) => getCurriculum(state, id)?.name ?? "";
export const getCurriculumType = (state, id) => getCurriculum(state, id)?.type;
export const getCurriculumTaskIds = (state, id) => {
    const curriculum = getCurriculum(state, id);
    return curriculum ? curriculum.tasks : [];
};
export const getCurriculumParent = (state, id) => {
    const curriculum = getCurriculum(state, id);
    return curriculum ? curriculum.parentCurriculum : "";
};

export const getGlobalTaskId = (state, curriculumId) => getCurriculumTaskIds(state, curriculumId).find(t => getCurriculumTaskType(t, state) === GLOBAL_TASK);
