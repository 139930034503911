import * as styles from './intro-help-dialog-styles';
import {useDispatch} from "react-redux";
import {modalBlocker} from "../../../../style-variables";
import CloseX from '../../../../inline-assets/close.svg';
import {setDogmaDashView} from "../../../../store/interactions/dogmaDash/actions";
import {CELL_VIEW} from "../../../../constants/DogmaDash";
import {openModalFor} from "../../../../store/navigation/actions";
import {HEADSET_PAIRING_MODAL} from "../../../../constants/modalTypes";

const IntroHelpDialog = ({id}) => {
    const dispatch = useDispatch();

    const close = () => {
        dispatch(openModalFor(HEADSET_PAIRING_MODAL));
        dispatch(setDogmaDashView(id, CELL_VIEW));
    };

    return (
        <div css={modalBlocker}>
            <div css={styles.container}>
                <button css={styles.close} onClick={close}><CloseX /></button>
                <h2 css={styles.header}>Mission Control</h2>
                <div css={styles.bodyText}>Help your partner get VR set up using the<br />videos below</div>
                <div css={styles.videosContainer}>
                    <div css={styles.videoContainer}>
                        <iframe src="https://www.youtube.com/embed/jFL0f5YzwD8"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                    <div css={styles.videoContainer}>
                        <iframe src="https://www.youtube.com/embed/vDztNWD-ieA"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                    <div css={styles.videoContainer}>
                        <iframe src="https://www.youtube.com/embed/rXSas-58O1E"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                    <div css={styles.videoContainer}>
                        <iframe src="https://www.youtube.com/embed/5EgfHIxaRAw"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                </div>
                <button css={styles.button} onClick={close}>My partner opened the app in VR</button>
            </div>
        </div>
    )
};

export default IntroHelpDialog;
