import {REPLACE_LEARNERS_IN_COHORT, SET_ALL_COHORTS} from "../cohorts/actions";
import {
    ADD_PARTNER_TO_LEARNER,
    BOUNTY_PROGRESS_UPDATED,
    CREATE_PARTNER_REQUEST,
    LEARNER_WORK_BOARDS_LOADED,
    PARTNER_REQUEST_LOADED,
    PARTNER_REQUEST_RESPONDED_TO,
    REMOVE_PARTNER_FROM_LEARNER,
    RESPOND_TO_PARTNER_REQUEST,
    UPDATE_LEARNER_NAME,
    VR_START_TIME_UPDATED
} from "./actions";
import {omit, union} from 'lodash';
import {SET_AUTHENTICATION_STATE, SET_LEARNER_DATA} from "../identity/actions";
import {NOT_LOGGED_IN} from "../../constants/AuthenticationState";

const learnerPrototype = {
    id: null,
    givenName: "",
    familyName: "",
    email: "",
    workBoards: [],
    bountyAssignments: {},
    vrSessionStartTime: null,
    partners: {},
    requestedPartners: {}, // people this learner asked to be partners with
    partnerRequests: {}, // people who have asked this learner to be partners with
};

export default (state = {}, action) => {
    if(action.type === SET_ALL_COHORTS) {
        return Object.keys(action.cohorts).reduce((agg, id) => {
            const cohort = action.cohorts[id];
            cohort.learners.forEach(l => agg[l.id] = Object.assign({}, learnerPrototype, l));
            return agg;
        }, {});
    }
    if(action.type === LEARNER_WORK_BOARDS_LOADED) {
        const learner = state[action.learnerId];
        const workBoards = action.workBoards.map(w => w.id);
        return Object.assign({}, state, {
            [action.learnerId]: Object.assign({}, learner, {
                workBoards: union(learner.workBoards, workBoards),
            }),
        });
    }
    if(action.type === REPLACE_LEARNERS_IN_COHORT) {
        const newLearners = action.learners.filter(l => !state.hasOwnProperty(l.id)).reduce((agg, l) => {
            agg[l.id] = Object.assign({}, learnerPrototype, l);
            return agg;
        }, {});
        return Object.assign({}, state, newLearners);
    }
    if(action.type === UPDATE_LEARNER_NAME) {
        const {learnerId, givenName, familyName} = action;
        return {
            ...state,
            [learnerId]: {
                ...state[learnerId],
                givenName,
                familyName,
            },
        };
    }
    if(action.type === SET_LEARNER_DATA) {
        let learnerData = omit(action, 'type');
        if(state[action.id]) {
            learnerData = {
                ...state[action.id],
                ...learnerData,
            };
        } else {
            learnerData = {
                ...learnerPrototype,
                ...learnerData,
            };
        }
        return {
            ...state,
            [action.id]: learnerData,
        };
    }
    if(action.type === BOUNTY_PROGRESS_UPDATED) {
        const {learnerId, bountyId, progress} = action;
        const updatedAssignments = Object.assign({}, state[learnerId].bountyAssignments, {
            [bountyId]: Object.assign({}, state[learnerId].bountyAssignments[bountyId], {
                progress,
            }),
        });
        return Object.assign({}, state, {
            [learnerId]: Object.assign({}, state[learnerId], {
                bountyAssignments: updatedAssignments,
            }),
        });
    }
    if(action.type === VR_START_TIME_UPDATED) {
        return Object.assign({}, state, {
            [action.learnerId]: Object.assign({}, state[action.learnerId], {
                vrSessionStartTime: action.startTime,
            }),
        });
    }
    if(action.type === ADD_PARTNER_TO_LEARNER) {
        const {learnerId, curriculumId, partnerId} = action;
        const learner = state[learnerId];
        let currentCurriculumPartners = learner.partners[curriculumId] || [];
        const partnerUpdate = {
            ...learner.partners,
            [curriculumId]: [...currentCurriculumPartners, partnerId],
        };
        let requested = learner.requestedPartners;
        if(requested[curriculumId] && requested[curriculumId].indexOf(partnerId) >= 0) {
            requested = {
                ...requested,
                [curriculumId]: requested[curriculumId].filter(id => id !== partnerId),
            };
        }
        let requests = learner.partnerRequests;
        if(requests[curriculumId] && requests[curriculumId].indexOf(partnerId) >= 0) {
            requests = {
                ...requests,
                [curriculumId]: requests[curriculumId].filter(id => id !== partnerId),
            };
        }
        return {
            ...state,
            [learnerId]: {
                ...learner,
                partners: partnerUpdate,
                requestedPartners: requested,
                partnerRequests: requests,
            },
        };
    }
    if(action.type === PARTNER_REQUEST_LOADED || action.type === CREATE_PARTNER_REQUEST) {
        const {requesterId, curriculumId, partnerId} = action;
        const requesterLearner = state[requesterId];
        let currentRequested = requesterLearner.requestedPartners[curriculumId] || [];
        const requestedUpdate = {
            ...requesterLearner.requestedPartners,
            [curriculumId]: [...currentRequested, partnerId],
        };

        const partnerLearner = state[partnerId];
        const currentRequests = partnerLearner.partnerRequests[curriculumId] || [];
        const requestsUpdate = {
            ...partnerLearner.partnerRequests,
            [curriculumId]: [...currentRequests, requesterId]
        }

        return {
            ...state,
            [requesterId]: {
                ...requesterLearner,
                requestedPartners: requestedUpdate,
            },
            [partnerId]: {
                ...partnerLearner,
                partnerRequests: requestsUpdate,
            },
        };
    }
    if(action.type === RESPOND_TO_PARTNER_REQUEST || action.type === PARTNER_REQUEST_RESPONDED_TO) {
        const {requesterId, curriculumId, partnerId, approve} = action;
        const requester = state[requesterId];
        const updatedRequests = requester.requestedPartners[curriculumId].filter(id => id !== partnerId);
        let currentPartners = requester.partners[curriculumId] || [];
        const requesterUpdatedPartners = approve
            ? currentPartners.indexOf(partnerId) < 0
                ? [...currentPartners, partnerId]
                : currentPartners
            : currentPartners;

        const partner = state[partnerId];
        const updatedRequesters = partner.partnerRequests[curriculumId].filter(id => id !== requesterId);
        currentPartners = partner.partners[curriculumId] || [];
        const partnerUpdatedPartners = approve
            ? currentPartners.indexOf(requesterId) < 0
                ? [...currentPartners, requesterId]
                : currentPartners
            : currentPartners;

        return {
            ...state,
            [requesterId]: {
                ...requester,
                partners: {
                    ...requester.partners,
                    [curriculumId]: requesterUpdatedPartners,
                },
                requestedPartners: {
                    ...requester.requestedPartners,
                    [curriculumId]: updatedRequests,
                },
            },
            [partnerId]: {
                ...partner,
                partners: {
                    ...partner.partners,
                    [curriculumId]: partnerUpdatedPartners,
                },
                partnerRequests: {
                    ...partner.partnerRequests,
                    [curriculumId]: updatedRequesters,
                },
            },
        };
    }
    if(action.type === REMOVE_PARTNER_FROM_LEARNER) {
        const {curriculumId, learnerId, partnerId} = action;
        let updatedLearner = {
            ...state[learnerId],
        };
        if(updatedLearner.partners[curriculumId] && updatedLearner.partners[curriculumId].some(p => p === partnerId)) {
            updatedLearner.partners = {
                ...updatedLearner.partners,
                [curriculumId]: updatedLearner.partners[curriculumId].filter(p => p !== partnerId)
            };
        }
        if(updatedLearner.requestedPartners[curriculumId] && updatedLearner.requestedPartners[curriculumId].some(p => p === partnerId)) {
            updatedLearner.requestedPartners = {
                ...updatedLearner.requestedPartners,
                [curriculumId]: updatedLearner.requestedPartners[curriculumId].filter(p => p !== partnerId),
            };
        }
        if(state[partnerId].partners[curriculumId]) {
            return {
                ...state,
                [learnerId]: updatedLearner,
                [partnerId]: {
                    ...state[partnerId],
                    partners: {
                        ...state[partnerId].partners,
                        [curriculumId]: state[partnerId].partners[curriculumId].filter(p => p !== learnerId),
                    },
                },
            };
        }
        return {
            ...state,
            [learnerId]: updatedLearner
        };
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    return state;
}
