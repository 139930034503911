import * as styles from './cross-card-style';
import React from 'react';
import YieldArrow from '../../../../../../inline-assets/yields-arrow.svg';
import {useSelector} from "react-redux";
import {getCrossCardNumberOfOffspring} from "../../../../../../store/crossCards/selectors";

export default props => {
    const {id} = props;
    const numberOfOffspring = useSelector(getCrossCardNumberOfOffspring.bind(null, id));

    return (
        <div css={styles.container}>
            <div css={styles.slot} />
            <div css={styles.crossSymbol}>x</div>
            <div css={styles.slot} />
            <div css={styles.yieldArrow}><YieldArrow /></div>
            {Array.from(new Array(numberOfOffspring), (_, i) => <div key={i} css={styles.slot} />)}
        </div>
    )
}
