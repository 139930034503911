import {call, select, takeEvery} from 'redux-saga/effects';
import {getActiveLearner} from "../../../store/navigation/selectors";
import {SET_EXPLAINER_PREDICTION} from "../../../store/interactions/explainerPrediction/actions";
import {sendGraphQLSaga} from "../../DataService";

const setLearnerPredictionForExplainer = `mutation SetLearnerPredictionForExplainer($input: SetLearnerPredictionForExplainerInput!) {
    setLearnerPredictionForExplainer(input: $input) {
        interaction {
            prediction
        }
    }
}`;

function* executeSetPrediction(action) {
    const learner = yield select(state => getActiveLearner(state));
    if(!learner) { return; }
    const variables = {
        input: {
            learner,
            curriculum: action.curriculumId,
            explainerPrediction: action.explainerPredictionId,
            prediction: action.prediction,
        },
    };

    yield call(sendGraphQLSaga, setLearnerPredictionForExplainer, variables);
}

export default [takeEvery(SET_EXPLAINER_PREDICTION, executeSetPrediction)];
