import * as styles from './work-board-style';
import React from 'react';
import {useParams} from "@reach/router";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getScrollerWorkBoardDefinition} from "../../../../../store/scrollerWorkBoards/selectors";
import WorkBoardDefinition from '../../Interactables/WorkBoard';
import {getAllWorkBoardDefinitionsInCurriculum} from "../../../../../store/curriculumCases/selectors";
import {getWorkBoardTitle} from "../../../../../store/curriculumTaskItems/workBoardDefinition/selectors";
import {setScrollerWorkBoardDefinition} from "../../../../../store/scrollerWorkBoards/action";


const ScrollerWorkBoard = ({id}) => {
    const {curriculumId} = useParams();
    const workBoardDefinition = useSelector(state => getScrollerWorkBoardDefinition(id, state));
    const allWorkBoardDefinitions = useSelector(state => getAllWorkBoardDefinitionsInCurriculum(curriculumId, state), shallowEqual);
    const workBoardTitles = useSelector(state => allWorkBoardDefinitions.map(d => getWorkBoardTitle(curriculumId, d, state)), () => true);
    const dispatch = useDispatch();

    const setWorkBoard = d => dispatch(setScrollerWorkBoardDefinition(curriculumId, id, d));

    return (
        <div css={styles.container}>
            {!workBoardDefinition && <div>
                <div>
                    <div>Choose a workboard to display</div>
                    {allWorkBoardDefinitions.map((d, i) => <button css={styles.definitionButton} key={d} onClick={() => setWorkBoard(d)}>{workBoardTitles[i]}</button>)}
                </div>
                <button>Create new</button>
            </div>}
            {workBoardDefinition && <WorkBoardDefinition id={workBoardDefinition} />}
        </div>
    );
};

export default ScrollerWorkBoard;
