import * as styles from "./mrna-inventory-styles";
import React from 'react';
import Protein from "./Protein";
import {useSelector} from "react-redux";
import {getDogmaDashAvailableProteins} from "../../../../store/interactions/dogmaDash/selectors";
import {DOGMA_DASH_MRNA_INVENTORY} from "../../../../store/tutorial/configuration/dogma-strand-1";
import PopUpTutorial from "../../../Tutorial/PopUpTutorial";
import {MRNA_INVENTORY_PROTEIN_TUTORIAL} from "../../../../store/tutorial/configuration/dogma-strand-antibody-unlock";

const MrnaInventory = ({dogmaId}) => {
    const availableSignals = useSelector(state => getDogmaDashAvailableProteins(state, dogmaId));
    const tutorialConfig = {dogmaDashId: dogmaId, location: DOGMA_DASH_MRNA_INVENTORY};

    return (
        <div css={styles.container}>
            <h3 css={styles.titleText}>mRNA Inventory</h3>
            <div css={styles.signalContainer}>
                {availableSignals.map((t, i) =>
                <div key={i} style={{position:"relative"}}>
                    <div css={styles.proteinTutorial}><PopUpTutorial positionData={{dogmaDashId: dogmaId, location: MRNA_INVENTORY_PROTEIN_TUTORIAL, protein: t}} horizontal={true} /></div>
                    <Protein type={t} />
                </div>
                )}
            </div>
            <div css={styles.tutorial}><PopUpTutorial positionData={tutorialConfig} /></div>
        </div>
    )
};

export default MrnaInventory;
