import {
    CREATE_PUNNETT_SQUARE, PUNNETT_SQUARES_LOADED, SET_PUNNETT_SQUARE_ENTRY_ALLELE, SET_PUNNETT_SQUARE_ENTRY_PHENOTYPE,
    SET_PUNNETT_SQUARE_PLANT, SET_PUNNETT_SQUARE_QUESTION_ANSWER,
    SET_PUNNETT_SQUARE_TRAIT
} from "./actions";
import {SEED_COLOR} from "../../constants/TraitNames";
import {UNKNOWN_SELECTION} from "../../constants/Zygosities";
import {SET_AUTHENTICATION_STATE} from "../identity/actions";
import {NOT_LOGGED_IN} from "../../constants/AuthenticationState";
import {CLONE_WORK_BOARD_ITEM} from "../workBoards/actions";

const emptyCross = () => [
    { alleleA: null, alleleB: null, phenotype: UNKNOWN_SELECTION },
    { alleleA: null, alleleB: null, phenotype: UNKNOWN_SELECTION },
    { alleleA: null, alleleB: null, phenotype: UNKNOWN_SELECTION },
    { alleleA: null, alleleB: null, phenotype: UNKNOWN_SELECTION },
];

const punnettSquarePrototype = {
    id: "",
    traits: [SEED_COLOR],
    leftPlant: null,
    topPlant: null,
    entries: emptyCross(),
    questionAnswers: [null, null, null, null, null],
};

export default (state = {}, action) => {
    if(action.type === CREATE_PUNNETT_SQUARE) {
        return Object.assign({}, state, {
            [action.id]: Object.assign({}, punnettSquarePrototype, {id: action.id}),
        });
    }
    if(action.type === CLONE_WORK_BOARD_ITEM) {
        const original = state[action.itemId];
        if(!original) { return state; }
        return {
            ...state,
            [action.newId]: {
                ...original,
                id: action.newId,
            }
        }
    }
    if(action.type === SET_PUNNETT_SQUARE_PLANT) {
        let newSquare;
        let entries = emptyCross();
        if(action.isOnLeft) {
            newSquare = Object.assign({}, state[action.id], { leftPlant: action.plantId, entries });
        } else {
            newSquare = Object.assign({}, state[action.id], { topPlant: action.plantId, entries });
        }
        return Object.assign({}, state, {
            [action.id]: newSquare,
        });
    }
    if(action.type === SET_PUNNETT_SQUARE_TRAIT) {
        return Object.assign({}, state, {
            [action.id]: Object.assign({}, state[action.id], {
                traits: [action.trait],
                entries: emptyCross(),
            }),
        });
    }
    if(action.type === SET_PUNNETT_SQUARE_ENTRY_ALLELE) {
        const square = state[action.id];
        const newEntries = square.entries.map((entry, index) => {
            if(action.y * 2 + action.x !== index) { return entry; }
            if(action.alleleIndex === 0) {
                return Object.assign({}, entry, { alleleA: action.alleleValue });
            }
            return Object.assign({}, entry, { alleleB: action.alleleValue });
        });
        return Object.assign({}, state, {
            [action.id]: Object.assign({}, square, {
                entries: newEntries,
            }),
        });
    }
    if(action.type === SET_PUNNETT_SQUARE_ENTRY_PHENOTYPE) {
        const square = state[action.id];
        const newEntries = square.entries.map((entry, index) => {
            if(action.y * 2 + action.x !== index) { return entry; }
            return Object.assign({}, entry, { phenotype: action.phenotype });
        });
        return Object.assign({}, state, {
            [action.id]: Object.assign({}, square, {
                entries: newEntries,
            }),
        });
    }
    if(action.type === PUNNETT_SQUARES_LOADED) {
        const punnettSquares = action.punnettSquares.reduce((agg, ps) => {
            if(!ps.traits.length) {
                ps.traits = punnettSquarePrototype.traits.slice();
            }
            agg[ps.id] = ps;
            return agg;
        }, {});
        return Object.assign({}, state, punnettSquares);
    }
    if(action.type === SET_PUNNETT_SQUARE_QUESTION_ANSWER) {
        const square = state[action.id];
        const updatedSquare = Object.assign({}, square, {
            questionAnswers: square.questionAnswers.map((a, i) => i === action.questionIndex ? action.answer : a),
        });
        return Object.assign({}, state, { [action.id]: updatedSquare });
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    return state;
}
