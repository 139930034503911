import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import {setAuthenticationState} from "../store/identity/actions";
import {ATTEMPTING_LOG_IN, LOGGED_IN, NOT_LOGGED_IN} from "../constants/AuthenticationState";
import pinMap from "./Learner/students";
import {call, put} from 'redux-saga/effects';

Amplify.configure({
    Auth: {
        region: "us-west-2",
        userPoolId: "us-west-2_0iSIhaEZ2",
        userPoolWebClientId: "6glidist99tlruhd138lhuv9pg",
        //cookieStorage: {} // TODO: look into better storage options
    }
});

export const getPINForEmail = email => {
    const loginData = pinMap.loginData.find(u => u.username === email);
    return loginData?.pin;
};

export const attemptLogin = async (username, password) => {
    try {
        const user = await Auth.signIn(username, password);
        return {
            logInSuccess: true,
            accessToken: user.getSignInUserSession().getAccessToken().getJwtToken(),
            refreshToken: user.getSignInUserSession().getRefreshToken().getToken(),
            idToken: user.getSignInUserSession().getIdToken().getJwtToken(),
        };
    } catch(e) {
        console.error(e);
        return {
            logInSuccess: false,
            error: e,
        };
    }
};

export const attemptLogout = async (dispatch) => {
    try {
        await Auth.signOut();
        dispatch(setAuthenticationState(NOT_LOGGED_IN));
    } catch(e) {
        console.error(e);
    }
};

export const getCognitoAccessToken = async (dispatch) => {
    try {
        dispatch(setAuthenticationState(ATTEMPTING_LOG_IN));
        const session = await Auth.currentSession();
        dispatch(setAuthenticationState(LOGGED_IN));
        return session.getAccessToken().getJwtToken();
    } catch(e) {
        dispatch(setAuthenticationState(NOT_LOGGED_IN));
        console.error(e);
        return null;
    }
};
export function* getCognitoAccessTokenSaga() {
    yield put(setAuthenticationState(ATTEMPTING_LOG_IN));
    try {
        const session = yield call(Auth.currentSession.bind(Auth));
        yield put(setAuthenticationState(LOGGED_IN));
        return session.getAccessToken().getJwtToken();
    } catch(e) {
        yield put(setAuthenticationState(NOT_LOGGED_IN));
        console.error(e);
        return null;
    }
}
