import {css} from "@emotion/core";
import {
    absoluteCenter,
    bbPadding,
    modalBlocker, themedModalClose,
    themedModalContainer,
    themedModalInput,
    themedModalTitle, themedModalButton as modalButtonImport
} from "../../style-variables";
import {darken, transparentize, toHex} from "color2k";
import {
    bodyFontColor,
    headerButtonsFontFamily,
    headerButtonsFontWeight,
    primaryColor, secondaryColor,
    titleFontFamily,
} from "../themes/selectors";

export const modalContainer = modalBlocker;

export const themedContainer = themedModalContainer;

export const themedTitle = themedModalTitle;

export const themedClose = themedModalClose;

export const themedModalButton = modalButtonImport;

export const pendingPlaceholderContainer = css({
    minWidth: "340px",
    border: "4px solid transparent",
    transition: "border-color 0.2s",
});
export const pendingContainer = css(pendingPlaceholderContainer, {
    border: "4px solid #ff0000",
});

export const partnersContainer = css(bbPadding("8px 0"), {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
});

export const themedPendingText = theme => css({
    color: "#ff0000",
    textTransform: 'uppercase',
    fontSize: "0.9375rem",
    fontWeight: 700,
    fontFamily: headerButtonsFontFamily(theme),
});

export const partnerContainer = css({
    flex: "0 0 auto",
    marginRight: "13px",
});

export const partnerRepresentation = css({
    width: "55px",
    height: "55px",
    border: "1px solid #fff",
    borderRadius: "3px",
    color: "#fff",
    marginBottom: "4px",
    fontWeight: 500,
    fontSize: "1.25rem",
    position: "relative",
});
export const themedPlaceholderRepresentation = theme => css(partnerRepresentation, {
    margin: "5px 0 0 6px",
    width: "44px",
    height: "44px",
    borderColor: primaryColor(theme),
    color: primaryColor(theme),
    backgroundColor: darken(primaryColor(theme), 0.68),
    fontFamily: headerButtonsFontFamily(theme),
    cursor: "pointer",
    transition: "background-color 0.2s, color 0.2s",
});
export const themedPlaceholderPickingPartner = theme => css(themedPlaceholderRepresentation(theme), {
    backgroundColor: primaryColor(theme),
    color: darken(primaryColor(theme), 0.68),
});

export const themedInput = themedModalInput;

export const rejectPartner = css(bbPadding("0 5px"), {
    width: "25px",
    height: "25px",
    background: "#FF6755",
    border: "none",
    borderRadius: "3px",
    fill: "#fff",
    cursor: "pointer",

    "& > svg": {
        width: "100%",
        height: "100%",
    },
});

export const cancelRequest = css(bbPadding("5px 1px"), {
    border: "none",
    borderRadius: "3px",
    background: "#FF6755",
    width: "55px",
    color: "#fff",
    fontSize: "0.75rem",
    lineHeight: "0.8em",
    fontWeight: 600,
    fontFamily: "Rajdhani, sans-serif",
    cursor: "pointer",
});

export const endPartner = css(bbPadding("5px 1px"), {
    background: "transparent",
    border: "2px solid #77FAEE",
    borderRadius: "3px",
    width: "55px",
    color: "#fff",
    fontSize: "0.75rem",
    lineHeight: "0.8em",
    fontWeight: 600,
    fontFamily: "Rajdhani, sans-serif",
    cursor: "pointer",
});

export const acceptPartner = css(bbPadding("0 3px"), {
    width: "25px",
    height: "25px",
    margin: "0 8px 0 -1px",
    background: "#23A933",
    border: "none",
    borderRadius: "3px",
    fill: "#fff",
    cursor: "pointer",

    "& > svg": {
        width: "100%",
        height: "100%",
    },
});

export const requestContainer = css({});
