import {INVENTORY_TASK, INVENTORY_TASK_INTERACTION} from "../../../constants/TaskTypes";
import {learnerInventoryUpdated} from "../../../store/interactions/learnerInventory/actions";

export default (dispatch, eventData) => {
    const {learnerInteraction: interaction} = eventData;
    if(!interaction) {return;}
    if(interaction.__typename === INVENTORY_TASK_INTERACTION) {
        dispatch(learnerInventoryUpdated(interaction));
    }
}
