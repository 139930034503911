export const CREATE_STICKY_NOTE = "Create sticky note";
export const createStickyNote = id => ({
    type: CREATE_STICKY_NOTE,
    id
});

export const SET_STICKY_NOTE_TEXT = "Set sticky note text";
export const setStickyNoteText = (id, text) => ({
    type: SET_STICKY_NOTE_TEXT,
    id,
    text
});

export const STICKY_NOTE_TEXT_UPDATED = "Sticky note text updated";
export const stickyNoteTextUpdated = (id, text) => ({
    type: STICKY_NOTE_TEXT_UPDATED,
    id,
    text,
});

export const STICKY_NOTES_LOADED = "Sticky notes loaded";
export const stickyNotesLoaded = notes => ({
    type: STICKY_NOTES_LOADED,
    notes,
});
