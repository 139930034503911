import {loadWorkBoardsForLearner} from "../../graphql/Cohort";
import {getDataService} from "../data/selectors";
import {getPINForEmail} from "../../Identity/utility";

const getLearner = (state, learnerId) => state.learners[learnerId];
export const getLearnerIds = (state) => Object.keys(state.learners);
export const getLearnerIdByEmail = (state, email) => {
    const ids = getLearnerIds(state);
    for(let i = 0; i < ids.length; ++i) {
        if(getEmailForLearner(state, ids[i]) === email) { return ids[i]; }
    }
    return null;
};
export const getEmailForLearner = (state, learnerId) => {
    const learner = getLearner(state, learnerId)
    return learner ? learner.email : "";
};
export const getFamilyNameForLearner = (state, learnerId) => learnerId ? getLearner(state, learnerId).familyName : "";
export const getGivenNameForLearner = (state, learnerId) => {
    if(!learnerId) { return ""; }
    return getLearner(state, learnerId).givenName || getPINForEmail(getEmailForLearner(state, learnerId));
}
export const getVRStartTimeForLearner = (state, learnerId) => learnerId ? getLearner(state, learnerId).vrSessionStartTime : null;
export const getLearnerWorkBoardIds = (state, learnerId) => {
    if(!learnerId) { return []; }
    const workBoards = getLearner(state, learnerId).workBoards;
    if(!workBoards.length) {
        loadWorkBoardsForLearner(getDataService(state), learnerId);
    }
    return workBoards.map(id => id);
};
export const getBountyAssignmentsForLearner = (state, learnerId) => learnerId ? Object.keys(getLearner(state, learnerId).bountyAssignments) : [];
export const getWorkBoardIdForBounty = (state, learnerId, bountyId) => (learnerId && bountyId)
    ? getLearner(state, learnerId).bountyAssignments[bountyId].workBoards[0]
    : null;

export const getBountyStatus = (state, learnerId, bountyId) => {
    if(!learnerId) { return false; }
    return getLearner(state, learnerId).bountyAssignments[bountyId].progress;
};

export const getLearnerRequestedPartnerIds = (state, learnerId, curriculumId) => {
    const learner = getLearner(state, learnerId);
    if(!learner) { return []; }
    return learner.requestedPartners[curriculumId] || [];
}
export const isValidRequest = (state, requesterId, partnerId, curriculumId) => {
    const learner = getLearner(state, requesterId);
    if (!learner) {
        return false;
    }
    const partners = learner.partners[curriculumId];
    const requesteds = learner.requestedPartners[curriculumId];
    const requests = learner.partnerRequests[curriculumId];
    const exists = (partners && partners.indexOf(partnerId) >= 0)
        || (requesteds && requesteds.indexOf(partnerId) >= 0)
        || (requests && requests.indexOf(partnerId) >= 0);
    return !exists;
}
export const getLearnerPartnerRequestsIds = (state, learnerId, curriculumId) => {
    const learner = getLearner(state, learnerId);
    if(!learner) {return [];}
    return learner.partnerRequests[curriculumId] || [];
}
export const getLearnerPartnerIds = (state, learnerId, curriculumId) => {
    const learner = getLearner(state, learnerId);
    if(learner === null) { return []; }
    return learner.partners[curriculumId] || [];
}
