import {getDogmaDashHighlightedGene, getDogmaDashLevelUnlockingProtein} from "../../interactions/dogmaDash/selectors";
import {Recycle, Structural} from "../../../constants/DogmaDash";

export const GENE_SELECTION_NUCLEUS = "dogma dash gene selection nucleus";

// todo: would it make sense for the whole strand to have an "active" condition as well?
export default {
    id: "41f25c91-ee40-41e2-bc95-addd63177ea9",
    items: [
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== GENE_SELECTION_NUCLEUS) {return false; }
                const currentUnlock = getDogmaDashLevelUnlockingProtein(state, dogmaDashId);
                return currentUnlock === Recycle || currentUnlock === Structural;
            },
            header: "Find the protein to fix the alert",
            callToAction: "Click on the genes highlighted in orange and “Spotlight” the one that will fix the problem",
            width: 460,
            completionCondition: (state, {dogmaDashId}) => !!getDogmaDashHighlightedGene(state, dogmaDashId),
        },
    ],
};

