import {INTERACTIONS_LOADED} from "../actions";
import {INVENTORY_TASK_INTERACTION} from "../../../constants/TaskTypes";
import {LEARNER_INVENTORY_UPDATED} from "./actions";

const defaultInventory = {
    objects: []
};

export default (state = {}, action) => {
    if(action.type === INTERACTIONS_LOADED) {
        const loadedInteractions = action.interactions.filter(i => i.__typename === INVENTORY_TASK_INTERACTION).reduce((agg, i) => ({
            ...agg,
            [i.interactable.id]: {
                ...(state[i.interactable.id] || defaultInventory),
                objects: i.objects.map(a => a),
            },
        }), []);
        return {
            ...state,
            ...loadedInteractions,
        };
    }
    if(action.type === LEARNER_INVENTORY_UPDATED) {
        const update = {
            ...state[action.interaction.interactable.id],
            objects: action.interaction.objects.map(a => a),
        };
        return {
            ...state,
            [action.interaction.interactable.id]: update,
        };
    }
    return state;
};
