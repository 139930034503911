import {
    PEA_PLANT_CARD,
    PODULATOR_RECEIPT,
    POLAROID,
    PUNNETT_SQUARE,
    STICKY_NOTE
} from "../../constants/WorkBoardItemTypes";
import {peaPlantFragment, plantCardFragment} from './peaPlant';

export const itemFragment = `
item {
    __typename
    ... on ${POLAROID} {
        id
        imageUrl
        notes
        borderColor
        attributes
    }
    ... on ${PEA_PLANT_CARD} {
        ${plantCardFragment}
    }
    ... on ${STICKY_NOTE} {
        id
        text
    }
    ... on ${PODULATOR_RECEIPT} {
        id
        sampleSize
        crosses {
            traitType
            trait1x1Count
            trait1x2Count
            trait2x1Count
            trait2x2Count
        }
        topParent {
            ${peaPlantFragment}
        }
        leftParent {
            ${peaPlantFragment}
        }
        uniqueGenomes {
            count
            traits {
                traitType
                zygosity
            }
        }
    }
    ... on ${PUNNETT_SQUARE} {
        id
        traits
        leftPlant {
            ${peaPlantFragment}
        }
        topPlant {
            ${peaPlantFragment}
        }
        entries {
            x
            y
            trait
            phenotype
            alleleA
            alleleB
        }
        probabilityQuestionAnswers
    }
}`;
