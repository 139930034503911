// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../CurriculumNavigation/Assets/vr-icon.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/white-square-x.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".vr-blocker{position:fixed;left:0;right:0;top:50px;height:calc(100vh - 50px);box-sizing:border-box;padding:50px 0 0 220px;background-color:#46b084;animation:.3s blocker-slide-up ease;z-index:101}.vr-blocker:before{content:\"\";width:150px;height:150px;display:block;position:absolute;top:50px;left:50px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:61% 51%;background-repeat:no-repeat;background-position:center;background-color:#fff;border-radius:50%}@keyframes blocker-slide-up{0%{transform:translateY(100%)}100%{transform:translateY(0)}}.vr-blocker--close{width:50px;height:50px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:100% 100%;cursor:pointer;background-color:rgba(0,0,0,0);border:none;position:absolute;right:50px;top:50px;padding:0}.vr-blocker--title{font-size:3em;color:#fff;font-weight:800;padding:0;margin:0 0 20px 0}.vr-blocker--steps{color:#fff;font-size:2.25em;font-weight:800}.vr-blocker--steps li{margin-bottom:10px}.vr-blocker--steps .vr-blocker--step{font-size:.667em;font-weight:400;margin-left:4px}.vr-blocker--continue-container{position:absolute;bottom:50px;right:50px;text-align:right}.vr-blocker--continue-title{font-size:3em;color:#fff;font-weight:800;padding:0;margin:20px 0}.vr-blocker--continue{font-size:1.25em;color:#6f6f6f;background:#fff;border:1px solid #e3c04b;width:200px;height:60px;border-radius:30px;transition:background-color .2s;cursor:pointer}.vr-blocker--continue:hover{background-color:#f1e0a5}", ""]);
// Exports
module.exports = exports;
