import {css} from "@emotion/core";

export const imageItem = css({
    width: "100%",
    height: "100%",

    "& .load-spinner": {
        position: "static",
        margin: "5px auto",
    },
});

export const imageInput = css({
    display: "none",
});

export const imageInputLabel = css({
    display: "inline-block",
    padding: "4px 8px",
    boxSizing: "border-box",
    border: "1px solid #999",
    borderRadius: 3,
    backgroundColor: "white",
    fontSize: "0.875em",
    cursor: "pointer",
});

export const image = css({
    width: "100%",
    height: "100%",
    display: "block",
});
