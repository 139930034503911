import "./image.scss";
import React, {useRef, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {isModalOpenFor} from "../../store/navigation/selectors";
import {ReactEditor, useSlate} from "slate-react";
import {openModalFor} from "../../store/navigation/actions";
import {Transforms, Range} from "slate";
import {EDITOR_COMPONENT_IMAGE} from "../editorComponents/Image";
import {getCognitoAccessToken} from "../../Identity/utility";
import {ADD_IMAGE_TO_RICH_TEXT_MODAL} from "../../constants/modalTypes";
import imageUpload from "../../Utility/imageUpload";

const stateMap = props => state => ({
    showImageInsert: isModalOpenFor(state, ADD_IMAGE_TO_RICH_TEXT_MODAL, props.editorId),
});

export default props => {
    const {editorId} = props;
    const editor = useSlate();
    const {showImageInsert} = useSelector(stateMap(props), shallowEqual);
    const dispatch = useDispatch();
    const [selection, setSelection] = useState(null);
    const fileInputRef = useRef();

    const openImageInsert = e => {
        e.preventDefault();
        e.stopPropagation();
        if(!editor.selection) { return; }
        setSelection(editor.selection);
        dispatch(openModalFor(ADD_IMAGE_TO_RICH_TEXT_MODAL, editorId));
    };
    const focusEditor = () => {
        ReactEditor.focus(editor);
        Transforms.select(editor, selection);
    };
    const closeImageInsert = () => {
        focusEditor();
        dispatch(openModalFor());
    };
    const uploadImage = async (editorImageLocation, fileData) => {
        const url = await imageUpload(fileData, dispatch);

        Transforms.setNodes(editor,{
            url,
        }, {
            at: editorImageLocation,
            match: n => n.type === EDITOR_COMPONENT_IMAGE,
        });
    };
    const insertImage = () => {
        const fileData = fileInputRef.current.files[0];
        closeImageInsert();
        const newNode = {
            type: EDITOR_COMPONENT_IMAGE,
            url: null,
            children: [{text: ""}]
        };
        if(Range.isCollapsed(selection) && selection.anchor.path[0] === 0 && selection.anchor.path[1] === 0) {
            Transforms.setNodes(editor, newNode);
        } else {
            Transforms.insertNodes(editor, newNode);
        }
        uploadImage(editor.selection, fileData);
        Transforms.move(editor, {
            distance: 1,
            unit: 'offset',
        });
    };

    return (
    <>
        {showImageInsert &&
        <div className="rich-editor--insert-image__blocker">
            <div className="rich-editor--insert-image">
                <h2 className="insert-image--modal-header">Insert Image</h2>
                <div className="insert-image--file-input-container">
                    <label htmlFor="image-file-choice" className="insert-image--file-input-label">Choose a file:</label>
                    <input ref={fileInputRef} id="image-file-choice" type="file" className="insert-image--file-input" />
                </div>
                <div className="insert-image--button-container">
                    <button onMouseDown={insertImage} className="insert-image--insert-button">Insert</button>
                    <button onMouseDown={closeImageInsert} className="insert-image--cancel-button">Cancel</button>
                </div>
            </div>
        </div>
        }
        <button onMouseDown={openImageInsert} className="rich-editor--style-button rich-editor--style-button__image" />
    </>
    )
};
