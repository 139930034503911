import React from 'react';
import {
    GLOBAL_TASK,
    UTILITY_BUTTON_GLOBAL_ITEM,
    UTILITY_BUTTON_HEADSET,
    UTILITY_BUTTON_PARTNER
} from "../../../../constants/TaskTypes";
import {useDispatch, useSelector} from "react-redux";
import {
    setUtilityButtonBehavior,
    setUtilityButtonGlobalItemId, setUtilityButtonText
} from "../../../../store/curriculumTaskItems/utilityButton/actions";
import {
    getUtilityButtonBehavior,
    getUtilityButtonGlobalItemId, getUtilityButtonText
} from "../../../../store/curriculumTaskItems/utilityButton/selectors";
import {bbPadding} from "../../../../style-variables";
import {closeModalType, openModalFor} from "../../../../store/navigation/actions";
import {ADD_TASK_REFERENCE_TO_RICH_TEXT_MODAL} from "../../../../constants/modalTypes";
import {isModalOpenFor} from "../../../../store/navigation/selectors";
import ItemReferenceSelector from "../../Utility/ItemReferenceSelector";
import {getCurriculumTaskItemType} from "../../../../store/curriculumTaskItems/selectors";

const displayNameMap = {
    [UTILITY_BUTTON_HEADSET]: "Open headset pairing",
    [UTILITY_BUTTON_PARTNER]: "Open partnering",
    [UTILITY_BUTTON_GLOBAL_ITEM]: "Open global interactive",
};

const container = bbPadding("6px");

const UtilityButtonAdmin = ({curriculum, id}) => {
    const behavior = useSelector(state => getUtilityButtonBehavior(state, id) || "None");
    const globalItemId = useSelector(state => getUtilityButtonGlobalItemId(state, id));
    const buttonText = useSelector(state => getUtilityButtonText(state, id));
    const itemType = useSelector(state => globalItemId ? getCurriculumTaskItemType(state, globalItemId) : "");
    const modalOpen = useSelector(state => isModalOpenFor(state, ADD_TASK_REFERENCE_TO_RICH_TEXT_MODAL, id));
    const dispatch = useDispatch();

    const openItemSelector = () => dispatch(openModalFor(ADD_TASK_REFERENCE_TO_RICH_TEXT_MODAL, id));
    const closeItemSelector = () => dispatch(closeModalType(ADD_TASK_REFERENCE_TO_RICH_TEXT_MODAL));
    const setBehavior = e => dispatch(setUtilityButtonBehavior(id, curriculum, e.target.value === "None" ? null : e.target.value));
    const setGlobalItemId = ({interactableId}) => {
        dispatch(setUtilityButtonGlobalItemId(id, curriculum, interactableId));
        closeItemSelector();
    };
    const updateButtonText = e => dispatch(setUtilityButtonText(id, curriculum, e.target.value));

    return (
        <>
            {modalOpen && <ItemReferenceSelector curriculumId={curriculum} selectedCallback={setGlobalItemId} modalCloser={closeItemSelector} allowedTaskTypes={[GLOBAL_TASK]} />}
            <div css={container}>
                <h2>Utility Button</h2>
                <div>
                    <label>Button Title:</label>
                    <input type={"text"} defaultValue={buttonText} onBlur={updateButtonText} />
                </div>
                <div>
                    <label>Behavior Type:</label>
                    <select value={behavior} onChange={setBehavior}>
                        <option>None</option>
                        <option value={UTILITY_BUTTON_HEADSET}>{displayNameMap[UTILITY_BUTTON_HEADSET]}</option>
                        <option value={UTILITY_BUTTON_PARTNER}>{displayNameMap[UTILITY_BUTTON_PARTNER]}</option>
                        <option value={UTILITY_BUTTON_GLOBAL_ITEM}>{displayNameMap[UTILITY_BUTTON_GLOBAL_ITEM]}</option>
                    </select>
                </div>
                {behavior === UTILITY_BUTTON_GLOBAL_ITEM && <div>
                    <label>Global Item to open:</label>
                    {globalItemId && <span>{itemType}</span>}
                    <button onClick={openItemSelector}>Choose</button>
                </div>}
            </div>
        </>
    )
};

export default UtilityButtonAdmin;
