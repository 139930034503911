export const ADD_INTERACTABLE_TO_TASK = "Add interactable to task";
export const addInteractableToTask = (curriculumId, taskId, interactableId, interactableType) => ({
    type: ADD_INTERACTABLE_TO_TASK,
    curriculumId,
    taskId,
    interactableId,
    interactableType,
});

export const REMOVE_INTERACTABLE_FROM_TASK = "remove interactable from task";
export const removeInteractableFromTask = (curriculumId, taskId, interactableId) => ({
    type: REMOVE_INTERACTABLE_FROM_TASK,
    curriculumId,
    taskId,
    interactableId,
});
