import * as styles from './cross-card-styles';
import React from 'react';
import YieldArrow from '../../inline-assets/yields-arrow.svg';
import {useDrop} from "react-dnd";
import {PEA_PLANT_CARD} from "../../constants/WorkBoardItemTypes";
import {useDispatch, useSelector} from "react-redux";
import {placePlantInCross} from "../../store/interactions/actions";
import {useParams} from "@reach/router";
import {
    getCrossOffspringCards,
    getInteractionWorkBoardId,
    getPlantInCrossSlot
} from "../../store/interactions/selectors";
import combineStyles from "../../Utility/combineStyles";
import {getPeaPlantParentIds} from "../../store/peaPlants/selectors";
import {getPeaPlantCardDisplayMode, getPeaPlantCardPlantId} from "../../store/peaPlantCards/selectors";
import uuid from 'uuid/v4';
import {cloneWorkBoardItem} from "../../store/workBoards/actions";
import {PLANT} from "../../constants/peaPlantCardDisplayModes";
import {css} from "@emotion/core";
import {PEA_PLANT_CARD_HEIGHT, PEA_PLANT_CARD_WIDTH} from "../../style-variables";
import useDropSortable from "../../Utility/useDropSortable";
import WorkBoardItem from '../Items/WorkBoardItem';
import {getBoardItemIds} from "../../store/workBoards/selectors";

const isChildOfCreator = state => (parentACard, parentBCard, childCard) => {
    const plantId = getPeaPlantCardPlantId(state, childCard);
    const parentA = getPeaPlantCardPlantId(state, parentACard);
    const parentB = getPeaPlantCardPlantId(state, parentBCard);
    const parents = getPeaPlantParentIds(state, plantId);
    return parents.includes(parentA) && parents.includes(parentB);
};

const WorkBoardCrossCard = ({id, workBoardDefinition, scaleFactor, readOnly}) => {
    scaleFactor = scaleFactor || 1;
    const {curriculumId} = useParams();
    const boardId = useSelector(state => getInteractionWorkBoardId(state, workBoardDefinition));
    const crossCardOffspring = useSelector(state => getCrossOffspringCards(id, state));
    const itemsOnBoard = useSelector(state => getBoardItemIds(state, boardId));
    const itemInParentOne = useSelector(state => getPlantInCrossSlot(id, 0, true, state));
    const itemInParentTwo = useSelector(state => getPlantInCrossSlot(id, 1, true, state));
    const dispatch = useDispatch();

    let crossStyle = styles.crossSymbol;
    if(scaleFactor !== 1) {
        crossStyle = css(crossStyle, {
            lineHeight: `${PEA_PLANT_CARD_HEIGHT * scaleFactor}px`
        });
    }

    const [_, droppableArea, draggableIds] = useDropSortable({
        accept: [PEA_PLANT_CARD],
        drop: (item, monitor, placeholderIndex) => {
            let {itemId, currentDropArea} = item;
            if(!itemsOnBoard.includes(itemId) && !crossCardOffspring.includes(itemId)) {
                const newId = uuid();
                dispatch(cloneWorkBoardItem(itemId, newId, boardId, -1));
                itemId = newId;
            }
            dispatch(placePlantInCross(curriculumId, itemId, id, placeholderIndex, false, currentDropArea));
        },
        canDrop: (item, monitor) => {
            // TODO: test for correct parents.
            // if(!itemInParentOne || !itemInParentTwo) { return false; }
            return monitor.isOver({shallow:true});
        }
    }, crossCardOffspring);
    const setRef = r => !readOnly && droppableArea(r);

    const placeholderStyle = css(styles.placeholder, {
        width: PEA_PLANT_CARD_WIDTH * scaleFactor,
    });

    return (
        <div css={styles.container} ref={setRef}>
            <div css={styles.parentsContainer}>
                <CrossDropSlot key="p1" slotIndex={0} crossId={id} boardId={boardId} scaleFactor={scaleFactor} readOnly={readOnly} />
                <div css={crossStyle}>x</div>
                <CrossDropSlot key="p2" slotIndex={1} crossId={id} boardId={boardId} scaleFactor={scaleFactor} readOnly={readOnly} />
                <div css={styles.yieldArrow}><YieldArrow /></div>
            </div>
            {draggableIds.map(draggableId =>
                <WorkBoardItem key={draggableId} id={draggableId} scaleFactor={scaleFactor} readOnly={readOnly} currentDropArea={id} style={styles.offspringCard} />)}
            {draggableIds.length === 0 && <div css={placeholderStyle} />}
        </div>
    )
}

const getDisplayModeCreator = state => id => getPeaPlantCardDisplayMode(id, state);
const CrossDropSlot = ({slotIndex, crossId, boardId, scaleFactor, readOnly}) => {
    const dispatch = useDispatch();
    const {curriculumId} = useParams();
    const itemInSlot = useSelector(state => getPlantInCrossSlot(crossId, slotIndex, true, state));
    const itemsOnBoard = useSelector(state => getBoardItemIds(state, boardId));
    const getDisplayMode = useSelector(getDisplayModeCreator); // TODO: this will always cause a rerender

    const [{hovered}, dropRef] = useDrop({
        accept: [PEA_PLANT_CARD],
        drop: item => {
            let {itemId, currentDropArea} = item;
            if(!itemsOnBoard.includes(itemId)) {
                const newId = uuid();
                dispatch(cloneWorkBoardItem(itemId, newId, boardId, -1));
                itemId = newId;
            }
            dispatch(placePlantInCross(curriculumId, itemId, crossId, slotIndex, true, currentDropArea));
        },
        canDrop: item => {
            if(itemInSlot) { return false; }
            if(getDisplayMode(item.itemId) !== PLANT) { return false; }
            return true;
        },
        collect: monitor => ({
            hovered: monitor.canDrop() && monitor.isOver(),
        }),
    });
    const setRef = r => !readOnly && dropRef(r);

    let slotStyle = combineStyles(styles, {
        slot: true,
        slotHovered: hovered,
    });
    if(scaleFactor !== 1) {
        slotStyle = css(slotStyle, {
            width: PEA_PLANT_CARD_WIDTH * scaleFactor,
            height: PEA_PLANT_CARD_HEIGHT * scaleFactor,
        });
    }

    return (
        <div ref={setRef} css={slotStyle}>
            {itemInSlot && <WorkBoardItem id={itemInSlot} scaleFactor={scaleFactor} readOnly={readOnly} currentDropArea={crossId} />}
        </div>
    )
};

export default WorkBoardCrossCard;
