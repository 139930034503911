import {css} from "@emotion/core";
import logoImage from './assets/logo.jpg';
import {HEADER_HEIGHT} from "../style-variables";
import {logo as baseLogo} from "../style-variables";

export const logo = css(baseLogo, {
    backgroundImage: `url(${logoImage})`,
    borderRadius: "50%",
});

export const container = css({
    width: "100%",
    height: `100%`,
    position: "relative",
});

export const router = css({
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
});
