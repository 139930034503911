import * as styles from './visceral-card-styles';
import React, {useRef} from 'react';
import {getCurriculumCaseIndexForWorkBoardDefinition} from "../../../../store/curriculumCases/selectors";
import {useDispatch, useSelector} from "react-redux";
import VisceralCard from "./VisceralCard";
import {useParams} from "@reach/router";
import {removeEvidenceFromExplainer} from "../../../../store/interactions/explainer/actions";
import {useDrag} from "react-dnd";

const VisceralCardCanvasWrapper = ({id, explainerId}) => {
    const {curriculumId} = useParams();
    const caseIndex = useSelector(state => getCurriculumCaseIndexForWorkBoardDefinition(curriculumId, id, state));
    const dispatch = useDispatch();
    const ref = useRef();

    const [_, dragRef] = useDrag({
        item: {
            type: "ExplainerEvidence",
            itemId: id,
            ref,
        },
    });
    const setRef = r => {
        if(r) { r.setAttribute("draggable", true); } // TODO: ACK! would love to not need this.
        ref.current = r;
        dragRef(r);
    };

    const remove = () => dispatch(removeEvidenceFromExplainer(curriculumId, explainerId, [id]));

    return (
        <div css={styles.canvasCard} ref={setRef}>
            <button onClick={remove} css={styles.removeButton} className={styles.cardBadgeClassName} />
            <VisceralCard id={id} caseIndex={caseIndex}  explainerId={explainerId} />
        </div>
    );
};

export default VisceralCardCanvasWrapper;
