import React from 'react';

export const EDITOR_COMPONENT_VIDEO = "VIDEO";

export default ({attributes, children, element}) => {
    const {url} = element;
    return (
        <div {...attributes}>
            <div contentEditable={false}>
                <iframe src={url} width="400px" height="225px" frameBorder="0" allowFullScreen={true} />
            </div>
            {children}
        </div>
    )
}
