import {css} from "@emotion/core";
import check from '../../../../../assets/check.svg';

export const menu = css({
    width: 180,
    backgroundColor: "#333",
    color: "white",
    padding: 1,
    boxSizing: "border-box",
    position: "absolute",
    boxShadow: "1px 1px 3px 0 rgba(0,0,0,0.5)",
    zIndex: 1,
});

export const button = css({
    display: "block",
    width: "100%",
    backgroundColor: "transparent",
    color: "inherit",
    padding: "4px 6px 4px 16px",
    boxSizing: "border-box",
    border: "none",
    textAlign: "left",
    transition: "background-color 0.2s",
    cursor: "pointer",
    position: "relative",

    "&:not(:last-of-type)": {
        margin: "0 0 1px 0",
    },

    "&:hover": {
        backgroundColor: "rgba(255,255,255,0.25)",
    },
});
export const buttonActive = css({
    backgroundColor: "rgba(255,255,255,0.5)",
});
export const buttonCheck = css({
    display: 'block',
    width: 16,
    height: 16,
    position: "absolute",
    left: 1,
    top: 5,

    "& .check_svg__svg-fill": {
        fill: "#fff"
    },
});

export const separator = css({
    width: "100%",
    borderTop: "1px solid #666",
    margin: "0 0 1px 0",
});
