import * as styles from './alert-panel-styles';
import React, {useEffect, useRef} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    getDogmaCellMembraneIds, getDogmaCentrioles, getDogmaDashCytoplasm, getDogmaLysosomeIds, getDogmaMicrotubuleIds,
    getDogmaMitochondriaIds, getOrganelleAlert, getOrganelleTimerDuration, getOrganelleTimerStart, getOrganelleTimerType
} from "../../../../store/interactions/dogmaDash/selectors";
import BlockedChannelIcon from './inline-assets/blocked-channel.svg';
import BrokenMicrotubuleIcon from './inline-assets/broken-microtubule.svg';
import NoRecyclerIcon from './inline-assets/no-recycler.svg';
import TooMuchCargoIcon from './inline-assets/too-much-cargo.svg';
import EnergyAlertIcon from './inline-assets/energy-vesicle.svg';
import ViralLoad from './inline-assets/viral-load.svg';
import {Transition, TransitionGroup} from "react-transition-group";
import {getPendingScoreEventFor} from "../../../../store/interactions/scoring/selectors";
import {changeCurrentDogmaDashScore} from "../../../../store/interactions/dogmaDash/actions";
import {pendingScoringEventCompleted} from "../../../../store/interactions/scoring/actions";
import {DOGMA_DASH_ALERT_LIST} from "../../../../store/tutorial/configuration/dogma-strand-1";
import {getTutorialDataToShow} from "../../../../store/tutorial/selectors";
import PopUpTutorial from "../../../Tutorial/PopUpTutorial";

export const Centriole = "Centriole";
export const Microtubule = "Microtubule";
export const Lysosome = "Lysosome";
export const CellMembrane = "CellMembrane";
export const Mitochondria = "Mitochondria";
export const Cytoplasm = "Cytoplasm";

export const AlertIconMap = {
    [Centriole]: TooMuchCargoIcon,
    [Microtubule]: BrokenMicrotubuleIcon,
    [Lysosome]: NoRecyclerIcon,
    [CellMembrane]: BlockedChannelIcon,
    [Mitochondria]: EnergyAlertIcon,
    [Cytoplasm]: ViralLoad,
}

const Alert = ({dogmaId, organelleId, organelleType, ...rest}) => {
    const alertStart = useSelector(state => getOrganelleTimerStart(state, organelleId));
    const alertDuration = useSelector(state => getOrganelleTimerDuration(state, organelleId));
    const alertText = useSelector(state => getOrganelleAlert(state, organelleId));
    const scoreEvent = useSelector(state => getPendingScoreEventFor(state, organelleId));
    const transitionRef = useRef();
    const barRef = useRef();
    const animationRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        const updateBar = () => {
            const timeSinceStart = Date.now() - alertStart;
            const target = Math.min(1, Math.max(0, 1 - timeSinceStart / alertDuration));
            barRef.current.style.height = `${target * 100}%`;
            animationRef.current = requestAnimationFrame(updateBar);
        };
        animationRef.current = requestAnimationFrame(updateBar);
        return () => cancelAnimationFrame(animationRef.current);
    }, []);

    useEffect(() => {
        if(!scoreEvent) {return;}
        dispatch(changeCurrentDogmaDashScore(dogmaId, scoreEvent.deltaScore));
        return () => dispatch(pendingScoringEventCompleted(scoreEvent.scorer));
    }, [scoreEvent]);

    const Icon = AlertIconMap[organelleType];

    const success = scoreEvent?.deltaScore > 0;
    const scoreText = success ? `+${scoreEvent?.deltaScore}` : scoreEvent?.deltaScore;

    return (
        <Transition nodeRef={transitionRef} timeout={scoreEvent ? 2500 : 0} {...rest}>
            {state => (
            <div ref={transitionRef} css={state !== "exiting" ? styles.alertContainer : success ? styles.alertContainerSuccess : styles.alertContainerFailure}>
                <div css={styles.clippingContainer}>
                    <div css={styles.textContainer}>
                        <div css={state === "exiting" ? styles.scoringText : styles.alertText}>{scoreEvent ? scoreText : alertText}</div>
                    </div>
                    <div css={state === "exiting" ? styles.scoringTimerContainer : styles.timerContainer}>
                        <div ref={barRef} css={styles.timerBar} />
                    </div>
                </div>
                <div css={state !== "exiting" ? styles.iconContainer : success ? styles.successIcon : styles.failureIcon}>
                    <Icon />
                </div>
            </div>
            )}
        </Transition>
    )
};

const AlertPanel = ({dogmaId}) => {
    const mitochondriaAlerts = useSelector(state => getDogmaMitochondriaIds(state, dogmaId).filter(id => getOrganelleTimerType(state, id) === "Alert" && !!getOrganelleTimerStart(state, id)), shallowEqual);
    const cellMembraneAlerts = useSelector(state => getDogmaCellMembraneIds(state, dogmaId).filter(id => getOrganelleTimerType(state, id) === "Alert" && !!getOrganelleTimerStart(state, id)), shallowEqual);
    const lysosomeAlerts = useSelector(state => getDogmaLysosomeIds(state, dogmaId).filter(id => getOrganelleTimerType(state, id) === "Alert" && !!getOrganelleTimerStart(state, id)), shallowEqual);
    const microtubuleAlerts = useSelector(state => getDogmaMicrotubuleIds(state, dogmaId).filter(id => getOrganelleTimerType(state, id) === "Alert" && !!getOrganelleTimerStart(state, id)), shallowEqual);
    const centrioleAlerts = useSelector(state => getDogmaCentrioles(state, dogmaId).filter(id => getOrganelleTimerType(state, id) === "Alert" && !!getOrganelleTimerStart(state, id)), shallowEqual);
    const cytoplasmAlert = useSelector(state => {
        const id = getDogmaDashCytoplasm(state, dogmaId);
        if(!id || getOrganelleTimerType(state, id) !== "Alert") {return null;}
        const alertStart = getOrganelleTimerStart(state, id);
        if(!alertStart) { return null; }
        return {id, startTime: alertStart, organelle: Cytoplasm};
    })
    const organellesWithAlerts = useSelector(state => {
        const alerts = [
            ...mitochondriaAlerts.map(id => ({id, startTime: getOrganelleTimerStart(state, id), organelle: Mitochondria})),
            ...cellMembraneAlerts.map(id => ({id, startTime: getOrganelleTimerStart(state, id), organelle: CellMembrane})),
            ...lysosomeAlerts.map(id => ({id, startTime: getOrganelleTimerStart(state, id), organelle: Lysosome})),
            ...microtubuleAlerts.map(id => ({id, startTime: getOrganelleTimerStart(state, id), organelle: Microtubule})),
            ...centrioleAlerts.map(id => ({id, startTime: getOrganelleTimerStart(state, id), organelle: Centriole})),
        ];
        if(cytoplasmAlert) { alerts.push(cytoplasmAlert); }
        alerts.sort((a, b) => a.startTime - b.startTime);
        return alerts;
    }, () => true);
    const tutorialConfig = {dogmaDashId: dogmaId, location: DOGMA_DASH_ALERT_LIST};

    return (
        <div css={styles.container}>
            <h3 css={styles.titleText}>Alerts</h3>
            <div css={styles.alertsContainer}>
                <TransitionGroup component={null}>
                    {organellesWithAlerts.map(a => <Alert key={a.id} dogmaId={dogmaId} organelleId={a.id} organelleType={a.organelle} />)}
                </TransitionGroup>
            </div>
            <div css={styles.tutorial}><PopUpTutorial positionData={tutorialConfig} /></div>
        </div>
    );
};

export default AlertPanel;
