import {CURRICULA_LOADED} from "../../curricula/actions";
import {PEA_PLANT_MATERIAL, WORK_BOARD_DEFINITION} from "../../../constants/TaskTypes";
import {omit} from "lodash";
import {ADD_INTERACTABLE_TO_TASK, REMOVE_INTERACTABLE_FROM_TASK} from "../actions";
import {
    ADD_PEA_PLANT_MATERIAL_TO_WORK_BOARD,
    REMOVE_MATERIAL_FROM_WORK_BOARD, SET_PEA_PLANT_MATERIAL_PROVIDE_CARD, SET_PEA_PLANT_MATERIAL_PROVIDE_PEAS,
    SET_WORK_BOARD_INSTRUCTIONS, SET_WORK_BOARD_USES_TEMPLATE
} from "./actions";

const createDefaultItem = () => ({
    materials: [],
    template: false,
});

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const newWorkBoardDefinitions = action.curricula.reduce((agg, c) => ({
            ...agg,
            ...(c.tasks || []).reduce((taskAgg, t) => ({
                ...taskAgg,
                ...t.items.filter(i => i.__typename === WORK_BOARD_DEFINITION).reduce((itemsAgg, i) => ({
                    ...itemsAgg,
                    [i.id]: {
                        ...createDefaultItem(),
                        introduction: i.introduction,
                        template: i.templateItems.length > 0,
                        materials: i.materials.map(m => ({ // TODO: this needs improving, especially as work boards have more materials
                            id: m.id,
                            type: PEA_PLANT_MATERIAL,
                            peaPlant: m.peaPlant.id,
                            provideCard: m.provideCard,
                            providePeas: m.providePeas,
                        })),
                    },
                }), {}),
            }), {}),
        }), {});
        return {
            ...state,
            ...newWorkBoardDefinitions,
        };
    }
    if(action.type === ADD_INTERACTABLE_TO_TASK && action.interactableType === WORK_BOARD_DEFINITION) {
        return {
            ...state,
            [action.interactableId]: {
                ...createDefaultItem(),
            },
        };
    }
    if(action.type === REMOVE_INTERACTABLE_FROM_TASK) {
        return omit(state, action.interactableId);
    }
    if(action.type === SET_WORK_BOARD_INSTRUCTIONS) {
        return {
            ...state,
            [action.workBoardDefinitionId]: {
                ...state[action.workBoardDefinitionId],
                introduction: action.instructions,
            },
        };
    }
    if(action.type === ADD_PEA_PLANT_MATERIAL_TO_WORK_BOARD) {
        const currentDefinition = state[action.workBoardDefinitionId];
        return {
            ...state,
            [action.workBoardDefinitionId]: {
                ...currentDefinition,
                materials: currentDefinition.materials.concat({
                    id: action.materialId,
                    type: PEA_PLANT_MATERIAL,
                    peaPlant: action.plantId,
                    provideCard: false,
                    providePeas: false,
                }),
            },
        };
    }
    if(action.type === REMOVE_MATERIAL_FROM_WORK_BOARD) {
        const currentDefinition = state[action.workBoardDefinitionId];
        return {
            ...state,
            [action.workBoardDefinitionId]: {
                ...currentDefinition,
                materials: currentDefinition.materials.filter(m => m.id !== action.materialId),
            },
        };
    }
    if(action.type === SET_PEA_PLANT_MATERIAL_PROVIDE_PEAS) {
        const {materials} = state[action.workBoardDefinitionId];
        const material = materials.find(m => m.id === action.materialId);
        const updatedMaterial = {
            ...material,
            providePeas: action.providePeas,
        };
        return {
            ...state,
            [action.workBoardDefinitionId]: {
                ...state[action.workBoardDefinitionId],
                materials: materials.map(m => m.id === action.materialId ? updatedMaterial : m),
            },
        };
    }
    if(action.type === SET_PEA_PLANT_MATERIAL_PROVIDE_CARD) {
        const {materials} = state[action.workBoardDefinitionId];
        const material = materials.find(m => m.id === action.materialId);
        const updatedMaterial = Object.assign({}, material, { provideCard: action.provideCard });
        return {
            ...state,
            [action.workBoardDefinitionId]: {
                ...state[action.workBoardDefinitionId],
                materials: materials.map(m => m.id === action.materialId ? updatedMaterial : m),
            },
        };
    }
    if(action.type === SET_WORK_BOARD_USES_TEMPLATE) {
        return {
            ...state,
            [action.workBoardDefinitionId]: {
                ...state[action.workBoardDefinitionId],
                template: action.useTemplate,
            },
        };
    }
    return state;
};
