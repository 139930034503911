import {CURRICULUM_LOADED, CURRICULUM_TASK_STATUS_UPDATED, SET_CURRICULUM_TASK_STATUS} from "./actions";
import {SET_AUTHENTICATION_STATE} from "../identity/actions";
import {NOT_LOGGED_IN} from "../../constants/AuthenticationState";

export default (state = {}, action) => {
    if(action.type === CURRICULUM_LOADED) {
        return action.curriculum;
    }
    if(action.type === SET_CURRICULUM_TASK_STATUS || action.type === CURRICULUM_TASK_STATUS_UPDATED) {
        let topic, activity, task;
        for(let t = 0; t < state.topicAssignments.length; ++t) {
            topic = state.topicAssignments[t];
            for(let a = 0; a < topic.activityAssignments.length; ++a) {
                activity = topic.activityAssignments[a];
                for(let i = 0; i < activity.taskAssignments.length; ++i) {
                    if(activity.taskAssignments[i].task.id === action.task) {
                        task = activity.taskAssignments[i];
                        break;
                    }
                }
                if(task) { break; }
            }
            if(task) { break; }
        }

        return Object.assign({}, state, {
            topicAssignments: state.topicAssignments.map(t => t !== topic ? t :
                Object.assign({}, t, {
                    activityAssignments: t.activityAssignments.map(a => a !== activity ? a :
                        Object.assign({}, a, {
                            taskAssignments: a.taskAssignments.map(ta => ta !== task ? ta :
                                Object.assign({}, ta, {
                                    status: action.status,
                                })),
                        })),
                })),
        });
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    return state;
};
