import * as styles from './trait-bank-styles';
import React from 'react';
import PhenotypeTile from "./PhenotypeTile";
import {
    FLOWER_COLOR,
    FLOWER_POSITION,
    HEIGHT,
    SEED_COLOR,
    SEED_SHAPE,
    POD_COLOR,
    TRAIT_DISPLAY_NAMES,
    POD_SHAPE
} from "../../../constants/TraitNames";

const TRAITS = [
    FLOWER_COLOR,
    FLOWER_POSITION,
    HEIGHT,
    SEED_COLOR,
    SEED_SHAPE,
    POD_COLOR,
    POD_SHAPE,
];

const PeaPlantCardTraitBank = React.memo(({plantCardId}) => {
    return (
        <div css={styles.bank}>
            {TRAITS.map(t => (
            <div css={styles.traitContainer} key={t}>
                <div css={styles.traitLabel}>{TRAIT_DISPLAY_NAMES[t]}</div>
                <PhenotypeTile plantCardId={plantCardId} trait={t} />
            </div>
            ))}
        </div>
    );
});

PeaPlantCardTraitBank.displayName = "PeaPlantCardTraitBank";
export default PeaPlantCardTraitBank;
