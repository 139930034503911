// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/Fonts/721263/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/Fonts/721263/1e9892c0-6927-4412-9874-1b82801ba47a.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./assets/Fonts/721281/14c73713-e4df-4dba-933b-057feeac8dd1.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./assets/Fonts/721281/b8e906a1-f5e8-4bf1-8e80-82c646ca4d5f.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./assets/Fonts/721287/5c57b2e2-f641-421e-a95f-65fcb47e409a.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./assets/Fonts/721287/181c847e-cdbc-43d5-ae14-03a81c8953b4.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./assets/point-up.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,800&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
exports.push([module.id, "@font-face{font-family:\"Avenir Next\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:400}@font-face{font-family:\"Avenir Next\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:700}@font-face{font-family:\"Avenir Next\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\");font-weight:900}body,html{width:100%;height:100%;margin:0;padding:0;font:400 16px \"Open Sans\",sans-serif}*{-webkit-font-smoothing:antialiased}button{font-family:inherit}.app-root{width:100%;height:100%;background:#fff}.content-container{position:absolute;left:0;top:0;width:100%;height:100%}.router{height:100%;width:100%}.logout-button{position:absolute;right:8px;top:8px;width:90px;height:35px;background-color:#329044;color:#fff;text-align:center;line-height:35px;cursor:pointer;border:none;transition:background-color .2s}.logout-button:hover{background-color:#246932}.point-up-icon{width:16px;height:16px;display:inline-block;vertical-align:middle;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ");background-size:100% 100%}", ""]);
// Exports
module.exports = exports;
