import './curriculum-case.scss';
import React, {useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import InlineEditor from "../../../Utility/InlineEditor";
import {openModalFor} from "../../../store/navigation/actions";
import {CASE_ADD_TASK_MODAL} from "../../../constants/modalTypes";
import {useParams} from "@reach/router";
import {isModalOpenFor} from "../../../store/navigation/selectors";
import AddTask from "./AddTask";
import {getCurriculumCaseDescription, getCurriculumCaseTasks} from "../../../store/curriculumCases/selectors";
import {getCurriculumTaskTitle} from "../../../store/curriculumTask/selectors";
import {
    removeTaskFromCurriculumCase,
    reorderTaskInCurriculumCase,
    setCurriculumCaseDescription
} from "../../../store/curriculumCases/actions";
import {Link} from "@reach/router";
import {Draggable, Droppable, DragDropContext} from "react-beautiful-dnd";

const isAddModalOpen = curriculumId => state => isModalOpenFor(state, CASE_ADD_TASK_MODAL, curriculumId);
const getTaskNames = taskIds => state => taskIds.map(t => getCurriculumTaskTitle(state, t));

export default ({index}) => {
    const {curriculumId} = useParams();
    const getDescription = useCallback(getCurriculumCaseDescription.bind(null, curriculumId, index), [index, curriculumId]);
    const addModalOpen = useSelector(isAddModalOpen(curriculumId), shallowEqual);
    const description = useSelector(getDescription, shallowEqual);
    const tasks = useSelector(getCurriculumCaseTasks.bind(null, curriculumId, index), shallowEqual);
    const dispatch = useDispatch();

    const setCaseDescription = newDescription => dispatch(setCurriculumCaseDescription(curriculumId, index, newDescription));
    const openAddTaskModal = () => dispatch(openModalFor(CASE_ADD_TASK_MODAL, curriculumId, index));

    const moveTask = (result) => {
        const taskMoved = result.draggableId;
        const newPosition = result.destination.index;
        dispatch(reorderTaskInCurriculumCase(curriculumId, index, taskMoved, newPosition));
    };

    return (
    <>
        {addModalOpen && <AddTask />}
        <div className="admin-curriculum--case">
            <div className="curriculum-case--title-container">
                <h3 className="curriculum-case--index">Case {index+1}:</h3>
                <span className="curriculum-case--title">
                    <InlineEditor name={description} setName={setCaseDescription} />
                </span>
            </div>
            <div className="curriculum-case--tasks-container">
                <h3 className="curriculum-case-tasks-header">Tasks in this case:</h3>
                <DragDropContext onDragEnd={moveTask}>
                    <Droppable droppableId={`case-${index}-tasks`} type="case-task">
                        {(provided, snapshot) =>
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            <TaskList tasks={tasks} index={index} />
                            {provided.placeholder}
                        </div>}
                    </Droppable>
                </DragDropContext>
                <button className="curriculum-case--add-task" onClick={openAddTaskModal}>+ task</button>
            </div>
        </div>
        </>
    )
}

const TaskList = ({tasks, index}) => {
    const {curriculumId} = useParams();
    const dispatch = useDispatch();
    const removeTask = taskId => dispatch(removeTaskFromCurriculumCase(curriculumId, index, taskId));
    const taskNames = useSelector(getTaskNames(tasks), shallowEqual);

    return (<>
    {tasks.map((t, i) =>
        <Draggable key={t} draggableId={t} index={i}>
            {(provided, snapshot) => (
            <div className="curriculum-case--task-container" ref={provided.innerRef} {...provided.draggableProps}>
                <div className="curriculum-case-task--handle-container" {...provided.dragHandleProps}>
                    <div className="curriculum-case-task--handle-circle" />
                    <div className="curriculum-case-task--handle-circle" />
                    <div className="curriculum-case-task--handle-circle" />
                </div>
                <Link className="curriculum-case-task--task-name"
                      to={`/admin/${curriculumId}/${t}`}>{taskNames[i]}</Link>
                <button onClick={() => removeTask(t)}
                        className="curriculum-case-task--remove"/>
            </div>
            )}
        </Draggable>)}
    </>);
};
