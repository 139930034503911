import * as styles from './explainer-evidence-viewer-styles';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {closeModalType} from "../../../../store/navigation/actions";
import {EXPLAINER_EVIDENCE} from "../../../../constants/modalTypes";
import {useParams} from "@reach/router";
import {
    getCurriculumCaseDescription,
    getCurriculumCaseIndexesWithWorkBoard,
    getWorkBoardDefinitionsInCurriculumCase
} from "../../../../store/curriculumCases/selectors";
import {combineStylesAlt} from "../../../../Utility/combineStyles";
import {getSelectedExplainerEvidence} from "../../../../store/navigation/selectors";
import {addEvidenceToExplainer, removeEvidenceFromExplainer} from "../../../../store/interactions/explainer/actions";
import VisceralCardPickerWrapper from "./VisceralCardPickerWrapper";
import {getExplainerEvidenceIds} from "../../../../store/interactions/explainer/selectors";
import {getWorkBoardTemplateItemIds} from "../../../../store/curriculumTaskItems/workBoardDefinition/selectors";
import {getWorkBoardTemplateItemType} from "../../../../store/workBoardTemplate/selectors";
import {WORK_BOARD_DROP_SLOT} from "../../../../constants/TaskTypes";
import {getDraggablesInDropArea} from "../../../../store/interactions/selectors";
import {useTheme} from "@emotion/react";

const ExplainerEvidenceViewer = ({id}) => {
    const {curriculumId} = useParams();
    const casesWithWorkBoard = useSelector(state => getCurriculumCaseIndexesWithWorkBoard(curriculumId, state).filter(c => c !== null && c !== undefined), shallowEqual);
    const selectedEvidence = useSelector(state => getSelectedExplainerEvidence(state), shallowEqual);
    const addedEvidence = useSelector(state => getExplainerEvidenceIds(state, id));
    const dispatch = useDispatch();
    const theme = useTheme();

    const close = () => dispatch(closeModalType(EXPLAINER_EVIDENCE));
    const addItems = () => {
        const adds = selectedEvidence.filter(id => !addedEvidence.includes(id));
        const removals = selectedEvidence.filter(id => addedEvidence.includes(id));
        adds.length && dispatch(addEvidenceToExplainer(curriculumId, id, adds));
        removals.length && dispatch(removeEvidenceFromExplainer(curriculumId, id, removals));
        close();
    };

    return (
        <div css={styles.container}>
            <button css={styles.closeButton} onClick={close} />
            <h2 css={styles.themedTitle(theme)}>Select pieces of evidence to support your claim</h2>
            <div css={styles.casesContainer}>
                {casesWithWorkBoard.map((index, i) => <ExplainerCaseWrapper caseIndex={index} key={`case-${index}`} defaultExpanded={i === 0} explainerId={id} />)}
            </div>
            <div css={styles.buttonContainer}>
                <button onClick={addItems} css={styles.themedDoneButton(theme)}>Done</button>
            </div>
        </div>
    );
};

const getWorkBoardsWithFilledSlot = (state, curriculumId, caseIndex) =>
    getWorkBoardDefinitionsInCurriculumCase(curriculumId, caseIndex, state)
        .filter(wb => {
            const slotId = getWorkBoardTemplateItemIds(wb, state).find(i => getWorkBoardTemplateItemType(wb, i, state) === WORK_BOARD_DROP_SLOT);
            return getDraggablesInDropArea(state, slotId).length > 0;
        });
const ExplainerCaseWrapper = ({caseIndex, defaultExpanded, explainerId}) => {
    const {curriculumId} = useParams();
    const title = useSelector(state => getCurriculumCaseDescription(curriculumId, caseIndex, state));
    const workBoardIds = useSelector(state => getWorkBoardsWithFilledSlot(state, curriculumId, caseIndex), shallowEqual);
    const [expanded, setExpanded] = useState(defaultExpanded);
    const theme = useTheme();

    const innerRef = useRef();
    const outerRef = useRef();
    useEffect(() => {
        const updateSizer = () => {
            outerRef.current.style.height = `${innerRef.current.getBoundingClientRect().height + 4}px`;
        };
        updateSizer();
        window.addEventListener("resize", updateSizer);
        return () => window.removeEventListener("resize", updateSizer);
    });

    const toggleExpanded = () => setExpanded(e => !e);

    return (
        <div css={combineStylesAlt([
            [styles.themedCaseContainer(theme), true],
            [styles.caseContainerCollapsed, !expanded],
        ])}>
            <button onClick={toggleExpanded} css={combineStylesAlt([
                [styles.expander, true],
                [styles.expanderCollapsed, !expanded],
            ])} />
            <h3 onClick={toggleExpanded} css={combineStylesAlt([
                [styles.caseTitle, true],
                [styles.caseTitleExpanded, expanded],
            ])}>{title}</h3>
            <div ref={outerRef} css={combineStylesAlt([
                [styles.evidenceExpander, true],
                [styles.evidenceExpanderCollapsed, !expanded],
            ])}>
                <div ref={innerRef}>
                    {workBoardIds.map(i => <VisceralCardPickerWrapper key={i} id={i} caseIndex={caseIndex} explainerId={explainerId} />)}
                </div>
            </div>
        </div>
    )
};

export default ExplainerEvidenceViewer;
