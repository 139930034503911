export const shallowUnorderedArrayCompare = (a, b) => {
    if((!a && b) || (!b && a)) { return false; }
    if(a.length !== b.length) { return false; }
    if(a.length === 0 && b.length === 0) {return true;}
    return a.every(c => b.indexOf(c) >= 0);
};

export const shallowOrderedArrayCompare = (a, b) => {
    if((!a && b) || (!b && a)) { return false; }
    if(a.length !== b.length) { return false; }
    return a.every((c, i) => b[i] === c);
};
