import * as styles from './header-type-styles';
import * as baseStyles from './button-bar-styles';
import React, {useEffect, useRef, useState} from 'react';
import {ReactEditor, useSlate} from "slate-react";
import {Editor, Transforms} from "slate";
import combineStyles from "../../Utility/combineStyles";
import {EDITOR_COMPONENT_HEADER} from "../editorComponents/Header";

export default () => {
    const [showSelector, setShowSelector] = useState(false);
    const [selection, setSelection] = useState();
    const editor = useSlate();
    const selectorRef = useRef();

    const [nodes] = Editor.nodes(editor, { match: n => n.type === EDITOR_COMPONENT_HEADER });
    const current = nodes ? nodes[0].headerType : null;

    useEffect(() => {
        if(showSelector) {
            selectorRef.current.focus();
        }
    }, [showSelector]);

    const capture = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const focusEditor = () => {
        ReactEditor.focus(editor);
        Transforms.select(editor, selection);
    };
    const blurSelector = () => setShowSelector(false);
    const toggleSelector = e => {
        capture(e);
        if(!showSelector) {
            setSelection(editor.selection);
        } else {
            focusEditor();
        }
        setShowSelector(!showSelector);
    };
    const selectTextStyle = headerType => e => {
        capture(e);
        focusEditor();
        if(current) {
            Transforms.unwrapNodes(editor, {
                split: true,
                match: n => n.type === EDITOR_COMPONENT_HEADER,
            });
        }
        if(headerType) {
            Transforms.wrapNodes(editor, {
                type: EDITOR_COMPONENT_HEADER,
                headerType,
            }, {
                split: true,
            });
        }

        setShowSelector(false);
    }

    const buttonStyle = combineStyles(baseStyles, {
        'button': true,
        'headerButton': true,
    });
    return (
        <button css={buttonStyle} onMouseDown={toggleSelector} onClick={capture}>
            {showSelector && <div ref={selectorRef} onBlur={blurSelector} css={styles.selectorContainer} tabIndex={-1}>
                <span onMouseDown={selectTextStyle(null)} css={combineStyles(styles, {
                    headerSelector: true,
                    activeHeader: current === null,
                })}>Normal</span>
                <span onMouseDown={selectTextStyle(1)} css={combineStyles(styles, {
                    headerSelector: true,
                    activeHeader: current === 1,
                })}><h1>Heading 1</h1></span>
                <span onMouseDown={selectTextStyle(2)} css={combineStyles(styles, {
                    headerSelector: true,
                    activeHeader: current === 2,
                })}><h2>Heading 2</h2></span>
                <span onMouseDown={selectTextStyle(3)} css={combineStyles(styles, {
                    headerSelector: true,
                    activeHeader: current === 3,
                })}><h3>Heading 3</h3></span>
            </div>}
        </button>
    )
}
