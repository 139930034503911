import "./curriculum-navigation.scss";
import React from 'react';
import classNames from 'class-names';
import {useSelector, useDispatch, shallowEqual} from "react-redux";
import {
    getActivitiesForTopic,
    getCurriculumTopics, getFirstIncompleteActivity, getTopicForActivity, getTopicTitle,
} from "../../store/curriculum/selectors";
import Topic from "./Topic";
import {getSelectedActivity, getSelectedTopic} from "../../store/navigation/selectors";
import {selectActiveActivity, selectActiveTopic} from "../../store/navigation/actions";

const stateMap = state => {
    const firstIncompleteActivity = getFirstIncompleteActivity(state);
    return {
        topics: getCurriculumTopics(state),
        selectedActivity: getSelectedActivity(state),
        firstIncompleteActivity,
        incompleteActivityTopic: getTopicForActivity(state, firstIncompleteActivity),
}};

const comparison = (left, right) => {
    if(left.selectedActivity !== right.selectedActivity) { return false; }
    if((left.topics && !right.topics) || (!left.topics && right.topics)) { return false; }
    else if(left.topics.length !== right.topics.length) { return false; }
    else if(!left.topics.every((t, i) => t === right.topics[i])) { return false; }
    return true;
};

export default React.memo(() => {
    const { topics, selectedActivity, firstIncompleteActivity, incompleteActivityTopic } = useSelector(stateMap, comparison);
    const dispatch = useDispatch();

    if(!selectedActivity && firstIncompleteActivity) {
        dispatch(selectActiveTopic(incompleteActivityTopic));
        dispatch(selectActiveActivity(firstIncompleteActivity));
    }

    return (
        <div className="curriculum-navigation-container">
            <div className="curriculum-navigation--topic-buttons-container">
                {topics.map(id => <TopicButton id={id} key={id} />)}
            </div>
            <div className="curriculum-navigation--topics-container">
                {topics.map(id => <Topic id={id} key={id} />)}
            </div>
        </div>
    );
}, () => true);

const topicButtonStateMap = props => state => {
    const title = getTopicTitle(state, props.id).split(' ');
    return {
        selected: getSelectedTopic(state) === props.id,
        firstTitleWord: title[0],
        secondTitleWord: title[1],
        firstActivity: getActivitiesForTopic(state, props.id)[0],
    };
};

const TopicButton = React.memo(props => {
    const {selected, firstTitleWord, secondTitleWord, firstActivity} = useSelector(topicButtonStateMap(props), shallowEqual);
    const dispatch = useDispatch();

    const select = () => {
        dispatch(selectActiveActivity(firstActivity));
        dispatch(selectActiveTopic(props.id));
    };

    return (
        <button onClick={select} className={classNames({
            "curriculum-navigation--topic-button": true,
            "curriculum-navigation--topic-button__selected": selected,
        })}>
            <div className="topic-button--first-word">{firstTitleWord}</div>
            <div className={classNames({
                "topic-button--second-word": true,
                "topic-button--second-word__number": !isNaN(parseInt(secondTitleWord)),
            })}>
                {secondTitleWord}
            </div>
        </button>
    )
});
