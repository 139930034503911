import Link, {EDITOR_COMPONENT_LINK} from "../../../RichText/editorComponents/Link";
import {EDITOR_COMPONENT_LONG_TEXT_REFERENCE} from "../../../RichText/editorComponents/LongTextReference";
import React from "react";
import LongTextReference from "./LongTextReference";
import Video, {EDITOR_COMPONENT_VIDEO} from "../../../RichText/editorComponents/Video";
import Image, {EDITOR_COMPONENT_IMAGE} from "../../../RichText/editorComponents/Image";
import {EDITOR_COMPONENT_DRAGGABLE_BANK_REFERENCE} from "../../../RichText/editorComponents/DraggableBankReference";
import DraggableBankReference from "./DraggableBankReference";
import Header, {EDITOR_COMPONENT_HEADER} from "../../../RichText/editorComponents/Header";

const TagMap = {
    [EDITOR_COMPONENT_LINK]: Link,
    [EDITOR_COMPONENT_LONG_TEXT_REFERENCE]: LongTextReference,
    [EDITOR_COMPONENT_VIDEO]: Video,
    [EDITOR_COMPONENT_IMAGE]: Image,
    [EDITOR_COMPONENT_DRAGGABLE_BANK_REFERENCE]: DraggableBankReference,
    "bulleted-list": ({attributes, children}) => <ul {...attributes}>{children}</ul>,
    "numbered-list": ({attributes, children}) => <ol {...attributes}>{children}</ol>,
    "list-item": ({attributes, children}) => <li {...attributes}>{children}</li>,
    [EDITOR_COMPONENT_HEADER]: Header,
};

export default (props) => {
    const {element, attributes, children} = props;
    if(TagMap.hasOwnProperty(element.type)) {
        const Tag = TagMap[element.type];
        return <Tag {...props} />
    }
    return <p {...attributes}>{children}</p>
};
