import * as styles from './editor-style';
import React from 'react';
import CurriculumList from "./CurriculumList";
import Curriculum from "./Curriculum";
import {Link, Router} from "@reach/router";
import AddLtiProvider from "./AddLtiProvider";

export default () => {
    return (
        <div css={styles.container}>
            <Router css={styles.router}>
                <CurriculumList path="/" />
                <AddLtiProvider path="/addLtiProvider" />
                <Curriculum path="/:curriculumId" />
                <Curriculum path="/:curriculumId/:taskId" />
            </Router>
        </div>
    )
}
