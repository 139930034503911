import * as styles from "./scrolling-task-styles.js";
import React, {useRef} from 'react';
import ScrollingItem from './ScrollingItems';
import {shallowEqual, useSelector} from "react-redux";
import {getCurriculumTaskHeight, getCurriculumTaskItemIds} from "../../store/curriculumTask/selectors";
import {useDrop} from "react-dnd";
import WorkBoardTray from "./WorkBoardTray";
import {idsForOpenModalType} from "../../store/navigation/selectors";
import {WORK_BOARD_MODAL} from "../../constants/modalTypes";
import WorkBoard from "./ScrollingItems/WorkBoard";
import {getAllWorkBoardDefinitionsInCurriculum, getNextTaskInCurriculum} from "../../store/curriculumCases/selectors";
import {useNavigate, useParams} from "@reach/router";
import {continueButton} from "./section-task-styles";
import {useTheme} from "@emotion/react";
import {themedBodyButton} from "../../style-variables";

const getInteractables = taskId => state => getCurriculumTaskItemIds(state, taskId);

export default ({taskId}) => {
    const {curriculumId} = useParams();
    const items = useSelector(getInteractables(taskId), shallowEqual);
    const height = useSelector(getCurriculumTaskHeight.bind(null, taskId), shallowEqual);
    const curriculumWorkBoards = useSelector(state => getAllWorkBoardDefinitionsInCurriculum(curriculumId, state).length);
    const openTaskWorkBoards = useSelector(idsForOpenModalType.bind(null, WORK_BOARD_MODAL));
    const scroller = useRef();
    const navigate = useNavigate();
    const theme = useTheme()
    const nextTask = useSelector(state => getNextTaskInCurriculum(state, curriculumId, taskId));

    const [_, dropRef] = useDrop({
        accept: [WORK_BOARD_MODAL],
        drop: (_, monitor) => {
            const {x: deltaX, y: deltaY} = monitor.getDifferenceFromInitialOffset();
            return {deltaX, deltaY};
        },
    });
    const navigateForward = () => navigate(`./${nextTask}`);

    return (
        <div ref={scroller} css={styles.taskContainer}>
            <div ref={dropRef} css={styles.scrollingContent} style={{height}}>
                {items.map(i => <ScrollingItem id={i} key={i} scroller={scroller} />)}
            </div>
            {curriculumWorkBoards > 0 && <WorkBoardTray/>}
            {openTaskWorkBoards.map(id => <WorkBoard taskId={id} key={id} /> )}
            {nextTask && <button css={[themedBodyButton(theme), continueButton]} onClick={navigateForward}>Next</button>}
        </div>
    )
};
