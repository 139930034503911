const getPeaPlantCards = state => state.peaPlantCards;
const getPeaPlantCard = (state, id) => getPeaPlantCards(state)[id];
export const getPeaPlantCardDisplayMode = (id, state) => {
    const plantCard = getPeaPlantCard(state, id);
    if(!plantCard) { return null; }
    return plantCard.cardDisplayMode;
}
export const getPeaPlantCardDisplayTrait = (id, state) => getPeaPlantCard(state, id).displayTrait;
export const getPeaPlantCardPlantId = (state, cardId) => getPeaPlantCard(state, cardId).peaPlant;
export const getPeaPlantCardIds = state => Object.keys(state.peaPlantCards);
