import * as styles from './item-context-menu-styles';
import React from 'react';
import {WORK_BOARD_CROSS_CARD} from "../../../../../constants/TaskTypes";
import CrossCardContextMenu from "./CrossCard/ContextMenu";
import {modalAdditionalData} from "../../../../../store/navigation/selectors";
import {TEMPLATE_ITEM_CONTEXT} from "../../../../../constants/modalTypes";
import {useDispatch, useSelector} from "react-redux";
import {css} from "@emotion/core";
import {removeItemFromWorkBoardTemplate} from "../../../../../store/workBoardTemplate/actions";
import {useParams} from "@reach/router";
import {openModalFor} from "../../../../../store/navigation/actions";
import {setScrollingItemEditing} from "../../../../../store/scrollingTaskItemPositions/actions";

const TagMap = {
    [WORK_BOARD_CROSS_CARD]: CrossCardContextMenu,
};

const getData = id => state => modalAdditionalData(state, TEMPLATE_ITEM_CONTEXT, id);

export default ({id, workBoardDefinition}) => {
    const {curriculumId} = useParams();
    const {x, y, type} = useSelector(getData(id));
    const dispatch = useDispatch();

    const capture = e => e.stopPropagation();
    const removeItem = () => {
        dispatch(removeItemFromWorkBoardTemplate(curriculumId, workBoardDefinition, id));
        dispatch(openModalFor(null, id));
    };
    const editItem = () => {
        dispatch(setScrollingItemEditing(curriculumId, id, true));
        dispatch(openModalFor(null, id));
    };

    const Tag = TagMap[type];
    return (
        <div onClick={capture} css={css(styles.menu, {
            left: x,
            top: y,
        })}>
            {Tag && <Tag id={id} />}
            <button onClick={editItem} css={styles.menuItemButton}>Edit</button>
            <button onClick={removeItem} css={styles.menuItemButton}>Remove</button>
        </div>
    )
}
