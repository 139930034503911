export const SET_MULTIPLE_CHOICE_INPUT_PROMPT = "Set multiple choice input prompt";
export const setMultipleChoiceInputPrompt = (curriculumId, interactableId, prompt) => ({
    type: SET_MULTIPLE_CHOICE_INPUT_PROMPT,
    curriculumId,
    interactableId,
    prompt,
});

export const ADD_MULTIPLE_CHOICE_INPUT_OPTION = "Add multiple choice input option";
export const addMultipleChoiceInputOption = (curriculumId, multipleChoiceId) => ({
    type: ADD_MULTIPLE_CHOICE_INPUT_OPTION,
    curriculumId,
    multipleChoiceId,
});

export const SET_MULTIPLE_CHOICE_INPUT_OPTION_TEXT = "Set multiple choice input option text";
export const setMultipleChoiceInputOptionText = (curriculumId, multipleChoiceId, optionIndex, text) => ({
    type: SET_MULTIPLE_CHOICE_INPUT_OPTION_TEXT,
    curriculumId,
    multipleChoiceId,
    optionIndex,
    text,
});

export const REMOVE_MULTIPLE_CHOICE_INPUT_OPTION = "Remove multiple choice input option";
export const removeMultipleChoiceInputOption = (curriculumId, multipleChoiceId, optionIndex) => ({
    type: REMOVE_MULTIPLE_CHOICE_INPUT_OPTION,
    curriculumId,
    multipleChoiceId,
    optionIndex,
});

export const SET_MULTIPLE_CHOICE_INPUT_CORRECT_SELECTION = "Set multiple choice input correct";
export const setMultipleChoiceInputCorrect = (curriculumId, multipleChoiceId, optionIndex, correct) => ({
    type: SET_MULTIPLE_CHOICE_INPUT_CORRECT_SELECTION,
    curriculumId,
    multipleChoiceId,
    optionIndex,
    correct,
});

export const SET_MULTIPLE_CHOICE_INPUT_MULTISELECT = "Set multiple choice input multiselect";
export const setMultipleChoiceInputMultiselect = (curriculumId, multipleChoiceId, multiselect) => ({
    type: SET_MULTIPLE_CHOICE_INPUT_MULTISELECT,
    curriculumId,
    multipleChoiceId,
    multiselect,
});
