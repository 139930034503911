import {css} from "@emotion/core";
import {headerButtonsFontFamily, headerButtonsFontWeight, primaryColor, secondaryColor} from "../themes/selectors";
import {darken} from "color2k";
import {bbPadding, inlineBlockMiddle} from "../../style-variables";

export const menuContainer = theme => css(bbPadding("23px 19px"), {
    border: `5px solid ${primaryColor(theme)}`,
    borderRight: 'none',
    backgroundColor: darken(primaryColor(theme), 0.65),
    position: "fixed",
    right: "0",
    top: "140px",
    zIndex: 2,
    transform: "translateX(100%)",
    transition: "transform 0.3s",
    fontFamily: headerButtonsFontFamily(theme),
    textAlign: "left",
});
export const menuContainerExpanded = css({
    transform: "translateX(0)",
});

export const menuToggleButton = theme => css(bbPadding("6px 45px"), {
    background: primaryColor(theme),
    border: 'none',
    position: "absolute",
    top: "-5px",
    right: "100%",
    transformOrigin: "100% 100%",
    transform: "rotate(-90deg)",
    color: darken(primaryColor(theme), 0.65),
    fontWeight: 700,
    fontSize: "1.5em",
    cursor: "pointer",
});

export const caseContainer = css({

});

export const caseTitle = theme => css({
    color: primaryColor(theme),
    fontSize: "2em",
    fontWeight: headerButtonsFontWeight(theme),
    cursor: "pointer",
    textDecoration: "none",
    textTransform: "uppercase",
});

export const casePageContainer = css(bbPadding("4px 0 4px 14px"), {
    overflow: "hidden",
    transition: "max-height 0.2s",
    margin: 0,
});

export const casePage = theme => css({
    color: secondaryColor(theme),
    fontSize: "1.25em",
    fontWeight: 500,
});

export const caseMarker = theme => css(inlineBlockMiddle, {
    width: "17px",
    height: "17px",
    border: `1px solid ${secondaryColor(theme)}`,
    borderRadius: "50%",
    marginRight: "12px",
});
export const caseText = css(inlineBlockMiddle, {
    textTransform: "uppercase",
});
