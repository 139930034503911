import * as styles from './drop-slot-style';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {isItemEditing} from "../../../../store/itemSelection/selector";
import {PHOTOGRAPH, PLANT_CARD, PLANT_PHENOTYPE} from "../../../../constants/DropItemTypes";
import {css} from "@emotion/core";
import {useParams} from "@reach/router";
import {getDropSlotAccepts, getDropSlotLabel, getDropSlotValidationData} from "../../../../store/dropSlots/selectors";
import {setDropSlotAccepts, setDropSlotLabel, setDropSlotValidationData} from "../../../../store/dropSlots/actions";
import combineStyles from "../../../../Utility/combineStyles";
import {DISPLAY_NAME_MAP, VISCERAL_PHOTO_STRUCTURES} from "../../../../constants/IdentifiableStructures";
import {setScrollingItemEditing} from "../../../../store/scrollingTaskItemPositions/actions";

const slotStyleMap = {
    [PLANT_CARD]: "plantCardSlot",
    [PLANT_PHENOTYPE]: "phenotypeSlot",
    [PHOTOGRAPH]: "photographSlot",
};
const slotTypeMap = {
    [PLANT_CARD]: "Plant card",
    [PLANT_PHENOTYPE]: "Phenotype",
    [PHOTOGRAPH]: "Photograph",
};

export default ({id}) => {
    const {curriculumId} = useParams();
    const acceptedType = useSelector(state => getDropSlotAccepts(id, state));
    const label = useSelector(state => getDropSlotLabel(id, state));
    const editing = useSelector(state => isItemEditing(id, state));
    const validationData = useSelector(state => getDropSlotValidationData(id, state));
    const dispatch = useDispatch();
    const [editingLabel, setEditingLabel] = useState(false);
    const labelInputRef = useRef();

    useEffect(() => {
        if(labelInputRef.current) {
            labelInputRef.current.focus();
        }
    }, [editingLabel]);

    const selectType = e => dispatch(setDropSlotAccepts(curriculumId, id, e.target.value));
    const setLabel = e => {
        dispatch(setDropSlotLabel(curriculumId, id, e.target.value));
        setEditingLabel(false);
    };
    const finishEditing = e => {
        if(e.which !== 13) { return; }
        setLabel(e);
    }
    const setValidationData = e => dispatch(setDropSlotValidationData(curriculumId, id, e.target.value));
    const stopEditing = () => dispatch(setScrollingItemEditing(curriculumId, id, false));

    let slotStyle = styles.slot;
    if(acceptedType) {
        slotStyle = css(slotStyle, styles[slotStyleMap[acceptedType]]);
    }
    const showEditor = !acceptedType || editing;
    return (
        <div css={combineStyles(styles, {
            container: "true",
            containerEditing: showEditor,
        })}>
            {showEditor && <div>
                <label css={styles.selectorLabel}>Slot type</label>
                <select value={acceptedType} onChange={selectType}>
                    <option value={PLANT_CARD}>Plant Card</option>
                    <option value={PLANT_PHENOTYPE}>Phenotype</option>
                    <option value={PHOTOGRAPH}>Photograph</option>
                </select>
                <input defaultValue={label} css={styles.labelInput} onBlur={setLabel} placeholder="Label" />
                {acceptedType === PHOTOGRAPH && <div>
                    <label css={styles.selectorLabel}>Photo must ID:</label>
                    <select value={validationData} onChange={setValidationData}>
                        {VISCERAL_PHOTO_STRUCTURES.map(s => <option value={s} key={s}>{DISPLAY_NAME_MAP[s]}</option>)}
                    </select>
                </div>}
                <button css={styles.typeSelector} onClick={stopEditing}>Done</button>
            </div>}
            {!showEditor && <div>
                <div css={slotStyle}>{slotTypeMap[acceptedType]}</div>
                {!editingLabel && <div onClick={setEditingLabel.bind(null, true)} css={combineStyles(styles, {
                    'label': true,
                    'labelEmpty': !label,
                })}>{label || "no label"}</div>}
                {editingLabel && <input ref={labelInputRef} defaultValue={label} css={styles.labelInput} onBlur={setLabel} onKeyDown={finishEditing} placeholder="Label" />}
            </div>}
        </div>
    );
};
