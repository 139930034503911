import {css} from "@emotion/core";
import {inlineBlockTop} from "../../style-variables";
import boldIcon from '../assets/format_bold.svg';
import italicIcon from '../assets/format_italic.svg';
import underlineIcon from '../assets/format_underlined.svg';
import orderedListIcon from '../assets/format_ordered-list.svg';
import unorderedListIcon from '../assets/format_unordered-list.svg';
import linkIcon from '../assets/format_link.svg';
import imageIcon from '../assets/image.svg';
import taskReferenceIcon from '../assets/task-reference.svg';
import videoIcon from '../assets/video.svg';
import headersIcon from '../assets/format_header-type.svg';

const EDITOR_BUTTON_SIZE = "30px";

export const stylingButton = css(inlineBlockTop, {
    width: EDITOR_BUTTON_SIZE,
    height: EDITOR_BUTTON_SIZE,
    border: "1px solid #f2f2f2",
    margin: "0 2px 2px 0",
    backgroundColor: "white",
    backgroundSize: "24px 24px",
    backgroundPosition: "50% 70%",
    backgroundRepeat: "no-repeat",
    cursor: "pointer",
    transition: "background-color 0.2s, border-color 0.2s",

    "&:hover, &__active": {
        borderColor: "#6f6f6f",
        backgroundColor: "#f2f2f2",
    },

    "&:last-of-type": {
        marginRight: 0,
    },
});

export const boldButton = css({
    backgroundImage: `url(${boldIcon})`,
});

export const italicButton = css({
    backgroundImage: `url(${italicIcon})`,
});

export const underlineButton = css({
    backgroundImage: `url(${underlineIcon})`,
})

export const orderedListButton = css({
    backgroundImage: `url(${orderedListIcon})`,
})

export const unorderedListButton = css({
    backgroundImage: `url(${unorderedListIcon})`,
});

export const hyperlinkButton = css({
    backgroundImage: `url(${linkIcon})`,
});

export const taskReferenceButton = css({
    backgroundImage: `url(${taskReferenceIcon})`,
});

export const videoButton = css({
    backgroundImage: `url(${videoIcon})`,
});

export const imageButton = css({
    backgroundImage: `url(${imageIcon})`,
});

export const headerButton = css({
    backgroundImage: `url(${headersIcon})`,
});

export const separator = css(inlineBlockTop, {
    flex: "0 0 auto",
    width: "1px",
    height: EDITOR_BUTTON_SIZE,
    backgroundColor: "#6f6f6f",
    margin: "0 7px 0 5px",
});
