import * as styles from "./scrolling-item-style";
import React, {useEffect, useRef} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    getScrollingItemDraggable,
    getScrollingItemFixed,
    getScrollingItemLocked,
    getScrollingItemPositions
} from "../../../../../store/scrollingTaskItemPositions/selectors";
import {
    FULL_WIDTH_TYPES,
    RESIZEABLE_TYPES,
    SCROLLER_EXPLAINER,
    SCROLLER_SECTION,
    SCROLLER_WORK_BOARD,
    SCROLLING_DATA_ITEM,
    SCROLLING_DROP_SLOT_ITEM,
    SCROLLING_IMAGE_ITEM,
    SCROLLING_SHAPE_ITEM,
    SCROLLING_TEXT_ITEM
} from "../../../../../constants/TaskTypes";
import Text from './Text';
import Shape from "./Shape";
import Image from "./Image";
import {useDrag} from "react-dnd";
import {moveScrollingTaskItem, setScrollingItemSelected} from "../../../../../store/scrollingTaskItemPositions/actions";
import {useParams} from "@reach/router";
import ItemMenu from "../Menus/ItemMenu";
import useScroll from "../../../../../Utility/useScroll";
import {css} from "@emotion/core";
import combineStyles from "../../../../../Utility/combineStyles";
import {isItemEditing, isItemSelected} from "../../../../../store/itemSelection/selector";
import ResizeHandle from "./ResizeHandle";
import {openModalFor} from "../../../../../store/navigation/actions";
import {isModalOpenFor} from "../../../../../store/navigation/selectors";
import {SCROLLER_ITEM_CONTEXT} from "../../../../../constants/modalTypes";
import ItemContextMenu from "../Menus/ItemContextMenu";
import DropSlot from "../../Interactables/DropSlot";
import DataItem from "./DataItem";
import {SCROLLING_CANVAS_WIDTH} from "../scrolling-styles";
import Explainer from "./Explainer";
import Section from "./Section";
import WorkBoard from './WorkBoard';
import {getCurriculumTaskItemType} from "../../../../../store/curriculumTaskItems/selectors";

const TagMap = {
    [SCROLLING_TEXT_ITEM]: Text,
    [SCROLLING_SHAPE_ITEM]: Shape,
    [SCROLLING_IMAGE_ITEM]: Image,
    [SCROLLING_DROP_SLOT_ITEM]: DropSlot,
    [SCROLLING_DATA_ITEM]: DataItem,
    [SCROLLER_EXPLAINER]: Explainer,
    [SCROLLER_SECTION]: Section,
    [SCROLLER_WORK_BOARD]: WorkBoard,
};

export const SCROLLING_DRAG_ITEM = "ScrollingItem";

const isModalOpen = id => state => isModalOpenFor(state, SCROLLER_ITEM_CONTEXT, id);

const ScrollingItemRoot = ({id, scroller}) => {
    const {curriculumId} = useParams();
    const positions = useSelector(state => getScrollingItemPositions(id, state), shallowEqual);
    const itemType = useSelector(state => getCurriculumTaskItemType(state, id));
    const locked = useSelector(state => getScrollingItemLocked(id, state));
    const draggable = useSelector(state => getScrollingItemDraggable(id, state));
    const fixed = useSelector(state => getScrollingItemFixed(id, state));
    const editing = useSelector(state => isItemEditing(id, state));
    const selected = useSelector(state => isItemSelected(id, state));
    const showContextMenu = useSelector(isModalOpen(id), shallowEqual);
    const dispatch = useDispatch();
    const itemRef = useRef();
    const resizeData = RESIZEABLE_TYPES[itemType];

    useEffect(() => {
        if(!selected && showContextMenu) {
            dispatch(openModalFor(null, id));
        }
    }, [selected, showContextMenu]);

    useEffect(() => {
        if(!selected || editing) { return; }
        const keyboardMove = e => {
            const magnitude = e.shiftKey ? 100 :
                e.ctrlKey ? 10 :
                1;
            const startScroll = positions[0].scroll;
            const endScroll = positions[1].scroll;
            const breakPointIndex = scroller.current.scrollTop <= startScroll || (locked && !endScroll) ? 0 : 1;
            const {x,y} = positions[breakPointIndex];
            const width = (resizeData && resizeData.x) ? positions[breakPointIndex].width : itemRef.current.getBoundingClientRect().width;
            const maxX = SCROLLING_CANVAS_WIDTH - width
            switch(e.which) {
                case 37:
                    return dispatch(moveScrollingTaskItem(curriculumId, id, breakPointIndex, Math.max(0, x - magnitude), y));
                case 38:
                    return dispatch(moveScrollingTaskItem(curriculumId, id, breakPointIndex, x, Math.max(0, y - magnitude)));
                case 39:
                    return dispatch(moveScrollingTaskItem(curriculumId, id, breakPointIndex, Math.min(maxX, x + magnitude), y));
                case 40:
                    return dispatch(moveScrollingTaskItem(curriculumId, id, breakPointIndex, x, y + magnitude));
            }
        };
        window.addEventListener("keydown", keyboardMove);

        return () => window.removeEventListener("keydown", keyboardMove);
    }, [selected, positions, editing]);

    const [_, dragRef, previewRef] = useDrag( {
        item: {type: SCROLLING_DRAG_ITEM},
        begin: () => {
            dispatch(setScrollingItemSelected(curriculumId, id, true));
            dispatch(openModalFor(null, id));
        },
        end: (_, monitor) => {
            if(!monitor.didDrop()) { return; }
            const result = monitor.getDropResult();
            if(!result) { return; }
            const {deltaX, deltaY} = result;
            const {x, y} = positions[0];
            const startScroll = positions[0].scroll;
            const endScroll = positions[1].scroll;
            if(scroller.current.scrollTop <= startScroll || (locked && !endScroll)) {
                const width = (resizeData && resizeData.x) ? positions[0].width : itemRef.current.getBoundingClientRect().width;
                const finalX = Math.max(0, Math.min(SCROLLING_CANVAS_WIDTH - width, x+deltaX));
                const finalY = Math.max(0, y+deltaY);
                dispatch(moveScrollingTaskItem(curriculumId, id, 0, finalX, finalY));
            } else {
                const endX = positions[1].x;
                const endY = positions[1].y;
                const width = (resizeData && resizeData.x) ? positions[1].width : itemRef.current.getBoundingClientRect().width;
                const finalX = Math.max(0, Math.min(SCROLLING_CANVAS_WIDTH - width, endX !== null ? endX + deltaX : x + deltaX));
                const finalY = Math.max(0, endY !== null ? endY + deltaY : (y + deltaY + (scroller.current.scrollTop - startScroll)));
                dispatch(moveScrollingTaskItem(curriculumId, id, 1, finalX, finalY));
            }
        },
    });
    const setItemRef = r => {
        itemRef.current = r;
        if(!editing && !fixed) {
            dragRef(r);
        }
    };

    useScroll(scroller, itemRef, positions, locked, resizeData);

    const capture = e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setScrollingItemSelected(curriculumId, id, true));
        dispatch(openModalFor(null, id));
    };

    const toggleContext = e => {
        if(fixed) { return; }
        e.preventDefault();
        e.stopPropagation();
        if(showContextMenu) {
            dispatch(openModalFor(null, id));
        } else {
            if(!selected) {
                dispatch(setScrollingItemSelected(curriculumId, id, true));
            }
            const {left, top} = itemRef.current.getBoundingClientRect();
            dispatch(openModalFor(SCROLLER_ITEM_CONTEXT, id, {
                x: e.clientX - left,
                y: e.clientY - top,
            }));
        }
    }

    let containerStyle = combineStyles(styles,{
        container: true,
        containerEditing: editing,
        containerFullWidth: FULL_WIDTH_TYPES.includes(itemType),
        containerDraggable: draggable,
    });
    containerStyle = css(containerStyle,
        styles.scrollingItemConfiguration(positions[0].x, positions[0].y));
    const editingWrapperStyle = combineStyles(styles, {
        editingWrapper: true,
        editingWrapperEditing: selected,
    });
    const ItemTag = TagMap[itemType];

    return (
        <div ref={setItemRef}  css={containerStyle} onClick={capture} onContextMenu={toggleContext}>
            <ItemMenu id={id} scroller={scroller} itemRef={itemRef} resizeData={resizeData}/>
            {showContextMenu && <ItemContextMenu item={id} />}
            <div css={editingWrapperStyle} ref={previewRef}>
                <ItemTag id={id} />
                {(resizeData && resizeData.x !== 0) && <ResizeHandle resizeDirection={{x: resizeData.x}} item={id} itemRef={itemRef} scrollerRef={scroller}/>}
                {(resizeData && resizeData.y !== 0) && <ResizeHandle resizeDirection={{y: resizeData.y}} item={id} itemRef={itemRef} scrollerRef={scroller}/>}
                {(resizeData && resizeData.x !== 0 && resizeData.y !== 0) && <ResizeHandle resizeDirection={resizeData} item={id} itemRef={itemRef} scrollerRef={scroller}/>}
            </div>
        </div>
    );
};

export default ScrollingItemRoot;
