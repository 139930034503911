import {WORK_BOARD} from "../../constants/WorkBoardItemTypes";
import {getWorkBoardTemplateItemType} from "../workBoardTemplate/selectors";

export const getWorkBoard = (state, id) => state.workBoards[id];
export const getWorkBoardName = (state, workBoardId) => getWorkBoard(state, workBoardId).title;
const getItemsForBoard = (state, boardId) => getWorkBoard(state, boardId).items;
export const getBoardItemIds = (state, boardId) => getItemsForBoard(state, boardId);
export const getDropAreaType = (state, itemId) => {
    if(getWorkBoard(state, itemId)) { return WORK_BOARD; }
    return getWorkBoardTemplateItemType(null, itemId, state);
}
