import * as styles from './template-root-styles';
import React, {useRef} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getWorkBoardTemplateItemIds} from "../../../../../store/curriculumTaskItems/workBoardDefinition/selectors";
import {closeModalType, openModalFor} from "../../../../../store/navigation/actions";
import {TEMPLATE_ITEM_CONTEXT, TEMPLATE_MENU} from "../../../../../constants/modalTypes";
import {idsForOpenModalType, isModalOpenFor} from "../../../../../store/navigation/selectors";
import CreateMenu from "./CreateMenu";
import Item from './TemplateItem';
import ItemContextMenu from "./ItemContextMenu";
import useDropSortable from "../../../../../Utility/useDropSortable";
import {moveWorkBoardTemplateItem} from "../../../../../store/workBoardTemplate/actions";
import {useParams} from "@reach/router";

const getCreateMenuShowing = id => state => isModalOpenFor(state, TEMPLATE_MENU, id);
export const WORK_BOARD_TEMPLATE_DRAG_ITEM = "WorkBoardTemplateItem";

export default props => {
    const {workBoard} = props;
    const {curriculumId} = useParams();
    const items = useSelector(state => getWorkBoardTemplateItemIds(workBoard, state), shallowEqual);
    const templateArea = useRef();
    const menuShowing = useSelector(getCreateMenuShowing(workBoard));
    const templateContextMenuId = useSelector(idsForOpenModalType.bind(null, TEMPLATE_ITEM_CONTEXT))[0];
    const dispatch = useDispatch();

    const [_, dropRef] = useDropSortable({
        accept: [WORK_BOARD_TEMPLATE_DRAG_ITEM],
        drop: (item, monitor, placeholderIndex) => {
            const {itemId} = item;
            dispatch(moveWorkBoardTemplateItem(curriculumId, workBoard, itemId, placeholderIndex))
        },
    }, items);
    const setRefs = r => {
        templateArea.current = r;
        dropRef(r);
    };

    const closeMenus = () => {
        dispatch(openModalFor(null, workBoard));//closes.. kinda gross.
        dispatch(closeModalType(TEMPLATE_ITEM_CONTEXT));
    };
    const toggleCreateMenuShowing = e => {
        e.preventDefault();
        // if(anythingSelected){
        //     return dispatch(setScrollingItemSelected(curriculumId));
        // }
        if(!menuShowing) {
            const {top, left, width, height} = templateArea.current.getBoundingClientRect();
            const x = (e.clientX - left) / width;
            const y = (e.clientY - top) / height;
            dispatch(closeModalType(TEMPLATE_ITEM_CONTEXT));
            dispatch(openModalFor(TEMPLATE_MENU, workBoard, { x, y }));
        } else {
            closeMenus();
        }
    }

    return (
        <div ref={setRefs} css={styles.templateContainer} onContextMenu={toggleCreateMenuShowing} onClick={closeMenus}>
            {templateContextMenuId && <ItemContextMenu key={templateContextMenuId} id={templateContextMenuId} workBoardDefinition={workBoard} />}
            {menuShowing && <CreateMenu id={workBoard} />}
            {items.map(i => <Item key={i} id={i} workBoard={workBoard} />)}
        </div>
    );
};
