import './learn-work-board.scss';

import React from 'react';
import WorkBoard from "../../WorkBoard";
import ToolBox from "../../WorkBoard/ToolBox";

export default props => {
    return (
        <div className='learner-work-board-container'>
            <WorkBoard boardId={props.id} workBoardDefinition={props.workBoardDefinition} />
            <ToolBox />
        </div>
    );
};
