import React, {useRef} from 'react';
import {useDrag} from "react-dnd";
import {STICKY_NOTE} from "../../constants/WorkBoardItemTypes";

export default () => {
    const sizeRef = useRef();

    const [_, dragRef] = useDrag({
        item: {
            type: "WorkBoardTool",
            toolType: STICKY_NOTE,
            itemId: STICKY_NOTE,
            ref: sizeRef,
        },
    });
    const setRef = r => {
        sizeRef.current = r;
        dragRef(r);
    };

    return (
        <div className="tool-box--item">
            <div ref={setRef} className="tool-box--sticky-note" />
            <div className="tool-box--item-description">Note</div>
        </div>
    )
}
