import * as baseStyles from './button-bar-styles';
import {css} from "@emotion/core";
import {absoluteCenter} from "../../style-variables";

export const textColorButton = css(baseStyles.button, {
    border: "3px solid transparent",
});

export const buttonText = css(absoluteCenter, {
    fontWeight: 700,
    fontSize: "0.625em",
    lineHeight: "16px",
});

export const colorSelectorContainer = css({
    position: "absolute",
    bottom: "75%",
    right: "75%",
});
