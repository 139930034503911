import addCurriculumTask from "./addCurriculumTask";
import updateCurriculumTaskContent from "./updateCurriculumTaskContent";
import removeTaskFromCurriculum from "./removeTaskFromCurriculum";
import addItemToTask from "./addItemToTask";
import removeItemFromTask from "./removeItemFromTask";
import updateScrollingItem from "./updateScrollingItem";
import setTaskItemOrder from "./setTaskItemOrder";
import updateDropSlot from "./updateDropSlot";
import updateSection from "./updateSection";
import updateScrollerWorkBoard from "./updateScrollerWorkBoard";
import updateExplainerPrediction from "./updateExplainerPrediction";
import updateExplainer from "./updateExplainer";
import updateDogmaDash from "./updateDogmaDash";
import updateCentralDogmaWidget from "./updateCentralDogmaWidget";
import updateUtilityButton from "./updateUtilityButton";

export default [
    ...addCurriculumTask,
    ...removeTaskFromCurriculum,
    ...updateCurriculumTaskContent,
    ...addItemToTask,
    ...removeItemFromTask,
    ...updateScrollingItem,
    ...setTaskItemOrder,
    ...updateDropSlot,
    ...updateSection,
    ...updateScrollerWorkBoard,
    ...updateExplainer,
    ...updateExplainerPrediction,
    ...updateDogmaDash,
    ...updateCentralDogmaWidget,
    ...updateUtilityButton,
];
