import * as styles from './data-item-style';
import React from 'react';
import {useParams} from "@reach/router";
import {useDispatch, useSelector} from "react-redux";
import {getScrollingItemContent} from "../../../../../store/scrollingTaskItemPositions/selectors";
import {isItemEditing} from "../../../../../store/itemSelection/selector";
import {
    setScrollingItemContent,
    setScrollingItemEditing,
    setScrollingItemHeight,
    setScrollingItemWidth
} from "../../../../../store/scrollingTaskItemPositions/actions";
import combineStyles from "../../../../../Utility/combineStyles";
import Phenotype from "../../../../../Utility/Phenotype";
import {
    AXIAL_FLOWERS,
    CONSTRICTED_PODS,
    GREEN_PODS,
    GREEN_SEEDS,
    INFLATED_PODS,
    PURPLE_FLOWERS,
    ROUND_SEEDS,
    SHORT_PLANT,
    TALL_PLANT,
    TERMINAL_FLOWERS,
    WHITE_FLOWERS,
    WRINKLED_SEEDS,
    YELLOW_PODS,
    YELLOW_SEEDS
} from "../../../../../constants/PlantPhenotypes";

const PHENOTYPES = [
    TALL_PLANT,
    SHORT_PLANT,
    AXIAL_FLOWERS,
    TERMINAL_FLOWERS,
    PURPLE_FLOWERS,
    WHITE_FLOWERS,
    YELLOW_SEEDS,
    GREEN_SEEDS,
    ROUND_SEEDS,
    WRINKLED_SEEDS,
    GREEN_PODS,
    YELLOW_PODS,
    INFLATED_PODS,
    CONSTRICTED_PODS,
];

export default props => {
    const {id} = props;
    const {curriculumId} = useParams();
    const itemType = useSelector(getScrollingItemContent.bind(null, id));
    const editing = useSelector(isItemEditing.bind(null, id));
    const dispatch = useDispatch();

    const setType = type => e => {
        dispatch(setScrollingItemWidth(curriculumId, id, 0, 56));
        dispatch(setScrollingItemHeight(curriculumId, id, 0, 56));
        dispatch(setScrollingItemContent(curriculumId, id, type));
        dispatch(setScrollingItemEditing(curriculumId, id, false));
    };

    const showEditing = editing || !itemType;
    const capture = e => {
        if(showEditing) {
            e.stopPropagation();
        }
    };

    return (
        <div onWheel={capture} css={combineStyles(styles, {
            container: true,
            containerEditing: showEditing,
        })}>
            {showEditing && <div>
                {PHENOTYPES.map((key, i) =>
                    <button key={i} onClick={setType(key)} css={styles.typeSelector}>
                        <Phenotype phenotype={key} />
                    </button>
                )}
            </div>}
            {!showEditing && <Phenotype phenotype={itemType} />}
        </div>
    )
};
