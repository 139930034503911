import {call, takeEvery} from 'redux-saga/effects';
import {SET_EXPLAINER_FOR_PREDICTION} from "../../../store/curriculumTaskItems/explanationPredictions/actions";
import {sendGraphQLSaga} from "../../DataService";

const setExplainerForPrediction = `mutation SetExplainerForPrediction($input: SetExplainerForPredictionInput!) {
    setExplainerForPrediction(input: $input) {
        explainerPrediction {
            explainer {
                id
            }
        }
    }
}`;

function* executeSetExplainer(action) {
    const variables = {
        input: {
            curriculum: action.curriculumId,
            explainerPrediction: action.predictionId,
            explainer: action.explainerId,
        }
    };

    yield call(sendGraphQLSaga, setExplainerForPrediction, variables);
}

export default [takeEvery(SET_EXPLAINER_FOR_PREDICTION, executeSetExplainer)];
