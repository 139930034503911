import React from 'react';
import classNames from 'class-names';
import {useSelector} from "react-redux";
import {
    getActivitiesForTopic,
    getTopicSubtitle,
} from "../../store/curriculum/selectors";
import Activity from "./Activity";
import {getSelectedTopic} from "../../store/navigation/selectors";

const stateMap = props => state => ({
    subtitle: getTopicSubtitle(state, props.id),
    activities: getActivitiesForTopic(state, props.id),
    selected: getSelectedTopic(state) === props.id,
});

const comparison = (left, right) => {
    if(left.subtitle !== right.subtitle) { return false; }
    if(left.selected !== right.selected) { return false; }
    if((!left.activities && right.activities) || (left.activities && !right.activities)) { return false; }
    else if(left.activities.length !== right.activities.length) { return false; }
    else if(!left.activities.every((a, i) => a === right.activities[i])) { return false; }
    return true;
};

export default React.memo(props => {
    const { subtitle, activities, selected } = useSelector(stateMap(props), comparison);

    return (
        <div className={classNames({
            "curriculum-navigation--topic": true,
            "curriculum-navigation--topic__selected": selected,
        })}>
            <div className="curriculum-navigation--topic-subtitle">{subtitle}</div>
            <div className="topic--activity-container">
                {activities.map(id => <Activity id={id} key={id} />)}
            </div>
        </div>
    )
});
