import {getCurriculumTaskItemIds} from "../curriculumTask/selectors";
import {WORK_BOARD_DEFINITION} from "../../constants/TaskTypes";
import {getCurriculumTaskItemType} from "../curriculumTaskItems/selectors";
import {getCurriculumTaskIds} from "../curricula/selectors";

export const getCurriculumStructure = (curriculumId, state) => state.curriculumCases[curriculumId];
export const getNumberOfCurriculumCases = (curriculumId, state) => getCurriculumStructure(curriculumId, state)?.length ?? 0;
const getCurriculumCase = (curriculumId, caseIndex, state) => {
    const cases = state.curriculumCases[curriculumId];
    if(!cases) { return null; }
    return cases[caseIndex];
}
export const getCurriculumCaseDescription = (curriculumId, caseIndex, state) => {
    const curriculumCase = getCurriculumCase(curriculumId, caseIndex, state);
    return curriculumCase ? curriculumCase.description : "";
}
export const getCurriculumCaseTasks = (curriculumId, caseIndex, state) => {
    const curriculumCase = getCurriculumCase(curriculumId, caseIndex, state);
    if(!curriculumCase) { return []; }
    return curriculumCase.tasks;
}
export const getCaseIndexForTask = (curriculumId, taskId, state) => {
    const cases = getCurriculumStructure(curriculumId, state);
    for(let i = 0; i < cases.length; ++i) {
        if(cases[i].tasks.includes(taskId)) {
            return i;
        }
    }
    return -1;
}
export const getTasksInCaseWithWorkBoard = (curriculumId, caseIndex, state) => {
    const caseDefinition = getCurriculumCase(curriculumId, caseIndex, state);
    if(!caseDefinition) { return []; }
    return caseDefinition.tasks.filter(t => !!getCurriculumTaskItemIds(state, t).find(item => getCurriculumTaskItemType(state, item) === WORK_BOARD_DEFINITION));
};
export const getTasksInCaseWithoutWorkBoard = (curriculumId, caseIndex, state) => {
    const caseDefinition = getCurriculumCase(curriculumId, caseIndex, state);
    if(!caseDefinition) { return []; }
    return caseDefinition.tasks.filter(t => !getCurriculumTaskItemIds(state, t).find(item => getCurriculumTaskItemType(state, item) === WORK_BOARD_DEFINITION));
};
export const getCurriculumTaskIdsWithWorkBoards = (curriculumId, state) => {
    const structure = getCurriculumStructure(curriculumId, state);
    if(!structure) { return []; }
    return structure.reduce((agg, c) => [
        ...agg,
        ...c.tasks.filter(t =>
            !!getCurriculumTaskItemIds(state, t).find(item => getCurriculumTaskItemType(state, item) === WORK_BOARD_DEFINITION)
        ),
    ], []);
};
export const getCurriculumCaseIndexesWithWorkBoard = (curriculumId, state) => {
    const structure = getCurriculumStructure(curriculumId, state);
    return structure.map((c, i) => getTasksInCaseWithWorkBoard(curriculumId, i, state).length > 0 ? i : -1).filter(index => index >= 0);
};
export const getCurriculumCaseIndexForWorkBoardDefinition = (curriculumId, workBoardId, state) => {
    const structure = getCurriculumStructure(curriculumId, state);
    for(let i = 0; i < structure.length; ++i) {
        const tasks = getCurriculumCaseTasks(curriculumId, i, state);
        if(tasks.find(t => getCurriculumTaskItemIds(state, t).includes(workBoardId))) {
            return i;
        }
    }
    return null;
};
export const getWorkBoardDefinitionsInCurriculumCase = (curriculumId, caseIndex, state) => {
    return getCurriculumCaseTasks(curriculumId, caseIndex, state)
        .reduce((ids, task) => ids.concat(getCurriculumTaskItemIds(state, task).filter(i => getCurriculumTaskItemType(state, i) === WORK_BOARD_DEFINITION)), []);
}

export const getAllWorkBoardDefinitionsInCurriculum = (curriculumId, state) => {
    const structure = getCurriculumStructure(curriculumId, state);
    if(!structure) { return []; }
    const interactables = structure.flatMap(c => c.tasks.flatMap(t => getCurriculumTaskItemIds(state, t)));
    return interactables.filter(i => getCurriculumTaskItemType(state, i) === WORK_BOARD_DEFINITION);
};

export const getNextTaskInCurriculum = (state, curriculumId, taskId) => {
    const structure = getCurriculumStructure(curriculumId, state);
    if(!structure) {
        const tasks = getCurriculumTaskIds(state, curriculumId);
        return tasks[tasks.indexOf(taskId) + 1];
    }
    const caseIndex = getCaseIndexForTask(curriculumId, taskId, state);
    const caseTasks = getTasksInCaseWithoutWorkBoard(curriculumId, caseIndex, state);
    const taskIndex = caseTasks.indexOf(taskId);
    if(taskIndex < caseTasks.length - 1) {
        return caseTasks[taskIndex+1];
    }
    const cases = getNumberOfCurriculumCases(curriculumId, state);
    for(let c = caseIndex + 1; c < cases; ++c) {
        const nextCaseTasks = getTasksInCaseWithoutWorkBoard(curriculumId, c, state);
        if(nextCaseTasks && nextCaseTasks.length) {
            return nextCaseTasks[0];
        }
    }
    return null;
};
export const getPreviousTaskInCurriculum = (state, curriculumId, taskId) => {
    const structure = getCurriculumStructure(curriculumId, state);
    if(!structure) {
        const tasks = getCurriculumTaskIds(state, curriculumId);
        return tasks[tasks.indexOf(taskId) - 1];
    }
    const caseIndex = getCaseIndexForTask(curriculumId, taskId, state);
    const caseTasks = getTasksInCaseWithoutWorkBoard(curriculumId, caseIndex, state);
    const taskIndex = caseTasks.indexOf(taskId);
    if(taskIndex > 0) {
        return caseTasks[taskIndex-1];
    }
    for(let c = caseIndex - 1; c >= 0; --c) {
        const previousCaseTasks = getTasksInCaseWithoutWorkBoard(curriculumId, c, state);
        if(previousCaseTasks && previousCaseTasks.length) {
            return previousCaseTasks[previousCaseTasks.length - 1];
        }
    }
    return null;
};
