import {itemFragment} from "./workBoardItem";

// export const learnerFragment = `
// id
// givenName
// familyName
// email
// bounties {
//     bounty {
//         id
//         name
//         description
//         task
//     }
//     workBoards {
//         id
//         items {
//             x
//             y
//             angle
//             ${itemFragment}
//         }
//     }
//     progress
// }
// curricula {
//     curriculum {
//         id
//     }
//     topicAssignments {
//         topic {
//             id
//             title
//             subtitle
//         }
//         activityAssignments {
//             activity {
//                 id
//                 title
//                 subtitle
//                 skipVRBlock
//             }
//             taskAssignments {
//                 task {
//                     id
//                     type
//                     description
//                     additionalText
//                     bounty {
//                         id
//                     }
//                     hide
//                 }
//                 status
//                 url
//             }
//         }
//     }
// }
// cohort {
//     curricula {
//         id
//         name
//         type
//     }
// }
// `;

export const learnerFragment = `
id
givenName
familyName
email
cohort {
    curricula {
        id
        name
        type
    }
    learners {
        id
        givenName
        familyName
        email
    }
}
groups {
    curriculum {
        id
    }
    learners {
        id
    }
}
partnershipRequests {
    curriculum {
        id
    }
    requester {
        id
    }
    partner {
        id
    }
}
`;

export const getVerdantLearnerQuery = `query GetVerdantLearner($id: ID) {
    verdantLearner(id: $id) {
        ${learnerFragment}
    }
}`;
export const getVerdantLearnerVariables = (id) => ({
    id
});

export const setLearnerNameQuery = `mutation SetLearnerName($input: SetLearnerNameInput!) {
    setLearnerName(input: $input) {
        learner {
            id
        }
    }
}`;
export const setLearnerNameVariables = (learnerId, givenName, familyName) => ({
    input: {
        learner: learnerId,
        givenName,
        familyName,
    },
});

export const learnerProgressUpdatedSubscription = `
subscription onLearnerProgressUpdated($input: LearnerProgressUpdatedSubscriptionInput!) {
    learnerProgressUpdated(input: $input) {
        learner {
            id
            vrSessionStartTime
        }
        bounty
        task
        status
    }
}`;
export const learnerProgressUpdatedVariables = (learnerIds) => ({
    input: {
        learners: learnerIds
    }
});

export const setLongTextInputTextQuery = `mutation SetLongTextInputLearnerText($input: SetLongTextInputLearnerTextInput!) {
    setLongTextInputLearnerText(input: $input) {
        interaction {
            interactable {
                id
            }
        }
    }
}`;
export const setLongTextInputTextVariables = (learnerId, curriculumId, interactableId, text) => ({
    input: {
        learner: learnerId,
        curriculum: curriculumId,
        interactable: interactableId,
        text,
    },
});

export const setMultipleChoiceInputSelectionQuery = `mutation SetMultipleChoiceInputLearnerSelection($input: SetMultipleChoiceInputLearnerSelectionInput!) {
    setMultipleChoiceInputLearnerSelection(input: $input) {
        interaction {
            interactable {
                id
            }
        }
    }
}`;
export const setMultipleChoiceInputSelectionVariables = (learnerId, curriculumId, interactableId, selection) => ({
    input: {
        learner: learnerId,
        curriculum: curriculumId,
        interactable: interactableId,
        selection,
    },
});

export const setInputTableLearnerInputs = `mutation SetInputTableLearnerInputs($input: SetInputTableLearnerInputsInput!) {
    setInputTableLearnerInputs(input: $input) {
        interaction {
            interactable {
                id
            }
        }
    }
}`;
export const setInputTableLearnerInputsVariables = (learnerId, curriuclumId, inputTableId, inputs) => ({
    input: {
        learner: learnerId,
        curriculum: curriuclumId,
        inputTable: inputTableId,
        inputs,
    },
});
