import * as styles from './all-scrolling-content-style';
import React, {useEffect, useRef} from 'react';
import {useNavigate, useParams} from "@reach/router";
import {SCROLLING_TASK} from "../../constants/TaskTypes";
import {
    getCurriculumTaskHeight,
    getCurriculumTaskItemIds,
    getCurriculumTaskType
} from "../../store/curriculumTask/selectors";
import {shallowEqual, useSelector} from "react-redux";
import ScrollingItem from "../CurriculumTask/ScrollingItems";
import WorkBoardTray from "../CurriculumTask/WorkBoardTray";
import WorkBoard from "../CurriculumTask/ScrollingItems/WorkBoard";
import {useDrop} from "react-dnd";
import {WORK_BOARD_MODAL} from "../../constants/modalTypes";
import {css} from "@emotion/core";
import {idsForOpenModalType} from "../../store/navigation/selectors";
import {getCurriculumCaseTasks, getNumberOfCurriculumCases} from "../../store/curriculumCases/selectors";

const getScrollingTasks = curriculumId => state => Array.from(new Array(getNumberOfCurriculumCases(curriculumId, state)), (_, i) => i)
    .reduce((tasks, caseIndex) => [...tasks, ...getCurriculumCaseTasks(curriculumId, caseIndex, state)], [])
    .filter(id => getCurriculumTaskType(id, state) === SCROLLING_TASK);
const getTaskHeights = tasks => state => tasks.map(t => getCurriculumTaskHeight(t, state));
const getTaskItems = tasks => state => tasks.map(t => getCurriculumTaskItemIds(state, t));

const AllScrollingContent = () => {
    return (
        <div css={styles.outerContainer}>
            <ScrollingItems />
            <WorkBoardTray />
            <OpenWorkBoards />
        </div>
    )
}

const ScrollingItems = React.memo(() => {
    const {taskId, curriculumId} = useParams();
    const taskIds = useSelector(getScrollingTasks(curriculumId), shallowEqual);
    const taskHeights = useSelector(getTaskHeights(taskIds), () => true);
    const taskItems = useSelector(getTaskItems(taskIds), () => true);
    const scroller = useRef();
    const scrollAnimationRef = useRef();
    const localTaskId = useRef(taskId);
    const navigate = useNavigate();

    const [_, dropRef] = useDrop({
        accept: [WORK_BOARD_MODAL],
        drop: (_, monitor) => {
            const {x: deltaX, y: deltaY} = monitor.getDifferenceFromInitialOffset();
            return {deltaX, deltaY};
        },
    });

    useEffect(() => {
        const navigateCheck = () => {
            const targetTask = taskIds.find((t, i) => scroller.current.scrollTop >= taskStartY[i] && (i === taskIds.length - 1 || scroller.current.scrollTop < taskStartY[i+1]));
            if(taskId !== targetTask) {
                localTaskId.current = targetTask;
                navigate(`./${targetTask}`, {replace: true});
            } else {
                scrollAnimationRef.current = requestAnimationFrame(navigateCheck);
            }
        };

        if(!taskId || taskId === localTaskId.current) {
            navigateCheck();
        } else {
            const targetIndex = taskIds.indexOf(taskId);
            const target = taskStartY[targetIndex];
            const startScroll = scroller.current.scrollTop;
            if (startScroll === target) {
                return;
            }
            const direction = Math.sign(target - startScroll);
            let lastTime = performance.now();
            const scrollTo = time => {
                const elapsed = time - lastTime;
                lastTime = time;
                const currentScroll = scroller.current.scrollTop;
                const delta = Math.min(Math.floor(elapsed * 4), Math.abs(target - currentScroll));
                scroller.current.scrollTop = currentScroll + delta * direction;
                if (scroller.current.scrollTop !== target) {
                    scrollAnimationRef.current = requestAnimationFrame(scrollTo);
                } else {
                    localTaskId.current = taskId;
                    navigateCheck();
                }
            };
            scrollTo(lastTime);
        }

        return () => scrollAnimationRef.current && cancelAnimationFrame(scrollAnimationRef.current);
    }, [taskId]);

    const taskStartY = taskHeights.reduce((agg, h, i) => {
        return [...agg, agg[i]+h]
    }, [0]);
    const contentContainerStyle = css(styles.contentContainer, { height: taskStartY[taskStartY.length-1] });

    return (
        <div ref={scroller} css={styles.container}>
            <div ref={dropRef} css={contentContainerStyle}>
                {taskItems.reduce((agg, taskItems, taskIndex) => agg.concat(
                    taskItems.map(id => <ScrollingItem key={id} id={id} scroller={scroller} yOffset={taskStartY[taskIndex]} />)
                ), [])}
            </div>
        </div>
    )
});
ScrollingItems.displayName = "ScrollingItems";

const OpenWorkBoards = () => {
    const openTaskWorkBoards = useSelector(state => idsForOpenModalType(WORK_BOARD_MODAL, state), shallowEqual);

    return (
        <div css={styles.openWorkBoardContainer}>
            {openTaskWorkBoards.map(id => <WorkBoard taskId={id} key={id} /> )}
        </div>
    );
};

export default AllScrollingContent;
