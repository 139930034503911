import {DOGMA_DASH_ALERT_LIST, DOGMA_DASH_MRNA_INVENTORY, DOGMA_DASH_ORGANELLE_DEBRIS} from "./dogma-strand-1";
import {
    getDogmaDashAvailableProteins, getDogmaDashCurrentView,
    getDogmaDashLevelUnlockingProtein, getDogmaGolgiBodyIds,
    getDogmaMitochondriaIds, getDogmaRibosomeIds,
    getOrganelleTimerStart, getOrganelleTimerType
} from "../../interactions/dogmaDash/selectors";
import {
    Antibody2,
    CELL_VIEW,
    EnergyProteinRotor,
    EnergyProteinStabilizer,
    ProteinForm_ProteinInVesicle
} from "../../../constants/DogmaDash";
import {mrnaInSlot, proteinFormAtOrganelle, proteinTypeAtOrganelle} from "./utility";
import {MRNA_INVENTORY_PROTEIN_TUTORIAL} from "./dogma-strand-antibody-unlock";

export default {
    id: "b8151825-0075-42a6-82a7-3c957a5647b1",
    items: [
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== DOGMA_DASH_ALERT_LIST) { return false; }
                const unlocking = getDogmaDashLevelUnlockingProtein(state, dogmaDashId);
                const available = getDogmaDashAvailableProteins(state, dogmaDashId);
                const mitoAlert = getDogmaMitochondriaIds(state, dogmaDashId).some(m => getOrganelleTimerStart(state, m) && getOrganelleTimerType(state, m) === "Alert");
                return mitoAlert && !unlocking && available.length === 7 && available.indexOf(Antibody2) < 0;
            },
            header: "New Alert Type",
            text: "The <b>mitochondria</b> is not producing enough energy to keep the cell running. The protein needed requires two sub-parts, you’ll need to transcribe and translate both.",
            forceCompletion: (state, {dogmaDashId}) => mrnaInSlot(state, dogmaDashId, EnergyProteinRotor) || mrnaInSlot(state, dogmaDashId, EnergyProteinStabilizer),
        },
        {
            condition: (state, {location, protein}) => location === MRNA_INVENTORY_PROTEIN_TUTORIAL && protein === EnergyProteinRotor,
            header: "Energy 1 and 2",
            text: "Every time your cell needs to produce more energy, you’ll need to <b>send BOTH energy mRNA codes</b> to the pilot",
            width: 460,
        },
        {
            condition: (state, {location, dogmaDashId, organelleId}) => {
                if(location === DOGMA_DASH_ORGANELLE_DEBRIS) {return false;}
                const golgi = getDogmaGolgiBodyIds(state, dogmaDashId);
                if(golgi.indexOf(organelleId) < 0) {return false;}
                return golgi.some(g => (proteinTypeAtOrganelle(state, g, EnergyProteinRotor) || proteinTypeAtOrganelle(state, g, EnergyProteinStabilizer) && proteinFormAtOrganelle(state, g, ProteinForm_ProteinInVesicle)));
            },
            header: "Final Energy Protein",
            text: "At the golgi body, the two energy sub-parts <b>get folded into one</b> larger energy protein.",
            forceCompletion: (state, {dogmaDashId}) => !getDogmaMitochondriaIds(state, dogmaDashId).some(m => getOrganelleTimerStart(state, m) && getOrganelleTimerType(state, m) === "Alert"),
        },
    ],
};
