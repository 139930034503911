import "./task.scss"
import React from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getCurriculumTaskTitle, getCurriculumTaskType} from "../../../store/curriculumTask/selectors";
import {setCurriculumTaskTitle} from "../../../store/curriculumTask/actions";
import InlineEditor from "../../../Utility/InlineEditor";
import {useParams} from "@reach/router";
import {GLOBAL_TASK, SCROLLING_TASK, SECTIONED_TASK} from "../../../constants/TaskTypes";
import Sectioned from "./Types/Sectioned";
import Scrolling from "./Types/Scrolling";
import Global from "./Types/Global";
import HTML5Backend from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";

const getTaskTitle = id => state => getCurriculumTaskTitle(state, id);

const TaskTagMap = {
    [SECTIONED_TASK]: Sectioned,
    [SCROLLING_TASK]: Scrolling,
    [GLOBAL_TASK]: Global
};

export default () => {
    const {taskId: id, curriculumId} = useParams();
    const title = useSelector(getTaskTitle(id), shallowEqual);
    const taskType = useSelector(getCurriculumTaskType.bind(null, id), shallowEqual);
    const dispatch = useDispatch();

    const setTitle = title => dispatch(setCurriculumTaskTitle(curriculumId, id, title));

    const TaskTag = TaskTagMap[taskType];
    return (
        <div className="admin-task--container">
            {taskType !== GLOBAL_TASK &&
            <div className="admin-task--title-container">
                <InlineEditor name={title || "Unnamed Task"} setName={setTitle}/>
            </div>}
            <div className="admin-task--task-type-container">
                {/*<DndProvider backend={HTML5Backend}>*/}
                    <TaskTag />
                {/*</DndProvider>*/}
            </div>
        </div>
    )
};
