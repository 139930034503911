import {css} from "@emotion/core";
import * as transcriptionStyles from './transcription-styles';
import {
    absoluteCenter,
    bbPadding,
    FONT_BOLD_WEIGHT,
    inlineBlockBottom,
    inlineBlockTop,
    modalBlocker
} from "../../../../../style-variables";
import {adenineColor, cytosineColor, guanineColor, uracilColor} from "./nucleotide-styles";
import {backgroundColor, dogmashBlue, dogmashPurple} from "../dogma-dash-styles";

export const container = transcriptionStyles.container;

export const pageSection = transcriptionStyles.pageSection;
export const pageHeaderSection = transcriptionStyles.pageHeaderSection;

export const backButton = transcriptionStyles.backButton;

export const header = transcriptionStyles.header;

export const headerPurple = css({
    color: dogmashPurple,
});

export const neutralTextTransform = css({
    textTransform: "none",
});
export const bolded = css({
    fontWeight: 700,
});

export const returnButton = transcriptionStyles.translationButton;
export const buttonTutorial = css(transcriptionStyles.buttonTutorial, {right: "140px"});

export const headerTutorial = css({
    width: 0,
    height: 0,
    position: 'absolute',
    left: "5px",
    top: "-5px",
    zIndex: 20,
});

const staticSectionBlock = css(transcriptionStyles.staticSectionBlock, {
    borderColor: dogmashPurple,

    "&:after": {
        background: `linear-gradient(45deg, ${backgroundColor} 48%, ${dogmashPurple} 48%, ${dogmashPurple} 54%, transparent 54%)`,
    },
});

export const staticBlockHeader = css(transcriptionStyles.staticBlockHeader, {
    background: dogmashPurple,

    "& > svg": {
        fill: dogmashPurple,
        stroke: dogmashPurple,
    },

    "&:after": {
        borderTopColor: dogmashPurple,
    },
});

export const translationBlock = css(staticSectionBlock, {
    flex: "1 0 auto",
    margin: "0 12px 0 0",
});

export const strandContent = transcriptionStyles.strandContent;

export const translationNucleotideBlock = css(transcriptionStyles.transcriptionNucleotideBlock, {
    marginRight: "40px",
    color: dogmashPurple,

    "&:nth-of-type(3n+1)": {
        marginRight: "40px",
    },
});
export const trnaCodonContainer = css({
    position: "absolute",
    top: "-29px",
    left: 0,
});
export const mrnaCodonContainer = css({

});

export const strandTutorialPosition = transcriptionStyles.strandTutorialPosition;

export const proteinImageBlock = css(staticSectionBlock, {
    display: "flex",
    flexDirection: "column",
    width: "274px",
    height: "234px",
    boxSizing: "border-box",
});
export const proteinImageContainer = transcriptionStyles.proteinImageContainer;

export const decoderSectionContainer =  css(staticSectionBlock, {
    flex: '0 0 auto',
    display: "flex",
    flexDirection: "column",
});
export const decoderContent = transcriptionStyles.decoderContent;
export const decoderPair = transcriptionStyles.decoderPair
export const strandHeader = transcriptionStyles.strandHeader;

export const interactableBlockHeader = transcriptionStyles.interactableBlockHeader;

export const inventoryBlock = transcriptionStyles.inventoryBlock;
export const disabledInventory = transcriptionStyles.disabledInventory;
export const inventorySectionContainer = transcriptionStyles.inventorySectionContainer;
export const inventoryTopContainer = css({
    width: "100%",
    textAlign: 'center',
    position: "relative",
    marginBottom: "12px",
});
export const trnaBubble = css(bbPadding("6px 8px"), inlineBlockTop, {
    width: "138px",
    height: "138px",
    borderRadius: "50%",
    background: "radial-gradient(50% 50% at 50% 50%, rgba(202, 202, 202, 0) 0%, rgba(202, 202, 202, 0.57) 100%)",
    position: "relative",
    transition: "background 0.3s",
});
export const trnaBubbleFull = css(trnaBubble, {
    background: "radial-gradient(50% 50% at 50% 50%, rgba(0, 255, 240, 0) 0%, rgba(0, 255, 240, 0.57) 100%)",
});

export const trnaContainer = css({
    position: "relative",
    textAlign: "center",
});
export const aminoAcidPlaceholder = css(inlineBlockTop, bbPadding("8px 0 0 0"), {
    width: "63px",
    height: "63px",
    borderRadius: "50%",
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: FONT_BOLD_WEIGHT,
    fontSize: '0.9375rem',
    textAlign: "center",
    textTransform: "uppercase",
    color: "#000",
});
// export const aminoAcid = color => css(aminoAcidPlaceholder, {
//     backgroundColor: color || "#C076FA",
// });
export const stopCodon = css(aminoAcidPlaceholder, {
    color: "#fff",
});
export const trnaLetterContainer = css(bbPadding("0 4px"), {
    width: "123px",
    height: "34px",
    borderRadius: "23px",
    backgroundColor: "#66507C",
    position: 'absolute',
    top: "30px",
    left: "50%",
    transform: "translateX(-50%)",
});
export const trnaLetter = css(inlineBlockTop, {
    width: "33%",
    fontFamily: "Rajdhani, sans-serif",
    fontSize: "1.5rem",
    fontWeight: FONT_BOLD_WEIGHT,
    textAlign: "center",
    lineHeight: "1.25em",
});
export const trnaAdenineLetter = css(trnaLetter, {
    color: adenineColor,
});
export const trnaGuanineLetter = css(trnaLetter, {
    color: guanineColor,
});
export const trnaCytosineLetter = css(trnaLetter, {
    color: cytosineColor,
});
export const trnaUracilLetter = css(trnaLetter, {
    color: uracilColor,
});

export const trnaNucleotideContainer = css(inlineBlockTop, {
    marginRight: "10px",

    "&:first-of-type": {
        marginLeft: "2px",
    },

    "&:last-of-type": {
        marginRight: "2px",
    },
});
export const mrnaNucleotideContainer = css(inlineBlockBottom, {
    marginRight: "10px",

    "&:first-of-type": {
        marginLeft: "2px",
    },

    "&:last-of-type": {
        marginRight: "2px",
    },
});
export const nucleotideContainer = css({
    width: "28px",
});
export const adenineContainer = css(nucleotideContainer, {
    height: "65px",
})
export const guanineContainer = css(nucleotideContainer, {
    height: "65px",
});
export const uracilContainer = css(nucleotideContainer, {
    height: "33px",
});
export const cytosineContainer = css(nucleotideContainer, {
    height: "33px",
});
export const trnaIncorrect = css({
    transform: "translateY(-8px) rotate(-8deg)",
});

export const inventoryButtonContainer = css(bbPadding("9px 18px 11px 18px"), {
    border: "2px solid #505050",
    borderRadius: "10px",
});
const inventoryButton = css(inlineBlockTop, {
    width: "37px",
    height: "38px",
    margin: "0 17px 0 0",
    backgroundColor: "#3F3F3F",
    border: "2px solid transparent",
    borderRadius: "5px",
    fontFamily: "Rajdhani, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 700,
    textTransform: "uppercase",
    boxShadow: "0 4px 4px 0 #000",
    transition: "transform 0.2s, box-shadow 0.2s",
    cursor: "pointer",

    "&:not(:disabled):active": {
        transform: "translateY(4px)",
        boxShadow: "0 0px 0px 0 #000",
    },

    "&:disabled": {
        boxShadow: "0 0px 0px 0 #000",
        transform: "translateY(4px)",
        cursor: "default",
    },
});
export const adenineInventoryButton = css(inventoryButton, {
    color: adenineColor,
    borderColor: adenineColor,
});
export const uracilInventoryButton = css(inventoryButton, {
    color: uracilColor,
    borderColor: uracilColor,
});
export const cytosineInventoryButton = css(inventoryButton, {
    color: cytosineColor,
    borderColor: cytosineColor,
});
export const guanineInventoryButton = css(inventoryButton, {
    color: guanineColor,
    borderColor: guanineColor,
});
export const backspaceButton = css(inventoryButton,{
    borderColor: "#fff",
    color: "#fff",
    fontSize: "0.5625rem",
});

export const portalBlock = transcriptionStyles.portalBlock;
export const vrPortalSection = transcriptionStyles.vrPortalSection;
export const portal = css(transcriptionStyles.portal, bbPadding("8px 11px"));

export const clear = transcriptionStyles.clear;


export const firstTimeModalBlocker = transcriptionStyles.firstTimeModalBlocker;

export const firstTimeContainer = transcriptionStyles.firstTimeContainer;

export const firstTimeHeader = transcriptionStyles.firstTimeHeader;

export const firstTimeImageContainer = transcriptionStyles.firstTimeImageContainer;

export const firstTimeText = transcriptionStyles.firstTimeText;

export const firstTimeButton = transcriptionStyles.firstTimeButton;
