import * as styles from './mrna-activity-styles';
import React, {memo} from 'react';
import {useDragLayer, useDrop} from "react-dnd";
import {useDispatch, useSelector} from "react-redux";
import {getAminoAcidInSlot} from "../../../store/interactions/mrnaActivity/selectors";
import {objectTypeToStyle} from "./Inventory";
import {setAminoAcidInMrnaSlot} from "../../../store/interactions/mrnaActivity/actions";
import {useParams} from "@reach/router";
import {css} from "@emotion/core";

const codonX = [61,302,520,748,1019];
const codonY = [-44,5,91,165,161];
const codonRotation = [0,19,19,11,-14];
const codons = [["u","u","c"],["a","g","c"],["u","c","u"],["g","g","a"],["u","u","c"]];
const allowedAminoAcids = ["BlueAminoAcid", "GreenAminoAcid", "PinkAminoAcid", "OrangeAminoAcid", "BlueAminoAcid"]; // TODO: calculate this
const baseMap = {
    "u": styles.baseU,
    "a": styles.baseA,
    "c": styles.baseC,
    "g": styles.baseG,
};

const LearnerMrnaActivity = ({id}) => {
    return (
        <div css={styles.container}>
            <AminoDragLayer />
            <div css={styles.mrna}>
                {codonX.map((c, i) => (
                    <div key={`codon-${i}`} css={css(styles.codon, {left: c, top: codonY[i], transform: `rotate(${codonRotation[i]}deg)`})}>
                        <div css={baseMap[codons[i][0]]} />
                        <div css={baseMap[codons[i][1]]} />
                        <div css={baseMap[codons[i][2]]} />
                        <div css={styles.dropArea}>
                            <AminoDropArea index={i} activityId={id} allowedAminoAcid={allowedAminoAcids[i]} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
};

const aminoToTrnaMap = {
    "BlueAminoAcid" : styles.trnaBlue,
    "GreenAminoAcid": styles.trnaGreen,
    "PinkAminoAcid": styles.trnaPink,
    "OrangeAminoAcid": styles.trnaOrange,
};
const aminoToCodonMap = {
    "BlueAminoAcid" : ['a', 'a', 'g'],
    "GreenAminoAcid": ['u', 'c', 'g'],
    "PinkAminoAcid": ['a', 'g', 'a'],
    "OrangeAminoAcid": ['c', 'c', 'u'],
};

const AminoDropArea = ({index, activityId, allowedAminoAcid}) => {
    const currentAmino = useSelector(state => getAminoAcidInSlot(state, activityId, index));
    const dispatch = useDispatch();
    const {curriculumId} = useParams();

    const [_, dropRef] = useDrop({
        accept: ["BlueAminoAcid", "GreenAminoAcid", "PinkAminoAcid", "OrangeAminoAcid"],
        drop: (item, monitor) => {
            if(item.type !== allowedAminoAcid) { return; }
            dispatch(setAminoAcidInMrnaSlot(curriculumId, activityId, index, item.type));
        }
    }, [index, activityId]);

    return (
        <div css={styles.aminoDropArea} ref={dropRef}>
            {currentAmino &&
            <div css={aminoToTrnaMap[currentAmino]}>
                <div css={styles.trnaCodon}>
                    <div css={css(styles.trnaBase, baseMap[aminoToCodonMap[currentAmino][0]])}/>
                    <div css={css(styles.trnaBase, baseMap[aminoToCodonMap[currentAmino][1]])}/>
                    <div css={css(styles.trnaBase, baseMap[aminoToCodonMap[currentAmino][2]])}/>
                </div>
                <div css={css(styles.trnaAmino, objectTypeToStyle[currentAmino])} />
            </div>
            }
        </div>
    )
};

const AminoDragLayer = () => {
    const {aminoType, isDragging, currentOffset} = useDragLayer(monitor => ({
        aminoType: monitor.getItemType(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));
    if(!isDragging || !currentOffset) {return null;}
    const {x,y} = currentOffset;
    const style = {
        transform: `translate(${x}px, ${y}px)`,
    }

    return (
        <div css={styles.customDragLayerContainer}>
            <div style={style}>
                <DraggingAmino aminoType={aminoType} />
            </div>
        </div>
    )
};

const DraggingAmino = memo(({aminoType}) => {
    return (
        <div css={aminoToTrnaMap[aminoType]}>
            <div css={styles.trnaCodon}>
                <div css={css(styles.trnaBase, baseMap[aminoToCodonMap[aminoType][0]])}/>
                <div css={css(styles.trnaBase, baseMap[aminoToCodonMap[aminoType][1]])}/>
                <div css={css(styles.trnaBase, baseMap[aminoToCodonMap[aminoType][2]])}/>
            </div>
            <div css={css(styles.trnaAmino, objectTypeToStyle[aminoType])} />
        </div>
    )
});

export default LearnerMrnaActivity;
