import {css} from "@emotion/core";

export const container = css({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
});

export const adminNavigation = css({
    width: "100%",
    position: 'fixed',
    top: 0,
    left: 0,
    padding: "0 4px",
    backgroundColor: "#333",
    fontSize: "0.75em",
    zIndex: 2,
});

export const backLink = css({
    color: "white",
})

export const preview = css({
    flex: "1 1 auto",
    overflow: "hidden",
    position: "relative",
});
