export const ADD_ITEM_TO_WORK_BOARD_TEMPLATE = "Add item to work board template";
export const addItemToWorkBoardTemplate = (curriculumId, workBoardDefinitionId, itemId, itemType, x, y) => ({
    type: ADD_ITEM_TO_WORK_BOARD_TEMPLATE,
    curriculumId,
    workBoardDefinitionId,
    itemId,
    itemType,
    x,
    y,
});

export const MOVE_WORK_BOARD_TEMPLATE_ITEM = "Move work board template item";
export const moveWorkBoardTemplateItem = (curriculumId, workBoardDefinitionId, itemId, newIndex) => ({
    type: MOVE_WORK_BOARD_TEMPLATE_ITEM,
    curriculumId,
    workBoardDefinitionId,
    itemId,
    newIndex,
});

export const REMOVE_ITEM_FROM_WORK_BOARD_TEMPLATE = "Remove item from work board template";
export const removeItemFromWorkBoardTemplate  = (curriculumId, workBoardDefinitionId, itemId) => ({
    type: REMOVE_ITEM_FROM_WORK_BOARD_TEMPLATE,
    curriculumId,
    workBoardDefinitionId,
    itemId,
});
