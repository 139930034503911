import {css} from "@emotion/core";
import {dogmashBlue, dogmashRed} from "./dogma-dash-styles";
import {absoluteCenter} from "../../../../style-variables";

export const container = css({
    flex: "0 0 auto",
    marginTop: "23px",
    zIndex: 11,
    position: 'relative',
});

export const tutorial = css({
    position: "absolute",
    left: "25%",
    top: "-4px",
});

export const meter = css({
    width: "360px",
    height: "14px",
    border: `1px solid ${dogmashBlue}`,
    textAlign: 'center',
    position: 'relative',
});

export const bar = css({
    height: '100%',
    backgroundColor: dogmashRed,
    position: 'absolute',
    left: 0,
    top: 0,
    transition: "width 0.2s",
});

export const text = css(absoluteCenter, {
    color: "#fff",
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 700,
    fontSize: "0.9375rem",
    textTransform: 'uppercase',
});

export const marker = css({
    width: "1px",
    height: "34px",
    background: dogmashRed,
    position: 'absolute',
    left: '50%',
    top: "50%",
    transform: "translateY(-50%)",
});

export const alertIcon = css({
    width: "38px",
    height: '38px',
    position: 'absolute',
    left: '0',
    bottom: '100%',
    transform: 'translateX(-50%)',

    "& > svg": {
        width: "100%",
        height: "100%",
        fill: dogmashRed,
        stroke: dogmashRed,
    },
});
