import {css} from "@emotion/core";
import trashIcon from '../../assets/trash.svg';
import {inlineBlockTop} from "../../style-variables";

export const workBoardItem = css(inlineBlockTop, {
    zIndex: 1,
    left: 0,
    top: 0,
    boxShadow: "0 0 2px 1px #555",
    margin: "0 8px 8px 0",
});

export const remove = css({
    position: "absolute",
    right: 3,
    top: 3,
    background: `url(${trashIcon})`,
    backgroundSize: "100% 100%",
    width: 18,
    height: 18,
    border: "none",
    padding: 0,
    cursor: "pointer",
    zIndex: 2,
});
