import gql from "graphql-tag";
import {headsetAuthorizationRevoked} from "../../../store/identity/actions";
import {END, eventChannel} from "redux-saga";

const subscriptionQuery = `subscription HeadsetAuthorizationEvent($input: HeadsetAuthorizationEventInput!) {
    headsetAuthorizationEvent(input: $input) {
        userId
    }
}`;

export default (subscriptionClient, pairingKey, learnerId) =>
    eventChannel(emitter => {
        const subscription = subscriptionClient.subscribe({
            query: gql`${subscriptionQuery}`,
            variables: {
                input: {
                    pairingKey,
                },
            },
        }).subscribe({
            next({data}) {
                const authorizedUserId = data.headsetAuthorizationEvent.userId;
                if(!authorizedUserId || authorizedUserId !== learnerId) {
                    emitter(headsetAuthorizationRevoked());
                    emitter(END);
                }
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    });
