import {ADD_ITEM_TO_WORK_BOARD_TEMPLATE} from "../workBoardTemplate/actions";
import {WORK_BOARD_CROSS_CARD, WORK_BOARD_DEFINITION} from "../../constants/TaskTypes";
import {SET_CROSS_CARD_NUMBER_OF_OFFSPRING} from "./actions";
import {CURRICULA_LOADED} from "../curricula/actions";

const defaultCrossCard = {
    numberOfOffspring: 2,
};

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const newItems = action.curricula.reduce((agg, c) => ({
            ...agg,
            ...(c.tasks || []).reduce((taskAgg, t) =>
                ({
                    ...taskAgg,
                    ...t.items.filter(i => i.__typename === WORK_BOARD_DEFINITION).reduce((wbAgg, i) => ({
                        ...wbAgg,
                        ...i.templateItems.filter(t => t.__typename === WORK_BOARD_CROSS_CARD).reduce((tAgg, t) => ({
                            ...tAgg,
                            [t.id]: {
                                ...defaultCrossCard,
                                numberOfOffspring: t.numberOfOffspring,
                            },
                        }), {}),
                    }), {}),
                }), {}),
        }), {});
        return {
            ...state,
            ...newItems,
        };
    }
    if(action.type === ADD_ITEM_TO_WORK_BOARD_TEMPLATE && action.itemType === WORK_BOARD_CROSS_CARD) {
        return {
            ...state,
            [action.itemId]: {
                ...defaultCrossCard
            },
        };
    }
    if(action.type === SET_CROSS_CARD_NUMBER_OF_OFFSPRING) {
        return {
            ...state,
            [action.crossCardId]: {
                ...state[action.crossCardId],
                numberOfOffspring: action.numberOfOffspring,
            },
        };
    }
    return state;
}
