import React from 'react';
import * as styles from './global-styles';
import {useParams} from "@reach/router";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getCurriculumTaskItemIds} from "../../../../store/curriculumTask/selectors";
import {addInteractableToTask} from "../../../../store/curriculumTaskItems/actions";
import uuid from "uuid/v4";
import Interactable from "../Interactables";
import {DOGMA_DASH, INVENTORY_TASK, OBJECT_SCANNING_TASK} from "../../../../constants/TaskTypes";

const GlobalTask = () => {
    const {curriculumId, taskId: id} = useParams();
    const interactables = useSelector(state => getCurriculumTaskItemIds(state, id), shallowEqual);
    const dispatch = useDispatch();
    const [showAddInteractableMenu, setShowAddInteractableMenu] = useState(false);

    useEffect(() => {
        setShowAddInteractableMenu(false);
    }, [id]);

    const toggleAddInteractableMenu = () => setShowAddInteractableMenu(!showAddInteractableMenu);
    const addInteractable = interactableType => {
        dispatch(addInteractableToTask(curriculumId, id, uuid(), interactableType));
        setShowAddInteractableMenu(false);
    };

    return (
        <>
            <h3 css={styles.title}>Global Task</h3>
            <div css={styles.interactablesContainer}>
                {interactables.map(interactableId => <Interactable key={interactableId} id={interactableId} curriculum={curriculumId} task={id} />)}
                <div css={showAddInteractableMenu ? styles.interactableCreationContainerVisisble : styles.interactableCreationContainer}>
                    <button onClick={setShowAddInteractableMenu.bind(null, false)} css={styles.close} />
                    <div css={styles.interactableCreationDescription}>Choose a student interaction to add to this task</div>
                    <button onClick={addInteractable.bind(null, OBJECT_SCANNING_TASK)} css={styles.newInteractable}>
                        <span>Object Scanning</span>
                    </button>
                    <button onClick={addInteractable.bind(null, INVENTORY_TASK)} css={styles.newInteractable}>
                        <span>Inventory</span>
                    </button>
                    <button onClick={addInteractable.bind(null, DOGMA_DASH)} css={styles.newInteractable}>
                        <span>Dogma Dash</span>
                    </button>
                </div>
                <button onClick={toggleAddInteractableMenu} css={styles.expandButton}>Add Interactable</button>
            </div>
        </>
    )
}

export default GlobalTask;
