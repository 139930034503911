import {
    ADD_ITEM_TO_SCROLLING_TASK,
    MOVE_SCROLLING_TASK_ITEM,
    SET_SCROLLING_ITEM_CONTENT, SET_SCROLLING_ITEM_DRAGGABLE, SET_SCROLLING_ITEM_FIXED, SET_SCROLLING_ITEM_HEIGHT,
    SET_SCROLLING_ITEM_LOCKED, SET_SCROLLING_ITEM_WIDTH,
    SET_SCROLLING_TASK_ITEM_SCROLL_POSITION
} from "./actions";
import {
    SCROLLER_EXPLAINER, SCROLLER_SECTION, SCROLLER_WORK_BOARD,
    SCROLLING_DATA_ITEM,
    SCROLLING_DROP_SLOT_ITEM,
    SCROLLING_IMAGE_ITEM,
    SCROLLING_SHAPE_ITEM,
    SCROLLING_TEXT_ITEM
} from "../../constants/TaskTypes";
import {CURRICULA_LOADED} from "../curricula/actions";
import {ADD_SPACE_TO_SCROLLER_TASK} from "../curriculumTask/actions";

const scrollingItemTypes = [SCROLLING_TEXT_ITEM, SCROLLING_IMAGE_ITEM, SCROLLING_SHAPE_ITEM, SCROLLING_DROP_SLOT_ITEM, SCROLLING_DATA_ITEM, SCROLLER_EXPLAINER, SCROLLER_SECTION, SCROLLER_WORK_BOARD];

const defaultItem = {
    id: "",
    positions: [],
    locked: true,
    content: "",
    draggable: false,
    fixed: false,
};

const defaultPosition = {
    x: 0,
    y: 0,
    width: null,
    height: null,
    scroll: null,
};

const updatePositions = (item, breakpoint, update) => {
    return {
        ...item,
        positions: item.positions.map((p, i) => i === breakpoint
            ? {
                ...p,
                ...update,
            }
            : p),
    };
};

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const newItems = action.curricula.reduce((agg, c) => ({
            ...agg,
            ...(c.tasks || []).reduce((taskAgg, t) =>
                ({
                    ...taskAgg,
                    ...t.items.filter(i => scrollingItemTypes.includes(i.__typename)).reduce((itemsAgg, i) => ({
                        ...itemsAgg,
                        [i.id]: {
                            ...defaultItem,
                            id: i.id,
                            positions: i.positions.map(p => ({ ...defaultPosition, ...p })),
                            locked: i.locked,
                            draggable: i.draggable,
                            fixed: i.fixed,
                            content: i.content || i.url || i.shape || i.dataType, // oh god its getting out of hand. (see dropSlots reducer)
                        },
                    }), {}),
                }), {}),
        }), {});
        return {
            ...state,
            ...newItems,
        };
    }
    if(action.type === ADD_ITEM_TO_SCROLLING_TASK) {
        return {
            ...state,
            [action.itemId]: {
                ...defaultItem,
                id: action.itemId,
                positions: [{
                    ...defaultPosition,
                    x: action.x,
                    y: action.y,
                }, {
                    ...defaultPosition,
                    x: action.x,
                    y: action.y,
                }],
            },
        };
    }
    if(action.type === MOVE_SCROLLING_TASK_ITEM) {
        if(state[action.itemId].positions[0].scroll === null) {
            return {
                ...state,
                [action.itemId]: {
                    ...state[action.itemId],
                    positions: state[action.itemId].positions.map(p => ({
                        ...p,
                        x: action.x,
                        y: action.y,
                    })),
                },
            };
        }
        return {
            ...state,
            [action.itemId]: updatePositions(state[action.itemId], action.breakpointIndex, {
                x: action.x,
                y: action.y,
            }),
        };
    }
    if(action.type === SET_SCROLLING_ITEM_LOCKED) {
        return {
            ...state,
            [action.itemId]: {
                ...state[action.itemId],
                locked: action.locked,
            },
        };
    }
    if(action.type === SET_SCROLLING_TASK_ITEM_SCROLL_POSITION) {
        return {
            ...state,
            [action.itemId]: updatePositions(state[action.itemId], action.breakpointIndex, {
                scroll: action.scrollPosition,
            }),
        };
    }
    if(action.type === SET_SCROLLING_ITEM_CONTENT) {
        return {
            ...state,
            [action.itemId]: {
                ...state[action.itemId],
                content: action.content,
            },
        };
    }
    if(action.type === SET_SCROLLING_ITEM_WIDTH) {
        return {
            ...state,
            [action.itemId]: updatePositions(state[action.itemId], action.breakpointIndex, {
                width: action.width,
            }),
        };
    }
    if(action.type === SET_SCROLLING_ITEM_HEIGHT) {
        return {
            ...state,
            [action.itemId]: updatePositions(state[action.itemId], action.breakpointIndex, {
                height: action.height,
            }),
        };
    }
    if(action.type === SET_SCROLLING_ITEM_DRAGGABLE) {
        return {
            ...state,
            [action.itemId]: {
                ...state[action.itemId],
                draggable: action.draggable,
            },
        };
    }
    if(action.type === SET_SCROLLING_ITEM_FIXED) {
        return {
            ...state,
            [action.itemId]: {
                ...state[action.itemId],
                fixed: action.fixed,
            },
        };
    }
    if(action.type === ADD_SPACE_TO_SCROLLER_TASK) {
        return action.taskItemIds.reduce((newState, itemId) => {
            const item = state[itemId];
            const higherPositions = item.positions.filter(p => shouldRepositionOnSpaceAdd(item, p, action.startY));
            if(higherPositions.length === 0) { return newState; }
            newState[itemId] = {
                ...item,
                positions: item.positions.map(p => !shouldRepositionOnSpaceAdd(item, p, action.startY) ? p : {
                    ...p,
                    y: p.y > action.startY ? p.y + action.height : p.y,
                    height: p.y < action.startY && item.type === SCROLLER_SECTION ? p.height + action.height : p.height,
                }),
            };
            return newState;
        }, {...state});
    }
    return state;
};

const shouldRepositionOnSpaceAdd = (item, position, startY) => {
    return position.y > startY || (item.type === SCROLLER_SECTION && position.y < startY && position.y + position.height > startY);
};
