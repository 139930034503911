import * as styles from "./shape-style";
import React from 'react';
import RightArrow from '../../../../../inline-assets/right-arrow-1.svg';
import Line from '../../../../../inline-assets/line.svg';
import Grid from '../../../../../inline-assets/grid.svg';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {isItemEditing} from "../../../../../store/itemSelection/selector";
import {getScrollingItemContent} from "../../../../../store/scrollingTaskItemPositions/selectors";
import {setScrollingItemContent} from "../../../../../store/scrollingTaskItemPositions/actions";
import {useParams} from "@reach/router";
import {GRID, LINE, RIGHT_ARROW} from "../../../../../constants/shapes";
import combineStyles from "../../../../../Utility/combineStyles";

const ShapeMap = {
    [LINE]: Line,
    [RIGHT_ARROW]: RightArrow,
    [GRID]: Grid,
};

export default props => {
    const {id} = props;
    const {curriculumId} = useParams();
    const isEditing = useSelector(isItemEditing.bind(null, id), shallowEqual);
    const shape = useSelector(getScrollingItemContent.bind(null, id), shallowEqual);
    const dispatch = useDispatch();

    const setShape = newShape => e => {
        e.preventDefault();
        dispatch(setScrollingItemContent(curriculumId, id, newShape));
    };

    const ShapeTag = ShapeMap[shape];
    return (
        <div css={styles.shape}>
            {(!shape || isEditing) && <div>
                <button onClick={setShape(RIGHT_ARROW)} css={combineStyles(styles, {
                    "shapeSelector": true,
                    "selectedShape": shape === RIGHT_ARROW,
                })}>
                    <RightArrow />
                </button>
                <button onClick={setShape(LINE)} css={combineStyles(styles, {
                    "shapeSelector": true,
                    "selectedShape": shape === LINE,
                })}>
                    <Line />
                </button>
                <button onClick={setShape(GRID)} css={combineStyles(styles, {
                    "shapeSelector": true,
                    "selectedShape": shape === GRID,
                })}>
                    <Grid />
                </button>
            </div> }
            {ShapeTag && !isEditing && <ShapeTag />}
        </div>
    );
};
