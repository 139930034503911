export const EnergyProteinRotor = "EnergyProteinRotor";
export const EnergyProteinConvertor = "EnergyProteinConvertor";
export const EnergyProteinStabilizer = "EnergyProteinStabilizer";
export const Motor = "Motor";
export const Transport = "Transport";
export const Antibody = "Antibody";
export const Antibody2 = "Antibody2";
export const Trim21 = "Trim21";
export const Recycle = "Recycler";
export const Structural = "Structural";
export const DefaultCargo = "DefaultCargo";
export const Virus = "Virus";

export const ProteinForm_MRNA = "MRNA";
export const ProteinForm_Protein = "Protein";
export const ProteinForm_ProteinInVesicle = "ProteinInVesicle";
export const ProteinForm_Denatured = "Denatured";

export const HELP_VIEW = "Help";
export const CELL_VIEW = "Cell";
export const GENE_SELECTION_VIEW = "Gene selection";
export const TRANSCRIPTION_VIEW = "Transcription";
export const TRANSLATION_VIEW = "Translation";

export const ADENINE = "Adenine";
export const THYMINE = "Thymine";
export const CYTOSINE = "Cytosine";
export const GUANINE = "Guanine";
export const URACIL = "Uracil";

export const DNA_STRAND = "dna";
export const MRNA_STRAND = "mrna";
export const TRNA_STRAND = "trna";

export const NUCLEOTIDE_LETTER_MAP = {
    [ADENINE]: "A",
    [THYMINE]: "T",
    [CYTOSINE]: "C",
    [GUANINE]: "G",
    [URACIL]: "U",
};
export const LETTER_TO_NUCLEOTIDE_MAP = {
    "A": ADENINE,
    "T": THYMINE,
    "C": CYTOSINE,
    "G": GUANINE,
    "U": URACIL,
};

export const IsMRNANucleotideMatch = (dna, mrna) => {
    return (dna === THYMINE && mrna === ADENINE)
        || (dna === ADENINE && mrna === URACIL)
        || (dna === GUANINE && mrna === CYTOSINE)
        || (dna === CYTOSINE && mrna === GUANINE);
};
export const GetTranslationNucleotideMatch = mrna => {
    return mrna === ADENINE
        ? URACIL
        : mrna === URACIL
        ? ADENINE
        : mrna === GUANINE
        ? CYTOSINE
        : mrna === CYTOSINE
        ? GUANINE
        : null;
}
export const IsTRNANucleotideMatch = (mrna, trna) => {
    return trna === GetTranslationNucleotideMatch(mrna);
};

export const STOP_CODON = "stop";

export const getAminoAcidDisplayNameForNucleotides = nucleotides => {
    if(nucleotides.length < 3 || nucleotides.some(n => !n)) { return null; }
    return TRNA_AMINO_ACID_CHART[nucleotides[0]][nucleotides[1]][nucleotides[2]].short;
};
export const getAminoAcidColorForNucleotides = nucleotides => {
    if(nucleotides.length < 3 || nucleotides.some(n => !n)) { return null; }
    return TRNA_AMINO_ACID_CHART[nucleotides[0]][nucleotides[1]][nucleotides[2]].color;
};
export const getColorForShortAminoAcid = shortAmino => {
    const config = Object.values(AMINO_ACIDS).find(a => a.short === shortAmino);
    return config ? config.color : "";
}

const PHENYLALANINE = "Phenylalanine";
const LEUCINE = "Leucine";
const SERINE = "Serine";
const TYROSINE = "Tyrosine";
const CYSTEINE = "Cysteine";
const TRYPTOPHAN = "Tryptophan";
const PROLINE = "Proline";
const HISTIDINE = "Histidine";
const GLUTAMINE = "Glutamine";
const ARGININE = "Arginine";
const ISOLEUCINE = "Isoleucine";
const METHIONINE = "Methionine";
const THREONINE = "Threonine";
const ASPARAGINE = "Asparagine";
const LYSINE = "Lysine";
const VALINE = "Valine";
const ALANINE = "Alanine";
const ASPARTIC_ACID = "Aspartic Acid";
const GLUTAMIC_ACID = "Glutamic Acid";
const GLYCINE = "Glycine";

const AMINO_ACIDS = {
    [STOP_CODON]: {short: STOP_CODON, color: "transparent"},
    [PHENYLALANINE]: {short: "phe", color: "#fedcba"},
    [LEUCINE]: {short: "leu", color: "#4263c3"},
    [SERINE]: {short: "ser", color: "#f19413"},
    [TYROSINE]: {short: "tyr", color: "#6cad26"},
    [CYSTEINE]: {short: "cys", color: "#7933c4"},
    [TRYPTOPHAN]: {short: "trp", color: "#fedcba"},
    [PROLINE]: {short: "pro", color: "#fedcba"},
    [HISTIDINE]: {short: "his", color: "#fedcba"},
    [GLUTAMINE]: {short: "gln", color: "#93db29"},
    [ARGININE]: {short: "arg", color: "#832eaa"},
    [ISOLEUCINE]: {short: "ile", color: "#fedcba"},
    [METHIONINE]: {short: "met", color: "#c83c79"},
    [THREONINE]: {short: "thr", color: "#cea123"},
    [ASPARAGINE]: {short: "asn", color: "#fedcba"},
    [LYSINE]: {short: "lys", color: "#addb6d"},
    [VALINE]: {short: "val", color: "#7B82BD"},
    [ALANINE]: {short: "ala", color: "#3969c4"},
    [ASPARTIC_ACID]: {short: "asp", color: "#eab25d"},
    [GLUTAMIC_ACID]: {short: "glu", color: "#f19513"},
    [GLYCINE]: {short: "gly", color: "#d8500d"},
}

const TRNA_AMINO_ACID_CHART = {
    [ADENINE]: {
        [ADENINE]: {
            [ADENINE]: AMINO_ACIDS[PHENYLALANINE],
            [GUANINE]: AMINO_ACIDS[PHENYLALANINE],
            [URACIL]: AMINO_ACIDS[LEUCINE],
            [CYTOSINE]: AMINO_ACIDS[LEUCINE],
        },
        [GUANINE]: {
            [ADENINE]: AMINO_ACIDS[SERINE],
            [GUANINE]: AMINO_ACIDS[SERINE],
            [URACIL]: AMINO_ACIDS[SERINE],
            [CYTOSINE]: AMINO_ACIDS[SERINE],
        },
        [URACIL]: {
            [ADENINE]: AMINO_ACIDS[TYROSINE],
            [GUANINE]: AMINO_ACIDS[TYROSINE],
            [URACIL]: AMINO_ACIDS[STOP_CODON],
            [CYTOSINE]: AMINO_ACIDS[STOP_CODON],
        },
        [CYTOSINE]: {
            [ADENINE]: AMINO_ACIDS[CYSTEINE],
            [GUANINE]: AMINO_ACIDS[CYSTEINE],
            [URACIL]: AMINO_ACIDS[STOP_CODON],
            [CYTOSINE]: AMINO_ACIDS[TRYPTOPHAN],
        },
    },
    [GUANINE]: {
        [ADENINE]: {
            [ADENINE]: AMINO_ACIDS[LEUCINE],
            [GUANINE]: AMINO_ACIDS[LEUCINE],
            [URACIL]: AMINO_ACIDS[LEUCINE],
            [CYTOSINE]: AMINO_ACIDS[LEUCINE],
        },
        [GUANINE]: {
            [ADENINE]: AMINO_ACIDS[PROLINE],
            [GUANINE]: AMINO_ACIDS[PROLINE],
            [URACIL]: AMINO_ACIDS[PROLINE],
            [CYTOSINE]: AMINO_ACIDS[PROLINE],
        },
        [URACIL]: {
            [ADENINE]: AMINO_ACIDS[HISTIDINE],
            [GUANINE]: AMINO_ACIDS[HISTIDINE],
            [URACIL]: AMINO_ACIDS[GLUTAMINE],
            [CYTOSINE]: AMINO_ACIDS[GLUTAMINE],
        },
        [CYTOSINE]: {
            [ADENINE]: AMINO_ACIDS[ARGININE],
            [GUANINE]: AMINO_ACIDS[ARGININE],
            [URACIL]: AMINO_ACIDS[ARGININE],
            [CYTOSINE]: AMINO_ACIDS[ARGININE],
        },
    },
    [URACIL]: {
        [ADENINE]: {
            [ADENINE]: AMINO_ACIDS[ISOLEUCINE],
            [GUANINE]: AMINO_ACIDS[ISOLEUCINE],
            [URACIL]: AMINO_ACIDS[ISOLEUCINE],
            [CYTOSINE]: AMINO_ACIDS[METHIONINE],
        },
        [GUANINE]: {
            [ADENINE]: AMINO_ACIDS[THREONINE],
            [GUANINE]: AMINO_ACIDS[THREONINE],
            [URACIL]: AMINO_ACIDS[THREONINE],
            [CYTOSINE]: AMINO_ACIDS[THREONINE],
        },
        [URACIL]: {
            [ADENINE]: AMINO_ACIDS[ASPARAGINE],
            [GUANINE]: AMINO_ACIDS[ASPARAGINE],
            [URACIL]: AMINO_ACIDS[LYSINE],
            [CYTOSINE]: AMINO_ACIDS[LYSINE],
        },
        [CYTOSINE]: {
            [ADENINE]: AMINO_ACIDS[SERINE],
            [GUANINE]: AMINO_ACIDS[SERINE],
            [URACIL]: AMINO_ACIDS[ARGININE],
            [CYTOSINE]: AMINO_ACIDS[ARGININE],
        },
    },
    [CYTOSINE]: {
        [ADENINE]: {
            [ADENINE]: AMINO_ACIDS[VALINE],
            [GUANINE]: AMINO_ACIDS[VALINE],
            [URACIL]: AMINO_ACIDS[VALINE],
            [CYTOSINE]: AMINO_ACIDS[VALINE],
        },
        [GUANINE]: {
            [ADENINE]: AMINO_ACIDS[ALANINE],
            [GUANINE]: AMINO_ACIDS[ALANINE],
            [URACIL]: AMINO_ACIDS[ALANINE],
            [CYTOSINE]: AMINO_ACIDS[ALANINE],
        },
        [URACIL]: {
            [ADENINE]: AMINO_ACIDS[ASPARTIC_ACID],
            [GUANINE]: AMINO_ACIDS[ASPARTIC_ACID],
            [URACIL]: AMINO_ACIDS[GLUTAMIC_ACID],
            [CYTOSINE]: AMINO_ACIDS[GLUTAMIC_ACID],
        },
        [CYTOSINE]: {
            [ADENINE]: AMINO_ACIDS[GLYCINE],
            [GUANINE]: AMINO_ACIDS[GLYCINE],
            [URACIL]: AMINO_ACIDS[GLYCINE],
            [CYTOSINE]: AMINO_ACIDS[GLYCINE],
        },
    },
};

export const ALL_AMINO_ACIDS = Object.values(AMINO_ACIDS).map(a => a.short);
