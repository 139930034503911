import {takeEvery, call} from 'redux-saga/effects';
import {sendGraphQLSaga} from "../../DataService";
import {REMOVE_ITEM_FROM_WORK_BOARD_TEMPLATE} from "../../../store/workBoardTemplate/actions";

const removeItemFromTemplate = `mutation RemoveItemFromTemplate($input: RemoveItemFromTemplateInput!) {
    removeItemFromTemplate(input: $input) {
        workBoardDefinition {
            id
        }
    }
}`;

function* execute(action) {
    const variables = {
        input: {
            curriculum: action.curriculumId,
            workBoardDefinition: action.workBoardDefinitionId,
            templateItem: action.itemId,
        },
    };

    yield call(sendGraphQLSaga, removeItemFromTemplate, variables);
}

export default [takeEvery(REMOVE_ITEM_FROM_WORK_BOARD_TEMPLATE, execute)];
