export const cohortLearnerQuery = `query CohortLearnerQuery($id: ID) {
    verdantLearner(id: $id) {
        workBoards {
            id
            title
            order
            template
            assignedTrait
            predictions
        }
    }
}`;
export const cohortLearnerVariables = id => ({ id });

export const createCohortQuery = `mutation CreateCohort($input: CreateCohortInput!) {
    createCohort(input: $input) { cohort { id } }
}`;
export const createCohortVariables = (cohortId, teacherId) => ({
    input: {
        cohortId,
        verdantTeacherId: teacherId,
    }
});

export const setCohortNameQuery = `mutation SetCohortName($input: SetCohortNameInput!) {
    setCohortName(input: $input) { cohort { id } }
}`;
export const setCohortNameVariables = (cohortId, name) => ({
    input: {
        cohortId,
        name,
    }
});

export const addLearnersToCohortQuery = `mutation AddLearnersToCohort($input: AddLearnerToCohortInput!) {
    addLearnersToCohort(input: $input) { 
        cohort { 
            learners {
                id
                email
            }
        }
    }
}`;
export const addLearnersToCohortVariables = (cohortId, emails) => ({
    input: {
        cohortId,
        emails,
    }
});

export const addCurriculumToCohortQuery = `mutation AddCurriculumToCohort($input: AddCurriculumToCohortInput!) {
    addCurriculumToCohort(input: $input) {
        cohort {
            id
            curricula {
                id
                name
                type
                parentCurriculum
            }
        }
    }
}`;
export const addCurriculumToCohortVariables = (cohort, curriculum) => ({
    input: {
        cohort,
        curriculum,
    },
});
