import {EMPTY_INTERACTIONS, INTERACTIONS_LOADED} from "../actions";
import {DOGMA_DASH_INTERACTION} from "../../../constants/TaskTypes";
import {
    ADD_ITEM_TO_ORGANELLE,
    DOGMA_DASH_OBJECT_MOVED,
    DOGMA_DASH_OBJECT_REMOVED,
    DOGMA_DASH_ORGANELLE_TIMER_CHANGED,
    LYSOSOME_CONSUME_AT,
    LYSOSOME_EATEN,
    LYSOSOME_TARGET_SET,
    MOVE_DOGMA_DASH_OBJECT,
    REFRESH_DOGMA_DASH_INTERACTION_DATA,
    SET_DOGMA_DASH_ORGANELLE_BROKEN,
    SET_LYSOSOME_TARGET,
    SET_LYSOSOME_TARGET_X_Y
} from "./actions";
import {SET_AUTHENTICATION_STATE} from "../../identity/actions";
import {NOT_LOGGED_IN} from "../../../constants/AuthenticationState";
import {LysosomeEatTime} from "../../../Learn/CurriculumTask/Interactables/DogmaDash/Lysosome";

const defaultOrganelle = {
    timerStartTimeMs: null,
    timerDurationMs: null,
    timerType: null,
    alive: true,
    broken: false,
    heldObjects: [],
    alertDescription: null,
    targetOrganelle: null,
    targetStartTime: null,
    targetDuration: null,
    destroyedObjects: [],
    eaten: 0,
};

export default (state = {}, action) => {
    if(action.type === INTERACTIONS_LOADED) {
        const loadedInteractions = action.interactions.filter(i => i.__typename === DOGMA_DASH_INTERACTION)
            .reduce((agg, i) => allOrganellesFromInteraction(i)
                .reduce((agg, o) => ({
                    ...agg,
                    [o.id]: newOrganelleFromGraphql(o),
                }), agg)
            , {});
        return {
            ...state,
            ...loadedInteractions,
        };
    }
    if(action.type === REFRESH_DOGMA_DASH_INTERACTION_DATA) {
        const newOrganelles = allOrganellesFromInteraction(action.interactionData)
            .reduce((agg, o) => ({
                ...agg,
                [o.id]: newOrganelleFromGraphql(o),
            }), {});
        return {
            ...state,
            ...newOrganelles,
        };
    }
    if(action.type === ADD_ITEM_TO_ORGANELLE) {
        const { organelleId, objectId } = action;
        return {
            ...state,
            [organelleId]: {
                ...state[organelleId],
                heldObjects: [...state[organelleId].heldObjects, objectId],
            },
        };
    }
    if(action.type === DOGMA_DASH_OBJECT_MOVED || action.type === MOVE_DOGMA_DASH_OBJECT) {
        const {objectId, newOrganelleId} = action;
        const oldOrganelle = Object.keys(state).find(id => state[id].heldObjects.indexOf(objectId) >= 0);
        if(!oldOrganelle && !newOrganelleId) { return state; }
        if(oldOrganelle && oldOrganelle === newOrganelleId) { return state; }
        let update = {...state};
        if(newOrganelleId) {
            update[newOrganelleId] = {
                ...state[newOrganelleId],
                heldObjects: [...state[newOrganelleId].heldObjects, objectId],
            };
        }
        if(oldOrganelle) {
            update[oldOrganelle] = {
                ...state[oldOrganelle],
                heldObjects: state[oldOrganelle].heldObjects.filter(o => o !== objectId),
            };
        }
        return update;
    }
    if(action.type === DOGMA_DASH_ORGANELLE_TIMER_CHANGED) {
        const {organelleId, start, duration, timerType, alertDescription} = action;
        return {
            ...state,
            [organelleId]: {
                ...state[organelleId],
                timerStartTimeMs: start,
                timerDurationMs: duration,
                timerType,
                alertDescription,
            },
        };
    }
    if(action.type === SET_DOGMA_DASH_ORGANELLE_BROKEN) {
        const { organelleId, broken } = action;
        return {
            ...state,
            [organelleId]: {
                ...state[organelleId],
                broken,
            },
        };
    }
    if(action.type === DOGMA_DASH_OBJECT_REMOVED) {
        const {objectId} = action;
        const holder = Object.keys(state).find(id => state[id].heldObjects.indexOf(objectId) >= 0);
        if(!holder) { return state; } // probably unnecessary protection
        return {
            ...state,
            [holder]: {
                ...state[holder],
                heldObjects: state[holder].heldObjects.filter(id => id !== objectId),
                destroyedObjects: [...state[holder].destroyedObjects, objectId],
            },
        };
    }
    if(action.type === SET_LYSOSOME_TARGET) {
        const {lysosomeId, x, y, targetOrganelle, setBy} = action;
        return {
            ...state,
            [lysosomeId]: {
                ...state[lysosomeId],
                targetX: x,
                targetY: y,
                targetOrganelle,
                targetSetBy: setBy,
                targetStartTime: Date.now(),
                targetDuration: 2000 + LysosomeEatTime, // TODO: LYSOSOME MOVE AND ANIMATION TIMES!
                eating: false,
            },
        };
    }
    if(action.type === LYSOSOME_TARGET_SET) {
        const {lysosomeId, setBy, targetOrganelle, startTime, duration} = action;
        const xyChange = targetOrganelle ? {} : {targetX: null, targetY: null};
        return {
            ...state,
            [lysosomeId]: {
                ...state[lysosomeId],
                ...xyChange,
                targetOrganelle,
                targetSetBy: setBy,
                targetStartTime: startTime,
                targetDuration: duration,
                eating: false,
            },
        };
    }
    if(action.type === SET_LYSOSOME_TARGET_X_Y) {
        const {lysosomeId, x, y} = action;
        return {
            ...state,
            [lysosomeId]: {
                ...state[lysosomeId],
                targetX: x,
                targetY: y,
            },
        };
    }
    if(action.type === LYSOSOME_CONSUME_AT) {
        const {lysosomeId} = action;
        return {
            ...state,
            [lysosomeId]: {
                ...state[lysosomeId],
                eating: true,
            },
        };
    }
    if(action.type === LYSOSOME_EATEN) {
        const {lysosomeId, numberEaten} = action;
        return {
            ...state,
            [lysosomeId]: {
                ...state[lysosomeId],
                eaten: state[lysosomeId].eaten + numberEaten,
            },
        };
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    if(action.type === EMPTY_INTERACTIONS) {
        return {};
    }

    return state;
}

const allOrganellesFromInteraction = interaction => [
    ...interaction.dashMrnaSlots,
    ...interaction.dashMitochondria,
    ...interaction.dashCellMembrane,
    ...interaction.dashLysosome,
    ...interaction.dashGolgiBody,
    ...interaction.dashRibosome,
    ...interaction.dashMicrotubules,
    ...interaction.dashCentrioles,
    interaction.dashCytoplasm,
];

const newOrganelleFromGraphql = graphql => ({
    ...defaultOrganelle,
    timerStartTimeMs: graphql.timerStartTimeMs,
    timerDurationMs: graphql.timerDurationMs,
    timerType: graphql.timerType,
    alive: graphql.alive,
    broken: graphql.broken,
    heldObjects: graphql.heldObjects ? graphql.heldObjects.map(o => o.id) : null,
    alertDescription: graphql.alertDescription,
    targetOrganelle: graphql.target,
    targetSetBy: graphql.targetSetter,
    targetStartTime: graphql.targetStartTimeMs,
    targetDuration: graphql.targetDurationMs,
});
