import {css} from "@emotion/core";
import {bbPadding} from "../../../../../style-variables";
import {SCROLLING_CANVAS_WIDTH} from "../scrolling-styles";

export const explainerContainer = css({
    width: SCROLLING_CANVAS_WIDTH,
    height: 555, // PAGE_HEIGHT?
    backgroundColor: "#ddd",
    position: "relative",
}, bbPadding("8px"));

export const title = css({
    fontSize: "1.125em",
    padding: 0,
    margin: "0 0 8px 0",
});

export const questionLabel = css({
    display: "block",
    margin: "0 0 6px 0",
});

// export const questionInput = css({
//     display: "block",
//     width: "100%",
// }, bbPadding("4px"));

export const questionInput = css({
    width: "100%",
    height: "120px",
    background: "white",
});
