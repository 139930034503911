import * as styles from './visceral-card-styles';
import React from 'react';
import VisceralCard from "./VisceralCard";
import {combineStylesAlt} from "../../../../Utility/combineStyles";
import {useDispatch, useSelector} from "react-redux";
import {isExplainerEvidenceSelected} from "../../../../store/navigation/selectors";
import {toggleExplainerEvidenceSelected} from "../../../../store/navigation/actions";
import {getExplainerEvidenceIds} from "../../../../store/interactions/explainer/selectors";

const VisceralCardPickerWrapper = ({id, explainerId, caseIndex}) => {
    const selected = useSelector(state => isExplainerEvidenceSelected(state, id));
    const alreadyAdded = useSelector(state => getExplainerEvidenceIds(state, explainerId).includes(id));
    const dispatch = useDispatch();

    const toggleSelected = () => {
        dispatch(toggleExplainerEvidenceSelected(id));
    };

    const includeInEvidence = (!selected && alreadyAdded) || (selected && !alreadyAdded);

    return (
        <div onClick={toggleSelected} css={combineStylesAlt([
            [styles.cardWrapper, true],
            [styles.cardWrapperSelected, includeInEvidence],
        ])}>
            {includeInEvidence && <div css={styles.usedAsEvidence} className={styles.cardBadgeClassName}>Added</div>}
            {!includeInEvidence && <div css={styles.addAsEvidence} className={styles.cardBadgeClassName}>+ Add evidence</div>}
            <VisceralCard id={id} caseIndex={caseIndex} />
        </div>
    );
};

export default VisceralCardPickerWrapper;
