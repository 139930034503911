import './sectioned.scss';
import React, {useEffect, useState} from 'react';
import RichEditor from "../../../../RichText/RichEditor";
import Interactable from "../Interactables";
import classNames from "class-names";
import {
    CENTRAL_DOGMA_WIDGET,
    CPR,
    DRAGGABLE_BANK,
    INPUT_TABLE,
    LONG_TEXT_INPUT,
    MRNA_ACTIVITY,
    MULTIPLE_CHOICE_INPUT,
    SCROLLER_EXPLAINER,
    SCROLLER_EXPLAINER_PREDICTION, UTILITY_BUTTON,
    WORK_BOARD_DEFINITION
} from "../../../../constants/TaskTypes";
import {setCurriculumTaskContent} from "../../../../store/curriculumTask/actions";
import {addInteractableToTask} from "../../../../store/curriculumTaskItems/actions";
import uuid from "uuid/v4";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useParams} from "@reach/router";
import {getCurriculumTaskContent, getCurriculumTaskItemIds} from "../../../../store/curriculumTask/selectors";

const curriculumTaskContent = id => state => getCurriculumTaskContent(state, id);
const taskInteractables = id => state => getCurriculumTaskItemIds(state, id);

export default () => {
    const {curriculumId, taskId: id} = useParams();
    const content = useSelector(curriculumTaskContent(id), shallowEqual);
    const interactables = useSelector(taskInteractables(id), shallowEqual);
    const dispatch = useDispatch();
    const [showAddInteractableMenu, setShowAddInteractableMenu] = useState(false);

    useEffect(() => {
        setShowAddInteractableMenu(false);
    }, [id]);

    const setContent = newContent => {
        if(newContent === content) { return; } // TODO: prevents update to server
        dispatch(setCurriculumTaskContent(curriculumId, id, newContent));
    };

    const toggleAddInteractableMenu = () => setShowAddInteractableMenu(!showAddInteractableMenu);
    const addInteractable = interactableType => {
        dispatch(addInteractableToTask(curriculumId, id, uuid(), interactableType));
        setShowAddInteractableMenu(false);
    };

    return (
        <>
            <div className="admin-task--content">
                <RichEditor content={content} setContent={setContent} id={id} />
            </div>
            <div className="admin-task--interactables-container">
                {interactables.map(interactableId => <Interactable key={interactableId} id={interactableId} curriculum={curriculumId} task={id} />)}
                <div className={classNames({
                    "admin-task--interactable-selection-container": true,
                    "admin-task--interactable-selection-container__visible": showAddInteractableMenu,
                })}>
                    <button onClick={setShowAddInteractableMenu.bind(null, false)} className="interactable-selection--close" />
                    <div className="interactable-selection--description">Choose a student interaction to add to this task</div>
                    <button onClick={addInteractable.bind(null, WORK_BOARD_DEFINITION)} className="interactable-selection--new interactable-selection--new-work-board">
                        <span>Work Board</span>
                    </button>
                    <button onClick={addInteractable.bind(null, LONG_TEXT_INPUT)} className="interactable-selection--new interactable-selection--new-long-text">
                        <span>Long text input</span>
                    </button>
                    <button onClick={addInteractable.bind(null, MULTIPLE_CHOICE_INPUT)} className="interactable-selection--new interactable-selection--new-multiple-choice">
                        <span>Multiple choice</span>
                    </button>
                    <button onClick={addInteractable.bind(null, INPUT_TABLE)} className="interactable-selection--new interactable-selection--new-input-table">
                        <span>Input table</span>
                    </button>
                    <button onClick={addInteractable.bind(null, DRAGGABLE_BANK)} className="interactable-selection--new interactable-selection--new-draggable-bank">
                        <span>Draggable bank</span>
                    </button>
                    <button onClick={addInteractable.bind(null, CPR)} className="interactable-selection--new interactable-selection--new-cpr">
                        <span>CPR</span>
                    </button>
                    <button onClick={addInteractable.bind(null, SCROLLER_EXPLAINER)} className="interactable-selection--new">
                        <span>Explanation Builder</span>
                    </button>
                    <button onClick={addInteractable.bind(null, SCROLLER_EXPLAINER_PREDICTION)} className="interactable-selection--new">
                        <span>Explanation Prediction</span>
                    </button>
                    <button onClick={addInteractable.bind(null, MRNA_ACTIVITY)} className="interactable-selection--new">
                        <span>mRNA Activity</span>
                    </button>
                    <button onClick={addInteractable.bind(null, CENTRAL_DOGMA_WIDGET)} className="interactable-selection--new">
                        <span>Central Dogma Widget</span>
                    </button>
                    <button onClick={addInteractable.bind(null, UTILITY_BUTTON)} className="interactable-selection--new">
                        <span>Button</span>
                    </button>
                </div>
                <button onClick={toggleAddInteractableMenu} className="admin-task--add-interactable">Add Interactable</button>
            </div>
        </>
    );
}
