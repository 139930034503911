import * as styles from './transcription-styles';
import React, {useEffect, useState} from 'react';
import {useDrag, useDrop} from "react-dnd";
import {
    ADENINE, Antibody2,
    CYTOSINE,
    EnergyProteinConvertor,
    EnergyProteinRotor,
    EnergyProteinStabilizer, GENE_SELECTION_VIEW,
    GUANINE,
    IsMRNANucleotideMatch,
    Motor,
    ProteinForm_Denatured, ProteinForm_MRNA, ProteinForm_Protein,
    Recycle,
    Structural,
    THYMINE,
    TRANSLATION_VIEW,
    Transport,
    URACIL
} from "../../../../../constants/DogmaDash";
import {useDispatch, useSelector} from "react-redux";
import {
    getDogmaDashHighlightedGene, getDogmaDashLevelUnlockingProtein,
    getDogmaDashPortalNucleotide,
    getDogmaDashTranscriptionNucleotides, isTranscriptionComplete
} from "../../../../../store/interactions/dogmaDash/selectors";
import {useParams} from "@reach/router";
import {setDogmaDashPortalNucleotide, setDogmaDashView} from "../../../../../store/interactions/dogmaDash/actions";
import Nucleotide from "./Nucleotide";
import {
    TRANSCRIPTION_CONTINUE_TUTORIAL,
    TRANSCRIPTION_DECODER_TUTORIAL,
    TRANSCRIPTION_INVENTORY_TUTORIAL,
    TRANSCRIPTION_STRAND_TUTORIAL
} from "../../../../../store/tutorial/configuration/transcription-strand-1";
import {getTutorialDataToShow} from "../../../../../store/tutorial/selectors";
import PopUpTutorial from "../../../../Tutorial/PopUpTutorial";
import ArrowAsset from "../../../../../inline-assets/right-arrow-1.svg";
import {ProteinIconMap} from "../Protein";
import {GeneSelectionCardData} from "./GeneSelection";
import {isModalOpenFor} from "../../../../../store/navigation/selectors";
import {FIRST_TIME_TRANSCRIPTION} from "../../../../../constants/modalTypes";
import FirstTimeVid from './Assets/transcription-tut.gif';
import {closeModalType} from "../../../../../store/navigation/actions";

export const transcriptionProteinDisplayNames = (proteinType, proteinForm) => {
    if(proteinForm === ProteinForm_Denatured) { return "Debris"; }

    return (
        proteinType === EnergyProteinRotor ? "Energy Protein 1" :
        proteinType === EnergyProteinConvertor ? "Energy Protein 3" :
        proteinType === EnergyProteinStabilizer ? "Energy Protein 2" :
        proteinType === Motor ? "Delivery" :
        proteinType === Recycle ? "Recycler Enzyme" :
        proteinType === Transport ? "Channel Protein" :
        proteinType === Structural ? "Structural Protein" :
        proteinType === Antibody2 ? "Antibody 2.0" :
        proteinType
    );
}

const NUCLEOTIDE_DRAGGER = "nucleotide dragger";

const FirstTimeModal = ({id}) => {
    const proteinName = useSelector(state => transcriptionProteinDisplayNames(getDogmaDashLevelUnlockingProtein(state, id), ProteinForm_Protein));
    const dispatch = useDispatch();

    const close = () => dispatch(closeModalType(FIRST_TIME_TRANSCRIPTION));

    return (
        <div css={styles.firstTimeModalBlocker}>
            <div css={styles.firstTimeContainer}>
                <h2 css={styles.firstTimeHeader}>Transcription</h2>
                <div css={styles.firstTimeImageContainer}>
                    <img src={FirstTimeVid} />
                </div>
                <div css={styles.firstTimeText}>This is the first step in creating a protein: convert the <b>DNA</b> code to a <br /> matching sequence of <b>Messenger RNA (mRNA)</b>.<br /><br />The pilot can then use the mRNA sequence to build the {proteinName}.</div>
                <button css={styles.firstTimeButton} onClick={close}>Continue</button>
            </div>
        </div>
    );
};

const Strand = ({id}) => {
    const strand = useSelector(state => getDogmaDashTranscriptionNucleotides(state, id));
    const tutorialConfig = {dogmaDashId: id, location: TRANSCRIPTION_STRAND_TUTORIAL};

    let firstUnpaired = 0;
    while(strand[firstUnpaired]?.paired) { ++firstUnpaired; }

    return (
        <div css={styles.strandContent}>
            <div css={styles.transcriptionNucleotideBlock}>
                <div>Pilot Attached <span css={[styles.neutralTextTransform, styles.bolded]}>mRNA</span></div>
                <div>Template Sequence <span css={styles.bolded}>DNA</span></div>
                {strand.length === 0 && <div css={styles.strandTutorialPosition}><PopUpTutorial positionData={tutorialConfig} horizontal={true} /></div>}
            </div>
            {strand.map((s, i) => (
            <div key={i} css={styles.transcriptionNucleotideBlock}>
                {i === firstUnpaired && <div css={styles.strandTutorialPosition}><PopUpTutorial positionData={tutorialConfig} horizontal={true} /></div>}
                {s.paired ? <Nucleotide nucleotide={s.paired} incorrect={!IsMRNANucleotideMatch(s.given, s.paired)} /> : <div /> }
                {<Nucleotide nucleotide={s.given} bottom={true} />}
            </div>
            ))}
        </div>
    )
};

const Transcription = ({id}) => {
    const {curriculumId} = useParams();
    const portalNucleotide = useSelector(state => getDogmaDashPortalNucleotide(state, id));
    const selectedGene = useSelector(state => getDogmaDashHighlightedGene(state, id));
    const firstTimeOpen = useSelector(state => isModalOpenFor(state, FIRST_TIME_TRANSCRIPTION));
    const isComplete = useSelector(state => isTranscriptionComplete(state, id));
    const dispatch = useDispatch();
    const strandTutorialConfig = { dogmaDashId: id, location: TRANSCRIPTION_STRAND_TUTORIAL };
    const tutorial = useSelector(state => getTutorialDataToShow(state, strandTutorialConfig));
    const decoderTutorialConfig = {location: TRANSCRIPTION_DECODER_TUTORIAL, dogmaDashId: id};
    const inventoryTutorialConfig = {location: TRANSCRIPTION_INVENTORY_TUTORIAL, dogmaDashId: id};
    const buttonTutorialConfig = {location: TRANSCRIPTION_CONTINUE_TUTORIAL, dogmaDashId: id };

    const [_, adenineDragRef, adeninePreviewRef] = useDrag({
        item: {
            type: NUCLEOTIDE_DRAGGER,
            nucleotide: ADENINE,
        },
    });
    const [__, cytosineDragRef, cytosinePreviewRef] = useDrag({
        item: {
            type: NUCLEOTIDE_DRAGGER,
            nucleotide: CYTOSINE,
        },
    });
    const [___, guanineDragRef, guaninePreviewRef] = useDrag({
        item: {
            type: NUCLEOTIDE_DRAGGER,
            nucleotide: GUANINE,
        },
    });
    const [____, uracilDragRef, uracilPreviewRef] = useDrag({
        item: {
            type: NUCLEOTIDE_DRAGGER,
            nucleotide: URACIL,
        },
    });
    const blockInventory = tutorial?.alert;
    const setRef = (actualRef, r) => {
        if(blockInventory) {return;}
        actualRef(r);
    }

    const [_____, dropRef] = useDrop({
        accept: NUCLEOTIDE_DRAGGER,
        canDrop: () => !portalNucleotide,
        drop: item => dispatch(setDogmaDashPortalNucleotide(id, curriculumId, item.nucleotide)),
    });

    const next = () => dispatch(setDogmaDashView(id, TRANSLATION_VIEW));
    const clearPortal = () => dispatch(setDogmaDashPortalNucleotide(id, curriculumId, null));
    const back = () => dispatch(setDogmaDashView(id, GENE_SELECTION_VIEW));

    const ProteinIcon = ProteinIconMap[selectedGene][ProteinForm_MRNA];
    const cardImage = GeneSelectionCardData[selectedGene].image;

    return (
        <div css={styles.container}>
            {firstTimeOpen && <FirstTimeModal id={id} />}
            <div css={styles.pageHeaderSection}>
                <button css={styles.backButton} onClick={back}><ArrowAsset /></button>
                <h1 css={styles.header}>
                    <span css={styles.headerBlue}>Transcription</span>: DNA <ArrowAsset /> mRNA
                </h1>
                {isComplete && <button css={styles.translationButton} onClick={next}>Continue to Ribosome</button>}
                <div css={styles.buttonTutorial}><PopUpTutorial positionData={buttonTutorialConfig} /></div>
            </div>
            <div css={styles.pageSection}>
                <div css={styles.transcriptionBlock}>
                    <h2 css={styles.staticBlockHeader}>Live mRNA Build Feed</h2>
                    <Strand id={id} />
                </div>
                <div css={styles.proteinImageBlock}>
                    <h2 css={styles.staticBlockHeader}>{transcriptionProteinDisplayNames(selectedGene)} <ProteinIcon /></h2>
                    <div css={styles.proteinImageContainer}><img src={cardImage} /></div>
                </div>
            </div>
            <div css={styles.pageSection}>
                <div css={styles.decoderSectionContainer}>
                    <h2 css={styles.staticBlockHeader}>
                        <div css={styles.headerTutorial}><PopUpTutorial expandUp={true} positionData={decoderTutorialConfig} /></div>
                        Decoder
                    </h2>
                    <div css={styles.decoderContent}>
                        <div css={styles.decoderPair}>
                            <div css={styles.strandHeader}>mRNA</div>
                            <div css={styles.strandHeader}>DNA</div>
                        </div>
                        <div css={styles.decoderPair}>
                            <Nucleotide nucleotide={URACIL}/>
                            <Nucleotide nucleotide={ADENINE} bottom={true} />
                        </div>
                        <div css={styles.decoderPair}>
                            <Nucleotide nucleotide={CYTOSINE}/>
                            <Nucleotide nucleotide={GUANINE} bottom={true} />
                        </div>
                        <div css={styles.decoderPair}>
                            <Nucleotide nucleotide={ADENINE} />
                            <Nucleotide nucleotide={THYMINE} bottom={true} />
                        </div>
                        <div css={styles.decoderPair}>
                            <Nucleotide nucleotide={GUANINE} />
                            <Nucleotide nucleotide={CYTOSINE} bottom={true} />
                        </div>
                    </div>
                </div>

                <div css={blockInventory ? styles.disabledInventory : styles.inventoryBlock}>
                    <h2 css={styles.interactableBlockHeader}>
                        <div css={styles.headerTutorial}><PopUpTutorial expandUp={true} positionData={inventoryTutorialConfig} /></div>
                        Nucleotide Inventory
                    </h2>
                    <div css={styles.inventorySectionContainer}>
                        <div css={styles.inventoryItem} ref={setRef.bind(null, adenineDragRef)}>
                            <div css={styles.inventoryItemInnerContainer} ref={adeninePreviewRef}>
                                <Nucleotide nucleotide={ADENINE} small={true} containerStyle={styles.inventoryNucleotideAGContainer} />
                            </div>
                        </div>
                        <div css={styles.inventoryItem} ref={setRef.bind(null, uracilDragRef)}>
                            <div css={styles.inventoryItemInnerContainer} ref={uracilPreviewRef}>
                                <Nucleotide nucleotide={URACIL} small={true} containerStyle={styles.inventoryNucleotideUCContainer} />
                            </div>
                        </div>
                        <br/>
                        <div css={styles.inventoryItem} ref={setRef.bind(null, cytosineDragRef)}>
                            <div css={styles.inventoryItemInnerContainer} ref={cytosinePreviewRef}>
                                <Nucleotide nucleotide={CYTOSINE} small={true} containerStyle={styles.inventoryNucleotideUCContainer} />
                            </div>
                        </div>
                        <div css={styles.inventoryItem} ref={setRef.bind(null, guanineDragRef)}>
                            <div css={styles.inventoryItemInnerContainer} ref={guaninePreviewRef}>
                                <Nucleotide nucleotide={GUANINE} small={true} containerStyle={styles.inventoryNucleotideAGContainer} />
                            </div>
                        </div>
                    </div>
                </div>

                <div css={styles.portalBlock}>
                    <h2 css={styles.interactableBlockHeader}>Pilot Portal</h2>
                    <div css={styles.vrPortalSection}>
                        <button css={styles.clear} onClick={clearPortal} disabled={!portalNucleotide}>X</button>
                        <div css={styles.portal} ref={dropRef}>
                            {portalNucleotide &&
                            <div css={styles.inventoryItemInnerContainer}>
                                <Nucleotide nucleotide={portalNucleotide}/>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Transcription;
