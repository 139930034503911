import "./input-table.scss";
import React from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    getInputTableData,
    getInputTableNumberOfColumns,
    getInputTablePrompt,
} from "../../../store/curriculumTaskItems/inputTable/selectors";
import RichTextDisplay from "../RichTextDisplay";
import {CELL_TYPE_INPUT, CELL_TYPE_NONE, CELL_TYPE_STATIC} from "../../../constants/InputTableCellTypes";
import {useParams} from "@reach/router";
import {getInputTableInput} from "../../../store/interactions/selectors";
import {setInputTableInput} from "../../../store/interactions/actions";

export default ({id}) => {
    const prompt = useSelector(state => getInputTablePrompt(state, id));
    const numberOfColumns = useSelector(state => getInputTableNumberOfColumns(state, id));
    const data = useSelector(state => getInputTableData(state, id));

    const rows = numberOfColumns
        ? Array.from(new Array(data.length/numberOfColumns),
            (_, i) => data.slice(i * numberOfColumns, i * numberOfColumns + numberOfColumns))
        : [];

    return (
        <div className="learner-curriculum-task--input-table">
            <div className="learner-input-table--prompt">
                <RichTextDisplay content={prompt} />
            </div>
            <table className="learner-input-table--table">
                <tbody>
                {rows.map((r, y) => (
                    <tr key={`${id}-row-${y}`}>
                        {r.map((cellData, x) => <TableCell key={`${id}-cell-${y},${x}`} inputTableId={id} cellData={cellData} row={y} column={x} />)}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
};

const cellStateMap = (inputTableId, inputId) => state => inputId ? getInputTableInput(state, inputTableId, inputId) : null;

const TableCell = ({ inputTableId, cellData }) => {
    const { type, content, inputId } = cellData;
    const {curriculumId} = useParams();
    const inputData = useSelector(cellStateMap(inputTableId, inputId), shallowEqual);
    let inputValue;
    if(type === CELL_TYPE_INPUT && inputData) {
        ({inputValue} = inputData);
    }
    const dispatch = useDispatch();
    const setInputValue = e => dispatch(setInputTableInput(curriculumId, inputTableId, {
        inputId,
        inputValue: e.target.value,
    }));

    return (
        <td>
            {type === CELL_TYPE_NONE && <div className="input-table--cell-none" /> }
            {type === CELL_TYPE_STATIC && <RichTextDisplay content={content} /> }
            {type === CELL_TYPE_INPUT && <input defaultValue={inputValue} onBlur={setInputValue} />}
        </td>
    )
};
