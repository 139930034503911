import {call, select, takeEvery} from 'redux-saga/effects';
import {MOVE_WORK_BOARD_TEMPLATE_ITEM} from "../../../store/workBoardTemplate/actions";
import {sendGraphQLSaga} from "../../DataService";
import {getWorkBoardTemplateItemIds} from "../../../store/curriculumTaskItems/workBoardDefinition/selectors";

// const moveQuery = `mutation MoveWorkBoardTemplateItem($input: MoveWorkBoardTemplateItemInput!) {
//     moveWorkBoardTemplateItem(input: $input) {
//         item {
//             id
//         }
//     }
// }`;

const moveQuery = `mutation SetWorkBoardTemplateItemsOrder($input: SetWorkBoardTemplateItemsOrderInput!) {
    setWorkBoardTemplateItemsOrder(input: $input) {
        workBoardDefinition {
            id
        }
    }
}`;

function* execute(action) {
    const itemIds = yield select(state => getWorkBoardTemplateItemIds(action.workBoardDefinitionId, state));

    const variables = {
        input: {
            curriculum: action.curriculumId,
            workBoardDefinition: action.workBoardDefinitionId,
            items: itemIds,
        },
    };

    yield call(sendGraphQLSaga, moveQuery, variables);
}

export default [takeEvery(MOVE_WORK_BOARD_TEMPLATE_ITEM, execute)];
