import * as styles from './text-color-button-styles';
import React, {useEffect, useRef, useState} from 'react';
import {ReactEditor, useSlate} from "slate-react";
import {ChromePicker} from 'react-color';
import {css} from "@emotion/core";
import {Editor, Transforms} from "slate";

export const TEXT_COLOR_MARK = "text color mark";

const TextColorButton = () => {
    const [showSelector, setShowSelector] = useState(false);
    const [selection, setSelection] = useState();
    const editor = useSlate();
    const editorRef = useRef();
    let color = "#000";
    try {
        const marks = Editor.marks(editor);
        if(marks && marks[TEXT_COLOR_MARK]) {
            color = marks[TEXT_COLOR_MARK];
        }
    } catch {}

    useEffect(() => {
        if(showSelector) {
            editorRef.current.focus();
        }
    }, [showSelector])

    const focusEditor = () => {
        ReactEditor.focus(editor);
        Transforms.select(editor, selection);
    };

    const setColor = newColor => {
        // selection?
        if(newColor === "#000000") {
            editor.removeMark(TEXT_COLOR_MARK);
        } else {
            editor.addMark(TEXT_COLOR_MARK, newColor.hex);
        }
    };

    const buttonStyle = css(styles.textColorButton, {
        borderColor: color
    });

    const toggleShowSelector = e => {
        if(e.defaultPrevented) { return; }
        capture(e)
        if(!showSelector) {
            setSelection(editor.selection);
        } else {
            focusEditor();
        }
        setShowSelector(!showSelector) // very specifically want to use this form because of the blur/mouse down ordering
    }
    const closeSelector = () => {
        setShowSelector(false);
    }
    const capture = e => {
        e.stopPropagation();
        e.preventDefault();
    }

    return (
        <button css={buttonStyle} onMouseDown={toggleShowSelector} onClick={capture}>
            <span css={styles.buttonText}>A</span>
            {showSelector && <div ref={editorRef} css={styles.colorSelectorContainer} onMouseDown={capture} onBlur={closeSelector} tabIndex={-1}>
                <ChromePicker color={color} onChange={setColor} />
            </div> }
        </button>
    )
};

export default TextColorButton;
