const getScrollingItem = (itemId, state) => state.scrollingTaskItemPositions[itemId];
export const getScrollingItemPositions = (itemId, state) => getScrollingItem(itemId, state).positions;
export const getScrollingItemLocked = (itemId, state) => getScrollingItem(itemId, state).locked;
export const getScrollingItemFixed = (itemId, state) => getScrollingItem(itemId, state).fixed;
export const getScrollingItemContent = (itemId, state) => getScrollingItem(itemId, state).content;
export const getScrollingItemDraggable = (itemId, state) => getScrollingItem(itemId, state).draggable;

export const getNextPosition = (positions, scrollPosition) => {
    if(positions[0].scroll === null) { return positions[0]; }
    return positions.find(p => p.scroll !== null && p.scroll >= scrollPosition) || positions[positions.length-1];
}
