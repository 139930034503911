import * as styles from './text-size-styles';
import React, {useEffect, useState} from 'react';
import {ReactEditor, useSlate} from "slate-react";
import {Editor, Transforms} from "slate";

export const TEXT_SIZE_MARK = "TEXT_SIZE";

const TextSize = () => {
    const editor = useSlate();
    const [selection, setSelection] = useState();
    const [textSize, setTextSize] = useState(16);

    useEffect(() => {
        try {
            const marks = Editor.marks(editor);
            if(marks && marks[TEXT_SIZE_MARK]) {
                setTextSize(marks[TEXT_SIZE_MARK]);
            }
        } catch {}
    }, [editor.selection]);

    const capture = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const decrement = e => {
        capture(e);
        setTextSize(s => s - 1);
        setSize(textSize - 1);
    };
    const increment = e => {
        capture(e);
        setTextSize(s => s + 1);
        setSize(textSize + 1);
    };

    const setSize = newSize => {
        if(selection) {
            ReactEditor.focus(editor);
            Transforms.select(editor, selection);
            setSelection(null);
        }
        if(newSize === 16) {
            editor.removeMark(TEXT_SIZE_MARK);
        } else {
            editor.addMark(TEXT_SIZE_MARK, newSize);
        }
    };

    const focus = () => setSelection(editor.selection);
    const blur = () => setSize(textSize);
    const checkBlur = e => e.which === 13 && e.target.blur();
    const changeSize = e => setTextSize(e.target.value);

    return (
        <div css={styles.container}>
            <button onMouseDown={decrement} css={styles.button}>-</button>
            <input value={textSize} onChange={changeSize} onMouseDown={focus} onBlur={blur} onKeyDown={checkBlur} css={styles.input} disabled={true} />
            <button onMouseDown={increment} css={styles.button}>+</button>
        </div>
    )
};

export default TextSize;
