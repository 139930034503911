import {css} from "@emotion/core";
import {
    bbPadding,
    DROP_SLOT_SHADOW,
    PEA_PLANT_CARD_HEIGHT,
    PEA_PLANT_CARD_WIDTH,
    PHENOTYPE_CARD_HEIGHT,
    PHENOTYPE_CARD_WIDTH, PHOTOGRAPH_HEIGHT, PHOTOGRAPH_WIDTH
} from "../../../../style-variables";

export const container = css({
    textAlign: "center",
});
export const containerEditing = css({
    width: 130,
    height: 130,
});

export const typeSelector = css({
    margin: '5px 5px 0 0',
    padding: 4,
    backgroundColor: 'white',
    border: "1px solid #333",
    cursor: "pointer",
    transition: "background-color 0.2s",

    "&:hover": {
        backgroundColor: '#eee',
    }
});

export const slot = css({
    display: "inline-block",
    verticalAlign: "top",
    borderRadius: 2,
    border: "1px solid #c4c4c4",
    backgroundColor: "white",
    fontSize: "0.625em",
    color: "#bbb",
    fontStyle: "italic",
    boxShadow: DROP_SLOT_SHADOW,
});

export const plantCardSlot = css({
    width: PEA_PLANT_CARD_WIDTH,
    height: PEA_PLANT_CARD_HEIGHT,
});

export const phenotypeSlot = css({
    width: PHENOTYPE_CARD_WIDTH,
    height: PHENOTYPE_CARD_HEIGHT,
});

export const photographSlot = css({
    width: PHOTOGRAPH_WIDTH,
    height: PHOTOGRAPH_HEIGHT,
});

export const label = css({
    margin: "4px 0 0 0",
    fontSize: "0.875em",
    whiteSpace: "nowrap",
    color: "#333",
}, bbPadding("0 4px"));
export const labelEmpty = css({
    color: "#ccc",
    fontStyle: "italic",
});

export const labelInput = css({
    display: "block",
    maxWidth: "calc(100% - 8px)",
    border: "1px solid #333",
    padding: "none",
    margin: "4px 4px 0 4px",
});

export const selectorLabel = css({
    display: 'block',
});
