import "./learner.scss";

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setLearnerCurriculumLoadedForTeacher} from "../../store/navigation/actions";
import LearnerNavigation from '../LearnerNavigation';
import {getDataService} from "../../store/data/selectors";
import {useNavigate, useParams} from "@reach/router";
import {getCohortCurricula} from "../../store/cohorts/selectors";
import {getCurriculumType} from "../../store/curricula/selectors";
import {VISCERAL} from "../../constants/curriculumTypes";
import Visceral from '../../Learn/CurriculaViews/Visceral';
import {getCurrentlyLoadedCurriculum, getLoadedLearnerForTeacher} from "../../store/navigation/selectors";
import {getCurriculumForLearner} from "../../graphql/Curriculum";
import {emptyInteractions} from "../../store/interactions/actions";

const getVisceralCurriculumId = (state, cohort) => getCohortCurricula(state, cohort).find(c => getCurriculumType(state, c) === VISCERAL);
const getCurriculumLoaded = (state, curriculumId, learnerId) => {
    if(!curriculumId) { return false; }
    return getLoadedLearnerForTeacher(state) === learnerId && getCurrentlyLoadedCurriculum(state) === curriculumId;
}

export default () => {
    const {learnerId, cohortId, curriculumId} = useParams();
    const curriculumLoaded = useSelector(state => getCurriculumLoaded(state, curriculumId, learnerId));
    const dataService = useSelector(state => getDataService(state));
    const visceralId = useSelector(state => getVisceralCurriculumId(state, cohortId));
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if(curriculumLoaded || !curriculumId || !learnerId) { return; }
        dispatch(emptyInteractions());
        getCurriculumForLearner(dataService, curriculumId, learnerId).then(() => {
            dispatch(setLearnerCurriculumLoadedForTeacher(learnerId, curriculumId));
        });
    }, [learnerId, curriculumId]);
    useEffect(() => {
        if(!curriculumId && visceralId) {
            navigate(`${learnerId}/${visceralId}`, {replace: true}); // so odd that this works too: props.navigate(visceralId);
        }
    }, [visceralId, curriculumId]);

    return (
        <div className="cohort--learner-container">
            <LearnerNavigation cohortId={cohortId} />
            {curriculumId && <div className="cohort--learner-content">
                {!curriculumLoaded && <div className="load-spinner" />}
                {curriculumLoaded && (
                <React.Fragment>
                    <Visceral />
                </React.Fragment>
                )}
            </div>}
        </div>
    )
};
