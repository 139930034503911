import {call, put, takeEvery} from 'redux-saga/effects';
import {curriculumForLearnerQuery, curriculumForLearnerVariables} from "../../queries/curriculum";
import {sendGraphQLSaga} from "../../DataService";
import {parseWorkBoard} from "../../WorkBoard";
import {learnerWorkBoardsLoaded} from "../../../store/learners/actions";
import {interactionsLoaded} from "../../../store/interactions/actions";
import {curriculaLoaded} from "../../../store/curricula/actions";
import {
    PEA_PLANT_CARD,
    PODULATOR_RECEIPT,
    POLAROID,
    PUNNETT_SQUARE,
    STICKY_NOTE
} from "../../../constants/WorkBoardItemTypes";
import {omit} from "lodash";
import {importPolaroids} from "../../../store/polaroids/actions";
import {peaPlantCardsLoaded} from "../../../store/peaPlantCards/actions";
import {stickyNotesLoaded} from "../../../store/stickyNotes/actions";
import {podulatorReceiptsLoaded} from "../../../store/podulatorReceipts/actions";
import {punnettSquaresLoaded} from "../../../store/punnettSquares/actions";
import {peaPlantsLoaded} from "../../../store/peaPlants/actions";
import {LOAD_CURRICULUM_FOR_LEARNER} from "../../../store/navigation/actions";

function* executeGetCurriculumForLearner(action) {
    const {curriculumId, learnerId} = action;

    const variables = curriculumForLearnerVariables(curriculumId, learnerId);
    const {curriculumForLearner} = yield call(sendGraphQLSaga, curriculumForLearnerQuery, variables);

    if(!curriculumForLearner.curriculum) { return; }

    const workBoards = curriculumForLearner.interactions.filter(i => i.workBoard);
    yield call(parseWorkBoards, workBoards);
    yield put(learnerWorkBoardsLoaded(learnerId, workBoards));
    yield put(interactionsLoaded(curriculumForLearner.interactions));
    yield put(curriculaLoaded([curriculumForLearner.curriculum], true));
}
function* parseWorkBoards(workBoardsData) {
    const data = {
        [POLAROID]: [],
        [PEA_PLANT_CARD]: [],
        [STICKY_NOTE]: [],
        [PODULATOR_RECEIPT]: [],
        [PUNNETT_SQUARE]: [],
        peaPlants: [],
    };
    const allItems = workBoardsData.reduce((agg, wb) => [...agg, ...wb.items], []);
    allItems.forEach(ip => {
        if(!data[ip.item.__typename]) { return; }
        data[ip.item.__typename].push(omit(ip.item, ["__typename"]));
    });
    data[PEA_PLANT_CARD].forEach(card => {
        data.peaPlants.push(card.peaPlant);
        card.peaPlant = card.peaPlant.id;
    });
    data[PUNNETT_SQUARE].forEach(square => {
        if(square.leftPlant) {
            data.peaPlants.push(square.leftPlant);
            square.leftPlant = square.leftPlant.id;
        }
        if(square.topPlant) {
            data.peaPlants.push(square.topPlant);
            square.topPlant = square.topPlant.id;
        }
    });
    data[PODULATOR_RECEIPT].forEach(receipt => {
        data.peaPlants.push(receipt.topParent);
        receipt.topParent = receipt.topParent.id;
        data.peaPlants.push(receipt.leftParent);
        receipt.leftParent = receipt.leftParent.id;
    });

    yield put(importPolaroids(data[POLAROID]));
    yield put(peaPlantCardsLoaded(data[PEA_PLANT_CARD]));
    yield put(stickyNotesLoaded(data[STICKY_NOTE]));
    yield put(podulatorReceiptsLoaded(data[PODULATOR_RECEIPT]));
    yield put(punnettSquaresLoaded(data[PUNNETT_SQUARE]));
    yield put(peaPlantsLoaded(data.peaPlants));

    return data;
}

export default [
    takeEvery(LOAD_CURRICULUM_FOR_LEARNER, executeGetCurriculumForLearner),
];
