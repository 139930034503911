import {
    ADD_CASE_TO_CURRICULUM,
    ADD_TASK_TO_CURRICULUM_CASE,
    REMOVE_CASE_FROM_CURRICULUM, REMOVE_TASK_FROM_CURRICULUM_CASE, REORDER_TASK_IN_CURRICULUM_CASE,
    SET_CURRICULUM_CASE_DESCRIPTION
} from "./actions";
import {CURRICULA_LOADED, REMOVE_TASK_FROM_CURRICULUM} from "../curricula/actions";
import rearrangeArray from "../../Utility/rearrangeArray";

const defaultCase = {
    description: "",
    tasks: [],
};

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const newCurricula = action.curricula.reduce((agg, c) => {
            agg[c.id] = c.structure ? JSON.parse(c.structure) : [];
            return agg;
        }, {});
        return {
            ...state,
            ...newCurricula,
        };
    }
    if(action.type === ADD_CASE_TO_CURRICULUM) {
        return {
            ...state,
            [action.curriculumId]: [
                ...state[action.curriculumId],
                {...defaultCase},
            ],
        };
    }
    if(action.type === REMOVE_CASE_FROM_CURRICULUM) {
        return {
            ...state,
            [action.curriculumId]: state[action.curriculumId].filter((c, i) => i !== action.caseIndex),
        };
    }
    if(action.type === SET_CURRICULUM_CASE_DESCRIPTION) {
        return {
            ...state,
            [action.curriculumId]: state[action.curriculumId].map((c, i) => i !== action.caseIndex ? c : {
                ...c,
                description: action.description,
            }),
        };
    }
    if(action.type === ADD_TASK_TO_CURRICULUM_CASE) {
        return {
            ...state,
            [action.curriculumId]: state[action.curriculumId].map((c, i) => i !== action.caseIndex ? c : {
                ...c,
                tasks: c.tasks.concat(action.taskId),
            }),
        };
    }
    if(action.type === REMOVE_TASK_FROM_CURRICULUM_CASE) {
        return {
            ...state,
            [action.curriculumId]: state[action.curriculumId].map((c, i) => i !== action.caseIndex ? c : {
                ...c,
                tasks: c.tasks.filter(t => t !== action.taskId),
            }),
        };
    }
    if(action.type === REMOVE_TASK_FROM_CURRICULUM) {
        const casesWithTask = state[action.curriculumId].filter(c => c.tasks.includes(action.taskId));
        if(!casesWithTask.length) { return state; }
        return {
            ...state,
            [action.curriculumId]: state[action.curriculumId].map(c => !casesWithTask.includes(c) ? c : {
                ...c,
                tasks: c.tasks.filter(t => t !== action.taskId),
            }),
        };
    }
    if(action.type === REORDER_TASK_IN_CURRICULUM_CASE) {
        const currentTaskOrder = state[action.curriculumId][action.caseIndex].tasks;
        const updatedOrder = rearrangeArray(currentTaskOrder, action.taskId, action.newTaskIndex);
        return {
            ...state,
            [action.curriculumId]: state[action.curriculumId].map((c, i) => i === action.caseIndex ? {
                ...c,
                tasks: updatedOrder,
            } : c),
        };
    }
    return state;
}
