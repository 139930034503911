import {css} from "@emotion/core";
import openLock from "../../../../../assets/lock_open.svg";
import closedLock from "../../../../../assets/lock_closed.svg";
import pin from '../../../../../assets/pin.svg';
import pinned from '../../../../../assets/pin_pushed.svg';
import trash from "../../../../../assets/trash.svg";

export const menu = css({
    display: "none",
    height: 30,
    padding: 3,
    boxSizing: "border-box",
    position: "absolute",
    // top: -4,
    left: "50%",
    // transform: "translate(-50%, -100%)",
    backgroundColor: "white",
    border: "1px solid #ccc",
    borderRadius: 15,
    whiteSpace: "nowrap",
    opacity: 0,
    transition: "opacity 0.2s",
});
export const menuSelected = css({
    display: "block",
    opacity: 1,
    zIndex: 100,
});

export const button = css({
    display: "inline-block",
    verticalAlign: "top",
    width: 22,
    height: 22,
    padding: "0",
    border: "1px solid #ccc",
    borderRadius: "50%",
    backgroundColor: "white",
    backgroundSize: "80% 80%",
    backgroundPosition: "50% 50%",
    backgroundRepeat: "no-repeat",
    position: "relative",
    cursor: "pointer",
    transition: "background-color 0.2s",

    "&:not(:last-child)": {
        marginRight: "4px",
    },

    "&:hover": {
        backgroundColor: "#ccc",
    },

    "& svg": {
        width: "80%",
        height: "80%",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
    },
});

export const lockButton = css({
    backgroundImage: `url(${pin})`,
});

export const unlockButton = css({
    backgroundImage: `url(${pinned})`,
});

export const fixButton = css({
    backgroundImage: `url(${openLock})`,
});

export const unfixButton = css({
    backgroundImage: `url(${closedLock})`,
});

export const trashButton = css({
    backgroundImage: `url(${trash})`,
});

export const editing = css({
    "& .check_svg__svg-fill": {
        fill: "#45cf87",
    }
});
