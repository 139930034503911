import * as styles from './scrolling-item-styles';
import React, {useRef} from 'react';
import {
    getScrollingItemContent,
    getScrollingItemDraggable,
    getScrollingItemLocked,
    getScrollingItemPositions
} from "../../../store/scrollingTaskItemPositions/selectors";
import {shallowEqual, useSelector} from "react-redux";
import {
    FULL_WIDTH_TYPES,
    RESIZEABLE_TYPES,
    SCROLLER_EXPLAINER,
    SCROLLER_SECTION,
    SCROLLER_WORK_BOARD,
    SCROLLING_DATA_ITEM,
    SCROLLING_DROP_SLOT_ITEM,
    SCROLLING_IMAGE_ITEM,
    SCROLLING_SHAPE_ITEM,
    SCROLLING_TEXT_ITEM
} from "../../../constants/TaskTypes";
import Text from "../../../Admin/Curriculum/Task/Types/ScrollingItems/Text";
import Image from "../../../Admin/Curriculum/Task/Types/ScrollingItems/Image";
import Shape from '../../../Admin/Curriculum/Task/Types/ScrollingItems/Shape';
import useScroll from "../../../Utility/useScroll";
import DataItem from "../../../Admin/Curriculum/Task/Types/ScrollingItems/DataItem";
import {
    AXIAL_FLOWERS,
    CONSTRICTED_PODS,
    GREEN_PODS,
    GREEN_SEEDS,
    INFLATED_PODS,
    PURPLE_FLOWERS,
    ROUND_SEEDS,
    SHORT_PLANT,
    TALL_PLANT,
    TERMINAL_FLOWERS,
    WHITE_FLOWERS,
    WRINKLED_SEEDS,
    YELLOW_PODS,
    YELLOW_SEEDS
} from "../../../constants/PlantPhenotypes";
import {PLANT_PHENOTYPE} from "../../../constants/DropItemTypes";
import {useDrag} from "react-dnd";
import combineStyles from "../../../Utility/combineStyles";
import {css} from "@emotion/core";
import {getDropAreasForDraggable} from "../../../store/interactions/selectors";
import DropSlot from "./DropSlot";
import Explainer from "./Explainer";
import Section from "../../../Admin/Curriculum/Task/Types/ScrollingItems/Section";
import InlineWorkBoard from "./InlineWorkBoard";
import {getCurriculumTaskItemType} from "../../../store/curriculumTaskItems/selectors";

const ItemTagMap = {
    [SCROLLING_TEXT_ITEM]: Text,
    [SCROLLING_IMAGE_ITEM]: Image,
    [SCROLLING_SHAPE_ITEM]: Shape,
    [SCROLLING_DROP_SLOT_ITEM]: DropSlot,
    [SCROLLING_DATA_ITEM]: DataItem,
    [SCROLLER_EXPLAINER]: Explainer,
    [SCROLLER_SECTION]: Section,
    [SCROLLER_WORK_BOARD]: InlineWorkBoard,
};

const ScrollingItem = React.memo(({id, scroller, yOffset}) => {
    const itemType = useSelector(state => getCurriculumTaskItemType(state, id));
    const positions = useSelector(state => getScrollingItemPositions(id, state));
    const locked = useSelector(state => getScrollingItemLocked(id, state));
    const draggable = useSelector(state => getScrollingItemDraggable(id, state));
    const draggableType = useSelector(state => getScrollingItemContent(id, state)); // todo: this really could be better (using "getContent" to get draggableType)
    const inDropArea = useSelector(state => getDropAreasForDraggable(state, id), shallowEqual);
    const itemRef = useRef();
    const resizeData = RESIZEABLE_TYPES[itemType];
    yOffset = yOffset || 0;

    useScroll(scroller, itemRef, positions, locked, resizeData, yOffset);

    const [{isDragging}, dragRef] = useDrag({
        item: {
            id,
            type: dragTypeMap[draggableType] || "none",
            draggableType: itemType,
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    })
    const setRef = ref => {
        itemRef.current = ref;
        if(draggable) {
            dragRef(ref);
        }
    }

    let style = combineStyles(styles, {
        item: true,
        itemDraggable: draggable,
        itemDragging: isDragging,
        itemInDropArea: inDropArea.length > 0,
        itemFullWidth: FULL_WIDTH_TYPES.includes(itemType),
    });
    style = css(style, {
        left: positions[0].x,
        top: positions[0].y + yOffset,
        opacity: 0,
    });

    const ItemTag = ItemTagMap[itemType];
    return (
        <div ref={setRef} css={style}>
            <ItemTag id={id} />
        </div>
    )
});

const dragTypeMap = {
    [WHITE_FLOWERS]: PLANT_PHENOTYPE,
    [PURPLE_FLOWERS]: PLANT_PHENOTYPE,
    [GREEN_SEEDS]: PLANT_PHENOTYPE,
    [YELLOW_SEEDS]: PLANT_PHENOTYPE,
    [WRINKLED_SEEDS]: PLANT_PHENOTYPE,
    [ROUND_SEEDS]: PLANT_PHENOTYPE,
    [GREEN_PODS]: PLANT_PHENOTYPE,
    [YELLOW_PODS]: PLANT_PHENOTYPE,
    [INFLATED_PODS]: PLANT_PHENOTYPE,
    [CONSTRICTED_PODS]: PLANT_PHENOTYPE,
    [TALL_PLANT]: PLANT_PHENOTYPE,
    [SHORT_PLANT]: PLANT_PHENOTYPE,
    [AXIAL_FLOWERS]: PLANT_PHENOTYPE,
    [TERMINAL_FLOWERS]: PLANT_PHENOTYPE,
};

ScrollingItem.displayName = "ScrollingItem";
export default ScrollingItem;
