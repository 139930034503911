import React from 'react';
import classNames from "class-names";
import {useSlate} from "slate-react";

export default props => {
    const editor = useSlate();

    const active = props.isActive(editor, props.style);

    const toggle = e => {
        e.preventDefault();
        e.stopPropagation();
        props.onToggle(editor, props.style, !active);
    };

    return (
        <span onMouseDown={toggle} className={classNames({
            "rich-editor--style-button": true,
            "rich-editor--style-button__active": active,
            [`rich-editor--style-button__${props.buttonType}`]: true,
        })} />
    );
};
