import {
    COHORT_ASSIGNED_CURRICULA_LOADED,
    CREATE_COHORT,
    REPLACE_LEARNERS_IN_COHORT,
    SET_ALL_COHORTS,
    SET_COHORT_NAME
} from "./actions";
import {SET_AUTHENTICATION_STATE} from "../identity/actions";
import {NOT_LOGGED_IN} from "../../constants/AuthenticationState";

const baseCohort = id => ({
    id,
    name: '',
    learners: [],
    curricula: [],
});

export default function(state = {}, action) {
    if(action.type === SET_ALL_COHORTS) {
        return Object.keys(action.cohorts).reduce((agg, cohortId) => {
            let cohort = action.cohorts[cohortId];
            agg[cohortId] = Object.assign({}, cohort, {
                learners: cohort.learners.map(l => l.id),
                curricula: cohort.curricula.map(c => c.id),
            });
            return agg;
        }, {});
    }
    if(action.type === CREATE_COHORT) {
        return Object.assign({}, state, { [action.id]: baseCohort(action.id) })
    }
    if(action.type === SET_COHORT_NAME) {
        return Object.assign({}, state, {
            [action.cohortId]: Object.assign({}, state[action.cohortId], { name: action.name }),
        });
    }
    if(action.type === REPLACE_LEARNERS_IN_COHORT) {
        return Object.assign({}, state, {
            [action.cohortId]: Object.assign({}, state[action.cohortId], {
                learners: action.learners.map(l => l.id),
            }),
        });
    }
    if(action.type === COHORT_ASSIGNED_CURRICULA_LOADED) {
        const updatedCurricula = Object.assign({}, state[action.cohortId], {
            curricula: action.curricula.map(c => c.id),
        });
        return Object.assign({}, state, {
            [action.cohortId]: updatedCurricula,
        });
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    return state;
}
