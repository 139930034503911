export const SET_DROP_SLOT_ACCEPTS = "set drop slot accepts";
export const setDropSlotAccepts = (curriculumId, dropSlotId, acceptsType) => ({
    type: SET_DROP_SLOT_ACCEPTS,
    curriculumId,
    dropSlotId,
    acceptsType,
});

export const SET_DROP_SLOT_LABEL = "set drop slot label";
export const setDropSlotLabel = (curriculumId, dropSlotId, label) => ({
    type: SET_DROP_SLOT_LABEL,
    curriculumId,
    dropSlotId,
    label,
});

export const SET_DROP_SLOT_VALIDATION_DATA = "set drop slot validation data";
export const setDropSlotValidationData = (curriculumId, dropSlotId, validationData) => ({
    type: SET_DROP_SLOT_VALIDATION_DATA,
    curriculumId,
    dropSlotId,
    validationData,
});
