import {css} from "@emotion/core";
import * as crossStyles from '../../Admin/Curriculum/Task/Interactables/WorkBoardTemplates/CrossCard/cross-card-style';
import {
    bbPadding,
    DROP_SLOT_HOVERED_HIGHLIGHT,
    DROP_SLOT_SHADOW,
    inlineBlockTop,
    PEA_PLANT_CARD_WIDTH
} from "../../style-variables";

export const container = css(crossStyles.container, bbPadding("0 8px 8px 0"));

export const parentsContainer = crossStyles.parentsContainer;

export const placeholder = css(inlineBlockTop, {
    margin: "0 0 0 6px",
});

export const slot = css({
    boxShadow: "none",
    transition: "background-color 0.2s",

    "&:empty": {
        boxShadow: DROP_SLOT_SHADOW,
    },
}, crossStyles.slot);
export const slotInactive = css({
    backgroundColor: "#bbb",
});
export const slotHovered = css({
    backgroundColor: DROP_SLOT_HOVERED_HIGHLIGHT,
});

export const crossSymbol = crossStyles.crossSymbol;

export const yieldArrow = crossStyles.yieldArrow;

export const offspringCard = css({
    margin: "8px 0 0 8px",
});
