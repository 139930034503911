import {
    getDogmaDashAvailableProteins,
    getDogmaDashCurrentView,
    getDogmaDashLevelUnlockingProtein,
    getDogmaDashPortalNucleotide,
    getDogmaDashTranscriptionNucleotides,
    isTranscriptionComplete
} from "../../interactions/dogmaDash/selectors";
import {TRANSCRIPTION_VIEW} from "../../../constants/DogmaDash";
import {isModalOpenFor} from "../../navigation/selectors";
import {FIRST_TIME_TRANSCRIPTION} from "../../../constants/modalTypes";

export const TRANSCRIPTION_STRAND_TUTORIAL = "transcription strand tutorial";
export const TRANSCRIPTION_DECODER_TUTORIAL = "transcription decoder tutorial";
export const TRANSCRIPTION_INVENTORY_TUTORIAL = "transcription inventory tutorial";
export const TRANSCRIPTION_CONTINUE_TUTORIAL = "transcription continue tutorial";

export default {
    id: "3f7254a1-e153-4b96-abea-51e1f68a360d",
    items: [
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== TRANSCRIPTION_STRAND_TUTORIAL || isModalOpenFor(state, FIRST_TIME_TRANSCRIPTION)) { return false; }
                const availableProteins = getDogmaDashAvailableProteins(state, dogmaDashId).length;
                return !!getDogmaDashLevelUnlockingProtein(state, dogmaDashId) &&
                    (availableProteins === 1 || availableProteins === 2);
            },
            header: "Waiting on Drone Pilot",
            text: "Your partner in VR needs to <b>teleport to the gene you highlighted</b><br />before you can start transcription.",
            alert: true,
            completionCondition: (state, {dogmaDashId}) => getDogmaDashTranscriptionNucleotides(state, dogmaDashId).length > 0,
        },
        {// temp hack for double jump
            condition: (state, {location, dogmaDashId}) => location === TRANSCRIPTION_CONTINUE_TUTORIAL,
            header: "",
            text: "",
            completionCondition: () => true,
        },
        {
            condition: (state, {location}) => location === TRANSCRIPTION_STRAND_TUTORIAL,
            header: "3 Things to Know for Transcription",
            text: "1. This is the sequence of <b>DNA nucleotides</b> you need to <b>Transcribe</b>. The <b>mRNA nucleotides</b> will appear as the Pilot attaches them.",
            forceCompletion: (state, {dogmaDashId}) => !!getDogmaDashPortalNucleotide(state, dogmaDashId),
        },
        {
            condition: (state, {location}) => location === TRANSCRIPTION_DECODER_TUTORIAL,
            header: "3 Things to Know for Transcription",
            text: "2. This is the Nucleotide Decoder, use it to figure out which mRNA nucleotides you need to match the DNA sequence.",
            forceCompletion: (state, {dogmaDashId}) => !!getDogmaDashPortalNucleotide(state, dogmaDashId),
        },
        {
            condition: (state, {location}) => location === TRANSCRIPTION_INVENTORY_TUTORIAL,
            header: "3 Things to Know for Transcription",
            text: "3. Send nucleotides to your pilot to help them build the mRNA by dragging and dropping them from your inventory to the Pilot Portal.",
            callToAction: "Drop a nucleotide into the Pilot Portal",
            completionCondition: (state, {dogmaDashId}) => {
                if(!dogmaDashId) {return false;}
                return !!getDogmaDashPortalNucleotide(state, dogmaDashId);
            },
        },
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== TRANSCRIPTION_STRAND_TUTORIAL) { return false; }
                return !getDogmaDashPortalNucleotide(state, dogmaDashId);
            } ,
            header: "Drone Pilot's Turn",
            text: "The pilot can generate their own nucleotides in VR.",
            callToAction: "Use the decoder to help the pilot figure out the next nucleotides",
            completionCondition: (state, {dogmaDashId}) => {
                if(!dogmaDashId) {return false;}
                const strand = getDogmaDashTranscriptionNucleotides(state, dogmaDashId);
                return strand.filter(n => !!n.paired).length > 1;
            },
            alert: true,
        },
        {// temp hack for double jump
            condition: (state, {location, dogmaDashId}) => location === TRANSCRIPTION_CONTINUE_TUTORIAL,
            header: "",
            text: "",
            completionCondition: () => true,
        },
        { // double jump?
            condition: (state, {location, dogmaDashId}) => location === TRANSCRIPTION_CONTINUE_TUTORIAL && isTranscriptionComplete(state, dogmaDashId),
            header: "Transcription Complete",
            text: "The pilot is taking the mRNA out of the nucleus and to the ribosome for translation.",
            callToAction: "Click Continue to Ribosome to move to the next stage of synthesis",
            completionCondition: (state, {dogmaDashId}) => {
                return getDogmaDashCurrentView(state, dogmaDashId) !== TRANSCRIPTION_VIEW;
            },
        }
    ],
};
