import "./activity-viewport.scss";
import React from 'react';
import {useSelector} from "react-redux";
import {getSelectedActivity} from "../../store/navigation/selectors";
import {getActivityTitle, getTasksForActivity, getTaskType} from "../../store/curriculum/selectors";
import TaskDisplay from "./TaskDisplay";
import {shallowUnorderedArrayCompare} from "../../utility";

const stateMap = state => {
    const selectedActivity = getSelectedActivity(state);
    const taskIds = getTasksForActivity(state, selectedActivity);
    const taskTypes = taskIds.map(t => getTaskType(state, t));
    const webTasks = taskTypes.reduce((agg, t, i) => t === "Web" ? agg.concat(taskIds[i]) : agg, []);
    const vrTasks = taskTypes.reduce((agg, t, i) => t === "VR" ? agg.concat(taskIds[i]) : agg, []);
    return {
        title: getActivityTitle(state, getSelectedActivity(state)),
        tasks: webTasks.concat(vrTasks),
    }
};

const compare = (a, b) => {
    if(a.title !== b.title) { return false; }
    return shallowUnorderedArrayCompare(a.tasks, b.tasks);
};

export default React.memo(props => {
    const { tasks, title } = useSelector(stateMap, compare);
    const TaskDisplayRender = props.TaskDisplay || TaskDisplay;
    return (
        <div className="learner-main--right">
            <h2 className="selected-activity-title">{title}</h2>
            <div className="selected-activity--tasks-container">
                {tasks.map(t => <TaskDisplayRender id={t} key={t} />)}
            </div>
        </div>
    );
});
