import {GENE_SELECTION_NUCLEUS} from "./gene-selection-strand1";
import {EnergyProteinRotor, EnergyProteinStabilizer} from "../../../constants/DogmaDash";
import {getDogmaDashAvailableProteins, getDogmaDashLevelUnlockingProtein} from "../../interactions/dogmaDash/selectors";

export default {
    id: "021d8930-b0f0-43cb-9783-772d2ccf2d34",
    items: [
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== GENE_SELECTION_NUCLEUS) { return false; }
                const unlocking = getDogmaDashLevelUnlockingProtein(state, dogmaDashId);
                return unlocking === EnergyProteinRotor || unlocking === EnergyProteinStabilizer;
            },
            header: "2 Protein Sub-parts",
            text: "There are two sub-parts required to build the energy protein.<br /><b>Highlight one</b> of them for the pilot now.",
            width: 460,
        },
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== GENE_SELECTION_NUCLEUS) { return false; }
                const available = getDogmaDashAvailableProteins(state, dogmaDashId);
                return available.indexOf(EnergyProteinRotor) >= 0 || available.indexOf(EnergyProteinStabilizer) >= 0;
            },
            header: "2 Protein Sub-parts",
            text: "You’ve built the first part of the energy protein!<br /><b>Highlight the second one</b> for the pilot now.",
            width: 460,
        },
    ],
};
