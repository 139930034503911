import * as styles from './explainer-prediction-styles';
import React from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {setExplainerForPrediction} from "../../../../../store/curriculumTaskItems/explanationPredictions/actions";
import {useParams} from "@reach/router";
import {
    getCurriculumTaskItemType,
    getCurriculumTasksWithExplainers,
} from "../../../../../store/curriculumTaskItems/selectors";
import {getPredictionExplainer} from "../../../../../store/curriculumTaskItems/explanationPredictions/selectors";
import {getCurriculumTaskItemIds, getCurriculumTaskTitle} from "../../../../../store/curriculumTask/selectors";
import {SCROLLER_EXPLAINER} from "../../../../../constants/TaskTypes";

const ExplainerPrediction = ({id}) => {
    const {curriculumId} = useParams();
    const explainerTasks = useSelector(state => getCurriculumTasksWithExplainers(state), shallowEqual);
    const explainerNames = useSelector(state => explainerTasks.map(t => getCurriculumTaskTitle(state, t)), () => true);
    const explainerIds = useSelector(state => explainerTasks.map(t => getCurriculumTaskItemIds(state, t).find(i => getCurriculumTaskItemType(state, i) === SCROLLER_EXPLAINER)), shallowEqual);
    const connectedExplainer = useSelector(state => getPredictionExplainer(state, id))
    const dispatch = useDispatch();

    const setExplainer = e => {
        const index = explainerNames.indexOf(e.target.value);
        dispatch(setExplainerForPrediction(curriculumId, id, index >= 0 ? explainerIds[index] : null));
    };

    const options = ["None selected"].concat(explainerNames);
    return (
        <div css={styles.container}>
            <h2 css={styles.heading}>Explanation Builder Prediction</h2>
            <label css={styles.explainerSelectLabel}>Prediction for Explainer in:</label>
            <select value={options[explainerIds.indexOf(connectedExplainer)+1]} onChange={setExplainer}>
                {options.map((o, i) => <option key={i}>{o}</option>)}
            </select>
        </div>
    )
};

export default ExplainerPrediction;
