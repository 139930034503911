import {getDataService} from "../data/selectors";
import {ATTEMPTING_LOG_IN, NOT_LOGGED_IN} from "../../constants/AuthenticationState";
import {ADMIN_PERMISSION, PERMISSION_ALLOWED, PERMISSION_DENIED, PERMISSION_UNKNOWN} from "../../constants/permissions";
import {getUserRoles} from "../../graphql/UserAccount";

export const getAuthenticationState = state => state.identity.authenticationState;
export const getAuthenticationAs = state => state.identity.authenticatedAs;
export const getLTIKey = state => sessionStorage.getItem("ltik");
export const getTeacherId = state => {
    const authenticationState = getAuthenticationState(state);
    if(authenticationState === NOT_LOGGED_IN || authenticationState === ATTEMPTING_LOG_IN) { return null; }
    let teacherId = state.identity.teacherId;
    if(!teacherId) {
        getDataService(state).getVerdantTeacher();
    }
    return teacherId;
};
export const isTeacherLoggedIn = state => !!state.identity.teacherId;

let roleRetrievalPromise;
export const isPermissionAllowed = (state, permissionType) => {
    if(permissionType === ADMIN_PERMISSION) {
        const roles = state.identity.currentUserRoles;
        if(!roles) {
            if(!roleRetrievalPromise) {
                roleRetrievalPromise = getUserRoles(getDataService(state))
                    .then(() => { roleRetrievalPromise = null; });
            }
            return PERMISSION_UNKNOWN;
        }
        return roles.indexOf("Admin") < 0 ? PERMISSION_DENIED : PERMISSION_ALLOWED;
    }
    return PERMISSION_UNKNOWN;
};

export const getHeadsetPairingKey = state => state.identity.headsetAuthorized;
export const isHeadsetAuthorized = state => !!getHeadsetPairingKey(state);
export const isAuthorizingHeadset = state => state.identity.headsetAuthorizedState === ATTEMPTING_LOG_IN;
