import {TRAIT_TYPES, ZYGOSITY} from "../../constants/PodulatorReceiptTypes";
import {TRAIT_SYMBOL_MAP} from "../../constants/TraitGeneSymbol";
import {DOMINANT, HETEROZYGOUS, RECESSIVE} from "../../constants/Zygosities";
import {getPeaPlantName, getPeaPlantTraitGenotype} from "../peaPlants/selectors";
import {getTraitZygosity} from "../../WorkBoard/Items/PunnettSquare/utility";

const getPodulatorReceipt = (state, id) => state.podulatorReceipts[id];
export const getPodulatorReceiptLabel = (state, id) => {
    const podulatorReceipt = getPodulatorReceipt(state, id);
    const topPlant = getPeaPlantName(state, podulatorReceipt.topParent);
    const leftPlant = getPeaPlantName(state, podulatorReceipt.leftParent);
    return `Pod ${topPlant} x ${leftPlant}`;
};
export const getPodulatorReceiptSampleSize = (state, id) => getPodulatorReceipt(state, id).sampleSize;

export const getPodulatorReceiptPlantGenotypeLetter = (state, id, traitType, leftPlant, entryIndex) => {
    const receipt = getPodulatorReceipt(state, id);
    const plantId = leftPlant ? receipt.leftParent : receipt.topParent;
    const trait = TRAIT_TYPES[traitType];
    const plantGenotype = getPeaPlantTraitGenotype(state, plantId, trait);
    const zygosity = getTraitZygosity(plantGenotype);
    const letter = TRAIT_SYMBOL_MAP[trait];
    if(zygosity === DOMINANT || (zygosity === HETEROZYGOUS && entryIndex === 0)) {
        return letter.toUpperCase();
    }
    return letter;
};

export const getPodulatorReceiptTraitTypes = (state, id) => getPodulatorReceipt(state, id).crosses.map(c => c.traitType);

export const getPodulatorReceiptEntries = (state, id, traitType) => {
    const sampleSize = getPodulatorReceiptSampleSize(state, id);
    const data = getPodulatorReceipt(state, id).crosses.find(c => c.traitType === traitType);

    return [
        [{
            percent: data.trait1x1Count / sampleSize,
            count: data.trait1x1Count,
        }, {
            percent: data.trait1x2Count / sampleSize,
            count: data.trait1x2Count,
        }],
        [{
            percent: data.trait2x1Count / sampleSize,
            count: data.trait2x1Count,
        }, {
            percent: data.trait2x2Count / sampleSize,
            count: data.trait2x2Count,
        }],
    ];
};

export const getPodulatorReceiptGenomes = (state, id) => {
    const genomes = getPodulatorReceipt(state, id).uniqueGenomes;
    let sampleSize = getPodulatorReceiptSampleSize(state, id);
    if(getPodulatorReceiptTraitTypes(state, id).length > 1) {
        sampleSize *= 8;
    }

    return genomes.map(g => ({
        count: g.count,
        percent: g.count / sampleSize,
        genotypeString: g.traits.map(t => createTraitString(t)).join(""),
    }));
};
const createTraitString = (trait) => {
    const traitType = TRAIT_TYPES[trait.traitType];
    const traitRepresentation = TRAIT_SYMBOL_MAP[traitType];
    const zygosity = ZYGOSITY[trait.zygosity];
    return zygosity === DOMINANT
        ? `${traitRepresentation.toUpperCase()}${traitRepresentation.toUpperCase()}`
        : zygosity === RECESSIVE
        ? `${traitRepresentation}${traitRepresentation}`
        : `${traitRepresentation.toUpperCase()}${traitRepresentation}`;
};
