import * as styles from './curriculum-root-styles';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ThemeProvider} from '@emotion/react';
import {getFamilyNameForLearner, getGivenNameForLearner} from "../store/learners/selectors";
import {getCurriculumName, getCurriculumType} from "../store/curricula/selectors";
import {
    getActiveLearner,
    getCurrentlyLoadedCurriculum,
    getCurriculumLoadingForLearner
} from "../store/navigation/selectors";
import {attemptLogout} from "../Identity/utility";
import {navigate} from "@reach/router";
import CurriculumDisplay from "./CurriculumDisplay";
import HeadsetAuthorization from "./HeadsetAuthorization";
import {loadCurriculumForLearner} from "../store/navigation/actions";
import PartnersModal from "./Partners";
import {getTheme} from "./themes/selectors";
import {identityContainer, themedHeaderButton} from "../style-variables";
import {css} from "@emotion/core";

const CurriculumRoot = ({curriculumId}) => {
    const currentlyLoadedCurriculum = useSelector(getCurrentlyLoadedCurriculum);
    const learnerId = useSelector(getActiveLearner);
    const givenName = useSelector(state => getGivenNameForLearner(state, learnerId));
    const familyName = useSelector(state => getFamilyNameForLearner(state, learnerId));
    const curriculumName = useSelector(state => getCurriculumName(state, curriculumId));
    const curriculumType = useSelector(state => getCurriculumType(state, curriculumId));
    const dispatch = useDispatch();
    const theme = getTheme(curriculumType);

    useEffect(() => {
        dispatch(loadCurriculumForLearner(curriculumId, learnerId));
    }, [curriculumId]);

    const doLogout = () => {
        attemptLogout(dispatch);
        navigate("/");
    };

    return (
        <ThemeProvider theme={theme}>
            <div css={theme ? styles.themedContainer(theme) : styles.container}>
                <div css={theme ? styles.themedHeader(theme) : styles.headerContainer}>
                    <h1>{curriculumName}</h1>
                    <div css={identityContainer}>
                        <HeadsetAuthorization />
                        <PartnersModal />
                        <a css={[themedHeaderButton(theme), css({lineHeight: "50px"})]} target={"_blank"} href={"https://docs.google.com/presentation/d/e/2PACX-1vTVRFr4PzZpYApmENlKBLqNaahNYOfobGwJBY1B7_hfD67Pg_AM5vHu6bzbta1PxmhFuP7JPvz4x9GC/pub?start=false&loop=false&delayms=3000&slide=id.g1118d051574_0_0"}>FAQ</a>
                        <div css={theme ? styles.themedName(theme) : styles.name}>{`${givenName} ${familyName}`}</div>
                        <button css={theme ? styles.themedLogoutButton(theme) : styles.logoutButton} onClick={doLogout}>Log out</button>
                    </div>
                </div>
                {currentlyLoadedCurriculum !== curriculumId && <div className="load-spinner" />}
                {currentlyLoadedCurriculum === curriculumId && <CurriculumDisplay />}
            </div>
        </ThemeProvider>
    );
}

export default CurriculumRoot;
