import "./inline-editor.scss";
import React, {useState, useRef, useEffect} from 'react';
import classNames from 'class-names';

export default props => {
    const { name, setName } = props;
    const [editing, setEditing] = useState(false);
    const nameRef = useRef();
    const inputRef = useRef();
    useEffect(() => {
        inputRef.current.value = name;
    },[name]);

    const editName = () => {
        inputRef.current.focus();
        inputRef.current.select();
        setEditing(true);
    };
    const stopEditName = () => {
        nameRef.current.innerText = name;
        inputRef.current.value = name;
        setEditing(false);
    };
    const updateDisplay = e => nameRef.current.innerText = e.target.value;
    const saveName = () => {
        setName(inputRef.current.value);
        stopEditName();
    };
    const saveOnEnter = e => {
        if(e.which === 13) {
            saveName();
        }
    };

    return (
        <div className={classNames({
            "inline-editor--container": true,
            "inline-editor--container__editing": editing,
        })}>
            <div className="inline-editor--name-container">
                <h2 ref={nameRef} className="inline-editor--name-display">{name}</h2>
                <input ref={inputRef} className="inline-editor--name-input" defaultValue={name} onChange={updateDisplay} onKeyPress={saveOnEnter} />
            </div>
            <div className="inline-editor--button-container">
                <button onClick={editName} className="inline-editor--edit-button" />
                <button onClick={saveName} className="inline-editor--save-button" />
                <button onClick={stopEditName} className="inline-editor--cancel-button" />
            </div>
        </div>
    )
}
