import {CURRICULA_LOADED} from "../../curricula/actions";
import {CENTRAL_DOGMA_WIDGET} from "../../../constants/TaskTypes";
import {omit} from "lodash";
import {ADD_INTERACTABLE_TO_TASK, REMOVE_INTERACTABLE_FROM_TASK} from "../actions";
import {
    ADD_TARGET_AMINO_ACID_TO_CENTRAL_DOGMA_WIDGET, REMOVE_TARGET_AMINO_ACID_TO_CENTRAL_DOGMA_WIDGET,
    SET_CENTRAL_DOGMA_INITIAL_STRAND_LENGTH,
    SET_CENTRAL_DOGMA_INITIAL_STRAND_NUCLEOTIDE,
    SET_CENTRAL_DOGMA_MUTATION
} from "./actions";

const defaultState = {
    initialStrand: [],
    mutations: [],
    targetAminoAcids: [],
};
const defaultStrandItem = {
    dna: null,
    mrna: null,
    trna: null,
};

export default (state = {}, action) => {
    if (action.type === CURRICULA_LOADED) {
        const newInputs = action.curricula.reduce((agg, c) => ({
            ...agg,
            ...(c.tasks || []).reduce((agg, t) => ({
                ...agg,
                ...t.items.filter(i => i.__typename === CENTRAL_DOGMA_WIDGET).reduce((agg, i) => ({
                    ...agg,
                    [i.id]: {
                        ...defaultState,
                        ...omit(i, '__typename'),
                    },
                }), agg),
            }), agg),
        }), {});
        return {
            ...state,
            ...newInputs,
        };
    }
    if (action.type === ADD_INTERACTABLE_TO_TASK && action.interactableType === CENTRAL_DOGMA_WIDGET) {
        return {
            ...state,
            [action.interactableId]: {
                ...defaultState,
            },
        };
    }
    if (action.type === REMOVE_INTERACTABLE_FROM_TASK && state[action.interactableId]) {
        return omit(state, action.interactableId);
    }
    if(action.type === SET_CENTRAL_DOGMA_INITIAL_STRAND_LENGTH) {
        const {widget, length} = action;
        const current = state[widget].initialStrand;
        const updatedStrand = Array.from({length}, (_, i) => i < current.length ? current[i] : {...defaultStrandItem});

        return {
            ...state,
            [widget]: {
                ...state[widget],
                initialStrand: updatedStrand,
            },
        };
    }
    if(action.type === SET_CENTRAL_DOGMA_INITIAL_STRAND_NUCLEOTIDE) {
        const {widget, strandName, index, nucleotide} = action;

        const current = state[widget].initialStrand;
        const updatedStrand = current.map((trio, i) => i !== index ? trio : {
            ...trio,
            [strandName]: nucleotide,
        });

        return {
            ...state,
            [widget]: {
                ...state[widget],
                initialStrand: updatedStrand,
            },
        };
    }
    if(action.type === SET_CENTRAL_DOGMA_MUTATION) {
        const {widget, strandName, index, nucleotide} = action;
        const currentMutation = state[widget].mutations.find(m => m.index === index && m.strand === strandName);
        const toUpdate = currentMutation || {index, strand: strandName};
        const mutation = {
            ...toUpdate,
            nucleotide,
        };

        return currentMutation ?
            {
                ...state,
                [widget]: {
                    ...state[widget],
                    mutations: state[widget].mutations.map(m => m.index === index && m.strand === strandName ? mutation : m),
                },
            } :
            {
                ...state,
                [widget]: {
                    ...state[widget],
                    mutations: state[widget].mutations.concat(mutation),
                },
            };
    }
    if(action.type === ADD_TARGET_AMINO_ACID_TO_CENTRAL_DOGMA_WIDGET) {
        const {widget, aminoAcid} = action;

        return {
            ...state,
            [widget]: {
                ...state[widget],
                targetAminoAcids: state[widget].targetAminoAcids.concat(aminoAcid),
            },
        };
    }
    if(action.type === REMOVE_TARGET_AMINO_ACID_TO_CENTRAL_DOGMA_WIDGET) {
        const {widget, index} = action;

        return {
            ...state,
            [widget]: {
                ...state[widget],
                targetAminoAcids: state[widget].targetAminoAcids.filter((_, i) => i !== index),
            },
        };
    }

    return state;
};
