import * as styles from "./editor-style";
import React, {useEffect, useMemo, useState} from 'react';
import {withHistory} from "slate-history";
import {withReact, Slate, Editable, ReactEditor} from "slate-react";
import {createEditor, Transforms} from "slate";
import ButtonBar from './ButtonBar';
import editorBlockRenderer from "../editorBlockRenderer";
import renderLeaf from "../renderLeaf";

const empty = [{type: 'paragraph', children: [{text:""}]}];

export default props => {
    const {content, setContent, id} = props;
    const editor = useMemo(() => withHistory(withReact(createEditor())), []);
    const [value, setValue] = useState(empty);
    useEffect(() => {
        let startingValue = null;
        try {
            startingValue = JSON.parse(content);
        } catch {
            startingValue = (content && content.length) ? [{type: 'paragraph', children: [{text:content}]}] : empty;
        }
        setValue(startingValue);
    }, [content]);

    useEffect(() => {
        ReactEditor.focus(editor);

        // TODO: put the cursor at the correct position, maybe helpful code?
        // There was something wrong with the way getSelection worked though...
        // const selection = window.getSelection();
        // if(selection.focusNode && selection.focusNode.nodeType === 3) {
        //     setEditorSelection({
        //          text: selection.focusNode.textContent,
        //          position: selection.focusOffset,
        //     });
        // } else {

        Transforms.select(editor, {
            anchor: {
                offset: 0,
                path: [0, 0]
            },
            focus: {
                offset: 0,
                path: [0, 0],
            },
        });
    }, []);

    const saveContent = () => {
        const update = JSON.stringify(value);
        setContent(update);
    };

    return (
        <div css={styles.container}>
            <Slate editor={editor} value={value} onChange={setValue}>
                <ButtonBar editorId={id} />
                <Editable css={styles.editor}
                    onBlur={saveContent}
                    renderElement={editorBlockRenderer}
                    renderLeaf={renderLeaf}
                />
            </Slate>
        </div>
    )
}
