import {getActiveLearner} from "../../../store/navigation/selectors";
import {sendGraphQLSaga} from "../../DataService";
import {call, select, takeEvery} from "redux-saga/effects";
import {SET_AMINO_ACID_IN_MRNA_SLOT} from "../../../store/interactions/mrnaActivity/actions";

const query = `mutation LearnerPlacedAminoAcidOnMrna($input: LearnerPlacedAminoAcidOnMrnaInput!) {
    learnerPlacedAminoAcidOnMrna(input: $input) {
        interaction {
            interactable {
                id
            }
        }
    }
}`;

function* execute(action) {
    const learnerId = yield select(state => getActiveLearner(state));
    if(!learnerId) {return;}
    const {activityId, curriculumId, index, aminoAcid} = action;

    const variables = {
        input: {
            learner: learnerId,
            curriculum: curriculumId,
            mrnaActivity: activityId,
            slot: index,
            objectType: aminoAcid,
        },
    };
    yield call(sendGraphQLSaga, query, variables);
}

export default [
    takeEvery(SET_AMINO_ACID_IN_MRNA_SLOT, execute),
];
