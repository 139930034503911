import { all } from 'redux-saga/effects';
import curricula from './curricula';
import curriculumTask from './curriculumTask';
import interactions from './interactions';
import workBoardDefinition from './workBoardDefinition';
import authorizeHeadset from './authorizeHeadset';
import learner from './learner';
import completionCheck from "./tutorial/completionCheck";
import lti from './lti';

export default function* root() {
    yield all([
        ...curriculumTask,
        ...interactions,
        ...workBoardDefinition,
        ...curricula,
        ...authorizeHeadset,
        ...learner,
        ...completionCheck,
        ...lti,
    ]);
};
