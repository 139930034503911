import {DOGMA_DASH_ALERT_LIST, DOGMA_DASH_MRNA_INVENTORY} from "./dogma-strand-1";
import {
    getCurrentDogmaDashLevel,
    getDogmaDashAvailableProteins,
    getDogmaDashCytoplasm,
    getDogmaDashLevelUnlockingProtein,
    getDogmaMicrotubuleIds,
    getDogmaMrnaSlotIds,
    getObjectsInOrganelle,
    getObjectType,
    getOrganelleTimerStart,
    getOrganelleTimerType
} from "../../interactions/dogmaDash/selectors";
import {ProteinForm_ProteinInVesicle, Structural} from "../../../constants/DogmaDash";
import {proteinFormAtOrganelle, proteinTypeAtOrganelle} from "./utility";
import {CYTOPLASM_TUTORIAL} from "./dogma-strand-structural-unlock";
import {MRNA_INVENTORY_PROTEIN_TUTORIAL} from "./dogma-strand-antibody-unlock";

export default {
    id: "dfc2323b-33eb-4bb6-b3c2-ee3d15609649",
    items: [
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== DOGMA_DASH_ALERT_LIST) {return false;}
                const microtubuleAlert = getDogmaMicrotubuleIds(state, dogmaDashId).some(o => getOrganelleTimerStart(state, o) && getOrganelleTimerType(state, o) === "Alert");
                return microtubuleAlert && !getDogmaDashLevelUnlockingProtein(state, dogmaDashId) && getDogmaDashAvailableProteins(state, dogmaDashId).length === 3 && getCurrentDogmaDashLevel(state, dogmaDashId) === 1;
            },
            header: "New Alert Type",
            text: "The cell’s highway system and central support, the <b>microtubules</b>, have been damaged.",
            forceCompletion: (state, {dogmaDashId}) => {
                if(!dogmaDashId) {return false;}
                return getDogmaMrnaSlotIds(state, dogmaDashId).some(s => getObjectsInOrganelle(state, s).filter(o => getObjectType(state, o) === Structural).length > 0);
            },
        },
        {
            condition: (state, {location, protein}) => location === MRNA_INVENTORY_PROTEIN_TUTORIAL && protein === Structural,
            header: "Send mRNA",
            text: "The Structural Protein is needed to fix the microtubule damage.",
            callToAction: "Send the mRNA to the pilot",
            completionCondition: (state, {dogmaDashId}) => {
                return getDogmaMrnaSlotIds(state, dogmaDashId).some(s => getObjectsInOrganelle(state, s).filter(o => getObjectType(state, o) === Structural).length > 0);
            },
            width: 460,
        },
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== CYTOPLASM_TUTORIAL) {return false;}
                const cyto = getDogmaDashCytoplasm(state, dogmaDashId);
                return !!cyto && proteinTypeAtOrganelle(state, cyto, Structural) && proteinFormAtOrganelle(state, cyto, ProteinForm_ProteinInVesicle);
            },
            header: "Deliver the Structural Protein",
            text: "The broken <b>microtubules</b> are out of reach of the Pilot. You need to deliver the Structural Protein.",
            callToAction: "Drag and drop the protein to the microtubule slot",
            completionCondition: (state, {dogmaDashId}) => {
                if(!dogmaDashId) { return false; }
                return getDogmaMicrotubuleIds(state, dogmaDashId).some(m => getObjectsInOrganelle(state, m).some(o => getObjectType(state, o) === Structural));
            },
            width: 460,
        },
    ],
};
