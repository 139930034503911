import {NANO, VISCERAL} from "../../constants/curriculumTypes";
import visceralTheme from "./visceral";
import nanoTheme from './nano';

export const getTheme = curriculumType =>
    curriculumType === VISCERAL
    ? visceralTheme
    : curriculumType === NANO
    ? nanoTheme
    : {};

export const primaryColor = theme => theme?.primaryColor || "#fff";
export const secondaryColor = theme => theme?.secondaryColor || "#000";

export const bodyFontFamily = theme => theme?.fonts?.body?.family || "Avenir Next, sans-serif";
export const bodyFontWeight = theme => theme?.fonts?.body?.weight || 400;
export const bodyFontColor = theme => theme?.fonts?.body?.color || "#000";

export const bodyHeader1FontFamily = theme => theme?.fonts?.bodyHeader1?.family || "Avenir Next, sans-serif";
export const bodyHeader1FontWeight = theme => theme?.fonts?.bodyHeader1?.weight || 400;
export const bodyHeader1FontColor = theme => theme?.fonts?.bodyHeader1?.color || "#000";
export const bodyHeader1FontSize = theme => theme?.fonts?.bodyHeader1?.size || "4.75rem";

export const bodyHeader2FontFamily = theme => theme?.fonts?.bodyHeader2?.family || "Avenir Next, sans-serif";
export const bodyHeader2FontWeight = theme => theme?.fonts?.bodyHeader2?.weight || 400;
export const bodyHeader2FontColor = theme => theme?.fonts?.bodyHeader2?.color || "#000";
export const bodyHeader2FontSize = theme => theme?.fonts?.bodyHeader2?.size || "3rem";

export const bodyHeader3FontFamily = theme => theme?.fonts?.bodyHeader3?.family || "Avenir Next, sans-serif";
export const bodyHeader3FontWeight = theme => theme?.fonts?.bodyHeader3?.weight || 400;
export const bodyHeader3FontColor = theme => theme?.fonts?.bodyHeader3?.color || "#000";
export const bodyHeader3FontSize = theme => theme?.fonts?.bodyHeader3?.size || "2rem";

export const titleFontFamily = theme => theme?.fonts?.title?.family || "Avenir Next, sans-serif"
export const titleFontWeight = theme => theme?.fonts?.title?.weight || 300;
export const titleFontColor = theme => theme?.fonts?.title?.color || "#329044";
export const titleFontSize = theme => theme?.fonts?.title?.size || "2em";
export const titleFontTextTransform = theme => theme?.fonts?.title?.textTransform || "uppercase";

export const userNameFontFamily = theme => theme?.fonts?.userName?.family || "Avenir Next, sans-serif"
export const userNameFontWeight = theme => theme?.fonts?.userName?.weight || 400;
export const userNameFontColor = theme => theme?.fonts?.userName?.color || "#999";
export const userNameFontSize = theme => theme?.fonts?.userName?.size || "1em";

export const headerButtonsFontFamily = theme => theme?.fonts?.headerButtons?.family || "Avenir Next, sans-serif"
export const headerButtonsFontWeight = theme => theme?.fonts?.headerButtons?.weight || 400;
export const headerButtonsFontColor = theme => theme?.fonts?.headerButtons?.color || "#fff";
export const headerButtonsFontSize = theme => theme?.fonts?.headerButtons?.size || "1em";

export const logoutButtonFontFamily = theme => theme?.fonts?.logoutButton?.family || "Avenir Next, sans-serif"
export const logoutButtonFontWeight = theme => theme?.fonts?.logoutButton?.weight || 400;
export const logoutButtonFontColor = theme => theme?.fonts?.logoutButton?.color || "#fff";
export const logoutButtonFontSize = theme => theme?.fonts?.logoutButton?.size || "1em";

export const headerBackground = theme => theme?.header?.background || "#fff";
export const headerBorderColor = theme => theme?.header?.borderColor || "transparent";
export const headerPadding = theme => theme?.header?.padding || "";
export const headerButtonBackground = theme => theme?.header?.buttonBackground || "#6cc947";
export const headerButtonBorder = theme => theme?.header?.buttonBorder || "transparent";
export const logoutBackground = theme => theme?.primaryColor || "#329044"

export const bodyBackground = theme => theme?.body?.background || "#fff";

export const continueButtonColor = theme => theme?.continueButton?.color || "#fff";
export const continueButtonBackground = theme => theme?.continueButton?.background || "#d7ffb7";
export const continueButtonBorderColor = theme => theme?.continueButton?.borderColor || "#d7ffb7";

export const explainerWidgetPrimaryColor = theme => theme?.widget?.explainer?.primaryColor || "#000";
export const explainerWidgetSecondaryColor = theme => theme?.widget?.explainer?.primaryColor || "#333";
