import * as styles from './explainer-styles';
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getExplainerQuestion} from "../../../../../store/curriculumTaskItems/explainer/selectors";
import {setExplainerQuestion} from "../../../../../store/curriculumTaskItems/explainer/actions";
import {useParams} from "@reach/router";
import RichEditor from "../../../../../RichText/RichEditor";

export default ({id}) => {
    const {curriculumId} = useParams();
    const question = useSelector(state => getExplainerQuestion(state, id));
    const dispatch = useDispatch();

    const setQuestion = newText => dispatch(setExplainerQuestion(curriculumId, id, newText));

    return (
        <div css={styles.explainerContainer}>
            <h1 css={styles.title}>Explanation Builder</h1>
            <label css={styles.questionLabel}>Question:</label>
            <div css={styles.questionInput}>
            <RichEditor content={question} setContent={setQuestion} id={id} />
            </div>
        </div>
    );
};
