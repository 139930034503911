// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/correct.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/incorrect.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".learner-curriculum-task--multiple-choice-input{width:100%}.learner-multiple-choice-input--option-correctness{width:15px;height:15px;display:inline-block;vertical-align:top;margin:3px 0 0 3px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%}.learner-multiple-choice-input--option-correctness__incorrect{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}", ""]);
// Exports
module.exports = exports;
