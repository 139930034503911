import React, {useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {requestLtiPlatformRegistration} from "../store/lti/actions";
import {getLtiRegistrationErrors, getLtiRegistrationStatus} from "../store/lti/selectors";
import {PENDING_REQUEST, SUCCESS} from "../store/lti/reducer";

const AddLtiProvider = () => {
    const status = useSelector(getLtiRegistrationStatus);
    const errors = useSelector(getLtiRegistrationErrors);
    const platformNameRef = useRef();
    const platformUrlRef = useRef();
    const clientIdRef = useRef();
    const authEndpointUrlRef = useRef();
    const accessTokenEndpointRef = useRef();
    const authKeyDataRef = useRef();
    const dispatch = useDispatch();

    const register = () => {
        const platformUrl = platformUrlRef.current.value;
        const clientId = clientIdRef.current.value;
        const name = platformNameRef.current.value;
        const authEndpoint = authEndpointUrlRef.current.value;
        const accessTokenEndpoint = accessTokenEndpointRef.current.value;
        const authKeyData = authKeyDataRef.current.value;
        if(!platformUrl || !clientId || !name || !authEndpoint || !accessTokenEndpoint || !authKeyData) {return;}
        dispatch(requestLtiPlatformRegistration(
            platformUrl,
            clientId,
            name,
            authEndpoint,
            accessTokenEndpoint,
            authKeyData,
        ));
    };

    return (
        <div>
            <div>
                <label>Platform name: </label>
                <input ref={platformNameRef} />
                <span>eg. Texas Allan SD Canvas</span>
            </div>
            <div>
                <label>Platform url: </label>
                <input ref={platformUrlRef}/>
                <span>eg. https://canvas.instructure.com</span>
            </div>
            <div>
                <label>LTI client id: </label>
                <input ref={clientIdRef}/>
            </div>
            <div>
                <label>Authentication Login url: </label>
                <input ref={authEndpointUrlRef}/>
                <span>eg. https://canvas.instructure.com/api/lti/authorize_redirect</span>
            </div>
            <div>
                <label>Authentication Token url: </label>
                <input ref={accessTokenEndpointRef}/>
                <span>eg. https://canvas.instructure.com/login/oauth2/token</span>
            </div>
            <div>
                <label>Authentication Key Data (key/url): </label>
                <input ref={authKeyDataRef}/>
                <span>eg. https://canvas.instructure.com/api/lti/security/jwks</span>
            </div>
            {errors.map((e, i) => <div key={i}>{e}</div>)}
            {status === PENDING_REQUEST && <div>Registering...</div>}
            {status !== SUCCESS && <button onClick={register}>Register</button>}
        </div>
    )
};

export default AddLtiProvider;
