import * as styles from './visceral-card-styles';
import React from 'react';
import {useSelector} from "react-redux";
import {
    getWorkBoardIntroduction,
    getWorkBoardTemplateItemIds
} from "../../../../store/curriculumTaskItems/workBoardDefinition/selectors";
import {getCurriculumCaseDescription, getCurriculumCaseTasks} from "../../../../store/curriculumCases/selectors";
import {useParams} from "@reach/router";
import {getCurriculumTaskItemIds, getCurriculumTaskTitle} from "../../../../store/curriculumTask/selectors";
import {getDraggablesInDropArea} from "../../../../store/interactions/selectors";
import Polaroid from "../../../../WorkBoard/Items/Polaroid";
import {getPolaroidAttributes} from "../../../../store/polaroids/selectors";
import {DISPLAY_NAME_MAP} from "../../../../constants/IdentifiableStructures";

const getTitle = (state, curriculumId, caseIndex, id) => {
    const task = getCurriculumCaseTasks(curriculumId, caseIndex, state).find(t => getCurriculumTaskItemIds(state, t).includes(id));
    return getCurriculumTaskTitle(state, task);
};
const getItemInSlot = (state, id) => {
    const slotId = getWorkBoardTemplateItemIds(id, state)[0];
    return getDraggablesInDropArea(state, slotId)[0];
};
const getLabel = (state, itemInSlot) => {
    if(!itemInSlot) { return ""; }
    const attributes = getPolaroidAttributes(state, itemInSlot);
    if(!attributes || !attributes.length) {return;}
    return attributes[0];
}

const VisceralCard = ({id, caseIndex}) => {
    const {curriculumId} = useParams();
    const question = useSelector(state => getWorkBoardIntroduction(id, state));
    const location = useSelector(state => getCurriculumCaseDescription(curriculumId, caseIndex, state));
    const itemInSlot = useSelector(state => getItemInSlot(state, id));
    const structure = useSelector(state => getLabel(state, itemInSlot));

    return (
        <div css={styles.card}>
            <div css={styles.locationContainer}>
                <span css={styles.locationDescription}>{location}</span>
            </div>
            <div css={styles.infoContainer}>
                <div css={styles.cardQuestion}>
                    {question.split('\n').map((fragment, i) =>
                        <span key={i}>{fragment}<br /></span>
                    )}
                </div>
                <div css={styles.slotContainer}>
                    <div css={styles.slot}>
                        {itemInSlot && <Polaroid id={itemInSlot} />}
                    </div>
                    <div css={styles.slotLabel}>{DISPLAY_NAME_MAP[structure]}</div>
                </div>
            </div>
        </div>
    );
};

export default VisceralCard;
