import {learnerFragment} from "./verdantLearner";
import {
    CENTRAL_DOGMA_INTERACTION,
    CENTRAL_DOGMA_WIDGET,
    CPR,
    CPR_INTERACTION, DOGMA_DASH, DOGMA_DASH_INTERACTION,
    DRAGGABLE_BANK,
    DROP_AREA_INTERACTION,
    EXPLAINER_INTERACTION,
    EXPLAINER_PREDICTION_INTERACTION,
    INPUT_TABLE,
    INPUT_TABLE_INTERACTION,
    INVENTORY_TASK_INTERACTION,
    LONG_TEXT_INPUT,
    LONG_TEXT_INPUT_INTERACTION, MRNA_ACTIVITY_INTERACTION,
    MULTIPLE_CHOICE_INPUT,
    MULTIPLE_CHOICE_INPUT_INTERACTION,
    PEA_PLANT_MATERIAL,
    SCROLLER_EXPLAINER,
    SCROLLER_EXPLAINER_PREDICTION,
    SCROLLER_SECTION,
    SCROLLER_WORK_BOARD,
    SCROLLING_DATA_ITEM,
    SCROLLING_DROP_SLOT_ITEM,
    SCROLLING_IMAGE_ITEM,
    SCROLLING_SHAPE_ITEM,
    SCROLLING_TASK,
    SCROLLING_TEXT_ITEM,
    SECTIONED_TASK, UTILITY_BUTTON,
    WORK_BOARD_CROSS_CARD,
    WORK_BOARD_CROSS_CARD_INTERACTION,
    WORK_BOARD_DEFINITION,
    WORK_BOARD_DROP_SLOT,
    WORK_BOARD_INTERACTION
} from "../../constants/TaskTypes";
import {peaPlantFragment, plantCardFragment} from "./peaPlant";
import {itemFragment} from "./workBoardItem";

export const fullCurriculaFragment = `
id
name
type
master
parentCurriculum
structure
tasks {
    __typename
    id
    title
    items {
        __typename
        id
        ... on ${LONG_TEXT_INPUT} {
            prompt
        }
        ... on ${WORK_BOARD_DEFINITION} {
            introduction
            materials {
                __typename
                ... on ${PEA_PLANT_MATERIAL} {
                    id
                    providePeas
                    provideCard
                    peaPlant {
                        ${peaPlantFragment}                                
                    }
                }
            }
            templateItems {
                __typename
                id
                x
                y
                ... on ${WORK_BOARD_DROP_SLOT} {
                    accepts
                    label
                    validationData
                }
                ... on ${WORK_BOARD_CROSS_CARD} {
                    numberOfOffspring 
                }
            }
        }
        ... on ${MULTIPLE_CHOICE_INPUT} {
            prompt
            options
            correctSelections
            multiselect
        }
        ... on ${INPUT_TABLE} {
            data
            numberOfColumns
            prompt
        }
        ... on ${DRAGGABLE_BANK} {
            prompt
            draggables {
                id
                content
            }
        }
        ... on ${CPR} {
            assessment
        }
        ... on ScrollerItem {
            locked
            draggable
            fixed
            positions {
                x
                y
                width
                height
                scroll
            }
        }
        ... on ${DOGMA_DASH} {
            maxMrnaSlots
            maxMitochondria
            maxCellMembrane
            maxLysosome
            maxGolgiBody
            maxRibosome
            maxMicrotubules
        }
        ... on ${CENTRAL_DOGMA_WIDGET} {
            initialStrand {
                dna
                mrna
                trna
            }
            mutations {
                index
                strand
                nucleotide
            }
            targetAminoAcids
        }
        ... on ${UTILITY_BUTTON} {
            behavior
            globalItemId
            buttonText
        }
        ... on ${SCROLLING_TEXT_ITEM} {
            content
        }
        ... on ${SCROLLING_IMAGE_ITEM} {
            url
        }
        ... on ${SCROLLING_SHAPE_ITEM} {
            shape
        }
        ... on ${SCROLLING_DROP_SLOT_ITEM} {
            accepts
            label
        }
        ... on ${SCROLLING_DATA_ITEM} {
            dataType
        }
        ... on ${SCROLLER_SECTION} {
            backgroundColor
        }
        ... on ${SCROLLER_WORK_BOARD} {
            workBoardDefinition {
                id
            }
        }
        ... on ${SCROLLER_EXPLAINER} {
            question
        }
        ... on ${SCROLLER_EXPLAINER_PREDICTION} {
            explainer {
                id
            }
        }
    }
    ... on ${SECTIONED_TASK} {
        textContent
    }
    ... on ${SCROLLING_TASK} {
        height
    }
}`;

export const masterCurriculaQuery = `{
    masterCurricula {
        ${fullCurriculaFragment}
    }
}`;
export const teacherMasterCurriculaQuery = `{
    masterCurricula {
        id
        name
        type
        master
    }
}`;

export const organelleFramgement = `{
    id
    timerStartTimeMs
    timerDurationMs
    timerType
    alive
    broken
    heldObjects {
        id
        type
        form
        ... on DogmaDashMotorProtein {
            movingTowards
            movementStartTimeMs
            movementDurationMs
        }
    }
    alertDescription
    target
    targetSetter
    targetStartTimeMs
    targetDurationMs
}`;

export const curriculumForLearnerQuery = `query GetCurriculum($input: CurriculumForLearnerInput!) {
    curriculumForLearner(input: $input) {
        curriculum {
            ${fullCurriculaFragment}
        }
        interactions {
            __typename
            interactable {
                id
            }
            ... on ${LONG_TEXT_INPUT_INTERACTION} {
                text
            }
            ... on ${WORK_BOARD_INTERACTION} {
                workBoard {
                    id
                    items {
                        x
                        y
                        angle
                        ${itemFragment}
                    }
                }
            }
            ... on ${MULTIPLE_CHOICE_INPUT_INTERACTION} {
                selection
            }
            ... on ${INPUT_TABLE_INTERACTION} {
                inputs
            }
            ... on ${DROP_AREA_INTERACTION} {
                draggables {
                    item {
                        id
                        __typename
                        ...on PeaPlantCard {
                            ${plantCardFragment}
                        }
                        ... on Polaroid {
                            imageUrl
                            attributes
                        }
                    }
                    metadata
                }
            }
            ... on ${CPR_INTERACTION} {
                attempts {
                    averageHandPositionX
                    averageHandPositionY
                    averageCompressionDepth
                    averageCompressionRate
                    recoilFailCount
                    recoilFailPercent
                    longestPause
                    longPauseCount
                }
            }
            ... on ${WORK_BOARD_CROSS_CARD_INTERACTION} {
                parents {
                    ${plantCardFragment}
                }
                offspring {
                    ${plantCardFragment}
                }
            }
            ... on ${EXPLAINER_INTERACTION} {
                claim
                canvases {
                    items
                    reasoning
                }
            }
            ... on ${EXPLAINER_PREDICTION_INTERACTION} {
                prediction
            }
            ... on ${INVENTORY_TASK_INTERACTION} {
                objects {
                    object {
                        objectType
                    }
                    count
                }
            }
            ... on ${MRNA_ACTIVITY_INTERACTION} {
                attachedAminoAcids {
                    object {
                        objectType
                    }
                    slot
                }
            }
            ... on ${DOGMA_DASH_INTERACTION} {
                dashMrnaSlots ${organelleFramgement}
                dashMitochondria ${organelleFramgement}
                dashCellMembrane ${organelleFramgement}
                dashLysosome ${organelleFramgement}
                dashGolgiBody ${organelleFramgement}
                dashRibosome ${organelleFramgement}
                dashMicrotubules ${organelleFramgement}
                dashCentrioles ${organelleFramgement}
                dashCytoplasm ${organelleFramgement}
                unlockedProteins
                portalNucleotide
                portalTrna
                transcriptionNucleotides {
                    given
                    paired
                }
                translationNucleotides {
                    given
                    paired
                }
                currentLevel
                currentStage
                currentLevelStageFinalLevel
                currentLevelStartTimeMs
                currentLevelDurationMs
                currentLevelCargoAlertThreshold
                currentLevelViralAlertThreshold
                levelUnlockingProtein
                unlockedProteins
                levelScores {
                    level
                    stage
                    score
                    stars
                    deliveries
                }
            }
            ... on ${CENTRAL_DOGMA_INTERACTION} {
                strand {
                    dna
                    mrna
                    trna
                }
            }
        }
    }
}`;
export const curriculumForLearnerVariables = (curriculumId, learnerId) => ({
    input: {
        curriculum: curriculumId,
        learner: learnerId,
    },
});

export const createCurriculumQuery = `mutation CreateCurriculum($input: CreateCurriculumInput!) {
    createCurriculum(input: $input) {
        curriculum {
            id
        }
    }
}`;
export const createCurriculumVariables = (curriculumId, name) => ({
    input: {
        id: curriculumId,
        name
    },
});

export const updateLongTextInputContentQuery = `mutation UpdateLongTextInputContent($input: UpdateLongTextInputContentInput!) {
    updateLongTextInputContent(input: $input) {
        longTextInput {
            id
        }
    }
}`;
export const updateLongTextInputContentVariables = (curriculumId, longTextInputId, promptText) => ({
    input: {
        curriculum: curriculumId,
        longTextInput: longTextInputId,
        prompt: promptText,
    },
});

export const updatePeaPlantWorkBoardMaterialQuery = `mutation UpdatePeaPlantWorkBoardMaterial($input: UpdatePeaPlantWorkBoardMaterialInput!) {
    updatePeaPlantWorkBoardMaterial(input: $input) {
        peaPlantMaterial {
            id
        }
    }
}`;
export const updatePeaPlantWorkBoardMaterialVariables = (materialId, curriculumId, peaPlantInput, providePeas, provideCard) => ({
    input: {
        material: materialId,
        curriculum: curriculumId,
        peaPlant: peaPlantInput,
        providePeas,
        provideCard,
    },
});

export const updateMultipleChoiceInputContentQuery = `mutation UpdateMultipleChoiceInputContent($input: UpdateMultipleChoiceInputContentInput!) {
    updateMultipleChoiceInputContent(input: $input) {
        multipleChoiceInput {
            id
        }
    }
}`;
export const updateMultipleChoiceInputContentVariables = (curriculumId, multipleChoiceId, prompt, options, correctSelections, multiselect) => ({
    input: {
        curriculum: curriculumId,
        multipleChoiceInput: multipleChoiceId,
        prompt,
        options,
        correctSelections,
        multiselect,
    },
});

export const updateInputTableContentQuery = `mutation UpdateInputTableContent($input: UpdateInputTableContentInput!) {
    updateInputTableContent(input: $input) {
        inputTable {
            id
        }
    }
}`;
export const updateInputTableContentVariables = (curriculumId, inputTableId, prompt, numberOfColumns, data) => ({
   input: {
        curriculum: curriculumId,
        inputTable: inputTableId,
        prompt,
        numberOfColumns,
        data,
   },
});

export const curriculaDifferenceQuery = `query CurriculaDifference($input: CurriculaDifferenceInput!) {
    curriculaDifference(input: $input) {
        changesExist
    }
}`;
export const curriculaDifferenceVariables = (baseCurriculumId, otherCurriculumId) => ({
    input: {
        baseCurriculum: baseCurriculumId,
        otherCurriculum: otherCurriculumId,
    },
});

export const resolveAllCurriculumDifferencesQuery = `mutation ResolveAllCurriculumDifferences($input: ResolveAllCurriculumDifferencesInput!) {
    resolveAllCurriculumDifferences(input: $input) {
        updated {
            ${fullCurriculaFragment}
        }
    }
}`;
export const resolveAllCurriculumDifferencesVariables = (curriculumToUpdateId, updateFromCurriculumId) => ({
    input: {
        curriculumToUpdate: curriculumToUpdateId,
        updateFrom: updateFromCurriculumId,
    },
});

export const updateDraggableBankContentQuery = `mutation UpdateDraggableBankContent($input: UpdateDraggableBankContentInput!) {
    updateDraggableBankContent(input: $input) {
        draggableBank {
            id
        }
    }
}`;
export const updateDraggableBankContentVariables = (curriculumId, draggableBankId, prompt) => ({
    input: {
        curriculum: curriculumId,
        draggableBank: draggableBankId,
        prompt
    },
});

export const addDraggableToDraggableBankQuery = `mutation AddDraggableToDraggableBank($input: AddDraggableToDraggableBankInput!) {
    addDraggableToDraggableBank(input: $input) {
        draggable {
            id
        }
    }
}`;
export const addDraggableToDraggableBankVariables = (curriculumId, draggableBankId, draggableId) => ({
    input: {
        curriculum: curriculumId,
        draggableBank: draggableBankId,
        draggable: draggableId,
    },
});

export const updateDraggableContentQuery = `mutation UpdateDraggableContent($input: UpdateDraggableContentInput!) {
    updateDraggableContent(input: $input) {
        draggable {
            id
        }
    }
}`;
export const updateDraggableContentVariables = (curriculumId, draggableId, content) => ({
    input: {
        curriculum: curriculumId,
        draggable: draggableId,
        content,
    },
});

export const updateCPRContentQuery = `mutation UpdateCPRContent($input: UpdateCPRContentInput!) {
    updateCPRContent(input: $input) {
        cpr {
            id
        }
    }
}`;
export const updateCPRContentVariables = (curriculumId, cprId, assessment) => ({
    input: {
        curriculum: curriculumId,
        cpr: cprId,
        assessment,
    },
});

// deprecated
export const assignCurriculumToLearnerQuery = `mutation AssignCurriculumToLearner($input: AssignCurriculumToLearnerInput!) {
    assignCurriculumToLearner(input: $input) {
        learner {
            ${learnerFragment}
        }
    }
}`;
export const assignCurriculumToLearnerVariables = (learnerId, curriculumId) => ({
    input: {
        learner: learnerId,
        curriculum: curriculumId,
    },
});

// deprecated
export const reassignCurriculumToLearnerQuery = `mutation ReassignCurriculumToLearner($input: AssignCurriculumToLearnerInput!) {
    reassignCurriculumToLearner(input: $input) {
        learner {
            ${learnerFragment}
        }
    }
}`;

// deprecated
export const resetCurriculaForLearnerQuery = `mutation ResetCurriculaForLearner($input: ResetCurriculaForLearnerInput!) {
    resetCurriculaForLearner(input: $input) {
        learner {
            id
        }
    }
}`;
export const resetCurriculaForLearnerVariables = learnerId => ({
    input: {
        learner: learnerId,
    },
});

// deprecated?
export const changeLearnerCurriculumProgressQuery = `mutation ChangeLearnerCurriculumProgress($input: ChangeLearnerCurriculumProgressInput!) {
    changeLearnerCurriculumProgress(input: $input) {
        learner {
            id
        }
    }
}`;
export const changeLearnerCurriculumProgressVariables = (learnerId, curriculumId, taskId, status) => ({
    input: {
        learner: learnerId,
        curriculum: curriculumId,
        curriculumItemId: taskId,
        status,
    },
});
