import {CURRICULA_LOADED} from "../../curricula/actions";
import {CENTRAL_DOGMA_INTERACTION, CENTRAL_DOGMA_WIDGET} from "../../../constants/TaskTypes";
import {INTERACTIONS_LOADED} from "../actions";
import {SET_CENTRAL_DOGMA_WIDGET_NUCLEOTIDE} from "./actions";
import {omit} from "lodash";

const defaultActivity = {
    initialStrand: [],
    strand: [],
    mutations: [],
    targetAminoAcids: [],
};
const defaultEntry = {
    dna: null,
    mrna: null,
    trna: null,
};

export default (state = {}, action) => {
    if (action.type === CURRICULA_LOADED) {
        const newItems = action.curricula.reduce((agg, c) => (c.tasks || [])
                .reduce((agg, t) => t.items.filter(i => i.__typename === CENTRAL_DOGMA_WIDGET)
                        .reduce((agg, i) => ({
                            ...agg,
                            [i.id]: {
                                ...(state[i.id] || defaultActivity),
                                initialStrand: i.initialStrand,
                                mutations: i.mutations,
                                targetAminoAcids: i.targetAminoAcids,
                            },
                        }), agg)
                    , agg)
            , {});
        return {
            ...state,
            ...newItems,
        };
    }
    if (action.type === INTERACTIONS_LOADED) {
        const loadedInteractions = action.interactions.filter(i => i.__typename === CENTRAL_DOGMA_INTERACTION).reduce((agg, i) => {
            const startStrand = state[i.interactable.id] || defaultActivity;
            return {
                ...agg,
                [i.interactable.id]: {
                    ...startStrand,
                    ...omit(i, "interactable"),
                },
            };
        }, {});
        return {
            ...state,
            ...loadedInteractions,
        };
    }
    if(action.type === SET_CENTRAL_DOGMA_WIDGET_NUCLEOTIDE) {
        const {widgetId, strandName, index, nucleotide} = action;
        const current = state[widgetId].strand;
        const updated = Array.from({length: Math.max(current.length, index+1)}, (_, i) => {
            const entry = current[i] || {...defaultEntry};
            return i !== index ? entry : {
                ...entry,
                [strandName]: nucleotide,
            };
        });

        return {
            ...state,
            [widgetId]: {
                ...state[widgetId],
                strand: updated,
            },
        };
    }

    return state;
};
