import "./add-task.scss";
import React from 'react';
import {getCurriculumTaskIds} from "../../../store/curricula/selectors";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useParams} from "@reach/router";
import {getCurriculumTaskTitle} from "../../../store/curriculumTask/selectors";
import {openModalFor} from "../../../store/navigation/actions";
import {addTaskToCurriculumCase} from "../../../store/curriculumCases/actions";
import {modalAdditionalData} from "../../../store/navigation/selectors";
import {CASE_ADD_TASK_MODAL} from "../../../constants/modalTypes";

const getTasks = curriculumId => state => getCurriculumTaskIds(state, curriculumId);
const getTaskNames = taskIds => state => taskIds.map(t => getCurriculumTaskTitle(state, t));
const getCaseIndex = curriculumId => state => modalAdditionalData(state, CASE_ADD_TASK_MODAL, curriculumId);

export default () => {
    const {curriculumId} = useParams();
    const tasks = useSelector(getTasks(curriculumId), shallowEqual);
    const taskNames = useSelector(getTaskNames(tasks), shallowEqual);
    const caseIndex = useSelector(getCaseIndex(curriculumId), shallowEqual);
    const dispatch = useDispatch();

    const close = () => dispatch(openModalFor());
    const addTaskToCase = taskId => {
        dispatch(addTaskToCurriculumCase(curriculumId, caseIndex, taskId));
        close();
    };

    return (
        <div className="admin-curriculum-case--add-task-modal__blocker">
            <div className="admin-curriculum-case--add-task-modal">
                <h2 className="case-add-task--modal-header">Choose task to add:</h2>
                <div className="case-add-task--task-container">
                    {tasks.map((t, i) => <button onClick={addTaskToCase.bind(null, t)} key={t} className="case-add-task--task">{taskNames[i]}</button> )}
                </div>
                <div className="case-add-task--button-container">
                    <button onClick={close} className="case-add-task--cancel">Cancel</button>
                </div>
            </div>
        </div>
    )
}
