import {css} from "@emotion/core";
import {
    AVENIR_FONT,
    bbPadding,
    FONT_BOLD_WEIGHT,
} from "../../../../style-variables";
import {
    continueButtonBorderColor, continueButtonColor,
    explainerWidgetPrimaryColor,
    explainerWidgetSecondaryColor
} from "../../../themes/selectors";

export const container = css({
    width: "100%",
});

export const explainerBlock = css({
    width: "100%",
    margin: "0 0 22px 0",
    position: "relative",
    transition: "width 0.3s, opacity 0.3s, margin 0.3s",
});
export const explainerBlockSmall = css({
    margin: "0 0 18px 0",
});

export const headerLabel = css({
    display: "inline",
    fontSize: "1.75em",
    fontWeight: FONT_BOLD_WEIGHT,
    textTransform: "uppercase",
    margin: "0 8px 0 0",
    transition: "font-size 0.3s",
});
export const themeHeaderLabel = theme => theme ? css(headerLabel, {
    color: explainerWidgetPrimaryColor(theme)
}) : headerLabel;
export const headerLabelSmall = css({
    fontSize: "1em",
});

export const headerText = css({
    display: "inline",
    fontSize: "1.75em",
    lineHeight: "38.25px",
    transition: "font-size 0.3s, line-height 0.3s",
});
export const headerTextSmall = css({
    fontSize: "1em",
    lineHeight: "22.5px",
});

export const inputSubtext = css({
    display: "block",
    fontSize: "1em",
    margin: "11px 0",
    maxHeight: "40px",
    transition: "font-size 0.3s, margin 0.3s, opacity 0.3s",
});
export const inputSubtextSmall = css({
    fontSize: "0.75em",
    margin: "6px 0",
});
export const inputSubtextInvisible = css({
    display: "none",
});

export const explainerTextInput = css({
    display: "block",
    width: "100%",
    height: "160px",
    border: "none",
    borderBottom: "4px solid transparent",
    backgroundColor: "white",
    fontFamily: AVENIR_FONT,
    fontSize: "1.75em",
    lineHeight: "38.25px",
    resize: "none",
    borderRadius: "8px",
    boxShadow: "0 0 0 0 rgba(0,0,0,0)",
    transition: "box-shadow 0.2s, font-size 0.3s, line-height 0.3s, padding 0.3s, height 0.3s, background-color 0.2s",
    outline: "none",

    "&:focus, &:hover": {
        boxShadow: "0 4px 4px 0 rgba(0,0,0,0.25)",
    },
}, bbPadding("22px 17px"));
export const themeExplainerTextInput = theme => theme ? css(explainerTextInput, {
    borderColor: explainerWidgetSecondaryColor(theme),
}) : explainerTextInput;
export const claimTextInputSmall = css({
    height: "115px",
    backgroundColor: "#efefef",
    fontSize: "1em",
    lineHeight: "22px",
    borderBottomColor: "transparent",
}, bbPadding("16px 12px"));

export const predictionViewer = css({
    fontSize: "1.75em",
    lineHeight: "38.25px",
});

export const explainerContainer = css({
    position: "relative",
    width: "100%",
    minHeight: "53px",
    margin: "0 0 24px 0",
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0 0 0 0 rgba(0,0,0,0)",
    transition: "box-shadow 0.25s, border-color 0.3",

    "&:hover": {
        boxShadow: "0 4px 4px 0 rgba(0,0,0,0.25)",
    },
}, bbPadding("17px"));

export const explainerContainerLabel = css({
    fontSize: "1.125em",
});

export const addEvidenceButton = css({
    width: "32px",
    height: "32px",
    backgroundColor: "#d7ffb7",
    margin: 0,
    padding: 0,
    position: "absolute",
    right: "10px",
    bottom: "11px",
    fontSize: "1.75em",
    lineHeight: "28px",
    border: "2px solid #d7ffb7",
    borderRadius: "50%",
    cursor: "pointer",
});
export const themeAddEvidenceButton = theme => theme ?
    css(addEvidenceButton, {
        backgroundColor: continueButtonBackground(theme),
        borderColor: continueButtonBorderColor(theme),
        color: continueButtonColor(theme),
    }) : addEvidenceButton;

export const reasoningContainer = css({
    display: "block",
    width: "100%",
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0 0 0 0 rgba(0,0,0,0)",
    transition: "box-shadow 0.25s, opacity 0.3s",
    overflow: "hidden",

    "&:focus-within, &:hover": {
        boxShadow: "0 4px 4px 0 rgba(0,0,0,0.25)",
    },
}, bbPadding("11px"));
export const themedReasoningContainer = theme => theme ? css(reasoningContainer, {
    borderBottom: `4px solid ${explainerWidgetPrimaryColor(theme)}`,
}) : reasoningContainer;
export const reasoningInputSmall = css({
    height: "53px",
});

export const faded = css({
    opacity: 0.25,
});

export const reasoningInput = css({
    display: "block",
    width: "100%",
    height: "120px",
    border: "none",
    margin: "8px 0 0 0",
    fontFamily: AVENIR_FONT,
    fontSize: "1.5em",
    lineHeight: "33px",
    resize: "none",

    "&:disabled": {
        background: "white",
    },
});
