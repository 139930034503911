import "./learner.scss";

import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {attemptLogin} from "../utility";
import {setAuthenticationState, setLTIKey} from "../../store/identity/actions";
import {navigate} from "@reach/router";
import pinMap from "./students.json";
import {LEARNER, LOGGED_IN} from "../../constants/AuthenticationState";

export default () => {
    const pinRef = useRef();
    const [loggingIn, setLoggingIn] = useState(false);
    const [validationError, setValidationError] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        if(!pinRef.current) {return;}
        pinRef.current.focus()
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const ltik = searchParams.get('ltik');
        const requestedCurriculum = searchParams.get("curriculum_id");
        if(!ltik) { return; }
        dispatch(setLTIKey(ltik, LEARNER));
        const newPath = requestedCurriculum
            ? `/learn/${requestedCurriculum}`
            : "/learn";
        navigate(newPath, {replace: true});
    }, []);

    const startLogin = async () => {
        const pin = pinRef.current.value;

        if(!pin) {
            return setValidationError("PIN is required");
        }
        const user = pinMap.loginData.find(u => u.pin === pin);
        if(!user) {
            return setValidationError("Invalid PIN");
        }
        setLoggingIn(true);
        const result = await attemptLogin(user.username, user.password);
        if(result.logInSuccess) {
            dispatch(setAuthenticationState(LOGGED_IN, LEARNER));
            navigate("/learn");
        } else {
            setValidationError("Invalid PIN - unable to login");
        }
        setLoggingIn(false);
    };

    const submitOnEnter = e => e.which === 13 && startLogin();

    return (
        <div className="learner-login--container">
            <div className="learner-login--logo" />
            <h2 className="learner-login--header">Student Login</h2>
            <div className="learner-login--validation-error">{validationError && <span>{validationError}</span>}</div>
            <div className="learner-login--input-container">
                <label className="input-container--label" htmlFor="pin">PIN</label>
                <input ref={pinRef} id="pin" className="input-container--input" type="text" disabled={loggingIn} onKeyPress={submitOnEnter} />
            </div>
            <button form="loginForm" className="learner-login--login-button" onClick={startLogin} disabled={loggingIn}>{
                loggingIn
                    ? <div className="load-spinner" />
                    : <span>Login</span>
            }</button>
        </div>
    )
}
