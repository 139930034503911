import {takeEvery, call} from 'redux-saga/effects';
import {sendGraphQLSaga} from "../../DataService";
import {SET_WORK_BOARD_INSTRUCTIONS} from "../../../store/curriculumTaskItems/workBoardDefinition/actions";

const updateWorkBoardDefinitionContent = `mutation UpdateWorkBoardDefinitionContent($input: UpdateWorkBoardDefinitionContentInput!) {
    updateWorkBoardDefinitionContent(input: $input) {
        workBoardDefinition {
            id
        }
    }
}`;

function* execute(action) {
    const variables = {
        input: {
            curriculum: action.curriculumId,
            workBoardDefinition: action.workBoardDefinitionId,
            introduction: action.instructions,
        },
    };

    yield call(sendGraphQLSaga, updateWorkBoardDefinitionContent, variables);
}

export default [
    takeEvery(SET_WORK_BOARD_INSTRUCTIONS, execute),
];
