import {SET_WORK_BOARD_USES_TEMPLATE} from "../curriculumTaskItems/workBoardDefinition/actions";
import {omit} from 'lodash';
import {
    ADD_ITEM_TO_WORK_BOARD_TEMPLATE,
    MOVE_WORK_BOARD_TEMPLATE_ITEM,
    REMOVE_ITEM_FROM_WORK_BOARD_TEMPLATE
} from "./actions";
import {CURRICULA_LOADED} from "../curricula/actions";
import {WORK_BOARD_DEFINITION} from "../../constants/TaskTypes";
import rearrangeArray from "../../Utility/rearrangeArray";

const defaultItem = {
    id: "",
    type: null,
}

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const newTemplates = action.curricula.reduce((agg, c) => ({
            ...agg,
            ...(c.tasks || []).reduce((taskAgg, t) => ({
                ...taskAgg,
                ...t.items.filter(i => i.__typename === WORK_BOARD_DEFINITION && i.templateItems.length > 0).reduce((tAgg, i) => ({
                    ...tAgg,
                    [i.id]: i.templateItems.map(t => ({
                        ...defaultItem,
                        id: t.id,
                        type: t.__typename,
                    })),
                }), {}),
            }), {}),
        }), {});
        return {
            ...state,
            ...newTemplates,
        };
    }
    if(action.type === SET_WORK_BOARD_USES_TEMPLATE) {
        if(action.useTemplate) {
            return {
                ...state,
                [action.workBoardDefinitionId]: []
            }
        } else {
            return omit(state, action.workBoardDefinitionId);
        }
    }
    if(action.type === ADD_ITEM_TO_WORK_BOARD_TEMPLATE) {
        return {
            ...state,
            [action.workBoardDefinitionId]: state[action.workBoardDefinitionId].concat({
                ...defaultItem,
                id: action.itemId,
                type: action.itemType,
            }),
        };
    }
    if(action.type === REMOVE_ITEM_FROM_WORK_BOARD_TEMPLATE) {
        return {
            ...state,
            [action.workBoardDefinitionId]: state[action.workBoardDefinitionId].filter(i => i.id !== action.itemId),
        };
    }
    if(action.type === MOVE_WORK_BOARD_TEMPLATE_ITEM) {
        const start = state[action.workBoardDefinitionId];
        const item = start.find(i => i.id === action.itemId);
        return {
            ...state,
            [action.workBoardDefinitionId]: rearrangeArray(start, item, action.newIndex),
        };
    }
    return state;
}
