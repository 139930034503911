import "./long-text-input.scss";
import React from 'react';
import {useSelector, shallowEqual, useDispatch} from "react-redux";
import RichTextDisplay from "../RichTextDisplay";
import {getLongTextInputText} from "../../../store/interactions/selectors";
import {setLongTextInputText} from "../../../store/interactions/actions";
import {useParams} from "@reach/router";
import {getLongTextInputPrompt} from "../../../store/curriculumTaskItems/longTextInput/selectors";

export default ({id}) => {
    const prompt = useSelector(state => getLongTextInputPrompt(state, id));
    const text = useSelector(state => getLongTextInputText(state, id));
    const dispatch = useDispatch();
    const {curriculumId} = useParams();

    const updateAnswer = e => {
        if(e.target.value === text) { return; }
        dispatch(setLongTextInputText(curriculumId, id, e.target.value));
    };

    return (
        <div className="learner-curriculum-task--long-text-input">
            <div className="learner-long-text-input--prompt">
                <RichTextDisplay content={prompt} />
            </div>
            <textarea className="learner-long-text-input--input" defaultValue={text} onBlur={updateAnswer} />
        </div>
    )
}
