import React, {useEffect, useState} from 'react';
import classNames from 'class-names';
import {useSelector, shallowEqual} from "react-redux";
import {
    getEmailForLearner,
    getFamilyNameForLearner,
    getGivenNameForLearner,
    getVRStartTimeForLearner
} from "../../store/learners/selectors";
import {getActiveLearner} from "../../store/navigation/selectors";
import {getPINForEmail} from "../../Identity/utility";
import {useNavigate, useParams} from "@reach/router";

export default ({id}) => {
    const email = useSelector(state => getEmailForLearner(state, id));
    const givenName = useSelector(state => getGivenNameForLearner(state, id));
    const familyName = getFamilyNameForLearner(state => getFamilyNameForLearner(state, id));
    // const vrStartTime = getVRStartTimeForLearner(state => getVRStartTimeForLearner(state, id));
    const navigate = useNavigate();
    const {cohortId, curriculumId, taskId, learnerId} = useParams();
    const selected = learnerId === id;
    const [updateTime, updateTimer] = useState(0);
    useEffect(() => {
        let timeout;
        const count = () => {
            updateTimer(updateTime + 1);
            timeout = setTimeout(count, 1000);
        };
        timeout = setTimeout(count, 1000);
        return () => clearTimeout(timeout);
    });

    const nameDisplay = givenName ? `${givenName} ${familyName}` : email;
    const pin = getPINForEmail(email) || "";

    // const now = Date.now();
    // const minutes = vrStartTime ? Math.floor((now - vrStartTime)/60000) : null;
    // const seconds = vrStartTime ? Math.floor(((now - vrStartTime)%60000)/1000) : null;
    // const timeDisplay = vrStartTime
    //     ? `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
    //     : null;

    const select = () => {
        let navigateLink = `/teach/cohort/${cohortId}/learner/${id}`;
        if(curriculumId) {
            navigateLink = `${navigateLink}/${curriculumId}`;
        }
        if(taskId) {
            navigateLink = `${navigateLink}/${taskId}`;
        }
        navigate(navigateLink);
    };

    return (
        <button onClick={select} className={classNames({
            "learner-navigation--learner": true,
            "learner-navigation--learner__selected": selected,
            // "learner-navigation--learner__in-vr": !!vrStartTime,
            // "learner-navigation--learner__over-time": minutes && minutes >= 20,
        })}>
            <div className="learner--name">{nameDisplay}</div>
            <div className="learner--pin">{`pin: ${pin}`}</div>
            {/*<div className="learner--vr-time">{vrStartTime ? `VR timer: ${timeDisplay}` : ''}</div>*/}
        </button>
    )
}
