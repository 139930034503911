import {css} from "@emotion/core";
import {bbPadding, DROP_SLOT_HOVERED_HIGHLIGHT, DROP_SLOT_SHADOW} from "../../style-variables";

export const container = css({
    textAlign: "center",
});

export const dropSlot = css({
    display: 'inline-block',
    backgroundColor: "white",
    border: "1px solid #777",
    borderRadius: 2,
    transition: "border-color 0.2s, background-color 0.2s",

    "&:empty": {
        boxShadow: DROP_SLOT_SHADOW,
        borderColor: "#c4c4c4",
    },
});
export const dropSlotHovered = css({
    backgroundColor: DROP_SLOT_HOVERED_HIGHLIGHT,
});

export const label = css({
    margin: "4px 0 0 0",
    fontSize: "0.875em",
    whiteSpace: "nowrap",
    color: "#333",
}, bbPadding("0 4px"));
