import {css} from "@emotion/core";
import {bbPadding, DROP_SLOT_SHADOW, FONT_BOLD_WEIGHT, inlineBlockTop} from "../../../../style-variables";
import trash from '../../../../assets/trash.svg';

export const cardBadgeClassName = "visceral-card-badge";

export const cardWrapper = css(inlineBlockTop, {
    position: "relative",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
    backgroundColor: "#f7fff1",
    transition: "background-color 0.2s",
    cursor: "pointer",
    marginRight: "12px",
    marginTop: "12px",
    borderRadius: "12px",
    overflow: "hidden",

    [`&:hover .${cardBadgeClassName}`]: {
        opacity: 1,
    },
});
export const cardWrapperSelected = css({
    backgroundColor: "#f1f9ff",
});

const cardBadge = css({
    position: "absolute",
    top: "12px",
    right: "12px",
    opacity: 0,
    transition: "opacity 0.2s",
    textAlign: "center",
    boxShadow: "2px 2px 6px 0 #d3d7da, -2px -2px 6px 0 #ffffff"
});
export const usedAsEvidence = css(cardBadge, {
    width: "114px",
    height: "24px",
    backgroundColor: "#EAF3FB",
    borderRadius: "12px",
    color: "#1D609C",
    fontSize: "0.875em",
    lineHeight: "24px",
    opacity: 1
});
export const addAsEvidence = css(cardBadge, {
    width: "114px",
    height: "24px",
    backgroundColor: "#EAFBEA",
    borderRadius: "12px",
    color: "#1F9C1D",
    fontSize: "0.875em",
    lineHeight: "24px",
    opacity: 1
});

export const canvasCard = css(cardWrapper, cardWrapperSelected, {
});

export const card = css({
    verticalAlign: "top",
    width: "540px",
}, bbPadding("12px"));

export const infoContainer = css({
    display: "flex",
    flexDirection: "row",
});

export const cardTitle = css({
    fontSize: "1.125em",
    fontWeight: FONT_BOLD_WEIGHT,
    padding: 0,
    margin: "0 0 12px 0",
});

export const locationContainer = css({
    margin: "0 0 12px 0",
    fontSize: "0.875em",
    fontWeight: FONT_BOLD_WEIGHT
});

export const locationLabel = css({
    margin: "0 4px 0 0",
    textTransform: "uppercase",
});

export const locationDescription = css({
    fontSize: "1.125em",
});

export const cardQuestion = css({
    flex: "1 1 auto",
    fontSize: "0.875em",
    lineHeight: "1.25em",
});

export const slotContainer = css({
    flex: "0 1 auto",
    margin: "0 0 0 5px",
});

export const slot = css({
    width: "309px",
    height: "190px",
    background: "white",

    "&:empty": {
        boxShadow: DROP_SLOT_SHADOW,
    },
});

export const slotLabel = css({
    fontSize: "0.875em",
});

export const removeButton = css(cardBadge, {
    width: "20px",
    height: "20px",
    backgroundColor: "#FFAAAA",
    backgroundImage: `url(${trash})`,
    backgroundSize: "15px 15px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    border: "none",
    borderRadius: "50%",
    cursor: "pointer",
});
