import {useDrag} from "react-dnd";
import React from "react";
import {getTraitLetter} from "./utility";

export const DRAGGABLE_ALLELE = "Draggable Allele";
export default props => {
    const [_, dragRef] = useDrag({
        item: { type: DRAGGABLE_ALLELE, alleleValue: props.alleleValue },
    });

    const alleleValue = props.alleleValue === 0 ? "dominant" : "recessive";
    const alleleClass = `punnett-square-allele--${props.trait}__${alleleValue}`;

    return (
        <div ref={dragRef} className={`punnett-square--header-allele ${alleleClass}`}>
            <div className="punnett-square-allele--trait-letter">{getTraitLetter(props.trait, props.alleleValue)}</div>
        </div>
    )
};
