import {
    ADD_ITEM_TO_BOARD_ACTION,
    CLONE_PLANT_CARD_AS_PHENOTYPE,
    CLONE_WORK_BOARD_ITEM,
    ITEM_ADDED_TO_BOARD_ACTION,
    ITEM_REMOVED_FROM_BOARD,
    SET_ALL_BOARDS_ACTION,
    SET_ITEM_POSITION_ACTION,
    WORK_BOARD_ITEMS_LOADED
} from "./actions";
import {LEARNER_WORK_BOARDS_LOADED} from "../learners/actions";
import {omit, unionBy} from 'lodash';
import {SET_AUTHENTICATION_STATE} from "../identity/actions";
import {NOT_LOGGED_IN} from "../../constants/AuthenticationState";
import {
    CREATE_WORK_BOARD_FOR_DEFINITION,
    DRAGGABLE_PLACED_IN_DROP_AREA,
    PLACE_PLANT_IN_CROSS,
    REMOVE_WORK_BOARD_FOR_DEFINITION
} from "../interactions/actions";
import rearrangeArray from "../../Utility/rearrangeArray";
import {REMOVE_LEARNER_ITEM} from "../learnerItems/actions";

const workBoardPrototype = {
    title: '',
    template: '',
    items: []
};

export default function(state = {}, action) {
    if(action.type === SET_ALL_BOARDS_ACTION || action.type === LEARNER_WORK_BOARDS_LOADED) {
        const boards = action.allBoards || action.workBoards;
        return boards.reduce((agg, wb) => ({
            ...agg,
            [wb.id]: {
                title: wb.title,
                items: wb.items.map(i => i.item.id),
            },
        }), {});
    }
    if(action.type === CREATE_WORK_BOARD_FOR_DEFINITION) {
        return {
            ...state,
            [action.workBoardId]: {
                ...workBoardPrototype,
                id: action.workBoardId,
            }
        }
    }
    if(action.type === REMOVE_WORK_BOARD_FOR_DEFINITION) {
        return omit(state, action.workBoardId);
    }
    if(action.type === SET_ITEM_POSITION_ACTION /*|| action.type === ITEM_MOVED_ON_WORKBOARD*/) { // todo
        const currentOrder =  state[action.boardId].items;
        const updatedOrder = rearrangeArray(currentOrder, action.itemId, action.newIndex);
        return {
            ...state,
            [action.boardId]: {
                ...state[action.boardId],
                items: updatedOrder,
            }
        };
    }
    // TODO: this section can probably go away eventually
    if(action.type === ADD_ITEM_TO_BOARD_ACTION || action.type === ITEM_ADDED_TO_BOARD_ACTION) {
        return {
            ...state,
            [action.boardId]: {
                ...state[action.boardId],
                items: state[action.boardId].items.concat(action.item.item),
            },
        };
    }
    if(action.type === CLONE_WORK_BOARD_ITEM && action.index >= 0) {
        const updatedOrder = rearrangeArray(state[action.newWorkBoardId].items, action.newId, action.index);
        return {
            ...state,
            [action.newWorkBoardId]: {
                ...state[action.newWorkBoardId],
                items: updatedOrder,
            },
        };
    }
    if(action.type === CLONE_PLANT_CARD_AS_PHENOTYPE) {
        if(action.index < 0) { return state; }
        const currentOrder = state[action.workBoardId].items;
        const updatedOrder = rearrangeArray(currentOrder, action.newId, action.index);
        return {
            ...state,
            [action.workBoardId]: {
                ...state[action.workBoardId],
                items: updatedOrder
            },
        };
    }
    if(action.type === ITEM_REMOVED_FROM_BOARD) {
        return {
            ...state,
            [action.boardId]: {
                ...state[action.boardId],
                items: state[action.boardId].items.filter(i => i !== action.itemId),
            },
        };
    }
    if(action.type === REMOVE_LEARNER_ITEM && state[action.currentLocation]) {
        return {
            ...state,
            [action.currentLocation]: {
                ...state[action.currentLocation],
                items: state[action.currentLocation].items.filter(i => i !== action.itemId),
            },
        };
    }
    if(action.startingLocation && (action.type === DRAGGABLE_PLACED_IN_DROP_AREA || action.type === PLACE_PLANT_IN_CROSS)) {
        const itemId = action.plantCardId || action.draggableId;
        if(!state[action.startingLocation]) { return state; }
        return {
            ...state,
            [action.startingLocation]: {
                ...state[action.startingLocation],
                items: state[action.startingLocation].items.filter(i => i !== itemId),
            },
        };
    }
    if(action.type === WORK_BOARD_ITEMS_LOADED) {
        const newItems = action.items.map(i => i.item.id);
        return {
            ...state,
            [action.boardId]: {
                ...state[action.boardId],
                items: unionBy(newItems, state[action.boardId].items),
            },
        };
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    return state;
}
