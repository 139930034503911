import {
    getDogmaCellMembraneIds,
    getDogmaDashAvailableProteins,
    getDogmaDashCurrentView,
    getDogmaDashLevelUnlockingProtein,
    getOrganelleTimerStart,
    getOrganelleTimerType
} from "../../interactions/dogmaDash/selectors";
import {DOGMA_DASH_ALERT_LIST, DOGMA_DASH_MRNA_INVENTORY, DOGMA_DASH_NUCLEUS} from "./dogma-strand-1";
import {CELL_VIEW, Motor, Transport} from "../../../constants/DogmaDash";
import {mrnaInSlot} from "./utility";
import {MRNA_INVENTORY_PROTEIN_TUTORIAL} from "./dogma-strand-antibody-unlock";

export default {
    id: "bbfb57ae-9af7-4ee3-a8a8-e6cd8f3df47a",
    items: [
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== DOGMA_DASH_ALERT_LIST) { return false; }
                const membraneAlert = getDogmaCellMembraneIds(state, dogmaDashId).some(m => getOrganelleTimerStart(state, m) && getOrganelleTimerType(state, m) === "Alert");
                return membraneAlert && getDogmaDashLevelUnlockingProtein(state, dogmaDashId) === Transport;
            },
            header: "New Alert Type",
            text: "The cell membrane needs a protein that <b>lets materials pass in and out of the cell</b>.",
            forceCompletion: (state, {dogmaDashId}) => getDogmaDashCurrentView(state, dogmaDashId) !== CELL_VIEW
                || getDogmaDashAvailableProteins(state, dogmaDashId).indexOf(Transport) >= 0,
        },
        {
            condition: (state, {location}) => location === DOGMA_DASH_NUCLEUS,
            header: "Enter the Nucleus",
            callToAction: "Click on the nucleus to get access to the cell’s DNA",
            completionCondition: (state, {dogmaDashId}) => getDogmaDashCurrentView(state, dogmaDashId) !== CELL_VIEW
                || getDogmaDashAvailableProteins(state, dogmaDashId).indexOf(Transport) >= 0,
        },
        {
            condition: (state, {location, dogmaDashId, protein}) => location === MRNA_INVENTORY_PROTEIN_TUTORIAL
                && protein === Transport
                && getDogmaDashAvailableProteins(state, dogmaDashId).indexOf(Transport) >= 0,
            width: 460,
            header: "Channel Protein mRNA",
            text: "You have unlocked a new mRNA! You can drop the mRNA to the pilot whenever membrane passages get blocked.",
            forceCompletion: (state, {dogmaDashId}) => mrnaInSlot(state, dogmaDashId, Motor),
        },
        {
            condition: (state, {location, protein}) => location === MRNA_INVENTORY_PROTEIN_TUTORIAL && protein === Motor,
            width: 460,
            header: "Delivery of Channel Proteins",
            text: "Channel proteins are lodged into the cell membrane. The pilot needs a delivery protein to carry the channel all the way out. ",
            callToAction: "Send the Pilot a Delivery Protein.",
            completionCondition: (state, {dogmaDashId}) => mrnaInSlot(state, dogmaDashId, Motor),
        },
    ],
};
