import * as styles from "./preview-style";
import {header as headerStyle} from "../../style-variables";
import React, {useEffect} from 'react';
import {Link, useParams} from "@reach/router";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getCurriculumName, getCurriculumType} from "../../store/curricula/selectors";
import CurriculumDisplay from "../../Learn/CurriculumDisplay";
import {getCurriculumTaskIdsWithWorkBoards} from "../../store/curriculumCases/selectors";
import {getCurriculumTaskItemIds} from "../../store/curriculumTask/selectors";
import {WORK_BOARD_DEFINITION} from "../../constants/TaskTypes";
import {getPeaPlantCardIds} from "../../store/peaPlantCards/selectors";
import {createWorkBoardForDefinition, removeWorkBoardForDefinition} from "../../store/interactions/actions";
import uuid from 'uuid/v4';
import {setItemPosition} from "../../store/workBoards/actions";
import {createPeaPlant, setPlantName} from "../../store/peaPlants/actions";
import {createPeaPlantCard} from "../../store/peaPlantCards/actions";
import {getCurriculumTaskItemType} from "../../store/curriculumTaskItems/selectors";
import {ThemeProvider} from "@emotion/react";
import {getTheme} from "../../Learn/themes/selectors";

const getFirstWorkBoard = curriculumId => state => {
    const tasksWithWorkBoards = getCurriculumTaskIdsWithWorkBoards(curriculumId, state);
    if(!tasksWithWorkBoards.length) { return null; }
    return getCurriculumTaskItemIds(state, tasksWithWorkBoards[0])
        .find(id => getCurriculumTaskItemType(state, id) === WORK_BOARD_DEFINITION);
}

export default () => {
    const {curriculumId, taskId} = useParams();
    const name = useSelector(state => getCurriculumName(state, curriculumId));
    const curriculumType = useSelector(state => getCurriculumType(state, curriculumId));
    const theme = getTheme(curriculumType);

    const backLink = taskId ? `/admin/${curriculumId}/${taskId}` : `/admin/${curriculumId}`
    return (
        <div css={styles.container}>
            <div css={styles.preview}>
                <div css={styles.adminNavigation}>
                    <Link css={styles.backLink} to={backLink}>{"<< Back"}</Link>
                </div>
                <div css={headerStyle}>
                    <h1>{name}</h1>
                </div>
                <ThemeProvider theme={theme}>
                    <CurriculumDisplay />
                </ThemeProvider>
            </div>
        </div>
    );
}

const createPreviewPeaPlantCards = dispatch => {
    const plantIds = previewPlants.map((p, i) => {
        const id = uuid();
        dispatch(createPeaPlant(id, p));
        dispatch(setPlantName(id, i+1));
        return id;
    });
    return plantIds.map(plantId => {
        const id = uuid();
        dispatch(createPeaPlantCard(id, plantId));
        return id;
    });
};

const previewPlants = [
    {
        height: { AlleleA: 1, AlleleB: 1},
        flowerColor: { AlleleA: 1, AlleleB: 1},
        flowerPosition: { AlleleA: 1, AlleleB: 1},
        seedColor: { AlleleA: 1, AlleleB: 1},
        seedShape: { AlleleA: 1, AlleleB: 1},
        podColor: { AlleleA: 1, AlleleB: 1},
        podShape: { AlleleA: 1, AlleleB: 1},
    },
    {
        height: { AlleleA: 0, AlleleB: 0},
        flowerColor: { AlleleA: 0, AlleleB: 0},
        flowerPosition: { AlleleA: 0, AlleleB: 0},
        seedColor: { AlleleA: 0, AlleleB: 0},
        seedShape: { AlleleA: 0, AlleleB: 0},
        podColor: { AlleleA: 0, AlleleB: 0},
        podShape: { AlleleA: 0, AlleleB: 0},
    },
    {
        height: { AlleleA: 1, AlleleB: 0},
        flowerColor: { AlleleA: 0, AlleleB: 1},
        flowerPosition: { AlleleA: 1, AlleleB: 0},
        seedColor: { AlleleA: 0, AlleleB: 1},
        seedShape: { AlleleA: 1, AlleleB: 0},
        podColor: { AlleleA: 0, AlleleB: 1},
        podShape: { AlleleA: 1, AlleleB: 0},
    },
    {
        height: { AlleleA: 0, AlleleB: 1},
        flowerColor: { AlleleA: 1, AlleleB: 1},
        flowerPosition: { AlleleA: 0, AlleleB: 0},
        seedColor: { AlleleA: 1, AlleleB: 0},
        seedShape: { AlleleA: 1, AlleleB: 1},
        podColor: { AlleleA: 0, AlleleB: 0},
        podShape: { AlleleA: 1, AlleleB: 1},
    },
    {
        height: { AlleleA: 1, AlleleB: 1},
        flowerColor: { AlleleA: 1, AlleleB: 0},
        flowerPosition: { AlleleA: 0, AlleleB: 1},
        seedColor: { AlleleA: 1, AlleleB: 1},
        seedShape: { AlleleA: 1, AlleleB: 1},
        podColor: { AlleleA: 1, AlleleB: 0},
        podShape: { AlleleA: 0, AlleleB: 0},
    },
];
