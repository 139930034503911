import * as styles from "./work-board-item-styles";
import React, {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useDrag} from "react-dnd";
import {
    PEA_PLANT_CARD,
    PODULATOR_RECEIPT,
    POLAROID,
    PUNNETT_SQUARE,
    STICKY_NOTE
} from "../../constants/WorkBoardItemTypes";
import Polaroid from './Polaroid';
import PeaPlantCard from './PeaPlantCard';
import StickyNote from "./StickyNote";
import PodulatorReceipt from "./PodulatorReceipt";
import PunnettSquare from "./PunnettSquare";
import {css} from "@emotion/core";
import {closeModalType, openModalFor} from "../../store/navigation/actions";
import {WORK_BOARD_ITEM_FOCUS} from "../../constants/modalTypes";
import {getLearnerItemType} from "../../store/learnerItems/selectors";
import {removeLearnerItem} from "../../store/learnerItems/actions";
import {useParams} from "@reach/router";

const TagMap = {
    [POLAROID]: Polaroid,
    [PEA_PLANT_CARD]: PeaPlantCard,
    [STICKY_NOTE]: StickyNote,
    [PODULATOR_RECEIPT]: PodulatorReceipt,
    [PUNNETT_SQUARE]: PunnettSquare,
};

const workBoardItem = React.memo(({id, scaleFactor, readOnly, currentDropArea, style}) => {
    const {curriculumId} = useParams();
    const itemType = useSelector(state => getLearnerItemType(id, state));
    const dispatch = useDispatch();
    const itemRef = useRef();
    const [{isDragging, forcePhenotype, plantCardId}, dragRef] = useDrag({
        item: {
            type: itemType,
            itemId: id,
            ref: itemRef,
            currentDropArea,
        },
        collect: monitor => {
            const collection = {
                isDragging: !!monitor.isDragging(),
            };
            if(id === PEA_PLANT_CARD) {
                collection.forcePhenotype = monitor.getItem().metadata.trait;
                collection.plantCardId = monitor.getItem().plantCardId;
            }
            return collection;
        },
        // make sure that it will be marked as dragging when it is rendered in another work board before being dropped
        isDragging: monitor => id === monitor.getItem().itemId || id === PEA_PLANT_CARD,
    });
    const setRef = r => {
        itemRef.current = r;
        if(!readOnly) {
            if(r) { r.setAttribute("draggable", true); } // TODO: ACK! would love to not need this.
            dragRef(r);
        }
    };

    const removeItem = e => {
        e.stopPropagation();
        dispatch(closeModalType(WORK_BOARD_ITEM_FOCUS));
        dispatch(removeLearnerItem(curriculumId, id, currentDropArea));
    }

    let cardStyle = css(styles.workBoardItem, style);
    if(isDragging) {
        cardStyle = css(cardStyle, {
            opacity: 0,
        });
    }

    const focus = e => {
        if(readOnly) { return; }
        e.stopPropagation();
        dispatch(closeModalType(WORK_BOARD_ITEM_FOCUS));
        dispatch(openModalFor(WORK_BOARD_ITEM_FOCUS, id));
    };

    const webAddable = itemType === PUNNETT_SQUARE || itemType === STICKY_NOTE;
    const Tag = TagMap[itemType];
    return (
        <div ref={setRef} className="work-board-item" css={cardStyle} onClick={focus}>
            {(!readOnly && webAddable) && <button css={styles.remove} onClick={removeItem} />}
            { Tag && <Tag id={plantCardId || id} scaleFactor={scaleFactor} forcePhenotype={forcePhenotype} /> }
        </div>
    );
});

workBoardItem.displayName = "workBoardItem";
export default workBoardItem;
