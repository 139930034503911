import "./task-reference.scss";
import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {EDITOR_COMPONENT_LONG_TEXT_REFERENCE} from "../editorComponents/LongTextReference";
import {getCurrentCurriculum, isModalOpenFor} from "../../store/navigation/selectors";
import {closeModalType, openModalFor} from "../../store/navigation/actions";
import {DRAGGABLE_BANK, LONG_TEXT_INPUT} from "../../constants/TaskTypes";
import {ReactEditor, useSlate} from "slate-react";
import {Range, Transforms} from "slate";
import {ADD_TASK_REFERENCE_TO_RICH_TEXT_MODAL} from "../../constants/modalTypes";
import {EDITOR_COMPONENT_DRAGGABLE_BANK_REFERENCE} from "../editorComponents/DraggableBankReference";
import uuid from 'uuid/v4';
import ItemReferenceSelector from "../../Admin/Curriculum/Utility/ItemReferenceSelector";

const interactableReferenceMap = {
    [LONG_TEXT_INPUT]: EDITOR_COMPONENT_LONG_TEXT_REFERENCE,
    [DRAGGABLE_BANK]: EDITOR_COMPONENT_DRAGGABLE_BANK_REFERENCE,
};

const allowedInteractableTypes = [LONG_TEXT_INPUT, DRAGGABLE_BANK];

export default props => {
    const {editorId} = props;
    const editor = useSlate();
    const [selection, setSelection] = useState();
    const curriculumId = useSelector(getCurrentCurriculum);
    const showTaskReferenceInput = useSelector(state => isModalOpenFor(state, ADD_TASK_REFERENCE_TO_RICH_TEXT_MODAL, editorId));
    const dispatch = useDispatch();

    const openTaskReferenceInput = e => {
        e.preventDefault();
        e.stopPropagation();
        if(!editor.selection || (editor.selection && !Range.isCollapsed(editor.selection))) { return; } // todo allow for uncollapsed ranges
        setSelection(editor.selection);
        dispatch(openModalFor(ADD_TASK_REFERENCE_TO_RICH_TEXT_MODAL, editorId));
    };
    const focusEditor = () => {
        ReactEditor.focus(editor);
        Transforms.select(editor, selection);
    };
    const closeInput = e => {
        e.stopPropagation();
        e.preventDefault();
        focusEditor();
        dispatch(closeModalType(ADD_TASK_REFERENCE_TO_RICH_TEXT_MODAL));
    };
    const insertTaskReference = ({interactableId, taskId, interactableType, eventData}) => {
        eventData.stopPropagation();
        eventData.preventDefault();
        focusEditor();
        const node = {
            type: interactableReferenceMap[interactableType],
            taskId,
            interactableId,
            children: [{text: ""}],
        };
        if(interactableType === DRAGGABLE_BANK) {
            node.inputId = uuid();
        }
        Transforms.insertNodes(editor, node);
        Transforms.move(editor, {
            distance: 1,
            unit: 'offset',
        });
        dispatch(closeModalType(ADD_TASK_REFERENCE_TO_RICH_TEXT_MODAL));
    };

    return (
        <>
            {showTaskReferenceInput && <ItemReferenceSelector curriculumId={curriculumId} modalCloser={closeInput} selectedCallback={insertTaskReference} allowedInteractableTypes={allowedInteractableTypes} />}
            <button onMouseDown={openTaskReferenceInput} className="rich-editor--style-button rich-editor--style-button__task-reference" />
        </>
    )
};
