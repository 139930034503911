import './landing.scss';

import React from 'react';
import {Link} from "@reach/router";

export default () => {
    return (
        <div className="landing-container">
            <div className="landing-container--header">
                <div className="header--logo" />
                <h1>Lighthaus Web Station</h1>
            </div>
            <div className="landing-container--logins">
                <Link to="/login/teach" className="logins--login-button">Teacher</Link>
                <Link to="/login/learn" className="logins--login-button">Student</Link>
            </div>
        </div>
    )
}
