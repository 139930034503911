export const ADD_ITEM_TO_SCROLLING_TASK = "add item to scrolling task";
export const addItemToScrollingTask = (curriculumId, taskId, itemId, itemType, x, y) => ({
    type: ADD_ITEM_TO_SCROLLING_TASK,
    curriculumId,
    taskId,
    itemId,
    itemType,
    x,
    y,
});

export const MOVE_SCROLLING_TASK_ITEM = "move scrolling task item";
export const moveScrollingTaskItem = (curriculumId, itemId, breakpointIndex, x, y) => ({
    type: MOVE_SCROLLING_TASK_ITEM,
    curriculumId,
    itemId,
    breakpointIndex,
    x,
    y,
});

export const SET_SCROLLING_TASK_ITEM_SCROLL_POSITION = "Set scrolling task item scroll position";
export const setScrollingTaskItemScrollPosition = (curriculumId, itemId, breakpointIndex, scrollPosition) => ({
    type: SET_SCROLLING_TASK_ITEM_SCROLL_POSITION,
    curriculumId,
    itemId,
    breakpointIndex,
    scrollPosition,
});

export const SET_SCROLLING_ITEM_CONTENT = "Set scrolling item content";
export const setScrollingItemContent = (curriculumId, itemId, content) => ({
    type: SET_SCROLLING_ITEM_CONTENT,
    curriculumId,
    itemId,
    content,
});

export const SET_SCROLLING_ITEM_LOCKED = "set scrolling item locked";
export const setScrollingItemLocked = (curriculumId, itemId, locked) => ({
    type: SET_SCROLLING_ITEM_LOCKED,
    curriculumId,
    itemId,
    locked,
});

export const SET_SCROLLING_ITEM_EDITING = "set scrolling item editing";
export const setScrollingItemEditing = (curriculumId, itemId, editing = false) => ({
    type: SET_SCROLLING_ITEM_EDITING,
    curriculumId,
    itemId,
    editing,
});

export const SET_SCROLLING_ITEM_SELECTED = "set scrolling item selected";
export const setScrollingItemSelected = (curriculumId, itemId, selected = false) => ({
    type: SET_SCROLLING_ITEM_SELECTED,
    curriculumId,
    itemId,
    selected,
});

export const SET_SCROLLING_ITEM_WIDTH = "set scrolling item width";
export const setScrollingItemWidth = (curriculumId, itemId, breakpointIndex, width) => ({
    type: SET_SCROLLING_ITEM_WIDTH,
    curriculumId,
    itemId,
    breakpointIndex,
    width,
});

export const SET_SCROLLING_ITEM_HEIGHT = "set scrolling item height";
export const setScrollingItemHeight = (curriculumId, itemId, breakpointIndex, height) => ({
    type: SET_SCROLLING_ITEM_HEIGHT,
    curriculumId,
    itemId,
    breakpointIndex,
    height,
});

export const SET_SCROLLING_ITEM_DRAGGABLE = "set scrolling item draggable";
export const setScrollingItemDraggable = (curriculumId, itemId, draggable) => ({
    type: SET_SCROLLING_ITEM_DRAGGABLE,
    curriculumId,
    itemId,
    draggable,
});

export const SET_SCROLLING_ITEM_FIXED = "set scrolling item fixed";
export const setScrollingItemFixed = (curriculumId, itemId, fixed) => ({
    type: SET_SCROLLING_ITEM_FIXED,
    curriculumId,
    itemId,
    fixed,
});
