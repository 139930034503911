import React, {useRef} from 'react';
import {useDrag} from "react-dnd";
import {PUNNETT_SQUARE} from "../../constants/WorkBoardItemTypes";

export default () => {
    const sizeRef = useRef();

    const [_, dragRef] = useDrag({
        item: {
            type: "WorkBoardTool",
            toolType: PUNNETT_SQUARE,
            itemId: PUNNETT_SQUARE,
            ref: sizeRef,
        },
    });
    const setRef = r => {
        sizeRef.current = r;
        dragRef(r);
    }

    return (
        <div className="tool-box--item">
            <div ref={setRef} className="tool-box--punnett-square" />
            <div className="tool-box--item-description">Punnett Square</div>
        </div>
    )
}
