import {css} from "@emotion/core";

export const container = css({
});
export const containerEditing = css({
    width: 130,
    height: 130,
    overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: "white",
});

export const typeSelector = css({
    display: 'block',
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    transition: "background-color 0.2s",

    "&:hover": {
        backgroundColor: '#c4c4c4',
    },

    "& span": {
        display: "block",
        height: "100%",
        textAlign: "left",
    },
});
