import './main.scss';

import "core-js";
import "regenerator-runtime/runtime";
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import createStore from './store/createStore';

import App from './App';
import {showResetCurriculum} from "./store/navigation/actions";
import HTML5Backend from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";

const store = createStore();
window.__store = store;
window.enableResetCurriculum = () => store.dispatch(showResetCurriculum());

render(
    (
        <Provider store={store}>
            <DndProvider backend={HTML5Backend}>
                <App />
            </DndProvider>
        </Provider>
    ),
    document.getElementById('main')
);
