import './tool-box.scss';

import React from 'react';
import StickyNote from "./StickyNote";
import PunnettSquare from "./PunnettSquare";

export default () => {
    return (
        <div className="work-board--tool-box">
            <h4>Tools</h4>
            <StickyNote />
            <PunnettSquare />
        </div>
    )
};
