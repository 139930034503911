export const NOTHING = "Nothing";
export const RUGAE = "Rugae";
export const PYLORIC_SPHINCTER = "PyloricSphincter";
export const CHEWED_FOOD = "ChewedFood";
export const GASTRIC_PIT = "GastricPit";
export const PEPSIN = "Pepsin";
export const POLYPEPTIDE = "Polypeptide";
export const PAPILLA = "Papilla";
export const CHYME = "Chyme";
export const VILLI = "Villi";
export const GLUCOSE = "Glucose";
export const AMYLASE = "Amylase";
export const LIPASE = "Lipase";
export const FATTY_ACIDS = "FattyAcids";
export const AMINO_ACID = "AminoAcid";
export const PROTEASE = "Protease";
export const MICELLES = "Micelles";
export const BILE = "Bile";
export const CELL_MEMBRANE = "CellMembrane";
export const MYOFIBRIL = "Myofibril";
export const NUCLEUS = "Nucleus";
export const MITOCHONDRIA = "Mitochondria";
export const RIBOSOME = "Ribosome";
export const INTRAMYOCELLULAR_ADIPOCYTES = "IntramyocellularAdipocytes";

export const DISPLAY_NAME_MAP = {
    [NOTHING]: "Nothing",
    [RUGAE]: "Rugae",
    [PYLORIC_SPHINCTER]: "Pyloric Sphincter",
    [CHEWED_FOOD]: "Chewed up food",
    [GASTRIC_PIT]: "Gastric Pit",
    [PEPSIN]: "Pepsin",
    [POLYPEPTIDE]: "Polypeptide",
    [PAPILLA]: "Papilla",
    [CHYME]: "Chyme",
    [VILLI]: "Villi",
    [GLUCOSE]: "Glucose",
    [AMYLASE]: "Amylase",
    [LIPASE]: "Lipase",
    [AMINO_ACID]: "Amino Acid",
    [FATTY_ACIDS]: "Fatty Acids",
    [PROTEASE]: "Protease",
    [MICELLES]: "Micelles",
    [BILE]: "Bile",
    [CELL_MEMBRANE]: "Cell membrane",
    [MYOFIBRIL]: "Myofibril",
    [NUCLEUS]: "Nucleus",
    [MITOCHONDRIA]: "Mitochondria",
    [RIBOSOME]: "Ribosome",
    [INTRAMYOCELLULAR_ADIPOCYTES]: "Intramyocellular Adipocytes",
};

export const VISCERAL_PHOTO_STRUCTURES = [
    NOTHING,
    RUGAE,
    PYLORIC_SPHINCTER,
    CHEWED_FOOD,
    GASTRIC_PIT,
    PEPSIN,
    POLYPEPTIDE,
    PAPILLA,
    CHYME,
    VILLI,
    GLUCOSE,
    AMYLASE,
    LIPASE,
    AMINO_ACID,
    FATTY_ACIDS,
    PROTEASE,
    MICELLES,
    BILE,
    CELL_MEMBRANE,
    MYOFIBRIL,
    NUCLEUS,
    MITOCHONDRIA,
    RIBOSOME,
    INTRAMYOCELLULAR_ADIPOCYTES,
];
