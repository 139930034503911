import {css} from "@emotion/core";
import {bbPadding} from "../../../../style-variables";
import {dogmashBlue} from "./dogma-dash-styles";
import dotBackground from './Assets/dot-background.png';

export const container = css({
    flex: "0 0 auto",
    width: "128px",
    position: "relative",
});

export const titleText = css({
    width: "100%",
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 400,
    fontSize: "0.9375rem",
    textTransform: "lowercase",
    textAlign: "center",
    padding: 0,
    margin: 0,
    position: "absolute",
    bottom: "calc(100% + 6px)",
    left: "50%",
    transform: "translateX(-50%)",
});

export const signalContainer = css(bbPadding("5px"), {
    width: "100%",
    height: "420px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: `2px solid ${dogmashBlue}`,
    borderRadius: "5px",
    background: `url(${dotBackground})`,
    textAlign: "center",
});

export const tutorial = css({
    width: 0,
    height: 0,
    position: "absolute",
    left: "50%",
    top: "225px",
    zIndex: 20,
});

export const proteinTutorial = css({
    position: 'absolute',
    top: "50%",
    left: "-5px",
});
