import * as styles from './inventory-styles';
import React, {useEffect} from 'react';
import {getLearnerInventoryItems} from "../../../store/interactions/learnerInventory/selectors";
import {useSelector} from "react-redux";
import {useDrag} from "react-dnd";
import {getEmptyImage} from "react-dnd-html5-backend";

export const objectTypeToStyle = {
    "BlueAminoAcid": styles.blueAminoAcid,
    "PinkAminoAcid": styles.pinkAminoAcid,
    "OrangeAminoAcid": styles.orangeAminoAcid,
    "GreenAminoAcid": styles.greenAminoAcid,
};

const getObjectType = o => o.object.objectType;

const LearnerInventory = ({id}) => {
    const inventoryObjects = useSelector(state => getLearnerInventoryItems(state, id));

    return (
        <div css={styles.container}>
            <span>Inventory</span>
            <div css={styles.objectsContainer}>
                {inventoryObjects.map((o, i) => <InventoryObject object={o} key={`${i}-${getObjectType(o)}`} />)}
            </div>
        </div>
    );
};

const InventoryObject = ({object}) => {
    const objectType = getObjectType(object);
    const [_, dragRef, preview] = useDrag({
        item: {
            type: objectType,
        },
    }, [objectType]);
    // check current activity type - if mrna then the preview thing shouldn't happen
    useEffect(() => {
        preview(getEmptyImage(), {captureDraggingState: true});
    }, []);

    return (
        <div ref={dragRef} css={styles.objectContainer}>
            { objectTypeToStyle.hasOwnProperty(objectType)
            ? <div css={objectTypeToStyle[objectType]}/>
            : <div css={styles.inventoryObject}>{objectType}</div> }
            <div css={styles.objectCount}>{object.count}</div>
        </div>
    );
}

export default LearnerInventory;
