import * as styles from './work-board-styles';
import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import WorkBoardItem from "./Items/WorkBoardItem";
import {getBoardItemIds} from "../store/workBoards/selectors";
import {PEA_PLANT_CARD, PODULATOR_RECEIPT, PUNNETT_SQUARE, STICKY_NOTE} from "../constants/WorkBoardItemTypes";
import {getWorkBoardTemplateItemIds} from "../store/curriculumTaskItems/workBoardDefinition/selectors";
import TemplateItem from './TemplateItems';
import {idsForOpenModalType} from "../store/navigation/selectors";
import {WORK_BOARD_ITEM_FOCUS} from "../constants/modalTypes";
import FocusedItem from "./Items/FocusedItem";
import {closeModalType} from "../store/navigation/actions";
import {PLANT_PHENOTYPE} from "../constants/DropItemTypes";
import {SCROLLING_DATA_ITEM} from "../constants/TaskTypes";
import {clonePlantCardIntoMode, cloneWorkBoardItem, setItemPosition} from "../store/workBoards/actions";
import uuid from 'uuid/v4';
import useDropSortable from "../Utility/useDropSortable";
import {getSlottedItemsOfTemplateItem} from "../store/interactions/selectors";
import {createStickyNote} from "../store/stickyNotes/actions";
import {createPunnettSquare} from "../store/punnettSquares/actions";
import {useParams} from "@reach/router";

const WorkBoard = React.memo(({boardId, readOnly, workBoardDefinition, scaleFactor}) => {
    scaleFactor = scaleFactor || 1;
    const {curriculumId} = useParams();
    const itemIds = useSelector(state => getBoardItemIds(state, boardId), shallowEqual);
    const templateItems = useSelector(state => getWorkBoardTemplateItemIds(workBoardDefinition, state), shallowEqual);
    const itemsOnTemplateItems = useSelector(state => templateItems.flatMap(i => getSlottedItemsOfTemplateItem(workBoardDefinition, i, state)), shallowEqual);
    const focusItem = useSelector(state => idsForOpenModalType(WORK_BOARD_ITEM_FOCUS, state)[0]);
    const dispatch = useDispatch();

    const [_, droppableArea, draggableIds] = useDropSortable({
        accept: [PEA_PLANT_CARD, STICKY_NOTE, PUNNETT_SQUARE, PODULATOR_RECEIPT, "WorkBoardTool", PLANT_PHENOTYPE],
        drop: (item, monitor, placeholderIndex) => {
            const {itemId, currentDropArea} = item;
            if(item.toolType) {
                const id = uuid();
                if(item.toolType === STICKY_NOTE) {
                    dispatch(createStickyNote(id));
                } else if(item.toolType === PUNNETT_SQUARE) {
                    dispatch(createPunnettSquare(id));
                }
                return dispatch(setItemPosition(curriculumId, boardId, id, placeholderIndex));
            }
            if(item.type === PLANT_PHENOTYPE) {
                dispatch(clonePlantCardIntoMode(item.plantCardId, uuid(), boardId, placeholderIndex, item.metadata.trait));
            } else if(itemIds.includes(itemId) || itemsOnTemplateItems.includes(itemId)) {
                dispatch(setItemPosition(curriculumId, boardId, itemId, placeholderIndex, currentDropArea));
            } else {
                dispatch(cloneWorkBoardItem(itemId, uuid(), boardId, placeholderIndex));
            }
        },
        canDrop: (item, monitor) => {
            if(item.draggableType === SCROLLING_DATA_ITEM) { return false; }
            return monitor.isOver({shallow: true});
        },
    }, itemIds);
    const setRef = r => !readOnly && droppableArea(r);

    const closeFocusItem = () => dispatch(closeModalType(WORK_BOARD_ITEM_FOCUS));
    const showFocusItem = !readOnly && focusItem && itemIds.includes(focusItem);

    return (
        <div className="work-board-container" css={styles.workBoardContainer}>
            <div ref={setRef} css={styles.workBoard} onClick={closeFocusItem}>
                {templateItems.map(id => <TemplateItem key={id} id={id} workBoardDefinition={workBoardDefinition} scaleFactor={scaleFactor} readOnly={readOnly} />)}
                {draggableIds.map((id) => <WorkBoardItem key={id} id={id} currentDropArea={boardId} scaleFactor={scaleFactor} readOnly={readOnly} />)}
                {showFocusItem && <FocusedItem boardId={boardId} id={focusItem} />}
            </div>
        </div>
    );
});

WorkBoard.displayName = "workBoard";
export default WorkBoard;
