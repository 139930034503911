import * as styles from './template-item-style';
import React from 'react';
import {useSelector} from "react-redux";
import {getWorkBoardTemplateItemType} from "../../store/workBoardTemplate/selectors";
import {WORK_BOARD_CROSS_CARD, WORK_BOARD_DROP_SLOT} from "../../constants/TaskTypes";
import DropSlot from './DropSlot';
import CrossCard from "./CrossCard";

const TagMap = {
    [WORK_BOARD_DROP_SLOT]: DropSlot,
    [WORK_BOARD_CROSS_CARD]: CrossCard,
};

export default props => {
    const {id, workBoardDefinition, scaleFactor} = props;
    const type = useSelector(state => getWorkBoardTemplateItemType(workBoardDefinition, id, state));

    const Tag = TagMap[type];
    return (
        <div css={styles.container}>
            <Tag id={id} workBoardDefinition={workBoardDefinition} scaleFactor={scaleFactor} />
        </div>
    );
}
