import "./draggable-bank-reference.scss";
import React from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useDrop} from "react-dnd";
import Draggable, {DRAGGABLE_BANK_DRAGGABLE} from "../Interactables/Draggable";
import {draggablePlacedInDropArea} from "../../../store/interactions/actions";
import {useParams} from "@reach/router";
import {getDraggablesInDropArea} from "../../../store/interactions/selectors";

const stateMap = inputId => state => ({
    currentItem: getDraggablesInDropArea(state, inputId)[0]
});

export default ({attributes, children, element}) => {
    const {inputId} = element;
    const { currentItem } = useSelector(stateMap(inputId), shallowEqual);
    const dispatch = useDispatch();
    const {curriculumId} = useParams();

    const [_, droppableArea] = useDrop({
        accept: DRAGGABLE_BANK_DRAGGABLE,
        canDrop: () => !currentItem,
        drop: (item, monitor) => {
            dispatch(draggablePlacedInDropArea(curriculumId, inputId, item.itemId, item.draggableType, null, item.metadata));
        }
    });

    return (
        <span {...attributes}>
            <span ref={droppableArea} className="learner-rich-text--draggable-bank-reference" >
                {!currentItem && <span className="draggable-bank-reference--placeholder"><em>Drop item here...</em></span>}
                {currentItem && <Draggable id={currentItem} />}
            </span>
        </span>
    );
}
