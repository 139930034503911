// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/logo.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/loading.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".teach-container{background:#fff;width:100%;height:100%;display:flex;flex-direction:column}.teach-container--header{height:50px;display:flex;flex-direction:row;align-items:center;border-bottom:1px solid #f2f2f2;box-sizing:border-box;flex:0 0 auto}.teach-container--header .header--logo{width:30px;height:30px;margin:0 0 0 12px;flex:0 0 auto}.teach-container--header h1{padding:0;margin:0 0 0 12px;font-weight:300;font-size:1.875em;flex:1 0 auto;text-transform:uppercase;color:#329044}.teach-container--header .header--identity{margin-right:20px;flex:0 0 auto}.teach-container--header .header--identity .logout-button{position:static;display:inline-block;vertical-align:middle}.teach-container--header .header--identity-name{color:#999;font-size:.875em;display:inline-block;vertical-align:middle;margin-right:10px;height:35px;padding-right:10px;line-height:35px}.teach-flex{flex:1 1 auto;height:calc(100% - 50px);overflow-y:auto}.cohorts-container--title{text-transform:uppercase;font-size:.875em;font-weight:600;padding:0;margin:8px 0 20px 10px}.cohorts-container{box-sizing:border-box;width:100%}.cohort-details{display:flex;flex-direction:row}.header--logo{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%}.load-spinner{width:60px;height:60px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:100% 100%;animation-name:spin;animation-timing-function:cubic-bezier(0.39, 0.58, 0.57, 1);animation-duration:.75s;animation-iteration-count:infinite}@keyframes spin{from{transform:rotate(0)}to{transform:rotate(360deg)}}", ""]);
// Exports
module.exports = exports;
