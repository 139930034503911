import {call, put, select, take, takeEvery} from "redux-saga/effects";
import {
    HEADSET_AUTHORIZED,
    headsetAuthorizationRevoked,
    headsetAuthorized, REVOKE_AUTHORIZATION,
    TRY_AUTHORIZE_HEADSET,
    TRY_REVOKE_AUTHORIZATION
} from "../../store/identity/actions";
import {sendGraphQLSaga} from "../DataService";
import {getHeadsetPairingKey} from "../../store/identity/selectors";
import {SELECT_ACTIVE_LEARNER} from "../../store/navigation/actions";
import headsetAuthorization from "../subscriptions/headsetAuthorization";
import {getSubscriptionClient} from "../../store/data/selectors";
import {getActiveLearner} from "../../store/navigation/selectors";

const authorizeHeadset = `mutation AuthorizeHeadset($input: AuthorizeHeadsetInput!) {
    authorizeHeadset(input: $input) {
        pairingKey
    }
}`;
function* executeAuthorize(action) {
    const {pairingKey} = action;
    const variables = {
        input: {
            pairingKey,
        },
    };
    const response = yield call(sendGraphQLSaga, authorizeHeadset, variables);

    if(response.authorizeHeadset.pairingKey !== action.pairingKey) {
        yield put(headsetAuthorizationRevoked());
        return;
    }
    yield put(headsetAuthorized(action.pairingKey));
}

const revokeAuthorizationMutation = `mutation RevokeHeadsetAuthorization($input: RevokeHeadsetAuthorizationInput!) {
    revokeHeadsetAuthorization(input: $input) {
        pairingKey
    }
}`;
function* executeRevokeAuthorization(action) {
    const pairingKey = yield select(state => getHeadsetPairingKey(state));

    const variables = {
        input: {
            pairingKey,
        },
    };

    yield call(sendGraphQLSaga, revokeAuthorizationMutation, variables);
}

const getHeadsetAuthorization = `query GetHeadsetAuthPairingKey {
    getHeadsetAuthPairingKey {
        pairingKey
    }
}`;
function* executeGetHeadsetAuthorization(action) {
    const response = yield call(sendGraphQLSaga, getHeadsetAuthorization);
    if(!response) { console.log("????????")}
    const {pairingKey} = response.getHeadsetAuthPairingKey;
    if(!pairingKey) {return;}
    yield put(headsetAuthorized(pairingKey));
}

function* listenForHeadsetAuthorizationChanges(action) {
    if(!action.pairingKey) { return; }
    const subscriptionClient = yield select(getSubscriptionClient);
    const learnerId = yield select(getActiveLearner);
    const subscriptionChannel = yield call(headsetAuthorization, subscriptionClient, action.pairingKey, learnerId);
    try {
        while(action.type !== REVOKE_AUTHORIZATION) {
            action = yield take(subscriptionChannel);
            yield put(action);
        }
    } finally {
    }
}

export default [
    takeEvery(TRY_AUTHORIZE_HEADSET, executeAuthorize),
    takeEvery(TRY_REVOKE_AUTHORIZATION, executeRevokeAuthorization),
    takeEvery(SELECT_ACTIVE_LEARNER, executeGetHeadsetAuthorization),
    takeEvery(HEADSET_AUTHORIZED, listenForHeadsetAuthorizationChanges),
];
