// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./Assets/vr-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".navigation-footer{height:80px;position:fixed;bottom:0;left:0;right:0;box-sizing:border-box;padding:8px 18px;border-top:1px solid #f2f2f2;display:flex;flex-direction:row;align-items:center;background-color:#fff;z-index:100;transition:background-color .3s}.navigation-footer__blocked{background-color:#46b084}.navigation-footer__blocked .navigation-footer--message-text:before{opacity:1}.navigation-footer__blocked .navigation-footer--back,.navigation-footer__blocked .navigation-footer--next{border-color:rgba(0,0,0,0) !important}.navigation-footer--back,.navigation-footer--next{width:113px;height:60px;flex:0 0 auto;border-radius:30px;font-size:1.25em;font-weight:800;text-transform:uppercase;cursor:pointer;background-color:#fff;border:1px solid #e3c04b;color:#6f6f6f;transition:background-color .2s}.navigation-footer--back:disabled,.navigation-footer--next:disabled{background-color:#f2f2f2;border-color:#e3c04b;cursor:not-allowed}.navigation-footer--back{border:1px solid #e3c04b}.navigation-footer--back:hover:not(:disabled){background-color:#f1e0a5}.navigation-footer--next{color:#000;background-color:#e3c04b;align-self:flex-end}.navigation-footer--next:disabled{color:#6f6f6f}.navigation-footer--next:hover:not(:disabled){background-color:#9d7e18}.footer-spacer{flex:1 1 auto}.navigation-footer--message-text{color:#fff;float:right;box-sizing:border-box;padding:0 0 0 70px;position:relative;height:100%}.navigation-footer--message-text h1{font-size:1.125em;font-weight:400;padding:0;margin:0}.navigation-footer--message-text div{font-size:.875em}.navigation-footer--message-text:before{content:\"\";display:inline-block;opacity:0;transition:opacity .3s;width:56px;height:56px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;border-radius:50%;position:absolute;left:0;top:0}.reference-card-link{display:inline-block;vertical-align:middle;margin-left:15px}", ""]);
// Exports
module.exports = exports;
