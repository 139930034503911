import './input-table-cell-editor.scss';
import React, {useState} from 'react';
import {CELL_TYPE_INPUT, CELL_TYPE_NONE, CELL_TYPE_STATIC} from "../../../../constants/InputTableCellTypes";
import RichEditor from "../../../../RichText/RichEditor";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getInputTableCellContent, getInputTableCellType} from "../../../../store/curriculumTaskItems/inputTable/selectors";
import {modalAdditionalData} from "../../../../store/navigation/selectors";
import {openModalFor} from "../../../../store/navigation/actions";
import {setInputTableCellContent} from "../../../../store/curriculumTaskItems/inputTable/actions";
import {useParams} from '@reach/router';
import uuid from 'uuid/v4';
import {EDIT_INPUT_TABLE_CELL_MODAL} from "../../../../constants/modalTypes";

const stateMap = props => state => {
    const {row, column} = modalAdditionalData(state, EDIT_INPUT_TABLE_CELL_MODAL, props.id);
    return {
        cellRow: row,
        cellColumn: column,
        content: getInputTableCellContent(state, props.id, row, column),
        type: getInputTableCellType(state, props.id, row, column),
    };
};

export default props => {
    const {id} = props;
    const {content, type, cellRow, cellColumn} = useSelector(stateMap(props), shallowEqual);
    const {curriculumId} = useParams();
    const dispatch = useDispatch();
    const [richContent, setRichContent] = useState(content);
    const [selectedType, setSelectedType] = useState(type);

    const setType = e => setSelectedType(e.target.value);
    const close = () => dispatch(openModalFor());
    const accept = () => {
        const cellContent = {
            type: selectedType,
        };
        if(selectedType === CELL_TYPE_STATIC) {
            cellContent.content = richContent;
        } else if(selectedType === CELL_TYPE_INPUT) {
            cellContent.inputId = uuid();
        }
        dispatch(setInputTableCellContent(curriculumId, id, cellRow, cellColumn, cellContent));
        close();
    };

    return (
        <div className="input-table--cell-editor__blocker">
            <div className="input-table--cell-editor">
                <h2 className="cell-editor--title">Edit Cell</h2>
                <div className="cell-editor--type-prompt">Cell content type:</div>
                <div className="cell-editor--type-container">
                    <div className="cell-editor--type-selection cell-editor--none-container">
                        <input id="cell-type-none" type="radio" checked={selectedType === CELL_TYPE_NONE} value={CELL_TYPE_NONE} className="cell-editor--type-input" onChange={setType} />
                        <label htmlFor="cell-type-none" className="cell-editor--type-label">Empty</label>
                    </div>
                    <div className="cell-editor--type-selection cell-editor--input-container">
                        <input id="cell-type-input" type="radio" checked={selectedType === CELL_TYPE_INPUT} value={CELL_TYPE_INPUT} className="cell-editor--type-input" onChange={setType} />
                        <label htmlFor="cell-type-input" className="cell-editor--type-label">Input</label>
                    </div>
                    <span className="cell-editor--type-selection cell-editor--static-container">
                        <input id="cell-type-static" type="radio" checked={selectedType === CELL_TYPE_STATIC} value={CELL_TYPE_STATIC} className="cell-editor--type-input" onChange={setType} />
                        <label htmlFor="cell-type-static" className="cell-editor--type-label">Rich Content</label>
                    </span>
                </div>
                {selectedType === CELL_TYPE_STATIC && <div className="cell-editor--rich-editor-container">
                    <RichEditor content={richContent} setContent={setRichContent} id={id} />
                </div>}
                <div className="cell-editor--button-container">
                    <button className="cell-editor--accept" onClick={accept}>Accept</button>
                    <button className="cell-editor--cancel" onClick={close}>Cancel</button>
                </div>
            </div>
        </div>
    )
};
