import "./curriculum-list.scss";
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getMasterCurriculaIds} from "../../store/curricula/selectors";
import {shallowUnorderedArrayCompare} from "../../utility";
import CurriculumTile from "./CurriculumTile";
import {createMasterCurriculum} from "../../store/curricula/actions";
import uuid from 'uuid/v4';
import {Link} from "@reach/router";
import {header as headerStyle} from "../../style-variables";
import {logo} from "../editor-style";

const stateMap = state => ({
    curricula: getMasterCurriculaIds(state),
});

const compare = (a, b) => {
    return shallowUnorderedArrayCompare(a.curricula, b.curricula);
};

export default React.memo(props => {
    const { curricula } = useSelector(stateMap, compare);
    const dispatch = useDispatch();

    const createCurriculum = () => {
        const newId = uuid();
        dispatch(createMasterCurriculum(newId));
        props.navigate(newId);
    };

    return (
        <>
            <div css={headerStyle}>
                <div css={logo} />
                <h1>VR-Sci Admin</h1>
            </div>
            <div className="admin-curricula-list">
                <h2 className="admin-curricula-list--title">Master Curricula</h2>
                {curricula.map(c => <CurriculumTile id={c} key={c} />)}
                <button onClick={createCurriculum} className="admin-curricula-list--add-button">Create curriculum</button>
            </div>
            <Link to="addLtiProvider">Register Lti Provider</Link>
        </>
    );
});
