import {call, takeEvery} from 'redux-saga/effects';
import {sendGraphQLSaga} from "../../DataService";
import {REMOVE_TASK_FROM_CURRICULUM} from "../../../store/curricula/actions";

const removeTaskFromCurriculumQuery = `mutation RemoveTaskFromCurriculum($input: RemoveTaskFromCurriculumInput!) {
    removeTaskFromCurriculum(input: $input) {
        curriculum {
            id
        }
    }
}`;

function* execute(action) {
    const variables = {
        input: {
            curriculum: action.curriculumId,
            task: action.taskId,
        },
    };
    yield call(sendGraphQLSaga, removeTaskFromCurriculumQuery, variables);
}

export default[takeEvery(REMOVE_TASK_FROM_CURRICULUM, execute)];
