import {css} from "@emotion/core";
import closeSVG from '../../../assets/close2.svg';

export const container = css({
    display: "flex",
    flexDirection: "column",
    flex: "1 1 0",
    minWidth: 0,
    minHeight: 80,
    position: "relative",
    "&:not(:last-of-type)": {
        marginRight: "12px",
    }
});

export const title = css({
    fontSize: "1.125em",
    margin: "0 0 6px 0",
});

export const cursorReset = css({
    flex: "1 1 0",
    display: "flex",
    cursor: "default",
});

export const close = css({
    width: 20,
    height: 20,
    padding: 0,
    position: "absolute",
    top: 11,
    right: 20,
    background: `url(${closeSVG})`,
    backgroundSize: "130%",
    backgroundPosition: "center",
    border: "none",
    cursor: "pointer",
});

export const dotContainer = css({
    display: "flex",
    flexDirection: "row",
    margin: "6px 0 0 0",
});

export const centerer = css({
    flex: "1 1 auto",
});

export const dot = css({
    flex: "0 0 auto",
    width: 10,
    height: 10,
    padding: 0,
    backgroundColor: "white",
    border: "1px solid black",
    transition: 'background-color 0.2s',
    borderRadius: "50%",
    cursor: "pointer",

    "&:hover": {
        backgroundColor: "#7f7f7f",
    },

    "&:not(:last-of-type)": {
        marginRight: 4,
    },
});
export const dotSelected = css({
    backgroundColor: "black",
});
