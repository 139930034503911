import {css} from "@emotion/core";
import {absoluteCenter, bbPadding, HEADER_HEIGHT, inlineBlockTop} from "../../../../style-variables";
import dotBackground from './Assets/dot-background.png';
import gridBackground from './Assets/grid-background.png';
import ribosomeImage from './Assets/Ribosome.png';
import golgiImage from './Assets/Golgi.png';
import mitoImage from './Assets/mito.png';
import {transparentize} from "color2k";

export const dogmashBlue = "#76FAED";
export const dogmashOrange = "#DD9334";
export const dogmashLightOrange = "#FAED7D";
export const backgroundColor = "#2a2a2b";
export const dogmashRed = "#DD3434";
export const dogmashSuccessScoreColor = "#14FF00";
export const dogmashFailColor = "#666666";
export const dogmashPurple = "#C076FA";

export const dogmaContainer = css(bbPadding("26px 0 0 0"), {
    width: "100%",
    height: "100%",
    position: 'relative',
    backgroundColor,
});

export const title = css({
    flex: "1 0 auto",
    fontFamily: "Orbitron, sans serif",
    fontWeight: "500",
    fontSize: "3.125rem",
    textTransform: "lowercase",
    padding: 0,
    margin: "0 0 14px 40px",
    color: dogmashBlue,
});

export const scoreboardContainer = css({
    flex: "0 0 auto",
});

export const smallScoreboardContent = css(bbPadding("17px"), {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    border: `3px solid ${dogmashBlue}`,
    borderRadius: "5px",
    background: `url(${dotBackground})`,
    position: "absolute",
    left: '50%',
    top: "14px",
    transform: 'translateX(-50%)',
    fontFamily: "Rajdhani, sans-serif",
});

export const scoreboardStage = css(bbPadding("7px 13px"), {
    border: `5px solid ${dogmashBlue}`,
    borderRadius: "5px",
    color: '#fff',
    fontSize: '1.25rem',
});

export const scoreboardScore = css({
    width: "90px",
    height: "41px",
    fontSize: "2rem",
    color: '#fff',
    margin: "0 6px",
    textAlign: 'center',
    transition: 'transform 0.2s, color 0.2s',
    transform: "scale(1)",
});
export const scoreboardScoreIncrease = css(scoreboardScore, {
    transform: "scale(1.625)",
    color: dogmashSuccessScoreColor,
});
export const scoreboardScoreDecrease = css(scoreboardScore, {
    transform: "scale(1.5)",
    color: dogmashRed,
});

export const batteryContainer = css(bbPadding("2px"), {
    width: "52px",
    height: "27px",
    border: "2px solid #65CB7A",
    borderRadius: "5px",
    position: 'relative',

    "&:after": {
        content: '""',
        display: 'block',
        width: "6px",
        height: "11px",
        border: `2px solid #65CB7A`,
        transform: "perspective(10px) rotateY(25deg)",
        position: 'absolute',
        left: "100%",
        top: "4px",
    },
});

export const batteryBar = css({
    height: "100%",
    width: "100%",
    background: "#65CB7A",
})

export const batteryText = css(absoluteCenter, {
    color: '#fff',
});

export const scoreboardBlocker = css({
    position: 'fixed',
    top: HEADER_HEIGHT,
    left: 0,
    width: "100%",
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 100,
});

export const endLevelContainer = css(absoluteCenter, bbPadding("38px 0"), {
    display: 'flex',
    width: "340px",
    flexDirection: "column",
    alignItems: "center",
    border: `1px solid ${dogmashBlue}`,
    borderRadius: "11px",
    backgroundColor: "#303030",
});

export const endLevelHeader = css(bbPadding("3px 25px"), {
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: `1px solid ${dogmashBlue}`,
    borderRadius: "11px",
    backgroundColor: "#303030",
    fontFamily: "Rajdhani, sans-serif",
    fontSize: "1.875rem",
    fontWeight: 700,
    color: dogmashBlue,
    lineHeight: "102.38%",
    margin: "0",
});

export const levelsContainer = css({
    flex: "0 0 auto",
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
    margin: "0 0 15px 0",
});

export const levelBox = css(bbPadding("6px"), {
    width: "27px",
    height: "27px",
    color: dogmashOrange,
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: "0.78em",
    border: `1px solid ${dogmashOrange}`,
    borderRadius: "5px",
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 6px 0 0",

    "&:last-of-type": {
        margin: 0,
    },
});
export const currentLevelBox = css(levelBox, {
    borderWidth: "2px",
    width: "44px",
    height: '44px',
});

export const currentLevelBoxNumber = css({
    fontWeight: 700,
    fontSize: "1.875rem",
    lineHeight: "0.8em",
});

export const endLevelScoreContainer = css({
    flex: "0 0 auto",
    display: 'flex',
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "0 0 15px 0",
});

export const endLevelStar = css({
    fontSize: "5rem",
    lineHeight: "1em",
    margin: "0 20px 0 0",
    color: "#fff"
});
export const endLevelFilledStar = css(endLevelStar, {
    color: "#FFA400",
});

export const endLevelScoreLabel = css(inlineBlockTop, {
    flex: "0 0 auto",
    width: "80px",
    color: dogmashBlue,
    fontFamily: "Rajdhani, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    lineHeight: "78.38%",
});

export const endLevelScore = css(inlineBlockTop, {
    flex: "0 0 auto",
    color: dogmashBlue,
    fontFamily: "Rajdhani, sans-serif",
    fontSize: "3.75rem",
    lineHeight: "0.7em",
    fontWeight: 400,
});

export const waitingText = css({
    fontFamily: "Rajdhani, sans-serif",
    fontSize: "0.9375rem",
    fontWeight: 700,
    lineHeight: "0.8em",
});

export const returnToTrainingButton = css(bbPadding("16px 26px"), {
    color: dogmashBlue,
    border: `1px solid ${dogmashBlue}`,
    backgroundColor: "#303030",
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 600,
    fontSize: "1.25rem",
    transition: "color 0.2s, background-color 0.2s",
    cursor: "pointer",

    "&:hover": {
        color: "#303030",
        backgroundColor: dogmashBlue,
    },
})

export const gameContainer = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
});

export const cellContainer = css({
    flex: "0 0 auto",
});

export const cellBox = css({
    width: "1015px",
    height: "368px",
    borderTop: `1px solid ${dogmashBlue}`,
    borderBottom: `1px solid ${dogmashBlue}`,
    background: `url(${gridBackground})`,
    position: "relative",
});

const cellCut = css({
    width: "331px",
    height: "23px",
    boxSizing: 'borderBox',
    backgroundColor,
    position: 'absolute',
    left: "50%",
    transform: 'translateX(-50%)',
    zIndex: 10,

    "&:before,&:after": {
        content: '""',
        display: 'block',
        width: "31px",
        height: '100%',
        position: 'absolute',
        backgroundColor,
        top: 0,
    },
    "&:after": {
        borderRight: `1px solid ${dogmashBlue}`,
        left: "100%",
    },
    "&:before": {
        borderLeft: `1px solid ${dogmashBlue}`,
        right: "calc(100% - 0.5px)",
    },
});
export const cellBoxTopCut = css(cellCut, {
    borderBottom: `1px solid ${dogmashBlue}`,
    top: '-1px',

    "&:after": {
        transform: "skew(-53.43deg)",
        transformOrigin: "0 0",
    },
    "&:before": {
        transform: "skew(53.43deg)",
        transformOrigin: "100% 0",
    },
});
export const cellBoxBottomCut = css(cellCut, {
    borderTop: `1px solid ${dogmashBlue}`,
    bottom: '-1px',

    "&:after": {
        transform: "skew(53.43deg)",
        transformOrigin: "0 100%",
    },
    "&:before": {
        transform: "skew(-53.43deg)",
        transformOrigin: "100% 100%",
    },
});

export const dashboardTutorialContainer = css({
    width: 0,
    height: 0,
    position: "absolute",
    left: "266px",
    top: "222px",
    zIndex: 20,
});

export const organellesContainer = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "space-between",
    alignItems: "flex-start",
});

export const organelleContainer = css({
    width: "320px",
    textAlign: "center",
    margin: "6px 0",
});

export const organelleTitle = css({
    fontFamily: "Rajdhani",
    fontSize: "1.25rem",
    fontWeight: "400",
    textTransform: "uppercase",
    color: "#fff",
    margin: "0 0 10px 0",
    padding: 0,
});
export const organelleTitleBottom = css(organelleTitle, {
    margin: "10px 0 0 0",
});

export const organelle = css({
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    verticalAlign: 'top',
    marginRight: "23px",
    backgroundSize: "100% 100%",
    position: "relative",

    "&:last-of-type": {
        marginRight: 0,
    },
});

export const ribosome = css(organelle, {
    width: "53px",
    height: "45px",
    background: `url(${ribosomeImage})`,
});

export const topOrganelleDebris = css({
    position: 'absolute',
    top: 'calc(100% + 16px)',
    zIndex: 1,
});
export const bottomOrganelleDebris = css({
    position: 'absolute',
    bottom: 'calc(100% + 16px)',
    zIndex: 1,
});

export const golgi = css(organelle, {
    width: "96px",
    height: '50px',
    background: `url(${golgiImage})`,
});

export const mitochondria = css(organelle, {
    width: "156px",
    height: "55px",
    background: `url(${mitoImage})`,
});

export const membraneContainer = css(organelle,{
    margin: "0 0 0 -100px!important",
});

export const nucleusContainer = css(absoluteCenter, {
    textAlign: "center",
});

export const nucleusTutorial = css({
    width: 0,
    height: 0,
    position: "absolute",
    left: "50%",
    top: "252px",
    zIndex: 20,
});

export const nucleus = css({
    width: "262px",
    height: "262px",
    border: `3px solid ${dogmashBlue}`,
    backgroundColor: transparentize(dogmashBlue, 0.3),
    borderRadius: "50%",
    position: "relative",
});

export const dropSpotContainer = css(absoluteCenter, {
    whiteSpace: "nowrap",
});

export const geneSelectionButton = css({
    width: "calc(100% + 6px)",
    height: "calc(100% + 6px)",
    position: 'absolute',
    left: "-3px",
    top: "-3px",
    background: "transparent",
    border: "3px solid transparent",
    borderRadius: "50%",
    boxShadow: "0 0 10px 0 rgba(255,255,255,0.75), inset 0 0 6px 0 rgba(255,255,255,1)",
    transition: "box-shadow 0.2s",
    cursor: "pointer",

    "&:hover": {
        boxShadow: "0 0 14px 0 rgba(255,255,255,1), inset 0 0 6px 0 rgba(255,255,255,1)",
    },
});

export const dropLabel = css({
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 700,
    fontSize: "0.9375rem",
    color: "#fff",
    margin: "11px 0 0 11px",
    textTransform: "uppercase",
});

export const cytoplasm = css({
    position: 'absolute',
    left: "720px",
    top: "20px",
});

export const microtubuleContainer = css({
    position: "absolute",
    left: "5px",
    top: "50%",
    transform: "translateY(-50%)",
});

export const microtubuleDebrisContainer = css({
    position: "absolute",
    left: '210px',
    top: '110px',
    width: "150px",
});
export const microtubuleDebris = css(inlineBlockTop, {
    margin: "0 10px 10px 0",
});

export const lysosomeDebris = css({
    position: "absolute",
    left: "770px",
    top: "160px",
});

export const cargoTutorial = css({
    position: "absolute",
    left: "51px",
    top: "260px",
});

export const lysosomeTutorial = css({
    position: "absolute",
    left: "856px",
    top: "205px",
});

export const labelAndCargoContainer = css({
    transform: "rotate(-90deg) translate(-50%, 50%)",
    transformOrigin: "0 50%",

    "& svg": {
        transform: "rotate(90deg)",
    },
});
export const microtubuleOne = css({
    width: "180px",
    position: "absolute",
    left: "65px",
    top: "-51px",
    transformOrigin: "0 50%",
    transform: "rotate(-36.55deg)",

    "& .microtubule-drop-spot-container": {
        transform: "translate(-50%, -50%) rotate(36.55deg)",
    },
});
export const microtubuleTwo = css({
    width: "300px",
    position: "absolute",
    left: "65px",
    top: "-12px",
    transformOrigin: "0 50%",
    transform: "rotate(-28.92deg)",

    "& .microtubule-drop-spot-container": {
        transform: "translate(-50%, -50%) rotate(28.92deg)",
    },
});
export const microtubuleThree = css({
    width: "344px",
    position: "absolute",
    left: "65px",
    top: "49px",
    transformOrigin: "0 50%",
    transform: "rotate(22.16deg)",

    "& .microtubule-drop-spot-container": {
        transform: "translate(-50%, -50%) rotate(-22.16deg)",
    },
});
export const microtubuleFour = css({
    width: "167px",
    position: "absolute",
    left: "65px",
    top: "92px",
    transformOrigin: "0 50%",
    transform: "rotate(36.55deg)",

    "& .microtubule-drop-spot-container": {
        transform: "translate(-50%, -50%) rotate(-36.55deg)",
    },
});

export const warningModalContainer = css(bbPadding("20px 12px"), absoluteCenter, {
    width: "475px",
    height: "240px",
    backgroundColor: "#082322",
    border: `5px solid ${dogmashRed}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
});

export const warningModalTitle = css({
    flex: "0 0 auto",
    padding: 0,
    margin: "0 0 12px 0",
    color: dogmashRed,
    fontFamily: "Rajdhani, sans-serif",
    fontSize: "2rem",
    fontWeight: 600,
    textTransform: "uppercase",
});

export const warningModalText = css({
    flex: "1 0 auto",
    color: '#fff',
    fontFamily: "Rajdhani, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 400,
    textAlign: "center",
});

export const warningModalButton = css(bbPadding("16px 26px"), {
    flex: "0 0 auto",
    border: `1px solid ${dogmashRed}`,
    backgroundColor: "transparent",
    color: dogmashRed,
    fontFamily: "Rajdhani, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
    cursor: "pointer",
});
