// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/gray-square-x.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".edit-learner-container{position:fixed;z-index:1002;top:0;left:0;bottom:0;right:0;background-color:rgba(0,0,0,.4)}.edit-learner-modal{border:1px solid #f2f2f2;background-color:#fff;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);font-size:.75em;box-sizing:border-box;padding:30px}.edit-learner-modal h1{font-size:1.5em;font-weight:600;color:#6f6f6f;padding:0;margin:0 0 30px 0}.edit-learner-modal--close{width:20px;height:20px;background-color:rgba(0,0,0,0);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;border:none;position:absolute;top:15px;right:15px;cursor:pointer;outline:none}.edit-learner--given-name{margin-bottom:15px}.edit-learner--family-name{margin-bottom:30px}.edit-learner--label{display:inline-block;vertical-align:top;width:80px;color:#6f6f6f}.edit-learner--input{display:inline-block;vertical-align:top;width:275px;color:#6f6f6f;border:1px solid #d7d7d7;border-radius:4px;box-sizing:border-box;padding:2px 4px}.edit-learner--input:focus{box-shadow:-2px 2px 4px -1px rgba(0,0,0,.4)}.edit-learner--button-container{width:100%;display:flex;flex-direction:row;justify-content:flex-end}.edit-learner--cancel{display:inline-block;vertical-align:top;width:72px;height:38px;background-color:#fff;color:#6f6f6f;border:1px solid #e3c04b;border-radius:19px;font-size:1em;margin:0 10px 0 0;cursor:pointer;outline:none;transition:background-color .2s}.edit-learner--cancel:hover{background-color:#e2e2e2}.edit-learner--save{display:inline-block;vertical-align:top;width:78px;height:38px;background-color:#e3c04b;border:none;border-radius:19px;color:#fff;font-size:1em;cursor:pointer;outline:none;transition:background-color .2s}.edit-learner--save:hover{background-color:#9d7e18}", ""]);
// Exports
module.exports = exports;
