import * as styles from './pop-up-tutorial-styles';
import React, {useEffect, useRef, useState} from 'react';
import InfoIcon from './inline-assets/tutorial_info_icon.svg';
import {useDispatch, useSelector} from "react-redux";
import {
    getTutorialDataToShow,
    getTutorialStrandCurrentIndex,
    getTutorialStrandItemComplete,
    shouldForceForward
} from "../../store/tutorial/selectors";
import {advanceTutorialStrand, tutorialNeedsCompletionCheck} from "../../store/tutorial/actions";
import {dogmashBlue, dogmashOrange, dogmashRed} from "../CurriculumTask/Interactables/DogmaDash/dogma-dash-styles";

const TutorialHelper = ({tutorialData, positionData, expandUp, left, horizontal}) => {
    const isComplete = useSelector(state => getTutorialStrandItemComplete(state, tutorialData.strandId, positionData));
    const currentIndex = useSelector(state => getTutorialStrandCurrentIndex(state, tutorialData.strandId));
    const forceForward = useSelector(state => shouldForceForward(state, tutorialData.strandId, positionData));
    const dispatch = useDispatch();

    const next = () => {
        dispatch(advanceTutorialStrand(tutorialData.strandId));
    };

    useEffect(() => {
        if(isComplete || forceForward) {
            console.log("COMPLETING ", tutorialData.text, tutorialData.index, currentIndex);
            if(currentIndex === tutorialData.index) {return next();}
        }
        return () => dispatch(tutorialNeedsCompletionCheck(tutorialData, positionData));
    }, [isComplete, forceForward]);

    const borderColor = tutorialData?.alert ? dogmashRed : dogmashOrange;
    const headerColor = tutorialData?.alert ? dogmashRed : dogmashBlue;
    const width = tutorialData?.width;

    const containerStyle = horizontal ? styles.container(borderColor, width) : expandUp ? styles.containerUp(borderColor, width) : styles.containerDown(borderColor, width);
    const containerLeftRight = horizontal ? (left ? styles.containerHorizontalLeft : styles.containerHorizontalRight) : (left ? styles.containerLeft : styles.containerRight);

    const arrowStyle = !horizontal && expandUp ? styles.upArrow(borderColor) : styles.arrow(borderColor);
    const leftRightArrow = horizontal ? (left ? styles.leftHorizontalArrow : styles.rightHorizontalArrow) : (left ? styles.leftArrow : styles.rightArrow);

    return (
        <div css={[containerStyle, containerLeftRight]}>
            <div
                css={[arrowStyle, leftRightArrow]}/>
            <div css={styles.headerContainer}>
                <span css={styles.headerIcon(tutorialData?.alert ? dogmashRed : dogmashBlue)}><InfoIcon/></span>
                <h3 css={styles.header(headerColor)}>{tutorialData.header}</h3>
            </div>
            {tutorialData.text &&
                <div css={styles.text} dangerouslySetInnerHTML={{__html: tutorialData.text}}/>}
            {tutorialData?.callToAction && <div css={styles.callToAction(borderColor)}>{tutorialData.callToAction}</div>}
            {!tutorialData?.conditionalCompletion &&
                <button css={styles.continueButton} onClick={next}>Next</button>}
        </div>
    );
};

// try memoizing the below?
const PopUpTutorial = ({positionData, expandUp, horizontal}) => {
    const tutorialData = useSelector(state => getTutorialDataToShow(state, positionData));//, (a, b) => a?.text === b?.text); // I don't love this equality check, and maybe it isn't necessary
    const [left, setLeft] = useState(false);
    const rootRef = useRef();

    useEffect(() => {
        const {offsetLeft} = rootRef.current;
        const width = window.innerWidth;
        setLeft(offsetLeft > width * 0.5);
    }, []);

    return (
        <div ref={rootRef} css={styles.root}>
            {tutorialData && <TutorialHelper tutorialData={tutorialData} positionData={positionData} expandUp={expandUp} left={left} horizontal={horizontal}/>}
        </div>
    );
};

export default PopUpTutorial;
