import {css} from "@emotion/core";
import {absoluteCenter} from "../../../style-variables";

export const explainerContainer = css({
    width: "100%",
    height: 555, // PAGE_HEIGHT?
    backgroundColor: "#ddd",
    position: "relative",
});

export const label = absoluteCenter;
