import {
    getDogmaMrnaSlotIds,
    getObjectForm,
    getObjectsInOrganelle,
    getObjectType
} from "../../interactions/dogmaDash/selectors";

export const mrnaInSlot = (state, dogmaDashId, proteinType) => getDogmaMrnaSlotIds(state, dogmaDashId).some(s => getObjectsInOrganelle(state, s).filter(o => getObjectType(state, o) === proteinType).length > 0)
export const proteinFormAtOrganelle = (state, organelleId, form) => getObjectsInOrganelle(state, organelleId).filter(o => getObjectForm(state, o) === form).length > 0;
export const proteinTypeAtOrganelle = (state, organelleId, type) => getObjectsInOrganelle(state, organelleId).filter(o => getObjectType(state, o) === type).length > 0;
