import {DRAGGABLE, DRAGGABLE_BANK} from "../../../constants/TaskTypes";
import {SET_DRAGGABLE_CONTENT} from "./actions";
import {ADD_INTERACTABLE_TO_TASK, REMOVE_INTERACTABLE_FROM_TASK} from "../actions";
import {omit} from "lodash";
import {CURRICULA_LOADED} from "../../curricula/actions";
import {ADD_DRAGGABLE_TO_BANK} from "../draggableBank/actions";

const defaultItem = {
    content: "",
};

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const newDraggables = action.curricula.reduce((agg, c) => ({
            ...agg,
            ...(c.tasks || []).reduce((taskAgg, t) => ({
                ...taskAgg,
                ...t.items.filter(i => i.__typename === DRAGGABLE_BANK).reduce((itemsAgg, i) => ({
                    ...itemsAgg,
                    ...i.draggables.reduce((draggableAgg, d) => ({
                        ...draggableAgg,
                        [d.id]: {
                            ...defaultItem,
                            ...d,
                        },
                    }), {}),
                }), {}),
            }), {}),
        }), {});
        return {
            ...state,
            ...newDraggables,
        };
    }
    if(action.type === ADD_INTERACTABLE_TO_TASK && action.interactableType === DRAGGABLE) {
        return {
            ...state,
            [action.interactableId]: {
                ...defaultItem,
            },
        };
    }
    if(action.type === REMOVE_INTERACTABLE_FROM_TASK) {
        return omit(state, action.interactableId);
    }
    if(action.type === ADD_DRAGGABLE_TO_BANK) {
        return {
            ...state,
            [action.draggableId]: {
                ...defaultItem,
            },
        };
    }
    if(action.type === SET_DRAGGABLE_CONTENT) {
        return {
            ...state,
            [action.draggableId]: {
                ...state[action.draggableId],
                content: action.content,
            },
        };
    }
    return state;
}
