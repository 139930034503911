import {css} from "@emotion/core";
import {bbPadding} from "../../../../../style-variables";

export const container = css({
    width: "100%",
    border: "1px solid #333",
}, bbPadding("8px 4px"));

export const heading = css({
    fontSize: "1.125em",
    margin: "0 0 6px 0",
    padding: 0,
});

export const explainerSelectLabel = css({
    margin: "0 4px 0 0",
});
