import './admin.scss';
import React, {useEffect, useState} from 'react';
import {shallowEqual, useSelector} from "react-redux";
import {isPermissionAllowed} from "../store/identity/selectors";
import {ADMIN_PERMISSION, PERMISSION_ALLOWED, PERMISSION_DENIED, PERMISSION_UNKNOWN} from "../constants/permissions";
import {getDataService} from "../store/data/selectors";
import {getMasterCurricula} from "../graphql/Curriculum";
import Preview from './Preview';
import {Router} from "@reach/router";
import Editor from "./Editor";

const stateMap = state => ({
    adminAllowed: isPermissionAllowed(state, ADMIN_PERMISSION),
    dataService: getDataService(state),
});

export default React.memo(() => {
    const {adminAllowed, dataService} = useSelector(stateMap, shallowEqual);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if(adminAllowed !== PERMISSION_ALLOWED) { return; }
        getMasterCurricula(dataService).then(() => setLoaded(true));
    }, [adminAllowed]);

    return (
        <div className="admin">
            {(adminAllowed === PERMISSION_UNKNOWN || !loaded) && <div className="load-spinner" /> }
            {adminAllowed === PERMISSION_DENIED && <h1>Permission denied</h1> }
            {adminAllowed === PERMISSION_ALLOWED && loaded &&
            <Router style={{height: "100%"}}>
                <Editor path="/*" />
                <Preview path="/preview/:curriculumId" />
                <Preview path="/preview/:curriculumId/:taskId" />
            </Router>}
        </div>
    );
});
