import React, {useEffect, useState} from 'react';
import DogmaDash from "./DogmaDash";
import {useDispatch, useSelector} from "react-redux";
import {getDogmaDashCurrentView} from "../../../../store/interactions/dogmaDash/selectors";
import {
    CELL_VIEW,
    GENE_SELECTION_VIEW,
    HELP_VIEW,
    TRANSCRIPTION_VIEW,
    TRANSLATION_VIEW
} from "../../../../constants/DogmaDash";
import Transcription from "./Transcription/Transcription";
import GeneSelection from "./Transcription/GeneSelection";
import {css} from "@emotion/core";
import {HEADER_HEIGHT} from "../../../../style-variables";
import Translation from "./Transcription/Translation";
import IntroHelpDialog from "./IntroHelpDialog";
import {getHeadsetPairingKey} from "../../../../store/identity/selectors";
import {openModalFor} from "../../../../store/navigation/actions";
import {HEADSET_PAIRING_MODAL, PARTNERS_MODAL} from "../../../../constants/modalTypes";
import {isModalOpenFor} from "../../../../store/navigation/selectors";

const container = css({
    width: "100%",
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
    zIndex: 100,
    position: "fixed",
    top: `${HEADER_HEIGHT}px`,
    left: 0,
    background: "transparent",
    overflow: "hidden",
});

const DogmaDashLearner = ({id}) => {
    const teamDialogOpen = useSelector(state => isModalOpenFor(state, PARTNERS_MODAL));
    const currentView = useSelector(state => getDogmaDashCurrentView(state, id));
    const paired = useSelector(state => !!getHeadsetPairingKey(state));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(openModalFor(PARTNERS_MODAL, null, {verify:true}));
    }, []);

    useEffect(() => {
        document.body.requestFullscreen();
        if(currentView === CELL_VIEW && !paired) {
            dispatch(openModalFor(HEADSET_PAIRING_MODAL));
        }
        return () => document.exitFullscreen();
    }, []);

    return (
        <div css={container}>
            {!teamDialogOpen && currentView === HELP_VIEW && <IntroHelpDialog id={id} />}
            {!teamDialogOpen && currentView === CELL_VIEW && <DogmaDash id={id} />}
            {!teamDialogOpen && currentView === GENE_SELECTION_VIEW && <GeneSelection id={id} />}
            {!teamDialogOpen && currentView === TRANSCRIPTION_VIEW && <Transcription id={id} />}
            {!teamDialogOpen && currentView === TRANSLATION_VIEW && <Translation id={id} />}
        </div>
    )
};

export default DogmaDashLearner;
