import {CREATE_COHORT, SET_COHORT_NAME} from "./cohorts/actions";
import {getTeacherId} from "./identity/selectors";
import {UPDATE_LEARNER_NAME} from "./learners/actions";
import {
    setInputTableInputs,
    setLearnerName,
    setLongTextInputText,
    setMultipleChoiceInputSelection
} from "../graphql/VerdantLearner";
import {SET_PUNNETT_SQUARE_QUESTION_ANSWER} from "./punnettSquares/actions";
import {setPunnettSquareQuestionAnswers} from "../graphql/PunnettSquare";
import {getPunnettSquareAnswers} from "./punnettSquares/selectors";
import {SET_CURRICULUM_TASK_STATUS} from "./curriculum/actions";
import {
    addDraggableToDraggableBank,
    changeLearnerCurriculumProgress,
    createCurriculum,
    updateCPRContent,
    updateDraggableContent,
    updateInputTableContent,
    updateLongTextInputContent,
    updateMultipleChoiceInputContent
} from "../graphql/Curriculum";
import {getCurriculumId} from "./curriculum/selectors";
import {getActiveLearner} from "./navigation/selectors";
import {CREATE_MASTER_CURRICULUM} from "./curricula/actions";
import {ADD_DRAGGABLE_TO_BANK} from './curriculumTaskItems/draggableBank/actions';
import {SET_CPR_ASSESSMENT} from "./curriculumTaskItems/cpr/actions";
import {SET_DRAGGABLE_CONTENT} from "./curriculumTaskItems/draggable/actions";
import {INPUT_TABLE, MULTIPLE_CHOICE_INPUT} from "../constants/TaskTypes";
import {
    SET_INPUT_TABLE_INPUT,
    SET_LONG_TEXT_INPUT_TEXT,
    SET_MULTIPLE_CHOICE_INPUT_SELECTION
} from "./interactions/actions";
import {
    getInputTableData,
    getInputTableNumberOfColumns,
    getInputTablePrompt,
} from "./curriculumTaskItems/inputTable/selectors";
import {
    getMultipleChoiceInputCorrectSelection,
    getMultipleChoiceInputMultiselect,
    getMultipleChoiceInputOptions,
    getMultipleChoiceInputPrompt
} from "./curriculumTaskItems/multipleChoiceInput/selectors";
import {getInputTableAllInputs} from "./interactions/selectors";
import {getCurriculumTaskItemType} from "./curriculumTaskItems/selectors";
import {
    ADD_MULTIPLE_CHOICE_INPUT_OPTION,
    REMOVE_MULTIPLE_CHOICE_INPUT_OPTION,
    SET_MULTIPLE_CHOICE_INPUT_CORRECT_SELECTION,
    SET_MULTIPLE_CHOICE_INPUT_MULTISELECT,
    SET_MULTIPLE_CHOICE_INPUT_OPTION_TEXT,
    SET_MULTIPLE_CHOICE_INPUT_PROMPT,
} from "./curriculumTaskItems/multipleChoiceInput/actions";
import {
    ADD_COLUMN_TO_INPUT_TABLE,
    ADD_ROW_TO_INPUT_TABLE,
    REMOVE_COLUMN_FROM_INPUT_TABLE,
    REMOVE_ROW_FROM_INPUT_TABLE,
    SET_INPUT_TABLE_CELL_CONTENT,
    SET_INPUT_TABLE_PROMPT,
} from "./curriculumTaskItems/inputTable/actions";
import {SET_LONG_TEXT_INPUT_PROMPT} from "./curriculumTaskItems/longTextInput/actions";

const multiChoiceInputUpdates = [SET_MULTIPLE_CHOICE_INPUT_PROMPT, ADD_MULTIPLE_CHOICE_INPUT_OPTION, SET_MULTIPLE_CHOICE_INPUT_OPTION_TEXT, SET_MULTIPLE_CHOICE_INPUT_MULTISELECT, SET_MULTIPLE_CHOICE_INPUT_CORRECT_SELECTION, REMOVE_MULTIPLE_CHOICE_INPUT_OPTION];
const inputTableUpdates = [SET_INPUT_TABLE_PROMPT, ADD_COLUMN_TO_INPUT_TABLE, REMOVE_COLUMN_FROM_INPUT_TABLE, ADD_ROW_TO_INPUT_TABLE, REMOVE_ROW_FROM_INPUT_TABLE, SET_INPUT_TABLE_CELL_CONTENT];

export default dataService => store => next => action => {
    let state = store.getState();
    switch(action.type) {
        case CREATE_COHORT:
            dataService.createCohort(action.id, getTeacherId(state));
            break;
        case SET_COHORT_NAME:
            dataService.setCohortName(action.cohortId, action.name);
            break;
        case UPDATE_LEARNER_NAME:
            setLearnerName(dataService, action.learnerId, action.givenName, action.familyName);
            break;
        case SET_CURRICULUM_TASK_STATUS:
            const learner = getActiveLearner(state);
            const curriculumId = getCurriculumId(state);
            changeLearnerCurriculumProgress(dataService, learner, curriculumId, action.task, action.status);
            break;
        case CREATE_MASTER_CURRICULUM:
            createCurriculum(dataService, action.id, action.name);
            break;
        case SET_LONG_TEXT_INPUT_PROMPT: // only update Long Text here. this generalization may have been a mistake...
            updateLongTextInputContent(dataService, action.curriculumId, action.interactableId, action.prompt);
            break;
        case SET_LONG_TEXT_INPUT_TEXT:
            setLongTextInputText(dataService, getActiveLearner(state), action.curriculumId, action.interactableId, action.text);
            break;
        case SET_MULTIPLE_CHOICE_INPUT_SELECTION:
            setMultipleChoiceInputSelection(dataService, getActiveLearner(state), action.curriculumId, action.interactableId, action.selection);
            break;
        case ADD_DRAGGABLE_TO_BANK:
            addDraggableToDraggableBank(dataService, action.curriculumId, action.draggableBankId, action.draggableId);
            break;
        case SET_DRAGGABLE_CONTENT:
            updateDraggableContent(dataService, action.curriculumId, action.draggableId, action.content);
            break;
        case SET_CPR_ASSESSMENT:
            updateCPRContent(dataService, action.curriculumId, action.cprId, action.assessment);
            break;
    }
    const result = next(action);
    state = store.getState();
    if(action.type === SET_PUNNETT_SQUARE_QUESTION_ANSWER) {
        const answers = getPunnettSquareAnswers(store.getState(), action.id);
        setPunnettSquareQuestionAnswers(dataService, action.id, answers);
    } else if(multiChoiceInputUpdates.includes(action.type)) {
        const { curriculumId } = action;
        const id = action.multipleChoiceId || action.interactableId;
        if(getCurriculumTaskItemType(state, id) === MULTIPLE_CHOICE_INPUT) { // guard for interactable text update
            updateMultipleChoiceInputContent(
                dataService,
                curriculumId,
                id,
                getMultipleChoiceInputPrompt(state, id),
                getMultipleChoiceInputOptions(state, id),
                getMultipleChoiceInputCorrectSelection(state, id),
                getMultipleChoiceInputMultiselect(state, id)
            );
        }
    } else if(inputTableUpdates.includes(action.type)) {
        const {curriculumId} = action;
        const id = action.inputTableId || action.interactableId;
        if(getCurriculumTaskItemType(state, id) === INPUT_TABLE) {
            updateInputTableContent(
                dataService,
                curriculumId,
                id,
                getInputTablePrompt(state, id),
                getInputTableNumberOfColumns(state, id),
                getInputTableData(state, id),
            );
        }
    } else if(action.type === SET_INPUT_TABLE_INPUT) {
        const {curriculumId, inputTableId} = action;
        const inputs = getInputTableAllInputs(state, inputTableId);
        setInputTableInputs(
            dataService,
            getActiveLearner(state),
            curriculumId,
            inputTableId,
            inputs.map(i => JSON.stringify(i))
        );
    }
    return result;
}
