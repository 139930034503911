import * as styles from "./sticky-notes-style";
import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {getStickyNoteText} from "../../store/stickyNotes/selectors";
import {setStickyNoteText} from "../../store/stickyNotes/actions";
import {css} from "@emotion/core";

export default props => {
    const {id} = props;
    const scaleFactor = props.scaleFactor || 1;
    const text = useSelector(state => getStickyNoteText(state, id));
    const dispatch = useDispatch();

    const updateText = e => dispatch(setStickyNoteText(id, e.target.value));

    const noteStyle = css(styles.stickyNote, {
        width: 175 * scaleFactor,
        height: 175 * scaleFactor,
    });

    return (
        <div css={noteStyle}>
            <textarea css={styles.text}
                      defaultValue={text}
                      onBlur={updateText}
                      placeholder="Put notes here..."
            />
        </div>
    )
}
