import {getWorkBoardTemplateItemType} from "../workBoardTemplate/selectors";
import {WORK_BOARD_CROSS_CARD, WORK_BOARD_DROP_SLOT} from "../../constants/TaskTypes";

const getInteraction = (state, interactableId) => state.interactions[interactableId];
export const getLongTextInputText = (state, interactableId) => {
    const interaction = getInteraction(state, interactableId);
    if(!interaction) { return null; }
    return interaction.text;
};
export const getInteractionWorkBoardId = (state, interactableId) => {
    const interaction = getInteraction(state, interactableId);
    if(!interaction) { return null; }
    return interaction.workBoard;
};
export const getDefinitionForWorkBoard = (workBoardId, state) =>
    Object.keys(state.interactions).find(k => state.interactions[k].workBoard === workBoardId);
export const getMultipleChoiceInputSelection = (state, interactableId) => {
    const interaction = getInteraction(state, interactableId);
    if(!interaction) { return []; }
    return interaction.selection;
};
export const getInputTableInput = (state, inputTableId, inputId) => {
    const interaction = getInteraction(state, inputTableId);
    if(!interaction) { return null; }
    return interaction.inputs.find(i => i.inputId === inputId)
};
export const getInputTableAllInputs = (state, inputTableId) => {
    const interaction = getInteraction(state, inputTableId);
    if(!interaction || !interaction.inputs) { return []; }
    return interaction.inputs;
};
export const getDraggablesInDropArea = (state, dropAreaId) => {
    const dropAreaInteraction = getInteraction(state, dropAreaId);
    if(!dropAreaInteraction) { return []; }
    return dropAreaInteraction.draggables.map(d => d.id);
};
// TODO: a little fragile, might need to do some type checking on the interactable
export const getDropAreasForDraggable = (state, draggableId) => {
    return Object.keys(state.interactions).filter(id => {
        const i = state.interactions[id];
        return i.draggables && !!i.draggables.find(d => d.id === draggableId);
    });
};
export const getDraggableType = (dropAreaId, draggableId, state) => {
    const dropAreaInteraction = getInteraction(state, dropAreaId);
    return dropAreaInteraction.draggables.find(d => d.id === draggableId).type;
};
export const getDraggableMetadata = (dropAreaId, draggableId, state) => {
    const dropAreaInteraction = getInteraction(state, dropAreaId);
    return dropAreaInteraction.draggables.find(d => d.id === draggableId).metadata;
}

export const getPlantInCrossSlot = (crossId, slotIndex, parent, state) => {
    const cross = getInteraction(state, crossId);
    if(!cross) { return null; }
    return parent ? cross.parents[slotIndex] : cross.offspring[slotIndex];
};

export const getCrossOffspringCards = (crossId, state) => {
    const cross = getInteraction(state, crossId);
    if(!cross) { return []; }
    return cross.offspring;
};

export const getCrossParentCards = (crossId, state) => {
    const cross = getInteraction(state, crossId);
    if(!cross) { return [null, null]; }
    return cross.parents;
};

// this is a little brittle..
export const plantCardCrossCards = (plantCardId, state) => {
    return Object.keys(state.interactions).filter(id => {
        const i = state.interactions[id];
        return (i.parents && i.parents.includes(plantCardId)) || (i.offspring && i.offspring.includes(plantCardId))
    });
}

export const getCPRAttempts = (cprId, state) => {
    const interaction = getInteraction(state, cprId);
    if(!interaction) { return []; }
    return interaction.attempts;
}

export const getSlottedItemsOfTemplateItem = (workBoardDefinition, templateItemId, state) => {
    const type = getWorkBoardTemplateItemType(workBoardDefinition, templateItemId, state);
    switch(type) {
        case WORK_BOARD_DROP_SLOT:
            return getDraggablesInDropArea(state, templateItemId);
        case WORK_BOARD_CROSS_CARD:
            return getCrossParentCards(templateItemId, state).concat(getCrossOffspringCards(templateItemId, state));
    }
    return [];
}
