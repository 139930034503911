import {
    CREATE_WORK_BOARD_FOR_DEFINITION,
    DRAGGABLE_PLACED_IN_DROP_AREA, DROP_AREA_DRAGGABLES_SET, EMPTY_INTERACTIONS,
    INTERACTIONS_LOADED,
    PLACE_PLANT_IN_CROSS,
    REMOVE_DRAGGABLE_FROM_DROP_AREA,
    REMOVE_WORK_BOARD_FOR_DEFINITION,
    SET_INPUT_TABLE_INPUT,
    SET_LONG_TEXT_INPUT_TEXT,
    SET_MULTIPLE_CHOICE_INPUT_SELECTION
} from "./actions";
import {SET_AUTHENTICATION_STATE} from "../identity/actions";
import {NOT_LOGGED_IN} from "../../constants/AuthenticationState";
import rearrangeArray from "../../Utility/rearrangeArray";
import {SET_ITEM_POSITION_ACTION} from "../workBoards/actions";

export default (state = {}, action) => {
    if(action.type === INTERACTIONS_LOADED) {
        // this duck-typing is getting bloated.
        const loadedInteractions = action.interactions.reduce((agg, i) => {
            const current = state[i.interactable.id] || {};
            const parsed = {
                ...current,
                ...i,
                interactable: i.interactable.id,
            };
            if(i.workBoard) {
                parsed.workBoard = i.workBoard.id;
            } else if(i.inputs) {
                parsed.inputs = i.inputs.map(j => JSON.parse(j));
            } else if(i.draggables) {
                parsed.draggables = i.draggables.map(d => ({
                    id: d.item.id,
                    type: d.item.__typename,
                    metadata: JSON.parse(d.metadata),
                }));
            } else if(i.parents && i.offspring) {
                parsed.parents = i.parents.map(p => p ? p.id : p);
                parsed.offspring = i.offspring.map(o => o ? o.id : o);
            }
            agg[i.interactable.id] = parsed;
            return agg;
        }, {});
        return Object.assign({}, state, loadedInteractions);
    }
    if(action.type === SET_LONG_TEXT_INPUT_TEXT) {
        const interaction = Object.assign({}, state[action.interactableId], {
            text: action.text,
        });
        return Object.assign({}, state, { [action.interactableId]: interaction });
    }
    if(action.type === SET_MULTIPLE_CHOICE_INPUT_SELECTION) {
        const interaction = {
            ...state[action.interactableId],
            selection: action.selection,
        };
        return {
            ...state,
            [action.interactableId]: interaction,
        }
    }
    if(action.type === SET_INPUT_TABLE_INPUT) {
        const inputs = state[action.inputTableId] ? state[action.inputTableId].inputs : [];
        let newInputs = inputs.filter(i => i.inputId !== action.inputData.inputId).concat(action.inputData);
        return {
            ...state,
            [action.inputTableId]: {
                ...state[action.inputTableId],
                inputs: newInputs,
            },
        };
    }
    if(action.type === DRAGGABLE_PLACED_IN_DROP_AREA) {
        const {draggableId, dropAreaId} = action;
        const newItem = {
            id: draggableId,
            type: action.draggableType,
            metadata: action.metadata,
        };
        const newState = {
            ...state,
            [dropAreaId]: {
                ...state[dropAreaId],
                draggables: state[dropAreaId] ? state[dropAreaId].draggables.concat(newItem) : [newItem]
            },
        };
        return removeDraggable(action.startingLocation, draggableId, newState);
    }
    if(action.type === DROP_AREA_DRAGGABLES_SET) {
        return {
            ...state,
            [action.dropAreaId]: {
                ...state[action.dropAreaId],
                draggables: action.draggables,
            },
        };
    }
    if(action.type === SET_ITEM_POSITION_ACTION && state[action.startingLocation]) {
        const {itemId, startingLocation} = action;
        return removeDraggable(startingLocation, itemId, state);
    }
    if(action.type === REMOVE_DRAGGABLE_FROM_DROP_AREA) {
        const {dropAreaId} = action;
        return {
            ...state,
            [dropAreaId]: {
                ...state[dropAreaId],
                draggables: state[dropAreaId].draggables.filter(d => d.id !== action.draggableId),
            }
        }
    }
    if(action.type === CREATE_WORK_BOARD_FOR_DEFINITION) {
        return {
            ...state,
            [action.workBoardDefinitionId]: {
                ...state[action.workBoardDefinitionId],
                workBoard: action.workBoardId,
            },
        };
    }
    if(action.type === REMOVE_WORK_BOARD_FOR_DEFINITION) {
        return {
            ...state,
            [action.workBoardDefinitionId]: {
                ...state[action.workBoardDefinitionId],
                workBoard: null,
            },
        };
    }
    if(action.type === PLACE_PLANT_IN_CROSS) {
        const {crossCardId, plantCardId, slotIndex} = action;
        const cross = state[crossCardId] || {
            parents: [null, null],
            offspring: [],
        };
        let updated;
        if(action.parent) {
            updated = {
                ...cross,
                parents: cross.parents.map((p, i) => i === slotIndex ? plantCardId : p),
            };
        } else {
            const offspring = rearrangeArray(cross.offspring, plantCardId, slotIndex);
            updated = {
                ...cross,
                offspring,
            };
        }
        const newState = {
            ...state,
            [crossCardId]: updated,
        };
        return removeDraggable(action.startingLocation, plantCardId, newState);
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    if(action.type === EMPTY_INTERACTIONS) { // there may be a better way...
        return {};
    }
    return state;
};

const removeDraggable = (startingLocation, itemId, state) => {
    if(!startingLocation || !state[startingLocation]) { return state; }
    if(state[startingLocation].draggables) {
        return {
            ...state,
            [startingLocation]: {
                ...state[startingLocation],
                draggables: state[startingLocation].draggables.filter(i => i.id !== itemId),
            },
        };
    }
    if(state[startingLocation].parents && state[startingLocation].offspring) {
        let parents = state[startingLocation].parents;
        if(parents.includes(itemId)) {
            parents = parents.map(i => i === itemId ? null : i);
        }
        let offspring = state[startingLocation].offspring;
        if(offspring.includes(itemId)) {
            offspring = offspring.filter(i => i !== itemId);
        }
        return {
            ...state,
            [startingLocation]: {
                ...state[startingLocation],
                parents,
                offspring,
            },
        };
    }
    return state;
}
