import * as styles from './space-adder-styles';
import React, {useRef, useState} from 'react';
import {css} from "@emotion/core";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {closeModalType} from "../../../../../store/navigation/actions";
import {ADD_SPACE_TO_SCROLLLING_CANVAS} from "../../../../../constants/modalTypes";
import {useParams} from "@reach/router";
import {addSpaceToScrollerTask} from "../../../../../store/curriculumTask/actions";
import {getCurriculumTaskItemIds} from "../../../../../store/curriculumTask/selectors";

export default ({scroller}) => {
    const {curriculumId, taskId} = useParams();
    const itemIds = useSelector(state => getCurriculumTaskItemIds(state, taskId), shallowEqual);
    const [top, setTop] = useState(275);
    const [height, setHeight] = useState(100);
    const bodyRef = useRef();
    const dispatch = useDispatch();

    const startMove = (shouldSetTop, shouldSetHeight) => e => {
        e.stopPropagation();
        e.preventDefault();
        const {clientY: startY} = e;
        const {height: parentHeight} = bodyRef.current.parentElement.getBoundingClientRect();

        const calculate = e => {
            const {clientY: currentY} = e;
            let deltaY = Math.max(-top, currentY - startY);
            const newTop = shouldSetTop ? Math.min(parentHeight - height, Math.max(0, top + deltaY)) : top;
            let maxHeight = parentHeight - newTop;
            const newHeight = shouldSetHeight ? Math.max(100, Math.min(maxHeight, height + deltaY * (shouldSetTop ? -1 : 1))) : height;
            return {newTop, newHeight};
        }

        let animation;
        const dragHandler = e => {
            if(animation) {
                cancelAnimationFrame(animation);
            }
            animation = requestAnimationFrame(() => {
                const {newTop, newHeight} = calculate(e);
                bodyRef.current.style.top = `${newTop}px`;
                bodyRef.current.style.height = `${newHeight}px`;
            });
        };
        document.body.addEventListener("mousemove", dragHandler);

        const endHandler = e => {
            document.body.removeEventListener("mousemove", dragHandler);
            document.body.removeEventListener("mouseup", endHandler);
            const {newTop, newHeight} = calculate(e);
            setTop(newTop);
            setHeight(newHeight);
        };
        document.body.addEventListener("mouseup", endHandler);
    };

    const measurerStyle = css(styles.measurer, {
        top,
        height,
    });

    const addSpace = () => {
        dispatch(addSpaceToScrollerTask(curriculumId, taskId, itemIds, top + scroller.current.scrollTop, height));
        dispatch(closeModalType(ADD_SPACE_TO_SCROLLLING_CANVAS));
    };

    return (
        <div css={styles.container}>
            <div css={measurerStyle} onMouseDown={startMove(true, false)} ref={bodyRef}>
                <div css={styles.barTop} onMouseDown={startMove(true, true)} />
                <div css={styles.barBottom} onMouseDown={startMove(false, true)} />
                <button onClick={addSpace} css={styles.addButton}>+</button>
            </div>
        </div>
    );
}
