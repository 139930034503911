import {POLAROID} from "../../../constants/WorkBoardItemTypes";
import {importPolaroids} from "../../../store/polaroids/actions";
import {dropAreaDraggablesSet} from "../../../store/interactions/actions";

export default (dispatch, eventData) => {
    const dropAreaId = eventData.interaction.interactable.id;
    const draggables = eventData.interaction.draggables;
    const dropAreaDraggables = draggables.map(d => ({
        id: d.item.id,
        type: d.item.__typename,
        metadata: JSON.parse(d.metadata),
    }));
    // TODO: plant cards
    const polaroids = draggables.filter(p => p.item.__typename === POLAROID).map(p => ({
        id: p.item.id,
        imageUrl: p.item.imageUrl,
    }));
    dispatch(importPolaroids(polaroids));
    dispatch(dropAreaDraggablesSet(dropAreaId, dropAreaDraggables));
};
