import "./add-learner-modal.scss";

import React, {useRef} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {getAddLearnerEmailList, getModalShowLoading} from "../../store/addLearnerModal/selectors";
import {addEmailToLearnerList, closeAddLearnerModal} from "../../store/addLearnerModal/action";

const stateMap = state => ({
    emails: getAddLearnerEmailList(state),
    loading: getModalShowLoading(state),
});

const dispatchMap = dispatch => ({
    addEmails: e => {
        if(e.which === 13) {
            dispatch(addEmailToLearnerList(e.target.value));
            e.target.value = "";
        }
    },
});

export default props => {
    const inputRef = useRef(null);
    const {emails, loading} = useSelector(stateMap);
    const {addEmails} = dispatchMap(useDispatch());
    const close = () => {
        const inputValue = inputRef.current.value;
        if(inputValue.length > 0) {
            // TODO: really?!? eewwww. There must be a better way (using the action here to parse the emails... might mean parsing before sending to action
            return props.close(emails.concat(addEmailToLearnerList(inputValue).emails));
        }
        props.close(emails);
    };

    return (
        <React.Fragment>
            <div className='add-learner-modal__blocker' />
            <div className='add-learner-modal'>
                { loading
                    ? (<div className='add-learner-modal__loading'>Loading...</div>)
                    : (
                        <React.Fragment>
                            <div className='add-learner-modal__instructions'>Type in emails individually or as a comma separated list and press "Enter" to add to the list.</div>
                            <div className='add-learner-modal__email-container'>
                                {emails.map((e, i) => <div className='add-learner-modal__email' key={i}>{e}</div>)}
                            </div>
                            <input ref={inputRef} className='add-learner-modal__add' onKeyPress={addEmails} />
                            <button className='add-learner-modal__close' onClick={close}>Finish</button>
                        </React.Fragment>
                    )
                }
            </div>
        </React.Fragment>
    );
};