import * as styles from './mrna-slot-styles';
import React, {useState} from 'react';
import {getFirstHeldObjectType} from "../../../../store/interactions/dogmaDash/selectors";
import {useDispatch, useSelector} from "react-redux";
import {addItemToOrganelle} from "../../../../store/interactions/dogmaDash/actions";
import {useParams} from "@reach/router";
import Protein, {DRAGGABLE_DOGMA_PROTEIN} from "./Protein";
import {useDrop} from "react-dnd";
import {absoluteCenter} from "../../../../style-variables";
import {ProteinForm_MRNA} from "../../../../constants/DogmaDash";

const MrnaSlot = ({id, dogmaDashId}) => {
    const {curriculumId} = useParams();
    const [showDropAnimation, setShowDropAnimation] = useState(false);
    const heldObjectType = useSelector(state => getFirstHeldObjectType(state, id));
    const dispatch = useDispatch();

    const [{over}, dropRef] = useDrop({
        accept: DRAGGABLE_DOGMA_PROTEIN,
        canDrop: item => !heldObjectType && item.form === ProteinForm_MRNA,
        drop: (dropped) => {
            dispatch(addItemToOrganelle(curriculumId, dogmaDashId, id, dropped.protein))
            setShowDropAnimation(true);
            setTimeout(() => setShowDropAnimation(false), 500);
        },
        collect: m => ({ over: m.isOver() && m.canDrop() }),
    });

    // const slotStyle = showDropAnimation
    //     ? styles.slotDropAnimation
    //     : over
    //     ? styles.hoveringSlot
    //     : styles.slot;

    return (
        <div ref={dropRef} css={styles.slotContainer}>
            <div css={styles.slot}>
                {heldObjectType && <div css={absoluteCenter}>
                    <Protein type={heldObjectType} showName={false} interactable={false} />
                </div>}
            </div>
        </div>
    )
};

export default MrnaSlot;
