import "./video.scss";
import React, {useRef, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {isModalOpenFor} from "../../store/navigation/selectors";
import {openModalFor} from "../../store/navigation/actions";
import {ReactEditor, useSlate} from "slate-react";
import {Transforms} from "slate";
import {EDITOR_COMPONENT_VIDEO} from "../editorComponents/Video";
import {ADD_VIDEO_TO_RICH_TEXT_MODAL} from "../../constants/modalTypes";

const stateMap = props => state => ({
    showingVideoInsert: isModalOpenFor(state, ADD_VIDEO_TO_RICH_TEXT_MODAL, props.editorId),
});

const youTubeVideoId = providedUrl => {
    const shareMatches = providedUrl.match(/youtu.be\/(.*)/);
    if(shareMatches && shareMatches.length > 1) {
        return shareMatches[1];
    }
    const urlMatches = providedUrl.match(/youtube.com\/watch?.*v=(.*)&?/);
    if(urlMatches && urlMatches.length > 1) {
        return urlMatches[1];
    }
    return null;
};
const createYouTubeEmbedUrl = providedUrl => {
    const id = youTubeVideoId(providedUrl);
    if(!id) { return null; }
    return `https://www.youtube.com/embed/${id}`;
};

export default props => {
    const {editorId} = props;
    const editor = useSlate();
    const {showingVideoInsert} = useSelector(stateMap(props), shallowEqual);
    const dispatch = useDispatch();
    const [selection, setSelection] = useState();
    const videoUrlRef = useRef();

    const openVideoInsert = e => {
        e.preventDefault();
        e.stopPropagation();
        if(!editor.selection) { return; }
        setSelection(editor.selection);
        dispatch(openModalFor(ADD_VIDEO_TO_RICH_TEXT_MODAL, editorId));
    };
    const focusEditor = () => {
        ReactEditor.focus(editor);
        Transforms.select(editor, selection);
    };
    const closeVideoInsert = () => {
        dispatch(openModalFor());
        focusEditor();
    };
    const insertVideo = () => {
        focusEditor();
        const url = createYouTubeEmbedUrl(videoUrlRef.current.value);
        if(!url) { return; } // TODO: better handling
        Transforms.insertNodes(editor, {
            type: EDITOR_COMPONENT_VIDEO,
            url,
            children: [{text: ""}]
        });
        Transforms.move(editor, {
            distance: 1,
            unit: 'offset',
        });
        dispatch(openModalFor());
    };

    return (
    <>
        {showingVideoInsert &&
        <div className="rich-editor--insert-video__blocker">
            <div className="rich-editor--insert-video">
                <h2 className="insert-video--modal-header">Insert Video</h2>
                <div className="insert-video--url-container">
                    <label htmlFor="video-url-input" className="insert-video--video-url-label">YouTube URL:</label>
                    <input ref={videoUrlRef} id="video-url-input" className="insert-video--video-url-input" placeholder="https://youtu.be/..." />
                </div>
                <div className="insert-video--button-container">
                    <button onMouseDown={insertVideo} className="insert-video--insert-button">Insert</button>
                    <button onMouseDown={closeVideoInsert} className="insert-video--cancel-button">Cancel</button>
                </div>
            </div>
        </div>}
        <button onMouseDown={openVideoInsert} className="rich-editor--style-button rich-editor--style-button__video" />
    </>
    );
}
