export const REQUEST_LTI_PLATFORM_REGISTRATION = "request lti platform registration";
export const requestLtiPlatformRegistration = (platformUrl, clientId, name, authenticationEndpoint, accessTokenEndpoint, authKeysData) => ({
    type: REQUEST_LTI_PLATFORM_REGISTRATION,
    platformUrl,
    clientId,
    name,
    authenticationEndpoint,
    accessTokenEndpoint,
    authKeysData,
});

export const LTI_PLATFORM_REGISTRATION_REQUEST_ANSWERED = "lti platform registration request answered";
export const ltiPlatformRegistrationRequestAnswered = (errors) => ({
    type: LTI_PLATFORM_REGISTRATION_REQUEST_ANSWERED,
    errors,
});
