export const SET_DOGMA_DASH_MAX_MRNA = 'set dogmash max mrna';
export const setDogmaDashMaxMrna = (dogmaDashId, number, curriculum) => ({
    type: SET_DOGMA_DASH_MAX_MRNA,
    dogmaDashId,
    number,
    curriculum,
});

export const SET_DOGMA_DASH_MAX_MITOCHONDRIA = 'set dogmash max mitochondria';
export const setDogmaDashMaxMitochondria = (dogmaDashId, number, curriculum) => ({
    type: SET_DOGMA_DASH_MAX_MITOCHONDRIA,
    dogmaDashId,
    number,
    curriculum,
});

export const SET_DOGMA_DASH_MAX_CELL_MEMBRANES = 'set dogmash max membranes';
export const setDogmaDashMaxCellMembranes = (dogmaDashId, number, curriculum) => ({
    type: SET_DOGMA_DASH_MAX_CELL_MEMBRANES,
    dogmaDashId,
    number,
    curriculum,
});

export const SET_DOGMA_DASH_MAX_LYSOSOMES = 'set dogmash max lysosomes';
export const setDogmaDashMaxLysosomes = (dogmaDashId, number, curriculum) => ({
    type: SET_DOGMA_DASH_MAX_LYSOSOMES,
    dogmaDashId,
    number,
    curriculum,
});

export const SET_DOGMA_DASH_MAX_GOLGI_BODIES = 'set dogmash max golgi';
export const setDogmaDashMaxGolgiBodies = (dogmaDashId, number, curriculum) => ({
    type: SET_DOGMA_DASH_MAX_GOLGI_BODIES,
    dogmaDashId,
    number,
    curriculum,
});

export const SET_DOGMA_DASH_MAX_RIBOSOMES = 'set dogmash max ribosomes';
export const setDogmaDashMaxRibosomes = (dogmaDashId, number, curriculum) => ({
    type: SET_DOGMA_DASH_MAX_RIBOSOMES,
    dogmaDashId,
    number,
    curriculum,
});

export const SET_DOGMA_DASH_MAX_MICROTUBULES = "set dogmash max microtubles";
export const setDogmaDashMaxMicrotubules = (dogmaDashId, number, curriculum) => ({
    type: SET_DOGMA_DASH_MAX_MICROTUBULES,
    dogmaDashId,
    number,
    curriculum,
});
