import {takeEvery, call, select} from 'redux-saga/effects';
import {getPeaPlant} from "../../../store/peaPlants/selectors";
import {ADD_PEA_PLANT_MATERIAL_TO_WORK_BOARD} from "../../../store/curriculumTaskItems/workBoardDefinition/actions";
import {sendGraphQLSaga} from "../../DataService";

const addPeaPlantDefinition = `mutation AddPeaPlantMaterialToWorkBoard($input: AddPeaPlantMaterialToWorkBoardInput!) {
    addPeaPlantMaterialToWorkBoard(input: $input) {
        workBoardDefinition {
            id
        }
    }
}`;

function* execute(action) {
    const peaPlant = select(state => getPeaPlant(state, action.plantId));

    const variables = {
        input: {
            id: action.materialId,
            curriculum: action.curriculumId,
            workBoardDefinition: action.workBoardDefinitionId,
            peaPlant: peaPlant,
        },
    };

    yield call(sendGraphQLSaga, addPeaPlantDefinition, variables);
}

export default [takeEvery(ADD_PEA_PLANT_MATERIAL_TO_WORK_BOARD, execute)];
