import {css} from "@emotion/core";
import {AVENIR_FONT, FONT_BOLD_WEIGHT, FONT_HEAVY_WEIGHT} from "../../../../../style-variables";

// TODO: don't love this location for the h1, strong font styling.
export const text = css({
    width: "100%",
    height: "100%",
    fontFamily: `${AVENIR_FONT}, sans-serif`,

    "& h1": {
        fontWeight: FONT_HEAVY_WEIGHT,
        fontSize: "6em",
        margin: "12px 0",
    },

    "& h2": {
        fontWeight: FONT_HEAVY_WEIGHT,
        fontSize: "3em",
        margin: "6px 0",
    },

    "& strong": {
        fontWeight: FONT_BOLD_WEIGHT,
    },
});

export const textPlaceholder = css({
    color: "#999",
    fontStyle: "italic",
});
