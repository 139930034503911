import {
    DOGMA_DASH_ALERT_LIST,
    DOGMA_DASH_MRNA_INVENTORY,
    DOGMA_DASH_NUCLEUS,
    DOGMA_DASH_ORGANELLE_DEBRIS
} from "./dogma-strand-1";
import {
    getDogmaDashAvailableProteins, getDogmaDashCurrentView,
    getDogmaDashLevelUnlockingProtein,
    getDogmaLysosomeIds, getObjectForm, getObjectsInOrganelle,
    getOrganelleTimerDuration,
    getOrganelleTimerType
} from "../../interactions/dogmaDash/selectors";
import {CELL_VIEW, ProteinForm_ProteinInVesicle, Recycle} from "../../../constants/DogmaDash";
import {MRNA_INVENTORY_PROTEIN_TUTORIAL} from "./dogma-strand-antibody-unlock";

export default  {
    id:"4a14f834-8638-40da-9ef8-a84b24d376f1",
    items: [
        {
            condition: (state, {location, dogmaDashId}) => {
                if(!dogmaDashId || location !== DOGMA_DASH_ALERT_LIST) {return false;}
                const unlockingProtein = getDogmaDashLevelUnlockingProtein(state, dogmaDashId);
                const lysosomeAlert = getDogmaLysosomeIds(state, dogmaDashId).filter(oId => !!getOrganelleTimerDuration(state, oId) && getOrganelleTimerType(state, oId) === "Alert").length > 0;
                const unlockedProteins = getDogmaDashAvailableProteins(state, dogmaDashId);
                return unlockedProteins?.indexOf(Recycle) < 0 && unlockingProtein === Recycle && lysosomeAlert;
            },
            header: "New Alert Type",
            text: "The Lysosome is unable to recycle debris. Replenish its enzyme.",
            forceCompletion: (state, {dogmaDashId}) => getDogmaDashCurrentView(state, dogmaDashId) !== CELL_VIEW,
        },
        {
            condition: (state, {location}) => location === DOGMA_DASH_MRNA_INVENTORY,
            header: "Missing mRNA",
            text: "You do not have mRNA that codes for the protein the Lysosome needs.",
            forceCompletion: (state, {dogmaDashId}) => getDogmaDashCurrentView(state, dogmaDashId) !== CELL_VIEW || getDogmaDashAvailableProteins(state, dogmaDashId).indexOf(Recycle) >= 0,
        },
        {
            condition: (state, {location}) => location === DOGMA_DASH_NUCLEUS,
            header: "Enter the Nucleus",
            text: "Click on the nucleus to get access to the cell’s DNA",
            completionCondition: (state, {dogmaDashId}) => getDogmaDashCurrentView(state, dogmaDashId) !== CELL_VIEW
                || getDogmaDashAvailableProteins(state, dogmaDashId).indexOf(Recycle) > 0,
        },
        {
            condition: (state, {location, dogmaDashId, protein}) => {
                if(location !== MRNA_INVENTORY_PROTEIN_TUTORIAL || protein !== Recycle) {return false; }
                const unlockingProtein = getDogmaDashLevelUnlockingProtein(state, dogmaDashId);
                const unlockedProteins = getDogmaDashAvailableProteins(state, dogmaDashId);
                return unlockingProtein === Recycle && unlockedProteins.indexOf(Recycle) >= 0;
            },
            width: 460,
            header: "Recycler Enzyme mRNA",
            text: "<b>You have unlocked a new mRNA!</b> You can drop the mRNA to the pilot whenever the cell is unable to break down debris.",
        },
        {
            condition: (state, {location, organelleId, dogmaDashId}) => {
                if(location === DOGMA_DASH_ORGANELLE_DEBRIS) {return false;}
                const lysosomes = getDogmaLysosomeIds(state, dogmaDashId);
                return lysosomes.indexOf(organelleId) >= 0 && getObjectsInOrganelle(state, organelleId).filter(o => getObjectForm(state, o) === ProteinForm_ProteinInVesicle).length > 0;
            },
            header: "Lysosome is Ready",
            text: "You can now use the lysosome to break down debris again!",
        },
    ],
};
