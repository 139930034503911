import {MRNA_INVENTORY_PROTEIN_TUTORIAL} from "./dogma-strand-antibody-unlock";
import {Antibody2} from "../../../constants/DogmaDash";

export default {
    id: "09607446-9fcd-4a9c-a0c7-10f549644b88",
    items: [
        {
            condition: (state, {location, dogmaDashId, protein}) => location === MRNA_INVENTORY_PROTEIN_TUTORIAL && protein === Antibody2,
            width: 460,
            header: "New Antibody",
            text: "Your newly designed antibody is ready for use.",
        },
    ],
};
