import {ADD_ITEM_TO_SCROLLING_TASK} from "../scrollingTaskItemPositions/actions";
import {SCROLLER_SECTION} from "../../constants/TaskTypes";
import {SET_SCROLLER_SECTION_BACKGROUND_COLOR} from "./actions";
import {CURRICULA_LOADED} from "../curricula/actions";

const defaultSection = {
    backgroundColor: "#ccc",
}

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const newItems = action.curricula.reduce((agg, c) => ({
            ...agg,
            ...(c.tasks || []).reduce((taskAgg, t) =>
                ({
                    ...taskAgg,
                    ...t.items.filter(i => i.__typename === SCROLLER_SECTION).reduce((itemsAgg, i) => ({
                        ...itemsAgg,
                        [i.id]: {
                            ...defaultSection,
                            backgroundColor: i.backgroundColor,
                        },
                    }), {}),
                }), {}),
        }), {});
        return {
            ...state,
            ...newItems,
        };
    }
    if(action.type === ADD_ITEM_TO_SCROLLING_TASK && action.itemType === SCROLLER_SECTION) {
        return {
            ...state,
            [action.itemId]: {
                ...defaultSection,
            },
        };
    } else if(action.type === SET_SCROLLER_SECTION_BACKGROUND_COLOR) {
        return {
            ...state,
            [action.sectionId]: {
                ...state[action.sectionId],
                backgroundColor: action.color,
            },
        };
    }
    return state;
}
