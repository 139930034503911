export const SET_ALL_BOARDS_ACTION = "Set all boards action";
export const setAllBoards = allBoards => ({
    type: SET_ALL_BOARDS_ACTION,
    allBoards,
});

export const ITEM_ADDED_TO_BOARD_ACTION = "Item added to board";
export const itemAddedToBoard = (boardId, item) => ({
    type: ITEM_ADDED_TO_BOARD_ACTION,
    boardId,
    item,
});

export const ADD_ITEM_TO_BOARD_ACTION = "Add item to board";
export const addItemToBoard = (boardId, item) => ({
    type: ADD_ITEM_TO_BOARD_ACTION,
    boardId,
    item
});

export const ITEM_REMOVED_FROM_BOARD = "Item removed from board";
export const itemRemovedFromBoard = (boardId, itemId) => ({
    type: ITEM_REMOVED_FROM_BOARD,
    boardId,
    itemId
});

// I think this is going to go away long term, to be replaced with a "workBoardOrderUpdated"
export const ITEM_MOVED_ON_WORKBOARD = 'Item moved on work board';
export const itemMovedOnWorkBoard = dispatch => (boardId, itemId, x, y, angle) => dispatch({
    type: ITEM_MOVED_ON_WORKBOARD,
    boardId,
    itemId,
    x,
    y,
    angle,
});

export const SET_ITEM_POSITION_ACTION = 'Set polaroid position';
export const setItemPosition = (curriculumId, boardId, itemId, newIndex, startingLocation) => ({
    type: SET_ITEM_POSITION_ACTION,
    curriculumId,
    boardId,
    itemId,
    newIndex,
    startingLocation,
});

export const WORK_BOARD_ITEMS_LOADED = "Work board items loaded";
export const workBoardItemsLoaded = (boardId, items) => ({
    type: WORK_BOARD_ITEMS_LOADED,
    boardId,
    items,
});

export const CLONE_WORK_BOARD_ITEM = "Clone work board item";
export const cloneWorkBoardItem = (itemId, newId, newWorkBoardId, index) => ({
    type: CLONE_WORK_BOARD_ITEM,
    itemId,
    newId,
    newWorkBoardId,
    index
});

export const CLONE_PLANT_CARD_AS_PHENOTYPE = "Clone plant card into mode";
export const clonePlantCardIntoMode = (cardId, newId, workBoardId, index, trait) => ({
    type: CLONE_PLANT_CARD_AS_PHENOTYPE,
    cardId,
    newId,
    workBoardId,
    index,
    trait,
});
