import * as styles from './explainer-prediction-styles';
import * as explainerStyles from './explainer-styles';
import React, {useContext} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "@reach/router";
import {getPredictionExplainer} from "../../../../store/curriculumTaskItems/explanationPredictions/selectors";
import {getExplainerQuestion} from "../../../../store/curriculumTaskItems/explainer/selectors";
import {getLearnerPredictionForExplainer} from "../../../../store/interactions/explainerPrediction/selectors";
import {setExplainerPrediction} from "../../../../store/interactions/explainerPrediction/actions";
import RichTextDisplay from "../../RichTextDisplay";
import {useTheme} from "@emotion/react";

const ExplainerPrediction = ({id}) => {
    const {curriculumId} = useParams();
    const explainerForPrediction = useSelector(state => getPredictionExplainer(state, id));
    const explainerQuestion = useSelector(state => explainerForPrediction ? getExplainerQuestion(state, explainerForPrediction) : "");
    const prediction = useSelector(state => getLearnerPredictionForExplainer(state, id));
    const dispatch = useDispatch();
    const theme = useTheme();

    const setPrediction = e => dispatch(setExplainerPrediction(curriculumId, id, e.target.value));

    return (
        <div css={styles.container}>
            <div css={explainerStyles.explainerBlock}>
                <h2 css={explainerStyles.themeHeaderLabel(theme)}>Question:</h2>
                <span css={explainerStyles.headerText}>
                    <RichTextDisplay content={explainerQuestion} />
                </span>
            </div>
            <h2 css={explainerStyles.themeHeaderLabel(theme)}>Prediction:</h2>
            <textarea css={explainerStyles.themeExplainerTextInput(theme)} onBlur={setPrediction} defaultValue={prediction} />
            <span css={explainerStyles.inputSubtext}>Based on what you already know, write down your best attempt at answering the question.</span>
        </div>
    );
};

export default ExplainerPrediction;
