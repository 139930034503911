import {getDefinitionForWorkBoard} from "../interactions/selectors";
import {WORK_BOARD_CROSS_CARD, WORK_BOARD_DROP_SLOT} from "../../constants/TaskTypes";

export const getWorkBoardTemplate = (workBoardDefinitionId, state) => state.workBoardTemplate[workBoardDefinitionId];
const getWorkBoardTemplateItem = (workBoardDefinitionId, itemId, state) => getWorkBoardTemplate(workBoardDefinitionId, state).find(i => i.id === itemId);
export const getWorkBoardTemplateItemType = (workBoardDefinitionId, itemId, state) => {
    if(workBoardDefinitionId) { return getWorkBoardTemplateItem(workBoardDefinitionId, itemId, state).type; }
    const item = Object.keys(state.workBoardTemplate).reduce((i, template) => i || getWorkBoardTemplateItem(template, itemId, state), null);
    return item ? item.type : null;
};

export const getDropAreasForWorkBoard = (workBoardId, state) => {
    const definitionId = getDefinitionForWorkBoard(workBoardId, state);
    const template = getWorkBoardTemplate(definitionId, state);
    if(!template) { return []; }
    return template.filter(item => item.type === WORK_BOARD_DROP_SLOT);
};
export const getCrossCardsForWorkBoard = (workBoardId, state) => {
    const definitionId = getDefinitionForWorkBoard(workBoardId, state);
    const template = getWorkBoardTemplate(definitionId, state);
    if(!template) { return []; }
    return template.filter(item => item.type === WORK_BOARD_CROSS_CARD);
};
