import {css} from "@emotion/core";
import {
    bodyFontColor,
    bodyFontFamily,
    bodyFontWeight, headerBackground,
    headerBorderColor, headerPadding,
    logoutBackground,
    logoutButtonFontColor,
    logoutButtonFontFamily,
    logoutButtonFontSize,
    logoutButtonFontWeight,
    titleFontColor,
    titleFontFamily, titleFontSize, titleFontTextTransform,
    titleFontWeight,
    userNameFontColor,
    userNameFontFamily,
    userNameFontSize,
    userNameFontWeight
} from "./themes/selectors";
import {bbPadding, header, identityName, logout} from "../style-variables";

export const container = css({
    width: "100%",
    height: "100%",
    fontFamily: `Avenir Next, sans-serif`,
    position: "relative",
});
export const themedContainer = theme => css(container, {
    fontFamily: bodyFontFamily(theme),
    fontWeight: bodyFontWeight(theme),
    color: bodyFontColor(theme),
});

export const headerContainer = header;
export const themedHeader = theme => css(headerContainer, {
    padding: headerPadding(theme),
    backgroundColor: headerBackground(theme),
    borderBottomColor: headerBorderColor(theme),

    "& > h1": {
        fontFamily: titleFontFamily(theme),
        color: titleFontColor(theme),
        weight: titleFontWeight(theme),
        fontSize: titleFontSize(theme),
        textTransform: titleFontTextTransform(theme),
    },
});

export const name = identityName;
export const themedName = theme => css(name, {
    fontFamily: userNameFontFamily(theme),
    fontWeight: userNameFontWeight(theme),
    fontSize: userNameFontSize(theme),
    color: userNameFontColor(theme),
});

export const logoutButton = logout;
export const themedLogoutButton = theme => css(logoutButton, {
    backgroundColor: logoutBackground(theme),
    fontFamily: logoutButtonFontFamily(theme),
    fontSize: logoutButtonFontSize(theme),
    fontWeight: logoutButtonFontWeight(theme),
    color: logoutButtonFontColor(theme),
});
