import uuid from 'uuid/v4';

export const SET_ALL_COHORTS = "Set all cohorts";
export const setAllCohorts = dispatch => cohorts => dispatch({
    type: SET_ALL_COHORTS,
    cohorts
});

export const CREATE_COHORT = "Create Cohort";
export const createCohort = dispatch => () => dispatch({
    id: uuid(),
    type: CREATE_COHORT,
});

export const SET_COHORT_NAME = "Set cohort name";
export const setCohortName = dispatch => (cohortId, name) => dispatch({
    type: SET_COHORT_NAME,
    cohortId,
    name,
});

export const REPLACE_LEARNERS_IN_COHORT = "Add learner to cohort";
export const replaceLearnersInCohort = dispatch => (cohortId, learners) => dispatch({
    type: REPLACE_LEARNERS_IN_COHORT,
    cohortId,
    learners
});

export const COHORT_ASSIGNED_CURRICULA_LOADED = "Cohort assigned curricula loaded";
export const cohortAssignedCurriculaLoaded = (cohortId, curricula) => ({
    type: COHORT_ASSIGNED_CURRICULA_LOADED,
    cohortId,
    curricula,
});
