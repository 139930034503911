import {image as imageStyle, imageInput, imageInputLabel, imageItem} from './image-style';
import React, {useRef, useState} from 'react';
import {useParams} from "@reach/router";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getScrollingItemContent} from "../../../../../store/scrollingTaskItemPositions/selectors";
import {isItemEditing} from "../../../../../store/itemSelection/selector";
import imageUpload from "../../../../../Utility/imageUpload";
import {
    setScrollingItemContent,
    setScrollingItemHeight,
    setScrollingItemWidth
} from "../../../../../store/scrollingTaskItemPositions/actions";
import {SCROLLING_CANVAS_WIDTH} from "../scrolling-styles";

export default ({id}) => {
    const {curriculumId} = useParams();
    const url = useSelector(getScrollingItemContent.bind(null, id), shallowEqual);
    const editing = useSelector(isItemEditing.bind(null, id), shallowEqual);
    const inputRef = useRef();
    const [uploading, setUploading] = useState(false);
    const dispatch = useDispatch();

    const setFile = async e => {
        setUploading(true);
        const fileData = e.target.files[0];
        const url = await imageUpload(fileData, dispatch);

        const img = document.createElement("img");
        img.onload = () => {
            let {width, height} = img;
            if(width > SCROLLING_CANVAS_WIDTH) {
                const ratio = height / width;
                width = SCROLLING_CANVAS_WIDTH;
                height = ratio * width;
            }
            dispatch(setScrollingItemWidth(curriculumId, id, 0, width));
            dispatch(setScrollingItemHeight(curriculumId, id, 0, height));
            img.onload = null;
        };
        img.src = url;

        setUploading(false);
        dispatch(setScrollingItemContent(curriculumId, id, url));
    };
    const capture = e => e.stopPropagation();

    return (
        <div css={imageItem}>
            { (!url || editing) && <>
                <label ref={inputRef} css={imageInputLabel} onClick={capture}>
                    <input id={`${id}-input`} type="file" css={imageInput} onChange={setFile} />
                    Upload image
                </label>
            </>}
            {uploading && <div className="load-spinner" /> }
            {!uploading && url && <img css={imageStyle} src={url} />}
        </div>
    );
}
