import {css} from "@emotion/core";
import {bbPadding, DARK_GRAY, WORK_BOARD_WIDTH} from "../../../../../style-variables";

export const templateContainer = css({
    minWidth: "100px",
    minHeight: "80px",
    maxWidth: WORK_BOARD_WIDTH,
    border: `1px solid ${DARK_GRAY}`,
    margin: "6px 0 0 0",
    position: 'relative',

    "&:before": {
        content: `"Right click to add an item"`,
        fontSize: "1.125em",
        color: DARK_GRAY,
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        opacity: 0,
        transition: "opacity 0.2s",
    },
    "&:empty:before": {
        opacity: 1,
    },
}, bbPadding("8px"));
