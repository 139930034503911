import "./learn.scss";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Router, navigate} from "@reach/router";
import {getActiveLearner} from "../store/navigation/selectors";
import {getSubscriptionClient} from "../store/data/selectors";
import VerdantV1 from "./VerdantV1";
import CurriculumRoot from "./CurriculumRoot";
import CurriculumChoice from "./CurriculumChoice";
import {getCurriculumId} from "../store/curriculum/selectors";
import learnerEvent from "../graphql/subscriptions/learnerEvent";
import {getLoadingLearner} from "../store/dataAccess/selectors";
import {getAuthenticationState} from "../store/identity/selectors";
import {LEARNER, NOT_LOGGED_IN, UNKNOWN} from "../constants/AuthenticationState";
import {tryAuthenticate} from "../store/identity/actions";

const LearnRoot = React.memo(() => {
    const isLoadingLearner = useSelector(getLoadingLearner);
    const loginState = useSelector(getAuthenticationState);
    const learnerId = useSelector(getActiveLearner);
    const verdantV1Id = useSelector(getCurriculumId);
    const subscriptionClient = useSelector(getSubscriptionClient);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!learnerId) { return; }
        learnerEvent(subscriptionClient, dispatch, learnerId); // unsubscribes on call - essentially a singleton
    }, [learnerId]);

    useEffect(() => {
        if(loginState === NOT_LOGGED_IN) {
            navigate("/login/learn");
        }
    }, []);

    if(!learnerId) {
        if(!isLoadingLearner && loginState === UNKNOWN) {
            dispatch(tryAuthenticate(LEARNER));
        }
        return (<div>Loading learner...</div>);
    }

    return (
        <Router className="learner-container">
            <CurriculumChoice path="/" />
            {verdantV1Id && <VerdantV1 path={`/${verdantV1Id}`} />}
            <CurriculumRoot path="/:curriculumId" />
            <CurriculumRoot path="/:curriculumId/:taskId" />
        </Router>
    );
}, () => true);

LearnRoot.displayName = "learnRoot";
export default LearnRoot;
