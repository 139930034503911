import * as styles from "./microtubule-styles";
import React, {useEffect, useRef, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    getObjectsInOrganelle,
    getObjectType,
    getOrganelleBroken,
    getOrganelleDestroyedObjects
} from "../../../../store/interactions/dogmaDash/selectors";
import {ProteinForm_ProteinInVesicle, Structural} from "../../../../constants/DogmaDash";
import MicrotubuleMotors from "./MicrotubuleMotors";
import Protein, {DRAGGABLE_DOGMA_PROTEIN} from "./Protein";
import {useDrop} from "react-dnd";
import {useParams} from "@reach/router";
import {changeCurrentDogmaDashScore, moveDogmaDashObject} from "../../../../store/interactions/dogmaDash/actions";
import {getPendingScoreEventFor} from "../../../../store/interactions/scoring/selectors";
import {pendingScoringEventCompleted} from "../../../../store/interactions/scoring/actions";

const Microtubule = ({id, dogmaId, top}) => {
    const {curriculumId} = useParams();
    const structuralProteins = useSelector(state => getObjectsInOrganelle(state, id).filter(i => getObjectType(state, i) === Structural), shallowEqual);
    const destroyedObjects = useSelector(state => getOrganelleDestroyedObjects(state, id));
    const scoringObjects = useSelector(state => destroyedObjects.map(o => getPendingScoreEventFor(state, o)).filter(e => !!e), shallowEqual);
    const broken = useSelector(state => getOrganelleBroken(state, id));
    const [length, setLength] = useState(0);
    const [previousScoringObjects, setPreviousScoringObjects] = useState(scoringObjects);
    const containerRef = useRef();
    const dispatch = useDispatch();
    const capsules = Math.ceil(length / (styles.capsuleWidth - 2));
    const brokenStart = broken ? Math.floor(capsules / 2) - 2 : capsules + 100;
    const brokenEnd = brokenStart + 3;
    const redStart = brokenStart - 1;
    const redEnd = brokenEnd + 1;

    const [{over}, dropRef] = useDrop({
        accept: DRAGGABLE_DOGMA_PROTEIN,
        canDrop: item => item.protein === Structural && item.form === ProteinForm_ProteinInVesicle,
        drop: dropped => {
            dispatch(moveDogmaDashObject(curriculumId, dogmaId, dropped.id, id));
        },
        collect: m => ({ over: m.isOver() && m.canDrop() }),
    });

    useEffect(() => {
        setLength(containerRef.current.offsetWidth);
    }, []);

    useEffect(() => {
        const newScoringObjects = scoringObjects.filter(s => !previousScoringObjects.some(p => p === s));
        for(let i = 0; i < newScoringObjects.length; ++i) {
            dispatch(changeCurrentDogmaDashScore(dogmaId, newScoringObjects[i].deltaScore));
            setTimeout(() => {
                dispatch(pendingScoringEventCompleted(newScoringObjects[i].scorer));
            }, 2000);
        }
        if(scoringObjects.length !== previousScoringObjects.length) {
            setPreviousScoringObjects(scoringObjects);
        }
    }, [scoringObjects]);

    const capsuleStyle = index => index >= brokenStart && index <= brokenEnd
        ? styles.brokenCapsule
        : index >= redStart && index <= redEnd
        ? styles.redCapsule
        : styles.capsule;
//{Array.from({length: capsules}, (_, i) => <div key={`${i}${Math.random()}`} css={capsuleStyle(i)} />)}
    return (
        <div css={styles.microtubuleContainer}>
            <div ref={containerRef} css={styles.capsuleContainer}>
                {Array.from({length: capsules}, (_, i) => <div key={i} css={capsuleStyle(i)} />)}
                {broken && <div ref={dropRef} className="microtubule-drop-spot-container" css={styles.dropSpotContainer}>
                    <div css={styles.dropSpot}>
                        {structuralProteins.length > 0 && <div css={styles.droppedProtein}>
                            <Protein type={Structural} showName={false} interactable={false} form={ProteinForm_ProteinInVesicle} />
                        </div>}
                    </div>
                </div>}
            </div>
            <div css={styles.proteinContainer}>
                <MicrotubuleMotors id={id} />
            </div>
            {scoringObjects.map(s => <div key={s.scorerId} css={top ? styles.scoreDown : styles.scoreUp}>+{s.deltaScore}</div>)}
        </div>
    );
};

export default Microtubule;
