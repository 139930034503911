import "./footer.scss";
import React from 'react';
import classNames from 'class-names';
import {useSelector, useDispatch, shallowEqual} from "react-redux";
import {getSelectedActivity, getSelectedTopic} from "../../store/navigation/selectors";
import {
    allWebTasksComplete,
    getNextAndPreviousActivity, getReferenceCardUrl, getTopicForActivity,
    isActivityComplete,
    shouldBlockForVR
} from "../../store/curriculum/selectors";
import {selectActiveActivity, selectActiveTopic, setVRBlockerShowing} from "../../store/navigation/actions";

const stateMap = state => {
    const currentActivity = getSelectedActivity(state);
    const vrBlock = shouldBlockForVR(state, currentActivity);
    const currentActivityComplete = allWebTasksComplete(state, currentActivity);
    const {next, previous} = getNextAndPreviousActivity(state, currentActivity);

    return {
        canNavigateForward: currentActivityComplete && !!next,
        canNavigateBackward: !!previous,
        currentTopic: getSelectedTopic(state),
        nextActivity: next,
        nextActivityTopic: getTopicForActivity(state, next),
        previousActivity: previous,
        previousActivityTopic: getTopicForActivity(state, previous),
        vrBlock,
        referenceCardUrl: getReferenceCardUrl(state),
    };
};

export default React.memo(() => {
    const {
        canNavigateForward,
        canNavigateBackward,
        nextActivity,
        previousActivity,
        vrBlock,
        currentTopic,
        nextActivityTopic,
        previousActivityTopic,
        referenceCardUrl,
    } = useSelector(stateMap, shallowEqual);
    const dispatch = useDispatch();

    const goNext = () => {
        if(vrBlock) {
            return dispatch(setVRBlockerShowing(true));
        }
        if(nextActivityTopic !== currentTopic) {
            dispatch(selectActiveTopic(nextActivityTopic));
        }
        dispatch(selectActiveActivity(nextActivity));
    };
    const goBack = () => {
        if(previousActivityTopic !== currentTopic) {
            dispatch(selectActiveTopic(previousActivityTopic));
        }
        dispatch(selectActiveActivity(previousActivity));
    };

    return (
        <div className={classNames({
            "navigation-footer": true,
        })}>
            <button onClick={goBack} className="navigation-footer--back" disabled={!canNavigateBackward}>Back</button>
            {referenceCardUrl && <a target="_blank" href={referenceCardUrl} className="reference-card-link">Pea plant reference card</a>}
            <div className="footer-spacer" />
            <button onClick={goNext} className="navigation-footer--next" disabled={!canNavigateForward}>Next</button>
        </div>
    );
});
