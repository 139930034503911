import './rich-editor.scss';
import React, {useEffect, useMemo, useState} from 'react';
import EditorButtonBar from "./EditorButtonBar";
import {EDITOR_COMPONENT_LINK} from "./editorComponents/Link";
import editorBlockRenderer from "./editorBlockRenderer";
import {createEditor, Transforms} from "slate";
import {Editable, Slate, withReact} from "slate-react";
import {withHistory} from "slate-history";
import renderLeaf from "./renderLeaf";
import {EDITOR_COMPONENT_LONG_TEXT_REFERENCE} from "./editorComponents/LongTextReference";
import {EDITOR_COMPONENT_VIDEO} from "./editorComponents/Video";
import {EDITOR_COMPONENT_IMAGE} from "./editorComponents/Image";
import {EDITOR_COMPONENT_DRAGGABLE_BANK_REFERENCE} from "./editorComponents/DraggableBankReference";

const INLINE_TYPES = [EDITOR_COMPONENT_LINK, EDITOR_COMPONENT_LONG_TEXT_REFERENCE,EDITOR_COMPONENT_DRAGGABLE_BANK_REFERENCE];
const VOID_TYPES = [EDITOR_COMPONENT_LONG_TEXT_REFERENCE,EDITOR_COMPONENT_VIDEO,EDITOR_COMPONENT_IMAGE,EDITOR_COMPONENT_DRAGGABLE_BANK_REFERENCE];

export const withLighthaus = editor => {
    const {isInline, isVoid} = editor;

    editor.isInline = element => INLINE_TYPES.includes(element.type) ? true : isInline(element);

    editor.isVoid = element => VOID_TYPES.includes(element.type) ? true : isVoid(element);

    return editor;
};

export default props => {
    const {content, setContent, id} = props;
    const editor = useMemo(() => withLighthaus(withHistory(withReact(createEditor()))), []);
    const [value, setValue] = useState([]);
    useEffect(() => {
        Transforms.deselect(editor);
        let startingValue;
        try {
            startingValue = !!content ? JSON.parse(content) : null;
        } catch {
            startingValue = [{type: 'paragraph', children: [{text: content || ""}]}];
        }
        if(!startingValue || !startingValue.length) {
            startingValue = [{type: 'paragraph', children: [{text:""}]}];
        }
        setValue(startingValue);
    }, [id]);

    const saveContent = () => {
        const update = JSON.stringify(value);
        setContent(update);
    };

    return (
        <div className="rich-editor" onMouseDown={focus}>
            <Slate editor={editor} value={value} onChange={value => setValue(value)}>
                <EditorButtonBar editorId={id} />
                <Editable
                    className="rich-editor--editor"
                    renderElement={editorBlockRenderer}
                    renderLeaf={renderLeaf}
                    onBlur={saveContent} />
            </Slate>
        </div>
    );
};
