import {CURRICULA_LOADED} from "../../curricula/actions";
import {LONG_TEXT_INPUT} from "../../../constants/TaskTypes";
import {ADD_INTERACTABLE_TO_TASK, REMOVE_INTERACTABLE_FROM_TASK} from "../actions";
import {omit} from "lodash";
import {SET_LONG_TEXT_INPUT_PROMPT} from "./actions";

const defaultItem = {
    prompt: "",
};

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const newWorkBoardDefinitions = action.curricula.reduce((agg, c) => ({
            ...agg,
            ...(c.tasks || []).reduce((taskAgg, t) => ({
                ...taskAgg,
                ...t.items.filter(i => i.__typename === LONG_TEXT_INPUT).reduce((itemsAgg, i) => ({
                    ...itemsAgg,
                    [i.id]: {
                        ...defaultItem,
                        ...omit(i, "__typename"),
                    },
                }), {}),
            }), {}),
        }), {});
        return {
            ...state,
            ...newWorkBoardDefinitions,
        };
    }
    if(action.type === ADD_INTERACTABLE_TO_TASK && action.interactableType === LONG_TEXT_INPUT) {
        return {
            ...state,
            [action.interactableId]: {
                ...defaultItem,
            },
        };
    }
    if(action.type === REMOVE_INTERACTABLE_FROM_TASK) {
        return omit(state, action.interactableId);
    }
    if(action.type === SET_LONG_TEXT_INPUT_PROMPT) {
        return {
            ...state,
            [action.interactableId]: {
                ...state[action.interactableType],
                prompt: action.prompt,
            },
        };
    }
    return state;
};
