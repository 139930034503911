import * as styles from "./item-menu-style";
import React, {useEffect, useRef} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {removeInteractableFromTask} from "../../../../../store/curriculumTaskItems/actions";
import {useParams} from "@reach/router";
import {
    getScrollingItemFixed,
    getScrollingItemLocked,
    getScrollingItemPositions
} from "../../../../../store/scrollingTaskItemPositions/selectors";
import {
    moveScrollingTaskItem,
    setScrollingItemEditing, setScrollingItemFixed,
    setScrollingItemLocked,
    setScrollingTaskItemScrollPosition
} from "../../../../../store/scrollingTaskItemPositions/actions";
import combineStyles from "../../../../../Utility/combineStyles";
import Pencil from '../../../../../inline-assets/edit.svg';
import Check from '../../../../../inline-assets/check.svg';
import {isItemEditing, isItemSelected} from "../../../../../store/itemSelection/selector";
import {css} from "@emotion/core";

export default ({id, scroller, resizeData, itemRef}) => {
    const {curriculumId, taskId} = useParams();
    const positions = useSelector(state => getScrollingItemPositions(id, state), shallowEqual);
    const locked = useSelector(state => getScrollingItemLocked(id, state));
    const fixed = useSelector(state => getScrollingItemFixed(id, state));
    const selected = useSelector(state => isItemSelected(id, state));
    const editing = useSelector(state => isItemEditing(id, state));
    const dispatch = useDispatch();
    const ref = useRef();

    useEffect(() => {
        const {top: scrollerY} = scroller.current.getBoundingClientRect();
        const {top: parentY} = ref.current.parentElement.getBoundingClientRect();
        const relativeY = parentY - scrollerY;
        if(relativeY > 35) {
            ref.current.style.top = "-4px";
            ref.current.style.bottom = "auto";
            ref.current.style.transform = "translate(-50%, -100%)";
        } else {
            ref.current.style.top = "auto";
            ref.current.style.bottom = "-4px";
            ref.current.style.transform = "translate(-50%, 100%)";
        }
    }, [positions, selected]);

    const toggleLock = e => {
        e.preventDefault();
        const scrollPosition = scroller.current.scrollTop;
        if(locked && (positions[0].scroll === null || positions[0].scroll >= scrollPosition)) {
            dispatch(setScrollingTaskItemScrollPosition(curriculumId, id, 0, scrollPosition));
        } else if(!locked) {
            let y = positions[1].y !== null ? positions[1].y : positions[0].y;
            if(resizeData && resizeData.variableHeight) {
                y += itemRef.current.getBoundingClientRect().height;
            }
            const scrollDelta = positions[1].scroll !== null
                ? scrollPosition - positions[1].scroll
                : scrollPosition - positions[0].scroll;
            dispatch(moveScrollingTaskItem(curriculumId, id, 1, positions[1].x, y + scrollDelta));
            dispatch(setScrollingTaskItemScrollPosition(curriculumId, id, 1, scrollPosition));
        }
        dispatch(setScrollingItemLocked(curriculumId, id, !locked));
    }

    const removeItem = e => {
        e.preventDefault();
        dispatch(removeInteractableFromTask(curriculumId, taskId, id));
    };

    const toggleEdit = e => {
        e.preventDefault();
        dispatch(setScrollingItemEditing(curriculumId, id, !editing));
    }

    const toggleFixed = e => {
        e.preventDefault();
        dispatch(setScrollingItemFixed(curriculumId, id, !fixed));
    }

    const lockButtonStyle = combineStyles(styles, {
        "button": true,
        "lockButton": !locked,
        "unlockButton": locked,
    });
    const fixButtonStyle = combineStyles(styles, {
        button: true,
        fixButton: !fixed,
        unfixButton: fixed,
    });
    const trashStyle = css(styles.button, styles.trashButton);
    const editStyle = combineStyles(styles,{
        "button": true,
        editing
    });

    return (
        <div ref={ref} css={combineStyles(styles, {
            "menu": true,
            "menuSelected": selected,
        })}>
            <button onClick={toggleLock} css={lockButtonStyle} />
            <button onClick={toggleFixed} css={fixButtonStyle} />
            <button onClick={toggleEdit} css={editStyle}>
                {!editing && <Pencil/>}
                {editing && <Check />}
            </button>
            <button onClick={removeItem} css={trashStyle} />
        </div>
    );
}
