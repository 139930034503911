import {takeEvery, call, put} from 'redux-saga/effects'
import {ltiPlatformRegistrationRequestAnswered, REQUEST_LTI_PLATFORM_REGISTRATION} from "../../../store/lti/actions";
import {sendGraphQLSaga} from "../../DataService";

const registerLtiPlatform = `mutation RegisterLtiPlatform($input: RegisterLtiPlatformInput) {
    registerLtiPlatform(input: $input) {
        errors 
    }
}`;
function* executeRegisterLtiPlatform(action) {
    const { platformUrl, clientId, name, authenticationEndpoint, accessTokenEndpoint, authKeysData } = action;

    const variables = {
        input: {
            platformUrl,
            clientId,
            name,
            authenticationEndpoint,
            accessTokenEndpoint,
            authKeysData,
        },
    };

    const response = yield call(sendGraphQLSaga, registerLtiPlatform, variables);
    yield put(ltiPlatformRegistrationRequestAnswered(response.registerLtiPlatform.errors));
}

export default [
    takeEvery(REQUEST_LTI_PLATFORM_REGISTRATION, executeRegisterLtiPlatform),
];
