import gql from "graphql-tag";
import dropAreaDraggablesUpdated from './DropAreaDraggablesUpdated';
import {plantCardFragment} from "../../queries/peaPlant";
import learnerInteractionUpdated from "./LearnerInteractionUpdated";
import {INVENTORY_TASK_INTERACTION} from "../../../constants/TaskTypes";
import {organelleFramgement} from "../../queries/curriculum";
import {
    dogmaDashObjectMoved,
    dogmaDashSessionStarted,
    handleDogmaDashMotorProteinMovementChange,
    handleDogmaDashObjectFormChanged,
    handleDogmaDashObjectMoved,
    handleDogmaDashObjectRemoved,
    handleDogmaDashOrganelleBrokenUpdated,
    handleDogmaDashOrganelleTargetChanged,
    handleDogmaDashOrganelleTimerUpdate,
    handleDogmaDashPortalNucleotideChanged,
    handleDogmaDashPortalTrnaChanged,
    handleDogmaDashTranscriptionUpdate, handleLevelStartTimeSet, handleScoreChange, handleUnlockedProteinsChanged
} from "./DogmaDash";
import {partnershipRequestCompleted, partnershipRequested} from "./partnering";

const DROP_AREA_DRAGGABLE_UPDATED = "DropAreaDraggablesUpdated";
const LEARNER_INTERACTION_UPDATED = "LearnerInteractionUpdated";
const DOGMA_DASH_SESSION_STARTED = "DogmaDashSessionStarted";
const DOGMA_DASH_ORGANELLE_BROKEN_CHANGED = "DogmaDashOrganelleBrokenChanged";
const DOGMA_DASH_OBJECT_MOVED = "DogmaDashObjectPlaced";
const DOGMA_DASH_OBJECT_REMOVED = "DogmaDashObjectRemoved";
const DOGMA_DASH_OBJECT_FORM_CHANGED = "DogmaDashObjectFormChanged";
const DOGMA_DASH_TIMER_CHANGED = "DogmaDashOrganelleTimerChanged";
const DOGMA_DASH_ORGANELLE_TARGET_CHANGED = "DogmaDashOrganelleTargetChanged";
const DOGMA_DASH_PORTAL_NUCLEOTIDE_CHANGED = "DogmaDashPortalNucleotideChanged";
const DOGMA_DASH_PORTAL_TRNA_CHANGED = "DogmaDashPortalTrnaChanged";
const MOTOR_PROTEIN_MOVEMENT_CHANGED = "MotorProteinMovementChanged";
const PARTNERSHIP_REQUESTED = "PartnershipRequestEvent";
const PARTNERSHIP_REQUEST_ANSWERED = "PartnershipRequestCompletedEvent";
const DOGMA_DASH_TRANSCRIPTION_UPDATE = "DogmaDashTranscriptionUpdate";
const DOGMA_DASH_UNLOCKED_PROTEINS_CHANGED = "DogmaDashUnlockedProteinsChanged"
const DOGMA_DASH_LEVEL_START_TIME_CHANGED = "DogmaDashLevelStartTimeChanged";
const DOGMA_DASH_LEVEL_SCORE_CHANGED = "DogmaDashLevelScoreChanged";

export const learnerEventSubscription = `subscription onLearnerEvent($input: LearnerEventInput!) {
    learnerEvent(input: $input) {
        update {
            __typename
            ... on PartnershipRequestEvent {
                requester {
                    givenName
                    familyName
                }
            }
            ... on ${DROP_AREA_DRAGGABLE_UPDATED} {
                interaction {
                    interactable {
                        id
                    }
                    draggables {
                        item {
                            id
                            __typename
                            ...on PeaPlantCard {
                                ${plantCardFragment}
                            }
                            ... on Polaroid {
                                imageUrl
                            }
                        }
                        metadata
                    }
                }
            }
            ... on ${LEARNER_INTERACTION_UPDATED} {
                learnerInteraction: interaction {
                    interactable {
                        __typename
                        id
                    }
                    ... on ${INVENTORY_TASK_INTERACTION} {
                        objects {
                            object {
                                objectType
                            }
                            count
                        }
                    }
                }
            }
            ... on ${PARTNERSHIP_REQUESTED} {
                learner
                requester {
                    id
                }
                curriculum {
                    id
                }
            }
            ... on ${PARTNERSHIP_REQUEST_ANSWERED} {
                learner
                requester {
                    id
                }
                curriculum {
                    id
                }
                approved
            }
            ... on ${DOGMA_DASH_SESSION_STARTED} {
                session {
                    interactable {
                        id
                    }
                    dashMrnaSlots ${organelleFramgement}
                    dashMitochondria ${organelleFramgement}
                    dashCellMembrane ${organelleFramgement}
                    dashLysosome ${organelleFramgement}
                    dashGolgiBody ${organelleFramgement}
                    dashRibosome ${organelleFramgement}
                    dashMicrotubules ${organelleFramgement}
                    dashCentrioles ${organelleFramgement}
                    dashCytoplasm ${organelleFramgement}
                    currentLevel
                    currentStage
                    currentLevelStageFinalLevel
                    currentLevelStartTimeMs
                    currentLevelDurationMs
                    currentLevelCargoAlertThreshold
                    currentLevelViralAlertThreshold
                    levelUnlockingProtein
                    unlockedProteins   
                }
            }
            ... on ${DOGMA_DASH_OBJECT_MOVED} {
                movedObject {
                    id
                    type
                    form
                }
                newOrganelle {
                    id
                }
            }
            ...on ${DOGMA_DASH_TIMER_CHANGED} {
                organelle { 
                    id
                    timerStartTimeMs
                    timerDurationMs
                    timerType
                    alertDescription
                }
                score
            }
            ... on ${DOGMA_DASH_OBJECT_REMOVED} {
                removedObject
                score
            }
            ... on ${DOGMA_DASH_OBJECT_FORM_CHANGED} {
                changedObject {
                    id
                    form
                }
            }
            ... on ${MOTOR_PROTEIN_MOVEMENT_CHANGED} {
                motorProtein {
                    id
                    movingTowards
                    movementStartTimeMs
                    movementDurationMs
                }
            }
            ... on ${DOGMA_DASH_ORGANELLE_BROKEN_CHANGED} {
                organelle {
                    id
                    broken
                }
            }
            ... on ${DOGMA_DASH_ORGANELLE_TARGET_CHANGED} {
                organelle {
                    id
                    target
                    targetSetter
                    targetStartTimeMs
                    targetDurationMs
                }
            }
            ... on ${DOGMA_DASH_PORTAL_NUCLEOTIDE_CHANGED} {
                session {
                    interactable {
                        id
                    }
                    portalNucleotide
                }
            }
            ... on ${DOGMA_DASH_PORTAL_TRNA_CHANGED} {
                session {
                    interactable {
                        id
                    }
                    portalTrna
                }
            }
            ... on ${DOGMA_DASH_TRANSCRIPTION_UPDATE} {
                session {
                    interactable {
                        id
                    }
                    transcriptionNucleotides {
                        given
                        paired
                    }
                    translationNucleotides {
                        given
                        paired
                    }
                }
            }
            ... on ${DOGMA_DASH_UNLOCKED_PROTEINS_CHANGED} {
                session {
                    interactable {
                        id
                    }
                    unlockedProteins
                }
            }
            ... on ${DOGMA_DASH_LEVEL_START_TIME_CHANGED} {
                session {
                    interactable {
                        id
                    }
                    currentLevelStartTimeMs
                }
            }
            ... on ${DOGMA_DASH_LEVEL_SCORE_CHANGED} {
                session {
                    interactable {
                        id
                    }
                    levelScores {
                        level
                        stage
                        score
                        stars
                        deliveries
                    }
                }
            }
        }
    }
}`;

const handlerMap = {
    [DROP_AREA_DRAGGABLE_UPDATED]: dropAreaDraggablesUpdated,
    [LEARNER_INTERACTION_UPDATED]: learnerInteractionUpdated,
    [DOGMA_DASH_SESSION_STARTED]: dogmaDashSessionStarted,
    [DOGMA_DASH_OBJECT_MOVED]: handleDogmaDashObjectMoved,
    [DOGMA_DASH_OBJECT_REMOVED]: handleDogmaDashObjectRemoved,
    [DOGMA_DASH_TIMER_CHANGED]: handleDogmaDashOrganelleTimerUpdate,
    [DOGMA_DASH_OBJECT_FORM_CHANGED]: handleDogmaDashObjectFormChanged,
    [MOTOR_PROTEIN_MOVEMENT_CHANGED]: handleDogmaDashMotorProteinMovementChange,
    [DOGMA_DASH_ORGANELLE_BROKEN_CHANGED]: handleDogmaDashOrganelleBrokenUpdated,
    [PARTNERSHIP_REQUESTED]: partnershipRequested,
    [PARTNERSHIP_REQUEST_ANSWERED]: partnershipRequestCompleted,
    [DOGMA_DASH_ORGANELLE_TARGET_CHANGED]: handleDogmaDashOrganelleTargetChanged,
    [DOGMA_DASH_PORTAL_NUCLEOTIDE_CHANGED]: handleDogmaDashPortalNucleotideChanged,
    [DOGMA_DASH_PORTAL_TRNA_CHANGED]: handleDogmaDashPortalTrnaChanged,
    [DOGMA_DASH_TRANSCRIPTION_UPDATE]: handleDogmaDashTranscriptionUpdate,
    [DOGMA_DASH_UNLOCKED_PROTEINS_CHANGED]: handleUnlockedProteinsChanged,
    [DOGMA_DASH_LEVEL_START_TIME_CHANGED]: handleLevelStartTimeSet,
    [DOGMA_DASH_LEVEL_SCORE_CHANGED]: handleScoreChange,
};

let subscription;
export default (subscriptionClient, dispatch, learnerId) => {
    if(subscription) {
        subscription.unsubscribe();
    }
    try {
        subscription = subscriptionClient.subscribe({
            query: gql`${learnerEventSubscription}`,
            variables: {
                input: {
                    learner: learnerId,
                },
            },
        }).subscribe({
            next({data}) {
                const event = data.learnerEvent.update;
                if (!event) {
                    return console.log(data);
                }
                if (!handlerMap.hasOwnProperty(event.__typename)) {
                    return;
                }
                handlerMap[event.__typename](dispatch, event);
            }
        });
    } catch(e) {console.log(e);}
};
