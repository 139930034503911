import {call, select, takeEvery} from 'redux-saga/effects';
import {getScrollingItemPositions} from "../../../store/scrollingTaskItemPositions/selectors";
import {sendGraphQLSaga} from "../../DataService";
import {
    MOVE_SCROLLING_TASK_ITEM,
    SET_SCROLLING_ITEM_CONTENT,
    SET_SCROLLING_ITEM_DRAGGABLE,
    SET_SCROLLING_ITEM_EDITING,
    SET_SCROLLING_ITEM_FIXED,
    SET_SCROLLING_ITEM_HEIGHT,
    SET_SCROLLING_ITEM_LOCKED,
    SET_SCROLLING_ITEM_WIDTH,
    SET_SCROLLING_TASK_ITEM_SCROLL_POSITION
} from "../../../store/scrollingTaskItemPositions/actions";
import {
    SCROLLING_DATA_ITEM,
    SCROLLING_IMAGE_ITEM,
    SCROLLING_SHAPE_ITEM,
    SCROLLING_TEXT_ITEM
} from "../../../constants/TaskTypes";
import {ADD_SPACE_TO_SCROLLER_TASK} from "../../../store/curriculumTask/actions";
import {getCurriculumTaskItemType} from "../../../store/curriculumTaskItems/selectors";

const setPositionQuery = `mutation SetScrollerItemPositions($input: SetScrollerItemPositionsInput!) {
    setScrollerItemPositions(input: $input) {
        item {
            id
        }
    }
}`;

const setLockedQuery = `mutation SetScrollerItemLocked($input: SetScrollerItemLockedInput!) {
    setScrollerItemLocked(input: $input) {
        item {
            id
        }
    }
}`;

const updateTextItemContentQuery = `mutation updateScrollerTextItemContent($input: UpdateScrollerTextItemContentInput!) {
    updateScrollerTextItemContent(input: $input) {
        item {
            id
        }
    }
}`;
const updateImageItemContentQuery = `mutation UpdateScrollerImageItemContent($input: UpdateScrollerImageItemContentInput!) {
    updateScrollerImageItemContent(input: $input) {
        item {
            id
        }
    }
}`;
const updateShapeItemContentQuery = `mutation UpdateScrollerShapeItemContent($input: UpdateScrollerShapeItemContentInput!) {
    updateScrollerShapeItemContent(input: $input) {
        item {
            id
        }
    }
}`;
const updateDataItemContentQuery = `mutation UpdateScrollerDataItemContent($input: UpdateScrollerDataItemContentInput!) {
    updateScrollerDataItemContent(input: $input) {
        item {
            id
        }
    }
}`;
const setDraggableQuery = `mutation SetScrollerItemDraggable($input: SetScrollerItemDraggableInput!) {
    setScrollerItemDraggable(input: $input) {
        item {
            id
        }
    }
}`;
const setFixedQuery = `mutation SetScrollerItemFixed($input: SetScrollerItemFixedInput!) {
    setScrollerItemFixed(input: $input) {
        item {
            id
        }
    }
}`;

function* setPosition(curriculum, item) {
    const positions = yield select(state => getScrollingItemPositions(item, state));
    const variables = {
        input: {
            curriculum,
            item,
            positions,
        },
    };
    yield call(sendGraphQLSaga, setPositionQuery, variables);
}
export function* setScrollingItemPositions(action) {
    if(action.type === SET_SCROLLING_ITEM_EDITING && action.editing) { return; }
    yield call(setPosition, action.curriculumId, action.itemId);
}
function* setScrollingItemsPositions(action) {
    for(let i = 0; i < action.taskItemIds.length; ++i) {
        yield call(setPosition, action.curriculumId, action.taskItemIds[i]);
    }
}

function* setLocked(action) {
    const variables = {
        input: {
            curriculum: action.curriculumId,
            item: action.itemId,
            locked: action.locked,
        },
    };
    yield call(sendGraphQLSaga, setLockedQuery, variables);
    yield call(setScrollingItemPositions, action);
}

function* setDraggable(action) {
    const variables = {
        input: {
            curriculum: action.curriculumId,
            item: action.itemId,
            draggable: action.draggable,
        },
    };
    yield call(sendGraphQLSaga, setDraggableQuery, variables);
}

function* setFixed(action) {
    const variables = {
        input: {
            curriculum: action.curriculumId,
            item: action.itemId,
            fixed: action.fixed,
        },
    };
    yield call(sendGraphQLSaga, setFixedQuery, variables);
}

function* setScrollingItemContent(action) {
    const {itemId: item} = action;
    const type = yield select(state => getCurriculumTaskItemType(state, item));
    let query;
    const variables = {
        input: {
            curriculum: action.curriculumId,
            item,
        },
    };
    if(type === SCROLLING_TEXT_ITEM) {
        query = updateTextItemContentQuery;
        variables.input.content = action.content;
    } else if(type === SCROLLING_IMAGE_ITEM) {
        query = updateImageItemContentQuery;
        variables.input.url = action.content;
    } else if(type === SCROLLING_SHAPE_ITEM) {
        query = updateShapeItemContentQuery;
        variables.input.shape = action.content;
    } else if(type === SCROLLING_DATA_ITEM) {
        query = updateDataItemContentQuery;
        variables.input.dataType = action.content;
    }
    if(query) {
        yield call(sendGraphQLSaga, query, variables);
    }
}

export default [
    takeEvery(MOVE_SCROLLING_TASK_ITEM, setScrollingItemPositions),
    takeEvery(SET_SCROLLING_TASK_ITEM_SCROLL_POSITION, setScrollingItemPositions),
    takeEvery(SET_SCROLLING_ITEM_WIDTH, setScrollingItemPositions),
    takeEvery(SET_SCROLLING_ITEM_HEIGHT, setScrollingItemPositions),
    takeEvery(SET_SCROLLING_ITEM_LOCKED, setLocked),
    takeEvery(SET_SCROLLING_ITEM_CONTENT, setScrollingItemContent),
    takeEvery(SET_SCROLLING_ITEM_DRAGGABLE, setDraggable),
    takeEvery(SET_SCROLLING_ITEM_FIXED, setFixed),
    takeEvery(ADD_SPACE_TO_SCROLLER_TASK, setScrollingItemsPositions),
];
