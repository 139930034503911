// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./Assets/punnetSquare.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".work-board--tool-box{flex:0 1 auto;margin-left:15px}.work-board--tool-box h4{padding:0;margin:0 0 8px;text-align:center;font-weight:600;text-transform:uppercase;color:#6f6f6f}.tool-box--item{display:inline-block;vertical-align:top;width:40px}.tool-box--item:not(:last-of-type){margin-right:6px}.tool-box--sticky-note{position:relative;width:40px;height:40px;box-shadow:1px 2px 2px 1px rgba(0,0,0,.3);background-color:#f9ffb6}.tool-box--punnett-square{width:40px;height:40px;box-shadow:1px 2px 2px 1px rgba(0,0,0,.3);background-color:#fff;box-sizing:border-box;padding:10px;text-align:center;position:relative}.tool-box--punnett-square:after{content:\"\";position:absolute;right:0;bottom:0;width:100%;height:100%;display:block;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%}.tool-box--item-description{margin-top:2px;font-size:.75em;color:#6f6f6f;white-space:normal}", ""]);
// Exports
module.exports = exports;
