import * as styles from "./curriculum-display-style";
import React from 'react';
import Menu from "./Navigation/Menu";
import {useParams} from "@reach/router";
import AllScrollingContent from './AllScrolllingContent';
import {useSelector} from "react-redux";
import {getCurriculumType} from "../store/curricula/selectors";
import {NANO, VERDANT, VISCERAL} from "../constants/curriculumTypes";
import VisceralLearner from "./CurriculaViews/Visceral";
import {css} from "@emotion/core";
import Nano from "./CurriculaViews/Nano";
import {useTheme} from "@emotion/react";

const curriculumDisplay = () => {
    const {curriculumId} = useParams();
    const curriculumType = useSelector(state => getCurriculumType(state, curriculumId));
    const theme = useTheme();

    return (
        <div css={theme ? styles.themedContent(theme) : styles.content}>
            {curriculumType === VISCERAL && <VisceralLearner />}
            {curriculumType === NANO && <Nano />}
            {curriculumType === VERDANT && <AllScrollingContent/>}
        </div>
    );
}

export default curriculumDisplay;
