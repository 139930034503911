import './rich-draggable.scss';
import React, {useState} from 'react';
import RichTextDisplay from "../../../../../Learn/CurriculumTask/RichTextDisplay";
import RichEditor from "../../../../../RichText/RichEditor";
import {isModalOpenFor} from "../../../../../store/navigation/selectors";
import {EDIT_DRAGGABLE_MODAL} from "../../../../../constants/modalTypes";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {openModalFor} from "../../../../../store/navigation/actions";
import {getDraggableContent} from "../../../../../store/curriculumTaskItems/draggable/selectors";
import {setDraggableContent} from "../../../../../store/curriculumTaskItems/draggable/actions";

const stateMap = props => state => ({
    editorOpen: isModalOpenFor(state, EDIT_DRAGGABLE_MODAL, props.id),
    content: getDraggableContent(state, props.id),
});

export default props => {
    const {curriculum, id} = props;
    const {editorOpen, content} = useSelector(stateMap(props), shallowEqual);
    const dispatch = useDispatch();
    const [editorContent, setEditorContent] = useState(content);

    const openEditor = () => dispatch(openModalFor(EDIT_DRAGGABLE_MODAL, props.id));
    const close = () => dispatch(openModalFor());
    const accept = () => {
        dispatch(setDraggableContent(curriculum, id, editorContent));
        close();
    };

    return (
        <>
            {editorOpen &&
            <div className="admin-draggable-rich--modal__blocker">
                <div className="admin-draggable-rich--modal">
                    <h3 className="admin-draggable-rich--title">Draggable Content</h3>
                    <div className="admin-draggable-rich--editor-container">
                        <RichEditor content={editorContent} setContent={setEditorContent} id={id}/>
                    </div>
                    <div className="cell-editor--button-container">
                        <button className="cell-editor--accept" onClick={accept}>Accept</button>
                        <button className="cell-editor--cancel" onClick={close}>Cancel</button>
                    </div>
                </div>
            </div>
            }
            <div className="admin-draggable--rich">
                {content && <RichTextDisplay content={content} />}
                {!content && <span className="admin-draggable-rich--placeholder"><em>Draggable content...</em></span>}
                <button className="admin-draggable-rich--edit" onClick={openEditor} />
            </div>
        </>
    );
}
