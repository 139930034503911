import {css} from "@emotion/core";
import {
    bbPadding, DROP_SLOT_HOVERED_HIGHLIGHT,
    DROP_SLOT_SHADOW,
    PEA_PLANT_CARD_HEIGHT,
    PEA_PLANT_CARD_WIDTH, PHENOTYPE_CARD_HEIGHT,
    PHENOTYPE_CARD_WIDTH, PHOTOGRAPH_HEIGHT, PHOTOGRAPH_WIDTH
} from "../../../style-variables";

export const container = css({
    position: "relative",
    textAlign: "center",
});
export const plantCardSlot = css({
    width: PEA_PLANT_CARD_WIDTH,
    height: PEA_PLANT_CARD_HEIGHT,
});
export const phenotypeSlot = css({
    width: PHENOTYPE_CARD_WIDTH,
    height: PHENOTYPE_CARD_HEIGHT,
});
export const photographSlot = css({
    width: PHOTOGRAPH_WIDTH,
    height: PHOTOGRAPH_HEIGHT,
});

export const slot = css({
    display: "inline-block",
    borderRadius: 2,
    border: "1px solid #c4c4c4",
    backgroundColor: "white",
    transition: "background-color 0.2s",

    "&:empty": {
        boxShadow: DROP_SLOT_SHADOW,
    },
});
export const slotHovered = css({
    backgroundColor: DROP_SLOT_HOVERED_HIGHLIGHT,
});

export const label = css({
    margin: "4px 0 0 0",
    fontSize: "0.875em",
    whiteSpace: "nowrap",
    color: "#333",
}, bbPadding("0 4px"));
