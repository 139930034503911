import uuid from 'uuid/v4';
import {SET_DOGMA_DASH_MAX_LYSOSOMES} from "../../curriculumTaskItems/dogmaDash/actions";

export const REFRESH_DOGMA_DASH_INTERACTION_DATA = "refresh dogma dash interaction data";
export const refreshDogmaDashInteractionData = interactionData => ({
    type: REFRESH_DOGMA_DASH_INTERACTION_DATA,
    interactionData,
});

export const SET_DOGMA_DASH_HIGHLIGHTED_GENE = "set dogmash highlighted gene";
export const setDogmaDashHighlightedGene = (dogmaDashId, curriculumId, gene) => ({
    type: SET_DOGMA_DASH_HIGHLIGHTED_GENE,
    dogmaDashId,
    curriculumId,
    gene,
});

export const SET_DOGMA_DASH_PORTAL_NUCLEOTIDE = "set dogmash portal nucleotide";
export const setDogmaDashPortalNucleotide = (dogmaDashId, curriculumId, nucleotide) => ({
    type: SET_DOGMA_DASH_PORTAL_NUCLEOTIDE,
    dogmaDashId,
    curriculumId,
    nucleotide,
});

export const DOGMA_DASH_PORTAL_NUCLEOTIDE_SET = "dogmash portal nucleotide set";
export const dogmaDashPortalNucleotideSet = (dogmaDashId, nucleotide) => ({
    type: DOGMA_DASH_PORTAL_NUCLEOTIDE_SET,
    dogmaDashId,
    nucleotide,
});

export const SET_DOGMA_DASH_PORTAL_TRNA = "set dogmash portal trna";
export const setDogmaDashPortalTrna = (dogmaDashId, curriculumId, nucleotides) => ({
    type: SET_DOGMA_DASH_PORTAL_TRNA,
    dogmaDashId,
    curriculumId,
    nucleotides,
});

export const DOGMA_DASH_PORTAL_TRNA_SET = "dogmash portal trna set";
export const dogmaDashPortalTrnaSet = (dogmaDashId, nucleotides) => ({
    type: DOGMA_DASH_PORTAL_TRNA_SET,
    dogmaDashId,
    nucleotides,
});

export const DOGMA_DASH_TRANSCRIPTION_NUCLEOTIDES_UPDATED = "dogmash transcription nucleotides updated";
export const dogmaDashTranscriptionNucleotidesUpdated = (dogmaDashId, transcriptionNucleotides, translationNucleotides) => ({
    type: DOGMA_DASH_TRANSCRIPTION_NUCLEOTIDES_UPDATED,
    dogmaDashId,
    transcriptionNucleotides,
    translationNucleotides,
});

export const ADD_ITEM_TO_ORGANELLE = "add object to mrna";
export const addItemToOrganelle = (curriculumId, dogmaDashId, organelleId, itemType) => ({
    type: ADD_ITEM_TO_ORGANELLE,
    curriculumId,
    dogmaDashId,
    organelleId,
    objectId: uuid(),
    itemType,
});

export const DOGMA_DASH_OBJECT_MOVED = "dogmash object moved";
export const dogmaDashObjectMoved = (objectId, newOrganelleId, objectType, objectForm) => ({
    type: DOGMA_DASH_OBJECT_MOVED,
    objectId,
    newOrganelleId,
    objectType,
    objectForm,
});

export const DOGMA_DASH_OBJECT_REMOVED = "dogmash object removed";
export const dogmaDashObjectRemoved = objectId => ({
    type: DOGMA_DASH_OBJECT_REMOVED,
    objectId
});

export const MOVE_DOGMA_DASH_OBJECT = "dogmash move object";
export const moveDogmaDashObject = (curriculumId, dogmaDashId, objectId, newOrganelleId) => ({
    type: MOVE_DOGMA_DASH_OBJECT,
    curriculumId,
    dogmaDashId,
    objectId,
    newOrganelleId,
});

export const DOGMA_DASH_OBJECT_FORM_CHANGED = "dogmash object form changed";
export const dogmaDashObjectFormChanged = (objectId, newForm) => ({
    type: DOGMA_DASH_OBJECT_FORM_CHANGED,
    objectId,
    newForm,
});

export const DOGMA_DASH_ORGANELLE_TIMER_CHANGED = "dogmash timer changed";
export const dogmaDashOrgnalleTimerChanged = (organelleId, start, duration, timerType, alertDescription = "") => ({
    type: DOGMA_DASH_ORGANELLE_TIMER_CHANGED,
    organelleId,
    start,
    duration,
    timerType,
    alertDescription,
});

export const SET_DOGMA_DASH_ORGANELLE_BROKEN = "dogmash set organelle broken";
export const setDogmaDashOrganelleBroken = (organelleId, broken = true) => ({
    type: SET_DOGMA_DASH_ORGANELLE_BROKEN,
    organelleId,
    broken,
});

export const LYSOSOME_TARGET_SET = "dogmash lysosome target set";
export const lysosomeTargetSet = (lysosomeId, targetId, setBy, startTime, duration) => ({
    type: LYSOSOME_TARGET_SET,
    lysosomeId,
    targetOrganelle: targetId,
    startTime,
    duration,
    setBy
});

export const SET_LYSOSOME_TARGET = "dogmash set lysosome target";
export const setLysosomeTarget = (curriculumId, lysosomeId, setBy, x, y, targetId) => ({
    type: SET_LYSOSOME_TARGET,
    lysosomeId,
    x,
    y,
    targetOrganelle: targetId,
    curriculumId,
    setBy,
});

export const SET_LYSOSOME_TARGET_X_Y = "dogmash set lysosome target x y";
export const setLysosomeTargetXY = (lysosomeId, x, y) => ({
    type: SET_LYSOSOME_TARGET_X_Y,
    lysosomeId,
    x,
    y
});

export const LYSOSOME_CONSUME_AT = "dogmash lysosome consume at";
export const lysosomeConsumeAt = (lysosomeId, targetOrganelle, curriculumId) => ({
    type: LYSOSOME_CONSUME_AT,
    lysosomeId,
    targetOrganelle,
    curriculumId,
});

export const LYSOSOME_EATEN = "dogmash lysosome eaten";
export const lysosomeEaten = (lysosomeId, numberEaten) => ({
    type: LYSOSOME_EATEN,
    lysosomeId,
    numberEaten,
});

export const SET_MOTOR_PROTEIN_MOVEMENT_DATA = "dogmash set motor protein movement data";
export const setMotorProteinMovementData = (motorProteinId, movingTowards, movementStartTime, movementDuration) => ({
    type: SET_MOTOR_PROTEIN_MOVEMENT_DATA,
    id: motorProteinId,
    movingTowards,
    movementStartTime,
    movementDuration,
});

export const SET_DOGMA_DASH_VIEW = "set dogmash view";
export const setDogmaDashView = (dogmaDashId, view) => ({
    type: SET_DOGMA_DASH_VIEW,
    dogmaDashId,
    view
});

export const CHANGE_CURRENT_DOGMA_DASH_SCORE = "change dogmash score";
export const changeCurrentDogmaDashScore = (dogmaId, deltaScore) => ({
    type: CHANGE_CURRENT_DOGMA_DASH_SCORE,
    dogmaId,
    deltaScore,
});

export const DOGMA_DASH_UNLOCKED_PROTEINS_UPDATED = "dogmash unlocked proteins updated";
export const dogmaDashUnlockedProteinsUpdated = (dogmaId, unlockedProteins) => ({
    type: DOGMA_DASH_UNLOCKED_PROTEINS_UPDATED,
    dogmaId,
    unlockedProteins,
});

export const DOGMA_DASH_LEVEL_START_SET = "dogmash level start set";
export const dogmaDashLevelStartSet = (dogmaId, startTime) => ({
    type: DOGMA_DASH_LEVEL_START_SET,
    dogmaId,
    startTime,
});

export const DOGMA_DASH_SCORES_CHANGED = "dogmash scores changed";
export const dogmaDashScoresChanged = (dogmaId, scores) => ({
    type: DOGMA_DASH_SCORES_CHANGED,
    dogmaId,
    scores,
});
