import * as styles from './centralDogmaWidget-styles';
import React, {useEffect, useRef, useState} from 'react';
import {
    getCentralDogmaWidgetInitialStrand,
    getCentralDogmaWidgetMutations, getCentralDogmaWidgetTargetAminoAcids
} from "../../../../store/curriculumTaskItems/centralDogmaWidget/selectors";
import {useDispatch, useSelector} from "react-redux";
import {
    addTargetAminoAcidToCentralDogmaWidget,
    removeTargetAminoAcidToCentralDogmaWidget,
    setCentralDogmaInitialStrandLength,
    setCentralDogmaInitialStrandNucleotide,
    setCentralDogmaMutation
} from "../../../../store/curriculumTaskItems/centralDogmaWidget/actions";
import {
    ALL_AMINO_ACIDS,
    DNA_STRAND,
    LETTER_TO_NUCLEOTIDE_MAP,
    MRNA_STRAND,
    NUCLEOTIDE_LETTER_MAP,
    TRNA_STRAND
} from "../../../../constants/DogmaDash";
import {css} from "@emotion/core";
import {isModalOpenFor} from "../../../../store/navigation/selectors";
import {closeModalType, openModalFor} from "../../../../store/navigation/actions";

const inputValueToNucleotideName = value => LETTER_TO_NUCLEOTIDE_MAP[value.toUpperCase()];
const AMINO_ACID_SELECTION = "AMINO ACID SELECTION MODAL";

const TargetAminoAcids = ({id, curriculum}) => {
    const targets = useSelector(state => getCentralDogmaWidgetTargetAminoAcids(state, id));
    const selectingAmino = useSelector(state => isModalOpenFor(state, AMINO_ACID_SELECTION, id));
    const dispatch = useDispatch();

    const openModal = () => dispatch(openModalFor(AMINO_ACID_SELECTION, id));
    const closeModel = () => dispatch(closeModalType(AMINO_ACID_SELECTION));
    const remove = index => dispatch(removeTargetAminoAcidToCentralDogmaWidget(curriculum, id, index));
    const add = amino => {
        dispatch(addTargetAminoAcidToCentralDogmaWidget(curriculum, id, amino));
        closeModel();
    };

    return (
        <div>
            {selectingAmino && <div css={styles.selectAminoModalContainer}>
                <div css={styles.selectAminoModal}>
                    <div css={styles.selectAminoButtonContainer}>
                        {Array.from(ALL_AMINO_ACIDS, a => <button key={a} css={styles.selectAminoButton} onClick={add.bind(null, a)}>{a}</button>)}
                    </div>
                    <button onClick={closeModel}>Cancel</button>
                </div>
            </div>}
            <span>Target amino acids:</span>
            {targets.map((amino, i) => (
                <div key={i} css={styles.targetAmino}>
                    <span>{amino}</span>
                    <button onClick={remove.bind(null, i)}>-</button>
                </div>
            ))}
            <button onClick={openModal}>+</button>
        </div>
    )
};

const CentralDogmaWidgetAdmin = ({curriculum, id}) => {
    const [nextFocusIndex, setNextFocusIndex] = useState();
    const [nextFocusStrand, setNextFocusStrand] = useState();
    const [mutationMode, setMutationMode] = useState(false);
    const strand = useSelector(state => getCentralDogmaWidgetInitialStrand(state, id));
    const mutations = useSelector(state => getCentralDogmaWidgetMutations(state, id));
    const dispatch = useDispatch();
    const ref = useRef();

    useEffect(() => {
        if(!ref.current) { return; }
        ref.current.focus();
    }, [nextFocusIndex]);
    ref.current = null;
    const setRef = (index, strand, r) => {
        if(index !== nextFocusIndex || strand !== nextFocusStrand) {return;}
        ref.current = r;
    };

    const aminoIndexes = Array.from({length: strand.length / 3}, (_, i) => i * 3);

    const expand = () => dispatch(setCentralDogmaInitialStrandLength(curriculum, id, strand.length + 3));
    const reduce = () => dispatch(setCentralDogmaInitialStrandLength(curriculum, id, strand.length - 3));

    const getNucleotideDisplayValue = (strandName, index) => {
        if(!mutationMode) { return NUCLEOTIDE_LETTER_MAP[strand[index][strandName]] || ""; }
        const mutation = mutations.find(m => m.index === index && m.strand === strandName);
        return mutation ? NUCLEOTIDE_LETTER_MAP[mutation.nucleotide] || "" : "";
    };
    const chooseInputStyle = (strandName, index) => {
        if(mutations.some(m => m.index === index && m.strand === strandName)) {
            return mutationMode ? styles.inputStyleMutationModeWithMutation : styles.inputStyleWithMutation;
        }
        return mutationMode ? styles.inputStyleMutationMode : styles.inputStyle;
    }

    const setNucleotide = (name, index, value) => {
        const nucleotide = inputValueToNucleotideName(value);
        if(!nucleotide && value !== "") { return; }
        if(value !== "" && index === strand.length - 1) {
            setNextFocusStrand(name === TRNA_STRAND
                ? MRNA_STRAND
                : name === MRNA_STRAND
                ? DNA_STRAND
                : null
            );
            setNextFocusIndex(0);
        } else if(value !== "") {
            setNextFocusStrand(name);
            setNextFocusIndex(index + 1);
        }
        mutationMode
            ? dispatch(setCentralDogmaMutation(curriculum, id, name, index, nucleotide))
            : dispatch(setCentralDogmaInitialStrandNucleotide(curriculum, id, name, index, nucleotide));
    };

    const backspace = (name, index, eventData) => {
        if(eventData.key !== "Backspace" || eventData.target.value !== "") { return; }
        if(index === 0) {
            setNextFocusStrand(name === DNA_STRAND
                ? MRNA_STRAND
                : name === MRNA_STRAND
                    ? TRNA_STRAND
                    : null
            );
            setNextFocusIndex(strand.length - 1);
        } else {
            setNextFocusStrand(name);
            setNextFocusIndex(index - 1);
        }
    }

    return (
        <div css={styles.container}>
            <h2>Central Dogma Widget</h2>
            <TargetAminoAcids id={id} curriculum={curriculum} />
            <div css={mutationMode ? styles.strandContainerMutationMode : styles.strandContainer}>
                <div css={styles.strandEntryContainer}>
                    <div css={css(styles.rowHeaders, styles.uppercase)}>Amino acids</div>
                    <div css={styles.rowHeaders}>mRNA</div>
                    <div css={styles.rowHeaders}>DNA</div>
                </div>
                {aminoIndexes.map(i => <div key={i} css={styles.strandEntryContainer}>
                    <div css={styles.strandSection}>
                        <input css={chooseInputStyle(TRNA_STRAND, i)} type={"text"} value={getNucleotideDisplayValue(TRNA_STRAND, i)}
                               ref={setRef.bind(null, i, TRNA_STRAND)}
                               onChange={e => setNucleotide(TRNA_STRAND, i, e.target.value)}
                               onKeyDown={backspace.bind(null, TRNA_STRAND, i)} />
                        <input css={chooseInputStyle(TRNA_STRAND, i+1)} type={"text"} value={getNucleotideDisplayValue(TRNA_STRAND, i+1)}
                               ref={setRef.bind(null, i+1, TRNA_STRAND)}
                               onChange={e => setNucleotide(TRNA_STRAND, i+1, e.target.value)}
                               onKeyDown={backspace.bind(null, TRNA_STRAND, i+1)} />
                        <input css={chooseInputStyle(TRNA_STRAND, i+2)} type={"text"} value={getNucleotideDisplayValue(TRNA_STRAND, i+2)}
                               ref={setRef.bind(null, i+2, "trna")}
                               onChange={e => setNucleotide(TRNA_STRAND, i+2, e.target.value)}
                               onKeyDown={backspace.bind(null, TRNA_STRAND, i+2)} />
                    </div>
                    <div css={styles.strandSection}>
                        <input css={chooseInputStyle(MRNA_STRAND, i)} type={"text"} value={getNucleotideDisplayValue(MRNA_STRAND, i)}
                               ref={setRef.bind(null, i, MRNA_STRAND)}
                               onChange={e => setNucleotide(MRNA_STRAND, i, e.target.value)}
                               onKeyDown={backspace.bind(null, MRNA_STRAND, i)} />
                        <input css={chooseInputStyle(MRNA_STRAND, i+1)} type={"text"} value={getNucleotideDisplayValue(MRNA_STRAND, i+1)}
                               ref={setRef.bind(null, i+1, MRNA_STRAND)}
                               onChange={e => setNucleotide(MRNA_STRAND, i+1, e.target.value)}
                               onKeyDown={backspace.bind(null, MRNA_STRAND, i+1)} />
                        <input css={chooseInputStyle(MRNA_STRAND, i+2)} type={"text"} value={getNucleotideDisplayValue(MRNA_STRAND, i+2)}
                               ref={setRef.bind(null, i+2, MRNA_STRAND)}
                               onChange={e => setNucleotide(MRNA_STRAND, i+2, e.target.value)}
                               onKeyDown={backspace.bind(null, MRNA_STRAND, i+2)} />
                    </div>
                    <div css={styles.strandSection}>
                        <input css={chooseInputStyle(DNA_STRAND, i)} type={"text"} value={getNucleotideDisplayValue(DNA_STRAND, i)}
                               ref={setRef.bind(null, i, DNA_STRAND)}
                               onChange={e => setNucleotide(DNA_STRAND, i, e.target.value)}
                               onKeyDown={backspace.bind(null, DNA_STRAND, i)} />
                        <input css={chooseInputStyle(DNA_STRAND, i+1)} type={"text"} value={getNucleotideDisplayValue(DNA_STRAND, i+1)}
                               ref={setRef.bind(null, i+1, DNA_STRAND)}
                               onChange={e => setNucleotide(DNA_STRAND, i+1, e.target.value)}
                               onKeyDown={backspace.bind(null, DNA_STRAND, i+1)} />
                        <input css={chooseInputStyle(DNA_STRAND, i+2)} type={"text"} value={getNucleotideDisplayValue(DNA_STRAND, i+2)}
                               ref={setRef.bind(null, i+2, DNA_STRAND)}
                               onChange={e => setNucleotide(DNA_STRAND, i+2, e.target.value)}
                               onKeyDown={backspace.bind(null, DNA_STRAND, i+2)} />
                    </div>
                </div>)}
                <div css={styles.buttonContainer}>
                    <button css={styles.buttonStyle} onClick={expand}>+</button>
                    <button css={styles.buttonStyle} onClick={reduce} disabled={strand.length === 0}>-</button>
                </div>
            </div>
            <button onClick={() => setMutationMode(!mutationMode)}>{mutationMode ? "Stop Mutating" : "Set Mutations"}</button>
        </div>
    );
};

export default CentralDogmaWidgetAdmin;
