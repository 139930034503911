import "./teacher.scss";

import React, {useEffect, useRef, useState} from 'react';
import {attemptLogin} from "../utility";
import {navigate} from "@reach/router";
import {useDispatch} from "react-redux";
import {setAuthenticationState} from "../../store/identity/actions";
import {LOGGED_IN, TEACHER} from "../../constants/AuthenticationState";


export default () => {
    const userRef = useRef();
    const passwordRef = useRef();
    const [loggingIn, setLoggingIn] = useState(false);
    const [validationError, setValidationError] = useState("");
    const dispatch = useDispatch();

    useEffect(() => userRef.current.focus(), []);

    const startLogin = async () => {
        const user = userRef.current.value;
        const password = passwordRef.current.value;
        if(!user && !password) {
            return setValidationError("Username and password are required");
        }
        if(!user) {
            return setValidationError("Username is required");
        }
        if(!password) {
            return setValidationError("Password is required");
        }
        setLoggingIn(true);
        const result = await attemptLogin(user, password);
        if(result.logInSuccess) {
            dispatch(setAuthenticationState(LOGGED_IN, TEACHER));
            navigate("/teach");
        } else {
            setValidationError("Invalid username or password");
        }
        setLoggingIn(false);
    };

    const submitOnEnter = e => e.which === 13 && startLogin();

    return (
        <div className="teacher-login--container">
            <div className="teacher-login--logo" />
            <h2 className="teacher-login--header">Teacher Login</h2>
            <div className="teacher-login--validation-error">{validationError && <span>{validationError}</span>}</div>
            <div className="teacher-login--input-container">
                <label className="input-container--label" htmlFor="username">Username</label>
                <input ref={userRef} id="username" className="input-container--input" type="text" disabled={loggingIn} onKeyPress={submitOnEnter} />
            </div>
            <div className="teacher-login--input-container">
                <label className="input-container--label" htmlFor="password">Password</label>
                <input ref={passwordRef} id="password" className="input-container--input" type="password" disabled={loggingIn} onKeyPress={submitOnEnter} />
            </div>
            <button form="loginForm" className="teacher-login--login-button" onClick={startLogin} disabled={loggingIn}>{
                loggingIn
                    ? <div className="load-spinner" />
                    : <span>Login</span>
            }</button>
        </div>
    )
};
