import "./input-table.scss";
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getInputTableData, getInputTableNumberOfColumns} from "../../../../store/curriculumTaskItems/inputTable/selectors";
import RichEditor from "../../../../RichText/RichEditor";
import {
    addColumnToInputTable,
    addRowToInputTable,
    removeColumnFromInputTable,
    removeRowFromInputTable,
} from "../../../../store/curriculumTaskItems/inputTable/actions";
import RichTextDisplay from "../../../../Learn/CurriculumTask/RichTextDisplay";
import {CELL_TYPE_INPUT, CELL_TYPE_NONE, CELL_TYPE_STATIC} from "../../../../constants/InputTableCellTypes";
import {openModalFor} from "../../../../store/navigation/actions";
import {isModalOpenFor} from "../../../../store/navigation/selectors";
import InputTableCellEditor from "./InputTableCellEditor";
import {EDIT_INPUT_TABLE_CELL_MODAL} from "../../../../constants/modalTypes";
import {setInputTablePrompt} from "../../../../store/curriculumTaskItems/inputTable/actions";
import {useParams} from "@reach/router";
import {getInputTablePrompt} from "../../../../store/curriculumTaskItems/inputTable/selectors";

export default ({id}) => {
    const {curriculumId: curriculum} = useParams();
    const data = useSelector(state => getInputTableData(state, id));
    const numberOfColumns = useSelector(state => getInputTableNumberOfColumns(state, id));
    const prompt = useSelector(state => getInputTablePrompt(state, id));
    const editingCell = useSelector(state => isModalOpenFor(state, EDIT_INPUT_TABLE_CELL_MODAL, id));
    const dispatch = useDispatch();

    const setPrompt = newText => dispatch(setInputTablePrompt(curriculum, id, newText));
    const addRow = () => dispatch(addRowToInputTable(curriculum, id));
    const removeRow = rowIndex => dispatch(removeRowFromInputTable(curriculum, id, rowIndex));
    const addColumn = () => dispatch(addColumnToInputTable(curriculum, id));
    const removeColumn = columnIndex => dispatch(removeColumnFromInputTable(curriculum, id, columnIndex));

    const rows = numberOfColumns > 0
        ? Array.from(new Array(data.length/numberOfColumns), (_, i) => data.slice(i * numberOfColumns, i * numberOfColumns + numberOfColumns))
        : [];
    const columnRemovers = numberOfColumns > 1
        ? Array.from(new Array(numberOfColumns), (_, x) => <td key={`column-remove-${x}`}><button onClick={removeColumn.bind(null, x)} className="input-table--remove-column" /></td>)
        : [];
    if(numberOfColumns > 1 && rows.length > 1) {
        columnRemovers.unshift((<td />));
    }
    return (
        <div className="admin-task--input-table">
            {editingCell && <InputTableCellEditor id={id}/>}
            <div className="input-table--prompt-prompt">Prompt:</div>
            <div className="input-table--prompt-container">
                <RichEditor content={prompt} setContent={setPrompt} id={id} />
            </div>
            <table className="input-table--table">
                <tbody>
                    {columnRemovers.length > 0 && <tr>{columnRemovers}</tr>}
                    {rows.map((r, y) => (
                    <tr key={`${id}-row-${y}`}>
                        {rows.length > 1 && <td><button className="input-table--remove-row" onClick={removeRow.bind(null, y)} /></td>}
                        {r.map((cellData, x) => <TableCell key={`${id}-cell-${y},${x}`} inputTableId={id} cellData={cellData} row={y} column={x} />)}
                        {y === 0 && <td rowSpan={rows.length}><button className="input-table--add-column" onClick={addColumn}>+ column</button></td> }
                    </tr>
                    ))}
                    <tr>
                        {rows.length > 1 && <td />}
                        <td colSpan={numberOfColumns}>
                            <button className="input-table--add-row" onClick={addRow}>+ row</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

const TableCell = props => {
    const { inputTableId, row, column, cellData } = props;
    const { type, content } = cellData;
    const dispatch = useDispatch();

    const edit = () => dispatch(openModalFor(EDIT_INPUT_TABLE_CELL_MODAL, inputTableId,{row, column}));

    return (
        <td>
            {type === CELL_TYPE_NONE && <div className="input-table--cell-none" /> }
            {type === CELL_TYPE_STATIC && <RichTextDisplay content={content} /> }
            {type === CELL_TYPE_INPUT && <input />}
            <button className="input-table--edit-cell" onClick={edit} />
        </td>
    )
};
