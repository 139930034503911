import React from 'react';
import {useSelector} from "react-redux";
import {
    getDogmaDashCytoplasm,
    getObjectForm,
    getObjectsInOrganelle,
    getObjectType
} from "../../../../store/interactions/dogmaDash/selectors";
import {ProteinForm_ProteinInVesicle, Structural} from "../../../../constants/DogmaDash";
import * as styles from "./organelle-debris-styles";
import Protein from "./Protein";
import {CYTOPLASM_TUTORIAL} from "../../../../store/tutorial/configuration/dogma-strand-structural-unlock";
import PopUpTutorial from "../../../Tutorial/PopUpTutorial";
import {shallowOrderedArrayCompare} from "../../../../utility";

const CytoplasmArea = ({dogmaId}) => {
    const cytoStructurals = useSelector(state => getObjectsInOrganelle(state, getDogmaDashCytoplasm(state, dogmaId)).filter(o => getObjectType(state, o) === Structural && getObjectForm(state, o) === ProteinForm_ProteinInVesicle), shallowOrderedArrayCompare);
    const tutorialConfig = {location: CYTOPLASM_TUTORIAL, dogmaDashId: dogmaId};

    return (
        <div>
            {!!cytoStructurals[0] && <Protein form={ProteinForm_ProteinInVesicle} type={Structural} id={cytoStructurals[0]} />}
            <div css={styles.tutorial}><PopUpTutorial positionData={tutorialConfig} /></div>
        </div>
    )
};

export default CytoplasmArea;
