import {inlineBlockBottom, inlineBlockMiddle, inlineBlockTop} from "../../style-variables";
import {css} from "@emotion/core";
import {stylingButton} from "./editor-buttons-styles";

export const container = inlineBlockTop;

export const button = stylingButton;

export const input = css(inlineBlockMiddle, {
    width: "30px",
    border: "1px solid #333",
    background: "white",
    margin: "2px 2px 0 0",
    textAlign: "center",
});
