import * as styles from './progress-menu-styles';
import React, {useEffect, useRef, useState} from 'react';
import {shallowEqual, useSelector} from "react-redux";
import {useMatch, useNavigate, useParams} from "@reach/router";
import {
    getCurriculumCaseDescription,
    getCurriculumCaseTasks,
    getNumberOfCurriculumCases
} from "../../store/curriculumCases/selectors";
import {getCurriculumTaskTitle} from "../../store/curriculumTask/selectors";
import {useTheme} from "@emotion/react";

const Case = ({caseIndex}) => {
    const {curriculumId, taskId} = useParams();
    const title = useSelector(state => getCurriculumCaseDescription(curriculumId, caseIndex, state));
    const tasksInCase = useSelector(state => getCurriculumCaseTasks(curriculumId, caseIndex, state), shallowEqual);
    const taskTitles = useSelector(state => tasksInCase.map(t => getCurriculumTaskTitle(state, t)), shallowEqual);
    const [pagesHeight, setPagesHeight] = useState(0);
    const pagesRef = useRef();
    const theme = useTheme();
    const isPreview = useMatch('/admin/preview/*');
    const navigate = useNavigate();
    const active = tasksInCase.some(t => taskId === t);
    const navigatePrefix = isPreview ? "/admin/preview" : "/learn";

    useEffect(() => {
        let height = pagesHeight;
        if(!pagesHeight) {
            ({height} = pagesRef.current?.getBoundingClientRect());
            setPagesHeight(height);
        }
        pagesRef.current.style["max-height"] = active ? `${height}px` : 0;
    }, [active]);

    useEffect(() => {
        if(taskId || caseIndex > 0) { return; }
        navigate(`${navigatePrefix}/${curriculumId}/${tasksInCase[0]}`, {replace: true});
    },[]);

    const navigateToCase = () => {
        if(!tasksInCase.length) {return;}
        navigate(`${navigatePrefix}/${curriculumId}/${tasksInCase[0]}`);
    };

    return (
        <div css={styles.caseContainer}>
            <a css={styles.caseTitle(theme)} onClick={navigateToCase}>{title}</a>
            <div ref={pagesRef} css={styles.casePageContainer}>
                {tasksInCase.map((id, i) => <div key={id} css={styles.casePage(theme)}><span css={styles.caseMarker(theme)} /><span css={styles.caseText}>{taskTitles[i]}</span></div>)}
            </div>
        </div>
    )
};

const ProgressMenu = () => {
    const {curriculumId} = useParams();
    const caseIndexes = useSelector(state => Array.from({ length: getNumberOfCurriculumCases(curriculumId, state) }, (_, i) => i), shallowEqual);
    const [expanded, setExpanded] = useState(true);
    // const isPreview = useMatch('/admin/preview/*');
    const theme = useTheme();

    const toggleExpand = () => setExpanded(!expanded);

    return (
        <div css={expanded ? [styles.menuContainer(theme), styles.menuContainerExpanded] : styles.menuContainer(theme)}>
            <button css={styles.menuToggleButton(theme)} onClick={toggleExpand}>Progress</button>
            {caseIndexes.map(i => <Case key={i} caseIndex={i} />)}
        </div>
    )
};

export default ProgressMenu;
