import "./verdant-v1.scss";
import VRBlocker from "./VRBlocker";
import CurriculumNavigation from "./CurriculumNavigation";
import ActivityViewport from "./ActivityViewport";
import Footer from "./CurriculumNavigation/Footer";
import React from "react";
import {attemptLogout, getPINForEmail} from "../Identity/utility";
import {navigate} from "@reach/router";
import {getActiveLearner, getVRBlockerShowing} from "../store/navigation/selectors";
import {getEmailForLearner, getFamilyNameForLearner, getGivenNameForLearner} from "../store/learners/selectors";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import HTML5Backend from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";

const stateMap = state => {
    const learnerId = getActiveLearner(state);
    return {
        givenName: getGivenNameForLearner(state, learnerId),
        familyName: getFamilyNameForLearner(state, learnerId),
        pin: getPINForEmail(getEmailForLearner(state, learnerId)) || "",
        showVRBlocker: getVRBlockerShowing(state),
    };
};

export default () => {
    const {givenName, familyName, pin, showVRBlocker} = useSelector(stateMap, shallowEqual);
    const dispatch = useDispatch();

    const doLogout = () => {
        attemptLogout(dispatch);
        navigate("/");
    };

    return (
        // <DndProvider backend={HTML5Backend}>
        <>
            {showVRBlocker && <VRBlocker />}
            <div className="learner-header">
                <div className="header--logo" />
                <h1>Verdant</h1>
                <div className="header--identity">
                    <div className="header--identity-name">{`${givenName} ${familyName} (${pin})`}</div>
                    <button className="logout-button" onClick={doLogout}>Logout</button>
                </div>
            </div>
            <div className="learner-container--main">
                <CurriculumNavigation key="curriculum-nav" />
                <ActivityViewport />
                <Footer />
            </div>
        </>
        // </DndProvider>
    );
}
