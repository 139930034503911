import {TRAIT_TYPES} from "../../constants/PodulatorReceiptTypes";

export const CREATE_PUNNETT_SQUARE = "Create Punnett Square";
export const createPunnettSquare = id => ({
    type: CREATE_PUNNETT_SQUARE,
    id,
});

export const SET_PUNNETT_SQUARE_PLANT = "Set punnett square plant";
export const setPunnettSquarePlant = (punnettSquareId, plantId, isOnLeft) => ({
    type: SET_PUNNETT_SQUARE_PLANT,
    id: punnettSquareId,
    plantId,
    isOnLeft,
});

export const SET_PUNNETT_SQUARE_TRAIT = "Set punnett square trait";
export const setPunnettSquareTrait = (punnettSquareId, trait) => ({
    type: SET_PUNNETT_SQUARE_TRAIT,
    id: punnettSquareId,
    trait,
});

export const SET_PUNNETT_SQUARE_ENTRY_ALLELE = "Set punnett square entry allele";
export const setPunnettSquareEntryAllele = (punnettSquareId, entryX, entryY, alleleIndex, alleleValue) => ({
    type: SET_PUNNETT_SQUARE_ENTRY_ALLELE,
    id: punnettSquareId,
    x: entryX,
    y: entryY,
    alleleIndex,
    alleleValue,
});

export const SET_PUNNETT_SQUARE_ENTRY_PHENOTYPE = "Set punnett square entry phenotype";
export const setPunnettSquareEntryPhenotype = (punnettSquareId, entryX, entryY, phenotype) => ({
    type: SET_PUNNETT_SQUARE_ENTRY_PHENOTYPE,
    id: punnettSquareId,
    x: entryX,
    y: entryY,
    phenotype,
});

export const PUNNETT_SQUARES_LOADED = "Punnett Squares loaded";
export const punnettSquaresLoaded = punnettSquares => {
    return {
        type: PUNNETT_SQUARES_LOADED,
        punnettSquares: punnettSquares.map(ps => ({
            id: ps.id,
            traits: ps.traits.map(t => TRAIT_TYPES[t]),
            leftPlant: ps.leftPlant,
            topPlant: ps.topPlant,
            entries: ps.entries.sort((a, b) => (a.y * 2 + a.x) - (b.y * 2 + b.y)),
            questionAnswers: ps.probabilityQuestionAnswers || Array.from({length: 5}, () => null),
        })),
    }
};

export const SET_PUNNETT_SQUARE_QUESTION_ANSWER = "Set punnett square question answer";
export const setPunnettSquareQuestionAnswer = (punnettSquareId, questionIndex, answer) => ({
    type: SET_PUNNETT_SQUARE_QUESTION_ANSWER,
    id: punnettSquareId,
    questionIndex,
    answer,
});
