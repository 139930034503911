import {call, takeEvery} from 'redux-saga/effects';
import {SET_SCROLLER_WORK_BOARD_DEFINITION} from "../../../store/scrollerWorkBoards/action";
import {sendGraphQLSaga} from "../../DataService";

const setWorkBoardDefinitionMutation = `mutation SetScrollerWorkBoardDefinition($input: SetScrollerWorkBoardDefinitionInput!) {
    setScrollerWorkBoardDefinition(input: $input) {
        scrollerWorkBoard {
            id
        }
    }
}`;

function *setWorkBoardDefinition(action) {
    const variables = {
        input: {
            curriculum: action.curriculumId,
            scrollerWorkBoard: action.scrollerWorkBoardId,
            workBoardDefinition: action.workBoardDefinitionId,
        },
    };

    yield call(sendGraphQLSaga, setWorkBoardDefinitionMutation, variables);
}

export default [
    takeEvery(SET_SCROLLER_WORK_BOARD_DEFINITION, setWorkBoardDefinition),
];
