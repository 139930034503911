import * as styles from './nano-styles';
import React from 'react';
import CurriculumTask from "../CurriculumTask";
import {useSelector} from "react-redux";
import {getGlobalTaskId} from "../../store/curricula/selectors";
import {useParams} from "@reach/router";
import GlobalTaskLearner from "../CurriculumTask/GlobalTask";
import ProgressMenu from "../Navigation/ProgressMenu";

const NanoLearner = () => {
    const {taskId, curriculumId} = useParams();
    const globalTask = useSelector(state => getGlobalTaskId(state, curriculumId));

    return (
        <div css={styles.container}>
            <ProgressMenu />
            <div css={styles.contentArea}>
                {globalTask && <GlobalTaskLearner taskId={globalTask} />}
                {taskId && <CurriculumTask />}
            </div>
        </div>
    )
};

export default NanoLearner;
