import {PODULATOR_RECEIPTS_LOADED} from "./actions";
import {SET_AUTHENTICATION_STATE} from "../identity/actions";
import {NOT_LOGGED_IN} from "../../constants/AuthenticationState";

export default (state = {}, action) => {
    if(action.type === PODULATOR_RECEIPTS_LOADED) {
        const newReceipts = action.podulatorReceipts.reduce((agg, receipt) => {
            agg[receipt.id] = receipt;
            return agg;
        }, {});
        return Object.assign({}, state, newReceipts);
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    return state;
}
