import {css} from "@emotion/core";

export default (styles, switches) => {
    const gathered = Object
        .keys(switches)
        .filter(k => switches[k])
        .map(k => styles[k]);
    return css(...gathered);
};

export const combineStylesAlt = (switches) => {
    return css(...switches.filter(s => s[1]).map(s => s[0]));
};
