import {CURRICULA_LOADED} from "../../curricula/actions";
import {SCROLLER_EXPLAINER} from "../../../constants/TaskTypes";
import {ADD_INTERACTABLE_TO_TASK, REMOVE_INTERACTABLE_FROM_TASK} from "../actions";
import {omit} from "lodash";
import {SET_EXPLAINER_QUESTION} from "./actions";

const defaultItem = {
    question: null,
};

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const newPredictions = action.curricula.reduce((agg, c) => ({
            ...agg,
            ...(c.tasks || []).reduce((taskAgg, t) => ({
                ...taskAgg,
                ...t.items.filter(i => i.__typename === SCROLLER_EXPLAINER).reduce((itemsAgg, i) => ({
                    ...itemsAgg,
                    [i.id]: {
                        ...defaultItem,
                        question: i.question,
                    },
                }), {}),
            }), {}),
        }), {});
        return {
            ...state,
            ...newPredictions,
        };
    }
    if(action.type === ADD_INTERACTABLE_TO_TASK && action.interactableType === SCROLLER_EXPLAINER) {
        return {
            ...state,
            [action.interactableId]: {
                ...defaultItem,
            },
        };
    }
    if(action.type === REMOVE_INTERACTABLE_FROM_TASK) {
        return omit(state, action.interactableId);
    }
    if(action.type === SET_EXPLAINER_QUESTION) {
        return {
            ...state,
            [action.explainerId]: {
                ...state[action.explainerId],
                question: action.question,
            },
        };
    }
    return state;
}
