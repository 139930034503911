import "./multiple-choice-input.scss";
import React from 'react';
import classNames from 'class-names';
import {useDispatch, useSelector} from "react-redux";
import {
    getMultipleChoiceInputCorrectSelection,
    getMultipleChoiceInputMultiselect,
    getMultipleChoiceInputOptions,
    getMultipleChoiceInputPrompt
} from "../../../store/curriculumTaskItems/multipleChoiceInput/selectors";
import RichTextDisplay from "../RichTextDisplay";
import {getMultipleChoiceInputSelection} from "../../../store/interactions/selectors";
import {setMultipleChoiceInputSelection} from "../../../store/interactions/actions";
import {useParams} from "@reach/router";

export default ({ id }) => {
    const prompt = useSelector(state => getMultipleChoiceInputPrompt(state, id));
    const options = useSelector(state => getMultipleChoiceInputOptions(state, id));
    const multiselect = useSelector(state => getMultipleChoiceInputMultiselect(state, id));
    const selection = useSelector(state => getMultipleChoiceInputSelection(state, id));
    const correctSelection = useSelector(state => getMultipleChoiceInputCorrectSelection(state, id));
    const dispatch = useDispatch();
    const {curriculumId} = useParams();

    const inputType = multiselect ? "checkbox" : "radio";

    const selectOption = (e) => {
        const index = parseInt(e.target.value);
        if(!e.target.checked) {
            return dispatch(setMultipleChoiceInputSelection(curriculumId, id, selection.filter(i => i !== index)));
        }
        if(multiselect) {
            dispatch(setMultipleChoiceInputSelection(curriculumId, id, selection.concat(index)));
        } else {
            dispatch(setMultipleChoiceInputSelection(curriculumId, id, [index]));
        }
    };

    const shouldShowCorrectness = selection.length > 0;
    const correctness = options.map((o, i) => correctSelection.includes(i) ? selection.includes(i) : !selection.includes(i));

    return (
        <div className="learner-curriculum-task--multiple-choice-input">
            <div className="learner-multiple-choice-input--prompt">
                <RichTextDisplay content={prompt} />
            </div>
            <div className="learner-multiple-choice-input--options-container">
                {options.map((o, i) => (
                <div key={`${id}-option-${i}`} className="learner-multiple-choice-input--option">
                    <input id={`${id}-option-${i}`} type={inputType} onChange={selectOption} value={i} checked={selection.includes(i)} className="learner-multiple-choice-option--input" />
                    <label htmlFor={`${id}-option-${i}`} className="learner-multiple-choice-option--label">{o}</label>
                    {shouldShowCorrectness && <span className={classNames({
                        "learner-multiple-choice-input--option-correctness": true,
                        "learner-multiple-choice-input--option-correctness__incorrect": !correctness[i],
                    })} /> }
                </div>
                ))}
            </div>
        </div>
    )
}
