import {css} from "@emotion/core";
import blankBackground from './Assets/Blank.jpg';
import {bbPadding} from "../style-variables";

export const workBoardContainer = css({
    flex: "1 1 0",
    minWidth: 0,
    minHeight: 0,
});

export const workBoard = css({
    display: "inline-block",
    verticalAlign: "top",
    width: "100%",
    height: "100%",
    border: "1px solid #aaa",
    position: "relative",
    zIndex: 0,
    backgroundSize: "100%",
    backgroundImage: `url(${blankBackground})`,
    overflow: "auto",
}, bbPadding("8px"));
