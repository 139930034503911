import React, {useEffect, useRef, useState} from 'react';
import * as styles from './headset-authorization-styles';
import {useDispatch, useSelector} from "react-redux";
import {getHeadsetPairingKey, isAuthorizingHeadset} from "../../store/identity/selectors";
import {tryAuthorizeHeadset, tryRevokeAuthorization} from "../../store/identity/actions";
import {isModalOpenFor} from "../../store/navigation/selectors";
import {FAKE_HEADSET_PAIRING_MODAL, HEADSET_PAIRING_MODAL} from "../../constants/modalTypes";
import {closeModalType, openModalFor} from "../../store/navigation/actions";
import {useTheme} from "@emotion/react";
import {themedHeaderButton, themedOpenHeaderButton} from "../../style-variables";
import CloseX from '../../inline-assets/close.svg';
import {useNavigate, useParams} from "@reach/router";
import {getNextTaskInCurriculum} from "../../store/curriculumCases/selectors";

const HeadsetAuthorization = () => {
    const {curriculumId, taskId} = useParams();
    const [pairingKey, setPairingKey] = useState("");
    const pairing = useSelector(state => isModalOpenFor(state, HEADSET_PAIRING_MODAL));
    const fakeModalOpen = useSelector(state => isModalOpenFor(state, FAKE_HEADSET_PAIRING_MODAL));
    const authorizedPairingKey = useSelector(getHeadsetPairingKey);
    const tryingAuthorization = useSelector(isAuthorizingHeadset);
    const dispatch = useDispatch();
    const inputRef = useRef();
    const theme = useTheme();
    const navigate = useNavigate();
    const nextTask = useSelector(state => getNextTaskInCurriculum(state, curriculumId, taskId));

    const authorized = !!authorizedPairingKey;

    useEffect(() => {
        if(!inputRef.current) { return; }
        inputRef.current.focus();
    }, [pairing]);

    const openDialog = () => dispatch(openModalFor(HEADSET_PAIRING_MODAL));

    const pairingKeyInput = e => setPairingKey(e.target.value);

    const pairButton = () => {
        dispatch(tryAuthorizeHeadset(pairingKey));
    };
    const cancelButton = () => {
        setPairingKey("");
        dispatch(closeModalType(HEADSET_PAIRING_MODAL));
    };
    const disconnectButton = () => {
        dispatch(tryRevokeAuthorization());
    };
    const closeFake = () => {
        navigate(`./${nextTask}`);
        dispatch(closeModalType(FAKE_HEADSET_PAIRING_MODAL));
    };

    const enterPress = e => e.key === "Enter" ? pairButton() : null;

    return (
        <>
            <button css={pairing ? themedOpenHeaderButton(theme) : themedHeaderButton(theme)} onClick={openDialog} disabled={tryingAuthorization}>
                {authorized ? "Stop Pairing" : "Connect Headset"}
            </button>
            {pairing &&
            <div css={styles.dialog}>
                <div css={styles.dialogBody(theme)}>
                    <h2 css={styles.dialogTitle(theme)}>Connect Headset</h2>
                    {!authorized && <div css={styles.explanatoryText(theme)}>Enter your VR partner’s 3-digit pairing code.</div>}
                    {authorized && <div css={styles.explanatoryText(theme)}>Headset paired using:</div>}
                    {authorized && <div css={styles.pairingKey(theme)}>{authorizedPairingKey}</div>}
                    {!authorized && <input ref={inputRef} type="text" css={styles.dialogInput(theme)} onChange={pairingKeyInput} onKeyDown={enterPress} placeholder="Pairing Key" disabled={tryingAuthorization}/>}
                    <div css={styles.buttonContainer}>
                        {!authorized && !tryingAuthorization && <button css={styles.dialogPairButton(theme)} onClick={pairButton} disabled={!pairingKey}>Connect</button>}
                        {!authorized && tryingAuthorization && <div css={styles.dialogPairingPlaceholder(theme)} />}
                        {authorized && <button css={styles.dialogPairButton(theme)} onClick={disconnectButton}>Disconnect</button>}
                        {authorized && <button css={styles.dialogPairButton(theme)} onClick={cancelButton}>Ok</button>}
                    </div>
                    <button css={styles.dialogCancelButton(theme)} onClick={cancelButton}><CloseX /></button>
                </div>
            </div>
            }
            {fakeModalOpen &&
            <div css={styles.dialog}>
                <div css={styles.dialogBody(theme)}>
                    <h2 css={styles.dialogTitle(theme)}>VR: Nano Drone</h2>
                    <div css={styles.explanatoryText(theme)}>Put on your headset and open up the Nano app.<br/>Once in, Tell your partner the pin.</div>
                    <div css={styles.secondaryText(theme)}>If you are supposed to be Mission Control this<br /> round, please exit this popup.</div>
                    <button css={styles.dialogCancelButton(theme)} onClick={closeFake}><CloseX /></button>
                </div>
            </div>}
        </>
    )
};

export default HeadsetAuthorization;
