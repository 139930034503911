import {CREATE_PEA_PLANT_CARD, PEA_PLANT_CARDS_LOADED} from "./actions";
import {SET_AUTHENTICATION_STATE} from "../identity/actions";
import {NOT_LOGGED_IN} from "../../constants/AuthenticationState";
import {CLONE_PLANT_CARD_AS_PHENOTYPE, CLONE_WORK_BOARD_ITEM} from "../workBoards/actions";
import {PHENOTYPE, PLANT} from "../../constants/peaPlantCardDisplayModes";
import {INTERACTIONS_LOADED} from "../interactions/actions";
import {DROP_AREA_INTERACTION, WORK_BOARD_CROSS_CARD_INTERACTION} from "../../constants/TaskTypes";
import {PEA_PLANT_CARD} from "../../constants/WorkBoardItemTypes";
import {deletePolaroidAttribute} from "../polaroids/actions";

const defaultCard = {
    peaPlant: null,
    cardDisplayMode: PLANT,
    displayTrait: null,
}

export default (state = {}, action) => {
    if(action.type === PEA_PLANT_CARDS_LOADED) {
        const cards = action.cards.reduce((agg, c) => {
            agg[c.id] = {
                ...defaultCard,
                ...c,
            };
            return agg;
        }, {});
        return {
            ...state,
            ...cards,
        };
    }
    if(action.type === INTERACTIONS_LOADED) {
        const cards = action.interactions.reduce((agg, i) => {
            if(i.__typename === DROP_AREA_INTERACTION) {
                return {
                    ...agg,
                    ...(i.draggables.filter(item => item.item.__typename === PEA_PLANT_CARD).reduce((itemAgg, item) => ({
                        ...itemAgg,
                        [item.item.id]: {
                            ...defaultCard,
                            peaPlant: item.item.peaPlant.id,
                            cardDisplayMode: item.item.cardDisplayMode,
                            displayTrait: item.item.displayTrait,
                        },
                    }), {})),
                };
            } else if(i.__typename === WORK_BOARD_CROSS_CARD_INTERACTION) {
                return {
                    ...agg,
                    ...(i.parents.concat(i.offspring).filter(item => !!item).reduce((itemAgg, item) => ({ // TODO: this will need to filter to just pea plant cards
                        ...itemAgg,
                        [item.id]: {
                            ...defaultCard,
                            peaPlant: item.peaPlant.id,
                            cardDisplayMode: item.cardDisplayMode,
                            displayTrait: item.displayTrait,
                        },
                    }), {})),
                }
            }
            return agg;
        }, {});
        return {
            ...state,
            ...cards,
        };
    }
    if(action.type === CREATE_PEA_PLANT_CARD) {
        return {
            ...state,
            [action.cardId]: {
                ...defaultCard,
                id: action.cardId,
                peaPlant: action.plantId,
            },
        };
    }
    if(action.type === CLONE_WORK_BOARD_ITEM) {
        const original = state[action.itemId];
        if(!original) { return state; }
        return {
            ...state,
            [action.newId]: {
                ...original,
                id: action.newId,
            }
        }
    }
    if(action.type === CLONE_PLANT_CARD_AS_PHENOTYPE) {
        const original = state[action.cardId];
        return {
            ...state,
            [action.newId]: {
                ...original,
                id: action.newId,
                cardDisplayMode: PHENOTYPE,
                displayTrait: action.trait,
            },
        };
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    return state;
}
