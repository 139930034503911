import * as styles from './phenotype-style';
import React from 'react';
import {css} from "@emotion/core";
import {
    AXIAL_FLOWERS,
    CONSTRICTED_PODS, EXPRESSION_DISPLAY_NAME,
    GREEN_PODS,
    GREEN_SEEDS,
    INFLATED_PODS,
    PURPLE_FLOWERS,
    ROUND_SEEDS,
    SHORT_PLANT,
    TALL_PLANT,
    TERMINAL_FLOWERS,
    WHITE_FLOWERS,
    WRINKLED_SEEDS,
    YELLOW_PODS,
    YELLOW_SEEDS
} from "../../constants/PlantPhenotypes";

const styleMap = {
    [TALL_PLANT]: "tall",
    [SHORT_PLANT]: "short",
    [PURPLE_FLOWERS]: "purpleFlower",
    [WHITE_FLOWERS]: "whiteFlower",
    [AXIAL_FLOWERS]: "axialFlower",
    [TERMINAL_FLOWERS]: "terminalFlower",
    [YELLOW_SEEDS]: "yellowSeed",
    [GREEN_SEEDS]: "greenSeed",
    [ROUND_SEEDS]: "roundSeed",
    [WRINKLED_SEEDS]: "wrinkledSeed",
    [GREEN_PODS]: "greenPod",
    [YELLOW_PODS]: "yellowPod",
    [INFLATED_PODS]: "inflatedPod",
    [CONSTRICTED_PODS]: "constrictedPod",
}

export default props => {
    const {phenotype} = props;

    return (
        <div css={styles.container}>
            <div css={css(styles.image, styles[styleMap[phenotype]])} />
            <div css={styles.label}>{EXPRESSION_DISPLAY_NAME[phenotype]}</div>
        </div>
    )
}
