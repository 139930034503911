export const LEARNER_WORK_BOARDS_LOADED = "ADD WORK BOARDS TO LEARNER";
export const learnerWorkBoardsLoaded = (learnerId, workBoards) => ({
    type: LEARNER_WORK_BOARDS_LOADED,
    learnerId,
    workBoards,
});

export const UPDATE_LEARNER_NAME = "UPDATE LEARNER NAME";
export const updateLearnerName = (learnerId, givenName, familyName) => ({
    type: UPDATE_LEARNER_NAME,
    learnerId,
    givenName,
    familyName,
});

export const BOUNTY_PROGRESS_UPDATED = "Bounty progress updated";
export const bountyProgressUpdated = (learnerId, bountyId, progress) => ({
    type: BOUNTY_PROGRESS_UPDATED,
    learnerId,
    bountyId,
    progress,
});


export const VR_START_TIME_UPDATED = "VR start time updated";
export const vrStartTimeUpdated = (learnerId, startTime) => ({
    type: VR_START_TIME_UPDATED,
    learnerId,
    startTime,
});

export const ADD_PARTNER_TO_LEARNER = "add partner to learner";
export const addPartnerToLearner = (learnerId, curriculumId, partnerId) => ({
    type: ADD_PARTNER_TO_LEARNER,
    learnerId,
    curriculumId,
    partnerId,
});

export const PARTNER_REQUEST_LOADED = "partner request loaded";
export const partnerRequestLoaded = (curriculumId, requesterId, partnerId) => ({
    type: PARTNER_REQUEST_LOADED,
    curriculumId,
    requesterId,
    partnerId,
});

export const CREATE_PARTNER_REQUEST = "create partner request";
export const createPartnerRequest = (curriculumId, requesterId, partnerId) => ({
    type: CREATE_PARTNER_REQUEST,
    curriculumId,
    requesterId,
    partnerId,
});

export const RESPOND_TO_PARTNER_REQUEST = "respond to partner request";
export const respondToPartnerRequest = (curriculumId, requesterId, partnerId, approve) => ({
    type: RESPOND_TO_PARTNER_REQUEST,
    curriculumId,
    requesterId,
    partnerId,
    approve,
});

export const PARTNER_REQUEST_RESPONDED_TO = "partnership request responded to";
export const partnerRequestRespondedTo = (curriculumId, requesterId, partnerId, approve) => ({
    type: PARTNER_REQUEST_RESPONDED_TO,
    curriculumId,
    requesterId,
    partnerId,
    approve,
});

export const END_PARTNERSHIP = "end partnership";
export const endPartnership = (curriculumId, otherLearner) => ({
    type: END_PARTNERSHIP,
    curriculumId,
    otherLearner,
});

export const REMOVE_PARTNER_FROM_LEARNER = "remove partner from learner";
export const removePartnerFromLearner = (curriculumId, learnerId, partnerId) => ({
    type: REMOVE_PARTNER_FROM_LEARNER,
    curriculumId,
    learnerId,
    partnerId,
});
