import {itemFragment} from "./workBoardItem";

export const getVerdantTeacherQuery = `{
    verdantTeacher {
        id
        cohorts {
            id
            name
            learners {
                id
                email
                familyName
                givenName
                vrSessionStartTime
            }
            workBoards {
                id
                template
                items {
                    x
                    y
                    angle
                    ${itemFragment}
                }
            }
            curricula {
                id
                name
                type
                parentCurriculum
            }
        }
    }
}`;
