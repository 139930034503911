import {call, select, takeEvery} from 'redux-saga/effects';
import {PLACE_PLANT_IN_CROSS} from "../../../store/interactions/actions";
import {getCrossOffspringCards, getCrossParentCards} from "../../../store/interactions/selectors";
import {getActiveLearner} from "../../../store/navigation/selectors";
import {sendGraphQLSaga} from "../../DataService";
import {getDropAreaType} from "../../../store/workBoards/selectors";
import {WORK_BOARD} from "../../../constants/WorkBoardItemTypes";
import {updateWorkBoardItemOrder} from "./workBoard";
import {WORK_BOARD_CROSS_CARD, WORK_BOARD_DROP_SLOT} from "../../../constants/TaskTypes";
import {setDropAreaDraggableItems} from "./draggableDropped";

const setCardsInCross = `mutation SetCrossCardPlantCards($input: SetCrossCardPlantCardsInput!) {
    setCrossCardPlantCards(input: $input) { 
        interaction {
            interactable {
                id
            }
        }
    }
}`;

function* execute(action) {
    const learnerId = yield select(state => getActiveLearner(state));
    if(!learnerId) { return; }

    yield call(setCrossCardCards, action.crossCardId, learnerId, action.curriculumId);

    if(action.startingLocation && action.startingLocation === action.crossCardId) { return; }
    const itemType = yield select(state => getDropAreaType(state, action.startingLocation));
    if(itemType === WORK_BOARD) {
        yield call(updateWorkBoardItemOrder, action.startingLocation);
    } else if(itemType === WORK_BOARD_DROP_SLOT) {
        yield call(setDropAreaDraggableItems, action.startingLocation, learnerId, action.curriculumId);
    } else if(itemType === WORK_BOARD_CROSS_CARD) {
        yield call(setCrossCardCards, action.startingLocation, learnerId, action.curriculumId);
    }
}
export function* setCrossCardCards(crossCardId, learnerId, curriculumId) {
    const parents = yield select(state => getCrossParentCards(crossCardId, state));
    const offspring = yield select(state => getCrossOffspringCards(crossCardId, state));

    const variables = {
        input: {
            learner: learnerId,
            curriculum: curriculumId,
            crossCard: crossCardId,
            parents,
            offspring,
        },
    };

    yield call(sendGraphQLSaga, setCardsInCross, variables);
}

// TODO: call when items are moved.

export default [
    takeEvery(PLACE_PLANT_IN_CROSS, execute),
];
