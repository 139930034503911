import {css} from "@emotion/core";
import purpleFlowerImage from './Assets/PurpleFlower.png';
import whiteFlowerImage from './Assets/WhiteFlower.png';
import axialFlowerImage from './Assets/AxialFlower.png';
import terminalFlowerImage from './Assets/TerminalFlower.png';
import yellowSeedImage from './Assets/YellowSeed.png';
import greenSeedImage from './Assets/GreenSeed.png';
import roundSeedImage from './Assets/RoundSeed.png';
import wrinkledSeedImage from './Assets/WrinkledSeed.png';
import greenPodImage from './Assets/GreenPod.png';
import yellowPodImage from './Assets/YellowPod.png';
import inflatedPodImage from './Assets/InflatedPod.png';
import constrictedPodImage from './Assets/ConstrictedPod.png';
import tallImage from './Assets/Tall.png';
import shortImage from './Assets/Short.png';
import {bbPadding, inlineBlockTop} from "../../style-variables";

export const container = css({
    textAlign: 'center',
    width: 56,
    height: 56,
    backgroundColor: "white",
}, bbPadding(6));

export const image = css(inlineBlockTop, {
    width: 24,
    height: 24,
    backgroundSize: "100%",
});

export const label = css({
    fontSize: '0.625em',
    lineHeight: "1.125em",
});

export const purpleFlower = css({
    backgroundImage: `url(${purpleFlowerImage})`,
});
export const whiteFlower = css({
    backgroundImage: `url(${whiteFlowerImage})`,
});
export const axialFlower = css({
    backgroundImage: `url(${axialFlowerImage})`,
});
export const terminalFlower = css({
    backgroundImage: `url(${terminalFlowerImage})`,
});
export const yellowSeed = css({
    backgroundImage: `url(${yellowSeedImage})`,
});
export const greenSeed = css({
    backgroundImage: `url(${greenSeedImage})`,
});
export const roundSeed = css({
    backgroundImage: `url(${roundSeedImage})`,
});
export const wrinkledSeed = css({
    backgroundImage: `url(${wrinkledSeedImage})`,
});
export const greenPod = css({
    backgroundImage: `url(${greenPodImage})`,
});
export const yellowPod = css({
    backgroundImage: `url(${yellowPodImage})`,
});
export const inflatedPod = css({
    backgroundImage: `url(${inflatedPodImage})`,
});
export const constrictedPod = css({
    backgroundImage: `url(${constrictedPodImage})`,
});
export const tall = css({
    backgroundImage: `url(${tallImage})`,
});
export const short = css({
    backgroundImage: `url(${shortImage})`,
});

