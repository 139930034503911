import "./cohort-learner.scss";
import React, {useEffect, useState} from 'react';
import classNames from 'class-names';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    getEmailForLearner,
    getFamilyNameForLearner,
    getGivenNameForLearner,
    getVRStartTimeForLearner
} from "../../store/learners/selectors";
import {Link} from "@reach/router";
import {openModalFor} from "../../store/navigation/actions";
import {getDataService} from "../../store/data/selectors";
import {reassignCurriculumToLearner, resetCurriculaForLearner} from "../../graphql/Curriculum";
import {getShowResetCurriculum} from "../../store/navigation/selectors";
import {getPINForEmail} from "../../Identity/utility";
import {EDIT_LEARNER_MODAL} from "../../constants/modalTypes";

const stateMap = props => state => ({
    email: getEmailForLearner(state, props.id),
    givenName: getGivenNameForLearner(state, props.id),
    familyName: getFamilyNameForLearner(state, props.id),
    vrStartTime: getVRStartTimeForLearner(state, props.id),
    dataService: getDataService(state),
    showResetCurriculum: getShowResetCurriculum(state),
});

export default React.memo(props => {
    const { email, givenName, familyName, vrStartTime, dataService, showResetCurriculum } = useSelector(stateMap(props), shallowEqual);
    const [showResetDialog, setShowResetDialog] = useState(false);
    const [updateTime, updateTimer] = useState(0);
    useEffect(() => {
        let timeout;
        const count = () => {
            updateTimer(updateTime + 1);
            timeout = setTimeout(count, 1000);
        };
        timeout = setTimeout(count, 1000);
        return () => clearTimeout(timeout);
    });

    const nameDisplay = givenName && familyName
        ? `${givenName} ${familyName}`
        : email;

    const pinDisplay  = getPINForEmail(email) || "";

    const now = Date.now();
    const minutes = vrStartTime ? Math.floor((now - vrStartTime)/60000) : null;
    const seconds = vrStartTime ? Math.floor(((now - vrStartTime)%60000)/1000) : null;
    const timeDisplay = vrStartTime
        ? `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
        : null;

    const dispatch = useDispatch();
    const editLearner = () => dispatch(openModalFor(EDIT_LEARNER_MODAL, props.id));

    const resetCurricula = () => {
        resetCurriculaForLearner(dataService, props.id);
        setShowResetDialog(false);
    };
    const reassignCurriculum = () => {
        reassignCurriculumToLearner(dataService, props.id, "curricula-0001");
    };

    const openResetDialog = () => setShowResetDialog(true);
    const closeResetDialog = () => setShowResetDialog(false);

    return (
        <div className={classNames({
            "cohort--learner": true,
            "cohort--learner__in-vr": !!timeDisplay,
            "cohort--learner__over-time": minutes >= 20,
        })}>
            <div className='cohort-learner--name'>{nameDisplay}</div>
            <div className='cohort-learner--pin'>{`pin: ${pinDisplay}`}</div>
            <div className='cohort-learner--vr-time'>{timeDisplay ? `VR Time: ${timeDisplay}` : ''}</div>
            <Link to={`./learner/${props.id}`} className="cohort-learner--progress-link">View progress</Link>
            {/*<button onClick={openResetDialog} className="cohort-learner--reset" />*/}
            <button onClick={editLearner} className="cohort-learner--edit" />
            {/*{showResetCurriculum && <button onClick={reassignCurriculum} className="cohort-learner--reassign" />}*/}
            {showResetDialog &&
            <div className="cohort-learner--reset-dialog-block">
                <div className="cohort-learner--reset-dialog">
                    <div className="reset-dialog--prompt">Are you sure you want to reset this student's progress? No work sheets will be reset.</div>
                    <div className="reset-dialog--button-container">
                        <button onClick={resetCurricula} className="reset-dialog--button">Reset</button>
                        <button onClick={closeResetDialog} className="reset-dialog--button">Cancel</button>
                    </div>
                    <button onClick={closeResetDialog} className="reset-dialog--close" />
                </div>
            </div>}
        </div>
    )
});
