import {css} from "@emotion/core";
import bold from '../assets/format_bold.svg';
import italic from '../assets/format_italic.svg';
import header from '../assets/format_header-type.svg';

const buttonDimension = 16;
const barPadding = 3;

export const bar = css({
    position: "absolute",
    left: -29,
    top: 28,
    border: "1px solid #ccc",
    backgroundColor: "#cccccc22",
    padding: barPadding,
    borderRadius: (buttonDimension + 2 * barPadding + 4) / 2,
    zIndex: "2",
});

export const button = css({
    display: "block",
    width: buttonDimension,
    height: buttonDimension,
    position: "relative",
    padding: 0,
    backgroundColor: "#fff",
    backgroundSize: "100% 100%",
    border: "1px solid #ccc",
    borderRadius: "50%",
    transition: "background-color 0.2s",
    cursor: "pointer",
    boxSizing: "border-box",

    "&:hover": {
        backgroundColor: "#dfdfdf",
    },
    "&:not(:last-child)": {
        margin: "0 0 4px 0",
    },
});

export const buttonActive = css({
    backgroundColor: "#ccc",
});

export const boldButton = css({
    backgroundImage: `url(${bold})`,
});

export const italicButton = css({
    backgroundImage: `url(${italic})`,
});

export const headerButton = css({
    backgroundImage: `url(${header})`,
});
