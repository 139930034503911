import './vr-blocker.scss';
import React from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {setVRBlockerShowing} from "../../store/navigation/actions";
import {attemptLogout, getPINForEmail} from "../../Identity/utility";
import {navigate} from "@reach/router";
import {getEmailForLearner} from "../../store/learners/selectors";
import {getActiveLearner} from "../../store/navigation/selectors";

const getPin = state => getPINForEmail(getEmailForLearner(state, getActiveLearner(state)));

export default () => {
    const pin = useSelector(getPin, shallowEqual);
    const dispatch = useDispatch();

    const closeBlocker = () => dispatch(setVRBlockerShowing(false));
    const logOut = () => {
        attemptLogout(dispatch);
        navigate("/");
    };

    return (
        <div className="vr-blocker">
            <button className="vr-blocker--close" onClick={closeBlocker} />
            <h1 className="vr-blocker--title">VR Time</h1>
            <ol className="vr-blocker--steps">
                <li><span className="vr-blocker--step">Close all activity browser tabs, and log out of your web session.</span></li>
                <li><span className="vr-blocker--step">{`Remember your PIN: ${pin}! You'll need to enter it in VR.`}</span></li>
                <li><span className="vr-blocker--step">Stand on your mat.</span></li>
                <li><span className="vr-blocker--step">Put on and adjust your headset. Hold controllers properly.</span></li>
                <li><span className="vr-blocker--step">Now follow instructions in VR!</span></li>
            </ol>
            <div className="vr-blocker--continue-container">
                <button className="vr-blocker--continue" onClick={logOut}>Logout</button>
            </div>
        </div>
    )
};
