import {
    DOGMA_DASH_ALERT_LIST, DOGMA_DASH_CARGO_METER_TUTORIAL,
    DOGMA_DASH_DASHBOARD,
    DOGMA_DASH_NUCLEUS,
    DOGMA_DASH_ORGANELLE_DEBRIS
} from "./dogma-strand-1";
import {
    getCurrentDogmaDashLevel, getDogmaCentrioles,
    getDogmaDashAvailableProteins,
    getDogmaDashLevelUnlockingProtein, getDogmaLysosomeIds, getDogmaMicrotubuleIds,
    getDogmaMrnaSlotIds,
    getDogmaRibosomeIds, getLysosomeTargetOrganelleId, getLysosomeTargetStartTime,
    getObjectForm,
    getObjectsInOrganelle,
    getObjectType, getOrganelleTimerDuration,
    getOrganelleTimerStart, getOrganelleTimerType
} from "../../interactions/dogmaDash/selectors";
import {DefaultCargo, Motor, ProteinForm_Denatured} from "../../../constants/DogmaDash";
import {mrnaInSlot, proteinTypeAtOrganelle} from "./utility";

export default {
    id: "dc6650cc-2a50-4b76-8891-db3f83bde985",
    items: [
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== DOGMA_DASH_DASHBOARD || !dogmaDashId) { return false; }
                const availableProteins = getDogmaDashAvailableProteins(state, dogmaDashId);
                const unlockingProtein = getDogmaDashLevelUnlockingProtein(state, dogmaDashId);
                const levelNumber = getCurrentDogmaDashLevel(state, dogmaDashId);
                return availableProteins.length === 2 && !unlockingProtein && levelNumber === 1;
            },
            header: "Mission Control",
            text: "This control panel connects you to Cell X, so you can help the drone pilot accomplish missions.",
            forceCompletion: (state, {dogmaDashId}) => getDogmaLysosomeIds(state, dogmaDashId).some(l => !!getLysosomeTargetOrganelleId(state, l))
        },
        {
            condition: (state, {location, organelleId}) => {
                if(location !== DOGMA_DASH_ORGANELLE_DEBRIS) { return false; }
                return getObjectsInOrganelle(state, organelleId).filter(o => getObjectForm(state, o) === ProteinForm_Denatured).length > 0;
            },
            header: "Clear Debris",
            text: "You have control of the Lysosome, the cell’s recycler system.",
            callToAction: "Click debris to break it down",
            completionCondition: (state, {organelleId}) => {
                return getObjectsInOrganelle(state, organelleId).filter(o => getObjectForm(state, o) === ProteinForm_Denatured).length === 0;
            },
        },
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== DOGMA_DASH_ALERT_LIST || !dogmaDashId) { return false; }
                const centrioles = getDogmaCentrioles(state, dogmaDashId);
                const withAlerts = centrioles.filter(oId => !!getOrganelleTimerDuration(state, oId) && getOrganelleTimerType(state, oId) === "Alert");
                return withAlerts.length > 0;
            },
            header: "Alert List",
            text: "Cargo is building up. The cell needs a delivery protein to remove cargo from the cell.",
            forceCompletion: (state, {dogmaDashId}) => mrnaInSlot(state, dogmaDashId, Motor),
        },
        {
            condition: (state, {location}) => location === DOGMA_DASH_ALERT_LIST,
            header: "mRNA Inventory",
            text: "mRNA holds the code used to build proteins. Right now, you have the code for the <b>Delivery Protein</b> and <b>Recycler Enzyme</b>.",
            forceCompletion: (state, {dogmaDashId}) => mrnaInSlot(state, dogmaDashId, Motor),
        },
        {
            condition: (state, {location}) => location === DOGMA_DASH_NUCLEUS,
            header: "RNA Drop Sites",
            text: "You can send mRNA code to your drone pilot by dropping the mRNA into these slots.",
            callToAction: "Drag and drop Delivery mRNA",
            completionCondition: (state, {dogmaDashId}) => {
                if(!dogmaDashId) {return false;}
                return mrnaInSlot(state, dogmaDashId, Motor);
            },
        },
        {
            condition: (state, {location}) => location === DOGMA_DASH_NUCLEUS,
            header: "mRNA is in the Portal",
            callToAction: "Check in with The Pilot, tell them to grab the mRNA from the portal in front of the nucleus",
            completionCondition: (state, {location, dogmaDashId}) => {
                if(location !== DOGMA_DASH_NUCLEUS || !dogmaDashId) {return false;}
                const mrnaSlots = getDogmaMrnaSlotIds(state, dogmaDashId);
                return !mrnaSlots.some(oId => getObjectsInOrganelle(state, oId).length > 0);
            },
        },
        { // try to avoid skips... really should track that down...
            condition: state => true,
            forceCompletion: () => true,
        },
        {
            condition: (state, {location, dogmaDashId, organelleId}) => {
                if(location === DOGMA_DASH_ORGANELLE_DEBRIS || getDogmaRibosomeIds(state, dogmaDashId).indexOf(organelleId) < 0) {return false;}
                return getObjectsInOrganelle(state, organelleId).length > 0;
            },
            header: "Translation in Progress",
            text: "The pilot has inserted the Delivery Protein mRNA into the <b>ribosome</b. Now it’s being translated into a protein.",
            callToAction: "Keep track of translation timer",
            completionCondition: (state, {organelleId}) => {
                if(!organelleId) { return false; }
                return !getOrganelleTimerStart(state, organelleId) || getOrganelleTimerType(state, organelleId) === "Burning";
            },
        },
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== DOGMA_DASH_CARGO_METER_TUTORIAL) {return false;}
                return getDogmaMicrotubuleIds(state, dogmaDashId).some(m => proteinTypeAtOrganelle(state, m, DefaultCargo));
            },
            forceCompletion: () => true,
        },
        {
            condition: (state, {location}) => location === DOGMA_DASH_CARGO_METER_TUTORIAL,
            header: "Delivery Protein Working!",
            text: "The cargo level will decrease as the pilot loads cargo onto the delivery proteins.  Below halfway, the alert wil clear.",
        },
    ]
}
