import React, {useMemo} from 'react';
import {Editable, Slate, withReact} from "slate-react";
import {createEditor, Node} from "slate";
import renderLeaf from "../../RichText/renderLeaf";
import editorBlockRenderer from "./RichText/editorBlockRenderer";
import {css} from "@emotion/core";

export default React.memo(props => {
    const {content} = props;
    const editor = useMemo(() => withReact(createEditor()));

    if(!content) { return null; }

    let parsed = null;
    try {
        parsed = JSON.parse(content);
    } catch {
        parsed = [{type: 'paragraph', children: [{text:content || ""}]}];
    }

    if(parsed.length === 1 &&
        (parsed[0].type === 'paragraph' && Node.string(parsed[0]).length === 0)) {
        return null;
    } else if(!parsed[0] || !parsed[0].children) {
        parsed = [{type: 'paragraph', children: [{text:content}]}];
    }

    return (
        <Slate editor={editor} value={parsed}>
            <Editable
                readOnly
                renderElement={editorBlockRenderer}
                renderLeaf={renderLeaf}
                value={content}
                css={css({ "& p:first-of-type": { marginTop: 0 }})}
            />
        </Slate>
    )
});
