import {css} from "@emotion/core";

export const container = css({
    width: "100%",
    height: "100%",
});

export const editor = css({
    "cursor": "text",

    "& p:first-of-type": {
        "marginTop": 0,
    },
});
