import {css} from "@emotion/core";
import {bbPadding, themedBodyButton} from "../../style-variables";
import {
    bodyFontColor,
    bodyFontFamily,
    bodyFontWeight,
    bodyHeader1FontColor,
    bodyHeader1FontFamily,
    bodyHeader1FontSize,
    bodyHeader1FontWeight,
    bodyHeader2FontColor,
    bodyHeader2FontFamily,
    bodyHeader2FontSize,
    bodyHeader2FontWeight,
    bodyHeader3FontColor,
    bodyHeader3FontFamily,
    bodyHeader3FontSize,
    bodyHeader3FontWeight
} from "../themes/selectors";

export const task = css({
    minHeight: "100%",
    position: "relative",
    zIndex: 0,
});

export const themedTaskContentContainer = theme => css(bbPadding("74px 30% 0 130px"), {
    width: "100%",
    fontFamily: bodyFontFamily(theme),
    fontWeight: bodyFontWeight(theme),
    color: bodyFontColor(theme),
    fontSize: "1.25rem",

    "& h1": {
        fontFamily: bodyHeader1FontFamily(theme),
        fontWeight: bodyHeader1FontWeight(theme),
        color: bodyHeader1FontColor(theme),
        fontSize: `${bodyHeader1FontSize(theme)}!important`,
        lineHeight: "1.25em",
        padding: 0,
        margin: "0 0 25px 0",

        "& p": {
            margin: 0,
        }
    },

    "& h2": {
        fontFamily: bodyHeader2FontFamily(theme),
        fontWeight: bodyHeader2FontWeight(theme),
        color: bodyHeader2FontColor(theme),
        fontSize: `${bodyHeader2FontSize(theme)}!important`,
        lineHeight: "1.25em",
        padding: 0,
        margin: "0 0 20px 0",

        "& p": {
            margin: 0,
        }
    },

    "& h3": {
        fontFamily: bodyHeader3FontFamily(theme),
        fontWeight: bodyHeader3FontWeight(theme),
        color: bodyHeader3FontColor(theme),
        fontSize: `${bodyHeader3FontSize(theme)}!important`,
        lineHeight: "1.25em",
        padding: 0,
        margin: "0 0 20px 0",

        "& p": {
            margin: 0,
        }
    },
});

export const interactableContainer = css({
    margin: "10px 0",
});

export const continueButton = css({
    position: 'absolute',
    bottom: "28px",
    left: "50%",
    transform: "translateX(-50%)",
});

export const themePreviousButton = themedBodyButton;
