import "./long-text-input.scss";
import React from 'react';
import {useSelector, shallowEqual, useDispatch} from "react-redux";
import RichEditor from "../../../../RichText/RichEditor";
import {getLongTextInputPrompt} from "../../../../store/curriculumTaskItems/longTextInput/selectors";
import {setLongTextInputPrompt} from "../../../../store/curriculumTaskItems/longTextInput/actions";
import {useParams} from "@reach/router";

export default ({id}) => {
    const {curriculumId: curriculum} = useParams();
    const prompt = useSelector(state => getLongTextInputPrompt(state, id), shallowEqual);
    const dispatch = useDispatch();

    const setPrompt = newPrompt => dispatch(setLongTextInputPrompt(curriculum, id, newPrompt));

    return (
        <div className="admin-task--long-text-input">
            <div className="long-text-input--title">Prompt:</div>
            <div className="long-text-input--prompt-container">
                <RichEditor content={prompt} setContent={setPrompt} id={id} />
            </div>
        </div>
    )
};
