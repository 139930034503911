import * as styles from './dogma-dash-styles';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getCurrentDogmaDashLevel,
    getCurrentDogmaDashScore, getCurrentDogmaDashStage,
    getDogmaCellMembraneIds,
    getDogmaCentrioles, getDogmaDashAvailableProteins, getDogmaDashCytoplasm, getDogmaDashFinalScore,
    getDogmaDashLevelDuration, getDogmaDashLevelsInStage, getDogmaDashLevelStars,
    getDogmaDashLevelStartTime, getDogmaDashLevelUnlockingProtein, getDogmaDashProteinsDelivered,
    getDogmaGolgiBodyIds,
    getDogmaLysosomeIds,
    getDogmaMicrotubuleIds,
    getDogmaMitochondriaIds,
    getDogmaMrnaSlotIds,
    getDogmaRibosomeIds, isDogmaDashProteinUnlockingNeeded
} from "../../../../store/interactions/dogmaDash/selectors";
import MrnaSlot from "./MrnaSlot";
import MrnaInventory from "./MrnaInventory";
import {
    Antibody,
    Antibody2,
    GENE_SELECTION_VIEW,
    ProteinForm_ProteinInVesicle,
    Structural
} from "../../../../constants/DogmaDash";
import {setDogmaDashView} from "../../../../store/interactions/dogmaDash/actions";
import AlertPanel from "./AlertPanel";
import CookingOrganelle from "./CookingOrganelle";
import Microtubule from "./Microtubule";
import CargoCounter from "./CargoCounter";
import Lysosome from "./Lysosome";
import {getTutorialDataToShow} from "../../../../store/tutorial/selectors";
import {
    DOGMA_DASH_CARGO_METER_TUTORIAL,
    DOGMA_DASH_DASHBOARD,
    DOGMA_DASH_NUCLEUS
} from "../../../../store/tutorial/configuration/dogma-strand-1";
import PopUpTutorial from "../../../Tutorial/PopUpTutorial";
import OrganelleDebris from "./OrganelleDebris";
import {useNavigate, useParams} from "@reach/router";
import GolgiDebrisArea from "./GolgiDebrisArea";
import {closeModalType, openModalFor, setGlobalInteractiveOpen} from "../../../../store/navigation/actions";
import ViralLoadMeter from "./ViralLoadMeter";
import {isModalOpenFor} from "../../../../store/navigation/selectors";
import {DOGMA_HEALTH_WARNING, DOGMA_HEALTH_WARNING_2} from "../../../../constants/modalTypes";
import {modalBlocker} from "../../../../style-variables";
import {tryRevokeAuthorization} from "../../../../store/identity/actions";
import CytoplasmArea from "./CytoplasmArea";
import {getNextTaskInCurriculum} from "../../../../store/curriculumCases/selectors";

const microtubuleStyling = [styles.microtubuleOne, styles.microtubuleTwo, styles.microtubuleThree, styles.microtubuleFour];

const createTimeString = timeLeft => {
    const minutes = Math.floor(timeLeft / 60000);
    const seconds = Math.floor(timeLeft / 1000) - (minutes * 60);
     return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
}

const FirstAntibodyModal = () => {
    const [panel, setPanel] = useState(0);
    const dispatch = useDispatch();

    const executeContinue = () => {
        if(panel === 3) { return dispatch(closeModalType(DOGMA_HEALTH_WARNING));}
        setPanel(panel+1);
    };

    useEffect(() => {
        if(panel !== 2) {return;}
        setTimeout(executeContinue, 2000);
    });

    return (
        <div css={modalBlocker}>
            <div css={styles.warningModalContainer}>
                <h2 css={styles.warningModalTitle}>Critical Health Alert</h2>
                {panel === 0 && <div css={styles.warningModalText}>Cell X has detected the <b>Pandoravirus</b> in your<br /> bloodstream.</div>}
                {panel === 1 && <div css={styles.warningModalText}>Press continue to install <b>Viral Tracking Package</b><br/> into Mission Control and the Nano Drone</div>}
                {panel === 2 && <div css={styles.warningModalText}><b>Installing...</b></div>}
                {panel === 3 && <div css={styles.warningModalText}>Installation complete.<br/><b>Proceed to nucleus to unlock Antibody mRNA ASAP</b></div>}
                {panel !== 2 && <button css={styles.warningModalButton} onClick={executeContinue}>Continue</button>}
            </div>
        </div>
    );
};

const SecondAntibodyModal = () => {
    const [panel, setPanel] = useState(0);
    const dispatch = useDispatch();

    const executeContinue = () => {
        if(panel === 1) {
            dispatch(closeModalType(DOGMA_HEALTH_WARNING));
            dispatch(tryRevokeAuthorization());
            return dispatch(setGlobalInteractiveOpen(null));
        }
        setPanel(panel+1);
    };

    return (
        <div css={modalBlocker}>
            <div css={styles.warningModalContainer}>
                <h2 css={styles.warningModalTitle}>Critical Health Alert</h2>
                {panel === 0 && <div css={styles.warningModalText}>Your antibodies are no longer working against the<br/>Pandoravirus. </div>}
                {panel === 1 && <div css={styles.warningModalText}>We are ending training here to assess<br/>the antibody problem</div>}
                <button css={styles.warningModalButton} onClick={executeContinue}>{panel !== 1 ? "Continue" : "Return to Training Hub"}</button>
            </div>
        </div>
    );
};

const ScoreBoard = ({dogmaId}) => {
    const {curriculumId, taskId} = useParams();
    const currentScore = useSelector(state => getCurrentDogmaDashScore(state, dogmaId));
    const finalScore = useSelector(state => getDogmaDashFinalScore(state, dogmaId));
    const proteinsDelivered = useSelector(state => getDogmaDashProteinsDelivered(state, dogmaId));
    const currentStage = useSelector(state => getCurrentDogmaDashStage(state, dogmaId));
    const currentLevel = useSelector(state => getCurrentDogmaDashLevel(state, dogmaId));
    const stars = useSelector(state => getDogmaDashLevelStars(state, dogmaId));
    const levelStartTime = useSelector(state => getDogmaDashLevelStartTime(state, dogmaId));
    const levelDuration = useSelector(state => getDogmaDashLevelDuration(state, dogmaId));
    const numberOfStageLevels = useSelector(state => getDogmaDashLevelsInStage(state, dogmaId));
    const shouldShowHealthAlert = useSelector(state => getDogmaDashLevelUnlockingProtein(state, dogmaId) === Antibody2);
    const [previousScore, setPreviousScore] = useState(currentScore);
    const [scoreChange, setScoreChange] = useState(0);
    const [levelComplete, setLevelComplete] = useState(false);

    const timeRef = useRef();
    const barRef = useRef();
    const animationRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const nextTask = useSelector(state => getNextTaskInCurriculum(state, curriculumId, taskId));

    useEffect(() => {
        setScoreChange(currentScore - previousScore);
        setPreviousScore(currentScore);
        const timeout = setTimeout(() => setScoreChange(0), 750);
        return () => window.clearTimeout(timeout);
    }, [currentScore]);
    useEffect(() => {
        if(!shouldShowHealthAlert) {
            dispatch(closeModalType(DOGMA_HEALTH_WARNING_2));
        }
    }, [shouldShowHealthAlert])

    useEffect(() => {
        if(levelComplete) { setLevelComplete(false); }
        if(!levelStartTime && timeRef.current != null) {
            timeRef.current.innerText = createTimeString(levelDuration);
            return;
        }
        const updateBar = () => {
            if(barRef.current == null || timeRef.current == null || !levelStartTime) { return; }
            const timeLeft = Math.max(0, levelDuration - (Date.now() - levelStartTime));
            const target = Math.min(1, timeLeft / levelDuration);
            barRef.current.style.width = `${target * 100}%`;

            timeRef.current.innerText = createTimeString(timeLeft);

            if(timeLeft > 0) {
                animationRef.current = requestAnimationFrame(updateBar);
            } else {
                setLevelComplete(true);
                if(shouldShowHealthAlert) { dispatch(openModalFor(DOGMA_HEALTH_WARNING_2)); }
            }
        };
        animationRef.current = requestAnimationFrame(updateBar);
        return () => cancelAnimationFrame(animationRef.current);
    }, [levelStartTime]);

    useEffect(() => {
        if(levelComplete) { setLevelComplete(false); }
    }, [levelDuration])

    const closeDogma = () => {
        navigate(`./${nextTask}`);
        dispatch(tryRevokeAuthorization());
        dispatch(setGlobalInteractiveOpen(null));
    }

    return (
        <div css={styles.scoreboardContainer}>
            {!levelComplete && <div css={styles.smallScoreboardContent}>
                <div css={styles.scoreboardStage}>{currentLevel}</div>
                <div css={scoreChange > 0 ? styles.scoreboardScoreIncrease : scoreChange < 0 ? styles.scoreboardScoreDecrease : styles.scoreboardScore}>{currentScore}</div>
                <div css={styles.batteryContainer}>
                    <div ref={barRef} css={styles.batteryBar} />
                    <div ref={timeRef} css={styles.batteryText} />
                </div>
            </div>}
            {levelComplete && !shouldShowHealthAlert && <div css={styles.scoreboardBlocker}>
                <div css={styles.endLevelContainer}>
                    <h3 css={styles.endLevelHeader}>Stage {currentStage}</h3>
                    <div css={styles.levelsContainer}>
                        {Array.from({length: numberOfStageLevels}, (_, i) => (
                            <div css={(i+1) === currentLevel ? styles.currentLevelBox : styles.levelBox}>
                                {(i+1) === currentLevel && <div>level</div>}
                                {<div css={styles.currentLevelBoxNumber}>{i+1}</div>}
                            </div>
                        ))}
                    </div>
                    <div css={styles.endLevelScoreContainer}>
                        <span css={styles.endLevelScoreLabel}>Points scored</span>
                        <span css={styles.endLevelScore}>{finalScore}</span>
                    </div>
                    <div css={styles.endLevelScoreContainer}>
                        <span css={styles.endLevelScoreLabel}>Proteins delivered</span>
                        <span css={styles.endLevelScore}>{proteinsDelivered}</span>
                    </div>
                    <div css={styles.endLevelScoreContainer}>
                        <div css={(stars && stars > 0) ? styles.endLevelFilledStar : styles.endLevelStar}>{stars > 0 ? "★" : "☆"}</div>
                        <div css={(stars && stars > 1) ? styles.endLevelFilledStar : styles.endLevelStar}>{stars > 0 ? "★" : "☆"}</div>
                        <div css={(stars && stars > 2) ? styles.endLevelFilledStar : styles.endLevelStar}>{stars > 0 ? "★" : "☆"}</div>
                    </div>
                    {currentLevel < numberOfStageLevels && <div css={styles.waitingText}>waiting for pilot to select next level</div>}
                    {currentLevel >= numberOfStageLevels && <button css={styles.returnToTrainingButton} onClick={closeDogma}>Return to Training Hub</button>}
                </div>
            </div>}
        </div>
    );
};

const DogmaDash = ({id}) => {
    const {curriculumId} = useParams();
    const mrnaSlots = useSelector(state => getDogmaMrnaSlotIds(state, id));
    const ribosomes = useSelector(state => getDogmaRibosomeIds(state, id));
    const golgi = useSelector(state => getDogmaGolgiBodyIds(state, id));
    const microtubules = useSelector(state => getDogmaMicrotubuleIds(state, id));
    const lysosomes = useSelector(state => getDogmaLysosomeIds(state, id));
    const mitochondria = useSelector(state => getDogmaMitochondriaIds(state, id));
    const centrioles = useSelector(state => getDogmaCentrioles(state, id));
    const cellMembrane = useSelector(state => getDogmaCellMembraneIds(state, id));
    const cytoplasmId = useSelector(state => getDogmaDashCytoplasm(state, id));
    const canGoToGeneSelection = useSelector(state => isDogmaDashProteinUnlockingNeeded(state, id));
    // const canGoToGeneSelection = true;
    const antibodyModalOpen = useSelector(state => isModalOpenFor(state, DOGMA_HEALTH_WARNING));
    const antibody2ModalOpen = useSelector(state => isModalOpenFor(state, DOGMA_HEALTH_WARNING_2));
    const unlockingProtein = useSelector(state => getDogmaDashLevelUnlockingProtein(state, id));
    const availableProteins = useSelector(state => getDogmaDashAvailableProteins(state, id));
    const levelStart = useSelector(state => getDogmaDashLevelStartTime(state, id));
    const dashboardTutorialConfig = {location: DOGMA_DASH_DASHBOARD, dogmaDashId: id};
    const nucleusTutorialConfig = {location: DOGMA_DASH_NUCLEUS, dogmaDashId: id};
    const cargoTutorialConfig = {location: DOGMA_DASH_CARGO_METER_TUTORIAL, dogmaDashId: id};
    const dispatch = useDispatch();

    useEffect(() => {
       if(unlockingProtein !== Antibody || availableProteins.indexOf(Antibody) >= 0 || levelStart) {return;}
       dispatch(openModalFor(DOGMA_HEALTH_WARNING));
    },[unlockingProtein])

    const changeToGeneSelection = () => dispatch(setDogmaDashView(id, GENE_SELECTION_VIEW));

    return (
        <div css={styles.dogmaContainer}>
            {antibodyModalOpen && <FirstAntibodyModal />}
            {antibody2ModalOpen && <SecondAntibodyModal />}
            <h2 css={styles.title}>Mission Control</h2>
            <ScoreBoard dogmaId={id} />

            <div css={styles.gameContainer}>
                <AlertPanel dogmaId={id} />

                <div css={styles.cellContainer}>
                    <div css={styles.organellesContainer}>
                        <div css={styles.organelleContainer}>
                            <h3 css={styles.organelleTitle}>Ribosomes</h3>
                            <div>
                                {ribosomes.map(oId =>
                                <div key={oId} css={styles.ribosome}>
                                    <CookingOrganelle id={oId} dogmaDashId={id} />
                                    <div css={styles.topOrganelleDebris}>
                                        <OrganelleDebris dogmaId={id} organelleId={oId} curriculumId={curriculumId} maxShown={1} />
                                    </div>
                                </div>)}
                            </div>
                        </div>
                        <div css={styles.organelleContainer}>
                            <h3 css={styles.organelleTitle}>Golgi Body</h3>
                            <div css={styles.golgi}>
                                {golgi.map(oId => <div key={oId}>
                                    <CookingOrganelle id={oId} interactableProteins={[{type: Structural, form: ProteinForm_ProteinInVesicle}]} dogmaDashId={id} />
                                    <div css={styles.topOrganelleDebris}>
                                        <div css={styles.topOrganelleDebris}>
                                            <OrganelleDebris dogmaId={id} organelleId={oId} curriculumId={curriculumId} maxShown={1} />
                                        </div>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </div>

                    <div css={styles.cellBox}>
                        <div css={styles.cellBoxTopCut} />
                        <div css={styles.cellBoxBottomCut} />
                        <div css={styles.cytoplasm}>
                            <CytoplasmArea dogmaId={id}/>
                            <div styles={{position: "absolute", left: "calc(-100% + 4px)", top: 0}}>
                                <OrganelleDebris dogmaId={id} organelleId={cytoplasmId} curriculumId={curriculumId} maxShown={1} />
                            </div>
                        </div>
                        <div css={styles.microtubuleContainer}>
                            <div css={styles.labelAndCargoContainer}>
                                <h3 css={styles.organelleTitle}>Microtubules</h3>
                                <CargoCounter dogmaId={id} />
                            </div>
                            {microtubules.map((oId, i) =>
                            <div key={oId} css={microtubuleStyling[i%microtubuleStyling.length]}>
                                <Microtubule id={oId} dogmaId={id} top={i < 2} />
                            </div>)}
                        </div>
                        <div css={styles.microtubuleDebrisContainer}>
                            {microtubules.map(oId => <div key={`${oId}-debris`} css={styles.microtubuleDebris}>
                                <OrganelleDebris dogmaId={id} organelleId={oId} curriculumId={curriculumId} maxShown={1} />
                            </div>)}
                            {centrioles.map(oId => <div key={oId} css={styles.microtubuleDebris}>
                                <OrganelleDebris dogmaId={id} organelleId={oId} curriculumId={curriculumId} maxShown={1} />
                            </div>)}
                        </div>
                        <div css={styles.nucleusContainer}>
                            <h3 css={styles.organelleTitle}>Nucleus</h3>
                            <div css={styles.nucleus}>
                                {canGoToGeneSelection && <button css={styles.geneSelectionButton} onClick={changeToGeneSelection}/>}
                                {!canGoToGeneSelection && <div css={styles.dropSpotContainer}>
                                    <div>
                                        {mrnaSlots.map(oId => <MrnaSlot key={oId} id={oId} dogmaDashId={id}/>)}
                                    </div>
                                    <div css={styles.dropLabel}>RNA drop sites</div>
                                </div>}
                            </div>
                        </div>
                        {!!lysosomes[0] && <Lysosome id={lysosomes[0]} dogmaId={id} />}
                        {!!lysosomes[0] && <div css={styles.lysosomeDebris}><OrganelleDebris dogmaId={id} organelleId={lysosomes[0]} curriculumId={curriculumId} maxShown={1} /></div>}
                        <div css={styles.nucleusTutorial}><PopUpTutorial positionData={nucleusTutorialConfig} /></div>
                        <div css={styles.dashboardTutorialContainer}><PopUpTutorial positionData={dashboardTutorialConfig} /></div>
                        <div css={styles.cargoTutorial}><PopUpTutorial positionData={cargoTutorialConfig} /></div>
                        <div css={styles.lysosomeTutorial}><PopUpTutorial positionData={{dogmaDashId: id, organelleId: lysosomes[0]}} /></div>
                    </div>

                    <div css={styles.organellesContainer}>
                        <div css={styles.organelleContainer}>
                            <div css={styles.membraneContainer}>
                                {cellMembrane.length > 0 &&
                                <div css={styles.bottomOrganelleDebris}>
                                    <OrganelleDebris dogmaId={id} organelleId={cellMembrane[0]} curriculumId={curriculumId} maxShown={1}/>
                                </div>}
                            </div>
                            <h3 css={styles.organelleTitleBottom}>Cell Membrane</h3>
                        </div>
                        <ViralLoadMeter dogmaId={id} />
                        <div css={styles.organelleContainer}>
                            <div>
                                {mitochondria.map(oId => <div key={oId} css={styles.mitochondria}>
                                    <div css={styles.bottomOrganelleDebris}>
                                        <OrganelleDebris dogmaId={id} organelleId={oId} curriculumId={curriculumId} maxShown={1} />
                                    </div>
                                </div>)}
                            </div>
                            <h3 css={styles.organelleTitleBottom}>Mitochondria</h3>
                        </div>
                    </div>
                </div>

                <MrnaInventory dogmaId={id} />
            </div>
        </div>
    );
}

export default DogmaDash;
