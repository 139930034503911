import React from 'react';

export const EDITOR_COMPONENT_LINK = "LINK";

export default ({attributes, children, element}) => {
    const {href} = element;
    return (
        <a {...attributes} href={href} target="_blank" className="rich-editor-components--hyperlink">
            {children}
        </a>
    )
};
