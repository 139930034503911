import React from 'react';
import Cohort from './Cohort';
import CohortNavigation from './Cohort/CohortNavigation';

export default React.memo(props => {
    return (
        <div className="cohorts-container">
            <h2 className="cohorts-container--title">Cohorts</h2>
            <div className="cohort-details">
                <CohortNavigation selectedCohort={props.cohortId} />
                {props.cohortId && <Cohort cohortId={props.cohortId}/>}
            </div>
        </div>
    );
});
