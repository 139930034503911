import React from 'react';
import {shallowEqual, useSelector} from "react-redux";
import {getLongTextInputText} from "../../../store/interactions/selectors";

const stateMap = props => state => ({
    text: getLongTextInputText(state, props.interactableId),
});

export default ({attributes, children, element}) => {
    const { text } = useSelector(stateMap(element), shallowEqual);

    return (
        <span className="learner-rich-text--long-text-reference" {...attributes}>
            {text || "No input value" }
            {children}
        </span>
    );
}
