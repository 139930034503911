import {SET_AMINO_ACID_IN_MRNA_SLOT} from "./actions";
import {INTERACTIONS_LOADED} from "../actions";
import {MRNA_ACTIVITY_INTERACTION} from "../../../constants/TaskTypes";

const defaultActivity = {
    slots: [null, null, null, null, null],
};

export default (state = {}, action) => {
    if(action.type === INTERACTIONS_LOADED) {
        const loadedInteractions = action.interactions.filter(i => i.__typename === MRNA_ACTIVITY_INTERACTION).reduce((agg, i) => {
            const newState = {...defaultActivity};
            return {
                ...agg,
                [i.interactable.id]: {
                    ...newState,
                    slots: newState.slots.map((v, index) => {
                        const loadedAminoPlacement = i.attachedAminoAcids.find(a => a.slot === index);
                        return loadedAminoPlacement ? loadedAminoPlacement.object.objectType : v;
                    }),
                },
            };
        }, {});
        return {
            ...state,
            ...loadedInteractions,
        };
    }
    if(action.type === SET_AMINO_ACID_IN_MRNA_SLOT) {
        // const current = action[action.activityId] ?? {...defaultActivity};
        const current = state[action.activityId] || {...defaultActivity};
        return {
            ...state,
            [action.activityId]: {
                ...current,
                slots: current.slots.map((s, i) => i === action.index ? action.aminoAcid : s),
            },
        };
    }
    return state;
}
