import "./draggable-bank.scss";
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import RichEditor from "../../../../RichText/RichEditor";
import {addDraggableToBank, setDraggableBankPrompt} from "../../../../store/curriculumTaskItems/draggableBank/actions";
import uuid from 'uuid/v4';
import RichDraggable from "./Draggables/RichDraggable";
import {
    getDraggableBankDraggables,
    getDraggableBankPrompt
} from "../../../../store/curriculumTaskItems/draggableBank/selectors";

export default props => {
    const {curriculum, id} = props;
    const prompt = useSelector(state => getDraggableBankPrompt(state, id));
    const draggableIds = useSelector(state => getDraggableBankDraggables(state, id));
    const dispatch = useDispatch();

    const setPrompt = newPrompt => dispatch(setDraggableBankPrompt(curriculum, id, newPrompt));
    const addDraggable = () => dispatch(addDraggableToBank(curriculum, id, uuid()));

    return (
        <div className="admin-task--draggable-bank">
            <h2 className="draggable-bank--title">Draggable Bank Definition</h2>
            <div className="draggable-bank--prompt-prompt">Prompt:</div>
            <div className="draggable-bank--prompt-container">
                <RichEditor content={prompt} setContent={setPrompt} id={id} />
            </div>
            <div className="draggable-bank--draggables-prompt">Draggables:</div>
            <div className="draggable-bank--draggable-container">
                {draggableIds.map(d => <RichDraggable key={d} curriculum={curriculum} bank={id} id={d} />)}
                <button className="draggable-bank--add-draggable" onClick={addDraggable}>+ draggable</button>
            </div>
        </div>
    );
}
