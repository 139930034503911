import {omit} from "lodash";
import {
    PEA_PLANT_CARD,
    PODULATOR_RECEIPT,
    POLAROID,
    PUNNETT_SQUARE,
    STICKY_NOTE
} from "../constants/WorkBoardItemTypes";
import {isWorkBoardLoadingItems} from "../store/dataAccess/selectors";
import {startLoadingWorkBoardItems} from "../store/dataAccess/actions";
import {
    deleteWorkBoardQuery,
    deleteWorkBoardVariables,
    getWorkBoardItemsQuery,
    getWorkBoardItemsVariables
} from "./queries/workBoard";
import {importPolaroids} from "../store/polaroids/actions";
import {peaPlantCardsLoaded} from "../store/peaPlantCards/actions";
import {workBoardItemsLoaded} from "../store/workBoards/actions";
import {stickyNotesLoaded} from "../store/stickyNotes/actions";
import {podulatorReceiptsLoaded} from "../store/podulatorReceipts/actions";
import {punnettSquaresLoaded} from "../store/punnettSquares/actions";
import {peaPlantsLoaded} from "../store/peaPlants/actions";

export const loadItemsForWorkBoard = (dataService, workBoardId) => {
    const state = dataService.store.getState();
    if(isWorkBoardLoadingItems(state, workBoardId)) { return; }
    dataService.store.dispatch(startLoadingWorkBoardItems(workBoardId));
    const variables = getWorkBoardItemsVariables(workBoardId);
    return dataService.sendGraphQL(getWorkBoardItemsQuery, variables).then(result => {
        const parsed = parseWorkBoard(result.workBoard, dataService.store.dispatch);
        dataService.store.dispatch(workBoardItemsLoaded(workBoardId, parsed.workBoard.items));
    });
};

export const parseWorkBoard = (workBoardData, dispatch) => {
    const data = {
        [POLAROID]: [],
        [PEA_PLANT_CARD]: [],
        [STICKY_NOTE]: [],
        [PODULATOR_RECEIPT]: [],
        [PUNNETT_SQUARE]: [],
        peaPlants: [],
        workBoard: workBoardData,
    };
    workBoardData.items.forEach(ip => {
        if(!data[ip.item.__typename]) { return; }
        data[ip.item.__typename].push(omit(ip.item, ["__typename"]));
    });
    data[PEA_PLANT_CARD].forEach(card => {
        data.peaPlants.push(card.peaPlant);
        card.peaPlant = card.peaPlant.id;
    });
    data[PUNNETT_SQUARE].forEach(square => {
        if(square.leftPlant) {
            data.peaPlants.push(square.leftPlant);
            square.leftPlant = square.leftPlant.id;
        }
        if(square.topPlant) {
            data.peaPlants.push(square.topPlant);
            square.topPlant = square.topPlant.id;
        }
    });
    data[PODULATOR_RECEIPT].forEach(receipt => {
        data.peaPlants.push(receipt.topParent);
        receipt.topParent = receipt.topParent.id;
        data.peaPlants.push(receipt.leftParent);
        receipt.leftParent = receipt.leftParent.id;
    });

    if(dispatch) {
        dispatch(importPolaroids(data[POLAROID]));
        dispatch(peaPlantCardsLoaded(data[PEA_PLANT_CARD]));
        dispatch(stickyNotesLoaded(data[STICKY_NOTE]));
        dispatch(podulatorReceiptsLoaded(data[PODULATOR_RECEIPT]));
        dispatch(punnettSquaresLoaded(data[PUNNETT_SQUARE]));
        dispatch(peaPlantsLoaded(data.peaPlants));
    }

    return data;
};

export const deleteWorkBoard = (dataService, workBoardId, learnerId) => {
    const variables = deleteWorkBoardVariables(workBoardId, learnerId);
    return dataService.sendGraphQL(deleteWorkBoardQuery, variables);
};
