import {DOGMA_DASH_ALERT_LIST, DOGMA_DASH_MRNA_INVENTORY} from "./dogma-strand-1";
import {
    getCurrentDogmaDashLevel,
    getDogmaCellMembraneIds,
    getDogmaDashAvailableProteins,
    getDogmaDashLevelUnlockingProtein,
    getDogmaGolgiBodyIds,
    getDogmaMicrotubuleIds,
    getDogmaRibosomeIds,
    getOrganelleTimerStart,
    getOrganelleTimerType
} from "../../interactions/dogmaDash/selectors";
import {mrnaInSlot, proteinTypeAtOrganelle} from "./utility";
import {Motor, Transport} from "../../../constants/DogmaDash";
import {MRNA_INVENTORY_PROTEIN_TUTORIAL} from "./dogma-strand-antibody-unlock";

export default {
    id: "04dfd5c4-a1d8-4723-80e2-baaca600b374",
    items: [
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== DOGMA_DASH_ALERT_LIST) {return false;}
                const membraneAlert = getDogmaCellMembraneIds(state, dogmaDashId).some(o => getOrganelleTimerStart(state, o) && getOrganelleTimerType(state, o) === "Alert");
                return membraneAlert && getDogmaDashAvailableProteins(state, dogmaDashId).length === 4 && !getDogmaDashLevelUnlockingProtein(state, dogmaDashId) && getCurrentDogmaDashLevel(state, dogmaDashId) === 5;
            },
            header: "New Alert Type",
            text: "The cell membrane needs a protein that lets <b>materials pass in and out of the cell</b>.",
            forceCompletion: (state, {dogmaDashId}) => {
                return mrnaInSlot(state, dogmaDashId, Transport);
            },
        },
        {
            condition: (state, {location, protein}) => location === MRNA_INVENTORY_PROTEIN_TUTORIAL && protein === Transport,
            header: "Send mRNA",
            text: "The <b>channel protein</b> is needed to get membrane passages flowing again.",
            callToAction: "Send the pilot a channel protein",
            completionCondition: (state, {dogmaDashId}) => {
                return mrnaInSlot(state, dogmaDashId, Transport);
            },
            width: 460,
        },
        {
            condition: (state, {location, dogmaDashId, protein}) => {
                if(location !== MRNA_INVENTORY_PROTEIN_TUTORIAL || protein !== Motor) { return false; }
                return getDogmaRibosomeIds(state, dogmaDashId).some(o => proteinTypeAtOrganelle(state, o, Transport));
            },
            header: "Delivery of Channel Proteins",
            text: "Channel proteins are lodged into the cell membrane. The pilot needs a delivery protein to carry the channel all the way out.",
            callToAction: "Send the Pilot a Delivery Protein.",
            completionCondition: (state, {dogmaDashId}) => {
                return mrnaInSlot(state, dogmaDashId, Motor)
                || getDogmaRibosomeIds(state, dogmaDashId).some(r => proteinTypeAtOrganelle(state, r, Motor))
                || getDogmaGolgiBodyIds(state, dogmaDashId).some(g => proteinTypeAtOrganelle(state, g, Motor))
                || getDogmaMicrotubuleIds(state, dogmaDashId).some(m => proteinTypeAtOrganelle(state, m, Motor));
            },
            width: 460,
        },
    ],
};
