import {css, keyframes} from "@emotion/core";
import {
    absoluteCenter,
    bbPadding,
    FONT_BOLD_WEIGHT,
    inlineBlockBottom,
    inlineBlockTop,
    modalBlocker
} from "../../../../../style-variables";
import {backgroundColor, dogmashBlue, dogmashOrange} from "../dogma-dash-styles";
import {transparentize} from "color2k";
import {backButton as geneSelectionBackButton} from "./gene-selection-styles";

export const container = css(bbPadding("40px 60px 0 60px"), {
    width: "100%",
    height: "100%",
    background: backgroundColor,
});

export const pageSection = css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "0 0 30px 0",
    position: 'relative',
    alignItems: 'center',
});
export const pageHeaderSection = css(pageSection, {
    justifyContent: "flex-start",
});

export const backButton = geneSelectionBackButton;

export const header = css({
    flex: '0 0 auto',
    margin: 0,
    padding: 0,
    color: "#fff",
    fontFamily: "Orbitron, sans-serif",
    fontWeight: 400,
    fontSize: "3.0625rem",

    "& > svg": {
        width: "51px",
        height: "51px",
        fill: "#fff",
        display: "inline-block",
        verticalAlign: "middle",
    },
});
export const headerBlue = css({
    color: dogmashBlue,
});

export const neutralTextTransform = css({
    textTransform: "none",
});
export const bolded = css({
    fontWeight: 700,
});

const showKeyFrames = keyframes({
    "0%": { opacity: "0" },
    "100%": { transform: "1" },
});
export const translationButton = css(bbPadding("16px 26px"), {
    flex: '0 0 auto',
    marginLeft: "auto",
    color: dogmashBlue,
    border: `1px solid ${dogmashBlue}`,
    backgroundColor: backgroundColor,
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 600,
    fontSize: "1.25rem",
    transition: "color 0.2s, background-color 0.2s",
    cursor: "pointer",
    animation: `${showKeyFrames} 0.3s forwards`,

    "&:not(:disabled):hover": {
        color: backgroundColor,
        backgroundColor: dogmashBlue,
    },
});

export const headerTutorial = css({
    width: 0,
    height: 0,
    position: 'absolute',
    left: "25px",
    top: "7px",
    zIndex: 20,
});

export const buttonTutorial = css({
    width: 0,
    height: 0,
    position: 'absolute',
    right: "200px",
    top: "calc(100% + 2px)",
    zIndex: 20,
});

export const staticSectionBlock = css({
    flex: "0 0 auto",
    border: `3px solid ${dogmashOrange}`,
    position: "relative",

    "&:after": {
        content: '""',
        display: 'block',
        width: "26px",
        height: "26px",
        position: "absolute",
        left: "-3px",
        bottom: "-3px",
        background: `linear-gradient(45deg, ${backgroundColor} 48%, ${dogmashOrange} 48%, ${dogmashOrange} 54%, transparent 54%)`,
    },
});

export const staticBlockHeader = css(bbPadding("5px 20px 8px 20px"), {
    width: "100%",
    margin: 0,
    background: dogmashOrange,
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "1.3em",
    color: backgroundColor,
    position: "relative",
    whiteSpace: "nowrap",

    "& > svg": {
        width: "36px",
        height: "36px",
        fill: dogmashOrange,
        stroke: dogmashOrange,
        margin: "0 0 0 13px",
        verticalAlign: "middle",
    },

    "&:after": {
        content: '""',
        display: 'block',
        position: "absolute",
        right: 0,
        top: "100%",
        borderTop: `28px solid ${dogmashOrange}`,
        borderLeft: "32px solid transparent",
    },
});

export const transcriptionBlock = css(staticSectionBlock, {
    flex: "1 0 auto",
    margin: "0 12px 0 0",
});

export const strandContent = bbPadding("6px 29px");

export const transcriptionNucleotideBlock = css({
    minHeight: "169px",
    display: "inline-flex",
    verticalAlign: "bottom",
    flexDirection: "column",
    justifyContent: "flex-end",
    marginRight: "10px",
    color: dogmashOrange,
    fontFamily: "Rajdhani, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 500,
    textTransform: "uppercase",
    textAlign: "right",
    position: 'relative',

    "&:first-of-type": {
        width: '170px',
        justifyContent: "space-between",
        padding: "24px 0",
        boxSizing: "border-box",
        marginRight: "20px",
        lineHeight: "1em",
    },

    "&:nth-of-type(3n+1)": {
        marginRight: "45px",
    },
});

export const strandTutorialPosition = css({
    width: "0",
    height: '0',
    position: "absolute",
    left: "100%",
    top: "33%",
    zIndex: 20,
});

export const proteinImageBlock = css(staticSectionBlock, {
    display: "flex",
    flexDirection: "column",
    width: "274px",
    height: "234px",
    boxSizing: "border-box",
});
export const proteinImageContainer = css({
    flex: "0 1 auto",
    overflow: 'hidden',
});

export const decoderSectionContainer = css(staticSectionBlock, {
    flex: '0 0 auto',
    display: "flex",
    flexDirection: "column",
});
export const decoderContent = css(bbPadding("8px 60px 8px 20px"), {
    flex: "1 0 auto",
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
});
export const decoderPair = css({
    flex: '0 0 auto',
    display: "inline-flex",
    verticalAlign: "bottom",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: "20px",

    "&:first-of-type": {
        marginRight: "40px",
        justifyContent: "space-between",
        padding: "57px 0",
        boxSizing: "border-box",
    },
});
export const strandHeader = css({
    color: dogmashOrange,
    fontFamily: "Rajdhani, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 700,
});

const interactableSectionBlockContent = css({
    border: `8px solid ${dogmashBlue}`,
    position: "relative",

    "&:before": {
        content: '""',
        display: 'block',
        width: "26px",
        height: "26px",
        position: "absolute",
        right: "-8px",
        bottom: "-8px",
        background: `linear-gradient(-45deg, ${backgroundColor} 47%, ${dogmashBlue} 47%, ${dogmashBlue} 64%, ${backgroundColor} 64%)`,
    },
});

export const interactableBlockHeader = css(bbPadding("8px 30px 0 18px"), {
    width: "100%",
    margin: 0,
    background: dogmashBlue,
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "1.3em",
    color: backgroundColor,
    position: "relative",

    "&:after": {
        content: '""',
        display: 'block',
        position: "absolute",
        left: "8px",
        top: "100%",
        borderTop: `28px solid ${dogmashBlue}`,
        borderRight: "32px solid transparent",
    },
});

export const inventoryBlock = css({
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "column",
});
export const disabledInventory = css(inventoryBlock, {
    opacity: 0.2,
});
export const inventorySectionContainer = css(interactableSectionBlockContent, bbPadding("15px 45px"), {
    flex: "1 0 auto",
});
export const inventoryItem = css(inlineBlockTop, {
    width: "90px",
    height: "90px",
    boxShadow: `inset 0 0 33px 1px ${transparentize(dogmashBlue, 0.75)}`,
    fontSize: "0.625em",
    position: "relative",
    borderRadius: "50%",
    margin: "0 10px 10px 0",
});
export const inventoryItemInnerContainer = css({
    position: "absolute",
    top: "calc(50% - 5px)",
    left: "50%",
    transform: "translate(-50%, -50%)",
});
export const inventoryNucleotideAGContainer = css({
    width: "16px",
    height: "37px",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.5)",
});
export const inventoryNucleotideUCContainer = css({
    width: "16px",
    height: "19px",
    boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.5)",
});

export const portalBlock = css({
    "flex": "0 0 auto",
});
export const vrPortalSection = css(interactableSectionBlockContent, bbPadding("25px 93px 38px 93px"), {
});
export const portal = css({
    width: "164px",
    height: "164px",
    border: `10px solid ${dogmashBlue}`,
    borderRadius: "50%",
    boxSizing: "border-box",
    position: "relative",
});
export const clear = css({
    width: "27px",
    height: "22px",
    position: "absolute",
    right: "24px",
    top: "24px",
    transform: "translateY(-50%)",
    backgroundColor: "#3f3f3f",
    border: "none",
    borderRadius: "50%",
    color: "#d6d6d6",
    fontSize: "1em",
    fontWeight: FONT_BOLD_WEIGHT,
    cursor: "pointer",
    transition: "transform 0.2s, drop-shadow 0.2s",

    "&:disabled": {
        cursor: "default",
    },

    "&:not(:disabled):active": {
        transform: "translateY(calc(-50% + 3px))",
        boxShadow: "none",
    }
});

export const firstTimeModalBlocker = modalBlocker;

export const firstTimeContainer = css(absoluteCenter, bbPadding("15px 47px"), {
    border: `5px solid ${dogmashBlue}`,
    borderRadius: "6px",
    backgroundColor: "#082322",
    textAlign: "center",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

export const firstTimeHeader = css({
    color: dogmashBlue,
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 600,
    fontSize: "2rem",
    textTransform: "uppercase",
    lineHeight: "1em",
    margin: "0 0 8px 0",
    padding: 0,
});

export const firstTimeImageContainer = css({
    flex: '0 0 auto',
    width: "360px",
    height: '360px',
    border: `2px solid ${dogmashBlue}`,
    position: "relative",
    margin: "0 0 8px 0",

    "& > img": {
        width: "100%",
        height: "100%",
    },

    "&:before, &:after": {
        content: '""',
        display: "block",
        width: "10%",
        height: "10%",
        position: 'absolute',
        zIndex: 1,
    },
    "&:before": {
        left: '-2px',
        top: '-2px',
        background: `linear-gradient(-45deg, transparent 56%, ${dogmashBlue} 56%, ${dogmashBlue} 60%, #082322 60%)`
    },
    "&:after": {
        right: '-2px',
        bottom: '-2px',
        background: `linear-gradient(-45deg, #082322 45%, ${dogmashBlue} 45%, ${dogmashBlue} 49%, transparent 49%)`
    }
});

export const firstTimeText = css({
    color: dogmashBlue,
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 500,
    fontSize: "0.9375rem",
    margin: "0 0 8px 0",
});

export const firstTimeButton = css(bbPadding("16px 26px"), {
    border: `2px solid ${dogmashBlue}`,
    background: '#082322',
    color: dogmashBlue,
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 600,
    fontSize: "1.25rem",
    transition: "background-color 0.2s, color 0.2s",
    cursor: "pointer",

    "&:hover": {
        color: `#082322`,
        background: dogmashBlue,
    },
});
