// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/trash.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".admin-task--multiple-choice-input{width:100%;border:1px solid #6f6f6f;margin:0 0 5px 0;box-sizing:border-box;padding:10px;overflow-y:auto}.multiple-choice-input--prompt-prompt{font-size:.875em}.multiple-choice-input--prompt-container{width:100%;height:90px;margin:0 0 4px 0}.multiple-choice-input--multiselect-label{font-size:.875em;display:inline-block;vertical-align:middle}.multiple-choice-input--multiselect-input{display:inline-block;vertical-align:middle;margin:0 0 0 4px}.multiple-choice-input--options-container{width:100%;margin:4px 0 0 0}.multiple-choice-input--options-prompt-container{margin:0 0 5px 0}.multiple-choice-input--options-prompt{width:285px;display:inline-block;vertical-align:top;font-size:.875em;font-weight:400;padding:0;margin:0 0 0 25px}.multiple-choice-input--correct-prompt{display:inline-block;vertical-align:top;font-size:.875em;font-weight:400;padding:0;margin:0}.multiple-choice-input--option{margin:0 0 4px 0}.multiple-choice-input--remove-option{width:15px;height:15px;display:inline-block;vertical-align:top;margin:3px 6px 0 0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;border:none;cursor:pointer}.multiple-choice-input--option-text{width:300px;display:inline-block;vertical-align:top;box-sizing:border-box;padding:2px 3px;font-size:.875em;border:none;border-bottom:1px solid #6f6f6f;transition:border-color .2s;resize:none}.multiple-choice-input--option-text:not(:last-child){margin-bottom:3px}.multiple-choice-input--option-text:hover{border-color:#46b084}.multiple-choice-input--option-text:focus{border-color:#329044}.multiple-choice-input--option-correct{display:inline-block;vertical-align:top;margin:3px 10px 0 5px}.multiple-choice-input--add-option{font-size:.875em;margin:5px 0}", ""]);
// Exports
module.exports = exports;
