import {CURRICULA_LOADED} from "../../curricula/actions";
import {DOGMA_DASH} from "../../../constants/TaskTypes";
import {omit} from "lodash";
import {ADD_INTERACTABLE_TO_TASK, REMOVE_INTERACTABLE_FROM_TASK} from "../actions";
import {
    SET_DOGMA_DASH_MAX_CELL_MEMBRANES, SET_DOGMA_DASH_MAX_GOLGI_BODIES,
    SET_DOGMA_DASH_MAX_LYSOSOMES, SET_DOGMA_DASH_MAX_MICROTUBULES,
    SET_DOGMA_DASH_MAX_MITOCHONDRIA,
    SET_DOGMA_DASH_MAX_MRNA, SET_DOGMA_DASH_MAX_RIBOSOMES
} from "./actions";

const defaultState = {
    maxMrnaSlots: 0,
    maxMitochondria: 0,
    maxCellMembrane: 0,
    maxLysosome: 0,
    maxGolgiBody: 0,
    maxRibosome: 0,
    maxMicrotubules: 0,
};

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const newInputs = action.curricula.reduce((agg, c) => ({
            ...agg,
            ...(c.tasks || []).reduce((agg, t) => ({
                ...agg,
                ...t.items.filter(i => i.__typename === DOGMA_DASH).reduce((agg, i) => ({
                    ...agg,
                    [i.id]: {
                        ...defaultState,
                        ...omit(i, '__typename'),
                    },
                }), agg),
            }), agg),
        }), {});
        return {
            ...state,
            ...newInputs,
        };
    }
    if(action.type === ADD_INTERACTABLE_TO_TASK && action.interactableType === DOGMA_DASH) {
        return {
            ...state,
            [action.interactableId]: {
                ...defaultState,
            },
        };
    }
    if(action.type === REMOVE_INTERACTABLE_FROM_TASK && state[action.interactableId]) {
        return omit(state, action.interactableId);
    }
    if(action.type === SET_DOGMA_DASH_MAX_MRNA) {
        const {dogmaDashId, number} = action;
        return {
            ...state,
            [dogmaDashId]: {
                ...state[dogmaDashId],
                maxMrnaSlots: number,
            },
        };
    }
    if(action.type === SET_DOGMA_DASH_MAX_MITOCHONDRIA) {
        const {dogmaDashId, number} = action;
        return {
            ...state,
            [dogmaDashId]: {
                ...state[dogmaDashId],
                maxMitochondria: number,
            },
        };
    }
    if(action.type === SET_DOGMA_DASH_MAX_CELL_MEMBRANES) {
        const {dogmaDashId, number} = action;
        return {
            ...state,
            [dogmaDashId]: {
                ...state[dogmaDashId],
                maxCellMembrane: number,
            },
        };
    }
    if(action.type === SET_DOGMA_DASH_MAX_LYSOSOMES) {
        const {dogmaDashId, number} = action;
        return {
            ...state,
            [dogmaDashId]: {
                ...state[dogmaDashId],
                maxLysosome: number,
            },
        };
    }
    if(action.type === SET_DOGMA_DASH_MAX_GOLGI_BODIES) {
        const {dogmaDashId, number} = action;
        return {
            ...state,
            [dogmaDashId]: {
                ...state[dogmaDashId],
                maxGolgiBody: number,
            },
        };
    }
    if(action.type === SET_DOGMA_DASH_MAX_RIBOSOMES) {
        const {dogmaDashId, number} = action;
        return {
            ...state,
            [dogmaDashId]: {
                ...state[dogmaDashId],
                maxRibosome: number,
            },
        };
    }
    if(action.type === SET_DOGMA_DASH_MAX_MICROTUBULES) {
        const {dogmaDashId, number} = action;
        return {
            ...state,
            [dogmaDashId]: {
                ...state[dogmaDashId],
                maxMicrotubules: number,
            },
        };
    }
    return state;
};
