export const ADD_DRAGGABLE_TO_BANK = "Add draggable to bank";
export const addDraggableToBank = (curriculumId, draggableBankId, draggableId) => ({
    type: ADD_DRAGGABLE_TO_BANK,
    curriculumId,
    draggableBankId,
    draggableId,
});

export const SET_DRAGGABLE_BANK_PROMPT = "Set draggable bank prompt";
export const setDraggableBankPrompt = (curriculumId, interactableId, prompt) => ({
    type: SET_DRAGGABLE_BANK_PROMPT,
    curriculumId,
    interactableId,
    prompt,
});
