import uuid from 'uuid/v4';

export const CURRICULA_LOADED = "Master curricula loaded";
export const curriculaLoaded = (curricula, setCurrent = false) => ({
    type: CURRICULA_LOADED,
    curricula,
    setCurrent,
});

export const CREATE_MASTER_CURRICULUM = "Create master curricula";
export const createMasterCurriculum = (id = uuid(), name = "") => ({
    type: CREATE_MASTER_CURRICULUM,
    id,
    name,
});

export const SET_CURRICULUM_NAME = "Set curricula name";
export const setCurriculumName = (curriculumId, name) => ({
    type: SET_CURRICULUM_NAME,
    curriculumId,
    name,
});

export const REMOVE_TASK_FROM_CURRICULUM = "Remove task from curricula";
export const removeTaskFromCurriculum = (curriculumId, taskId) => ({
    type: REMOVE_TASK_FROM_CURRICULUM,
    curriculumId,
    taskId,
});

export const SET_CURRICULUM_TYPE = "Set curriculum type";
export const setCurriculumType = (curriculumId, curriculumType) => ({
    type: SET_CURRICULUM_TYPE,
    curriculumId,
    curriculumType,
});
