import {css} from "@emotion/core";
import {bbPadding} from "../../../style-variables";
import cardBackground from '../Assets/PeaPlantCard/background.png';
import shortAxial from "../Assets/PeaPlantCard/stem 4 - short axial.png";
import tallTerminal from "../Assets/PeaPlantCard/stem 1 - tall terminal.png";
import shortTerminal from "../Assets/PeaPlantCard/stem 3 - short terminal.png";
import tallAxial from "../Assets/PeaPlantCard/stem 2- tall axial.png";
import greenInflated from "../Assets/PeaPlantCard/pea pod 1 - green full.png";
import yellowInflated from "../Assets/PeaPlantCard/pea pod 3 - yellow full.png";
import greenConstricted from "../Assets/PeaPlantCard/pea pod 2 - green constricted.png";
import yellowConstricted from "../Assets/PeaPlantCard/pea pod 4 - yellow constricted.png";
import inflatedPodYellowRound from "../Assets/PeaPlantCard/peas 3 - round yellow full.png";
import inflatedPodGreenRound from "../Assets/PeaPlantCard/peas 1 - round green full.png";
import inflatedPodYellowWrinkled from "../Assets/PeaPlantCard/peas 7 - wrinkled yellow full.png";
import inflatedPodGreenWrinkled from "../Assets/PeaPlantCard/peas 5 - wrinkled green full.png";
import constrictedPodYellowRound from "../Assets/PeaPlantCard/peas 4 - round yellow constricted.png";
import constrictedPodGreenRound from "../Assets/PeaPlantCard/peas 2 - round green constricted.png";
import constrictedPodYellowWrinkled from "../Assets/PeaPlantCard/peas 8 - wrinkled yellow constricted.png";
import constrictedPodGreenWrinkled from "../Assets/PeaPlantCard/peas 6 - wrinkled green constricted.png";
import purpleFlower from "../Assets/PeaPlantCard/flower 2 - purple.png";
import whiteFlower from "../Assets/PeaPlantCard/flower 1 - white.png";

export const container = css({
    width: "100%",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    textAlign: "left",
    backgroundImage: `url(${cardBackground})`,
    backgroundSize: "100%",
});

export const labelContainer = css({
    display: "inline-block",
}, bbPadding(3));

export const parentLabel = css({
    fontSize: "0.75em",
    textAlign: "center",
});

export const label = css({
    fontSize: "1.375em",
    lineHeight: "1em",
    width: "100%",
    textAlign: "center",
});

export const trait = css({
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundSize: "100%",
    zIndex: 0,
});

export const plantDisplay = css({
    "& .pea-plant-card--plant": {
        backgroundImage: `url(${tallAxial})`,
    },

    "& .pea-plant-card--flower, & .pea-plant-card--pod": {
        top: `${(59 / 191) * 100}%`,
    },
});
export const plantDisplayContainer = css(container, plantDisplay);

export const plantDisplayShort = css({
    "& .pea-plant-card--plant": {
        backgroundImage: `url(${shortAxial})`,
    },

    "& .pea-plant-card--flower, & .pea-plant-card--pod": {
        top: `${(87 / 191) * 100}%`,
        left: `${(9 / 147) * 100}%`,
    },
});

export const plantDisplayTerminal = css({
    "& .pea-plant-card--plant": {
        backgroundImage: `url(${tallTerminal})`,
    },

    "& .pea-plant-card--flower, & .pea-plant-card--pod": {
        top: 0,
    },
});

export const plantDisplayShortTerminal = css({
    "& .pea-plant-card--plant": {
        backgroundImage: `url(${shortTerminal})`,
    },

    "& .pea-plant-card--flower, & .pea-plant-card--pod": {
        top: `${(47 / 191) * 100}%`,
        left: `${(-1 / 147) * 100}%`,
    },
});

export const podDisplay = css({
    zIndex: 2,
    backgroundImage: `url(${greenInflated})`,

    "& .pea-plant-card--seeds": {
        backgroundImage: `url(${inflatedPodYellowRound})`,

        "&.pea-plant-card--seeds__color-recessive": {
            backgroundImage: `url(${inflatedPodGreenRound})`,
        },
        "&.pea-plant-card--seeds__shape-recessive": {
            backgroundImage: `url(${inflatedPodYellowWrinkled})`,
        },
        "&.pea-plant-card--seeds__color-recessive.pea-plant-card--seeds__shape-recessive": {
            backgroundImage: `url(${inflatedPodGreenWrinkled})`,
        },
    },

    "&.pea-plant-card--pod__color-recessive": {
        backgroundImage: `url(${yellowInflated})`,
    },

    "&.pea-plant-card--pod__shape-recessive": {
        backgroundImage: `url(${greenConstricted})`,

        "& .pea-plant-card--seeds": {
            backgroundImage: `url(${constrictedPodYellowRound})`,

            "&.pea-plant-card--seeds__color-recessive": {
                backgroundImage: `url(${constrictedPodGreenRound})`,
            },
            "&.pea-plant-card--seeds__shape-recessive": {
                backgroundImage: `url(${constrictedPodYellowWrinkled})`,
            },
            "&.pea-plant-card--seeds__color-recessive.pea-plant-card--seeds__shape-recessive": {
                backgroundImage: `url(${constrictedPodGreenWrinkled})`,
            },
        },
    },

    "&.pea-plant-card--pod__color-recessive.pea-plant-card--pod__shape-recessive": {
        backgroundImage: `url(${yellowConstricted})`,
    },
});
export const podDisplayTrait = css(trait, podDisplay);

export const flowerDisplay = css({
    zIndex: 3,
    backgroundImage: `url(${purpleFlower})`,

    "&.pea-plant-card--flower__color-recessive": {
        backgroundImage: `url(${whiteFlower})`,
    },
});
export const flowerDisplayTrait = css(trait, flowerDisplay);
