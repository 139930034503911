import {css} from "@emotion/core";
import {bbPadding, growKeyframes} from "../../../../../style-variables";

export const menu = css({
    width: "200px",
    backgroundColor: "#333",
    position: "absolute",
    animation: `${growKeyframes} 0.2s forwards`,
    overflow: "hidden",
    zIndex: 1000,
    transformOrigin: "0 0",
    boxShadow: "2px 2px 3px 0 rgba(0,0,0,0.5)",
}, bbPadding(1));

export const button = css({
    display: "block",
    width: "100%",
    color: "white",
    background: "transparent",
    border: "none",
    textAlign: "left",
    cursor: "pointer",
    transition: "background-color 0.2s",

    "&:not(:last-of-type)": {
        margin: "0 0 1px 0",
    },

    "&:hover": {
        backgroundColor: "rgba(255,255,255,0.25)",
    },
}, bbPadding("4px 6px"));

export const separator = css({
    width: "100%",
    height: "1px",
    backgroundColor: "#555",
    margin: "3px 0",
});
