import * as styles from './global-task-styles';
import React from 'react';
import {useSelector} from "react-redux";
import {getCurriculumTaskItemIds} from "../../store/curriculumTask/selectors";
import {getCurriculumTaskItemType} from "../../store/curriculumTaskItems/selectors";
import {DOGMA_DASH, INVENTORY_TASK} from "../../constants/TaskTypes";
import LearnerInventory from "./Interactables/Inventory";
import DogmaDash from "./Interactables/DogmaDash";
import {getOpenGlobalInteractive} from "../../store/navigation/selectors";

const getInventoryId = (state, taskId) => getCurriculumTaskItemIds(state, taskId).find(i => getCurriculumTaskItemType(state, i) === INVENTORY_TASK);
const getDogmaDashId = (state, taskId) => getCurriculumTaskItemIds(state, taskId).find(i => getCurriculumTaskItemType(state, i) === DOGMA_DASH);

const GlobalTaskLearner = ({taskId}) => {
    const inventoryId = useSelector(state => getInventoryId(state, taskId));
    const dogmaDashId = useSelector(state => getDogmaDashId(state, taskId));
    const openInteractive = useSelector(state => getOpenGlobalInteractive(state));

    return (
        <>
        {/*<div css={styles.container}>*/}
            {!!dogmaDashId && openInteractive === dogmaDashId && <DogmaDash id={dogmaDashId} />}
            {/*{inventoryId && <LearnerInventory id={inventoryId} />}*/}
        {/*</div>*/}
        </>
    )
};

export default GlobalTaskLearner;
