export default {
    widget: {
        explainer: {
            primary: "#b2343f",
            secondary: "#e25e6b",
        },
    },
    continueButton: {
        color: "#b2343f",
        background: "#fee5a4",
        borderColor: "#b2343f",
    },
};
