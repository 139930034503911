import {css} from "@emotion/core";

export const container = css({
    position: "absolute",
    left: 10,
    top: "-20%",
    border: "1px solid #eee",
    borderRadius: "6px",
    boxShadow: "0 2px 2px 0 rgba(0,0,0,0.3)",
    zIndex: 1000,
    overflow: "hidden",
});
