import {css, keyframes} from "@emotion/core";
import {absoluteCenter, inlineBlockTop} from "../../../../style-variables";
import {dogmashBlue, dogmashOrange, dogmashRed, dogmashSuccessScoreColor} from "./dogma-dash-styles";
import {transparentize} from "color2k";

export const capsuleWidth = 12;

export const microtubuleContainer = css({
    width: "100%",
    position: "relative",
});

export const capsuleContainer = css({
    width: "100%",
    whiteSpace: "nowrap",
});

export const proteinContainer = css({
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
});

export const capsule = css(inlineBlockTop, {
    width: `${capsuleWidth}px`,
    height: "22px",
    border: `2px solid ${dogmashOrange}`,
    boxSizing: "border-box",
    borderRadius: "8px",
    backgroundColor: transparentize(dogmashOrange, 0.5),
    transition: "border-color 0.3, background-color 0.3",

    "&:not(:last-of-type)": {
        marginRight: "-2px",
    },
});
export const brokenCapsule = css(capsule, {
    borderColor: 'transparent',
    backgroundColor: "transparent",
});
export const redCapsule = css(capsule, {
    borderColor: dogmashRed,
    backgroundColor: transparentize(dogmashRed, 0.5),
});

export const dropSpotContainer = css({
    position: "absolute",
    left: "50%",
    top: "50%",
    zIndex: 1,
});

const glowAnimation = keyframes({
    "0%": {
        borderColor: dogmashBlue,
        boxShadow: "0 0 10px 1px rgba(255,255,255,0.4)",
    },
    "10%": {
        borderColor: dogmashBlue,
        boxShadow: "0 0 10px 1px rgba(255,255,255,0.6)",
    },
    "50%": {
        borderColor: dogmashRed,
        boxShadow: "0 0 10px 1px rgba(255,255,255,0.99)",
    },
    "90%": {
        borderColor: dogmashBlue,
        boxShadow: "0 0 10px 1px rgba(255,255,255,0.6)",
    },
    "100%": {
        borderColor: dogmashBlue,
        boxShadow: "0 0 10px 1px rgba(255,255,255,0.4)",
    },
});
export const dropSpot = css({
    width: "27px",
    height: "27px",
    border: `3px solid ${dogmashBlue}`,
    transform: "rotate(45deg)",
    animation: `${glowAnimation} 1s ease-in-out infinite`,
    boxShadow: "0 0 10px 1px rgba(255,255,255,0.4)",
});

export const droppedProtein = css({
    position: 'absolute',
    left: "50%",
    top: "50%",
    transform: 'translate(-50%, -50%) rotate(-45deg)',
});

const scoreAnimation = up => keyframes({
    "0%": {
        opacity: 0,
        transform: "translate(0, 0)",
    },
    "50%": {
        opacity: 1,
    },
    "100%": {
        opacity: 0.1,
        transform: `translate(25px, ${(up ? -1 : 1)*50}px)`,
    },
});
export const scoreDown = css({
    color: dogmashSuccessScoreColor,
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 600,
    fontSize: "2rem",
    animation: `${scoreAnimation(false)} 2s forwards`,
    position: 'absolute',
    left: "90%",
    top: 0,
});

export const scoreUp = css(scoreDown,{
    animation: `${scoreAnimation(true)} 2s forwards`,
});
