import './polaroid.scss';
import React from 'react';
import {useSelector} from 'react-redux';
import {getPolaroidImageUrl} from "../../store/polaroids/selectors";

export default ({id}) => {
    const imageUrl = useSelector(state => getPolaroidImageUrl(state, id));

    return (
        <div className='polaroid'>
            <img src={imageUrl} />
        </div>
    );
}
