import * as styles from './partners-styles';
import React, {useRef, useState} from 'react';
import pinMap from "../../Identity/Learner/students.json";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {closeModalType, openModalFor} from "../../store/navigation/actions";
import {PARTNERS_MODAL} from "../../constants/modalTypes";
import {
    getFamilyNameForLearner,
    getGivenNameForLearner,
    getLearnerIdByEmail,
    getLearnerPartnerIds,
    getLearnerPartnerRequestsIds,
    getLearnerRequestedPartnerIds,
    isValidRequest
} from "../../store/learners/selectors";
import {useParams} from "@reach/router";
import {createPartnerRequest, endPartnership, respondToPartnerRequest} from "../../store/learners/actions";
import {getActiveLearner, isModalOpenFor, modalAdditionalData} from "../../store/navigation/selectors";
import {useTheme} from "@emotion/react";
import CloseX from '../../inline-assets/close.svg';
import AcceptCheck from '../../inline-assets/check.svg';
import {absoluteCenter, themedHeaderButton, themedOpenHeaderButton} from "../../style-variables";
import {shallowOrderedArrayCompare} from "../../utility";

const getUsernameByPin = pin => {
    const user = pinMap.loginData.find(u => u.pin === pin);
    return user ? user.username : null;
};

const getLearnerIdByPin = (state, pin) => {
    const user = getUsernameByPin(pin);
    if(!user) { return null; }
    return getLearnerIdByEmail(state, user);
};

const Partner = ({partnerId, pending, wasRequested}) => {
    const currentLearner = useSelector(getActiveLearner);
    const partnerName = useSelector(state => `${getGivenNameForLearner(state, partnerId)} ${getFamilyNameForLearner(state, partnerId)}`);
    const {curriculumId} = useParams();
    const theme = useTheme();
    const dispatch = useDispatch();

    const end = () => pending && !wasRequested
        ? dispatch(respondToPartnerRequest(curriculumId, partnerId, currentLearner, false))
        : dispatch(endPartnership(curriculumId, partnerId));
    const accept = () => dispatch(respondToPartnerRequest(curriculumId, partnerId, currentLearner, true));

    return (
        <div css={styles.partnerContainer}>
            <div css={styles.partnerRepresentation}>
                <span css={absoluteCenter}>{partnerName}</span>
            </div>
            {pending && !wasRequested && <button css={styles.acceptPartner} onClick={accept}><AcceptCheck /></button>}
            {pending && !wasRequested && <button css={styles.rejectPartner} onClick={end}><CloseX/></button>}
            {pending && wasRequested && <button css={styles.cancelRequest} onClick={end}>cancel<br/>request</button>}
            {!pending && <button css={styles.endPartner} onClick={end}>remove<br/>partner</button>}
        </div>
    );
};

const PartnersModal = () => {
    const {curriculumId} = useParams();
    const [partnerRequest, setPartnerRequest] = useState("");
    const currentLearner = useSelector(getActiveLearner);
    const partnerIds = useSelector(state => getLearnerPartnerIds(state, currentLearner, curriculumId), shallowOrderedArrayCompare);
    const [addingPartner, setAddingPartner] = useState(false);
    const isOpen = useSelector(state => isModalOpenFor(state, PARTNERS_MODAL));
    const additionalData = useSelector(state => modalAdditionalData(state, PARTNERS_MODAL, null));
    const pendingPartnerRequestId = useSelector(state => getLearnerIdByPin(state, partnerRequest));
    const isValidPartnerRequest = useSelector(state => isValidRequest(state, currentLearner, pendingPartnerRequestId, curriculumId));
    const requestedIds = useSelector(state => getLearnerRequestedPartnerIds(state, currentLearner, curriculumId), shallowOrderedArrayCompare);
    const requesterIds = useSelector(state => getLearnerPartnerRequestsIds(state, currentLearner, curriculumId), shallowOrderedArrayCompare);
    const dispatch = useDispatch();
    const inputRef = useRef();
    const theme = useTheme();

    const requestPartner = () => {
        inputRef.current.value = "";
        setAddingPartner(false);
        dispatch(createPartnerRequest(curriculumId, currentLearner, pendingPartnerRequestId));
    };
    const enterPress = e => e.key === "Enter" ? requestPartner() : null;

    const showPartnerModal = () => dispatch(openModalFor(PARTNERS_MODAL));
    const close = () => {
        setPartnerRequest("");
        dispatch(closeModalType(PARTNERS_MODAL));
    };

    const hasRequest = requestedIds.length > 0 || requesterIds.length > 0;
    const startPicking = () => setAddingPartner(true);

    return (
        <>
            <button css={isOpen ? themedOpenHeaderButton(theme) : themedHeaderButton(theme)} onClick={showPartnerModal}>Team</button>
            {isOpen && <div css={styles.modalContainer}>
                <div css={styles.themedContainer(theme)}>
                    <h2 css={styles.themedTitle(theme)}>{additionalData?.verify ? "Double check your team" : "Research Team"}</h2>
                    <div css={hasRequest ? styles.pendingContainer : styles.pendingPlaceholderContainer}>
                        {hasRequest && <div css={styles.themedPendingText(theme)}>
                            {requestedIds.length > 0 ? "waiting for partner to accept:" : "Partner request pending"}
                        </div>}
                        <div css={styles.partnersContainer}>
                            <div css={styles.partnerContainer}><div css={styles.partnerRepresentation}><span css={absoluteCenter}>You</span></div></div>
                            {partnerIds.map(id => <Partner key={id} partnerId={id}/>)}
                            {requestedIds.map(id => <Partner key={id} partnerId={id} pending={true} wasRequested={true} />)}
                            {requesterIds.map(id => <Partner key={id} partnerId={id} pending={true} wasRequested={false}/>)}
                            <div css={styles.partnerContainer}>
                                <div css={addingPartner ? styles.themedPlaceholderPickingPartner(theme) : styles.themedPlaceholderRepresentation(theme)} onClick={startPicking}>
                                    <span css={absoluteCenter}>+</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {addingPartner && <div css={styles.requestContainer}>
                        <input css={styles.themedInput(theme)} ref={inputRef} placeholder="Enter Partner PIN"
                               onChange={e => setPartnerRequest(e.target.value)} onKeyDown={enterPress}/>
                        <button css={styles.themedModalButton(theme)} onClick={requestPartner}
                                disabled={!pendingPartnerRequestId || !isValidPartnerRequest}>Request
                        </button>
                    </div>}
                    <button css={styles.themedClose(theme)} onClick={close}><CloseX/></button>
                    {!addingPartner && <button css={styles.themedModalButton(theme)} onClick={close}>Continue</button> }
                </div>
            </div>}
        </>
    );
};

export default PartnersModal;
