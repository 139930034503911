import {userAccountQuery} from "./queries/userAccount";
import {setCurrentUserRoles} from "../store/identity/actions";

export const getUserRoles = (dataService) => {
    return dataService.sendGraphQL(userAccountQuery)
        .then(data => {
            if(!data.userAccount) { return; }
            dataService.store.dispatch(setCurrentUserRoles(data.userAccount.roles));
        });
};
