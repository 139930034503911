import * as styles from './explainer-styles';
import React, {useContext, useState} from 'react';
import {useParams} from "@reach/router";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getExplainerQuestion} from "../../../../store/curriculumTaskItems/explainer/selectors";
import {
    getExplainerClaim,
    getExplainerEvidenceIds,
    getExplainerReasoning
} from "../../../../store/interactions/explainer/selectors";
import {
    setExplainerClaim,
    setExplainerReasoning,
    updateEvidenceOrder
} from "../../../../store/interactions/explainer/actions";
import {getLearnerPredictionForExplainer} from "../../../../store/interactions/explainerPrediction/selectors";
import {getPredictionIdForExplainer} from "../../../../store/curriculumTaskItems/explanationPredictions/selectors";
import {combineStylesAlt} from "../../../../Utility/combineStyles";
import {openModalFor} from "../../../../store/navigation/actions";
import {EXPLAINER_EVIDENCE} from "../../../../constants/modalTypes";
import {isModalOpenFor} from "../../../../store/navigation/selectors";
import ExplainerEvidenceViewer from "./ExplainerEvidenceViewer";
import VisceralCardCanvasWrapper from "./VisceralCardCanvasWrapper";
import useDropSortable from "../../../../Utility/useDropSortable";
import RichTextDisplay from "../../RichTextDisplay";
import {FONT_BOLD_WEIGHT} from "../../../../style-variables";
import {getCurriculumCaseIndexForWorkBoardDefinition} from "../../../../store/curriculumCases/selectors";
import {useTheme} from "@emotion/react";

const getValidExplainerEvidence = (state, id, curriculumId) =>
    getExplainerEvidenceIds(state, id).filter(e => {
        const caseIndex = getCurriculumCaseIndexForWorkBoardDefinition(curriculumId, e, state);
        return caseIndex !== null && caseIndex !== undefined;
    });

const ExplainerInteractable = ({id}) => {
    const {curriculumId} = useParams();
    const question = useSelector(state => getExplainerQuestion(state, id));
    const claim = useSelector(state => getExplainerClaim(state, id));
    const predictionId = useSelector(state => getPredictionIdForExplainer(state, id));
    const prediction = useSelector(state => predictionId ? getLearnerPredictionForExplainer(state, predictionId) : "");
    const reasoning = useSelector(state => getExplainerReasoning(state, id));
    const itemIds = useSelector(state => getValidExplainerEvidence(state, id, curriculumId), shallowEqual);
    const evidenceOpen = useSelector(state => isModalOpenFor(state, EXPLAINER_EVIDENCE, id));
    const dispatch = useDispatch();
    const [hasClaim, setHasClaim] = useState(!!claim);
    const [editingClaim, setEditingClaim] = useState(!hasClaim);
    const theme = useTheme();

    const [_, dropRef, evidenceIds] = useDropSortable({
        accept: ["ExplainerEvidence"],
        drop: (item, monitor, placeholderIndex) => {
            const {itemId} = item;
            dispatch(updateEvidenceOrder(curriculumId, id, itemId, placeholderIndex));
        },
    }, itemIds);

    const setClaim = e => {
        if(!claim && !e.target.value) { return; }
        dispatch(setExplainerClaim(curriculumId, id, e.target.value));
        setEditingClaim(false);
        setHasClaim(true);
    };
    const editClaim = () => setEditingClaim(true);
    const setReasoning = e => dispatch(setExplainerReasoning(curriculumId, id, e.target.value));
    const openEvidence = () => dispatch(openModalFor(EXPLAINER_EVIDENCE, id));
    const checkBlur = e => e.which === 13 && e.target.blur();

    return (
        <div css={styles.container}>
            <div css={combineStylesAlt([
                [styles.explainerBlock, true],
                [styles.explainerBlockSmall, hasClaim],
            ])}>
                <h2 css={combineStylesAlt([
                    [styles.headerLabel, true],
                    [styles.headerLabelSmall, hasClaim]
                ])}>Question:</h2>
                <span css={combineStylesAlt([
                    [styles.headerText, true],
                    [styles.headerTextSmall, hasClaim]
                ])}>
                    <RichTextDisplay content={question} />
                </span>
            </div>
            <div css={combineStylesAlt([
                [styles.explainerBlock, true],
                [styles.explainerBlockSmall, hasClaim],
            ])}>
                <h2 css={combineStylesAlt([
                    [styles.headerLabel, true],
                    [styles.headerLabelSmall, hasClaim]
                ])}>Prediction</h2>
                <span css={combineStylesAlt([
                    [styles.headerText, true],
                    [styles.headerTextSmall, hasClaim]
                ])}>{prediction}</span>
                <span css={combineStylesAlt([
                    [styles.inputSubtext, true],
                    [styles.inputSubtextSmall, hasClaim],
                ])}>This is the prediction you made earlier.</span>
            </div>
            <div css={combineStylesAlt([
                [styles.explainerBlock, true],
                [styles.explainerBlockSmall, !editingClaim],
            ])}>
                <h2 css={combineStylesAlt([
                    [styles.themeHeaderLabel(theme), true],
                    [styles.headerLabelSmall, !editingClaim]
                ])}>New Claim</h2>
                <textarea defaultValue={claim} onBlur={setClaim} onFocus={editClaim} onKeyDown={checkBlur} css={combineStylesAlt([
                    [styles.themeExplainerTextInput(theme), true],
                    [styles.claimTextInputSmall, !editingClaim],
                ])}/>
                <span css={combineStylesAlt([
                    [styles.inputSubtext, true],
                    [styles.inputSubtextInvisible, hasClaim],
                ])}>Based on what you learned in VR, write down your best answer to the question. It might be similar to or completely different from your prediction.</span>
            </div>
            <div css={combineStylesAlt([
                [styles.explainerBlock, true],
                [styles.faded, !hasClaim]
            ])}>
                <h2 css={combineStylesAlt([
                    [styles.themeHeaderLabel(theme), true],
                    [styles.headerLabelSmall, !hasClaim],
                ])}>Explanation</h2>
                <div css={styles.explainerContainer} ref={dropRef}>
                    <div css={styles.explainerContainerLabel}>
                        <span style={{fontWeight: FONT_BOLD_WEIGHT}}>Evidence:</span> Add in pieces of evidence that you collected in VR to support your new claim.
                    </div>
                    {evidenceIds.map(i => <VisceralCardCanvasWrapper id={i} key={i} explainerId={id} />)}
                    <button css={styles.themeAddEvidenceButton(theme)} onClick={openEvidence} disabled={!hasClaim}>+</button>
                </div>
                <label css={combineStylesAlt([
                    [styles.themedReasoningContainer(theme), true],
                    [styles.faded, !hasClaim || evidenceIds.length === 0],
                    [styles.reasoningInputSmall, !hasClaim || evidenceIds.length === 0],
                ])}>
                    <div css={styles.explainerContainerLabel}>
                        <span style={{fontWeight: FONT_BOLD_WEIGHT}}>Reasoning:</span> Explain your new claim referring to the evidence you added above.
                    </div>
                    <textarea disabled={!hasClaim || evidenceIds.length === 0}  defaultValue={reasoning} onBlur={setReasoning} css={styles.reasoningInput}/>
                </label>
            </div>
            {evidenceOpen && <ExplainerEvidenceViewer id={id} />}
        </div>
    )
}

export default ExplainerInteractable;
