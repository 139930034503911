import {css} from "@emotion/core";
import grabbableCursor from "../../../assets/cursor_grabbable.png";

export const container = css({
    display: "inline-block",
    backgroundColor: "white",
    color: "black",
    border: "1px solid #eee",
    borderRadius: 4,
    boxShadow: "0 2px 2px 0 rgba(0,0,0,0.4)",
    cursor: `url(${grabbableCursor}) 16 4, auto !important`,
});
