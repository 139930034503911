import * as styles from "./work-board-tray-styles";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "@reach/router";
import {shallowEqual, useSelector} from "react-redux";
import {getTasksInCaseWithWorkBoard} from "../../../store/curriculumCases/selectors";
import TaskTile from "./TaskTile";

export default ({caseIndex, trayRef}) => {
    const {curriculumId} = useParams();
    const taskIds = useSelector(state => getTasksInCaseWithWorkBoard(curriculumId, caseIndex, state), shallowEqual);
    const leftArrowRef = useRef();
    const rightArrowRef = useRef();
    const containerRef = useRef();
    const [position, setPosition] = useState(0);
    const [maxPosition, setMaxPosition] = useState(0);

    // todo: change from tileSpacing to calculating width of each button if we stick with this design.
    useEffect(() => {
        setPosition(0);
        const calculateTraySizing = () => {
            const {width: trayWidth} = trayRef.current.getBoundingClientRect();
            const availableSpace = trayWidth - styles.trayHorizontalPadding * 2;
            const numberVisible = Math.floor(availableSpace / styles.tileSpacing);
            const newMaxPosition = Math.max(0, taskIds.length - numberVisible);
            setMaxPosition(newMaxPosition);
            if(newMaxPosition !== 0) {
                leftArrowRef.current.style.opacity = 1;
                rightArrowRef.current.style.opacity = 1;
            } else {
                leftArrowRef.current.style.opacity = 0;
                rightArrowRef.current.style.opacity = 0;
            }
        };
        calculateTraySizing();
        window.addEventListener("resize", calculateTraySizing);
        return () => window.removeEventListener("resize", calculateTraySizing);
    },[caseIndex]);

    useEffect(() => {
        const x = -position * styles.tileSpacing;
        containerRef.current.style.transform = `translateX(${x}px)`;
    }, [position, containerRef.current]);

    const increasePosition = () => setPosition(p => Math.min(maxPosition, p+1));
    const decreasePosition = () => setPosition(p => Math.max(0, p-1));

    return (
        <div css={styles.caseContainer}>
            <button css={styles.leftButton} onClick={decreasePosition} ref={leftArrowRef} disabled={position === 0} />
            <div css={styles.tileContainer} ref={containerRef}>
                {taskIds.map((t, i) => <TaskTile taskId={t} key={t} index={i} />)}
            </div>
            <button css={styles.rightButton} onClick={increasePosition} ref={rightArrowRef} disabled={position === maxPosition}/>
        </div>
    );
};
