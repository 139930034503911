import {partnerRequestLoaded, partnerRequestRespondedTo} from "../../../store/learners/actions";

export const partnershipRequested = (dispatch, data) => {
    const {learner} = data;
    const requester = data.requester.id;
    const curriuclum = data.curriculum.id;

    dispatch(partnerRequestLoaded(curriuclum, requester, learner));
};

export const partnershipRequestCompleted = (dispatch, data) => {
    const {learner, approved} = data;
    const requester = data.requester.id;
    const curriculum = data.curriculum.id;
    dispatch(partnerRequestRespondedTo(curriculum, requester, learner, approved));
}
