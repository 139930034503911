import {css} from "@emotion/core";
import {absoluteCenter, AVENIR_FONT, bbPadding, FONT_BOLD_WEIGHT, inlineBlockTop} from "../../../style-variables";
import {
    adenineColor,
    cytosineColor,
    guanineColor,
    thymineColor,
    uracilColor
} from "./DogmaDash/Transcription/nucleotide-styles";

const blue = "#76FAED";
const mutationColor = "#ff0000";

export const container = css(bbPadding("10px"), {

});

export const strandContainer = css({
    display: "flex",
    flexDirection: "row",
});

export const strandEntryContainer = css({
    width: "140px",
    position: "relative",

    "&:first-of-type": {
        border: `1px solid ${blue}`,
        padding: "6px 4px",
    },
});

export const codonContainer = css({
    display: "flex",
    flexDirection: "column",
    width: "100%",

    "&:last-of-type": {
        borderRight: `1px solid ${blue}`,
    },
});
export const codonContainerWithMutation = css(codonContainer, {
    border: `4px solid ${mutationColor} !important`,
    backgroundColor: "#303030",
    borderRadius: "20px 20px 6px 6px",
    height: "calc(100% + 30px)",
    position: "absolute",
    top: "-24px",
    paddingTop: "20px",
    boxSizing: "border-box",
});
export const codonMutation = css({
    position: "absolute",
    color: mutationColor,
    top: "5px",
    left: "50%",
    transform: "translateX(-50%)",
    textTransform: "uppercase",
    fontSize: "0.9375em",
    fontWeight: FONT_BOLD_WEIGHT,
});

export const rowHeaders = css(bbPadding("7px 5px"), {
    textAlign: "right",
    marginBottom: "9px",

    "&:last-of-type": {
        marginBottom: 0,
    },
});

export const uppercase = css({
    textTransform: "uppercase",
});

export const strandSection = css(bbPadding("4px 6px"),{
    textAlign: "center",
    borderTop: `1px solid ${blue}`,

    "&:last-of-type": {
        borderBottom: `1px solid ${blue}`,
    }
});
export const strandSectionWithMutation = css(strandSection, {
    borderColor: 'transparent!important',
    position: "relative",
});

export const nucleotideContainer = css(inlineBlockTop, {
    border: '1px solid #FAAC1B',
    borderRadius: "3px",
    transition: "border-color 0.2s, background-color 0.2s, box-shadow 0.2s",
});
export const mutationNucleotideContainer = css(nucleotideContainer, {
    backgroundColor: "#FF0000",
    boxShadow: "0 3px 5px 0 rgba(255, 0, 0, 0.5)",
    borderColor: "transparent",
});
export const adenine = css(nucleotideContainer, {
    backgroundColor: adenineColor,
    borderColor: "transparent",
});
export const thymine = css(nucleotideContainer,{
    backgroundColor: thymineColor,
    borderColor: "transparent",
});
export const uracil = css(nucleotideContainer,{
    backgroundColor: uracilColor,
    borderColor: "transparent",
});
export const guanine = css(nucleotideContainer, {
    backgroundColor: guanineColor,
    borderColor: "transparent",
});
export const cytosine = css(nucleotideContainer, {
    backgroundColor: cytosineColor,
    borderColor: "transparent",
});

export const presetNucleotide = css(inlineBlockTop, {
    margin: "7px 5px",
    width: "15px",
    textAlign: "center",
    borderBottom: "1px solid transparent",
});

export const inputStyle = css(inlineBlockTop, {
    width: "15px",
    margin: "7px 5px",
    padding: 0,
    backgroundColor: "transparent",
    border: 'none',
    borderBottom: "2px solid #FAAC1B",
    borderRadius: "2px",
    transition: "border-color 0.2s",
    fontSize: "1em",
    textAlign: "center",
    fontFamily: `${AVENIR_FONT}, sans-serif`,
    outline: "none",

    "&:focus": {
        borderBottomColor: blue,
    },

    "&::placeholder": {
        color: "#000",
        opacity: 1,
    },
});
export const inputWithValue = css(inputStyle, {
    borderBottomColor: "#000",

    "&:focus": {
        borderBottomColor: "#fff",
    },
});
export const inputWithMutation = css(inputStyle, {
    borderBottomColor: "#000",

    "&:focus": {
        borderBottomColor: "#fff",
    },
});

export const aminoAcidPlaceholder = css(inlineBlockTop, {
    width: "47px",
    height: "47px",
    position: "relative",
});
const aminoAcidContainerBase = css(aminoAcidPlaceholder, bbPadding("2px 0 0 2px"), {
    border: `1px solid ${blue}`,
    backgroundColor: `${blue}40`,
    borderRadius: "50%",

    "& > svg": {
        width: "100%",
        height: "100%",
    },
});
export const aminoAcidContainer = fillColor => css(aminoAcidContainerBase, {
    fill: fillColor,
    transition: "fill 0.2s",
});
export const aminoAcidLabel = css(absoluteCenter, {
    fontSize: "0.875em",
    color: "#000",
    textTransform: "uppercase",
});
export const stopLabel = css(aminoAcidLabel, {
    fontSize: "0.6875em",
    color: "#fff",
});

export const flip = css({
    width: "30px",
    height: "30px",
    position: "absolute",
    left: "-2px",
    top: "50%",
    transform: "translate(-50%, -50%)",
    border: `2px solid ${mutationColor}`,
    backgroundColor: "#303030",
    borderRadius: "50%",

    "& > svg": {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
    },
});
