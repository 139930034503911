import {css} from "@emotion/core";
import {bbPadding} from "../../style-variables";

export const taskContainer = css({
    width: "100%",
    height: "100%",
    overflowY: "auto",
    position: "relative",
});

export const scrollingContent = css({
    width: "100%",
    position: "relative",
});

export const nextButton = css(bbPadding("10px 0"), {
    width: "120px",
    border: "none",
    textAlign: "center",
    borderRadius: 0,
    cursor: "pointer",
    float: "right",
    margin: "8px",
});
