import {call, takeEvery, select} from 'redux-saga/effects'
import {
    ADD_TARGET_AMINO_ACID_TO_CENTRAL_DOGMA_WIDGET, REMOVE_TARGET_AMINO_ACID_TO_CENTRAL_DOGMA_WIDGET,
    SET_CENTRAL_DOGMA_INITIAL_STRAND_LENGTH,
    SET_CENTRAL_DOGMA_INITIAL_STRAND_NUCLEOTIDE, SET_CENTRAL_DOGMA_MUTATION
} from "../../../store/curriculumTaskItems/centralDogmaWidget/actions";
import {sendGraphQLSaga} from "../../DataService";
import {getCentralDogmaWidgetTargetAminoAcids} from "../../../store/curriculumTaskItems/centralDogmaWidget/selectors";

const setCentralDogmaWidgetStrandLength = `mutation SetCentralDogmaWidgetStrandLength($input: SetCentralDogmaWidgetStrandLengthInput!) {
    setCentralDogmaWidgetStrandLength(input: $input) {
        centralDogmaWidget {
            id
        }
    }
}`;
function* executeSetStrandLength(action) {
    const {widget, curriculum, length} = action;

    const variables = {
        input: {
            centralDogmaWidget: widget,
            curriculum,
            length,
        },
    };

    yield call(sendGraphQLSaga, setCentralDogmaWidgetStrandLength, variables);
}

const setCentralDogmaWidgetStrandNucleotide = `mutation SetCentralDogmaWidgetStrandNucleotide($input: SetCentralDogmaWidgetStrandNucleotideInput!) {
    setCentralDogmaWidgetStrandNucleotide(input: $input) {
        centralDogmaWidget {
            id
        }
    }
}`;
function* executeSetStrandNucleotide(action) {
    const {widget, curriculum, strandName, index, nucleotide} = action;

    const variables = {
        input: {
            centralDogmaWidget: widget,
            curriculum,
            index,
            nucleotides: [nucleotide],
            strand: strandName,
        },
    };

    yield call(sendGraphQLSaga, setCentralDogmaWidgetStrandNucleotide, variables);
}

const setCentralDogmaWidgetMutation = `mutation SetCentralDogmaWidgetMutation($input: SetCentralDogmaWidgetMutationInput!) {
    setCentralDogmaWidgetMutation(input: $input) {
        centralDogmaWidget {
            id
        }
    }
}`;
function* executeSetWidgetMutation(action) {
    const {widget, curriculum, strandName, index, nucleotide} = action;

    const variables = {
        input: {
            centralDogmaWidget: widget,
            curriculum,
            index,
            strand: strandName,
            nucleotide,
        },
    };

    yield call(sendGraphQLSaga, setCentralDogmaWidgetMutation, variables);
}

const setCentralDogmaWidgetTargetAminoAcids = `mutation SetCentralDogmaWidgetTargetAminoAcids($input: SetCentralDogmaWidgetTargetAminoAcidsInput!) {
    setCentralDogmaWidgetTargetAminoAcids(input: $input) {
        centralDogmaWidget {
            targetAminoAcids
        }
    }
}`;
function* executeSetAminoAcids(action) {
    const {curriculum, widget} = action;
    const aminoAcids = yield select(state => getCentralDogmaWidgetTargetAminoAcids(state, widget));

    const variables = {
        input: {
            centralDogmaWidget: widget,
            curriculum,
            aminoAcids,
        },
    };

    yield call(sendGraphQLSaga, setCentralDogmaWidgetTargetAminoAcids, variables);
}

export default [
    takeEvery(SET_CENTRAL_DOGMA_INITIAL_STRAND_LENGTH, executeSetStrandLength),
    takeEvery(SET_CENTRAL_DOGMA_INITIAL_STRAND_NUCLEOTIDE, executeSetStrandNucleotide),
    takeEvery(SET_CENTRAL_DOGMA_MUTATION, executeSetWidgetMutation),
    takeEvery(ADD_TARGET_AMINO_ACID_TO_CENTRAL_DOGMA_WIDGET, executeSetAminoAcids),
    takeEvery(REMOVE_TARGET_AMINO_ACID_TO_CENTRAL_DOGMA_WIDGET, executeSetAminoAcids),
];
