import {takeEvery, call, select} from 'redux-saga/effects';
import {
    SET_DROP_SLOT_ACCEPTS,
    SET_DROP_SLOT_LABEL,
    SET_DROP_SLOT_VALIDATION_DATA
} from "../../../store/dropSlots/actions";
import {getDropSlotAccepts, getDropSlotLabel} from "../../../store/dropSlots/selectors";
import {sendGraphQLSaga} from "../../DataService";

const updateDropSlotItemContentQuery = `mutation UpdateScrollerDropSlotItemContent($input: UpdateScrollerDropSlotItemContentInput!) {
    updateScrollerDropSlotItemContent(input: $input) {
        item {
            id
        }
    }
}`;

function* execute(action) {
    const accepts = yield select(state => getDropSlotAccepts(action.dropSlotId, state));
    const label = yield select(state => getDropSlotLabel(action.dropSlotId, state));

    const variables = {
        input: {
            curriculum: action.curriculumId,
            item: action.dropSlotId,
            accepts,
            label,
        },
    };

    yield call(sendGraphQLSaga, updateDropSlotItemContentQuery, variables);
}

const setWorkBoardDropSlotValidationData = `mutation SetWorkBoardDropSlotValidationData($input: SetWorkBoardDropSlotValidationData!) {
    setWorkBoardDropSlotValidationData(input: $input) {
        dropSlot {
            validationData
        }
    }
}`;
function* executeSetValidationData(action) {
    const variables = {
        input: {
            curriculum: action.curriculumId,
            dropSlot: action.dropSlotId,
            validationData: action.validationData,
        },
    };

    yield call(sendGraphQLSaga, setWorkBoardDropSlotValidationData, variables);
}

export default [
    takeEvery(SET_DROP_SLOT_ACCEPTS, execute),
    takeEvery(SET_DROP_SLOT_LABEL, execute),
    takeEvery(SET_DROP_SLOT_VALIDATION_DATA, executeSetValidationData),
];
