export const getActiveLearner = state => state.navigation.activeLearner;
export const getSelectedActivity = state => state.navigation.activeActivity;
export const getSelectedTopic = state => state.navigation.activeTopic;
export const getCurrentCurriculum = state => state.navigation.activeCurriculum;
export const getVRBlockerShowing = state => state.navigation.vrBlockerShowing;
export const getTaskHovered = (state, taskId) => state.navigation.tasksHovered[taskId] || false;
export const getShowResetCurriculum = state => state.navigation.showResetCurriculum;
const findOpenModal = (state, modalType, id) =>
    state.navigation.openModals.find(m => m.type === modalType && m.id === id);
export const isModalOpenFor = (state, modalType, id = null) => !!findOpenModal(state, modalType, id);
export const idsForOpenModalType = (modalType, state) => state.navigation.openModals.filter(m => m.type === modalType).map(m => m.id)
export const modalAdditionalData = (state, modalType, id) => {
    const modal = findOpenModal(state, modalType, id);
    if(!modal) {
        return null;
    }
    return modal.additionalData;
};
export const getSelectedExplainerEvidence = (state) => state.navigation.selectedExplainerEvidence;
export const isExplainerEvidenceSelected = (state, evidenceId) => getSelectedExplainerEvidence(state, evidenceId).includes(evidenceId);
export const getLoadedLearnerForTeacher = (state) => state.navigation.currentlyLoadedLearner;
export const getCurrentlyLoadedCurriculum = state => state.navigation.currentlyLoadedCurriculum;
export const getOpenGlobalInteractive = state => state.navigation.globalInteractiveOpen;
export const getCurriculumLoadingForLearner = state => state.navigation.loadingLearnerCurriculum;
