import {css} from "@emotion/core";
import geneChoiceBackground from './Assets/gene-selection-background.png';
import gridBackground from '../Assets/grid-background.png';
import {absoluteCenter, bbPadding, inlineBlockTop} from "../../../../../style-variables";
import {backgroundColor, dogmashBlue, dogmashOrange, dogmashPurple, dogmashRed} from "../dogma-dash-styles";
import {transparentize} from "color2k";

const cardWidth = "295px";

export const container = css({
    width: "100%",
    height: "100%",
    backgroundColor,
    position: "relative",

    "&:before": {
        content: '""',
        display: "block",
        width: "100%",
        height: "368px",
        position: "absolute",
        left: 0,
        top: "50%",
        transform: "translateY(-50%)",
        backgroundImage: `url(${gridBackground})`,
        opacity: 0.25,
    },
});

export const tutorialContainer = css({
    width: 0,
    height: 0,
    zIndex: 20,
    margin: "0 10px 0 60px",
});

export const headerContainer = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "0 0 28px 0",
});

export const infoContainer = css(bbPadding("25px 0 0 25px"), {
    display: 'flex',
    flexDirection: "column",
    alignItems: "flex-start",
});

export const backButton = css(inlineBlockTop, bbPadding("5px"), {
    width: "45px",
    height: "42px",
    border: `1px solid ${dogmashBlue}`,
    backgroundColor: backgroundColor,
    margin: "0 15px 0 0",
    transition: "background-color 0.2s",
    cursor: "pointer",

    "& > svg": {
        width: "100%",
        height: "100%",
        stroke: dogmashBlue,
        fill: dogmashBlue,
        transform: "rotate(180deg)",
    },

    "&:hover": {
        backgroundColor: dogmashBlue,

        "& > svg": {
            stroke: backgroundColor,
            fill: backgroundColor,
        },
    },
});

export const header = css(inlineBlockTop, {
    margin: 0,
    padding: 0,
    fontFamily: "Orbitron, sans-serif",
    fontSize: "3.4375rem",
    fontWeight: 500,
    color: "#fff",
});

export const alertHeader = css({
    color: dogmashRed,
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 700,
    fontSize: "1.5625rem",
    padding: 0,
    margin: "0 0 14px 0",
});

export const alert = css({
    width: "128px",
    flex: "0 0 auto",
});

export const geneSelector = css(absoluteCenter, {
    width: "912px",
    height: "623px",
    backgroundImage: `url(${geneChoiceBackground})`,
    backgroundSize: "100% 100%",
    transition: "transform 0.3s",
});
export const geneSelectorGeneSelected = css(geneSelector, {
    transform: `translate(calc(-50% - 108px), -50%)`,
});

export const geneLocation = css({
    position: 'absolute',
});
export const geneLocationSelected = css(geneLocation, {
    zIndex: 2,
});

export const locationIndicatorUnselected = css({
    border: `1px solid ${dogmashOrange}`,
    borderRadius: "50%",
    backgroundColor: transparentize(dogmashOrange, 0.3),
    cursor: "pointer",
    transition: "background-color 0.2s ,border-color 0.2s",//, border-width 0.2s, margin 0.2s
    margin: "0 0 6px 0",
    position: "relative",
});
export const locationIndictatorSelected = css(locationIndicatorUnselected, {
    border: `5px solid ${dogmashOrange}`,
    margin: "-4px 0 6px -4px",
})
export const locationIndicatorHighlighted = css(locationIndicatorUnselected, {
    border: `5px solid ${dogmashBlue}`,
    backgroundColor: transparentize(dogmashBlue, 0.3),
    margin: "-4px 0 6px -4px",
});
export const locationIndicatorUnlocked = css(locationIndicatorUnselected, {
    borderColor: dogmashPurple,
    backgroundColor: transparentize(dogmashPurple, 0.3),
});

export const geneSelectorButton = css({
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    background: 'transparent',
    border: "none",
    cursor: "pointer",
});

export const geneName = css({
    width: "130px",
    fontFamily: "Rajdhani, sans-serif",
    fontSize: "1.5625rem",
    fontWeight: 700,
    margin: "0 0 10px 0",
    color: dogmashOrange,
    textAlign: "center",
    textTransform: "uppercase",
    transition: "color 0.2s",
    transform: "translateX(calc(-50% + 30px))",
});
export const geneNameHighlighted = css(geneName, {
    color: dogmashBlue,
});
export const geneNameUnlocked = css(geneName, {
    color: dogmashPurple,
});

export const geneViewButton = css(bbPadding("20px 32px"), {
    width: "235px",
    border: `1px solid ${dogmashOrange}`,
    backgroundColor: `#292929`,
    fontFamily: "Rajdhani, sans-serif",
    fontSize: "1.75rem",
    fontWeight: 600,
    color: dogmashOrange,
    lineHeight: "0.92em",
    textAlign: "center",
    textTransform: "capitalize",
    cursor: "pointer",
    transform: "translateX(calc(-50% + 30px))",
    transition: "background-color 0.2s, color 0.2s, box-shadow 0.2s",
    boxShadow: "0px 0px 80px 2px rgba(2, 126, 115, 0.59)",

    "&:hover": {
        backgroundColor: dogmashOrange,
        color: "#292929",
        boxShadow: "0px 0px 90px 2px rgba(2, 126, 115, 0.8)",
    }
});

export const unhighlightButton = css(bbPadding("0 4px"), {
    width: "17px",
    height: "17px",
    background: "#ff6755",
    border: "none",
    borderRadius: "3px",
    position: "absolute",
    top: "-5px",
    left: "calc(100% + 2px)",
    fill: "#fff",
    cursor: "pointer",
});

export const cardContainer = css(bbPadding("66px 17px 0 17px"), {
    width: cardWidth,
    height: "100%",
    position: "absolute",
    top: "0",
    right: "0",
    transform: "translateX(100%)",
    backgroundColor: backgroundColor,
    border: `3px solid ${dogmashBlue}`,
    borderRadius: "8px 0 0 8px",
    borderRight: 'none',
    transition: "transform 0.3s",
});
export const cardContainerExpanded = css(cardContainer, {
    transform: "translateX(0)",
});

export const cardClose = css(bbPadding("11px"), {
    width: "40px",
    height: "40px",
    backgroundColor: dogmashBlue,
    border: 'none',
    fill: "#292929",
    position: "absolute",
    top: "-3px",
    right: "0",
    margin: 0,
    cursor: "pointer",
});

export const cardHeader = css({
    color: dogmashBlue,
    fontSize: "2.5rem",
    textAlign: "left",
    margin: "0 0 8px 0",
    whiteSpace: "normal",
    padding: 0,
    fontFamily: "Orbitron, sans-serif",
    fontWeight: 400,
    textTransform: "capitalize",
});

export const cardImage = css({
    display: "block",
    margin: "0 0 30px 0"
});

export const functionHeader = css({
    padding: 0,
    margin: "0 0 9px 0",
    color: dogmashOrange,
    fontFamily: "Rajdhani, sans-serif",
    fontSize: "1.875rem",
    fontWeight: "700",
    textTransform: "uppercase",
});

export const cardDescriptionText = css({
    margin: "0 0 80px 0",
    color: "#fff",
    fontSize: "1.25em",
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 500,
});

export const cardSelectionButton = css(bbPadding("20px 32px"), {
    backgroundColor: "transparent",
    border: `1px solid ${dogmashBlue}`,
    color: dogmashBlue,
    fontFamily: "Rajdhani, sans-serif",
    fontSize: "1.25em",
    fontWeight: 600,
    textTransform: "capitalize",
    cursor: "pointer",
    transition: "background-color 0.2s, color 0.2s",
    whiteSpace: "nowrap",

    "&:hover": {
        backgroundColor: dogmashBlue,
        color: "#292929",
    }
});
export const cardSelectionHighlighted = css(cardSelectionButton, {
    cursor: "default",
    backgroundColor: dogmashBlue,
    color: "#292929",
});
export const cardSelectionUnlocked = css(cardSelectionButton, {
    color: "#292929",
    borderColor: dogmashPurple,
    backgroundColor: dogmashPurple,
    cursor: "default",

    "&:hover": {
        color: "#292929",
        borderColor: dogmashPurple,
        backgroundColor: dogmashPurple
    }
});

export const cardButtonContainer = css({
    position: "absolute",
    left: "50%",
    bottom: "40px",
    transform: "translateX(-50%)",
});

export const incorrectAlertArrow = css({
    borderTop: `25px solid ${dogmashRed}`,
    borderRight: "17px solid transparent",
    borderLeft: "17px solid transparent",
    position: 'absolute',
    left: "50%",
    bottom: "calc(100% + 4px)",
});

export const incorrectAlertContent = css(bbPadding("16px 45px 11px 28px"), {
    width: "570px",
    border: `5px solid ${dogmashRed}`,
    borderRadius: "14px 14px 0px 14px",
    backgroundColor: "#002926",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    bottom: "24px",
    right: "-30px",

    "& > svg": {
        flex: '0 0 auto',
        fill: dogmashRed,
        marginRight: "8px",
    },
});

export const incorrectAlertText = css({
    flex: "1 1 auto",
    color: dogmashRed,
    fontFamily: "Rajdhani, sans-serif",
    fontSize: "1.75rem",
    fontWeight: "700",
});

export const incorrectClose = css(bbPadding("11px"), {
    width: "40px",
    height: "40px",
    background: dogmashRed,
    border: 'none',
    position: "absolute",
    top: "-2px",
    right: '-2px',
    cursor: 'pointer',

    "& > svg": {
        width: "100%",
        height: "100%",
        fill: "#002926",
        stroke: "#002926"
    }
});
