import {css} from "@emotion/core";
import {bbPadding} from "../../style-variables";

export const stickyNote = css({
    backgroundColor: "#F9FFB6",
}, bbPadding("6px"));

export const text = css({
    width: "100%",
    height: "100%",
    border: "none",
    backgroundColor: "transparent",
    resize: "none",
});
