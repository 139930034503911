import * as styles from "./translation-styles";
import * as nucleotideStyles from './nucleotide-styles';
import React, {useState} from 'react';
import {css} from "@emotion/core";
import {
    ADENINE,
    CELL_VIEW,
    CYTOSINE,
    EnergyProteinRotor,
    EnergyProteinStabilizer,
    GENE_SELECTION_VIEW,
    getAminoAcidColorForNucleotides,
    getAminoAcidDisplayNameForNucleotides,
    GUANINE,
    IsTRNANucleotideMatch,
    NUCLEOTIDE_LETTER_MAP,
    ProteinForm_MRNA,
    STOP_CODON, TRANSCRIPTION_VIEW,
    URACIL
} from "../../../../../constants/DogmaDash";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    getDogmaDashAvailableProteins,
    getDogmaDashHighlightedGene, getDogmaDashLevelUnlockingProtein,
    getDogmaDashPortalTrna,
    getDogmaDashTranslationNucleotides
} from "../../../../../store/interactions/dogmaDash/selectors";
import Nucleotide, {NucleotideStyleMap} from "./Nucleotide";
import {setDogmaDashPortalTrna, setDogmaDashView} from "../../../../../store/interactions/dogmaDash/actions";
import {useDrag, useDrop} from "react-dnd";
import {ProteinIconMap} from "../Protein";
import {useParams} from "@reach/router";
import {
    TRANSLATION_CONTINUE_TUTORIAL,
    TRANSLATION_INVENTORY_TUTORIAL,
    TRANSLATION_STRAND_TUTORIAL
} from "../../../../../store/tutorial/configuration/translation-strand-1";
import PopUpTutorial from "../../../../Tutorial/PopUpTutorial";
import ArrowAsset from '../../../../../inline-assets/right-arrow-1.svg'
import {transcriptionProteinDisplayNames} from "./Transcription";
import {GeneSelectionCardData} from "./GeneSelection";
import {closeModalType} from "../../../../../store/navigation/actions";
import {FIRST_TIME_TRANSLATION} from "../../../../../constants/modalTypes";
import FirstTimeVid from "./Assets/translation-tut.gif";
import {isModalOpenFor} from "../../../../../store/navigation/selectors";
import {getTutorialDataToShow} from "../../../../../store/tutorial/selectors";

const TRNA_DRAGGER = "translation trna";

const FirstTimeModal = () => {
    const dispatch = useDispatch();

    const close = () => dispatch(closeModalType(FIRST_TIME_TRANSLATION));

    return (
        <div css={styles.firstTimeModalBlocker}>
            <div css={styles.firstTimeContainer}>
                <h2 css={styles.firstTimeHeader}>Translation</h2>
                <div css={styles.firstTimeImageContainer}>
                    <img src={FirstTimeVid} />
                </div>
                <div css={styles.firstTimeText}>In the second step of creating a protein, the pilot is taking the mRNA to <b>the<br /> ribosome</b>. You have to help them translate the mRNA sequence into a<br /> <b>chain of amino acids</b> -- also known as a <b>protein</b>.</div>
                <button css={styles.firstTimeButton} onClick={close}>Continue</button>
            </div>
        </div>
    );
};

const nucleotideStylingMap = {
    [ADENINE]: {
        nucleotide: nucleotideStyles.adenine,
        container: styles.adenineContainer,
        letter: styles.trnaAdenineLetter,
    },
    [GUANINE]: {
        nucleotide: nucleotideStyles.guanine,
        container: styles.guanineContainer,
        letter: styles.trnaGuanineLetter,
    },
    [CYTOSINE]: {
        nucleotide: nucleotideStyles.cytosine,
        container: styles.cytosineContainer,
        letter: styles.trnaCytosineLetter,
    },
    [URACIL]: {
        nucleotide: nucleotideStyles.uracil,
        container: styles.uracilContainer,
        letter: styles.trnaUracilLetter,
    },
};
const TRNA = ({nucleotides, portal, incorrect}) => {
    const amino = getAminoAcidDisplayNameForNucleotides(nucleotides);
    const color = getAminoAcidColorForNucleotides(nucleotides);
    const styleMap = portal ? nucleotideStylingMap : NucleotideStyleMap;

    const aminoColor = css({backgroundColor: color});

    return (
        <div css={incorrect ? css(styles.trnaIncorrect, styles.trnaContainer) : styles.trnaContainer}>
            {nucleotides.length === 3 ? <div css={amino === STOP_CODON ? styles.stopCodon : [styles.aminoAcidPlaceholder, aminoColor]}>{amino}</div> : <div css={styles.aminoAcidPlaceholder} />}
            <div>
                {nucleotides.map((n, i) =>
                    <div key={i} css={styles.trnaNucleotideContainer}>
                        <div css={styleMap[n].container}>
                            <div css={styleMap[n].nucleotide} />
                        </div>
                    </div>)}
            </div>
            <div css={styles.trnaLetterContainer}>
                {nucleotides.map((n, i) => <div key={i} css={nucleotideStylingMap[n].letter}>{NUCLEOTIDE_LETTER_MAP[n]}</div>)}
            </div>
        </div>
    )
};

const Strand = ({id}) => {
    const strand = useSelector(state => getDogmaDashTranslationNucleotides(state, id));
    const tutorialConfig = {dogmaDashId: id, location: TRANSLATION_STRAND_TUTORIAL};
    const codonIndexes = Array.from({length: Math.floor(strand.length / 3)}, (_, i) => i * 3);

    let firstEmpty = 0;
    while(firstEmpty < codonIndexes.length && !!strand[codonIndexes[firstEmpty]].paired) { ++firstEmpty; }

    return (
        <div css={styles.strandContent}>
            <div css={styles.translationNucleotideBlock}>
                <div>Pilot attached <span css={styles.neutralTextTransform}>tRNA</span></div>
                <div><span css={styles.neutralTextTransform}>mRNA</span> Sequence</div>
                {strand.length === 0 && <div css={styles.strandTutorialPosition}><PopUpTutorial positionData={tutorialConfig} horizontal={true} /></div>}
            </div>
            {codonIndexes.map((c, i) =>
                <div key={c} css={styles.translationNucleotideBlock}>
                    {i === firstEmpty && <div css={styles.strandTutorialPosition}><PopUpTutorial positionData={tutorialConfig} horizontal={true} /></div>}
                    <div css={styles.trnaCodonContainer}>
                        {!!strand[c].paired && <TRNA nucleotides={[strand[c].paired, strand[c+1].paired, strand[c+2].paired]} incorrect={!IsTRNANucleotideMatch(strand[c].given, strand[c].paired) || !IsTRNANucleotideMatch(strand[c+1].given, strand[c+1].paired) || !IsTRNANucleotideMatch(strand[c+2].given, strand[c+2].paired)} />}
                    </div>
                    <div css={styles.mrnaCodonContainer}>
                        <div css={styles.mrnaNucleotideContainer}><Nucleotide nucleotide={strand[c].given} bottom={true}/></div>
                        <div css={styles.mrnaNucleotideContainer}><Nucleotide nucleotide={strand[c + 1].given} bottom={true}/></div>
                        <div css={styles.mrnaNucleotideContainer}><Nucleotide nucleotide={strand[c + 2].given} bottom={true}/></div>
                    </div>
                </div>
            )}
        </div>
    )
};

const Translation = ({id}) => {
    const {curriculumId} = useParams();
    const selectedGene = useSelector(state => getDogmaDashHighlightedGene(state, id));
    const unlockingGene = useSelector(state => getDogmaDashLevelUnlockingProtein(state, id));
    const portalTrna = useSelector(state => getDogmaDashPortalTrna(state, id), shallowEqual);
    const unlockedGenes = useSelector(state => getDogmaDashAvailableProteins(state, id));
    const firstTimeOpen = useSelector(state => isModalOpenFor(state, FIRST_TIME_TRANSLATION));
    const strandTutorial = useSelector(state => getTutorialDataToShow(state, {dogmaDashId: id, location: TRANSLATION_STRAND_TUTORIAL}));
    const selectedUnlocked = unlockedGenes.indexOf(selectedGene) >= 0 || unlockedGenes.indexOf(unlockingGene) >= 0;
    const selectedRoundTwoNeeded = (selectedGene === EnergyProteinRotor && unlockedGenes.indexOf(EnergyProteinStabilizer) < 0) || (selectedGene === EnergyProteinStabilizer && unlockedGenes.indexOf(EnergyProteinRotor) < 0);
    const [currentTrna, setCurrentTra] = useState([]);
    const dispatch = useDispatch();
    const inventoryTutorialConfig = {dogmaDashId: id, location: TRANSLATION_INVENTORY_TUTORIAL};

    const [_, dragRef] = useDrag({
        item: {
            type: TRNA_DRAGGER,
        },
    });
    const setDragRef = r => currentTrna.length === 3 && dragRef(r);

    const [__, dropRef] = useDrop({
        accept: TRNA_DRAGGER,
        canDrop: () => !portalTrna || !portalTrna.length,
        drop: _ => {
            dispatch(setDogmaDashPortalTrna(id, curriculumId, currentTrna))
            setCurrentTra([]);
        },
    });

    const continueButtonAction = () => {
        selectedRoundTwoNeeded
            ? dispatch(setDogmaDashView(id, GENE_SELECTION_VIEW))
            : dispatch(setDogmaDashView(id, CELL_VIEW));
    }
    const clearPortal = () => dispatch(setDogmaDashPortalTrna(id, curriculumId, null));

    const addToTrna = nucleotide => setCurrentTra([...currentTrna, nucleotide]);
    const backspaceTrna = () => setCurrentTra(currentTrna.slice(0, currentTrna.length - 1));

    const ProteinIcon = ProteinIconMap[selectedGene][ProteinForm_MRNA];
    const cardImage = GeneSelectionCardData[selectedGene].image;

    const back = () => dispatch(setDogmaDashView(id, TRANSCRIPTION_VIEW));

    return (
        <div css={styles.container}>
            {firstTimeOpen && <FirstTimeModal />}
            <div css={styles.pageHeaderSection}>
                <button css={styles.backButton} onClick={back}><ArrowAsset /></button>
                <h1 css={styles.header}>
                    <span css={styles.headerPurple}>Translation</span>: mRNA <ArrowAsset /> tRNA
                </h1>
                {selectedUnlocked && <button css={styles.returnButton} onClick={continueButtonAction}>Return to {selectedRoundTwoNeeded ? "Nucleus" : "Cell"}</button>}
                <div css={styles.buttonTutorial}><PopUpTutorial positionData={{location: TRANSLATION_CONTINUE_TUTORIAL, dogmaDashId: id}} /></div>
            </div>
            <div css={styles.pageSection}>
                <div css={styles.translationBlock}>
                    <h2 css={styles.staticBlockHeader}>Live Ribosome Feed</h2>
                    <Strand id={id} />
                </div>
                <div css={styles.proteinImageBlock}>
                    <h2 css={styles.staticBlockHeader}>{transcriptionProteinDisplayNames(selectedGene)} <ProteinIcon /></h2>
                    <div css={styles.proteinImageContainer}><img src={cardImage} /></div>
                </div>
            </div>
            <div css={styles.pageSection}>
                <div css={styles.decoderSectionContainer}>
                    <h2 css={styles.staticBlockHeader}>Decoder</h2>
                    <div css={styles.decoderContent}>
                        <div css={styles.decoderPair}>
                            <div css={styles.strandHeader}>tRNA</div>
                            <div css={styles.strandHeader}>mRNA</div>
                        </div>
                        <div css={styles.decoderPair}>
                            <Nucleotide nucleotide={URACIL}/>
                            <Nucleotide nucleotide={ADENINE} bottom={true} />
                        </div>
                        <div css={styles.decoderPair}>
                            <Nucleotide nucleotide={CYTOSINE}/>
                            <Nucleotide nucleotide={GUANINE} bottom={true} />
                        </div>
                        <div css={styles.decoderPair}>
                            <Nucleotide nucleotide={ADENINE} />
                            <Nucleotide nucleotide={URACIL} bottom={true} />
                        </div>
                        <div css={styles.decoderPair}>
                            <Nucleotide nucleotide={GUANINE} />
                            <Nucleotide nucleotide={CYTOSINE} bottom={true} />
                        </div>
                    </div>
                </div>

                <div css={strandTutorial?.alert ? styles.disabledInventory : styles.inventoryBlock}>
                    <h2 css={styles.interactableBlockHeader}>
                        {<div css={styles.headerTutorial}><PopUpTutorial expandUp={true} positionData={inventoryTutorialConfig} /></div>}
                        tRNA Builder
                    </h2>
                    <div css={styles.inventorySectionContainer}>
                        <div css={styles.inventoryTopContainer}>
                            <div css={currentTrna.length === 3 ? styles.trnaBubbleFull : styles.trnaBubble}>
                                <div ref={setDragRef}>
                                    {currentTrna.length > 0 && <TRNA nucleotides={currentTrna} portal={true} />}
                                </div>
                            </div>
                        </div>
                        <div css={styles.inventoryButtonContainer}>
                            <button css={styles.adenineInventoryButton} onClick={() => addToTrna(ADENINE)} disabled={currentTrna.length >= 3}>A</button>
                            <button css={styles.uracilInventoryButton} onClick={() => addToTrna(URACIL)} disabled={currentTrna.length >= 3}>U</button>
                            <button css={styles.cytosineInventoryButton} onClick={() => addToTrna(CYTOSINE)} disabled={currentTrna.length >= 3}>C</button>
                            <button css={styles.guanineInventoryButton} onClick={() => addToTrna(GUANINE)} disabled={currentTrna.length >= 3}>G</button>
                            <button css={styles.backspaceButton} onClick={backspaceTrna} disabled={currentTrna.length === 0}>Delete</button>
                        </div>
                    </div>
                </div>

                <div css={styles.portalBlock}>
                    <h2 css={styles.interactableBlockHeader}>Pilot Portal</h2>
                    <div css={styles.vrPortalSection}>
                        <button css={styles.clear} onClick={clearPortal} disabled={!portalTrna || !portalTrna.length}>X</button>
                        <div css={styles.portal} ref={dropRef}>
                            {portalTrna && portalTrna.length > 0 && <TRNA nucleotides={portalTrna} portal={true} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Translation;
