import {css} from "@emotion/core";
import {bbPadding} from "../../../style-variables";

export const bank = css({
    // width: "50%",
    height: "100%",
    position: "absolute",
    right: 0,
    top: 0,
    backgroundColor: "rgba(0,0,0,0.4)",
    color: "white",
    zIndex: 5,
}, bbPadding(8));

export const traitContainer = css({
    display: "inline-block",
    verticalAlign: "top",
    width: "48%",
    margin: "0 0 4px 0",
    textAlign: "center",
});

export const traitLabel = css({
    fontSize: "0.75em",
    textAlign: "left",
});
