import * as styles from './create-menu-styles';
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {modalAdditionalData} from "../../../../../store/navigation/selectors";
import {TEMPLATE_MENU} from "../../../../../constants/modalTypes";
import {css} from "@emotion/core";
import {openModalFor} from "../../../../../store/navigation/actions";
import {useParams} from "@reach/router";
import {addItemToWorkBoardTemplate} from "../../../../../store/workBoardTemplate/actions";
import uuid from 'uuid/v4';
import {WORK_BOARD_CROSS_CARD, WORK_BOARD_DROP_SLOT} from "../../../../../constants/TaskTypes";

const getMenuPosition = id => state => modalAdditionalData(state, TEMPLATE_MENU, id);

export default props => {
    const {id} = props;
    const {curriculumId} = useParams();
    const {x,y} = useSelector(getMenuPosition(id));
    const dispatch = useDispatch();

    const createItem = type => e => {
        dispatch(openModalFor(null, id)); //closes.. kinda gross.
        dispatch(addItemToWorkBoardTemplate(curriculumId, id, uuid(), type, x, y));
    };

    const menuStyle = css({
        top: `${y * 100}%`,
        left: `${x * 100}%`,
    }, styles.menu);

    return (
        <div css={menuStyle}>
            <button css={styles.menuButton} onClick={createItem(WORK_BOARD_DROP_SLOT)}>Add Drop Slot</button>
            <button css={styles.menuButton} onClick={createItem(WORK_BOARD_CROSS_CARD)}>Add Cross Card</button>
        </div>
    );
}
