import {TRAIT_SYMBOL_MAP} from "../../../constants/TraitGeneSymbol";
import {DOMINANT, HETEROZYGOUS, RECESSIVE} from "../../../constants/Zygosities";

export const getTraitLetter = (trait, alleleValue) => {
    let traitLetter = TRAIT_SYMBOL_MAP[trait];
    if(alleleValue === 0) {
        return traitLetter.toUpperCase();
    }
    return traitLetter
};

export const getTraitZygosity = (traitGenotype) => {
    if(traitGenotype.AlleleA === traitGenotype.AlleleB) {
        if(traitGenotype.AlleleA === 0) {
            return DOMINANT;
        }
        return RECESSIVE;
    }
    return HETEROZYGOUS;
};
