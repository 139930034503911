import {takeEvery, call} from 'redux-saga/effects';
import {ADD_ITEM_TO_WORK_BOARD_TEMPLATE} from "../../../store/workBoardTemplate/actions";
import {sendGraphQLSaga} from "../../DataService";
import {WORK_BOARD_CROSS_CARD, WORK_BOARD_DROP_SLOT} from "../../../constants/TaskTypes";

const addDropSlot = `mutation AddDropSlotToWorkBoardTemplate($input: AddDropSlotToWorkBoardTemplateInput!) {
    addDropSlotToWorkBoardTemplate(input: $input) {
        dropSlot {
            id
        }
    }
}`;

const addCrossCard = `mutation AddCrossToWorkBoardTemplate($input: AddCrossToWorkBoardTemplateInput!) {
    addCrossToWorkBoardTemplate(input: $input) {
        crossCard {
            id
        }
    }
}`;

function* execute(action) {
    const variables = {
        input: {
            curriculum: action.curriculumId,
            workBoardDefinition: action.workBoardDefinitionId,
            x: action.x,
            y: action.y,
        },
    };
    let query;
    if(action.itemType === WORK_BOARD_DROP_SLOT) {
        query = addDropSlot;
        variables.input.dropSlot = action.itemId;
    } else if(action.itemType === WORK_BOARD_CROSS_CARD) {
        query = addCrossCard;
        variables.input.crossCard = action.itemId;
    }

    yield call(sendGraphQLSaga, query, variables);
}

export default [takeEvery(ADD_ITEM_TO_WORK_BOARD_TEMPLATE, execute)];
