export const SET_WORK_BOARD_INSTRUCTIONS = "set work board instructions";
export const setWorkBoardInstructions = (curriculumId, workBoardDefinitionId, instructions) => ({
    type: SET_WORK_BOARD_INSTRUCTIONS,
    curriculumId,
    workBoardDefinitionId,
    instructions,
});

export const ADD_PEA_PLANT_MATERIAL_TO_WORK_BOARD = "Add pea plant material to work board";
export const addPeaPlantMaterialToWorkBoard = (curriculumId, workBoardDefinitionId, materialId, plantId) => ({
    type: ADD_PEA_PLANT_MATERIAL_TO_WORK_BOARD,
    curriculumId,
    workBoardDefinitionId,
    materialId,
    plantId,
});

export const REMOVE_MATERIAL_FROM_WORK_BOARD = "Remove material from work board";
export const removeMaterialFromWorkBoard = (curriculumId, workBoardDefinitionId, materialId) => ({
    type: REMOVE_MATERIAL_FROM_WORK_BOARD,
    curriculumId,
    workBoardDefinitionId,
    materialId,
});

// TODO: save to db
export const SET_PEA_PLANT_MATERIAL_PROVIDE_PEAS = "Set pea plant material provide peas";
export const setPeaPlantMaterialProvidePeas = (curriculumId, workBoardDefinitionId, materialId, providePeas) => ({
    type: SET_PEA_PLANT_MATERIAL_PROVIDE_PEAS,
    curriculumId,
    workBoardDefinitionId,
    materialId,
    providePeas,
});

// TODO: save to db
export const SET_PEA_PLANT_MATERIAL_PROVIDE_CARD = "Set pea plant material provide card";
export const setPeaPlantMaterialProvideCard = (curriculumId, workBoardDefinitionId, materialId, provideCard) => ({
    type: SET_PEA_PLANT_MATERIAL_PROVIDE_CARD,
    curriculumId,
    workBoardDefinitionId,
    materialId,
    provideCard,
});

export const SET_WORK_BOARD_USES_TEMPLATE = "Set work board uses template"
export const setWorkBoardUsesTemplate = (curriculumId, workBoardDefinitionId, useTemplate) => ({
    type: SET_WORK_BOARD_USES_TEMPLATE,
    curriculumId,
    workBoardDefinitionId,
    useTemplate,
});
