// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/trash.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".admin-curriculum--case{width:100%}.curriculum-case--title-container{margin:0 0 4px 0}.curriculum-case--index{display:inline-block;vertical-align:top;font-size:.875em;font-weight:600;margin:0 4px 0 0}.curriculum-case--title{display:inline-block;vertical-align:top;font-size:.875em}.curriculum-case--tasks-container{margin:0 0 4px 0}.curriculum-case-tasks-header{font-size:1em;font-weight:400;margin:0 0 4px 0;padding:0}.curriculum-case--task-container{box-sizing:border-box;padding:6px}.curriculum-case--task-container:nth-child(2n){background-color:#efefef}.curriculum-case-task--handle-container{display:inline-block;vertical-align:middle;box-sizing:border-box;padding:0 3px;margin-right:3px}.curriculum-case-task--handle-circle{width:4px;height:4px;background-color:#888;border-radius:50%;position:relative}.curriculum-case-task--handle-circle:not(:last-of-type){margin-bottom:3px}.curriculum-case-task--task-name{display:inline-block;vertical-align:middle;font-size:.875em}.curriculum-case-task--remove{display:inline-block;vertical-align:middle;width:15px;height:15px;padding:0;margin:auto;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;border:none;cursor:pointer}.curriculum-case--add-task{display:block;box-sizing:border-box;padding:4px 8px;background:#fff;border:1px dashed #444;cursor:pointer}", ""]);
// Exports
module.exports = exports;
