import {combineReducers} from "redux";
import identity from "./identity/reducer";
import polaroids from "./polaroids/reducer";
import peaPlantCards from "./peaPlantCards/reducer";
import stickyNotes from "./stickyNotes/reducer";
import podulatorReceipts from "./podulatorReceipts/reducer";
import punnettSquares from "./punnettSquares/reducer";
import workBoards from "./workBoards/reducer";
import data from "./data/reducer";
import navigation from "./navigation/reducer";
import curriculum from "./curriculum/reducer";
import learners from "./learners/reducer";
import cohorts from "./cohorts/reducer";
import bounties from "./bounties/reducer";
import peaPlants from "./peaPlants/reducer";
import curricula from "./curricula/reducer";
import curriculumCases from "./curriculumCases/reducer";
import curriculumTask from "./curriculumTask/reducer";
import scrollingTaskItemPositions from "./scrollingTaskItemPositions/reducer";
import itemSelection from "./itemSelection/reducer";
import interactions from "./interactions/reducer";
import addLearnerModal from "./addLearnerModal/reducer";
import dataAccess from "./dataAccess/reducer";
import dropSlots from './dropSlots/reducer';
import crossCards from './crossCards/reducer';
import workBoardTemplate from './workBoardTemplate/reducer';
import scrollerSections from './scrollerSections/reducer';
import learnerItems from './learnerItems/reducer';
import scrollerWorkBoards from './scrollerWorkBoards/reducer';
import curriculumTaskItems from './curriculumTaskItems/reducer';
import cprInteractables from './curriculumTaskItems/cpr/reducer';
import draggableInteractables from './curriculumTaskItems/draggable/reducer';
import draggableBankInteractables from './curriculumTaskItems/draggableBank/reducer';
import inputTableInteractables from './curriculumTaskItems/inputTable/reducer';
import multipleChoiceInputInteractables from './curriculumTaskItems/multipleChoiceInput/reducer';
import dogmaDashInteractables from './curriculumTaskItems/dogmaDash/reducer';
import centralDogmaWidgetInteractables from './curriculumTaskItems/centralDogmaWidget/reducer';
import utilityButtonInteractables from './curriculumTaskItems/utilityButton/reducer';
import workBoardDefinitions from './curriculumTaskItems/workBoardDefinition/reducer';
import longTextInputInteractables from './curriculumTaskItems/longTextInput/reducer';
import explainerInteractables from './curriculumTaskItems/explainer/reducer';
import explainerPredictionInteractables from './curriculumTaskItems/explanationPredictions/reducer';
import explainerInteractions from './interactions/explainer/reducer';
import explainerPredictionInteractions from './interactions/explainerPrediction/reducer';
import learnerInventoryInteractions from './interactions/learnerInventory/reducer';
import mrnaActivityInteractions from './interactions/mrnaActivity/reducer';
import dogmaDashInteractions from './interactions/dogmaDash/reducer';
import dogmaDashOrganelles from "./interactions/dogmaDash/dogmaDashOrganelleReducer";
import dogmaDashObjects from "./interactions/dogmaDash/dogmaDashObjectsReducer";
import centralDogmaInteractions from './interactions/centralDogmaWidget/reducer';
import pendingScoring from './interactions/scoring/reducer';
import tutorials from './tutorial/reducer';
import lti from './lti/reducer';

export default combineReducers({
    identity,
    polaroids,
    peaPlantCards,
    stickyNotes,
    podulatorReceipts,
    punnettSquares,
    workBoards,
    data,
    navigation,
    curriculum,
    learners,
    cohorts,
    bounties,
    peaPlants,
    curricula,
    curriculumCases,
    curriculumTask,
    scrollingTaskItemPositions,
    itemSelection,
    interactions,
    addLearnerModal,
    dataAccess,
    dropSlots,
    crossCards,
    workBoardTemplate,
    scrollerSections,
    learnerItems,
    scrollerWorkBoards,
    curriculumTaskItems,
    cprInteractables,
    draggableInteractables,
    draggableBankInteractables,
    inputTableInteractables,
    multipleChoiceInputInteractables,
    dogmaDashInteractables,
    centralDogmaWidgetInteractables,
    utilityButtonInteractables,
    workBoardDefinitions,
    longTextInputInteractables,
    explainerInteractables,
    explainerPredictionInteractables,
    explainerInteractions,
    explainerPredictionInteractions,
    learnerInventoryInteractions,
    mrnaActivityInteractions,
    dogmaDashInteractions,
    dogmaDashOrganelles,
    dogmaDashObjects,
    centralDogmaInteractions,
    pendingScoring,
    tutorials,
    lti,
});
