import React from 'react';
import Phenotype from "../../Utility/Phenotype";
import {useSelector} from "react-redux";
import TraitPhenotypeDominanceMap from "../../constants/TraitPhenotypeDominanceMap";
import {getPeaPlantTraitPhenotype} from "../../store/peaPlants/selectors";
import {getPeaPlantCardPlantId} from "../../store/peaPlantCards/selectors";
import PlantDisplay from "../../WorkBoard/Items/PlantCard/PlantDisplay";

const getPlantId = cardId => state => getPeaPlantCardPlantId(state, cardId);
const getExpression = (plantId, trait, state) => {
    if(!trait) { return null; }
    return TraitPhenotypeDominanceMap[trait][getPeaPlantTraitPhenotype(state, plantId, trait)];
};

export default props => {
    const {id, metadata} = props;
    const plantId = useSelector(getPlantId(id));
    const expression = useSelector(getExpression.bind(null, plantId, metadata && metadata.trait));

    return (
        <div style={{height: "100%"}}>
            {!metadata && <PlantDisplay plantId={plantId} />}
            {metadata && metadata.phenotype && <Phenotype phenotype={expression} />}
        </div>
    )
};
