import "./cpr.scss";
import React from 'react';
import {shallowEqual, useSelector} from "react-redux";
import {getIsCPRAssessment} from "../../../store/curriculumTaskItems/cpr/selectors";
import {getCPRAttempts} from "../../../store/interactions/selectors";

export default props => {
    const isAssessment = useSelector(getIsCPRAssessment.bind(null, props.id), shallowEqual);
    const attempts = useSelector(getCPRAttempts.bind(null, props.id), shallowEqual);

    return (
        <div className="learner-curriculum-task--cpr">
            <h2 className="learner-cpr--title">CPR {isAssessment ? "Test" : "Practice"}</h2>
            {attempts.length === 0 && <div className="learner-cpr--no-attempts">Not yet attempted</div>}
            {attempts.length > 0 &&
            <div className="learner-cpr--metrics-container">
                <div className="learner-cpr--metrics-prompt">Average performance across all attempts:</div>
                <div className="learner-cpr--metric">
                    <span className="learner-cpr--metric-label">Average X Hand Position:</span>
                    <span className="learner-cpr--metric-value">
                        {attempts.reduce((sum, a) => sum + a.averageHandPositionX, 0)/attempts.length}
                    </span>
                </div>
                <div className="learner-cpr--metric">
                    <span className="learner-cpr--metric-label">Average Y Hand Position:</span>
                    <span className="learner-cpr--metric-value">
                        {attempts.reduce((sum, a) => sum + a.averageHandPositionY, 0)/attempts.length}
                    </span>
                </div>
                <div className="learner-cpr--metric">
                    <span className="learner-cpr--metric-label">Average Compression Depth:</span>
                    <span className="learner-cpr--metric-value">
                        {attempts.reduce((sum, a) => sum + a.averageCompressionDepth, 0)/attempts.length}
                    </span>
                </div>
                <div className="learner-cpr--metric">
                    <span className="learner-cpr--metric-label">Average Compression Rate:</span>
                    <span className="learner-cpr--metric-value">
                        {attempts.reduce((sum, a) => sum + a.averageCompressionRate, 0)/attempts.length}
                    </span>
                </div>
                <div className="learner-cpr--metric">
                    <span className="learner-cpr--metric-label">Average number of failed recoils:</span>
                    <span className="learner-cpr--metric-value">
                        {attempts.reduce((sum, a) => sum + a.recoilFailCount, 0)/attempts.length}
                    </span>
                </div>
                <div className="learner-cpr--metric">
                    <span className="learner-cpr--metric-label">Average percentage of failed recoils:</span>
                    <span className="learner-cpr--metric-value">
                        {attempts.reduce((sum, a) => sum + a.recoilFailPercent, 0)/attempts.length}
                    </span>
                </div>
                <div className="learner-cpr--metric">
                    <span className="learner-cpr--metric-label">Longest Pause:</span>
                    <span className="learner-cpr--metric-value">
                        {attempts.reduce((max, a) => a.longestPause > max ? a.longestPause : max, 0)}
                    </span>
                </div>
                <div className="learner-cpr--metric">
                    <span className="learner-cpr--metric-label">Average number of long pauses:</span>
                    <span className="learner-cpr--metric-value">
                        {attempts.reduce((sum, a) => sum + a.longPauseCount, 0)/attempts.length}
                    </span>
                </div>
            </div>}
        </div>
    )
}
