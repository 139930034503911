import {
    ADD_POLAROID_ATTRIBUTE,
    CHANGE_POLAROID_BORDER_COLOR,
    CHANGE_POLAROID_NOTES,
    CREATE_POLAROID_ACTION,
    DELETE_POLAROID_ATTRIBUTE,
    IMPORT_POLAROIDS_ACTION,
    UPDATE_POLAROID_ATTRIBUTE,
} from "./actions";
import {SET_AUTHENTICATION_STATE} from "../identity/actions";
import {NOT_LOGGED_IN} from "../../constants/AuthenticationState";
import {INTERACTIONS_LOADED} from "../interactions/actions";
import {DROP_AREA_INTERACTION} from "../../constants/TaskTypes";
import {POLAROID} from "../../constants/WorkBoardItemTypes";

const defaultPolaroidState = {
    imageUrl: '',
    borderColor: '#f5f5f5',
    notes: '',
    attributes: [],
};

export default function(state = {}, action) {
    switch(action.type) {
        case INTERACTIONS_LOADED:
            const interactionPolaroids = action.interactions.filter(i => i.__typename === DROP_AREA_INTERACTION).reduce((agg, i) => ({
                ...agg,
                ...i.draggables.filter(d => d.item.__typename === POLAROID).reduce((dAgg, d) => ({
                    ...dAgg,
                    [d.item.id]: {
                        ...defaultPolaroidState,
                        imageUrl: d.item.imageUrl,
                        attributes: d.item.attributes,
                    },
                }), {}),
            }), {});
            return {
                ...state,
                ...interactionPolaroids,
            };
        case IMPORT_POLAROIDS_ACTION:
            return {
                ...state,
                ...action.polaroids.reduce((agg, p) => ({
                    ...agg,
                    [p.id]: p,
                }), {}),
            };
        case CREATE_POLAROID_ACTION:
            return Object.assign({}, state, {
                [action.id]: Object.assign({}, defaultPolaroidState, { imageUrl: action.imageUrl }),
            });
        case CHANGE_POLAROID_BORDER_COLOR:
            return Object.assign({}, state, {
                [action.id]: Object.assign({}, state[action.id], { borderColor: action.color }),
            });
        case CHANGE_POLAROID_NOTES:
            return Object.assign({}, state, {
                [action.id]: Object.assign({}, state[action.id], { notes: action.notes }),
            });
        case ADD_POLAROID_ATTRIBUTE:
            return Object.assign({}, state, {
                [action.polaroidId]: Object.assign({}, state[action.polaroidId], {
                    attributes: [...state[action.polaroidId].attributes, action.text],
                }),
            });
        case DELETE_POLAROID_ATTRIBUTE:
            return Object.assign({}, state, {
                [action.polaroidId]: Object.assign({}, state[action.polaroidId], {
                    attributes: state[action.polaroidId].attributes.filter((t, i) => i !== action.attributeNumber),
                }),
            });
        case UPDATE_POLAROID_ATTRIBUTE:
            return Object.assign({}, state, {
                [action.polaroidId]: Object.assign({}, state[action.polaroidId], {
                    attributes: state[action.polaroidId].attributes.map((text, i) => i === action.attributeNumber ? action.text : text),
                }),
            });
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    return state;
}
