import {css} from "@emotion/core";
import {AVENIR_FONT, bbPadding, DARK_GRAY, LIGHT_GRAY} from "../../../../style-variables";

export const instructions = css({
    width: "100%",
    height: 70,
    resize: "none",
    marginBottom: 6,
    boxSizing: "border-box",
    fontFamily: `${AVENIR_FONT}, sans-serif`,
});

export const materialsDescription = css({
    fontSize: "0.875em",
});

export const addMaterialContainer = css({
    height: 0,
    width: "100%",
    overflow: "hidden",
    position: "relative",
    transition: "height 0.3s, padding 0.3s, margin 0.3s, overflow 0.3s",
}, bbPadding("0 10px 0 10px"));
export const addMaterialContainerVisible = css({
    height: 90,
    margin: "0 0 5px 0",
    paddingTop: 5,
    overflow: "auto",
});

export const button = css({
    height: 30,
    width: "100%",
    margin: "0 0 10px 0",
    border: `1px solid ${DARK_GRAY}`,
    backgroundColor: "white",
    transition: "background-color 0.2s",
    cursor: "pointer",

    "&:hover": {
        backgroundColor: LIGHT_GRAY,
    },
});

export const materialButton = css({
    width: 60,
    height: 60,
    margin: "0 5px 0 0",
    backgroundColor: "white",
    border: `1px solid ${DARK_GRAY}`,
    transition: "background-color 0.2s",
    cursor: "pointer",

    "&:hover": {
        backgroundColor: LIGHT_GRAY,
    },
});
