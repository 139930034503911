import * as styles from './pea-plant-card-styles';
import React from 'react';
import {
    getPeaPlantCardDisplayMode,
    getPeaPlantCardDisplayTrait,
    getPeaPlantCardPlantId,
} from "../../store/peaPlantCards/selectors";
import {useSelector} from "react-redux";
import TraitBank from "./PlantCard/TraitBank";
import PlantDisplay from "./PlantCard/PlantDisplay";
import TraitPhenotypeDominanceMap from "../../constants/TraitPhenotypeDominanceMap";
import {getPeaPlantTraitPhenotype} from "../../store/peaPlants/selectors";
import {PHENOTYPE, PLANT} from "../../constants/peaPlantCardDisplayModes";
import Phenotype from '../../Utility/Phenotype';
import {css} from "@emotion/core";
import {
    PEA_PLANT_CARD_HEIGHT,
    PEA_PLANT_CARD_WIDTH,
    PHENOTYPE_CARD_HEIGHT,
    PHENOTYPE_CARD_WIDTH
} from "../../style-variables";

const getPlantId = cardId => state => getPeaPlantCardPlantId(state, cardId);
const getExpression = (plantId, trait) => state => trait
    ? TraitPhenotypeDominanceMap[trait][getPeaPlantTraitPhenotype(state, plantId, trait)]
    : null;

const SIZING = {
    [PLANT]: { width: PEA_PLANT_CARD_WIDTH, height: PEA_PLANT_CARD_HEIGHT },
    [PHENOTYPE]: { width: PHENOTYPE_CARD_WIDTH, height: PHENOTYPE_CARD_HEIGHT },
};

const PeaPlantCard = ({id, scaleFactor, large, forcePhenotype, plantCardId}) => {
    scaleFactor = scaleFactor || 1;
    large = large || false;
    const plantId = useSelector(getPlantId(id));
    let displayMode = useSelector(state => getPeaPlantCardDisplayMode(id, state));
    let displayTrait = useSelector(state => getPeaPlantCardDisplayTrait(id, state));
    if(forcePhenotype) {
        displayMode = PHENOTYPE;
        displayTrait = forcePhenotype;
    }
    const expression = useSelector(getExpression(plantId, displayTrait));

    const style = css({
        width: SIZING[displayMode].width * (large ? 2 : 1) * scaleFactor,
        height: SIZING[displayMode].height * (large ? 2 : 1) * scaleFactor,
    });

    return (
        <div css={style}>
            {displayMode === PLANT && <PlantDisplay plantId={plantId} />}
            {displayMode === PHENOTYPE && <Phenotype phenotype={expression} />}
            {large && <TraitBank plantCardId={id} />}
        </div>
    )
};

export default PeaPlantCard;
