import {css} from "@emotion/core";
import {headerButton, stylingButton} from "./editor-buttons-styles";

export const button = css(stylingButton, headerButton, {
    position: "relative",
});

export const selectorContainer = css({
    width: 160,
    padding: "1px 1px 0 1px",
    position: "absolute",
    left: "calc(100% + 1px)",
    top: "50%",
    boxShadow: "1px 1px 3px 0 rgba(0,0,0,0.5)",
    zIndex: 1,
});

export const headerSelector = css({
    width: "100%",
    display: "block",
    padding: 6,
    border: "none",
    borderBottom: "1px solid #ccc",
    backgroundColor: "white",
    transition: "background-color 0.2s",
    whiteSpace: "nowrap",
    textAlign: "left",
    cursor: "pointer",

    "&:hover": {
        backgroundColor: "#dfdfdf",
    },

    "& h1": {
        padding: 0,
        margin: 0,
        fontSize: "1.75em",
    },
    "& h2": {
        padding: 0,
        margin: 0,
        fontSize: "1.5em",
    },
    "& h3": {
        padding: 0,
        margin: 0,
        fontSize: "1.25em",
    },
});

export const activeHeader = css({
    backgroundColor: "#dfdfdf",
});

