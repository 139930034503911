import {
    getDogmaDashAvailableProteins,
    getDogmaDashCurrentView,
    getDogmaDashLevelUnlockingProtein,
    getDogmaDashPortalTrna, getDogmaDashTranscriptionNucleotides,
    getDogmaDashTranslationNucleotides
} from "../../interactions/dogmaDash/selectors";
import {isModalOpenFor} from "../../navigation/selectors";
import {FIRST_TIME_TRANSLATION} from "../../../constants/modalTypes";
import {TRANSLATION_VIEW} from "../../../constants/DogmaDash";
import {TRANSCRIPTION_CONTINUE_TUTORIAL} from "./transcription-strand-1";

export const TRANSLATION_STRAND_TUTORIAL = "translation strand tutorial";
export const TRANSLATION_INVENTORY_TUTORIAL = "translation inventory tutorial";
export const TRANSLATION_CONTINUE_TUTORIAL = "translation continue tutorial";

export default {
    id: '7c38650e-4f27-417d-a91b-8cbebc3a8a4b',
    items: [
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== TRANSLATION_STRAND_TUTORIAL || isModalOpenFor(state, FIRST_TIME_TRANSLATION)) { return false; }
                const availableProteins = getDogmaDashAvailableProteins(state, dogmaDashId).length;
                return !!getDogmaDashLevelUnlockingProtein(state, dogmaDashId) &&
                    (availableProteins === 1 || availableProteins === 2);
            },
            header: "Waiting on Drone Pilot",
            text: "Your partner in VR needs to <b>take the mRNA out of the nucleus and place it in the ribosome</b> before you can begin translation.",
            alert: true,
            completionCondition: (state, {dogmaDashId}) => getDogmaDashTranslationNucleotides(state, dogmaDashId).length > 0,
        },
        {// temp hack for double jump
            condition: (state, {location, dogmaDashId}) => location === TRANSLATION_CONTINUE_TUTORIAL,
            header: "",
            text: "",
            completionCondition: () => true,
        },
        {
            condition: (state, {location}) => location === TRANSLATION_STRAND_TUTORIAL,
            header: "2 Things About Translation",
            text: "1. You will be matching your mRNA strand with <b>tRNA</b> this time. Each tRNA has 3 nucleotides that code for an <b>amino acid</b>.",
            forceCompletion: (state, {dogmaDashId}) => !!getDogmaDashPortalTrna(state, dogmaDashId)
        },
        {
            condition: (state, {location}) => location === TRANSLATION_INVENTORY_TUTORIAL,
            header: "2 Things About Translation",
            text: "2. To send tRNA to your pilot, type in 3 nucleotides using the nucleotide buttons below. Once 3 are typed in, the corresponding amino acid will appear and you can drop it in the portal.",
            callToAction: "Drag and drop a finished tRNA into the Pilot Portal",
            completionCondition: (state, {dogmaDashId}) => {
                if(!dogmaDashId) {return false;}
                return !!getDogmaDashPortalTrna(state, dogmaDashId);
            },
        },
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== TRANSLATION_STRAND_TUTORIAL) {return false; }
                return !getDogmaDashPortalTrna(state, dogmaDashId);
            },
            header: "Drone Pilot's Turn",
            text: "The pilot can generate their own tRNA in VR. Let them figure out the next one.",
            callToAction: "Use the decoder to tell the pilot which nucleotides are needed for the next codon",
            completionCondition: (state, {dogmaDashId}) => {
                const translationStrand = getDogmaDashTranslationNucleotides(state, dogmaDashId);
                return !!translationStrand[4].paired;
            },
            alert: true,
        },
        {// temp hack for double jump
            condition: (state, {location, dogmaDashId}) => location === TRANSLATION_CONTINUE_TUTORIAL,
            header: "",
            text: "",
            completionCondition: () => true,
        },
        {
            condition: (state, {location, dogmaDashId}) => location === TRANSLATION_CONTINUE_TUTORIAL
                && getDogmaDashAvailableProteins(state, dogmaDashId).indexOf(getDogmaDashLevelUnlockingProtein(state, dogmaDashId)) >= 0,
            header: "Protein Complete",
            text: "Congratulations! You have successfully produced the protein!",
            callToAction: "Return to the cell to finish production",
            completionCondition: (state, {dogmaDashId}) => getDogmaDashCurrentView(state, dogmaDashId) !== TRANSLATION_VIEW,
        }
    ],
};
