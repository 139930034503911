import * as styles from "./nucleotide-styles";
import React from 'react';
import {css} from "@emotion/core";
import {ADENINE, CYTOSINE, GUANINE, NUCLEOTIDE_LETTER_MAP, THYMINE, URACIL} from "../../../../../constants/DogmaDash";

export const NucleotideStyleMap = {
    [ADENINE]: {
        nucleotide: styles.adenine,
        container: styles.fullSizeAdenineContainer,
        letter: styles.adenineHeader,
    },
    [CYTOSINE]: {
        nucleotide: styles.cytosine,
        container: styles.fullSizeCytosineContainer,
        letter: styles.cytosineHeader,
    },
    [GUANINE]: {
        nucleotide: styles.guanine,
        container: styles.fullSizeGuanineContainer,
        letter: styles.guanineHeader,
    },
    [THYMINE]: {
        nucleotide: styles.thymine,
        container: styles.fullSizeThymineContainer,
        letter: styles.thymineHeader,
    },
    [URACIL]: {
        nucleotide: styles.uracil,
        container: styles.fullSizeUracilContainer,
        letter: styles.uracilHeader,
    },
};

const Nucleotide = ({nucleotide, small, containerStyle, bottom, incorrect}) => {
    const styling = NucleotideStyleMap[nucleotide];
    const letterCss = !small ? styling.letter : css(styling.letter, {"&:first-of-type": {margin: 0}});
    const nucleotideCss = !bottom ? styling.nucleotide : css(styling.nucleotide, styles.nucleotideBottom);
    let containerCss = containerStyle || styling.container;
    if(incorrect) { containerCss = css(containerCss,{transform: "translateY(-8px) rotate(-8deg)"}); }

    return (
        <>
            {!bottom && <div css={letterCss}>{NUCLEOTIDE_LETTER_MAP[nucleotide]}</div>}
            <div css={containerCss}><div css={nucleotideCss} /></div>
            {bottom && <div css={letterCss}>{NUCLEOTIDE_LETTER_MAP[nucleotide]}</div>}
        </>
    )
};

export default Nucleotide;
