import {css} from "@emotion/core";
import {bbPadding, inlineBlockMiddle} from "../../style-variables";
import {dogmashBlue, dogmashOrange} from "../CurriculumTask/Interactables/DogmaDash/dogma-dash-styles";

export const root = css({
    position: 'fixed',
    zIndex: 20,
});

export const arrow = color => css({
    width: "30px",
    height: "30px",
    overflow: "hidden",
    position: 'absolute',
    bottom: "calc(100% + 5px)",

    "&:before": {
        content: '""',
        display: "block",
        width: "26px",
        height: "26px",
        backgroundColor: color,
        transform: "rotate(57deg) skew(25deg)",
        boxShadow: "0 -2px 3px 1px rgba(0, 0, 0, 0.5)",
        position: 'absolute',
        bottom: '-14px',
    },
});
export const upArrow = color => css(arrow(color), {
    bottom: "unset",
    top: 'calc(100% + 5px)',

    "&:before": {
        boxShadow: "0 2px 3px 1px rgba(0, 0, 0, 0.5)",
        bottom: "unset",
        top: '-14px',
        left: "2px",
    },
});
export const leftArrow = css({
    right: "22px",
});
export const leftHorizontalArrow = css({
    bottom: "unset",
    top: "30%",
    left: "calc(100% + 5px)",

    "&:before": {
        boxShadow: "2px 0 3px 1px rgba(0, 0, 0, 0.5)",
        left: '-17px',
        top: '4px',
        transform: "rotate(-34deg) skew(25deg)",
    },
});
export const rightArrow = css({
    left: "22px",
});
export const rightHorizontalArrow = css({
    bottom: "unset",
    top: "30%",
    right: "calc(100% + 5px)",

    "&:before": {
        boxShadow: "-2px 0 3px 1px rgba(0, 0, 0, 0.5)",
        right: '-17px',
        top: '4px',
        transform: "rotate(-34deg) skew(25deg)",
    },
});

export const container = (borderColor, width = 600) => css(bbPadding("30px"), {
    width: `${width}px`,
    background: "#002926",
    border: `5px solid ${borderColor}`,
    borderRadius: "14px 14px 0px 14px",
    position: 'absolute',
    textAlign: "left",
    textTransform: "none",
    whiteSpace: "normal",
});
export const containerDown = (borderColor, width) => css(container(borderColor, width), {
    top: "25px",
    boxShadow: "0 -2px 3px 1px rgba(0, 0, 0, 0.5)",
});
export const containerUp = (borderColor, width) => css(container(borderColor, width), {
    bottom: "25px",
    boxShadow: "0 2px 3px 1px rgba(0, 0, 0, 0.5)",
});
export const containerLeft = css({
    right: "-40px",
});
export const containerHorizontalLeft = css({
    top: 0,
    right: "21px",
    transform: "translateY(calc(-30% - 19px))",
    boxShadow: "2px 0 3px 1px rgba(0, 0, 0, 0.5)",
});
export const containerRight = css({
    left: "-40px",
});
export const containerHorizontalRight = css({
    top: 0,
    left: "21px",
    transform: "translateY(calc(-30% - 19px))",
    boxShadow: "-2px 0 3px 1px rgba(0, 0, 0, 0.5)",
});

export const headerContainer = css({
    margin: "0 0 13px 0",
});

export const headerIcon = color => css(inlineBlockMiddle, {
    margin: "0 8px 0 0",
    width: "28px",
    height: "28px",

    "& > svg": {
        width: "100%",
        height: "100%",
        fill: color,
    },
});

export const header = color => css(inlineBlockMiddle, {
    margin: 0,
    padding: 0,
    color,
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 700,
    fontSize: "1.75rem",
});

export const text = css({
    color: "#fff",
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 500,
    fontSize: "1.25rem",
    lineHeight: "1.3em",
});

export const callToAction = color => css(text, {
    color: color,
    marginTop: "26px",
    fontWeight: 700,
    margin: "30px 0 0 0",
});

export const continueButton = css({
    width: "160px",
    height: "56px",
    background: dogmashOrange,
    border: "none",
    color: "#fff",
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 600,
    fontSize: "1.75rem",
    cursor: "pointer",
    float: "right",
    margin: "0 -30px -30px 0",
});
