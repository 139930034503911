export const SET_UTILITY_BUTTON_BEHAVIOR = "Set utility button behavior";
export const setUtilityButtonBehavior = (buttonId, curriculum, behavior) => ({
    type: SET_UTILITY_BUTTON_BEHAVIOR,
    buttonId,
    curriculum,
    behavior,
});

export const SET_UTILITY_BUTTON_GLOBAL_ITEM_ID = "Set utility button global item id";
export const setUtilityButtonGlobalItemId = (buttonId, curriculum, globalItemId) => ({
    type: SET_UTILITY_BUTTON_GLOBAL_ITEM_ID,
    buttonId,
    curriculum,
    globalItemId,
});

export const SET_UTILITY_BUTTON_TEXT = "Set utility button text";
export const setUtilityButtonText = (buttonId, curriculum, text) => ({
    type: SET_UTILITY_BUTTON_TEXT,
    buttonId,
    curriculum,
    text,
});
