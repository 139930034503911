import {css} from "@emotion/core";
import {dogmashBlue} from "./dogma-dash-styles";
import {absoluteCenter, bbPadding, inlineBlockTop} from "../../../../style-variables";

export const container = css(bbPadding("22px 150px"), absoluteCenter, {
    border: `5px solid ${dogmashBlue}`,
    backgroundColor: "#082322",
    textAlign: 'center',
});

export const close = css(bbPadding("10px"), {
    width: "44px",
    height: "44px",
    position: 'absolute',
    background: dogmashBlue,
    border: "none",
    borderRadius: 0,
    top: 0,
    right: 0,
    cursor: "pointer",

    "& > svg": {
        stroke: "#082322",
        fill: "#082322",
    }
});

export const header = css({
    color: dogmashBlue,
    fontFamily: 'Rajdhani, sans-serif',
    fontWeight: 600,
    fontSize: "2rem",
    lineHeight: "1.25em",
    padding: 0,
    margin: "0 0 8px 0",
});

export const bodyText = css({
    fontFamily: "Rajdhani, sans-serif",
    color: "#fff",
    fontWeight: 600,
    fontSize: "1.25rem",
    lineHeight: "1.25em",
    margin: "0 0 16px 0",
});

export const videosContainer = css({
    margin: "0 0 30px 0",
    minWidth: "670px",
});

export const videoContainer = css(inlineBlockTop, {
    width: "330px",
    height: "190px",

    "&:nth-of-type(2n+1)": {
        margin: "0 10px 10px 0",
    },

    "& > iframe": {
        width: "100%",
        height: "100%",
        border: 'none',
    },
});

export const button = css(bbPadding("16px 26px"), {
    backgroundColor: "#082322",
    border: `1px solid ${dogmashBlue}`,
    color: dogmashBlue,
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 600,
    fontSize: "1.25rem",
    lineHeight: "1.25em",
    cursor: "pointer",
    transition: "background-color 0.2s, color 0.2s",

    "&:hover": {
        backgroundColor: dogmashBlue,
        color: "#082322",
    },
});
