import {DOGMA_DASH_INTERACTION} from "../../../constants/TaskTypes";
import {EMPTY_INTERACTIONS, INTERACTIONS_LOADED} from "../actions";
import {
    ADD_ITEM_TO_ORGANELLE,
    DOGMA_DASH_OBJECT_FORM_CHANGED,
    DOGMA_DASH_OBJECT_MOVED,
    DOGMA_DASH_OBJECT_REMOVED,
    REFRESH_DOGMA_DASH_INTERACTION_DATA,
    SET_MOTOR_PROTEIN_MOVEMENT_DATA
} from "./actions";
import {SET_AUTHENTICATION_STATE} from "../../identity/actions";
import {NOT_LOGGED_IN} from "../../../constants/AuthenticationState";

const defaultObjectState = {
    type: "Motor",
    form: "MRNA",
    movingTowards: 0,
    movementStartTime: null,
    movementDuration: null,
    destroyed: false,
};

export default (state = {}, action) => {
    if(action.type === INTERACTIONS_LOADED) {
        const loadedInteractions = action.interactions.filter(i => i.__typename === DOGMA_DASH_INTERACTION).reduce((agg, i) => ({
            ...agg,
            ...allObjectsFromInteraction(i),
        }), {});
        return {
            ...state,
            ...loadedInteractions,
        };
    }
    if(action.type === REFRESH_DOGMA_DASH_INTERACTION_DATA) {
        const loadedObjects = allObjectsFromInteraction(action.interactionData);
        return {
            ...loadedObjects,
        };
    }
    if(action.type === ADD_ITEM_TO_ORGANELLE) {
        const { objectId, itemType } = action;
        return {
            ...state,
            [objectId]: {
                ...defaultObjectState,
                type: itemType,
            },
        };
    }
    if(action.type === DOGMA_DASH_OBJECT_MOVED && !state[action.objectId]) {
        const {objectId, objectType, objectForm} = action;
        return {
            ...state,
            [objectId]: {
                ...defaultObjectState,
                type: objectType,
                form: objectForm,
            },
        };
    }
    if(action.type === DOGMA_DASH_OBJECT_REMOVED) {
        return {
            ...state,
            [action.objectId]: {
                ...state[action.objectId],
                destroyed: true,
            },
        };
    }
    if(action.type === DOGMA_DASH_OBJECT_FORM_CHANGED) {
        const {objectId, newForm} = action;
        return {
            ...state,
            [objectId]: {
                ...state[objectId],
                form: newForm
            },
        };
    }
    if(action.type === SET_MOTOR_PROTEIN_MOVEMENT_DATA) {
        const {id, movingTowards, movementStartTime, movementDuration} = action;
        return {
            ...state,
            [id]: {
                ...state[id],
                movingTowards,
                movementStartTime,
                movementDuration,
            },
        };
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    if(action.type === EMPTY_INTERACTIONS) {
        return {};
    }

    return state;
};

const allObjectsFromInteraction = interaction =>
    [
        ...interaction.dashMrnaSlots.reduce((agg, o) => o.heldObjects ? [...agg, ...o.heldObjects] : agg, []),
        ...interaction.dashMitochondria.reduce((agg, o) => o.heldObjects ? [...agg, ...o.heldObjects] : agg, []),
        ...interaction.dashCellMembrane.reduce((agg, o) => o.heldObjects ? [...agg, ...o.heldObjects] : agg, []),
        ...interaction.dashLysosome.reduce((agg, o) => o.heldObjects ? [...agg, ...o.heldObjects] : agg, []),
        ...interaction.dashGolgiBody.reduce((agg, o) => o.heldObjects ? [...agg, ...o.heldObjects] : agg, []),
        ...interaction.dashRibosome.reduce((agg, o) => o.heldObjects ? [...agg, ...o.heldObjects] : agg, []),
        ...interaction.dashMicrotubules.reduce((agg, o) => o.heldObjects ? [...agg, ...o.heldObjects] : agg, []),
        ...interaction.dashCentrioles.reduce((agg, o) => o.heldObjects ? [...agg, ...o.heldObjects] : agg, []),
        ...(interaction.dashCytoplasm.heldObjects || []),
    ].reduce((agg, o) => ({
        ...agg,
        [o.id]: {
            ...defaultObjectState,
            type: o.type,
            form: o.form,
            movingTowards: o.movingTowards,
            movementStartTime: o.movementStartTimeMs,
            movementDuration: o.movementDurationMs,
        },
    }), {});
