import {css} from "@emotion/core";

export const debrisContainer = css({
    display: "flex",
    flexDirection: "row",
});

export const debris = css({
    flex: "0 0 auto",
    cursor: "pointer",
});

export const tutorial = css({
    position: "absolute",
    top: "100%",
    left: "50%",
});
