import {MULTIPLE_CHOICE_INPUT} from "../../../constants/TaskTypes";
import {CURRICULA_LOADED} from "../../curricula/actions";
import {omit} from "lodash";
import {ADD_INTERACTABLE_TO_TASK, REMOVE_INTERACTABLE_FROM_TASK} from "../actions";
import {
    ADD_MULTIPLE_CHOICE_INPUT_OPTION,
    REMOVE_MULTIPLE_CHOICE_INPUT_OPTION,
    SET_MULTIPLE_CHOICE_INPUT_CORRECT_SELECTION,
    SET_MULTIPLE_CHOICE_INPUT_MULTISELECT,
    SET_MULTIPLE_CHOICE_INPUT_OPTION_TEXT, SET_MULTIPLE_CHOICE_INPUT_PROMPT
} from "./actions";

const defaultItem = {
    prompt: "",
    options: [],
    correctSelections: [],
    multiselect: false,
}

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const newInputs = action.curricula.reduce((agg, c) => ({
            ...agg,
            ...(c.tasks || []).reduce((agg, t) => ({
                ...agg,
                ...t.items.filter(i => i.__typename === MULTIPLE_CHOICE_INPUT).reduce((agg, i) => ({
                    ...agg,
                    [i.id]: {
                        ...defaultItem,
                        ...omit(i, '__typename'),
                    },
                }), agg),
            }), agg),
        }), {});
        return {
            ...state,
            ...newInputs,
        };
    }
    if(action.type === ADD_INTERACTABLE_TO_TASK && action.interactableType === MULTIPLE_CHOICE_INPUT) {
        return {
            ...state,
            [action.interactableId]: {
                ...defaultItem,
            },
        };
    }
    if(action.type === REMOVE_INTERACTABLE_FROM_TASK && state[action.interactableId]) {
        return omit(state, action.interactableId);
    }
    if(action.type === SET_MULTIPLE_CHOICE_INPUT_PROMPT) {
        const id = action.interactableId;
        return {
            ...state,
            [id]: {
                ...state[id],
                prompt: action.prompt,
            },
        };
    }
    if(action.type === ADD_MULTIPLE_CHOICE_INPUT_OPTION || action.type === SET_MULTIPLE_CHOICE_INPUT_OPTION_TEXT || action.type === REMOVE_MULTIPLE_CHOICE_INPUT_OPTION) {
        let {options: updatedOptions, correctSelections: updatedCorrect} = state[action.multipleChoiceId];
        if(action.type === ADD_MULTIPLE_CHOICE_INPUT_OPTION) {
            updatedOptions = updatedOptions.concat("");
        } else if(action.type === SET_MULTIPLE_CHOICE_INPUT_OPTION_TEXT) {
            updatedOptions = updatedOptions.map((o, i) => i === action.optionIndex ? action.text : o);
        } else if(action.type === REMOVE_MULTIPLE_CHOICE_INPUT_OPTION) {
            updatedOptions = updatedOptions.filter((o, i) => i !== action.optionIndex);
            updatedCorrect = updatedCorrect.filter(i => i !== action.optionIndex);
        }
        return {
            ...state,
            [action.multipleChoiceId]: {
                ...state[action.multipleChoiceId],
                options: updatedOptions,
                correctSelections: updatedCorrect,
            }
        };
    }
    if(action.type === SET_MULTIPLE_CHOICE_INPUT_CORRECT_SELECTION) {
        let {correctSelections: updatedCorrect} = state[action.multipleChoiceId];
        if(action.correct) {
            updatedCorrect = updatedCorrect.concat(action.optionIndex)
        } else {
            updatedCorrect = updatedCorrect.filter(index => index !== action.optionIndex);
        }
        return {
            ...state,
            [action.multipleChoiceId]: {
                ...state[action.multipleChoiceId],
                correctSelections: updatedCorrect,
            },
        };
    }
    if(action.type === SET_MULTIPLE_CHOICE_INPUT_MULTISELECT) {
        return {
            ...state,
            [action.multipleChoiceId]: {
                ...state[action.multipleChoiceId],
                multiselect: action.multiselect,
            },
        };
    }
    return state;
}
