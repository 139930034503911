import React from 'react';
import { Router } from "@reach/router";
import TeacherLogin from './Teacher'
import LearnerLogin from './Learner';

export default () => {
    return (
        <Router>
            <TeacherLogin path="teach"/>
            <LearnerLogin path="learn"/>
        </Router>
    );
}
