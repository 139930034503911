import {css} from "@emotion/core";
import grabbableCursor from '../../../assets/cursor_grabbable.png';
import grabbingCursor from '../../../assets/cursor_grabbing.png';
import {DRAGGABLE_SHADOW} from "../../../style-variables";

export const item = css({
    position: "absolute",
    zIndex: 0,
});
export const itemDraggable = css({
    cursor: `url(${grabbableCursor}) 16 4, auto !important`,
    boxShadow: DRAGGABLE_SHADOW,
});
export const itemDragging = css({
    cursor: `url(${grabbingCursor}) 16 4, auto !important`,
});
export const itemInDropArea = css({
    display: "none",
});

export const dropAreaItem = css({
    height: "100%",
    cursor: `url(${grabbableCursor}) 16 4, auto !important`,
});
export const itemFullWidth = css({
    width: "100%",
});
