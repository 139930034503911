import {DOGMA_DASH_MRNA_INVENTORY, DOGMA_DASH_NUCLEUS} from "./dogma-strand-1";
import {
    getDogmaDashAvailableProteins,
    getDogmaDashCurrentView,
    getDogmaDashLevelUnlockingProtein, getDogmaMicrotubuleIds
} from "../../interactions/dogmaDash/selectors";
import {Antibody, CELL_VIEW, DefaultCargo, Motor} from "../../../constants/DogmaDash";
import {mrnaInSlot, proteinTypeAtOrganelle} from "./utility";
import {isModalOpenFor} from "../../navigation/selectors";
import {DOGMA_HEALTH_WARNING} from "../../../constants/modalTypes";

export const MRNA_INVENTORY_PROTEIN_TUTORIAL = "mrna inventory protein location";
export const VIRAL_LOAD_TUTORIAL = "viral load tutorial location";

export default {
    id: "79942653-a375-4752-be1a-a186bdaa5c9b",
    items: [
        {
            condition: (state, {location, dogmaDashId}) => location === DOGMA_DASH_NUCLEUS
                && getDogmaDashLevelUnlockingProtein(state, dogmaDashId) === Antibody
                && !isModalOpenFor(state, DOGMA_HEALTH_WARNING),
            header: "Enter the Nucleus",
            callToAction: "Click on the nucleus to get access to the cell’s DNA",
            completionCondition: (state, {dogmaDashId}) => getDogmaDashCurrentView(state, dogmaDashId) !== CELL_VIEW || getDogmaDashAvailableProteins(state, dogmaDashId).indexOf(Antibody) >= 0,
        },
        {
            condition: (state, {location, dogmaDashId, protein}) => {
                if(location !== MRNA_INVENTORY_PROTEIN_TUTORIAL || protein !== Antibody) {return false;}
                return getDogmaDashAvailableProteins(state, dogmaDashId).indexOf(Antibody) >= 0;
            },
            width: 460,
            header: "Antibody mRNA",
            text: "You now have mRNA for a Pandoravirus Antibody.",
            forceCompletion: (state, {dogmaDashId}) => mrnaInSlot(state, dogmaDashId, Motor),
        },
        {
            condition: (state, {location, protein}) => location === MRNA_INVENTORY_PROTEIN_TUTORIAL && protein === Motor,
            width: 460,
            header: "Antibody mRNA",
            text: "Antibodies need to be sent out of the cell to neutralize viruses in your bloodstream. You need a <b>Delivery Protein</b> to carry them out.",
            callToAction: "Send your pilot a Delivery Protein mRNA",
            completionCondition: (state, {dogmaDashId}) => mrnaInSlot(state, dogmaDashId, Motor),
        },
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== VIRAL_LOAD_TUTORIAL) {return false;}
                return getDogmaMicrotubuleIds(state, dogmaDashId).some(m => proteinTypeAtOrganelle(state, m, DefaultCargo));
            },
            forceCompletion: () => true,
        },
        {
            condition: (state, {location}) => location === VIRAL_LOAD_TUTORIAL,
            header: "Viral Load",
            text: "Every time the pilot sends an antibody out of the cell the viral load decreases. <b>Keep the viral load as low as possible by producing antibodies</b>."
        },
    ],
};
