import {CURRICULA_LOADED} from "../curricula/actions";
import {SET_AUTHENTICATION_STATE} from "../identity/actions";
import {NOT_LOGGED_IN} from "../../constants/AuthenticationState";
import {
    ADD_SPACE_TO_SCROLLER_TASK,
    CREATE_CURRICULUM_TASK,
    CURRICULUM_ITEM_TO_BOTTOM,
    CURRICULUM_ITEM_TO_TOP,
    REORDER_CURRICULUM_ITEM,
    SET_CURRICULUM_TASK_CONTENT,
    SET_CURRICULUM_TASK_HEIGHT,
    SET_CURRICULUM_TASK_TITLE,
    SET_CURRICULUM_TASK_TYPE
} from "./actions";
import {ADD_INTERACTABLE_TO_TASK, REMOVE_INTERACTABLE_FROM_TASK} from "../curriculumTaskItems/actions";
import {SECTIONED_TASK} from "../../constants/TaskTypes";
import {ADD_ITEM_TO_SCROLLING_TASK} from "../scrollingTaskItemPositions/actions";

const defaultTask = {
    id: "",
    title: "",
    items: [],
    type: SECTIONED_TASK,
    height: 0,
};

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const loadedTasks = action.curricula.reduce((agg, c) => {
            c.tasks && c.tasks.forEach(t => {
                agg[t.id] = {
                    ...defaultTask,
                    ...t,
                    items: t.items.map(i => i.id),
                    type: t.__typename
                };
            });
            return agg;
        }, {});
        return Object.assign({}, state, loadedTasks);
    }
    if(action.type === CREATE_CURRICULUM_TASK) {
        return {
            ...state,
            [action.taskId]: {
                ...defaultTask,
                id: action.taskId,
                type: action.taskType,
            },
        };
    }
    if(action.type === SET_CURRICULUM_TASK_TITLE) {
        return Object.assign({}, state, {
            [action.taskId]: Object.assign({}, state[action.taskId], {
                title: action.title,
            }),
        });
    }
    if(action.type === SET_CURRICULUM_TASK_CONTENT) {
        return Object.assign({}, state, {
            [action.taskId]: Object.assign({}, state[action.taskId], {
                textContent: action.content,
            }),
        });
    }
    if(action.type === ADD_INTERACTABLE_TO_TASK) {
        return Object.assign({}, state, {
            [action.taskId]: Object.assign({}, state[action.taskId], {
                items: state[action.taskId].items.concat(action.interactableId),
            }),
        });
    }
    if(action.type === REMOVE_INTERACTABLE_FROM_TASK) {
        return {
            ...state,
            [action.taskId]: {
                ...state[action.taskId],
                items: state[action.taskId].items.filter(i => i !== action.interactableId),
            },
        };
    }
    if(action.type === SET_CURRICULUM_TASK_TYPE) {
        return {
            ...state,
            [action.taskId]: {
                ...state[action.taskId],
                type: action.taskType,
                items: [],
            },
        };
    }
    if(action.type === SET_CURRICULUM_TASK_HEIGHT) {
        return {
            ...state,
            [action.taskId]: {
                ...state[action.taskId],
                height: action.height,
            }
        };
    }
    if(action.type === ADD_SPACE_TO_SCROLLER_TASK) {
        return {
            ...state,
            [action.taskId]: {
                ...state[action.taskId],
                height: state[action.taskId].height + action.height,
            },
        };
    }
    if(action.type === ADD_ITEM_TO_SCROLLING_TASK) {
        return {
            ...state,
            [action.taskId]: {
                ...state[action.taskId],
                items: state[action.taskId].items.concat(action.itemId),
            },
        };
    }
    if(action.type === REORDER_CURRICULUM_ITEM) {
        const index = state[action.taskId].items.indexOf(action.itemId);
        if(index === 0 && action.direction === -1) { return state; }
        if(index === state[action.taskId].items.length - 1 && action.direction === 1) { return state; }
        const newItems = state[action.taskId].items.map((id, i) => i === index
            ? state[action.taskId].items[i + action.direction]
            : i === (index + action.direction)
            ? action.itemId
            : id);
        return {
            ...state,
            [action.taskId]: {
                ...state[action.taskId],
                items: newItems,
            },
        };
    }
    if(action.type === CURRICULUM_ITEM_TO_TOP) {
        const withoutItem = state[action.taskId].items.filter(id => id !== action.itemId);
        return {
            ...state,
            [action.taskId]: {
                ...state[action.taskId],
                items: withoutItem.concat(action.itemId),
            },
        };
    }
    if(action.type === CURRICULUM_ITEM_TO_BOTTOM) {
        const withoutItem = state[action.taskId].items.filter(id => id !== action.itemId);
        return {
            ...state,
            [action.taskId]: {
                ...state[action.taskId],
                items: [action.itemId].concat(withoutItem),
            },
        };
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    return state;
}
