import {css} from "@emotion/core";
import {bbPadding, FONT_HEAVY_WEIGHT, inlineBlockTop} from "../../../style-variables";

const shadow = "4px 3px 4px 0 rgba(0, 0, 0, 0.4)";

const buttonOuterMargin = 21;
export const trayHorizontalPadding = 60;
export const tileRightMargin = 12;
export const tileSpacing = 200 + tileRightMargin; // TODO: get rid of this.

export const tray = css({
    position: "absolute",
    top: "100%",
    left: 0,
    right: 0,
    transform: "translateY(-40px)",
    overflow: "hidden",
    transition: "transform 0.3s",

    "&:hover": {
        transform: "translateY(-100%)",
    }
});

export const trayTitle = css({
    display: "inline-block",
    fontSize: "1.125em",
    borderRadius: "5px 5px 0 0",
    backgroundColor: "#eee",
    boxShadow: shadow,
    margin: "0 0 0 60px",
}, bbPadding("2px 6px 6px 6px"));

export const trayContents = css({
    width: "100%",
    height: 155,
    position: "relative",
});

export const casesContainer = css({
    display: 'inline-flex',
    verticalAlign: "top",
    flexDirection: "column",
    width: "70%",
    height: "100%",
    backgroundColor: "#eee",
    overflow: "hidden",
});

export const resourceContainer = css({
    display: "inline-flex",
    verticalAlign: "top",
    flexDirection: "row",
    width: "30%",
    height: "100%",
    backgroundColor: "#f7f7f7",
}, bbPadding(`39px 25px`));

export const caseSelectionContainer = css({
    flex: "0 1 auto",
    margin: `10px 0 10px ${trayHorizontalPadding}px`,
});

export const caseButton = css(inlineBlockTop, {
    fontSize: "0.875em",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    padding: 0,

    "&:not(:last-of-type)": {
        marginRight: "20px",
    },
});
export const caseButtonSelected = css({
    fontWeight: FONT_HEAVY_WEIGHT,
});

export const caseContainer = css({
    flex: "1 1 auto",
    position: "relative",
});

const carouselButtonBase = css({
    width: 32,
    height: 32,
    padding: 0,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "#4A9D09",
    border: "none",
    borderRadius: "50%",
    cursor: "pointer",
    zIndex: 1,
    opacity: 0,
    transition: "opacity 0.2s",

    "&:not(:disabled):hover": {
        "&:before,&:after": {
            backgroundColor: "#eee",
        }
    },

    "&:disabled": {
        opacity: "0!important",
    },

    "&:before,&:after": {
        content: '""',
        display: "block",
        width: 2,
        height: 12,
        backgroundColor: "#fff",
        position: "absolute",
        top: "50%",
        transition: "background-color 0.2s",
    },
});
export const leftButton = css(carouselButtonBase, {
    left: buttonOuterMargin,

    "&:before": {
        left: "30%",
        transform: "translateY(-100%) translateY(1px) rotate(45deg)",
        transformOrigin: "50% 100%",
    },
    "&:after": {
        left: "30%",
        transform: "rotate(-45deg)",
        transformOrigin: "50% 0",
    }
});
export const rightButton = css(carouselButtonBase, {
    right: buttonOuterMargin,

    "&:before": {
        right: "30%",
        transform: "translateY(-100%) translateY(1px) rotate(-45deg)",
        transformOrigin: "50% 100%",
    },
    "&:after": {
        right: "30%",
        transform: "rotate(45deg)",
        transformOrigin: "50% 0",
    }
});

export const tileContainer = css({
    position: "absolute",
    height: "100%",
    bottom: 0,
    left: trayHorizontalPadding,
    whiteSpace: "nowrap",
    transition: "transform 0.3s",
});

export const tileLabel = css({
    fontWeight: FONT_HEAVY_WEIGHT,
    marginRight: 4,
});

export const tileTitleClass = "work-board-tray-tile-title";
export const tileTitle = css({
    textOverflow: "ellipsis",
});

export const tile = css({
    display: "inline-flex",
    verticalAlign: "top",
    flexDirection: "column",
    height: "100%",
    width: 200,
    backgroundColor: "transparent",
    border: "1px solid transparent",
    padding: 0,
    cursor: "pointer",
    position: "relative",
    fontSize: "0.75em",
    textAlign: "left",

    "&:not(:last-of-type)": {
        marginRight: tileRightMargin,
    },

    [`&:hover .${tileTitleClass}`]: {
        textDecoration: "underline",
    },
});
export const tileActive = css({
    [`& .${tileTitleClass}`]: {
        textDecoration: "underline",
    },
});

export const toolsContainer = css({
    backgroundColor: "#DFDBDB",
}, bbPadding("6px"));
