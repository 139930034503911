import React, {useRef, useEffect} from 'react';
import classNames from 'class-names';
import {useSelector, useDispatch} from "react-redux";
import {
    getTasksForActivity,
    getActivityTitle,
    isActivityComplete, getTaskHidden,
} from "../../store/curriculum/selectors";
import Task from "./Task";
import {selectActiveActivity} from "../../store/navigation/actions";
import {getSelectedActivity} from "../../store/navigation/selectors";

const stateMap = props => state => ({
    title: getActivityTitle(state, props.id),
    tasks: getTasksForActivity(state, props.id).filter(t => !getTaskHidden(state, t)),
    selected: getSelectedActivity(state) === props.id,
    complete: isActivityComplete(state, props.id),
});

const comparison = (left, right) => {
    if(left.title !== right.title) { return false; }
    if(left.selected !== right.selected) { return false; }
    if(left.complete !== right.complete) { return false; }
    if((!left.tasks && right.tasks) || (left.tasks && !right.tasks)) { return false; }
    else if(left.tasks.length !== right.tasks.length) { return false; }
    else if(!left.tasks.every((t, i) => t === right.tasks[i])) { return false; }
    return true;
};

export default React.memo(props => {
    const {title, tasks, selected, complete} = useSelector(stateMap(props), comparison);
    const dispatch = useDispatch();

    const select = () => dispatch(selectActiveActivity(props.id));

    const sizingRef = useRef();
    const animationRef = useRef();
    useEffect(() => {
        animationRef.current.style["max-height"] = selected ? sizingRef.current.offsetHeight + 20 : 0;
    });

    return (
        <div className={classNames({
            "curriculum-navigation--activity": true,
            "curriculum-navigation--activity__selected": selected,
            "curriculum-navigation--activity__complete": complete,
        })}>
            <h3 onClick={select} className="curriculum-navigation--activity-title">{title}</h3>
            <div ref={animationRef} className={classNames({
                "curriculum-navigation--activity-task-container": true,
                "curriculum-navigation--activity-task-container__expanded": selected,
            })}>
                <div ref={sizingRef}>
                    {tasks.map(id => <Task id={id} key={id} />)}
                </div>
            </div>
        </div>
    )
});
