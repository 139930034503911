export const SET_CENTRAL_DOGMA_INITIAL_STRAND_LENGTH = "set central dogma initial strand length";
export const setCentralDogmaInitialStrandLength = (curriculum, widgetId, length) => ({
    type: SET_CENTRAL_DOGMA_INITIAL_STRAND_LENGTH,
    curriculum,
    widget: widgetId,
    length
})

export const SET_CENTRAL_DOGMA_INITIAL_STRAND_NUCLEOTIDE = "set central dogma initial strand nucleotide";
export const setCentralDogmaInitialStrandNucleotide = (curriculum, widgetId, strandName, index, nucleotide) => ({
    type: SET_CENTRAL_DOGMA_INITIAL_STRAND_NUCLEOTIDE,
    curriculum,
    widget: widgetId,
    strandName,
    index,
    nucleotide,
});

export const SET_CENTRAL_DOGMA_MUTATION = "set central dogma mutation";
export const setCentralDogmaMutation = (curriculum, widgetId, strandName, index, nucleotide) => ({
    type: SET_CENTRAL_DOGMA_MUTATION,
    curriculum,
    widget: widgetId,
    strandName,
    index,
    nucleotide,
});

export const ADD_TARGET_AMINO_ACID_TO_CENTRAL_DOGMA_WIDGET = "add target amino acid to central dogma widget";
export const addTargetAminoAcidToCentralDogmaWidget = (curriculum, widgetId, aminoAcid) => ({
    type: ADD_TARGET_AMINO_ACID_TO_CENTRAL_DOGMA_WIDGET,
    curriculum,
    widget: widgetId,
    aminoAcid,
});

export const REMOVE_TARGET_AMINO_ACID_TO_CENTRAL_DOGMA_WIDGET = "remove target amino acid to central dogma widget";
export const removeTargetAminoAcidToCentralDogmaWidget = (curriculum, widgetId, index) => ({
    type: REMOVE_TARGET_AMINO_ACID_TO_CENTRAL_DOGMA_WIDGET,
    curriculum,
    widget: widgetId,
    index,
});
