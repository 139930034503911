import {
    FLOWER_COLOR,
    FLOWER_POSITION,
    HEIGHT,
    POD_COLOR,
    POD_SHAPE,
    SEED_COLOR,
    SEED_SHAPE
} from "../../constants/TraitNames";
import {DOMINANT_TRAIT, RECESSIVE_TRAIT} from "../../constants/Zygosities";

export const getPeaPlant = (state, id) => state.peaPlants[id];
export const getPeaPlantName = (state, plantId) => getPeaPlant(state, plantId).name;
export const getPeaPlantParentIds = (state, plantId) => {
    const plant = getPeaPlant(state, plantId);
    return [
        plant.parentA ? plant.parentA.id : null,
        plant.parentB ? plant.parentB.id : null,
    ];
}
export const getPeaPlantParentAName = (state, plantId) => {
    const plant = getPeaPlant(state, plantId);
    return plant.parentA ? plant.parentA.name : null;
};
export const getPeaPlantParentBName = (state, plantId) => {
    const plant = getPeaPlant(state, plantId);
    return plant.parentB ? plant.parentB.name : null;
};
export const getPeaPlantHeightPhenotype = (state, plantId) => phenotype(getPeaPlantHeightGenotype(state, plantId));
export const getPeaPlantFlowerColorPhenotype = (state, plantId) => phenotype(getPeaPlantFlowerColorGenotype(state, plantId));
export const getPeaPlantFlowerPositionPhenotype = (state, plantId) => phenotype(getPeaPlantFlowerPositionGenotype(state, plantId));
export const getPeaPlantSeedColorPhenotype = (state, plantId) => phenotype(getPeaPlantSeedColorGenotype(state, plantId));
export const getPeaPlantSeedShapePhenotype = (state, plantId) => phenotype(getPeaPlantSeedShapeGenotype(state, plantId));
export const getPeaPlantPodColorPhenotype = (state, plantId) => phenotype(getPeaPlantPodColorGenotype(state, plantId));
export const getPeaPlantPodShapePhenotype = (state, plantId) => phenotype(getPeaPlantPodShapeGenotype(state, plantId));
export const getPeaPlantHeightGenotype = (state, plantId) => getPeaPlant(state, plantId)[HEIGHT];
export const getPeaPlantFlowerColorGenotype = (state, plantId) => getPeaPlant(state, plantId)[FLOWER_COLOR];
export const getPeaPlantFlowerPositionGenotype = (state, plantId) => getPeaPlant(state, plantId)[FLOWER_POSITION];
export const getPeaPlantSeedColorGenotype = (state, plantId) => getPeaPlant(state, plantId)[SEED_COLOR];
export const getPeaPlantSeedShapeGenotype = (state, plantId) => getPeaPlant(state, plantId)[SEED_SHAPE];
export const getPeaPlantPodColorGenotype = (state, plantId) => getPeaPlant(state, plantId)[POD_COLOR];
export const getPeaPlantPodShapeGenotype = (state, plantId) => getPeaPlant(state, plantId)[POD_SHAPE];
export const getPeaPlantTraitPhenotype = (state, plantId, trait) => {
    switch(trait) {
        case HEIGHT:
                return getPeaPlantHeightPhenotype(state, plantId);
        case FLOWER_COLOR:
                return getPeaPlantFlowerColorPhenotype(state, plantId);
        case FLOWER_POSITION:
                return getPeaPlantFlowerPositionPhenotype(state, plantId);
        case SEED_COLOR:
                return getPeaPlantSeedColorPhenotype(state, plantId);
        case SEED_SHAPE:
                return getPeaPlantSeedShapePhenotype(state, plantId);
        case POD_COLOR:
                return getPeaPlantPodColorPhenotype(state, plantId);
        case POD_SHAPE:
                return getPeaPlantPodShapePhenotype(state, plantId);
    }
    return null;
}
export const getPeaPlantTraitGenotype = (state, plantId, trait) => {
    switch(trait) {
        case HEIGHT:
            return getPeaPlantHeightGenotype(state, plantId);
        case FLOWER_COLOR:
            return getPeaPlantFlowerColorGenotype(state, plantId);
        case FLOWER_POSITION:
            return getPeaPlantFlowerPositionGenotype(state, plantId);
        case SEED_COLOR:
            return getPeaPlantSeedColorGenotype(state, plantId);
        case SEED_SHAPE:
            return getPeaPlantSeedShapeGenotype(state, plantId);
        case POD_COLOR:
            return getPeaPlantPodColorGenotype(state, plantId);
        case POD_SHAPE:
            return getPeaPlantPodShapeGenotype(state, plantId);
    }
    return null;
};

const phenotype = trait => {
    if(!trait) return null;
    const combination = trait.AlleleA && trait.AlleleB;
    return combination ? RECESSIVE_TRAIT : DOMINANT_TRAIT;
};
