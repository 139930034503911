import {useDrop} from "react-dnd";
import {useEffect, useRef, useState} from "react";
import rearrangeArray from "./rearrangeArray";

// .collect will insert hoverItemId into the collected values
// REQUIREMENTS:
// Draggables in this droppable must have the "draggable" attribute and be immediate children. TODO: it'd be great to get rid of this dependency... might need to implement my own useDrag to just do it?
// Draggables must have an itemId and ref field in their item definition
// assumes children of the dropRef are in the correct order
export default (droppableSpec, draggableIds) => {
    const [placeholderIndex, setPlaceholderIndex] = useState(null);
    const dropRef = useRef();
    const previousPositionsRef = useRef({});
    const timeoutsRef = useRef({});

    // TODO: find a way to add a getPlaceholderIndex to monitor
    // NOTE: position determination is done using offsetLeft/offsetTop instead of the bounding rect to ignore transforms
    const [collection, setDropabbleRef] = useDrop({
        ...droppableSpec,
        drop: (item, monitor) => {
            droppableSpec.drop && droppableSpec.drop(item, monitor, placeholderIndex);
            setPlaceholderIndex(null);
        },
        hover: (item, monitor) => {
            droppableSpec.hover && droppableSpec.hover(item, monitor, placeholderIndex);
            if(!dropRef.current) { return; }
            if(placeholderIndex === null) { previousPositionsRef.current = {}; }
            if(!item.ref || !item.ref.current || !monitor.canDrop()) { return; }
            let {x, y} = monitor.getClientOffset();
            const {left: dropLeft, top: dropTop} = dropRef.current.getBoundingClientRect();
            x -= dropLeft;
            y -= dropTop;
            const {width: itemWidth} = item.ref.current.getBoundingClientRect();

            let hoverIndex = 0;
            let draggableIndex = -1;
            for(let i = 0; i < dropRef.current.children.length; ++i) {
                const child = dropRef.current.children[i];
                if(!child.getAttribute("draggable")) { continue; }
                draggableIndex++;
                const {width: childWidth} = child.getBoundingClientRect();
                if(child.offsetTop > y) { break; }
                const right = child.offsetLeft + childWidth;
                if(right - itemWidth > x) { continue; }
                hoverIndex = draggableIndex;
            }
            setPlaceholderIndex(hoverIndex);
        },
        collect: monitor => {
            const collected = droppableSpec.collect ? droppableSpec.collect(monitor, placeholderIndex) : {};
            if(!monitor.canDrop()) {
                setPlaceholderIndex(null);
                return collected;
            }
            const item = monitor.getItem();
            if(!item || !item.ref) { return collected; }
            return {
                ...collected,
                hoverItemId: item.itemId,
            };
        }
    });
    const setRef = r => {
        dropRef.current = r;
        setDropabbleRef(r);
    }

    const draggables = placeholderIndex === null ? draggableIds : rearrangeArray(draggableIds, collection.hoverItemId, placeholderIndex);
    useEffect(() => {
        if(!dropRef.current) { return; }
        let draggableIndex = 0;
        for(let i = 0; i < dropRef.current.children.length; ++i) {
            const child = dropRef.current.children[i];
            if(!child.getAttribute("draggable")) { continue; }
            let draggableId = draggables[draggableIndex++];
            if(!previousPositionsRef.current[draggableId]) {
                // from another list or first pass...
                previousPositionsRef.current[draggableId] = { left: child.offsetLeft, top: child.offsetTop };
                continue;
            }
            const {left: previousX, top: previousY} = previousPositionsRef.current[draggableId];
            const updatedPosition = {left: child.offsetLeft, top: child.offsetTop};
            const {left: currentX, top: currentY} = updatedPosition;
            if(currentX === previousX && currentY === previousY) { continue; }

            clearTimeout(timeoutsRef.current[draggableId]);
            child.style.transform = "";
            child.style.transition = "";
            //spectacular.
            timeoutsRef.current[draggableId] = setTimeout(() => {
                child.style.transform = `translate(${previousX - currentX}px, ${previousY - currentY}px)`;
                timeoutsRef.current[draggableId] = setTimeout(() => {
                    child.style.transition = `transform 0.35s ease`;
                    timeoutsRef.current[draggableId] = setTimeout(() => {
                        child.style.transform = "translate(0, 0)";
                        timeoutsRef.current[draggableId] = setTimeout(() => {
                            child.style.transform = "";
                            child.style.transition = "";
                            timeoutsRef.current[draggableId] = null;
                        }, 350);
                    }, 0);
                }, 0);
            },0);

            previousPositionsRef.current[draggableId] = updatedPosition;
        }
    }, [placeholderIndex]);

    return [collection, setRef, draggables];
}
