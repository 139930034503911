import * as styles from './viral-load-meter-styles';
import React, {useEffect, useRef} from 'react';
import {useSelector} from "react-redux";
import {
    getAccumulatedVirusForAlert, getDogmaDashAvailableProteins,
    getDogmaDashCytoplasm,
    getObjectsInOrganelle,
    getObjectType,
    getOrganelleTimerStart,
    getOrganelleTimerType
} from "../../../../store/interactions/dogmaDash/selectors";
import {Antibody, Virus} from "../../../../constants/DogmaDash";
import AlertIcon from './inline-assets/viral-load.svg';
import {VIRAL_LOAD_TUTORIAL} from "../../../../store/tutorial/configuration/dogma-strand-antibody-unlock";
import PopUpTutorial from "../../../Tutorial/PopUpTutorial";

const ViralLoadMeter = ({dogmaId}) => {
    const cytoId = useSelector(state => getDogmaDashCytoplasm(state, dogmaId));
    const hasAlert = useSelector(state => getOrganelleTimerStart(state, cytoId) && getOrganelleTimerType(state, cytoId) === "Alert");
    const numberOfVirus = useSelector(state => getObjectsInOrganelle(state, cytoId).filter(o => getObjectType(state, o) === Virus).length);
    const countOnAlert = useSelector(state => getAccumulatedVirusForAlert(state, dogmaId));
    const show = useSelector(state => numberOfVirus > 0 || getDogmaDashAvailableProteins(state, dogmaId).indexOf(Antibody) >= 0);
    const tutorialConfig = {location: VIRAL_LOAD_TUTORIAL, dogmaDashId: dogmaId};
    const barRef = useRef();

    useEffect(() => {
        if(!barRef.current) {return;}
        const maxVirus = countOnAlert * 2;
        const p = Math.min(1, numberOfVirus / maxVirus);
        barRef.current.style.width = `${p * 100}%`;
    }, [numberOfVirus]);

    return (
        <div css={styles.container}>
            <div css={styles.tutorial}><PopUpTutorial positionData={tutorialConfig} expandUp={true} /></div>
            {show && <div css={styles.meter}>
                <div ref={barRef} css={styles.bar}/>
                <div css={styles.marker}>
                    {hasAlert && <div css={styles.alertIcon}><AlertIcon/></div>}
                </div>
                <div css={styles.text}>Viral Load</div>
            </div>}
        </div>
    )
};

export default ViralLoadMeter;
