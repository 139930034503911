import * as styles from './protein-styles';
import React from 'react';
import {useDrag} from "react-dnd";
import {
    Antibody, Antibody2, DefaultCargo,
    EnergyProteinConvertor,
    EnergyProteinRotor,
    EnergyProteinStabilizer,
    Motor,
    ProteinForm_Denatured,
    ProteinForm_MRNA,
    ProteinForm_Protein,
    ProteinForm_ProteinInVesicle,
    Recycle,
    Structural,
    Transport
} from "../../../../constants/DogmaDash";
import AntibodyIcon from './inline-assets/antibody.svg';
import TransportIcon from './inline-assets/transport.svg';
import MotorIcon from './inline-assets/motor.svg';
import Energy1Icon from './inline-assets/energy1.svg';
import Energy2Icon from './inline-assets/energy2.svg';
import EnergyVesicleIcon from './inline-assets/energy-vesicle.svg';
import RecycleIcon from './inline-assets/recycler.svg';
import StructuralIcon from './inline-assets/structural.svg';
import StructuralVesicleIcon from './inline-assets/structural-vesicle.svg';
import {useSelector} from "react-redux";
import {isLysosomeEating} from "../../../../store/interactions/dogmaDash/selectors";
import {getOpenGlobalInteractive} from "../../../../store/navigation/selectors";

export const proteinDisplayName = (proteinType, proteinForm) => {
    if(proteinForm === ProteinForm_Denatured) { return "Debris"; }

    return (
        proteinType === EnergyProteinRotor ? (proteinForm === ProteinForm_ProteinInVesicle ? "Energy" : "Energy 1") :
        proteinType === EnergyProteinConvertor ? (proteinForm === ProteinForm_ProteinInVesicle ? "Energy" : "Energy 3") :
        proteinType === EnergyProteinStabilizer ? (proteinForm === ProteinForm_ProteinInVesicle ? "Energy" : "Energy 2") :
        proteinType === Motor ? "Delivery" :
        proteinType === Recycle ? "Recycler" :
        proteinType === Transport ? "Channel" :
        proteinType === Antibody2 ? "Antibody 2.0" :
        proteinType
    );
}

const Denatured = ({id}) => {
    const dogmaId = useSelector(state => getOpenGlobalInteractive(state)); // TODO: DANGEROUS!
    const beingEaten = useSelector(state => isLysosomeEating(state, dogmaId, id));
    return <div css={beingEaten ? styles.denaturedEaten : styles.denatured} />
};

export const ProteinIconMap = {
    [Antibody]: {
        [ProteinForm_MRNA]: AntibodyIcon,
        [ProteinForm_Protein]: AntibodyIcon,
        [ProteinForm_ProteinInVesicle]: AntibodyIcon,
        [ProteinForm_Denatured]: Denatured,
    },
    [Antibody2]: {
        [ProteinForm_MRNA]: AntibodyIcon,
        [ProteinForm_Protein]: AntibodyIcon,
        [ProteinForm_ProteinInVesicle]: AntibodyIcon,
        [ProteinForm_Denatured]: Denatured,
    },
    [Transport]: {
        [ProteinForm_MRNA]: TransportIcon,
        [ProteinForm_Protein]: TransportIcon,
        [ProteinForm_ProteinInVesicle]: TransportIcon,
        [ProteinForm_Denatured]: Denatured,
    },
    [Motor]: {
        [ProteinForm_MRNA]: MotorIcon,
        [ProteinForm_Protein]: MotorIcon,
        [ProteinForm_ProteinInVesicle]: MotorIcon,
        [ProteinForm_Denatured]: Denatured,
    },
    [EnergyProteinRotor]: {
        [ProteinForm_MRNA]: Energy1Icon,
        [ProteinForm_Protein]: Energy1Icon,
        [ProteinForm_ProteinInVesicle]: EnergyVesicleIcon,
        [ProteinForm_Denatured]: Denatured,
    },
    [EnergyProteinStabilizer]: {
        [ProteinForm_MRNA]: Energy2Icon,
        [ProteinForm_Protein]: Energy2Icon,
        [ProteinForm_ProteinInVesicle]: EnergyVesicleIcon,
        [ProteinForm_Denatured]: Denatured,
    },
    [Recycle]: {
        [ProteinForm_MRNA]: RecycleIcon,
        [ProteinForm_Protein]: RecycleIcon,
        [ProteinForm_ProteinInVesicle]: RecycleIcon,
        [ProteinForm_Denatured]: Denatured,
    },
    [Structural]: {
        [ProteinForm_MRNA]: StructuralIcon,
        [ProteinForm_Protein]: StructuralIcon,
        [ProteinForm_ProteinInVesicle]: StructuralVesicleIcon,
        [ProteinForm_Denatured]: Denatured,
    },
    [DefaultCargo]: {
        [ProteinForm_Denatured]: Denatured,
    },
};

export const DRAGGABLE_DOGMA_PROTEIN = "Dogma Dash Protein";

const Protein = ({type, showName = true, interactable = true, form = ProteinForm_MRNA, id}) => {
    const [_, dragRef, previewRef] = useDrag({
        item: {
            type: DRAGGABLE_DOGMA_PROTEIN,
            form: form,
            protein: type,
            id,
        },
    });
    const setDragRef = r => {
        if(!interactable) {return;}
        dragRef(r);
    };

    const IconTag = ProteinIconMap[type][form];

    return (
        <div ref={setDragRef} css={styles.container}>
            {showName && form === ProteinForm_Denatured && <div css={styles.debrisLabel}>Debris</div>}
            <div css={interactable ? styles.iconBlue : styles.iconYellow} ref={previewRef}>
                <IconTag id={id} />
            </div>
            {showName && form !== ProteinForm_Denatured && <div css={styles.label}>{proteinDisplayName(type)}</div>}
        </div>
    );
};

export default Protein;
