import * as styles from './phenotype-tile-styles';
import React, {useRef} from 'react';
import {useDrag} from "react-dnd";
import {PLANT_CARD, PLANT_PHENOTYPE} from "../../../constants/DropItemTypes";
import {useSelector} from "react-redux";
import {getPeaPlantTraitPhenotype} from "../../../store/peaPlants/selectors";
import TraitPhenotypeDominanceMap from "../../../constants/TraitPhenotypeDominanceMap";
import Phenotype from '../../../Utility/Phenotype';
import {getPeaPlantCardPlantId} from "../../../store/peaPlantCards/selectors";

const getPlantId = cardId => state => getPeaPlantCardPlantId(state, cardId);
const getExpression = (plantId, trait, state) =>
    TraitPhenotypeDominanceMap[trait][getPeaPlantTraitPhenotype(state, plantId, trait)];

export default props => {
    const {plantCardId, trait} = props;
    const plantId = useSelector(getPlantId(plantCardId));
    const expression = useSelector(getExpression.bind(null, plantId, trait));
    const ref = useRef();

    const [_, dragRef] = useDrag({
        item: {
            type: PLANT_PHENOTYPE,
            itemId: PLANT_CARD,
            plantCardId: plantCardId,
            draggableType: PLANT_CARD,
            metadata: { trait, phenotype: true },
            ref,
        },
    });
    const setRef = r => {
        dragRef(r);
        ref.current = r;
    };

    return (
        <div ref={setRef} css={styles.container}>
            <Phenotype phenotype={expression} />
        </div>
    );
}
