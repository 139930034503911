import {css} from "@emotion/core";
import {bbPadding, HEADER_HEIGHT, LIGHT_GRAY} from "../../style-variables";
import trash from '../../assets/trash.svg';

export const TASK_REMOVE_CLASS_NAME = "task-remove-button";

export const container = css(bbPadding(`${HEADER_HEIGHT+2}px 20px 0 20px`),{
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: `100%`,
});

export const navigationContainer = css({
    width: "100%",
    fontSize: "0.75em",
    marginBottom: 8,
    position: "fixed",
    background: 'white',
    top: 0,
    left: 0,
});

export const backLink = css({});

export const previewLink = css({
    float: "right",
});

export const nameContainer = css({
    flex: "0 0 auto",
    fontSize: "1.25em",
    marginBottom: 6,
});

export const typeContainer = css({
    width: 120,
    marginBottom: 4,

    "& select": {
        width: "100%",
    },
});

export const tasksTitle = css({
    flex: "0 0 auto",
    margin: "0 0 4px 0",
    padding: 0,
    fontSize: "1.125em",
});

export const tasksContainer = css({
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    overflow: "hidden",
});

export const tasksList = css({
    width: "20%",
    flex: "0 0 auto",
    overflowX: "hidden",
    overflowY: "auto",
    transition: "width 0.4s",
});
export const tasksListCollapsed = css({
    width: 0,
});

export const separator = css({
    flex: "0 0 auto",
    width: "14px",
    backgroundColor: "#dfdfdf",
    position: "relative",
    cursor: "pointer",

    "&:before": {
        display: "block",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
    }
});
export const separatorExpand = css({
    "&:before": {
        content: `">"`,
    },
});
export const separatorCollapse = css({
    "&:before": {
        content: `"<"`,
    },
});

export const task = css({
    display: "block",
    backgroundColor: "white",
    color: "black",
    position: "relative",
    textDecoration: "none",
    transition: "background-color 0.2s",

    "&:hover": {
        backgroundColor: LIGHT_GRAY,

        [`.${TASK_REMOVE_CLASS_NAME}`]: {
            opacity: 0.5,
        },
    }
}, bbPadding("8px 30px 8px 8px"));
export const taskSelected = css({
    backgroundColor: LIGHT_GRAY,

    [`.${TASK_REMOVE_CLASS_NAME}`]: {
        opacity: 1,
    },
});

export const taskName = css({
    overflow: "hidden",
    textOverflow: "ellipsis",
});

export const taskRemove = css({
    width: 18,
    height: 18,
    backgroundImage: `url(${trash})`,
    backgroundSize: "100% 100%",
    backgroundColor: "transparent",
    border: "none",
    position: "absolute",
    right: 8,
    top: "50%",
    transform: "translateY(-50%)",
    opacity: 0,
    transition: "opacity 0.2s",
    cursor: "pointer",

    "&:hover": {
        opacity: "1!important",
    },
});

export const addTask = css({
    width: "100%",
    backgroundColor: "white",
    border: `1px dashed ${LIGHT_GRAY}`,
    borderRightWidth: 0,
    transition: "background-color 0.2s",
    textAlign: "left",
    cursor: "pointer",

    "&:hover": {
        backgroundColor: LIGHT_GRAY,
    }
}, bbPadding("8px"));

export const createTaskContainer = bbPadding("20px 0 0 20px");

export const createTask = css({
    width: 200,
    height: 200,
    margin: "0 10px 0 0",
    backgroundColor: "white",
    border: `1px dashed #6f6f6f`,
    transition: "background-color 0.2s",
    cursor: "pointer",

    "&:hover": {
        backgroundColor: LIGHT_GRAY,
    },
});
