import React from 'react';
import classNames from 'class-names';
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {getTaskDescription, getTaskStatus, getTaskType} from "../../store/curriculum/selectors";
import {COMPLETE} from "../../constants/TaskStatus";
import {getTaskHovered} from "../../store/navigation/selectors";
import {endTaskHover, startTaskHover} from "../../store/navigation/actions";

const stateMap = props => state => ({
    description: getTaskDescription(state, props.id),
    type: getTaskType(state, props.id),
    finished: getTaskStatus(state, props.id) === COMPLETE,
    hovered: getTaskHovered(state, props.id),
});

export default React.memo(props => {
    const { description, type, finished, hovered } = useSelector(stateMap(props), shallowEqual);

    const dispatch = useDispatch();
    const startHover = () => dispatch(startTaskHover(props.id));
    const endHover = () => dispatch(endTaskHover(props.id));

    return (
        <div onMouseEnter={startHover} onMouseLeave={endHover} className={classNames({
            "curriculum-navigation--task": true,
            [`curriculum-navigation--task__${type}`]: true,
            "curriculum-navigation--task__finished": finished,
            "curriculum-navigation--task__highlit": hovered,
        })}>
            <div className="curriculum-navigation--task-description">{description}</div>
        </div>
    )
});
