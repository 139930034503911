import './learner-navigation.scss';
import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import LearnerButton from "./LearnerButton";
import {getCohortLearnerIds, getCohortName} from "../../store/cohorts/selectors";
import {getDataService} from "../../store/data/selectors";
import {subscribeToLearnersUpdated} from "../../graphql/VerdantLearner";
import {Link, useParams} from "@reach/router";
import {shallowUnorderedArrayCompare} from "../../utility";

const stateMap = props => state => ({
    learners: getCohortLearnerIds(state, props.cohortId),
    cohortName: getCohortName(state, props.cohortId),
    dataService: getDataService(state),
});

const compare = (a, b) => {
    if(a.cohortName !== b.cohortName) { return false; }
    return shallowUnorderedArrayCompare(a.learners, b.learners);
};

export default props => {
    const {cohortId} = useParams();
    const {learners, cohortName, dataService} = useSelector(stateMap(props), compare);

    useEffect(() => {
        const subscription = subscribeToLearnersUpdated(dataService, learners);
        return () => subscription.unsubscribe();
    });

    return (
        <div className="learner-navigation">
            <span>{"<"} <Link to={`/teach/cohort/${cohortId}`}>Back to Cohorts</Link></span>
            <h2 className="learner-navigation--cohort-name">{cohortName}</h2>
            <div className="learner-navigation--learner-list">
                {learners.map(l => <LearnerButton key={l} id={l} />)}
            </div>
        </div>
    )
};
