import {
    addDraggableToDraggableBankQuery,
    addDraggableToDraggableBankVariables,
    assignCurriculumToLearnerQuery,
    assignCurriculumToLearnerVariables,
    changeLearnerCurriculumProgressQuery,
    changeLearnerCurriculumProgressVariables,
    createCurriculumQuery,
    createCurriculumVariables,
    curriculaDifferenceQuery,
    curriculaDifferenceVariables,
    curriculumForLearnerQuery,
    curriculumForLearnerVariables,
    masterCurriculaQuery,
    reassignCurriculumToLearnerQuery,
    resetCurriculaForLearnerQuery,
    resetCurriculaForLearnerVariables,
    resolveAllCurriculumDifferencesQuery,
    resolveAllCurriculumDifferencesVariables,
    updateCPRContentQuery,
    updateCPRContentVariables,
    updateDraggableContentQuery,
    updateDraggableContentVariables,
    updateInputTableContentQuery,
    updateInputTableContentVariables,
    updateLongTextInputContentQuery,
    updateLongTextInputContentVariables,
    updateMultipleChoiceInputContentQuery,
    updateMultipleChoiceInputContentVariables,
    updatePeaPlantWorkBoardMaterialQuery,
    updatePeaPlantWorkBoardMaterialVariables
} from "./queries/curriculum";
import {parseVerdantLearner} from "./VerdantLearner";
import {curriculaLoaded} from "../store/curricula/actions";
import {getPeaPlant} from "../store/peaPlants/selectors";
import {interactionsLoaded} from "../store/interactions/actions";
import {learnerWorkBoardsLoaded} from "../store/learners/actions";
import {parseWorkBoard} from "./WorkBoard";

export const getMasterCurricula = (dataService) => {
    return dataService.sendGraphQL(masterCurriculaQuery)
        .then(data => {
            if(!data.masterCurricula) { return; }
            dataService.store.dispatch(curriculaLoaded(data.masterCurricula));
        });
};

export const getCurriculumForLearner = (dataService, curriculumId, learnerId) => {
    const variables = curriculumForLearnerVariables(curriculumId, learnerId);
    return dataService.sendGraphQL(curriculumForLearnerQuery, variables)
        .then(data => {
            if(!data.curriculumForLearner.curriculum) { return; }
            const {dispatch} = dataService.store;
            const workBoards = data.curriculumForLearner.interactions.filter(i => i.workBoard)
                .map(i => parseWorkBoard(i.workBoard, dispatch).workBoard);
            dispatch(learnerWorkBoardsLoaded(learnerId, workBoards));
            dispatch(interactionsLoaded(data.curriculumForLearner.interactions));
            dispatch(curriculaLoaded([data.curriculumForLearner.curriculum]));
        });
};

export const createCurriculum = (dataService, curriculumId, name) => {
    const variables = createCurriculumVariables(curriculumId, name);
    return dataService.sendGraphQL(createCurriculumQuery, variables);
};

export const updateLongTextInputContent = (dataService, curriculumId, longTextInputId, prompt) => {
    const variables = updateLongTextInputContentVariables(curriculumId, longTextInputId, prompt);
    return dataService.sendGraphQL(updateLongTextInputContentQuery, variables);
};

export const updatePeaPlantWorkBoardMaterial = (dataService, materialId, curriculumId, peaPlantId, providePeas, provideCard) => {
    const peaPlant = getPeaPlant(dataService.store.getState(), peaPlantId);
    const variables = updatePeaPlantWorkBoardMaterialVariables(materialId, curriculumId, peaPlant, providePeas, provideCard);
    return dataService.sendGraphQL(updatePeaPlantWorkBoardMaterialQuery, variables);
};

export const assignCurriculumToLearner = (dataService, learnerId, curriculumId) => {
    const variables = assignCurriculumToLearnerVariables(learnerId, curriculumId);
    return dataService.sendGraphQL(assignCurriculumToLearnerQuery, variables)
        .then(response => {
            if(!response) { return; }
            parseVerdantLearner(dataService, response.assignCurriculumToLearner.learner);
        });
};
export const reassignCurriculumToLearner = (dataService, learnerId, curriculumId) => {
    const variables = assignCurriculumToLearnerVariables(learnerId, curriculumId);
    return dataService.sendGraphQL(reassignCurriculumToLearnerQuery, variables)
        .then(response => {
            if(!response) { return; }
            parseVerdantLearner(dataService, response.reassignCurriculumToLearner.learner);
        });
};
export const resetCurriculaForLearner = (dataService, learnerId) => {
    const variables = resetCurriculaForLearnerVariables(learnerId);
    return dataService.sendGraphQL(resetCurriculaForLearnerQuery, variables);
};

export const changeLearnerCurriculumProgress = (dataService, learnerId, curriculumId, taskId, status) => {
    const variables = changeLearnerCurriculumProgressVariables(learnerId, curriculumId, taskId, status);
    return dataService.sendGraphQL(changeLearnerCurriculumProgressQuery, variables);
};

export const curriculaDifferences = (dataService, curriculumId, parentCurriculumId) => {
    const variables = curriculaDifferenceVariables(curriculumId, parentCurriculumId);
    return dataService.sendGraphQL(curriculaDifferenceQuery, variables);
};

export const resolveAllCurriculumDifferences = (dataService, curriculumId, parentCurriculumId) => {
    const variables = resolveAllCurriculumDifferencesVariables(curriculumId, parentCurriculumId);
    return dataService.sendGraphQL(resolveAllCurriculumDifferencesQuery, variables);
};

export const updateMultipleChoiceInputContent = (dataService, curriculumId, multipleChoiceId, prompt, options, correctSelections, multiselect) => {
    const variables = updateMultipleChoiceInputContentVariables(curriculumId, multipleChoiceId, prompt, options, correctSelections, multiselect);
    return dataService.sendGraphQL(updateMultipleChoiceInputContentQuery, variables);
};

export const updateInputTableContent = (dataService, curriculumId, inputTableId, prompt, numberOfColumns, data) => {
    const variables = updateInputTableContentVariables(curriculumId, inputTableId, prompt, numberOfColumns, data.map(c => JSON.stringify(c)));
    return dataService.sendGraphQL(updateInputTableContentQuery, variables);
};

export const addDraggableToDraggableBank = (dataService, curriculumId, draggableBankId, draggableId) => {
    const variables = addDraggableToDraggableBankVariables(curriculumId, draggableBankId, draggableId);
    return dataService.sendGraphQL(addDraggableToDraggableBankQuery, variables);
};

export const updateDraggableContent = (dataService, curriculumId, draggableId, content) => {
    const variables = updateDraggableContentVariables(curriculumId, draggableId, content);
    return dataService.sendGraphQL(updateDraggableContentQuery, variables);
};

export const updateCPRContent = (dataService, curriculumId, cprId, assessment) => {
    const variables = updateCPRContentVariables(curriculumId, cprId, assessment);
    return dataService.sendGraphQL(updateCPRContentQuery, variables);
}
