import {getCognitoAccessToken} from "../Identity/utility";

export default async (fileData, dispatch) => {
    const accessToken = await getCognitoAccessToken(dispatch);
    const formData = new FormData();
    formData.append("asset", fileData);
    const response = await fetch(IMAGE_UPLOAD_ENDPOINT, {
        method: "POST",
        headers: {
            "access_token": accessToken,
        },
        body: formData,
    });
    // todo: better error handling
    if(response.status !== 200) {
        console.error(response);
        return;
    }
    const {url} = await response.json();
    return url;
};
