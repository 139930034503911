import {
    CREATE_MASTER_CURRICULUM,
    CURRICULA_LOADED,
    REMOVE_TASK_FROM_CURRICULUM,
    SET_CURRICULUM_NAME, SET_CURRICULUM_TYPE
} from "./actions";
import {SET_AUTHENTICATION_STATE} from "../identity/actions";
import {NOT_LOGGED_IN} from "../../constants/AuthenticationState";
import {CREATE_CURRICULUM_TASK} from "../curriculumTask/actions";
import {COHORT_ASSIGNED_CURRICULA_LOADED, SET_ALL_COHORTS} from "../cohorts/actions";

export const defaultCurriculum = {
    id: "",
    name: "",
    tasks: [],
    type: "",
    master: false,
    parentCurriculum: "",
};

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const loadedCurricula = action.curricula.reduce((agg, c) => {
            agg[c.id] = Object.assign({}, state[c.id] || defaultCurriculum, c, {
                tasks: c.tasks ? c.tasks.map(t => t.id) : [],
            });
            return agg;
        }, {});
        return Object.assign({}, state, loadedCurricula);
    }
    if(action.type === SET_ALL_COHORTS) {
        const loadedCurricula = Object.keys(action.cohorts).reduce((agg, cId) => {
            action.cohorts[cId].curricula.forEach(c => {
                agg[c.id] = Object.assign({}, state[c.id] || defaultCurriculum, c);
            });
            return agg;
        }, {});
        return Object.assign({}, state, loadedCurricula);
    }
    if(action.type === COHORT_ASSIGNED_CURRICULA_LOADED) {
        const loadedCurricula = action.curricula.reduce((agg, c) => {
            agg[c.id] = Object.assign({}, state[c.id] || defaultCurriculum, c);
            return agg;
        }, {});
        return Object.assign({}, state, loadedCurricula);
    }
    if(action.type === CREATE_MASTER_CURRICULUM) {
        return Object.assign({}, state, {
            [action.id]: Object.assign({}, defaultCurriculum, {
                id: action.id,
                name: action.name,
            }),
        });
    }
    if(action.type === SET_CURRICULUM_NAME) {
        return Object.assign({}, state, {
            [action.curriculumId]: Object.assign({}, state[action.curriculumId], {
                name: action.name,
            }),
        });
    }
    if(action.type === SET_CURRICULUM_TYPE) {
        return {
            ...state,
            [action.curriculumId]: {
                ...state[action.curriculumId],
                type: action.curriculumType,
            },
        };
    }
    if(action.type === CREATE_CURRICULUM_TASK) {
        const curriculum = state[action.curriculumId];
        return Object.assign({}, state, {
            [action.curriculumId]: Object.assign({}, curriculum, {
                tasks: curriculum.tasks.concat(action.taskId),
            }),
        });
    }
    if(action.type === REMOVE_TASK_FROM_CURRICULUM) {
        const curriculum = state[action.curriculumId];
        return Object.assign({}, state, {
            [action.curriculumId]: Object.assign({}, curriculum, {
                tasks: curriculum.tasks.filter(t => t !== action.taskId),
            }),
        });
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    return state;
}
