const getLtik = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const ltik = searchParams.get('ltik')
    if (!ltik) throw new Error('Missing lti key.')
    return ltik
};

const LtiDeepLink = () => {
    const setCurriculum = async curriculumId => {
        try {
            const response = await fetch("/lti/deeplink", {
                method: "post",
                headers: {
                    Authorization: `Bearer ${getLtik()}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: "include",
                body: JSON.stringify({
                    title: "Nanocell",
                    curriculumId,
                })
            });
            const form = await response.text();

            document.body.append(document.createRange().createContextualFragment(form));
        } catch(e) {
            console.error(e);
        }
    }

    return (
        <div>
            <h1>Curriculum Selection</h1>
            <button onClick={() => setCurriculum("a5b1aaba-6fe4-40cb-ab45-95465762aa09")}>Nano</button>
        </div>
    )
};

export default LtiDeepLink;
