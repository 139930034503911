import {call, select, takeEvery} from 'redux-saga/effects';
import {DRAGGABLE_PLACED_IN_DROP_AREA, REMOVE_DRAGGABLE_FROM_DROP_AREA} from "../../../store/interactions/actions";
import {getActiveLearner} from "../../../store/navigation/selectors";
import {getDraggableMetadata, getDraggablesInDropArea, getDraggableType} from "../../../store/interactions/selectors";
import {sendGraphQLSaga} from "../../DataService";
import {getDropAreaType} from "../../../store/workBoards/selectors";
import {WORK_BOARD} from "../../../constants/WorkBoardItemTypes";
import {WORK_BOARD_CROSS_CARD, WORK_BOARD_DROP_SLOT} from "../../../constants/TaskTypes";
import {setCrossCardCards} from "./crossCard";
import {updateWorkBoardItemOrder} from "./workBoard";

const query = `mutation SetDraggablesPosition($input: SetDraggablesPositionInput!) {
    setDraggablesPosition(input: $input) {
        interaction {
            interactable {
                id
            }
        }
    }
}`;

function* execute(action) {
    const learnerId = yield select(state => getActiveLearner(state));
    if(!learnerId) { return; }
    const {dropAreaId, curriculumId} = action;

    yield call(setDropAreaDraggableItems, dropAreaId, learnerId, curriculumId);
    if(action.startingLocation === dropAreaId) { return; }
    const itemType = yield select(state => getDropAreaType(state, action.startingLocation));
    if(itemType === WORK_BOARD) {
        yield call(updateWorkBoardItemOrder, action.startingLocation);
    } else if(itemType === WORK_BOARD_DROP_SLOT) {
        yield call(setDropAreaDraggableItems, action.startingLocation, learnerId, action.curriculumId);
    } else if(itemType === WORK_BOARD_CROSS_CARD) {
        yield call(setCrossCardCards, action.startingLocation, learnerId, action.curriculumId);
    }
}
export function* setDropAreaDraggableItems(dropAreaId, learnerId, curriculumId) {
    const draggables = yield select(state => getDraggablesInDropArea(state, dropAreaId).map(id => ({
        id,
        type: getDraggableType(dropAreaId, id, state),
        metadata: JSON.stringify(getDraggableMetadata(dropAreaId, id, state)),
    })));
    const variables = {
        input: {
            learner: learnerId,
            curriculum: curriculumId,
            dropArea: dropAreaId,
            draggables,
        },
    };
    yield call(sendGraphQLSaga, query, variables);
}

// TODO: call when items are moved.

export default [
    takeEvery(DRAGGABLE_PLACED_IN_DROP_AREA, execute),
    takeEvery(REMOVE_DRAGGABLE_FROM_DROP_AREA, execute),
];
