export const SELECT_ACTIVE_LEARNER = "Select active learner";
export const selectActiveLearner = learnerId => ({
    type: SELECT_ACTIVE_LEARNER,
    learnerId
});

export const SELECT_ACTIVE_ACTIVITY = "Select active activity";
export const selectActiveActivity = activityId => ({
    type: SELECT_ACTIVE_ACTIVITY,
    activityId,
});

export const SELECT_ACTIVE_TOPIC = "Select active topic";
export const selectActiveTopic = topicId => ({
    type: SELECT_ACTIVE_TOPIC,
    topicId,
});

export const SET_VR_BLOCKER_SHOWING = "Set VR blocker showing";
export const setVRBlockerShowing = (showBlocker) => ({
    type: SET_VR_BLOCKER_SHOWING,
    showBlocker,
});

export const START_TASK_HOVER = "Start task hover";
export const startTaskHover = taskId => ({
    type: START_TASK_HOVER,
    task: taskId,
});

export const END_TASK_HOVER = "End task hover";
export const endTaskHover = taskId => ({
    type: END_TASK_HOVER,
    task: taskId,
});

export const ESC_PRESSED = "Esc pressed";
export const escPressed = () => ({
    type: ESC_PRESSED,
});

export const SHOW_RESET_CURRICULUM = "show reset curricula";
export const showResetCurriculum = () => ({
    type: SHOW_RESET_CURRICULUM,
});

export const SET_CURRENT_CURRICULUM = "Set current curricula";
export const setCurrentCurriculum = curriculumId => ({
    type: SET_CURRENT_CURRICULUM,
    curriculum: curriculumId,
});

export const OPEN_MODAL_FOR = "Open modal for";
export const openModalFor = (modalType = null, id = null, additionalData) => ({
    type: OPEN_MODAL_FOR,
    modalType,
    id,
    additionalData,
});

export const CLOSE_MODAL_TYPE = "close modal type";
export const closeModalType = (modalType) => ({
    type: CLOSE_MODAL_TYPE,
    modalType,
});

export const TOGGLE_EXPLAINER_EVIDENCE_SELECTED = "toggle explainer evidence selected";
export const toggleExplainerEvidenceSelected = (evidenceId) => ({
    type: TOGGLE_EXPLAINER_EVIDENCE_SELECTED,
    evidenceId,
});

export const SET_LEARNER_CURRICULUM_LOADED_FOR_TEACHER = "set learner curriculum loaded";
export const setLearnerCurriculumLoadedForTeacher = (learnerId, curriculumId) => ({
    type: SET_LEARNER_CURRICULUM_LOADED_FOR_TEACHER,
    learnerId,
    curriculumId,
});

export const SET_GLOBAL_INTERACTIVE_OPEN = "set global interactive open";
export const setGlobalInteractiveOpen = interactiveId => ({
    type: SET_GLOBAL_INTERACTIVE_OPEN,
    interactiveId,
});

export const LOAD_CURRICULUM_FOR_LEARNER = "load curriculum for learner";
export const loadCurriculumForLearner = (curriculumId, learnerId) => ({
    type: LOAD_CURRICULUM_FOR_LEARNER,
    curriculumId,
    learnerId,
});
