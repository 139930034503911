import React from 'react';
import {css} from "@emotion/core";

export const EDITOR_COMPONENT_HEADER = "HEADER";

const fontSizeMap = {
    1: "2em",
    2: "1.625em",
    3: "1.375em",
};

export default ({attributes, children, element}) => {
    const {headerType} = element;

    const style = css({
        fontSize: fontSizeMap[headerType],
    });

    return (
        <>
        {headerType === 1 && <h1 {...attributes} css={style}>{children}</h1>}
        {headerType === 2 && <h2 {...attributes} css={style}>{children}</h2>}
        {headerType === 3 && <h3 {...attributes} css={style}>{children}</h3>}
        </>
    );
}
