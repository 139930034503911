import React from 'react';
import {shallowEqual, useSelector} from "react-redux";
import {getCurriculumTaskItemIds, getCurriculumTaskTitle} from "../../store/curriculumTask/selectors";

export const EDITOR_COMPONENT_LONG_TEXT_REFERENCE = "LONG_TEXT_REFERENCE";

const stateMap = props => state => ({
    taskTitle: getCurriculumTaskTitle(state, props.taskId),
    longTextIndex: getCurriculumTaskItemIds(state, props.taskId).indexOf(props.interactableId),
});

export default ({attributes, children, element}) => {
    const { taskTitle, longTextIndex } = useSelector(stateMap(element), shallowEqual);

    return (
        <span {...attributes}>
            <span className="rich-editor-components--long-text-reference" contentEditable={false}>
                {`Response to: ${taskTitle} long text ${longTextIndex+1}`}
            </span>
            {children}
        </span>
    );
};
