import * as styles from './work-board-styles';
import {interactableContainer} from "./style-variables";
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getWorkBoardIntroduction,
    getWorkBoardMaterials,
    getWorkBoardUsesTemplate
} from "../../../../store/curriculumTaskItems/workBoardDefinition/selectors";
import uuid from 'uuid/v4';
import {
    addPeaPlantMaterialToWorkBoard,
    setWorkBoardInstructions,
    setWorkBoardUsesTemplate
} from "../../../../store/curriculumTaskItems/workBoardDefinition/actions";
import {createPeaPlant} from "../../../../store/peaPlants/actions";
import WorkBoardMaterial from './WorkBoardMaterials';
import combineStyles from "../../../../Utility/combineStyles";
import WorkBoardTemplate from './WorkBoardTemplates';
import {useParams} from "@reach/router";

export default ({id}) => {
    const {curriculumId} = useParams();
    const instructions = useSelector(state => getWorkBoardIntroduction(id, state));
    const materials = useSelector(getWorkBoardMaterials.bind(null, id));
    const usesTemplate = useSelector(getWorkBoardUsesTemplate.bind(null, id));

    const [showAddMaterial, setShowAddMaterial] = useState(false);
    useEffect(() => setShowAddMaterial(false), [id]);
    const dispatch = useDispatch();

    const addPeaPlantMaterial = () => {
        const peaPlantId = uuid();
        dispatch(createPeaPlant(peaPlantId));
        dispatch(addPeaPlantMaterialToWorkBoard(curriculumId, id, uuid(), peaPlantId));
        setShowAddMaterial(false);
    };

    const updateInstructions = e => {
        dispatch(setWorkBoardInstructions(curriculumId, id, e.target.value));
    }
    const useTemplate = () => dispatch(setWorkBoardUsesTemplate(curriculumId, id, true));

    const toggleAddMaterial = () => setShowAddMaterial(!showAddMaterial);

    return (
        <div css={interactableContainer}>
            <div>
                <div css={styles.materialsDescription}>Work Board instructions:</div>
                <textarea css={styles.instructions} onBlur={updateInstructions} defaultValue={instructions} />
                <div css={styles.materialsDescription}>Materials for this work board:</div>
                {materials.map(m => <WorkBoardMaterial key={m.id} material={m} workBoardDefinition={id} curriculum={curriculumId} />)}
                <div css={combineStyles(styles, {
                    addMaterialContainer: true,
                    addMaterialContainerVisible: showAddMaterial,
                })}>
                    <div className="add-material--description">Choose a material to add</div>
                    <button onClick={addPeaPlantMaterial} css={styles.materialButton}>
                        <span>Pea Plant</span>
                    </button>
                </div>
                <button onClick={toggleAddMaterial} css={styles.button}>Add material</button>
                {!usesTemplate && <button onClick={useTemplate} css={styles.button}>Create Template</button>}
                {usesTemplate && <WorkBoardTemplate workBoard={id} />}
            </div>
        </div>
    )
};
