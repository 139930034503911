import "./pea-plant-material.scss";
import React, {useEffect} from 'react';
import {useSelector, useDispatch, shallowEqual} from "react-redux";
import {
    getPeaPlantFlowerColorGenotype,
    getPeaPlantFlowerPositionGenotype,
    getPeaPlantHeightGenotype,
    getPeaPlantName,
    getPeaPlantPodColorGenotype,
    getPeaPlantPodShapeGenotype,
    getPeaPlantSeedColorGenotype,
    getPeaPlantSeedShapeGenotype
} from "../../../../../store/peaPlants/selectors";
import InlineEditor from "../../../../../Utility/InlineEditor";
import {setPlantGenotype, setPlantName} from "../../../../../store/peaPlants/actions";
import {
    FLOWER_COLOR,
    FLOWER_POSITION,
    HEIGHT,
    POD_COLOR, POD_SHAPE,
    SEED_COLOR,
    SEED_SHAPE
} from "../../../../../constants/TraitNames";
import {
    setPeaPlantMaterialProvideCard,
    setPeaPlantMaterialProvidePeas
} from "../../../../../store/curriculumTaskItems/workBoardDefinition/actions";
import {updatePeaPlantWorkBoardMaterial} from "../../../../../graphql/Curriculum";
import {debounce} from 'lodash';
import {getDataService} from "../../../../../store/data/selectors";

const stateMap = props => state => {
    const peaPlantId = props.material.peaPlant;
    return {
        dataService: getDataService(state),
        plantId: peaPlantId,
        plantName: getPeaPlantName(state, peaPlantId),
        height: getPeaPlantHeightGenotype(state, peaPlantId),
        flowerColor: getPeaPlantFlowerColorGenotype(state, peaPlantId),
        flowerPosition: getPeaPlantFlowerPositionGenotype(state, peaPlantId),
        seedColor: getPeaPlantSeedColorGenotype(state, peaPlantId),
        seedShape: getPeaPlantSeedShapeGenotype(state, peaPlantId),
        podColor: getPeaPlantPodColorGenotype(state, peaPlantId),
        podShape: getPeaPlantPodShapeGenotype(state, peaPlantId),
    }
};

const updateMaterialPlant = (dataService, materialId, curriculumId, peaPlantId) => {
    updatePeaPlantWorkBoardMaterial(dataService, materialId, curriculumId, peaPlantId, null, null);
};
const debouncedUpdateMaterialPlant = debounce(updateMaterialPlant, 2000);
const updateMaterialWithoutPlant = (dataService, materialId, curriculumId, provideCard, providePeas) => {
    updatePeaPlantWorkBoardMaterial(dataService, materialId, curriculumId, null, provideCard, providePeas);
};
const debouncedUpdateMaterialWithoutPlant = debounce(updateMaterialWithoutPlant, 2000);

export default props => {
    const { curriculum, workBoardDefinition } = props;
    const { provideCard, providePeas, id } = props.material;
    const {
        dataService,
        plantId,
        plantName,
        height,
        flowerColor,
        flowerPosition,
        seedColor,
        seedShape,
        podColor,
        podShape,
    } = useSelector(stateMap(props), shallowEqual);
    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            debouncedUpdateMaterialPlant.flush();
            debouncedUpdateMaterialWithoutPlant.flush();
        };
    }, [props.workBoardDefinition]);

    const setName = name => {
        dispatch(setPlantName(plantId, name));
        debouncedUpdateMaterialPlant(dataService, id, curriculum, plantId);
    };
    const setGenotype = (traitName, genotype) => {
        dispatch(setPlantGenotype(plantId, traitName, genotype));
        debouncedUpdateMaterialPlant(dataService, id, curriculum, plantId);
    };
    const setProvideCard = e => {
        dispatch(setPeaPlantMaterialProvideCard(curriculum, workBoardDefinition, id, e.target.checked));
        debouncedUpdateMaterialWithoutPlant(dataService, id, curriculum, e.target.checked, providePeas);
    };
    const setProvidePeas = e => {
        dispatch(setPeaPlantMaterialProvidePeas(curriculum, workBoardDefinition, id, e.target.checked));
        debouncedUpdateMaterialWithoutPlant(dataService, id, curriculum, provideCard, e.target.checked);
    };

    return (
        <div className="work-board-definition--pea-plant-material">
            <div className="pea-plant-material--left">
                <div className="pea-plant-material--name-container">
                    <InlineEditor setName={setName} name={plantName || "Unnamed plant"} />
                </div>
                <div className="pea-plant-material--provide-peas-container">
                    <label htmlFor="should-provide-peas" className="pea-plant-material--provide-peas-label">Provide Peas?</label>
                    <input id="should-provide-peas" className="pea-plant-material--provide-peas-input" checked={providePeas} type="checkbox" onChange={setProvidePeas} />
                </div>
                <div className="pea-plant-material--provide-card-container">
                    <label htmlFor="should-provide-card" className="pea-plant-material--provide-card-label">Provide Card?</label>
                    <input id="should-provide-card" className="pea-plant-material--provide-card-input" checked={provideCard} type="checkbox" onChange={setProvideCard} />
                </div>
            </div>
            <div className="pea-plant-material--right">
                <div className="pea-plant-material--genotype-header">Pea plant genotype</div>
                <div className="pea-plant-material--trait-container">
                    <label htmlFor="height-genotype" className="trait-genotype--label">Height:</label>
                    <GenotypeSelector genotype={height} updateGenotype={setGenotype.bind(null, HEIGHT)} id="height-genotype" />
                </div>
                <div className="pea-plant-material--trait-container">
                    <label htmlFor="flower-color-genotype" className="trait-genotype--label">Flower Color:</label>
                    <GenotypeSelector genotype={flowerColor} updateGenotype={setGenotype.bind(null, FLOWER_COLOR)} id="flower-color-genotype" />
                </div>
                <div className="pea-plant-material--trait-container">
                    <label htmlFor="flower-position-genotype" className="trait-genotype--label">Flower Position:</label>
                    <GenotypeSelector genotype={flowerPosition} updateGenotype={setGenotype.bind(null, FLOWER_POSITION)} id="flower-position-genotype" />
                </div>
                <div className="pea-plant-material--trait-container">
                    <label htmlFor="seed-color-genotype" className="trait-genotype--label">Pea color:</label>
                    <GenotypeSelector genotype={seedColor} updateGenotype={setGenotype.bind(null, SEED_COLOR)} id="seed-color-genotype" />
                </div>
                <div className="pea-plant-material--trait-container">
                    <label htmlFor="seed-shape-genotype" className="trait-genotype--label">Pea shape:</label>
                    <GenotypeSelector genotype={seedShape} updateGenotype={setGenotype.bind(null, SEED_SHAPE)} id="seed-shape-genotype" />
                </div>
                <div className="pea-plant-material--trait-container">
                    <label htmlFor="pod-color-genotype" className="trait-genotype--label">Pod color:</label>
                    <GenotypeSelector genotype={podColor} updateGenotype={setGenotype.bind(null, POD_COLOR)} id="pod-color-genotype" />
                </div>
                <div className="pea-plant-material--trait-container">
                    <label htmlFor="pod-shape-genotype" className="trait-genotype--label">Pod shape:</label>
                    <GenotypeSelector genotype={podShape} updateGenotype={setGenotype.bind(null, POD_SHAPE)} id="pod-shape-genotype" />
                </div>
            </div>
        </div>
    )
};


const RECESSIVE = "Recessive";
const DOMINANT = "Dominant";
const HETEROZYGOUS = "Heterozygous";
const parseGenotype = genotype => genotype.AlleleA && genotype.AlleleB
    ? RECESSIVE
    : genotype.AlleleA || genotype.AlleleB
        ? HETEROZYGOUS
        : DOMINANT;

const GenotypeSelector = props => {
    const changed = e => {
        switch(e.target.value) {
            case DOMINANT: return props.updateGenotype({ AlleleA: 0, AlleleB: 0 });
            case HETEROZYGOUS: return props.updateGenotype({ AlleleA: 1, AlleleB: 0 });
            case RECESSIVE: return props.updateGenotype({ AlleleA: 1, AlleleB: 1 });
        }
    };

    return (
        <select id={props.id} value={parseGenotype(props.genotype)} onChange={changed} className="trait-genotype--select">
            <option>{DOMINANT}</option>
            <option>{HETEROZYGOUS}</option>
            <option>{RECESSIVE}</option>
        </select>
    )
};
