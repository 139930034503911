import {
    addCurriculumToCohortQuery,
    addCurriculumToCohortVariables,
    cohortLearnerQuery,
    cohortLearnerVariables
} from "./queries/cohort";
import {learnerWorkBoardsLoaded} from "../store/learners/actions";
import {startLoadingLearnerWorkBoards} from "../store/dataAccess/actions";
import {isLearnerLoadingWorkBoards} from "../store/dataAccess/selectors";
import {cohortAssignedCurriculaLoaded} from "../store/cohorts/actions";

export const loadWorkBoardsForLearner = (dataService, learnerId) => {
    const state = dataService.store.getState();
    if(isLearnerLoadingWorkBoards(state, learnerId)) { return; }
    dataService.store.dispatch(startLoadingLearnerWorkBoards(learnerId));
    const variables = cohortLearnerVariables(learnerId);
    return dataService.sendGraphQL(cohortLearnerQuery, variables).then(result => {
        const workBoards = result.verdantLearner.workBoards;
        workBoards.sort((a, b) => (a.order - b.order) || 0);
        dataService.store.dispatch(learnerWorkBoardsLoaded(learnerId, workBoards));
    });
};

export const addCurriculumToCohort = (dataService, cohortId, curriculumId) => {
    const variables = addCurriculumToCohortVariables(cohortId, curriculumId);
    return dataService.sendGraphQL(addCurriculumToCohortQuery, variables)
        .then(data => {
            if(!data.addCurriculumToCohort || !data.addCurriculumToCohort.cohort) { return; }
            const cohort = data.addCurriculumToCohort.cohort;
            dataService.store.dispatch(cohortAssignedCurriculaLoaded(cohort.id, cohort.curricula));
        });
};
