import React from 'react';
import {useSelector, shallowEqual} from "react-redux";
import {getCurriculumName} from "../../store/curricula/selectors";
import {Link} from "@reach/router";

const stateMap = props => state => ({
    name: getCurriculumName(state, props.id),
});

export default React.memo(props => {
    const { name } = useSelector(stateMap(props), shallowEqual);

    return (
        <Link className="admin-curricula-list--curriculum" to={`./${props.id}`}>
            <div className="admin-curricula-list--curriculum-name">{name || "Unnamed Curriculum"}</div>
        </Link>
    )
});
