import {takeEvery, call, select} from 'redux-saga/effects';
import {
    ADD_EVIDENCE_TO_EXPLAINER,
    REMOVE_EVIDENCE_FROM_EXPLAINER,
    SET_EXPLAINER_CLAIM, SET_EXPLAINER_REASONING, UPDATE_EVIDENCE_ORDER
} from "../../../store/interactions/explainer/actions";
import {getActiveLearner} from "../../../store/navigation/selectors";
import {sendGraphQLSaga} from "../../DataService";
import {getExplainerEvidenceIds} from "../../../store/interactions/explainer/selectors";

const setExplainerLearnerClaim = `mutation SetExplainerLearnerClaim($input: setExplainerLearnerClaim!) {
    setExplainerLearnerClaim(input: $input) {
        interaction {
            claim
        }
    }
}`;
function* executeSetClaim(action) {
    const learner = yield select(state => getActiveLearner(state))
    if(!learner) { return; }

    const variables = {
        input: {
            learner,
            curriculum: action.curriculumId,
            explainer: action.explainerId,
            claim: action.claim,
        },
    };

    yield call(sendGraphQLSaga, setExplainerLearnerClaim, variables);
}

const setExplainerLearnerCanvasItems = `mutation SetExplainerLearnerCanvasItems($input: SetExplainerLearnerCanvasItemsInput!) {
    setExplainerLearnerCanvasItems(input: $input) {
        interaction {
            canvases {
                items
            }
        }
    }
}`;
function* executeSetCanvasItems(action) {
    const learner = yield select(state => getActiveLearner(state))
    if(!learner) { return; }

    const items = yield select(state => getExplainerEvidenceIds(state, action.explainerId));

    const variables = {
        input: {
            learner,
            curriculum: action.curriculumId,
            explainer: action.explainerId,
            canvasIndex: 0,
            items,
        },
    };

    yield call(sendGraphQLSaga, setExplainerLearnerCanvasItems, variables);
}

const setExplainerLearnerCanvasReasoning = `mutation SetExplainerLearnerCanvasReasoning($input: SetExplainerLearnerCanvasReasoningInput!) {
    setExplainerLearnerCanvasReasoning(input: $input) {
        interaction {
            canvases {
                reasoning
            }
        }
    }
}`;
function* executeSetCanvasReasoning(action) {
    const learner = yield select(state => getActiveLearner(state))
    if(!learner) { return; }

    const variables = {
        input: {
            learner,
            curriculum: action.curriculumId,
            explainer: action.explainerId,
            canvasIndex: 0,
            reasoning: action.reasoning,
        },
    };

    yield call(sendGraphQLSaga, setExplainerLearnerCanvasReasoning, variables);
}

export default [
    takeEvery(SET_EXPLAINER_CLAIM, executeSetClaim),
    takeEvery(ADD_EVIDENCE_TO_EXPLAINER, executeSetCanvasItems),
    takeEvery(REMOVE_EVIDENCE_FROM_EXPLAINER, executeSetCanvasItems),
    takeEvery(SET_EXPLAINER_REASONING, executeSetCanvasReasoning),
    takeEvery(UPDATE_EVIDENCE_ORDER, executeSetCanvasItems),
];
