import {call, delay, put, select, takeEvery} from 'redux-saga/effects';
import {getActiveLearner, getOpenGlobalInteractive} from "../../../store/navigation/selectors";
import {sendGraphQLSaga} from "../../DataService";
import {
    ADD_ITEM_TO_ORGANELLE, dogmaDashObjectFormChanged,
    LYSOSOME_CONSUME_AT, lysosomeEaten,
    MOVE_DOGMA_DASH_OBJECT,
    SET_DOGMA_DASH_HIGHLIGHTED_GENE,
    SET_DOGMA_DASH_PORTAL_NUCLEOTIDE,
    SET_DOGMA_DASH_PORTAL_TRNA,
    SET_LYSOSOME_TARGET,
    setDogmaDashOrganelleBroken,
    setLysosomeTarget
} from "../../../store/interactions/dogmaDash/actions";
import {
    getAmountLysosomeHasEaten,
    getDogmaMicrotubuleIds, getLysosomeEatingThreshold,
    getLysosomeTargetSetBy,
    getObjectForm,
    getObjectsInOrganelle,
    getObjectType
} from "../../../store/interactions/dogmaDash/selectors";
import {ProteinForm_Denatured, ProteinForm_ProteinInVesicle, Recycle, Structural} from "../../../constants/DogmaDash";
import {LysosomeEatTime} from "../../../Learn/CurriculumTask/Interactables/DogmaDash/Lysosome";

const createObjectMutation = `mutation CreateDogmaDashObjectInOrganelle($input: CreateDogmaDashObjectInOrganelleInput!) {
    createDogmaDashObjectInOrganelle(input: $input) {
        createdObject {
            id
        }
    }
}`;

function* executeAddItem(action) {
    const learnerId = yield select(getActiveLearner);
    if(!learnerId) {return;}
    const {curriculumId, dogmaDashId, organelleId, objectId, itemType} = action;

    const variables = {
        input: {
            dogmaDash: dogmaDashId,
            learner: learnerId,
            curriculum: curriculumId,
            organelle: organelleId,
            objectId,
            objectType: itemType,
        },
    };
    yield call(sendGraphQLSaga, createObjectMutation, variables);
}

const moveObjectToOrganelle = `mutation MoveObjectToOrganelle($input: MoveObjectToOrganelleInput!) {
    moveObjectToOrganelle(input: $input) {
        newOrganelle {
            id
        }
    }
}`;
const removeDogmaDashObject = `mutation RemoveDogmaDashObject($input: RemoveDogmaDashObjectInput!) {
    removeDogmaDashObject(input: $input) {
        errors
    }
}`;
const setDogmaDashOrganelleBrokenMutation = `mutation SetDogmaDashOrganelleBroken($input: SetDogmaDashOrganelleBrokenInput!) {
    setDogmaDashOrganelleBroken(input: $input) {
        organelle {
            id
        }
    }
}`;

function* executeMoveItem(action) {
    const learnerId = yield select(getActiveLearner);
    if(!learnerId) {return;}
    const {curriculumId, dogmaDashId, newOrganelleId, objectId} = action;

    const moveVariables = {
        input: {
            dogmaDash: dogmaDashId,
            learner: learnerId,
            curriculum: curriculumId,
            movedObject: objectId,
            newOrganelle: newOrganelleId,
        },
    };

    yield call(sendGraphQLSaga, moveObjectToOrganelle, moveVariables);

    yield delay(600);

    const objectType = yield select(state => getObjectType(state, objectId));
    const microtubules = yield select(state => getDogmaMicrotubuleIds(state, dogmaDashId));
    if(microtubules.indexOf(newOrganelleId) >= 0 && objectType === Structural) {
        const removeVariables = {
            input: {
                dogmaDash: dogmaDashId,
                learner: learnerId,
                curriculum: curriculumId,
                objectToRemove: objectId,
            },
        };
        yield call(sendGraphQLSaga, removeDogmaDashObject, removeVariables);

        const repairVariables = {
            input: {
                dogmaDash: dogmaDashId,
                learner: learnerId,
                curriculum: curriculumId,
                organelle: newOrganelleId,
                broken: false,
            },
        };
        yield call(sendGraphQLSaga, setDogmaDashOrganelleBrokenMutation, repairVariables);
        yield put(setDogmaDashOrganelleBroken(newOrganelleId, false));
    }
}

const setDogmaDashOrganelleTarget = `mutation SetDogmaDashOrganelleTarget($input: SetDogmaDashOrganelleTargetInput!) {
    setDogmaDashOrganelleTarget(input: $input) {
        organelle {
            target
        }
    }
}`;
function *executeSetLysosomeTarget(action) {
    const dogmaDashId = yield select(getOpenGlobalInteractive); // TODO: breaks with more than one global interactive open :-/
    const {lysosomeId, setBy, targetOrganelle, curriculumId} = action;

    const variables = {
        input: {
            dogmaDash: dogmaDashId,
            learner: setBy,
            curriculum: curriculumId,
            organelle: lysosomeId,
            newTarget: targetOrganelle,
            targetStartTimeMs: Date.now(),
            targetDurationMs: 2000 + 2533, // TODO: LYSOSOME MOVE AND ANIMATION TIMES!
        }
    };
    yield call(sendGraphQLSaga, setDogmaDashOrganelleTarget, variables);
}

function* executeLysosomeConsumeAt(action) {
    const {lysosomeId, targetOrganelle, curriculumId} = action;

    const targetSetBy = yield select(state => getLysosomeTargetSetBy(state, lysosomeId));
    const learnerId = yield select(getActiveLearner);
    if(learnerId !== targetSetBy) { return; }

    const dogmaDashId = yield select(getOpenGlobalInteractive); // TODO: breaks with more than one global interactive open :-/
    const organelleTrash = yield select(state => getObjectsInOrganelle(state, targetOrganelle).filter(id => getObjectForm(state, id) === ProteinForm_Denatured));
    const totalEaten = yield select(state => getAmountLysosomeHasEaten(state, lysosomeId));
    const eatingThreshold = yield select(state => getLysosomeEatingThreshold(state, dogmaDashId));

    yield delay(LysosomeEatTime);
    let numberEating = organelleTrash.length;
    for(let i = 0; i < organelleTrash.length; ++i) {
        const objectStillExists = yield select(state => !!getObjectType(state, organelleTrash[i]));
        if(!objectStillExists) {continue;}
        const removeVariables = {
            input: {
                dogmaDash: dogmaDashId,
                learner: learnerId,
                curriculum: curriculumId,
                objectToRemove: organelleTrash[i],
                score: 10,
            },
        };
        yield call(sendGraphQLSaga, removeDogmaDashObject, removeVariables);
    }

    if(eatingThreshold > 0 && Math.floor(totalEaten / eatingThreshold) < Math.floor((totalEaten + numberEating) / eatingThreshold)) {
        const recyclerId = yield select(state => getObjectsInOrganelle(state, lysosomeId).find(id => getObjectType(state, id) === Recycle && getObjectForm(state, id) === ProteinForm_ProteinInVesicle));
        yield put(dogmaDashObjectFormChanged(recyclerId, ProteinForm_Denatured));
    }

    yield put(lysosomeEaten(lysosomeId, numberEating));
    yield put(setLysosomeTarget(curriculumId, lysosomeId, learnerId));
}

const setDogmaDashHighlightedGene = `mutation SetDogmaDashHighlightedGene($input: SetDogmaDashHighlightedGeneInput!) {
    setDogmaDashHighlightedGene(input: $input) {
        session {
            interactable {
                id
            }
            highlightedGene
        }
    }
}`;
function* executeHighlightGene(action) {
    const learnerId = yield select(getActiveLearner);
    if(!learnerId) { return; }
    const {dogmaDashId, curriculumId, gene} = action;

    const variables = {
        input: {
            learner: learnerId,
            curriculum: curriculumId,
            dogmaDash: dogmaDashId,
            highlightedGene: gene,
        },
    };
    yield call(sendGraphQLSaga, setDogmaDashHighlightedGene, variables);
}

const setDogmaDashPortalNucleotide = `mutation SetDogmaDashPortalNucleotide($input: SetDogmaDashPortalNucleotideInput!) {
    setDogmaDashPortalNucleotide(input: $input) {
        session {
            portalNucleotide
        }
    }
}`;
function* executeSetPortalNucleotide(action) {
    const learnerId = yield select(getActiveLearner);
    if(!learnerId) { return; }
    const {dogmaDashId, curriculumId, nucleotide} = action;

    const variables = {
        input: {
            learner: learnerId,
            curriculum: curriculumId,
            dogmaDash: dogmaDashId,
            nucleotide,
        },
    };
    yield call(sendGraphQLSaga, setDogmaDashPortalNucleotide, variables);
}

const setDogmaDashPortalTrna = `mutation SetDogmaDashPortalTrna($input: SetDogmaDashPortalTrnaInput!) {
    setDogmaDashPortalTrna(input: $input) {
        session {
            portalTrna
        }
    }
}`;
function *executeSetPortalTrna(action) {
    const learnerId = yield select(getActiveLearner);
    if(!learnerId) { return; }
    const {dogmaDashId, curriculumId, nucleotides} = action;

    const variables = {
        input: {
            learner: learnerId,
            curriculum: curriculumId,
            dogmaDash: dogmaDashId,
            trnaNucleotides: nucleotides,
        },
    };
    yield call(sendGraphQLSaga, setDogmaDashPortalTrna, variables);
};

export default [
    takeEvery(ADD_ITEM_TO_ORGANELLE, executeAddItem),
    takeEvery(MOVE_DOGMA_DASH_OBJECT, executeMoveItem),
    takeEvery(LYSOSOME_CONSUME_AT, executeLysosomeConsumeAt),
    takeEvery(SET_LYSOSOME_TARGET, executeSetLysosomeTarget),
    takeEvery(SET_DOGMA_DASH_HIGHLIGHTED_GENE, executeHighlightGene),
    takeEvery(SET_DOGMA_DASH_PORTAL_NUCLEOTIDE, executeSetPortalNucleotide),
    takeEvery(SET_DOGMA_DASH_PORTAL_TRNA, executeSetPortalTrna),
];
