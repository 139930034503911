import {WORK_BOARD_DEFINITION} from "../../constants/TaskTypes";
import {getCurriculumTaskItemType} from "../curriculumTaskItems/selectors";

const getCurriculumTask = (state, taskId) => state.curriculumTask[taskId];
export const getAllCurriculumTasks = (state) => Object.keys(state.curriculumTask);
export const getCurriculumTaskTitle = (state, taskId) => getCurriculumTask(state, taskId).title;
export const getCurriculumTaskContent = (state, taskId) => getCurriculumTask(state, taskId).textContent;
export const getCurriculumTaskItemIds = (state, taskId) => getCurriculumTask(state, taskId).items;
export const getCurriculumTaskWorkBoardDefinitions = (taskId, state) =>
    getCurriculumTask(state, taskId).items.filter(i => getCurriculumTaskItemType(state, i) === WORK_BOARD_DEFINITION);
export const getCurriculumTaskType = (taskId, state) => getCurriculumTask(state, taskId).type;
export const getCurriculumTaskHeight = (taskId, state) => getCurriculumTask(state, taskId).height;
