import {CREATE_PEA_PLANT, PEA_PLANTS_LOADED, SET_PLANT_GENOTYPE, SET_PLANT_NAME} from "./actions";
import {SET_AUTHENTICATION_STATE} from "../identity/actions";
import {NOT_LOGGED_IN} from "../../constants/AuthenticationState";
import {CURRICULA_LOADED} from "../curricula/actions";
import {
    DROP_AREA_INTERACTION,
    PEA_PLANT_MATERIAL,
    WORK_BOARD_CROSS_CARD_INTERACTION,
    WORK_BOARD_DEFINITION
} from "../../constants/TaskTypes";
import {omit} from 'lodash';
import {INTERACTIONS_LOADED} from "../interactions/actions";
import {PEA_PLANT_CARD} from "../../constants/WorkBoardItemTypes";

const trait = {
    AlleleA: 0,
    AlleleB: 0,
};
const defaultPeaPlant = {
    id: null,
    parentA: null,
    parentB: null,
    name: "",
    height: {...trait},
    flowerColor: {...trait},
    flowerPosition: {...trait},
    seedColor: {...trait},
    seedShape: {...trait},
    podColor: {...trait},
    podShape: {...trait},
};

export default (state = {}, action) => {
    if(action.type === PEA_PLANTS_LOADED) {
        const newPlants = action.plants.reduce((agg, plant) => {
            agg[plant.id] = plant;
            return agg;
        }, {});
        return Object.assign({}, state, newPlants);
    }
    if(action.type === CURRICULA_LOADED) {
        const newPlants = action.curricula.reduce((agg, c) =>
            !c.tasks ? agg :
            Object.assign(agg, c.tasks.reduce((agg, t) => Object.assign(agg, t.items.filter(i => i.__typename === WORK_BOARD_DEFINITION)
                .reduce((agg, i) => Object.assign(agg, i.materials.filter(m => m.__typename === PEA_PLANT_MATERIAL)
                    .reduce((agg, m) => {
                        agg[m.peaPlant.id] = m.peaPlant;
                        return agg;
                    }, {})
                ), {}),
            ), {})
        ), {});
        return Object.assign({}, state, newPlants);
    }
    if(action.type === INTERACTIONS_LOADED) {
        const plants = action.interactions.reduce((agg, i) => {
            if(i.__typename === DROP_AREA_INTERACTION) {
                return {
                    ...agg,
                    ...(i.draggables.filter(item => item.item.__typename === PEA_PLANT_CARD).reduce((itemAgg, item) => ({
                        ...itemAgg,
                        [item.item.peaPlant.id]: item.item.peaPlant,
                    }), {})),
                };
            } else if(i.__typename === WORK_BOARD_CROSS_CARD_INTERACTION) {
                return {
                    ...agg,
                    ...(i.parents.concat(i.offspring).filter(item => !!item).reduce((itemAgg, item) => ({ // TODO: this will need to filter to just pea plant cards
                        ...itemAgg,
                        [item.peaPlant.id]: item.peaPlant,
                    }), {})),
                }
            }
            return agg;
        }, {});
        return {
            ...state,
            ...plants,
        };
    }
    if(action.type === CREATE_PEA_PLANT) {
        return {
            ...state,
            [action.plantId]: {
                ...defaultPeaPlant,
                ...omit(action, "type"),
            },
        };
    }
    if(action.type === SET_PLANT_NAME) {
        const plant = state[action.plantId];
        const updatePlant = Object.assign({}, plant, {
            name: action.name,
        });
        return Object.assign({}, state, {[action.plantId]: updatePlant});
    }
    if(action.type === SET_PLANT_GENOTYPE) {
        const plant = state[action.plantId];
        const updatePlant = Object.assign({}, plant, {
            [action.traitName]: action.genotype,
        });
        return Object.assign({}, state, { [action.plantId]: updatePlant });
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    return state;
}
