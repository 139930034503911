import {itemFragment} from "./workBoardItem";

export const getWorkBoardItemsQuery = `query GetWorkBoardItems($id: ID!) {
    workBoard(id: $id) {
        items {
            x
            y
            angle
            ${itemFragment}
        }
    }
}`;
export const getWorkBoardItemsVariables = workBoardId => ({id: workBoardId});

export const addPolaroidToWorkBoardQuery = `
mutation AddPolaroidToWorkBoard($input: AddPolaroidToWorkBoardInput!) {
    addPolaroidToWorkBoard(input: $input) {
        x
    }
}`;
export const addPolaroidToWorkBoardVariables = (workBoardId, itemPosition) => ({
    input: Object.assign({}, {workBoard: workBoardId}, itemPosition),
});

export const workBoardUpdatedSubscription = `
subscription onWorkBoardUpdated($input: WorkBoardUpdatedSubscriptionInput!) {
    workBoardUpdated(input: $input) {
        type
        workBoardId
        updater {
            id
        }
        updated {
            x
            y
            angle
            ${itemFragment}
        }
    }
}`;
export const workBoardUpdatedVariables = (workBoardIds) => ({
    input: {
        workBoards: workBoardIds
    }
});

export const deleteWorkBoardQuery = `mutation DeleteWorkBoard($input: DeleteWorkBoardFromLearnerInput!) { 
    deleteWorkBoardFromLearner(input: $input) {
        workBoard
    }
}`;
export const deleteWorkBoardVariables = (workBoardId, learnerId) => ({
    input: {
        workBoard: workBoardId,
        learner: learnerId,
    },
});
