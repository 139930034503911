import {css} from "@emotion/core";
import {bbPadding, HEADER_HEIGHT} from "../style-variables";
import {bodyBackground, bodyFontColor, bodyFontFamily, bodyFontWeight} from "./themes/selectors";

export const content = css(bbPadding(`${HEADER_HEIGHT}px 0 0 0`), {
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    zIndex: 0,
});
export const themedContent = theme => css(content, {
    background: bodyBackground(theme),
});
