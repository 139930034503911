import "./cohort-navigation.scss";
import React from 'react';
import classNames from 'class-names';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "@reach/router";
import {getAllCohortIds, getCohortName} from "../../store/cohorts/selectors";
import {createCohort} from "../../store/cohorts/actions";
import {shallowUnorderedArrayCompare} from "../../utility";

const stateMap = state => {
    const ids = getAllCohortIds(state);
    return {
        cohorts: ids.map(id => ({id, name: getCohortName(state, id) })),
    };
};

const comparer = (a, b) => {
    return shallowUnorderedArrayCompare(a.cohorts, b.cohorts);
};

export default React.memo(props => {
    const { cohorts } = useSelector(stateMap, comparer);
    const dispatch = useDispatch();
    const {selectedCohort} = props;

    const newCohort = () => createCohort(dispatch)();

    return (
        <div className="cohort-navigation--container">
            <button onClick={newCohort} className="cohort-navigation--create-cohort">Add cohort</button>
            {cohorts.map(({id, name}) => (
                <Link key={id} to={`/teach/cohort/${id}`} className={classNames({
                    "cohort-navigation--cohort-button": true,
                    "cohort-navigation--cohort-button__selected": id === selectedCohort,
                })}>
                    <span className="cohort-button--name">{name}</span>
                </Link>
            ))}
        </div>
    );
});

