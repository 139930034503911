export const ADVANCE_TUTORIAL_STRAND = "advance tutorial strand";
export const advanceTutorialStrand = strandId => ({
    type: ADVANCE_TUTORIAL_STRAND,
    strandId,
});

export const TUTORIAL_NEEDS_COMPLETION_CHECK = "tutorial needs completion check";
export const tutorialNeedsCompletionCheck = (tutorialData, positionData) => ({
    type: TUTORIAL_NEEDS_COMPLETION_CHECK,
    tutorialData,
    positionData,
});
