import {
    ADD_EMAIL_TO_LEARNER_LIST,
    CLOSE_ADD_LEARNER_MODAL,
    OPEN_ADD_LEARNER_TO_COHORT_MODAL,
    SET_MODAL_LOADING
} from "./action";

const defaultState = {
    cohortModalOpen : false,
    emails: [],
    showLoading: false,
};

export default (state = defaultState, action) => {
    if(action.type === OPEN_ADD_LEARNER_TO_COHORT_MODAL) {
        return Object.assign({}, state, {
            cohortModalOpen: true,
            emails: [],
        });
    }
    if(action.type === CLOSE_ADD_LEARNER_MODAL) {
        return Object.assign({}, state, {
            cohortModalOpen: false,
            showLoading: false,
        });
    }
    if(action.type === ADD_EMAIL_TO_LEARNER_LIST) {
        return Object.assign({}, state, {
            emails: state.emails.concat(action.emails),
        });
    }
    if(action.type === SET_MODAL_LOADING) {
        return Object.assign({}, state, {
            showLoading: action.loading,
        });
    }
    return state;
};
