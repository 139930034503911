import "./cpr.scss";
import React from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getIsCPRAssessment} from "../../../../store/curriculumTaskItems/cpr/selectors";
import {setCPRAssessment} from "../../../../store/curriculumTaskItems/cpr/actions";

export default props => {
    const {curriculum, id} = props;
    const isAssessment = useSelector(getIsCPRAssessment.bind(null, id), shallowEqual);
    const dispatch = useDispatch();

    const setIsAssessment = e => dispatch(setCPRAssessment(curriculum, id, e.target.checked));

    return (
        <div className="admin-task--cpr">
            <h2 className="cpr--title">CPR Definition</h2>
            <div className="cpr--assessment-container">
                <label htmlFor={`${props.id}-assessment`} className="cpr--assessment-label">Is assessment?</label>
                <input id={`${props.id}-assessment`} className="cpr--assessment-input" type="checkbox" checked={isAssessment} onChange={setIsAssessment} />
            </div>
        </div>
    )
}
