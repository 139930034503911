import {idsForOpenModalType, isModalOpenFor} from "../../../store/navigation/selectors";
import {WORK_BOARD_MODAL} from "../../../constants/modalTypes";
import {useParams} from "@reach/router";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getCurriculumTaskTitle, getCurriculumTaskWorkBoardDefinitions} from "../../../store/curriculumTask/selectors";
import {getInteractionWorkBoardId} from "../../../store/interactions/selectors";
import React, {useEffect} from "react";
import {createWorkBoardForDefinition} from "../../../store/interactions/actions";
import uuid from "uuid/v4";
import {openModalFor} from "../../../store/navigation/actions";
import combineStyles from "../../../Utility/combineStyles";
import * as styles from "./work-board-tray-styles";
import WorkBoard from "../../../WorkBoard";

const getNumberOpen = state => idsForOpenModalType(WORK_BOARD_MODAL, state).length;
export default ({taskId, index}) => {
    const {curriculumId} = useParams();
    const taskTitle = useSelector(state => getCurriculumTaskTitle(state, taskId));
    const isOpen = useSelector(state => isModalOpenFor(state, WORK_BOARD_MODAL, taskId));
    const numberOpen = useSelector(getNumberOpen, shallowEqual);
    const firstWorkBoardDefinition = useSelector(state => getCurriculumTaskWorkBoardDefinitions(taskId, state)[0]);
    const firstWorkBoardId = useSelector(state => getInteractionWorkBoardId(state, firstWorkBoardDefinition));
    const dispatch = useDispatch();

    useEffect(() => {
        if(!firstWorkBoardId) {
            dispatch(createWorkBoardForDefinition(curriculumId, firstWorkBoardDefinition, uuid()));
        }
    }, [firstWorkBoardId]);

    const toggleWorkBoard = () => {
        if(isOpen) {
            dispatch(openModalFor(null, taskId)); // ew. I keep saying that, I should just fix it...
        } else {
            const x = numberOpen * 20 + 100;
            const y = numberOpen * 20 + 170;
            dispatch(openModalFor(WORK_BOARD_MODAL, taskId, {x,y}));
        }
    };

    return (
        <button onClick={toggleWorkBoard} css={combineStyles(styles, {
            'tile': true,
            'tileActive': isOpen,
        })}>
            <div style={{flex: "0 0 auto"}}>
                {index !== undefined && <span css={styles.tileLabel}>{`VR${index+1}`}</span>}
                <span className={styles.tileTitleClass} css={styles.tileTitle}>{taskTitle}</span>
            </div>
            { firstWorkBoardId &&
            <WorkBoard workBoardDefinition={firstWorkBoardDefinition} boardId={firstWorkBoardId} scaleFactor={0.12} readOnly={true}/>
            }
        </button>
    )
}
