import {EMPTY_INTERACTIONS, INTERACTIONS_LOADED} from "../actions";
import {EXPLAINER_PREDICTION_INTERACTION} from "../../../constants/TaskTypes";
import {SET_EXPLAINER_PREDICTION} from "./actions";
import {SET_AUTHENTICATION_STATE} from "../../identity/actions";
import {NOT_LOGGED_IN} from "../../../constants/AuthenticationState";

const defaultInteraction = {
    prediction: "",
};

export default (state = {}, action) => {
    if(action.type === INTERACTIONS_LOADED) {
        const loadedInteractions = action.interactions.filter(i => i.__typename === EXPLAINER_PREDICTION_INTERACTION).reduce((agg, i) => ({
            ...agg,
            [i.interactable.id]: {
                ...(state[i.interactable.id] || defaultInteraction),
                prediction: i.prediction,
            }
        }), {});
        return {
            ...state,
            ...loadedInteractions,
        };
    }
    if(action.type === SET_EXPLAINER_PREDICTION) {
        return {
            ...state,
            [action.explainerPredictionId]: {
                ...state[action.explainerPredictionId],
                prediction: action.prediction,
            },
        };
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    if(action.type === EMPTY_INTERACTIONS) {
        return {};
    }
    return state;
}
