import {css} from "@emotion/core";
import {bbPadding, inlineBlockTop} from "../../../../../style-variables";
import {SCROLLING_CANVAS_WIDTH} from "../scrolling-styles";

export const container = css(inlineBlockTop,{
    width: 20,
    height: "100%",
    position: "relative",
});

export const measurer = css({
    width: "100%",
    minHeight: 20,
    position: "absolute",
    background: "rgba(85,165,49,0.44)",
    cursor: "grab",
});
export const measurerDragging = css({
    cursor: "grabbing",
});

const bar = css({
    height: 6,
    width: "100%",
    position: "absolute",
    right: 0,
    background: "rgba(85,165,49,0.44)",
    cursor: "ns-resize",

    "&:before": {
        content: '""',
        display: "block",
        width: SCROLLING_CANVAS_WIDTH,
        height: 1,
        position: "absolute",
        right: "100%",
        background: "rgba(85,165,49,0.44)",
    },
});

export const barTop = css(bar,{
    top: 0,

    "&:before": {
        top: 0,
    }
});

export const barBottom = css(bar,{
    bottom: 0,

    "&:before": {
        bottom: 0,
    }
});

export const addButton = css({
    backgroundColor: "transparent",
    border: "1px solid #ccc",
    borderLeft: "none",
    position: "absolute",
    left: "100%",
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
    transition: "background-color 0.2s",

    "&:hover": {
        backgroundColor: "#efefef"
    }
}, bbPadding("10px 2px"));
