import { takeEvery, call, select } from 'redux-saga/effects';
import {REMOVE_TASK_FROM_CURRICULUM, SET_CURRICULUM_NAME, SET_CURRICULUM_TYPE} from "../../../store/curricula/actions";
import {
    ADD_CASE_TO_CURRICULUM,
    ADD_TASK_TO_CURRICULUM_CASE,
    REMOVE_CASE_FROM_CURRICULUM,
    REMOVE_TASK_FROM_CURRICULUM_CASE,
    REORDER_TASK_IN_CURRICULUM_CASE,
    SET_CURRICULUM_CASE_DESCRIPTION
} from "../../../store/curriculumCases/actions";
import {getCurriculumName, getCurriculumType} from "../../../store/curricula/selectors";
import {getCurriculumStructure} from "../../../store/curriculumCases/selectors";
import {sendGraphQLSaga} from "../../DataService";

const updateContent = `mutation UpdateCurriculumContent($input: UpdateCurriculumContentInput!) {
    updateCurriculumContent(input: $input) {
        curriculum {
            id
        }
    }
}`;

function* execute(action) {
    const name = yield select(state => getCurriculumName(state, action.curriculumId));
    const structureJSON = yield select(state => getCurriculumStructure(action.curriculumId, state));
    const structure = JSON.stringify(structureJSON);
    const curriculumType = yield select(state => getCurriculumType(state, action.curriculumId));

    const variables = {
        input: {
            curriculum: action.curriculumId,
            name,
            structure,
            type: curriculumType || "None",
        },
    };

    yield call(sendGraphQLSaga, updateContent, variables);
}

export default [
    takeEvery(SET_CURRICULUM_NAME, execute),
    takeEvery(ADD_CASE_TO_CURRICULUM, execute),
    takeEvery(REMOVE_CASE_FROM_CURRICULUM, execute),
    takeEvery(ADD_TASK_TO_CURRICULUM_CASE, execute),
    takeEvery(REMOVE_TASK_FROM_CURRICULUM_CASE, execute),
    takeEvery(REMOVE_TASK_FROM_CURRICULUM, execute),
    takeEvery(SET_CURRICULUM_CASE_DESCRIPTION, execute),
    takeEvery(SET_CURRICULUM_TYPE, execute),
    takeEvery(REORDER_TASK_IN_CURRICULUM_CASE, execute),
];
