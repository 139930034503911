export default (array, item, newIndex) => {
    const currentIndex = array.indexOf(item);
    return Array.from({length: currentIndex >= 0 ? array.length : array.length + 1}, (_, i) => {
        if (i === newIndex) { return item; }
        let index = i;
        if (i >= newIndex) { --index; }
        if(currentIndex >= 0 && (i > currentIndex || (currentIndex < newIndex && i === currentIndex))) { ++index; }
        return array[index];
    });
};
