import dogmaStrand1 from "./configuration/dogma-strand-1";
import dogmaStrand2 from "./configuration/dogma-strand-2";
import transcriptionStrand1 from "./configuration/transcription-strand-1";
import translationStrand1 from "./configuration/translation-strand-1";
import dogmaStrand3 from "./configuration/dogma-strand-3";
import dogmaStrand4 from "./configuration/dogma-strand-4";
import geneSelectionStrand1 from "./configuration/gene-selection-strand1";
import dogmaStrand5 from "./configuration/dogma-strand-structural-unlock";
import dogmaStrand6 from "./configuration/dogma-strand-structural-tutorial";
import dogmaStrand7 from "./configuration/dogma-strand-7";
import dogmaStrandTransportTutorial from "./configuration/dogma-strand-transport-tutorial";
import dogmaStrandAntibodyUnlock from "./configuration/dogma-strand-antibody-unlock";
import dogmaStrandAntibodyTutorial from "./configuration/dogma-strand-antibody-tutorial";
import geneSelectionStrand2 from "./configuration/gene-selection-strand2";
import dogmaStrandEnergyUnlock from "./configuration/dogma-strand-energy-unlock";
import dogmaStrandEnergyTutorial from "./configuration/dogma-strand-energy-tutorial";
import dogmaStrandAntibody2 from "./configuration/dogma-strand-antibody-2";

const tutorials = [dogmaStrand1, dogmaStrand2, transcriptionStrand1, translationStrand1,
    dogmaStrand3, dogmaStrand4, dogmaStrand5, dogmaStrand6, dogmaStrand7, dogmaStrandTransportTutorial,
    dogmaStrandAntibodyUnlock, dogmaStrandAntibodyTutorial, dogmaStrandEnergyUnlock, dogmaStrandEnergyTutorial,
    dogmaStrandAntibody2, geneSelectionStrand1, geneSelectionStrand2];

export const getTutorialDataToShow = (state, positionData) => {
    for(let i = 0; i < tutorials.length; ++i) {
        const strand = tutorials[i];
        const currentItemIndex = getTutorialStrandCurrentIndex(state, strand.id);
        if(currentItemIndex >= strand.items.length) { continue; }
        const item = strand.items[currentItemIndex];
        if (!item.condition(state, positionData)) { continue; }
        return {
            strandId: strand.id,
            header: item.header,
            text: item.text,
            callToAction: item.callToAction,
            conditionalCompletion: !!item.completionCondition,
            alert: item.alert,
            index: currentItemIndex,
            width: item.width,
        };
    }
    return null;
};

export const getTutorialStrandItemComplete = (state, strandId, positionData) => {
    const strand = tutorials.find(s => s.id === strandId);
    if(!strand) { return false; }
    const currentItemIndex = getTutorialStrandCurrentIndex(state, strandId);
    const item = strand.items[currentItemIndex];
    return item.completionCondition?.(state, positionData);
};

export const shouldForceForward = (state, strandId, positionData) => {
    const strand = tutorials.find(s => s.id === strandId);
    if(!strand) { return false; }
    const currentItemIndex = getTutorialStrandCurrentIndex(state, strandId);
    const item = strand.items[currentItemIndex];
    return item.forceCompletion?.(state, positionData);
};

export const getTutorialStrandCurrentIndex = (state, strandId) => state.tutorials[strandId]?.currentItem ?? 0;
