import {css} from "@emotion/core";
import {absoluteCenter, bbPadding, inlineBlockTop} from "../../../../style-variables";

export const container = css(bbPadding("10px"), {
    border: "1px solid #333",
});

export const strandContainer = css({
    display: "flex",
    flexDirection: "row",
    border: "1px solid #555",
});
export const strandContainerMutationMode = css(strandContainer, {
    borderColor: "#be0c0c"
})

export const strandEntryContainer = css(inlineBlockTop, {
    display: "flex",
    flexDirection: "column",
    borderLeft: "1px solid #555",

    "&:first-of-type": {
        // borderTop: "1px solid #555",
        // borderBottom: "1px solid #555",
        borderLeft: "none",
    },

    "&:last-of-type": {
        // borderBottom: "1px solid #555",
        // borderRight: "1px solid #555",
    },
});

export const rowHeaders = css(bbPadding("4px 6px"), {
    textAlign: "right",
});

export const uppercase = css({
    textTransform: "uppercase",
});

export const strandSection = css(bbPadding("4px 6px"),{
    textAlign: "center",
    borderTop: "1px solid #555",

    "&:first-of-type": {
        borderTop: "none",
    },
});

export const buttonContainer = css({
    borderLeft: "1px solid #555",
    display: "flex",
    flexDirection: "column",
});

export const buttonStyle = css({
    flex: "1 1 0",
    margin: "3px",
});

export const inputStyle = css({
    width: "10px",
    border: "1px solid transparent",
    borderBottomColor: "#333",
    margin: "0 1px",
    outline: "none",

    "&:focus": {
        borderBottomColor: "#74DED4",
    }
});
export const inputStyleMutationMode = css(inputStyle,{
    borderBottomColor: "#c78686",

    "&:focus": {
        borderBottomColor: "#be0c0c",
    }
});
export const inputStyleWithMutation = css(inputStyle, {
    borderTopColor: "#c78686",
    borderLeftColor: "#c78686",
    borderRightColor: "#c78686",
});
export const inputStyleMutationModeWithMutation = css(inputStyle, {
    borderTopColor: "#be0c0c",
    borderLeftColor: "#be0c0c",
    borderRightColor: "#be0c0c",
});

export const targetAmino = css(bbPadding("2px"), inlineBlockTop, {
    border: "1px solid #333",
    margin: "0 0 3px 3px",

    "&:last-of-type": {
        marginRight: "3px",
    },

    "& > span": {
        marginRight: "3px",
        textTransform: "uppercase",
    },
});

export const selectAminoModalContainer = css({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
});

export const selectAminoModal = css(absoluteCenter, bbPadding("20px"), {
    width: "500px",
    background: "#fff",
});

export const selectAminoButtonContainer = css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
});

export const selectAminoButton = css({
    margin: "0 0 5px 5px",
    textTransform: "uppercase",
});
