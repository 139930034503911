import React from 'react';
import {useParams} from "@reach/router";
import {useSelector} from "react-redux";
import {getCurriculumTaskType} from "../../store/curriculumTask/selectors";
import {SCROLLING_TASK, SECTIONED_TASK} from "../../constants/TaskTypes";
import SectionedTask from "./SectionedTask";
import ScrollingTask from "./ScrollingTask";

const TaskTagMap = {
    [SECTIONED_TASK]: SectionedTask,
    [SCROLLING_TASK]: ScrollingTask,
};

export default () => {
    const {taskId} = useParams();
    const taskType = useSelector(state => taskId ? getCurriculumTaskType(taskId, state) : "");
    const TaskTag = TaskTagMap[taskType];

    if(!TaskTag) { return null; }

    return <TaskTag taskId={taskId} />;
}
