import * as styles from './drop-slot-style';
import * as draggableStyles from './scrolling-item-styles';
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useDrag, useDrop} from "react-dnd";
import {css} from "@emotion/core";
import {useParams} from "@reach/router";
import {getDraggableMetadata, getDraggablesInDropArea, getDraggableType} from "../../../store/interactions/selectors";
import {draggablePlacedInDropArea, removeDraggableFromDropArea} from "../../../store/interactions/actions";
import {
    SCROLLING_DATA_ITEM,
    SCROLLING_IMAGE_ITEM,
    SCROLLING_SHAPE_ITEM,
    SCROLLING_TEXT_ITEM
} from "../../../constants/TaskTypes";
import Text from "../../../Admin/Curriculum/Task/Types/ScrollingItems/Text";
import Image from "../../../Admin/Curriculum/Task/Types/ScrollingItems/Image";
import Shape from "../../../Admin/Curriculum/Task/Types/ScrollingItems/Shape";
import DataItem from "../../../Admin/Curriculum/Task/Types/ScrollingItems/DataItem";
import {DROPPED_ITEM, PHOTOGRAPH, PLANT_CARD, PLANT_PHENOTYPE} from "../../../constants/DropItemTypes";
import {getDropSlotAccepts, getDropSlotLabel} from "../../../store/dropSlots/selectors";
import DropSlotPlantCard from '../../../Utility/DropSlotPlantCard';
import {PEA_PLANT_CARD} from "../../../constants/WorkBoardItemTypes";
import {getPeaPlantCardDisplayMode, getPeaPlantCardDisplayTrait} from "../../../store/peaPlantCards/selectors";
import {PHENOTYPE, PLANT} from "../../../constants/peaPlantCardDisplayModes";
import combineStyles from "../../../Utility/combineStyles";
import {getCurriculumTaskItemType} from "../../../store/curriculumTaskItems/selectors";

const slotStyleMap = {
    [PLANT_CARD]: "plantCardSlot",
    [PLANT_PHENOTYPE]: "phenotypeSlot",
    [PHOTOGRAPH]: "photographSlot",
};
const acceptsMap = {
    [PLANT_PHENOTYPE]: [PLANT_PHENOTYPE, PEA_PLANT_CARD],
    [PLANT_CARD]: [PEA_PLANT_CARD],
    [PHOTOGRAPH]: [],
};

const createDisplayModeGetter = state => id => getPeaPlantCardDisplayMode(id, state);
const createDisplayTraitGetter = state => id => getPeaPlantCardDisplayTrait(id, state);

const ScrollingDropSlot = ({id}) => {
    const {curriculumId} = useParams();
    const acceptedType = useSelector(state => getDropSlotAccepts(id, state));
    const label = useSelector(state => getDropSlotLabel(id, state));
    const itemIn = useSelector(state => getDraggablesInDropArea(state, id)[0]);
    const getDisplayMode = useSelector(createDisplayModeGetter); // TODO: this will always cause a rerender
    const getDisplayTrait = useSelector(createDisplayTraitGetter); // TODO: this will always cause a rerender
    const dispatch = useDispatch();

    const accept = acceptedType ? [...acceptsMap[acceptedType], DROPPED_ITEM] : [DROPPED_ITEM];
    const [{hovered}, dropAreaRef] = useDrop({
        accept,
        collect: monitor => ({
            hovered: monitor.isOver() && monitor.canDrop(),
        }),
        canDrop: item => {
            if(itemIn && itemIn !== item.itemId) { return false; }
            if(acceptedType === PLANT_PHENOTYPE && item.type === PEA_PLANT_CARD) {
                return getDisplayMode(item.plantCardId) === PHENOTYPE;
            }
            if(acceptedType === PEA_PLANT_CARD && item.type === PEA_PLANT_CARD) {
                return getDisplayMode(item.itemId) === PLANT;
            }
            return true;
        },
        drop: (item, monitor) => {
            if(item.type === DROPPED_ITEM) {
                return {};
            }
            if(item.type === PEA_PLANT_CARD) {
                return acceptedType === PLANT_PHENOTYPE
                    ? dispatch(draggablePlacedInDropArea(curriculumId, id, item.itemId, PLANT_CARD, null,{
                        phenotype: true,
                        trait: getDisplayTrait(item.itemId),
                    }))
                    : dispatch(draggablePlacedInDropArea(curriculumId, id, item.itemId, PLANT_CARD));
            }
            const itemId = item.plantCardId || item.id;
            dispatch(draggablePlacedInDropArea(curriculumId, id, itemId, item.draggableType, null, item.metadata));
        },
    });

    let slotStyle = combineStyles(styles, {
        slot: true,
        slotHovered: hovered,
    });
    if(acceptedType) {
        slotStyle = css(slotStyle, styles[slotStyleMap[acceptedType]]);
    }
    return (
        <div css={styles.container}>
            {acceptedType && <div ref={dropAreaRef} css={slotStyle}>
                {itemIn && <DroppedItem id={itemIn} dropArea={id}/>}
            </div>}
            {label && <div css={styles.label}>{label}</div>}
        </div>
    );
};

const ItemTagMap = {
    [SCROLLING_TEXT_ITEM]: Text,
    [SCROLLING_IMAGE_ITEM]: Image,
    [SCROLLING_SHAPE_ITEM]: Shape,
    [SCROLLING_DATA_ITEM]: DataItem,
    [PLANT_CARD]: DropSlotPlantCard,
};

const getItemType = (id, draggableType) => state => draggableType ? draggableType : getCurriculumTaskItemType(state, id);

const DroppedItem = props => {
    const {curriculumId} = useParams();
    const {id, dropArea} = props;
    const draggableType = useSelector(getDraggableType.bind(null, dropArea, id));
    const itemType = useSelector(getItemType(id, draggableType));
    const metadata = useSelector(getDraggableMetadata.bind(null, dropArea, id));
    const dispatch = useDispatch();

    const [_, dragRef] = useDrag({
        item: {type: DROPPED_ITEM, id},
        end: (_, monitor) => {
            if(!monitor.didDrop()) {
                dispatch(removeDraggableFromDropArea(curriculumId, dropArea, id));
            }
        },
    });

    let ItemTag = ItemTagMap[itemType];
    return (
        <div ref={dragRef} css={draggableStyles.dropAreaItem}>
            <ItemTag id={id} metadata={metadata} />
        </div>
    );
}

export default ScrollingDropSlot;
