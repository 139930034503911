import "./long-text-input.scss";
import React from 'react';

export default ({id}) => {
    return (
        <div>
            <span>Object Scanning Task</span>
        </div>
    )
};
