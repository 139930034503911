import {css} from "@emotion/core";
import {DRAGGABLE_SHADOW} from "../../../../../style-variables";

export const container = css({
    position: "absolute",
    opacity: 0,
    transition: "border-color 0.2s",
    cursor: "move",
});
export const containerEditing = css({
    cursor: "default",
    zIndex: 10,
});
export const containerFullWidth = css({
    width: "100%",
});
export const containerDraggable = css({
    boxShadow: DRAGGABLE_SHADOW,
});

export const scrollingItemConfiguration = (x, y) => css({
    left: x,
    top: y,
});

export const editingWrapper = css({
    width: "100%",
    height: "100%",
    border: "1px solid transparent",
    transition: "border-color 0.2s",
    boxSizing: "border-box",

    "&:hover": {
        borderColor: "#32904440",
    }
});
export const editingWrapperEditing = css({
    borderColor: "#329044",
    position: "relative",

    "&:hover": {
        borderColor: "#329044",
    },
});
