import {bbPadding, contextMenuBase, contextMenuButtonBase, contextMenuLabel} from "../../../../../../style-variables";
import {css} from "@emotion/core";

export const container = css({
    width: "100%",
});

export const menuLabel = contextMenuLabel;

export const childrenContainer = css({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: "transparent",
    borderColor: "rgba(255,255,255,0.25)",
    margin: "0 0 1px 0",
    transition: "background-color 0.2s, border-color 0.2s",

    "&:hover": {
        backgroundColor: "rgba(255,255,255,0.25)",
        borderColor: '#333',
    },
}, bbPadding(3));

export const childrenButton = css({
    display: "inline-block",
    flex: "0 0 auto",
    width: 30,
    height: 30,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "inherit",
    backgroundColor: "transparent",
    color: "white",
    cursor: "pointer",
    transition: "background-color 0.2s, border-color 0.2s",

    "&:hover": {
        backgroundColor: "#333",
    },

    "&:not(:last-of-type)": {
        margin: "0 4px 0 0",
    },

    "&:disabled": {
        backgroundColor: "#5f5f5f!important"
    }
});

export const childrenDisplay = css({
    display: "inline-block",
    flex: "1 1 auto",
    color: "white",
    textAlign: "center",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "inherit",
    margin: "0 4px 0 0",
    lineHeight: "28px",
});
