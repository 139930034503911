import {ADD_ITEM_TO_SCROLLING_TASK} from "../scrollingTaskItemPositions/actions";
import {SCROLLER_WORK_BOARD} from "../../constants/TaskTypes";
import {SET_SCROLLER_WORK_BOARD_DEFINITION} from "./action";
import {CURRICULA_LOADED} from "../curricula/actions";

const defaultItem = {
    workBoardDefinition: null
};

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        return {
            ...state,
            ...(action.curricula.reduce((agg, c) => ({
                ...agg,
                ...(c.tasks || []).reduce((taskAgg, t) =>
                    ({
                        ...taskAgg,
                        ...t.items.filter(i => i.__typename === SCROLLER_WORK_BOARD).reduce((itemsAgg, i) => ({
                            ...itemsAgg,
                            [i.id]: {
                                ...defaultItem,
                                workBoardDefinition: i.workBoardDefinition ? i.workBoardDefinition.id : i.workBoardDefinition,
                            },
                        }), {}),
                    }), {}),
            }), {}))
        };
    }
    if(action.type === ADD_ITEM_TO_SCROLLING_TASK && action.itemType === SCROLLER_WORK_BOARD) {
        return {
            ...state,
            [action.itemId]: {
                ...defaultItem,
            },
        };
    }
    if(action.type === SET_SCROLLER_WORK_BOARD_DEFINITION) {
        return {
            ...state,
            [action.scrollerWorkBoardId]: {
                ...state[action.scrollerWorkBoardId],
                workBoardDefinition: action.workBoardDefinitionId,
            },
        };
    }
    return state;
};
