import {EMPTY_INTERACTIONS, INTERACTIONS_LOADED} from "../actions";
import {DOGMA_DASH, DOGMA_DASH_INTERACTION} from "../../../constants/TaskTypes";
import {CURRICULA_LOADED} from "../../curricula/actions";
import {
    CHANGE_CURRENT_DOGMA_DASH_SCORE,
    DOGMA_DASH_LEVEL_START_SET,
    DOGMA_DASH_PORTAL_NUCLEOTIDE_SET,
    DOGMA_DASH_PORTAL_TRNA_SET,
    DOGMA_DASH_SCORES_CHANGED,
    DOGMA_DASH_TRANSCRIPTION_NUCLEOTIDES_UPDATED,
    DOGMA_DASH_UNLOCKED_PROTEINS_UPDATED,
    REFRESH_DOGMA_DASH_INTERACTION_DATA,
    SET_DOGMA_DASH_HIGHLIGHTED_GENE,
    SET_DOGMA_DASH_PORTAL_NUCLEOTIDE,
    SET_DOGMA_DASH_PORTAL_TRNA,
    SET_DOGMA_DASH_VIEW
} from "./actions";
import {SET_AUTHENTICATION_STATE} from "../../identity/actions";
import {NOT_LOGGED_IN} from "../../../constants/AuthenticationState";
import {HELP_VIEW} from "../../../constants/DogmaDash";

const defaultActivity = {
    dashMrnaSlots: [],
    dashMitochondria: [],
    dashCellMembrane: [],
    dashLysosome: [],
    dashGolgiBody: [],
    dashRibosome: [],
    dashMicrotubules: [],
    dashCentrioles: [],
    dashCytoplasm: "",
    currentView: HELP_VIEW,
    unlockedProteins: [],
    highlightedGene: null,
    portalNucleotide: null,
    portalTrna: null,
    transcriptionNucleotides: [],
    isTranscriptionComplete: false,
    translationNucleotides: [],
    currentScore: 0,
    currentLevel: 0,
    currentLevelStageFinalLevel: 0,
    currentLevelStartTimeMs: null,
    currentLevelDurationMs: null,
    currentLevelCargoAlertThreshold: 0,
    currentLevelViralAlertThreshold: 0,
    currentLevelLysosomeConsumptionThreshold: 0,
};

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const newItems = action.curricula.reduce((agg, c) => (c.tasks || [])
            .reduce((agg, t) => t.items.filter(i => i.__typename === DOGMA_DASH && !state[i.id])
                .reduce((agg, i) => ({
                    ...agg,
                    [i.id]: {...defaultActivity},
                }), agg)
            , agg)
        , {});
        return {
            ...state,
            ...newItems,
        };
    }
    if(action.type === INTERACTIONS_LOADED) {
        const loadedInteractions = action.interactions.filter(i => i.__typename === DOGMA_DASH_INTERACTION).reduce((agg, i) => {
            const newState = {...defaultActivity};
            return {
                ...agg,
                [i.interactable.id]: {
                    ...newState,
                    ...interactionGraphqlToState(newState, i),
                },
            };
        }, {});
        return {
            ...state,
            ...loadedInteractions,
        };
    }
    if(action.type === REFRESH_DOGMA_DASH_INTERACTION_DATA) {
        let id = action.interactionData.interactable.id;
        return {
            ...state,
            [id]: {
                ...state[id],
                ...interactionGraphqlToState(state[id], action.interactionData),
            },
        };
    }
    if(action.type === SET_DOGMA_DASH_VIEW) {
        const {dogmaDashId, view} = action;
        return {
            ...state,
            [dogmaDashId]: {
                ...state[dogmaDashId],
                currentView: view,
            },
        };
    }
    if(action.type === SET_DOGMA_DASH_HIGHLIGHTED_GENE) {
        const {dogmaDashId, gene} = action;
        return {
            ...state,
            [dogmaDashId]: {
                ...state[dogmaDashId],
                highlightedGene: gene,
            },
        };
    }
    if(action.type === SET_DOGMA_DASH_PORTAL_NUCLEOTIDE || action.type === DOGMA_DASH_PORTAL_NUCLEOTIDE_SET) {
        const {dogmaDashId, nucleotide} = action;
        return {
            ...state,
            [dogmaDashId]: {
                ...state[dogmaDashId],
                portalNucleotide: nucleotide,
            },
        };
    }
    if(action.type === SET_DOGMA_DASH_PORTAL_TRNA || action.type === DOGMA_DASH_PORTAL_TRNA_SET) {
        const {dogmaDashId, nucleotides} = action;
        return {
            ...state,
            [dogmaDashId]: {
                ...state[dogmaDashId],
                portalTrna: nucleotides,
            },
        };
    }
    if(action.type === DOGMA_DASH_TRANSCRIPTION_NUCLEOTIDES_UPDATED) {
        const {dogmaDashId, transcriptionNucleotides, translationNucleotides} = action;
        const transcriptionFinished = transcriptionNucleotides.length === 0 && translationNucleotides.length === 0
            ? state[dogmaDashId].isTranscriptionComplete
            : !transcriptionNucleotides.some(n => !n.paired) || translationNucleotides.some(n => n.paired);
        return {
            ...state,
            [dogmaDashId]: {
                ...state[dogmaDashId],
                transcriptionNucleotides,
                translationNucleotides,
                isTranscriptionComplete: transcriptionFinished,
            },
        };
    }
    if(action.type === CHANGE_CURRENT_DOGMA_DASH_SCORE) {
        const {dogmaId, deltaScore} = action;
        return {
            ...state,
            [dogmaId]: {
                ...state[dogmaId],
                currentScore: state[dogmaId].currentScore + deltaScore,
            },
        };
    }
    if(action.type === DOGMA_DASH_UNLOCKED_PROTEINS_UPDATED) {
        const {dogmaId, unlockedProteins} = action;
        return {
            ...state,
            [dogmaId]: {
                ...state[dogmaId],
                unlockedProteins,
            },
        };
    }
    if(action.type === DOGMA_DASH_LEVEL_START_SET) {
        const {dogmaId, startTime} = action;
        return {
            ...state,
            [dogmaId]: {
                ...state[dogmaId],
                currentLevelStartTimeMs: startTime,
            },
        };
    }
    if(action.type === DOGMA_DASH_SCORES_CHANGED) {
        const {dogmaId, scores} = action;
        return {
            ...state,
            [dogmaId]: {
                ...state[dogmaId],
                levelScores: scores,
            },
        };
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    if(action.type === EMPTY_INTERACTIONS) {
        return {};
    }
    return state;
}

const interactionGraphqlToState = (startState, graphql) => ({
    dashMrnaSlots: graphql.dashMrnaSlots ? graphql.dashMrnaSlots.map(o => o.id) : startState.dashMrnaSlots,
    dashMitochondria: graphql.dashMitochondria ? graphql.dashMitochondria.map(o => o.id) : startState.dashMitochondria,
    dashCellMembrane: graphql.dashCellMembrane ? graphql.dashCellMembrane.map(o => o.id) : startState.dashCellMembrane,
    dashLysosome: graphql.dashLysosome ? graphql.dashLysosome.map(o => o.id) : startState.dashLysosome,
    dashGolgiBody: graphql.dashGolgiBody ? graphql.dashGolgiBody.map(o => o.id) : startState.dashGolgiBody,
    dashRibosome: graphql.dashRibosome ? graphql.dashRibosome.map(o => o.id) : startState.dashRibosome,
    dashMicrotubules: graphql.dashMicrotubules ? graphql.dashMicrotubules.map(o => o.id) : startState.dashMicrotubules,
    dashCentrioles: graphql.dashCentrioles ? graphql.dashCentrioles.map(o => o.id) : startState.dashCentrioles,
    dashCytoplasm: graphql.dashCytoplasm ? graphql.dashCytoplasm.id : startState.dashCytoplasm,
    portalNucleotide: graphql.portalNucleotide || startState.portalNucleotide,
    portalTrna: graphql.portalTrna || startState.portalTrna,
    unlockedProteins: graphql.unlockedProteins || startState.unlockedProteins,
    transcriptionNucleotides: graphql.transcriptionNucleotides || startState.transcriptionNucleotides,
    translationNucleotides: graphql.translationNucleotides || startState.translationNucleotides,
    isTranscriptionComplete: !(graphql.transcriptionNucleotides || startState.transcriptionNucleotides).some(n => !n.paired) || (graphql.translationNucleotides || startState.translationNucleotides).some(n => n.paired),
    currentLevel: graphql.currentLevel,
    currentStage: graphql.currentStage,
    currentLevelStageFinalLevel: graphql.currentLevelStageFinalLevel,
    currentLevelStartTimeMs: graphql.currentLevelStartTimeMs,
    currentLevelDurationMs: graphql.currentLevelDurationMs,
    currentLevelCargoAlertThreshold: graphql.currentLevelCargoAlertThreshold,
    currentLevelViralAlertThreshold: graphql.currentLevelViralAlertThreshold,
    currentLevelLysosomeConsumptionThreshold: graphql.currentLevelLysosomeConsumptionThreshold ?? 0,
    levelUnlockingProtein: graphql.levelUnlockingProtein,
    currentScore: graphql.levelScores?.find(l => l.level === graphql.currentLevel && l.stage === graphql.currentStage)?.score ?? 0,
    levelScores: graphql.levelScores || startState.levelScores,
});
