export const INTERACTIONS_LOADED = "Interactions loaded";
export const interactionsLoaded = (interactions) => ({
    type: INTERACTIONS_LOADED,
    interactions,
});

export const SET_LONG_TEXT_INPUT_TEXT = "set long text input text";
export const setLongTextInputText = (curriculumId, interactableId, text) => ({
    type: SET_LONG_TEXT_INPUT_TEXT,
    curriculumId,
    interactableId,
    text,
});

export const SET_MULTIPLE_CHOICE_INPUT_SELECTION = "set multiple choice input selection";
export const setMultipleChoiceInputSelection = (curriculumId, interactableId, selection) => ({
    type: SET_MULTIPLE_CHOICE_INPUT_SELECTION,
    curriculumId,
    interactableId,
    selection,
});

export const SET_INPUT_TABLE_INPUT = "set input table input";
export const setInputTableInput = (curriculumId, inputTableId, inputData) => ({
    type: SET_INPUT_TABLE_INPUT,
    curriculumId,
    inputTableId,
    inputData,
});

export const DRAGGABLE_PLACED_IN_DROP_AREA = "draggable placed in drop area";
export const draggablePlacedInDropArea = (curriculumId, dropAreaId, draggableId, draggableType = null, startingLocation = null, metadata = null) => ({
    type: DRAGGABLE_PLACED_IN_DROP_AREA,
    curriculumId,
    dropAreaId,
    draggableId,
    draggableType,
    metadata,
    startingLocation,
});

export const REMOVE_DRAGGABLE_FROM_DROP_AREA = "draggable removed from drop area";
export const removeDraggableFromDropArea = (curriculumId, dropAreaId, draggableId) => ({
    type: REMOVE_DRAGGABLE_FROM_DROP_AREA,
    curriculumId,
    dropAreaId,
    draggableId,
});

export const DROP_AREA_DRAGGABLES_SET = "drop area draggables set";
export const dropAreaDraggablesSet = (dropAreaId, draggables) => ({
    type: DROP_AREA_DRAGGABLES_SET,
    dropAreaId,
    draggables,
});

export const CREATE_WORK_BOARD_FOR_DEFINITION = "create work board for definition";
export const createWorkBoardForDefinition = (curriculumId, workBoardDefinitionId, workBoardId) => ({
    type: CREATE_WORK_BOARD_FOR_DEFINITION,
    curriculumId,
    workBoardDefinitionId,
    workBoardId,
});

export const REMOVE_WORK_BOARD_FOR_DEFINITION = "remove work board for definition";
export const removeWorkBoardForDefinition = (curriculumId, workBoardDefinitionId, workBoardId) => ({
    type: REMOVE_WORK_BOARD_FOR_DEFINITION,
    curriculumId,
    workBoardDefinitionId,
    workBoardId,
});

export const PLACE_PLANT_IN_CROSS = "place plant in cross";
export const placePlantInCross = (curriculumId, plantCardId, crossCardId, slotIndex, parent, startingLocation) => ({
    type: PLACE_PLANT_IN_CROSS,
    curriculumId,
    plantCardId,
    crossCardId,
    slotIndex,
    parent,
    startingLocation,
});

export const EMPTY_INTERACTIONS = "empty interactions";
export const emptyInteractions = () => ({type: EMPTY_INTERACTIONS});
