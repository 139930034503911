import "./work-board-materials.scss";
import React from 'react';
import {useDispatch} from "react-redux";
import {PEA_PLANT_MATERIAL} from "../../../../../constants/TaskTypes";
import PeaPlantMaterial from "./PeaPlantMaterial";
import {removeMaterialFromWorkBoard} from "../../../../../store/curriculumTaskItems/workBoardDefinition/actions";

const materialTags = {
    [PEA_PLANT_MATERIAL]: PeaPlantMaterial,
};

export default props => {
    const {material, curriculum, workBoardDefinition} = props;
    const dispatch = useDispatch();

    const removeMaterial = () => dispatch(removeMaterialFromWorkBoard(curriculum, workBoardDefinition, material.id));

    const Tag = materialTags[material.type];

    return (
        <div className="work-board-definition--material">
            <button className="material--remove" onClick={removeMaterial} />
            <Tag {...props} />
        </div>
    )
};
