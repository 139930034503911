export const PEA_PLANT_CARDS_LOADED = "Pea plant cards loaded";
export const peaPlantCardsLoaded = cards => ({
    type: PEA_PLANT_CARDS_LOADED,
    cards
});

export const CREATE_PEA_PLANT_CARD = "create pea plant card";
export const createPeaPlantCard = (cardId, plantId) => ({
    type: CREATE_PEA_PLANT_CARD,
    cardId,
    plantId,
});
