import {
    CLOSE_MODAL_TYPE,
    END_TASK_HOVER,
    ESC_PRESSED, LOAD_CURRICULUM_FOR_LEARNER,
    OPEN_MODAL_FOR,
    SELECT_ACTIVE_ACTIVITY,
    SELECT_ACTIVE_LEARNER,
    SELECT_ACTIVE_TOPIC,
    SET_CURRENT_CURRICULUM, SET_GLOBAL_INTERACTIVE_OPEN, SET_LEARNER_CURRICULUM_LOADED_FOR_TEACHER,
    SET_VR_BLOCKER_SHOWING,
    SHOW_RESET_CURRICULUM,
    START_TASK_HOVER, TOGGLE_EXPLAINER_EVIDENCE_SELECTED
} from "./actions";
import {SET_AUTHENTICATION_STATE} from "../identity/actions";
import {NOT_LOGGED_IN} from "../../constants/AuthenticationState";
import {CURRICULA_LOADED} from "../curricula/actions";

const defaultState = {
    activeLearner: null,
    activeWorkBoardIndex: null,
    activeActivity: null,
    activeTopic: null,
    activeCurriculum: null,
    vrBlockerShowing: false,
    tasksHovered: {},
    showResetCurriculum : false,
    openModals: [],
    selectedExplainerEvidence: [],
    currentlyLoadedLearner: null,
    currentlyLoadedCurriculum: null,
    globalInteractiveOpen: null,
    loadingLearnerCurriculum: null,
};

export default function(state = defaultState, action) {
    switch(action.type) {
        case SELECT_ACTIVE_LEARNER:
            return Object.assign({}, state, { activeLearner: action.learnerId });
        case SELECT_ACTIVE_ACTIVITY:
            return Object.assign({}, state, { activeActivity: action.activityId });
        case SELECT_ACTIVE_TOPIC:
            return Object.assign({}, state, { activeTopic: action.topicId });
        case SET_VR_BLOCKER_SHOWING:
            return Object.assign({}, state, { vrBlockerShowing: action.showBlocker });
        case START_TASK_HOVER:
            return Object.assign({}, state, {
                tasksHovered: Object.assign({}, state.tasksHovered, {
                    [action.task]: true,
                }),
            });
        case END_TASK_HOVER:
            return Object.assign({}, state, {
                tasksHovered: Object.assign({}, state.tasksHovered, {
                    [action.task]: false,
                }),
            });
        case SHOW_RESET_CURRICULUM:
            return Object.assign({}, state, {
                showResetCurriculum : true,
            });
        case SET_CURRENT_CURRICULUM:
            return Object.assign({}, state, {
                activeCurriculum: action.curriculum,
            });
        case OPEN_MODAL_FOR:
            if(!action.modalType && !action.id) {
                return {
                    ...state,
                    openModals: state.openModals.slice(0, state.openModals.length - 1),
                }
            } else if(!action.modalType && action.id) {
                if(!state.openModals.find(m => m.id === action.id)) { return state; }
                return {
                    ...state,
                    openModals: state.openModals.filter(m => m.id !== action.id),
                };
            }
            const update = state.openModals.find(m => m.type === action.modalType && m.id === action.id);
            let openModals;
            if(update) {
                openModals = state.openModals.map(m => m !== update ? m : {
                    ...update,
                    additionalData: action.additionalData,
                });
            } else {
                openModals = state.openModals.concat({ type: action.modalType, id: action.id, additionalData: action.additionalData });
            }
            return {
                ...state,
                openModals,
                selectedExplainerEvidence: [],
            };
        case CLOSE_MODAL_TYPE:
            if(!state.openModals.find(m => m.type === action.modalType)) { return state; }
            return {
                ...state,
                openModals: state.openModals.filter(m => m.type !== action.modalType),
            };
        case SET_LEARNER_CURRICULUM_LOADED_FOR_TEACHER:
            return {
                ...state,
                currentlyLoadedLearner: action.learnerId,
                currentlyLoadedCurriculum: action.curriculumId
            };
    }
    if(action.type === ESC_PRESSED && (state.openModals.length > 0 || state.vrBlockerShowing)) {
        return Object.assign({}, state, {
            vrBlockerShowing: false,
            openModals: [],
        });
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return Object.assign({}, defaultState);
    }
    if(action.type === TOGGLE_EXPLAINER_EVIDENCE_SELECTED) {
        let evidence = state.selectedExplainerEvidence;
        if(evidence.includes(action.evidenceId)) {
            evidence = evidence.filter(id => id !== action.evidenceId);
        } else {
            evidence = evidence.concat(action.evidenceId);
        }
        return {
            ...state,
            selectedExplainerEvidence: evidence,
        };
    }
    if(action.type === SET_GLOBAL_INTERACTIVE_OPEN) {
        return {
            ...state,
            globalInteractiveOpen: action.interactiveId,
        };
    }
    if(action.type === LOAD_CURRICULUM_FOR_LEARNER) {
        return {
            ...state,
            loadingLearnerCurriculum: action.curriculumId,
        };
    }
    if(action.type === CURRICULA_LOADED) {
        return {
            ...state,
            loadingLearnerCurriculum: null,
            currentlyLoadedCurriculum: action.setCurrent ? action.curricula[0].id : state.currentlyLoadedCurriculum,
        };
    }
    return state;
}
