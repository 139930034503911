import * as styles from './curriculum-style';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getCurriculumName, getCurriculumTaskIds, getCurriculumType} from "../../store/curricula/selectors";
import InlineEditor from "../../Utility/InlineEditor";
import {setCurriculumName, setCurriculumType} from "../../store/curricula/actions";
import {Link} from "@reach/router";
import TaskTile from "./TaskTile";
import Task from './Task';
import Structure from './Structure';
import uuid from 'uuid/v4';
import {createCurriculumTask} from "../../store/curriculumTask/actions";
import {setCurrentCurriculum} from "../../store/navigation/actions";
import {GLOBAL_TASK, SCROLLING_TASK, SECTIONED_TASK} from "../../constants/TaskTypes";
import combineStyles from "../../Utility/combineStyles";
import {NANO, VERDANT, VISCERAL} from "../../constants/curriculumTypes";
import {header as headerStyle} from "../../style-variables";
import {logo} from "../editor-style";

const getName = id => state => getCurriculumName(state, id);
const getTaskIds = id => state => getCurriculumTaskIds(state, id);
const getType = id => state => getCurriculumType(state, id);

export default React.memo(props => {
    const { curriculumId: id, taskId } = props;
    const name = useSelector(getName(id));
    const tasks = useSelector(getTaskIds(id));
    const curriculumType = useSelector(getType(id));
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false);
    const [isCreatingTask, setIsCreatingTask] = useState(false);

    useEffect(() => {
        dispatch(setCurrentCurriculum(id));
    }, [id]);
    useEffect(() => {
        setIsCreatingTask(false);
    }, [taskId, id]);

    const updateName = name => dispatch(setCurriculumName(id, name));
    const setType = e => dispatch(setCurriculumType(id, e.target.value));
    const relativeLinkPrefix = taskId ? "../" : "./";
    const showCreateTask = () => {
        setIsCreatingTask(true);
    };
    const createTask = taskType => {
        setIsCreatingTask(false);
        const newId = uuid();
        dispatch(createCurriculumTask(id, newId, taskType));
        props.navigate(`${relativeLinkPrefix}${newId}`);
    };

    const toggleCollapse = () => setCollapsed(c => !c);

    const previewLink = taskId ? `/admin/preview/${id}/${taskId}` : `/admin/preview/${id}`
    return (
        <>
            <div css={headerStyle}>
                <div css={styles.navigationContainer}>
                    <Link to="/admin" css={styles.backLink}>{"<< Back"}</Link>
                    <Link to={previewLink} css={styles.previewLink}>{">> Preview"}</Link>
                </div>
                <div css={logo} />
                <h1>VR-Sci Admin</h1>
            </div>
            <div css={styles.container}>
                <div css={styles.nameContainer}>
                    <InlineEditor name={name || "Unnamed Curriculum"} setName={updateName} />
                </div>
                <div css={styles.typeContainer}>
                    <select value={curriculumType} onChange={setType}>
                        <option value="None">None</option>
                        <option value={VERDANT}>Verdant</option>
                        <option value={VISCERAL}>Visceral</option>
                        <option value={NANO}>Nano</option>
                    </select>
                </div>
                <Structure />
                <div css={styles.tasksContainer}>
                    <div css={combineStyles(styles, {
                        tasksList: true,
                        tasksListCollapsed: collapsed,
                    })}>
                        <h3 css={styles.tasksTitle}>Tasks</h3>
                        {tasks.map(t => <TaskTile key={t} id={t} linkPrefix={relativeLinkPrefix} />)}
                        <button onClick={showCreateTask} css={styles.addTask}>Create task</button>
                    </div>
                    <div onClick={toggleCollapse} css={combineStyles(styles, {
                        separator: true,
                        separatorExpand: collapsed,
                        separatorCollapse: !collapsed,
                    })} />
                    {taskId && !isCreatingTask && <Task /> }
                    {isCreatingTask &&
                    <div css={styles.createTaskContainer}>
                        <button css={styles.createTask} onClick={createTask.bind(null, SECTIONED_TASK)}>Sectioned Task</button>
                        <button css={styles.createTask} onClick={createTask.bind(null, SCROLLING_TASK)}>Scrolling Task</button>
                        <button css={styles.createTask} onClick={createTask.bind(null, GLOBAL_TASK)}>Global Task</button>
                    </div>}
                </div>
            </div>
        </>
    )
});
