import {call, takeEvery} from 'redux-saga/effects';
import {SET_EXPLAINER_QUESTION} from "../../../store/curriculumTaskItems/explainer/actions";
import {sendGraphQLSaga} from "../../DataService";

const setExplainerQuestion = `mutation SetExplainerQuestion($input: SetExplainerQuestionInput!) {
    setExplainerQuestion(input: $input) {
        explainer {
            question
        }
    }
}`;

function* executeSetQuestion(action) {
    const variables = {
        input: {
            curriculum: action.curriculumId,
            explainer: action.explainerId,
            question: action.question,
        },
    };

    yield call(sendGraphQLSaga, setExplainerQuestion, variables);
}

export default [takeEvery(SET_EXPLAINER_QUESTION, executeSetQuestion)];
