import * as styles from './visceral-styles';
import React, {useEffect, useState} from 'react';
import CurriculumTask from "../CurriculumTask";
import {ThemeContext} from "../CurriculumDisplay";
import {useNavigate, useParams} from "@reach/router";
import {useSelector} from "react-redux";
import {
    getCurriculumCaseTasks,
    getNumberOfCurriculumCases,
    getTasksInCaseWithoutWorkBoard
} from "../../store/curriculumCases/selectors";
import {getAllCurriculumTasks, getCurriculumTaskType} from "../../store/curriculumTask/selectors";
import {getCurriculumTaskIds, getGlobalTaskId} from "../../store/curricula/selectors";
import GlobalTask from "../CurriculumTask/GlobalTask";
import {GLOBAL_TASK} from "../../constants/TaskTypes";

const getFirstTask = (state, curriculumId) => {
    const totalCases = getNumberOfCurriculumCases(curriculumId, state);
    let firstTask;
    for(let i = 0; i < totalCases; ++i) {
        const tasks = getCurriculumCaseTasks(curriculumId, i, state);
        firstTask = firstTask || tasks[0];
        let noWorkBoardTasks = getTasksInCaseWithoutWorkBoard(curriculumId, i, state);
        if(noWorkBoardTasks.length) {
            return noWorkBoardTasks[0];
        }
    }
    return firstTask;
}

const VisceralLearner = () => {
    const {taskId, curriculumId} = useParams();
    const firstTask = useSelector(state => getFirstTask(state, curriculumId));
    // const globalTask = useSelector(state => getGlobalTaskId(state, curriculumId));
    // const [globalExpanded, setGlobalExpanded] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if(taskId) { return; }
        navigate(`${curriculumId}/${firstTask}`, {replace: true});
    });

    // const toggleGlobal = () => setGlobalExpanded(!globalExpanded);

    return (
        <div css={styles.container}>
            <div css={styles.contentArea}>
                <CurriculumTask />
            </div>
            {/*{globalTask &&*/}
            {/*<div css={globalExpanded ? styles.globalContainerExpanded : styles.globalContainer}>*/}
            {/*    <button css={styles.globalToggleButton} onClick={toggleGlobal}>Inventory</button>*/}
            {/*    <div css={styles.globalContentArea}>*/}
            {/*        <GlobalTask taskId={globalTask}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*}*/}
        </div>
    );
};

export default VisceralLearner;
