import * as styles from './curriculum-style';
import {TASK_REMOVE_CLASS_NAME} from './curriculum-style';
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {removeTaskFromCurriculum} from "../../store/curricula/actions";
import {Link, useNavigate, useParams} from "@reach/router";
import {getCurriculumTaskTitle, getCurriculumTaskType} from "../../store/curriculumTask/selectors";
import combineStyles from "../../Utility/combineStyles";

const getTitle = id => state => getCurriculumTaskTitle(state, id);

export default props => {
    const {id} = props;
    const title = useSelector(getTitle(id));
    const type = useSelector(state => getCurriculumTaskType(id, state));
    const {curriculumId, taskId: selectedTask} = useParams();
    const selected = selectedTask === id;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const remove = e => {
        e.stopPropagation();
        e.preventDefault();
        if(selected) {
            // relative navigation here is from the previous router, so /admin/:curriculumId
            // which means navigating to '../' goes to /admin, but "./" goes to /admin/:curriculumId
            // even when there is a taskId
            navigate(`./`);
        }
        dispatch(removeTaskFromCurriculum(curriculumId, id));
    };

    return (
        <Link to={`${props.linkPrefix}${props.id}`} css={combineStyles(styles,{
            task: true,
            taskSelected: selected,
        })}>
            <span css={styles.taskName}>{title || type}</span>
            <button css={styles.taskRemove} className={TASK_REMOVE_CLASS_NAME} onClick={remove} />
        </Link>
    );
}
