export const WHITE_FLOWERS = "WHITE_FLOWERS";
export const PURPLE_FLOWERS = "PURPLE_FLOWERS";
export const TALL_PLANT = "TALL_PLANT";
export const SHORT_PLANT = "SHORT_PLANT";
export const GREEN_SEEDS = "GREEN_SEEDS";
export const YELLOW_SEEDS = "YELLOW_SEEDS";
export const WRINKLED_SEEDS = "WRINKLED_SEEDS";
export const ROUND_SEEDS = "ROUND_SEEDS";
export const GREEN_PODS = "GREEN_PODS";
export const YELLOW_PODS = "YELLOW_PODS";
export const INFLATED_PODS = "INFLATED_PODS";
export const CONSTRICTED_PODS = "CONSTRICTED_PODS";
export const AXIAL_FLOWERS = "AXIAL_FLOWERS";
export const TERMINAL_FLOWERS = "TERMINAL_FLOWERS";

export const EXPRESSION_DISPLAY_NAME = {
    [WHITE_FLOWERS]: "white flowers",
    [PURPLE_FLOWERS]: "purple flowers",
    [TALL_PLANT]: "tall",
    [SHORT_PLANT]: "short",
    [GREEN_SEEDS]: "green seeds",
    [YELLOW_SEEDS]: "yellow seeds",
    [WRINKLED_SEEDS]: "wrinkled seeds",
    [ROUND_SEEDS]: "round seeds",
    [GREEN_PODS]: "green pods",
    [YELLOW_PODS]: "yellow pods",
    [INFLATED_PODS]: "inflated pods",
    [CONSTRICTED_PODS]: "constricted pods",
    [AXIAL_FLOWERS]: "axial flowers",
    [TERMINAL_FLOWERS]: "terminal flowers",
};
