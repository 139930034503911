import {CURRICULA_LOADED} from "../../curricula/actions";
import {UTILITY_BUTTON} from "../../../constants/TaskTypes";
import {omit} from "lodash";
import {ADD_INTERACTABLE_TO_TASK, REMOVE_INTERACTABLE_FROM_TASK} from "../actions";
import {SET_UTILITY_BUTTON_BEHAVIOR, SET_UTILITY_BUTTON_GLOBAL_ITEM_ID, SET_UTILITY_BUTTON_TEXT} from "./actions";

const defaultState = {
    behavior: null,
    globalItemId: null,
    buttonText: "",
};

export default (state = {}, action) => {
    if (action.type === CURRICULA_LOADED) {
        const newInputs = action.curricula.reduce((agg, c) => ({
            ...agg,
            ...(c.tasks || []).reduce((agg, t) => ({
                ...agg,
                ...t.items.filter(i => i.__typename === UTILITY_BUTTON).reduce((agg, i) => ({
                    ...agg,
                    [i.id]: {
                        ...defaultState,
                        ...omit(i, '__typename'),
                    },
                }), agg),
            }), agg),
        }), {});
        return {
            ...state,
            ...newInputs,
        };
    }
    if (action.type === ADD_INTERACTABLE_TO_TASK && action.interactableType === UTILITY_BUTTON) {
        return {
            ...state,
            [action.interactableId]: {
                ...defaultState,
            },
        };
    }
    if (action.type === REMOVE_INTERACTABLE_FROM_TASK && state[action.interactableId]) {
        return omit(state, action.interactableId);
    }
    if(action.type === SET_UTILITY_BUTTON_BEHAVIOR) {
        const {buttonId, behavior} = action;
        return {
            ...state,
            [buttonId]: {
                ...state[buttonId],
                behavior,
            },
        };
    }
    if(action.type === SET_UTILITY_BUTTON_GLOBAL_ITEM_ID) {
        const {buttonId, globalItemId} = action;
        return {
            ...state,
            [buttonId]: {
                ...state[buttonId],
                globalItemId,
            },
        };
    }
    if(action.type === SET_UTILITY_BUTTON_TEXT) {
        const {buttonId, text} = action;
        return {
            ...state,
            [buttonId]: {
                ...state[buttonId],
                buttonText: text,
            },
        };
    }

    return state;
}
