import {css} from "@emotion/core";
import {dogmashOrange, dogmashRed} from "./dogma-dash-styles";
import {bbPadding} from "../../../../style-variables";

export const cargoCounterContainer = css({
    position: 'relative',
});

export const cargoCounter = css(bbPadding("0 10px"), {
    width: "144px",
    border: `1px solid ${dogmashOrange}`,
    background: "#1C1C1C",
    position: 'relative',
    zIndex: 0,
});

export const bar = css({
    height: "100%",
    backgroundColor: dogmashOrange,
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: -1,
});

export const text = css({
    fontFamily: "Rajdhani",
    fontSize: "0.9375rem",
    fontWeight: 400,
    color: "#fff",
    textTransform: "uppercase",
});
export const boldText = css({
    fontWeight: 700,
});

export const line = css({
    height: "calc(100% + 6px)",
    width: "1px",
    background: dogmashRed,
    position: 'absolute',
    left: "50%",
    top: 0,
});

export const alertContainer = css({
    width: "38px",
    height: "38px",
    position: "absolute",
    left: "50%",
    top: "calc(100% + 2px)",
    transform: 'translateX(-50%)',

    "& > svg": {
        width: "100%",
        height: "100%",
        fill: dogmashRed,
        stroke: dogmashRed,
        transform: "rotate(90deg)",
    },
});
