import {put, takeEvery, select} from 'redux-saga/effects';
import {advanceTutorialStrand, TUTORIAL_NEEDS_COMPLETION_CHECK} from "../../../store/tutorial/actions";
import {
    getTutorialDataToShow,
    getTutorialStrandCurrentIndex,
    getTutorialStrandItemComplete
} from "../../../store/tutorial/selectors";

function* executeCompletionCheck(action) {
    const {tutorialData, positionData} = action;
    const indexCheck = yield select(state => getTutorialStrandCurrentIndex(state, tutorialData.strandId));
    // if(indexCheck !== tutorialData) { return; } // can't do this! this is why this function exists in the first place
    if(indexCheck !== tutorialData.index) { return; }
    const completed = yield select(state => getTutorialStrandItemComplete(state, tutorialData.strandId, positionData));
    if(completed) {
        console.log("after completion: ", tutorialData, indexCheck);
        yield put(advanceTutorialStrand(tutorialData.strandId));
    }
}

export default [
    takeEvery(TUTORIAL_NEEDS_COMPLETION_CHECK, executeCompletionCheck),
];
