import {css} from "@emotion/core";
import {
    bbPadding,
    DROP_SLOT_SHADOW,
    inlineBlockTop,
    PEA_PLANT_CARD_HEIGHT,
    PEA_PLANT_CARD_WIDTH
} from "../../../../../../style-variables";

export const container = css({
    width: "100%",
    backgroundColor: "white",
    position: "relative",
    boxShadow: "2px 2px 3px 0 rgba(0,0,0,0.25)",
});

export const parentsContainer = css(inlineBlockTop, {
    backgroundColor: "#f7f7f7",
}, bbPadding("8px"));

export const slot = css({
    display: "inline-block",
    verticalAlign: "top",
    width: PEA_PLANT_CARD_WIDTH,
    height: PEA_PLANT_CARD_HEIGHT,
    backgroundColor: "white",
    border: "1px solid #888",
    borderRadius: 2,
    transition: "border-color 0.2s, background-color 0.2s",
    margin: "0 6px 0 0",

    "&:empty": {
        boxShadow: DROP_SLOT_SHADOW,
        borderColor: "#c4c4c4",
    }
});

export const crossSymbol = css({
    display: "inline-blocK",
    verticalAlign: "middle",
    lineHeight: `${PEA_PLANT_CARD_HEIGHT}px`,
    margin: "0 6px 0 0",
});

export const yieldArrow = css({
    display: "inline-block",
    verticalAlign: "middle",
    width: 16,
    height: 14,
    margin: "0 6px 0 0",

    "& svg": {
        width: "100%",
        height: "100%",
    },
});
