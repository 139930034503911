export const START_LOADING_LEARNER_WORK_BOARDS = 'START LOADING LEARNER WORK BOARDS';
export const startLoadingLearnerWorkBoards = learnerId => ({
    type: START_LOADING_LEARNER_WORK_BOARDS,
    learnerId,
});

export const START_LOADING_WORK_BOARD_ITEMS = 'START LOADING WORK BOARD ITEMS';
export const startLoadingWorkBoardItems = workBoardId => ({
    type: START_LOADING_WORK_BOARD_ITEMS,
    workBoardId,
});

export const START_LOADING_LEARNER = "Start loading learner";
export const startLoadingLearner = learnerId => ({
    type: START_LOADING_LEARNER,
    learnerId,
});
