import {
    dogmaDashLevelStartSet,
    dogmaDashObjectFormChanged,
    dogmaDashObjectMoved,
    dogmaDashObjectRemoved,
    dogmaDashOrgnalleTimerChanged,
    dogmaDashPortalNucleotideSet,
    dogmaDashPortalTrnaSet, dogmaDashScoresChanged,
    dogmaDashTranscriptionNucleotidesUpdated, dogmaDashUnlockedProteinsUpdated,
    lysosomeTargetSet,
    refreshDogmaDashInteractionData,
    setDogmaDashOrganelleBroken,
    setMotorProteinMovementData
} from "../../../store/interactions/dogmaDash/actions";
import {addPendingScoringEvent} from "../../../store/interactions/scoring/actions";

export const dogmaDashSessionStarted = (dispatch, data) => {
    dispatch(refreshDogmaDashInteractionData(data.session));
};

export const handleDogmaDashObjectMoved = (dispatch, data) => {
    const {id: movedObject, type, form} = data.movedObject;
    const newOrganelle = data.newOrganelle ? data.newOrganelle.id : null;
    dispatch(dogmaDashObjectMoved(movedObject, newOrganelle, type, form));
};

export const handleDogmaDashOrganelleTimerUpdate = (dispatch, data) => {
    const {score, organelle} = data;
    const {id, timerStartTimeMs, timerDurationMs, timerType, alertDescription} = organelle;
    const now = Date.now();
    console.log("TIMER UPDATE", timerStartTimeMs, now, timerDurationMs, now - timerStartTimeMs);
    if(score) {
        dispatch(addPendingScoringEvent(id, score));
    }
    dispatch(dogmaDashOrgnalleTimerChanged(id, timerStartTimeMs, timerDurationMs, timerType, alertDescription));
};

export const handleDogmaDashObjectRemoved = (dispatch, data) => {
    const {removedObject, score} = data;
    if(score) {
        dispatch(addPendingScoringEvent(removedObject, score));
    }
    dispatch(dogmaDashObjectRemoved(removedObject));
};

export const handleDogmaDashObjectFormChanged = (dispatch, data) => {
    const {changedObject} = data;
    dispatch(dogmaDashObjectFormChanged(changedObject.id, changedObject.form));
};

export const handleDogmaDashMotorProteinMovementChange = (dispatch, data) => {
    if(!data.motorProtein) { return; }
    const {id, movingTowards, movementStartTimeMs, movementDurationMs} = data.motorProtein;
    dispatch(setMotorProteinMovementData(id, movingTowards, movementStartTimeMs, movementDurationMs));
};

export const handleDogmaDashOrganelleBrokenUpdated = (dispatch, data) => {
    const {id, broken} = data.organelle;
    dispatch(setDogmaDashOrganelleBroken(id, broken));
};

export const handleDogmaDashOrganelleTargetChanged = (dispatch, data) => {
    const {id, targetSetter, targetStartTimeMs, targetDurationMs, target} = data.organelle;
    dispatch(lysosomeTargetSet(id, target, targetSetter, targetStartTimeMs, targetDurationMs));
};

export const handleDogmaDashPortalNucleotideChanged = (dispatch, data) => {
    const {session} = data;
    dispatch(dogmaDashPortalNucleotideSet(session.interactable.id, session.portalNucleotide));
};

export const handleDogmaDashTranscriptionUpdate = (dispatch, data) => {
    const {session} = data;
    dispatch(dogmaDashTranscriptionNucleotidesUpdated(session.interactable.id, session.transcriptionNucleotides, session.translationNucleotides));
};

export const handleDogmaDashPortalTrnaChanged = (dispatch, data) => {
    const {session} = data;
    dispatch(dogmaDashPortalTrnaSet(session.interactable.id, session.portalTrna));
};

export const handleUnlockedProteinsChanged = (dispatch, data) => {
    const {session} = data;
    dispatch(dogmaDashUnlockedProteinsUpdated(session.interactable.id, session.unlockedProteins));
};

export const handleLevelStartTimeSet = (dispatch, data) => {
    const {session} = data;
    const now = Date.now();
    console.log("LEVEL TIMER START", session.currentLevelStartTimeMs, now, now - session.currentLevelStartTimeMs);
    dispatch(dogmaDashLevelStartSet(session.interactable.id, session.currentLevelStartTimeMs))
};

export const handleScoreChange = (dispatch, data) => {
    const {session} = data;
    dispatch(dogmaDashScoresChanged(session.interactable.id, session.levelScores));
};
