import {css} from "@emotion/core";
import {bbPadding, PEA_PLANT_CARD_HEIGHT} from "../../../../../style-variables";

export const container = css({
    minHeight: "100px",
    height: (PEA_PLANT_CARD_HEIGHT * 2) + 32, // 8 px margin 8 px padding
    overflow: "auto",
}, bbPadding(3));

export const definitionButton = css({
    display: "block",
    width: "100%",
});
