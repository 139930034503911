import React from 'react';

export const EDITOR_COMPONENT_IMAGE = "image";

export default ({attributes, children, element}) => {
    const {url} = element;
    return (
        <div {...attributes}>
            <div contentEditable={false}>
                {url && <img src={url} style={{
                    "maxWidth": "100%",
                    "display": "block",
                }} />}
                {!url && <div className="rich-editor-components--image load-spinner" /> }
            </div>
            {children}
        </div>
    )
}
