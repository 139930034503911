export const CREATE_CURRICULUM_TASK = "Create curricula task";
export const createCurriculumTask = (curriculumId, taskId, taskType) => ({
    type: CREATE_CURRICULUM_TASK,
    curriculumId,
    taskId,
    taskType,
});

export const SET_CURRICULUM_TASK_TITLE = "Set curricula task title";
export const setCurriculumTaskTitle = (curriculumId, taskId, title) => ({
    type: SET_CURRICULUM_TASK_TITLE,
    curriculumId,
    taskId,
    title,
});

export const SET_CURRICULUM_TASK_CONTENT = "Set curricula task content";
export const setCurriculumTaskContent = (curriculumId, taskId, content) => ({
    type: SET_CURRICULUM_TASK_CONTENT,
    curriculumId,
    taskId,
    content,
});

export const SET_CURRICULUM_TASK_TYPE = "Set curricula task type";
export const setCurriculumTaskType = (curriculumId, taskId, type) => ({
    type: SET_CURRICULUM_TASK_TYPE,
    curriculumId,
    taskId,
    taskType: type,
});

export const SET_CURRICULUM_TASK_HEIGHT = "Set scroller task height";
export const setCurriculumTaskHeight = (curriculumId, taskId, height) => ({
    type: SET_CURRICULUM_TASK_HEIGHT,
    curriculumId,
    taskId,
    height: Math.round(height),
});

export const REORDER_CURRICULUM_ITEM = "Reorder curricula item";
export const reorderCurriculumItem = (curriculumId, taskId, itemId, direction) => ({
    type: REORDER_CURRICULUM_ITEM,
    curriculumId,
    taskId,
    itemId,
    direction,
});

export const CURRICULUM_ITEM_TO_TOP = "Curriculum item to top";
export const curriculumItemToTop = (curriculumId, taskId, itemId) => ({
    type: CURRICULUM_ITEM_TO_TOP,
    curriculumId,
    taskId,
    itemId
});

export const CURRICULUM_ITEM_TO_BOTTOM = "Curriculum item to bottom";
export const curriculumItemToBottom = (curriculumId, taskId, itemId) => ({
    type: CURRICULUM_ITEM_TO_BOTTOM,
    curriculumId,
    taskId,
    itemId
});

export const ADD_SPACE_TO_SCROLLER_TASK = "Add space to scroller task";
export const addSpaceToScrollerTask = (curriculumId, taskId, taskItemIds, startY, height) => ({
    type: ADD_SPACE_TO_SCROLLER_TASK,
    curriculumId,
    taskId,
    taskItemIds,
    startY,
    height,
});
