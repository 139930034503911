import {CURRICULA_LOADED} from "../curricula/actions";
import {ADD_INTERACTABLE_TO_TASK, REMOVE_INTERACTABLE_FROM_TASK} from "./actions";
import {omit} from 'lodash';
import {ADD_ITEM_TO_SCROLLING_TASK} from "../scrollingTaskItemPositions/actions";

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const newItems = action.curricula.reduce((agg, c) => ({
            ...agg,
            ...(c.tasks || []).reduce((taskAgg, t) =>
                ({
                    ...taskAgg,
                    ...t.items.reduce((itemsAgg, i) => ({
                        ...itemsAgg,
                        [i.id]: {
                            type: i.__typename,
                        },
                    }), {}),
                }), {}),
        }), {});
        return {
            ...state,
            ...newItems,
        };
    }
    if(action.type === ADD_INTERACTABLE_TO_TASK) {
        return {
            ...state,
            [action.interactableId]: {
                type: action.interactableType,
            },
        };
    }
    if(action.type === REMOVE_INTERACTABLE_FROM_TASK) {
        return omit(state, action.interactableId);
    }
    if(action.type === ADD_ITEM_TO_SCROLLING_TASK) {
        return {
            ...state,
            [action.itemId]: {
                type: action.itemType,
            },
        };
    }
    return state;
};
