import * as styles from './work-board-styles';
import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getInteractionWorkBoardId} from "../../../store/interactions/selectors";
import WorkBoard from "../../../WorkBoard";
import {getCurriculumTaskTitle, getCurriculumTaskWorkBoardDefinitions} from "../../../store/curriculumTask/selectors";
import {createWorkBoardForDefinition} from "../../../store/interactions/actions";
import {useParams} from "@reach/router";
import uuid from 'uuid/v4';
import combineStyles from "../../../Utility/combineStyles";
import {openModalFor} from "../../../store/navigation/actions";

const scrollingTasksWorkBoard = ({taskId}) => {
    const {curriculumId} = useParams();
    const title = useSelector(state => getCurriculumTaskTitle(state, taskId));
    const definitions = useSelector(state => getCurriculumTaskWorkBoardDefinitions(taskId, state), shallowEqual);
    const workBoardIds = useSelector(state => definitions.map(d => getInteractionWorkBoardId(state, d)), shallowEqual);
    const [selectedIndex, selectBoardIndex] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        workBoardIds.forEach((w, i) => {
            if(!w) {
                dispatch(createWorkBoardForDefinition(curriculumId, definitions[i], uuid()));
            }
        });
    }, [taskId]);

    const close = () => dispatch(openModalFor(null, taskId)); // ew.

    return (
        <div css={styles.container}>
            <h2 css={styles.title}>{title}</h2>
            <button css={styles.close} onClick={close} />
            {workBoardIds[selectedIndex] &&
            <div css={styles.cursorReset}>
                <WorkBoard boardId={workBoardIds[selectedIndex]} workBoardDefinition={definitions[selectedIndex]}/>
            </div>}
            {definitions.length > 1 && <div css={styles.dotContainer}>
                <div css={styles.centerer}/>
                {definitions.map((_, i) =>
                    <button key={i} onClick={selectBoardIndex.bind(null, i)} css={combineStyles(styles, {
                        dot: true,
                        dotSelected: i === selectedIndex,
                    })} />
                )}
                <div css={styles.centerer}/>
            </div>}
        </div>
    );
};

export default scrollingTasksWorkBoard;
