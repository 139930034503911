import {css} from "@emotion/core";
import {AVENIR_FONT, bbPadding, inlineBlockTop} from "../../style-variables";
import intestineImage from '../../assets/intestine.svg';

export const container = css({
    width: "100%",
    height: `100%`,
    backgroundColor: "#faf5f5",
    overflow: "auto",
    textAlign: "center",
    fontFamily: AVENIR_FONT,
    position: "relative",

    "&:before": {
        content: '""',
        display: "block",
        height: "calc(100% + 10px)",
        width: "98px",
        backgroundImage: `url(${intestineImage})`,
        backgroundSize: "98px 1081px",
        backgroundRepeat: "no-repeat",
        position: "absolute",
        top: "-10px",
        left: "11%",
        transform: "translateX(-120px)",
        opacity: 1,
        transition: "opacity 0.2s",
    },

    "@media (max-width: 1366px)": {
        "&:before": {
            opacity: 0,
        },
    },
}, bbPadding('32px 12px 0 12px'));

export const contentArea = css(inlineBlockTop, {
    width: "76%",
    minWidth: "1138px",
    height: "100%",
    textAlign: "left",
    transition: "width 0.3s",

    "@media (max-width: 1366px)": {
        minWidth: 0,
        width: "100%",
    },
});

export const globalContainer = css({
    width: 0,
    height: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    boxShadow: "-1px 0px 3px 0 rgba(0,0,0,0)",
    transition: "width 0.4s, box-shadow 0.4s",
});
export const globalContainerExpanded = css(globalContainer, {
    width: "96px",
    boxShadow: "-1x 0px 3px 0 rgba(0,0,0,0.25)",
});

export const globalToggleButton = css(bbPadding("6px 10px"), {
    position: "absolute",
    top: "90px",
    right: "calc(100% - 1px)",
    fontSize: "0.875em",
    border: "none",
    borderRadius: "6px 6px 0 0",
    backgroundColor: "white",
    boxShadow: "0 -2px 1px 0 rgba(0,0,0,0.25)",
    cursor: "pointer",
    zIndex: 1,
    transform: "rotate(-90deg)",
    transformOrigin: "100% 100%",
});

export const globalContentArea = css({
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    overflow: "hidden",
});
