import {css, keyframes} from "@emotion/core";
import {
    continueButtonBackground,
    continueButtonBorderColor, continueButtonColor,
    headerButtonBackground, headerButtonBorder,
    headerButtonsFontColor,
    headerButtonsFontFamily,
    headerButtonsFontSize,
    headerButtonsFontWeight, primaryColor, titleFontFamily
} from "./Learn/themes/selectors";
import {darken, toHex, transparentize} from "color2k";
import logoImage from "./Admin/assets/logo.jpg";

export const AVENIR_FONT = "Avenir Next";

export const FONT_HEAVY_WEIGHT = 900;
export const FONT_BOLD_WEIGHT = 700;

export const LIGHT_GRAY = "#f2f2f2";
export const DARK_GRAY = "#6f6f6f";
const TITLE_GREEN = "#329044";

export const DROP_SLOT_HOVERED_HIGHLIGHT = "#ececec";

const LOGO_WIDTH = 30;
export const HEADER_HEIGHT = LOGO_WIDTH + 20;

export const WORK_BOARD_WIDTH = 900;
export const WORK_BOARD_HEIGHT = WORK_BOARD_WIDTH * 0.75;
export const PEA_PLANT_CARD_WIDTH = WORK_BOARD_WIDTH / 6.5; // old: 147px;
export const PEA_PLANT_CARD_HEIGHT = (WORK_BOARD_WIDTH * 0.75 ) / (3.9); // old 191px;
export const PHENOTYPE_CARD_WIDTH = 54;
export const PHENOTYPE_CARD_HEIGHT = 54;
export const PHOTOGRAPH_WIDTH = 150;
export const PHOTOGRAPH_HEIGHT = 150;

export const DROP_SLOT_SHADOW = "inset 0px 2px 4px -2px rgba(0,0,0,0.5)";
export const DRAGGABLE_SHADOW = "1px 1px 3px 0 rgba(0,0,0,0.4)";

export const bbPadding = padding => css({
    padding,
    boxSizing: "border-box",
});

export const inlineBlockMiddle = css({
    display: "inline-block",
    verticalAlign: "middle",
});

export const inlineBlockTop = css({
    display: "inline-block",
    verticalAlign: "top",
});

export const inlineBlockBottom = css({
    display: "inline-block",
    verticalAlign: "bottom",
});

export const absoluteCenter = css({
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
});

export const header = css(bbPadding("6px 20px 6px 0"), {
    height: HEADER_HEIGHT,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: `1px solid ${LIGHT_GRAY}`,
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1,

    "& h1": {
        padding: 0,
        margin: 0,
        fontWeight: 300,
        fontSize: "1.875em",
        flex: "1 0 auto",
        textTransform: "uppercase",
        color: TITLE_GREEN,
    },
});

export const logo = css({
    width: LOGO_WIDTH,
    height: LOGO_WIDTH,
    margin: "0 8px 0 10px",
    flex: "0 0 auto",
    backgroundSize: "100% 100%",
});

export const identityContainer = css({
    height: "100%",
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
});

export const identityName = css(bbPadding("0 22px"), {
    fontSize: "0.875em",
    marginLeft: "30px",
    color: "#999",
}, inlineBlockMiddle);

export const headerButton = css(bbPadding("0 30px"), {
    height: "100%",
    color: "#fff",
    background: "#329044",
    border: "none",
    borderRight: "1px solid transparent",
    borderLeft: "1px solid transparent",
    cursor: "pointer",
    transition: "background 0.2s",
    textDecoration: "none",
});

export const themedHeaderButton = theme => css(headerButton, {
    color: headerButtonsFontColor(theme),
    fontFamily: headerButtonsFontFamily(theme),
    fontSize: headerButtonsFontSize(theme),
    fontWeight: headerButtonsFontWeight(theme),
    background: headerButtonBackground(theme),
    borderColor: headerButtonBorder(theme),
});
export const themedOpenHeaderButton = theme => css(themedHeaderButton(theme), {
    background: `linear-gradient(180deg, ${transparentize(primaryColor(theme), 0.5)} -31.97%, ${transparentize(darken(primaryColor(theme), 0.51), 0.5)} 136.89%)`,
});

export const logout = css({
    width: "115px",
    height: "100%",
    backgroundColor: "#329044",
    color: "white",
    textAlign: "center",
    lineHeight: "35px",
    cursor: "pointer",
    border: "none",
    transition: "background-color 0.2s",
}, inlineBlockMiddle);


export const growKeyframes = keyframes({
    "0%": { transform: "scaleY(0)" },
    "100%": { transform: "scaleY(1)" },
});
export const contextMenuBase = css({
    width: 200,
    backgroundColor: "#333",
    position: "absolute",
    overflow: "hidden",
    boxShadow: "2px 2px 3px 0 rgba(0,0,0,0.5)",
    transformOrigin: "0 0",
    animation: `${growKeyframes} 0.2s forwards`,
    zIndex: 1000,
}, bbPadding(1));
export const contextMenuButtonBase = css({
    display: "block",
    width: "100%",
    color: "white",
    background: "transparent",
    border: "none",
    textAlign: "left",
    cursor: "pointer",
    transition: "background-color 0.2s",

    "&:not(:last-of-type)": {
        margin: "0 0 1px 0",
    },

    "&:hover": {
        backgroundColor: "rgba(255,255,255,0.25)",
    },
}, bbPadding("4px 6px"));
export const contextMenuLabel = css({
    display: "block",
    width: "100%",
    color: "white",
    fontSize: "0.875em",
}, bbPadding("4px 6px"))

export const greenButton = css({
    fontSize: "1.25em",
    backgroundColor: "#d7ffb7",
    border: "4px solid #d7ffb7",
    margin: 0,
    cursor: "pointer",
    boxShadow: "0 0 0 0 #d7ffb7",
    transition: "background-color 0.2s, box-shadow 0.3s, transform 0.3s",
    position: "relative",

    "&:hover": {
        transform: "translate(4px, -6px)",
        boxShadow: "-4px 6px 0 1px #d7ffb7",
    },

    "&:active": {
        transform: "translate(0, 0)",
        boxShadow: "0 0 0 0 #d7ffb7",
    },
});

export const upDownExpander = css({
    display: "inline-block",
    position: "relative",
    background: "transparent",
    border: "none",
    margin: 0,
    padding: 0,
    cursor: "pointer",

    "&:before,&:after": {
        content: '""',
        display: "block",
        width: "60%",
        height: "3px",
        background: "black",
        position: "absolute",
        top: "50%",
        transition: "transform 0.3s",
    },
    "&:before": {
        transform: "translateY(-50%) rotate(45deg)",
    },
    "&:after": {
        right: "1px",
        transform: "translateY(-50%) rotate(-45deg)",
    },
});
export const upDownExpanderCollapsed = css({
    "&:before": {
        transform: "translateY(-50%) rotate(-45deg)",
    },
    "&:after": {
        transform: "translateY(-50%) rotate(45deg)",
    },
});

export const modalBlocker = css({
    backgroundColor: "rgba(0,0,0,0.75)",
    position: 'fixed',
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1000,
});

export const themedModalContainer = theme => css(absoluteCenter, bbPadding("20px 100px 30px 100px"), {
    background: darken(primaryColor(theme), 0.65),
    border: `5px solid ${primaryColor(theme)}`,
    borderRadius: "11px",
    textAlign: 'center',
});

export const themedModalTitle = theme => css({
    color: primaryColor(theme),
    padding: 0,
    marginBottom: "22px",
    fontFamily: titleFontFamily(theme),
    fontSize: "2em",
    fontWeight: 600,
    textTransform: "uppercase",
});

export const modalButtonContainer = css({
    width: "100%",
    marginTop: "10px",
    textAlign: "right",
});

export const modalAcceptButton = css({
    width: "90px",
    height: "30px",
    padding: "0",
    marginRight: "10px",
    border: "none",
    backgroundColor: "#E3C04B",
    transition: "background-color 0.2s",
    cursor: "pointer",
    color: "#eee",

    "&:disabled": {
        backgroundColor: "#6F6F6F",
        cursor: "not-allowed",
    },

    "&:not(:disabled):hover": {
        backgroundColor: "#e3bb34"
    },
});

export const  modalCancelButton = css({
    width: "90px",
    height: "30px",
    padding: 0,
    border: "1px solid $no-emphasis-color",
    backgroundColor: "#fff",
    transition: "background-color 0.2s",
    cursor: "pointer",

    "&:hover": {
        backgroundColor: LIGHT_GRAY,
    },
});

export const themedModalClose = theme => css(bbPadding("11px"), {
    width: "40px",
    height: "40px",
    fill: darken(primaryColor(theme), 0.65),
    border: "none",
    background: primaryColor(theme),
    borderTopRightRadius: "11px",
    position: 'absolute',
    top: "-5px",
    right: "-5px",
    cursor: "pointer",

    "& > svg": {
        width: "100%",
        height: "100%",
    },
});

export const themedModalButton = theme => css(bbPadding("16px 26px"), {
    border: `2px solid ${primaryColor(theme)}`,
    background: 'transparent',
    color: primaryColor(theme),
    transition: "background 0.2s, color 0.2s",
    fontFamily: headerButtonsFontFamily(theme),
    fontWeight: headerButtonsFontWeight(theme),
    fontSize: "1.25em",
    cursor: "pointer",

    "&:hover": {
        background: primaryColor(theme),
        color: darken(primaryColor(theme), 0.65),
    },
});

export const themedModalInput = theme => css(bbPadding("8px"), {
    width: "100%",
    border: "none",
    display: "block",
    margin: "15px 0 14px 0",
    textAlign: "center",
    color: primaryColor(theme),
    background: `linear-gradient(180deg, ${transparentize(primaryColor(theme), 0.23)} -28.95%, ${transparentize(darken(primaryColor(theme), 0.51), 0.23)} 142.59%)`,
    fontSize: "1.25em",
    fontFamily: headerButtonsFontFamily(theme),

    "&::placeholder": {
        fontFamily: headerButtonsFontFamily(theme),
        color: transparentize(primaryColor(theme), 0.2),
    },
});

export const themedBodyButton = theme => css(bbPadding("22px 30px"), {
    border: `1px solid ${continueButtonBorderColor(theme)}`,
    backgroundColor: continueButtonBackground(theme),
    color: continueButtonColor(theme),
    fontFamily: headerButtonsFontFamily(theme),
    fontWeight: headerButtonsFontWeight(theme),
    fontSize: "1.75rem",
    boxShadow: `0 0 0 0 rgba(1,1,1,0)`,
    transition: "box-shadow 0.2s, color 0.2s, background-color 0.2s",
    cursor: "pointer",

    "&:hover": {
        backgroundColor: continueButtonColor(theme),
        color: darken(continueButtonColor(theme), 0.65),
        boxShadow: `0 0 20px 0 ${continueButtonBorderColor(theme)}`,
    },
});
