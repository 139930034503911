import {stylingButton} from './editor-buttons-styles';
import {css} from "@emotion/core";
import {absoluteCenter} from "../../style-variables";

export const textColorButton = css(stylingButton, {
    position: "relative",
});

export const buttonText = css(absoluteCenter, {
    width: "70%",
    fontWeight: 700,
    fontSize: "1em",
    lineHeight: "18px",
    borderBottom: "3px solid #000",
});

export const colorSelectorContainer = css({
    position: "absolute",
    top: "75%",
    left: "75%",
    zIndex: 1,
});
