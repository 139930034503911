import {call, select, takeEvery} from 'redux-saga/effects';
import {TRAIT_TYPES} from "../../../constants/PodulatorReceiptTypes";
import {sendGraphQLSaga} from "../../DataService";
import {
    CREATE_PUNNETT_SQUARE, SET_PUNNETT_SQUARE_ENTRY_ALLELE, SET_PUNNETT_SQUARE_ENTRY_PHENOTYPE,
    SET_PUNNETT_SQUARE_PLANT,
    SET_PUNNETT_SQUARE_TRAIT
} from "../../../store/punnettSquares/actions";
import {
    getPunnettSquareEntryAllele,
    getPunnettSquareEntryPhenotype,
    getPunnettSquareTraits
} from "../../../store/punnettSquares/selectors";
import {getActiveLearner} from "../../../store/navigation/selectors";

export const createPunnettSquareQuery = `mutation CreatePunnettSquare($input: CreatePunnettSquareInput!) {
    createPunnettSquare(input: $input) {
        punnettSquare {
            id
        }
    }
}`;
export function* executeCreatePunnettSquare(action) {
    const learnerId = yield select(state => getActiveLearner(state));
    if(!learnerId) { return; }

    const variables = {
        input: {
            id: action.id,
        },
    };

    yield call(sendGraphQLSaga, createPunnettSquareQuery, variables);
    yield call(executeSetPunnettSquareTrait, action);
}

export const setPunnettSquareTraitsQuery = `mutation SetPunnettSquareTraits($input: SetPunnettSquareTraitsInput!) {
    setPunnettSquareTraits(input: $input) {
        punnettSquare {
            id
        }
    }
}`;
export function* executeSetPunnettSquareTrait(action) {
    const learnerId = yield select(state => getActiveLearner(state));
    if(!learnerId) { return; }

    const trait = yield select(state => getPunnettSquareTraits(state, action.id)[0]);

    const variables = {
        input: {
            punnettSquare: action.id,
            traits: [TRAIT_TYPES.indexOf(trait)],
        },
    };

    yield call(sendGraphQLSaga, setPunnettSquareTraitsQuery, variables);
}

export const setPunnettSquarePlantQuery = `mutation setPunnettSquarePlant($input: SetPunnettSquarePlantInput!) {
    setPunnettSquarePlant(input: $input) {
        punnettSquare {
            id
        }
    }
}`;
export function* executeSetPunnettSquarePlant(action) {
    const learnerId = yield select(state => getActiveLearner(state));
    if(!learnerId) { return; }

    const variables = {
        input: {
            punnettSquare: action.id,
            plant: action.plantId,
            left: action.isOnLeft,
        },
    };

    yield call(sendGraphQLSaga, setPunnettSquarePlantQuery, variables);
}

export const setPunnettSquareEntryQuery = `mutation setPunnettSquareEntry($input: SetPunnettSquareEntryInput!) {
    setPunnettSquareEntry(input: $input) {
        punnettSquare {
            id
        }
    }
}`;
export function* executeSetPunnettSquareEntry(action) {
    const learnerId = yield select(state => getActiveLearner(state));
    if(!learnerId) { return; }

    const {id, x, y} = action;
    const phenotype = yield select(state => getPunnettSquareEntryPhenotype(state, id, x, y));
    const alleleA = yield select(state => getPunnettSquareEntryAllele(state, id, x, y, 0));
    const alleleB = yield select(state => getPunnettSquareEntryAllele(state, id, x, y, 1));
    const trait = yield select(state => getPunnettSquareTraits(state, id)[0]);
    const variables = {
        input: {
            punnettSquare: id,
            x,
            y,
            trait: TRAIT_TYPES.indexOf(trait),
            phenotype,
            alleleA,
            alleleB,
        },
    };

    yield call(sendGraphQLSaga, setPunnettSquareEntryQuery, variables);
}

export default [
    takeEvery(CREATE_PUNNETT_SQUARE, executeCreatePunnettSquare),
    takeEvery(SET_PUNNETT_SQUARE_PLANT, executeSetPunnettSquarePlant),
    takeEvery(SET_PUNNETT_SQUARE_TRAIT, executeSetPunnettSquareTrait),
    takeEvery(SET_PUNNETT_SQUARE_ENTRY_ALLELE, executeSetPunnettSquareEntry),
    takeEvery(SET_PUNNETT_SQUARE_ENTRY_PHENOTYPE, executeSetPunnettSquareEntry),
];
