import * as styles from './focused-item-styles';
import React from 'react';
import {
    PEA_PLANT_CARD,
    PODULATOR_RECEIPT,
    POLAROID,
    PUNNETT_SQUARE,
    STICKY_NOTE
} from "../../constants/WorkBoardItemTypes";
import PeaPlantCard from "./PeaPlantCard";
import {useSelector} from "react-redux";
import Polaroid from "./Polaroid";
import StickyNote from "./StickyNote";
import PodulatorReceipt from "./PodulatorReceipt";
import PunnettSquare from "./PunnettSquare";
import {getLearnerItemType} from "../../store/learnerItems/selectors";

const TagMap = {
    [POLAROID]: Polaroid,
    [PEA_PLANT_CARD]: PeaPlantCard,
    [STICKY_NOTE]: StickyNote,
    [PODULATOR_RECEIPT]: PodulatorReceipt,
    [PUNNETT_SQUARE]: PunnettSquare,
};

export default props => {
    const {id} = props;
    const type = useSelector(state => getLearnerItemType(id, state));

    const Tag = TagMap[type];
    return (
        <div css={styles.container}>
            {Tag && <Tag id={id} large={true} />}
        </div>
    )
};
