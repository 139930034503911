import * as styles from './explainer-styles';
import React from 'react';

export default props => {

    return (
        <div css={styles.explainerContainer}>
            <h1 css={styles.label}>Explanation Builder</h1>
        </div>
    );
};
