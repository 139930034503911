import * as styles from './cooking-organelle-styles';
import React, {useEffect, useRef} from 'react';
import {shallowEqual, useSelector} from "react-redux";
import {
    getObjectForm,
    getObjectsInOrganelle,
    getObjectType,
    getOrganelleTimerDuration,
    getOrganelleTimerStart,
    getOrganelleTimerType
} from "../../../../store/interactions/dogmaDash/selectors";
import Protein from "./Protein";
import {
    EnergyProteinConvertor,
    EnergyProteinRotor,
    EnergyProteinStabilizer,
    ProteinForm_Denatured,
    ProteinForm_ProteinInVesicle
} from "../../../../constants/DogmaDash";
import PopUpTutorial from "../../../Tutorial/PopUpTutorial";

const isMultipartProtein = type => type === EnergyProteinRotor || type === EnergyProteinStabilizer || type === EnergyProteinConvertor;

const CookingOrganelle = ({id, interactableProteins: allowInteractionsWith = [], dogmaDashId}) => {
    const objectsIn = useSelector(state => getObjectsInOrganelle(state, id).filter(o => getObjectForm(state, o) !== ProteinForm_Denatured));
    const objectsInType = useSelector(state => objectsIn.map(o => getObjectType(state, o)), shallowEqual);
    const objectsInForm = useSelector(state => objectsIn.map(o => getObjectForm(state, o)), shallowEqual);
    const timerStart = useSelector(state => getOrganelleTimerStart(state, id));
    const timerType = useSelector(state => getOrganelleTimerType(state, id));
    const timerDuration = useSelector(state => getOrganelleTimerDuration(state, id));
    const tutorialConfig = {organelleId: id, dogmaDashId};
    const timerRef = useRef();
    const animationRef = useRef();
    const burning = timerType === "Burning";

    useEffect(() => {
        if(timerRef.current == null) {return;}
        const updateBar = () => {
            if(timerRef.current == null) { return; }
            const timeSinceStart = Date.now() - timerStart;
            const target = burning
                ? Math.max(0, 1 - (timeSinceStart / timerDuration))
                : Math.min(1, timeSinceStart / timerDuration);
            timerRef.current.style.height = `${target * 100}%`;
            animationRef.current = requestAnimationFrame(updateBar);
        };
        animationRef.current = requestAnimationFrame(updateBar);
        return () => cancelAnimationFrame(animationRef.current);
    }, [timerStart, timerDuration]);

    const representation = objectsInType[0];
    const representationForm = isMultipartProtein(representation) && timerType === "Cooking" ? ProteinForm_ProteinInVesicle : objectsInForm[0];
    const interactable = allowInteractionsWith.length > 0 && !objectsInType.some((o, i) => !allowInteractionsWith.some(a => a.type === o && a.form === objectsInForm[i]));

    return (
        <div css={styles.container}>
            {representation && <Protein interactable={interactable} blue={interactable} showName={false} type={representation} form={representationForm} id={objectsIn[0]} />}
            {representation && timerStart && <div css={styles.timerContainer}>
                <div ref={timerRef} css={burning ? styles.burnTimer : styles.timer} />
            </div>}
            <div css={styles.tutorial}><PopUpTutorial positionData={tutorialConfig} /></div>
        </div>
    )
};

export default CookingOrganelle;
