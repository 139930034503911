import * as styles from './centralDogmaWidget-styles';
import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "@reach/router";
import {useDispatch, useSelector} from "react-redux";
import {
    getInitialStrand,
    getMutations,
    getTargetAminoAcids,
    getUserStrand
} from "../../../store/interactions/centralDogmaWidget/selectors";
import {css} from "@emotion/core";
import {
    ADENINE,
    CYTOSINE,
    DNA_STRAND,
    getAminoAcidColorForNucleotides,
    getAminoAcidDisplayNameForNucleotides, getColorForShortAminoAcid,
    GetTranslationNucleotideMatch,
    GUANINE,
    LETTER_TO_NUCLEOTIDE_MAP,
    MRNA_STRAND,
    NUCLEOTIDE_LETTER_MAP,
    STOP_CODON,
    THYMINE,
    TRNA_STRAND,
    URACIL
} from "../../../constants/DogmaDash";
import {setCentralDogmaWidgetNucleotide} from "../../../store/interactions/centralDogmaWidget/actions";
import AminoAcid from './inline-assets/AminoAcid.svg';
import FlipIcon from './inline-assets/Flip_Icon.svg';

const nucleotideStyling = {
    [ADENINE]: styles.adenine,
    [THYMINE]: styles.thymine,
    [URACIL]: styles.uracil,
    [GUANINE]: styles.guanine,
    [CYTOSINE]: styles.cytosine,
};

const StrandNucleotide = ({widgetId, strandIndex, strandName, focused, setFocus}) => {
    const {curriculumId} = useParams();
    const initialStrand = useSelector(state => getInitialStrand(state, widgetId));
    const userStrand = useSelector(state => getUserStrand(state, widgetId));
    const mutations = useSelector(state => getMutations(state, widgetId));
    const ref = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if(!focused || !ref.current) { return; }
        ref.current.focus();
    }, [focused]);

    const initialValue = initialStrand[strandIndex][strandName];
    const mutation = mutations.find(m => m.index === strandIndex && m.strand === strandName);
    const mutationValue = mutation ? mutation.nucleotide : null;
    const userValue = userStrand && userStrand.length > strandIndex ? userStrand[strandIndex][strandName] : null;
    const displayNucleotide = userValue || initialValue;
    const displayValue = NUCLEOTIDE_LETTER_MAP[displayNucleotide] || "";
    const containerStyle = mutationValue
        ? userValue ? nucleotideStyling[displayNucleotide] : styles.mutationNucleotideContainer
        : displayValue ? nucleotideStyling[displayNucleotide] : styles.nucleotideContainer;
    const inputStyle = mutationValue
        ? userValue ? styles.inputWithValue : styles.inputWithMutation
        : displayValue ? styles.inputWithValue : styles.inputStyle;

    const setNucleotide = e => {
        let value = e.target.value;
        const nucleotide = LETTER_TO_NUCLEOTIDE_MAP[value.toUpperCase()];
        if(!nucleotide && value !== "") { return; }
        if(value !== "" && strandIndex === initialStrand.length - 1) {
            const nextStrand = strandName === TRNA_STRAND
                ? MRNA_STRAND
                : strandName === MRNA_STRAND
                ? DNA_STRAND
                : null;
            setFocus(0, nextStrand);
        } else if(value !== "") {
            setFocus(strandIndex + 1, strandName)
        }
        dispatch(setCentralDogmaWidgetNucleotide(curriculumId, widgetId, strandName, strandIndex, nucleotide));
    };

    const backspace = e => {
        if(e.key !== "Backspace" || e.target.value !== "") { return; }
        if(strandIndex === 0) {
            const nextStrand = strandName === DNA_STRAND
                ? MRNA_STRAND
                : strandName === MRNA_STRAND
                ? TRNA_STRAND
                : null;
            setFocus(initialStrand.length - 1, nextStrand);
        } else {
            setFocus(strandIndex - 1, strandName);
        }
    }

    return (
        <div css={containerStyle}>
            {initialValue !== null && <span css={styles.presetNucleotide}>{displayValue}</span>}
            {initialValue === null && <input css={inputStyle} type={"text"} value={displayValue}
                                             placeholder={NUCLEOTIDE_LETTER_MAP[mutationValue]}
                                             ref={ref} onChange={setNucleotide} onKeyDown={backspace} />}
        </div>
    )
}

const chooseTRNANucleotide = (initialStrand, userStrand, mutations, index) => {
    const trnaMutation = mutations.find(m => m.index === index && m.strand === TRNA_STRAND);
    const mrnaMutation = mutations.find(m => m.index === index && m.strand === MRNA_STRAND);
    const trnaNucleotide = (trnaMutation && trnaMutation.nucleotide) || initialStrand[index].trna;
    return trnaNucleotide || GetTranslationNucleotideMatch((userStrand[index] && userStrand[index].mrna) || (mrnaMutation && mrnaMutation.nucleotide) || initialStrand[index].mrna);
}

const StrandAminoAcid = ({widgetId, startIndex}) => {
    const [hovering, setHovering] = useState(false);
    const initialStrand = useSelector(state => getInitialStrand(state, widgetId));
    const userStrand = useSelector(state => getUserStrand(state, widgetId));
    const mutations = useSelector(state => getMutations(state, widgetId));
    const targetAminoAcids = useSelector(state => getTargetAminoAcids(state, widgetId));
    const target = targetAminoAcids[startIndex / 3];

    const nucleotides = [
        chooseTRNANucleotide(initialStrand, userStrand, mutations, startIndex),
        chooseTRNANucleotide(initialStrand, userStrand, mutations, startIndex + 1),
        chooseTRNANucleotide(initialStrand, userStrand, mutations, startIndex + 2),
    ];
    const aminoAcidLabel = getAminoAcidDisplayNameForNucleotides(nucleotides);
    const aminoAcidColor = isMutation && hovering ? getColorForShortAminoAcid(target) : getAminoAcidColorForNucleotides(nucleotides);

    const isMutation = target && aminoAcidLabel !== target && mutations.some(m => m.index === startIndex || m.index === startIndex + 1 || m.index === startIndex + 2);

    return (
        <div onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
            {isMutation && <div css={styles.flip}><FlipIcon /></div>}
            <div css={aminoAcidLabel ? styles.aminoAcidContainer(isMutation && !hovering ? "#ff0000" : aminoAcidColor) : styles.aminoAcidPlaceholder}>
                {aminoAcidLabel && <AminoAcid />}
                {aminoAcidLabel && <div css={aminoAcidLabel === STOP_CODON ? styles.stopLabel : styles.aminoAcidLabel}>{isMutation && hovering ? target : aminoAcidLabel}</div>}
            </div>
        </div>
    );
};

const CentralDogmaWidget = ({id}) => {
    const [nextFocusIndex, setNextFocusIndex] = useState();
    const [nextFocusStrand, setNextFocusStrand] = useState();
    const initialStrand = useSelector(state => getInitialStrand(state, id));
    const userStrand = useSelector(state => getUserStrand(state, id));
    const mutations = useSelector(state => getMutations(state, id));
    const targetAminoAcids = useSelector(state => getTargetAminoAcids(state, id));

    const aminoIndexes = Array.from({length: initialStrand.length / 3}, (_, i) => i * 3);
    const setFocus = (strandIndex, strandName) => {
        setNextFocusIndex(strandIndex);
        setNextFocusStrand(strandName);
    };

    const aminoForUserMrna = startIndex => getAminoAcidDisplayNameForNucleotides([
        GetTranslationNucleotideMatch(userStrand[startIndex] && userStrand[startIndex].mrna),
        GetTranslationNucleotideMatch(userStrand[startIndex+1] && userStrand[startIndex+1].mrna),
        GetTranslationNucleotideMatch(userStrand[startIndex+2] && userStrand[startIndex+2].mrna),
    ]);
    const aminoCorrect = startIndex => !targetAminoAcids[startIndex / 3] || targetAminoAcids[startIndex / 3] === aminoForUserMrna(startIndex);
    const showAsMutation = startIndex => {
        console.log(aminoCorrect(startIndex), startIndex, targetAminoAcids[startIndex / 3], aminoForUserMrna(startIndex), userStrand);
        return !aminoCorrect(startIndex) && mutations.some(m => m.index === startIndex || m.index === startIndex + 1 || m.index === startIndex + 2); // TODO: not working.
        // Probably need to have a selector to get all the amino acids that match their targets - this will also help with correctness checking
    }

    return (
        <div css={styles.container}>
            <div css={styles.strandContainer}>
                <div css={styles.strandEntryContainer}>
                    <div css={css(styles.rowHeaders, styles.uppercase)}>Amino acids</div>
                    <div css={styles.rowHeaders}>mRNA</div>
                    <div css={styles.rowHeaders}>DNA</div>
                </div>
                {aminoIndexes.map(i => <div key={i} css={styles.strandEntryContainer}>
                    <div css={showAsMutation(i) ? styles.codonContainerWithMutation : styles.codonContainer}>
                        {showAsMutation(i) && <div css={styles.codonMutation}>Mutated</div>}
                        <div css={showAsMutation(i) ? styles.strandSectionWithMutation : styles.strandSection}>
                            <StrandAminoAcid widgetId={id} startIndex={i} />
                        </div>
                        <div css={showAsMutation(i) ? styles.strandSectionWithMutation : styles.strandSection}>
                            <StrandNucleotide strandName={MRNA_STRAND} strandIndex={i} widgetId={id} focused={i === nextFocusIndex && nextFocusStrand === MRNA_STRAND} setFocus={setFocus} />
                            <StrandNucleotide strandName={MRNA_STRAND} strandIndex={i+1} widgetId={id} focused={i+1 === nextFocusIndex && nextFocusStrand === MRNA_STRAND} setFocus={setFocus} />
                            <StrandNucleotide strandName={MRNA_STRAND} strandIndex={i+2} widgetId={id} focused={i+2 === nextFocusIndex && nextFocusStrand === MRNA_STRAND} setFocus={setFocus} />
                        </div>
                        <div css={showAsMutation(i) ? styles.strandSectionWithMutation : styles.strandSection}>
                            <StrandNucleotide strandName={DNA_STRAND} strandIndex={i} widgetId={id} focused={i === nextFocusIndex && nextFocusStrand === DNA_STRAND} setFocus={setFocus} />
                            <StrandNucleotide strandName={DNA_STRAND} strandIndex={i+1} widgetId={id} focused={i+1 === nextFocusIndex && nextFocusStrand === DNA_STRAND} setFocus={setFocus} />
                            <StrandNucleotide strandName={DNA_STRAND} strandIndex={i+2} widgetId={id} focused={i+1 === nextFocusIndex && nextFocusStrand === DNA_STRAND} setFocus={setFocus} />
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    );
};

export default CentralDogmaWidget;
