export const ADD_PENDING_SCORING_EVENT = "add pending scoring event";
export const addPendingScoringEvent = (scorerId, deltaScore) => ({
    type: ADD_PENDING_SCORING_EVENT,
    deltaScore,
    scorerId,
});

export const PENDING_SCORING_EVENT_COMPLETED = "pending scoring event completed";
export const pendingScoringEventCompleted = (scorerId) => ({
    type: PENDING_SCORING_EVENT_COMPLETED,
    scorerId,
});
