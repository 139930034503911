export const SET_EXPLAINER_CLAIM = "set explainer claim";
export const setExplainerClaim = (curriculumId, explainerId, claim) => ({
    type: SET_EXPLAINER_CLAIM,
    curriculumId,
    explainerId,
    claim,
});

export const ADD_EVIDENCE_TO_EXPLAINER = "add evidence to explainer";
export const addEvidenceToExplainer = (curriculumId, explainerId, evidenceIds) => ({
    type: ADD_EVIDENCE_TO_EXPLAINER,
    curriculumId,
    explainerId,
    evidenceIds,
});

export const UPDATE_EVIDENCE_ORDER = "update evidence order";
export const updateEvidenceOrder = (curriculumId, explainerId, evidenceId, newIndex) => ({
    type: UPDATE_EVIDENCE_ORDER,
    curriculumId,
    explainerId,
    evidenceId,
    newIndex,
});

export const REMOVE_EVIDENCE_FROM_EXPLAINER = "remove evidence from explainer";
export const removeEvidenceFromExplainer = (curriculumId, explainerId, evidenceIds) => ({
    type: REMOVE_EVIDENCE_FROM_EXPLAINER,
    curriculumId,
    explainerId,
    evidenceIds,
});

export const SET_EXPLAINER_REASONING = "set explainer reasoning";
export const setExplainerReasoning = (curriculumId, explainerId, reasoning) => ({
    type: SET_EXPLAINER_REASONING,
    curriculumId,
    explainerId,
    reasoning,
});
