import React, {useEffect, useRef, useState} from 'react';
import {css, keyframes} from "@emotion/core";
import {useDispatch, useSelector} from "react-redux";
import {
    canLysosomeEat,
    getLysosomeTargetDuration,
    getLysosomeTargetOrganelleId,
    getLysosomeTargetStartTime,
    getLysosomeTargetX,
    getLysosomeTargetY
} from "../../../../store/interactions/dogmaDash/selectors";
import {lysosomeConsumeAt} from "../../../../store/interactions/dogmaDash/actions";
import {useParams} from "@reach/router";
import recylerEnzymeSprite from './Assets/recyclerSprite.png';
import ReccyclerAlertIcon from './inline-assets/no-recycler.svg';
import {dogmashBlue, dogmashRed} from "./dogma-dash-styles";
import {darken} from "color2k";
import {absoluteCenter} from "../../../../style-variables";

const defaultX = 829;
const defaultY = 150;

const baseStyle = css({
    width: "53px",
    height: "53px",
    position: "absolute",
    left: `${defaultX}px`,
    top: `${defaultY}px`,
    border: `1px solid ${dogmashBlue}`,
    borderRadius: "50%",
    boxSizing: "border-box",
    backgroundColor: darken(dogmashBlue, 0.34),

    "& > svg": {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: "translate(-50%, -50%)",
        fill: dogmashBlue,
        width: "27px",
        height: "27px"
    },
});
const noEatStyle = css(baseStyle, {
    "& > svg": {
        fill: dogmashRed,
        stroke: dogmashRed,
        width: "38px",
        height: "38px"
    },
});

const eatFrames = 25;
const frameWidth = 40;
export const LysosomeEatTime = 2400; // TODO: LYSOSOME MOVE AND ANIMATION TIMES!
const recyclerEatAnimation = keyframes({
    "100%": {backgroundPositionX: `-${frameWidth * eatFrames}px`},
});
const recycler = css(absoluteCenter, {
    width: `${frameWidth}px`,
    height: `${frameWidth}px`,
    backgroundImage: `url(${recylerEnzymeSprite})`,
    backgroundPositionX: 0,
    backgroundRepeat: "no-repeat",
});
const recyclerEating = css(recycler, {
    animation: `${recyclerEatAnimation} ${(LysosomeEatTime+100)/3}ms steps(${eatFrames}) infinite`,
});

const Lysosome = ({id}) => {
    const {curriculumId} = useParams();
    const canEat = useSelector(state => canLysosomeEat(state, id));
    const targetX = useSelector(state => getLysosomeTargetX(state, id));
    const targetY = useSelector(state => getLysosomeTargetY(state, id));
    const [initialX, setInitialX] = useState();
    const [initialY, setInitialY] = useState();
    const targetOrganelle = useSelector(state => getLysosomeTargetOrganelleId(state, id));
    const movementStart = useSelector(state => getLysosomeTargetStartTime(state, id));
    const movementDuration = useSelector(state => getLysosomeTargetDuration(state, id));
    const ref = useRef();
    const animationRef = useRef();
    const dispatch = useDispatch();
    const [eating, setEating] = useState(false);

    useEffect(() => {
        const {x, y} = ref.current.getBoundingClientRect();
        setInitialX(x);
        setInitialY(y);
    }, []);

    useEffect(() => {
        setEating(false);
        if(!initialX || !initialY) { return; }
        const match = ref.current.style.transform.match(/translate\((-?\d+\.?\d+?)px, (-?\d+\.?\d+?)px\)/);
        const startX = match ? parseFloat(match[1]) : 0;
        const startY = match ? parseFloat(match[2]) : 0;
        const finalTargetX = !targetX ? 0 : targetX - initialX;
        const finalTargetY = !targetY ? 0 : targetY - initialY;
        const move = () => {
            let p = Math.max(0, Math.min(1, (Date.now() - movementStart) / (movementDuration - LysosomeEatTime)));
            let x = startX + (finalTargetX - startX) * p;
            let y = startY + (finalTargetY - startY) * p;

            ref.current.style.transform = `translate(${x}px, ${y}px)`;
            if(p < 1) {
                animationRef.current = requestAnimationFrame(move);
            } else if(p === 1 && targetOrganelle) {
                setEating(true);
                dispatch(lysosomeConsumeAt(id, targetOrganelle, curriculumId));
            }
        };
        animationRef.current = requestAnimationFrame(move);
        return () => {
            cancelAnimationFrame(animationRef.current);
        };
    }, [targetX, targetY, initialY]);

    return <div ref={ref} css={canEat ? baseStyle : noEatStyle}>
        {canEat ? <div css={eating ? recyclerEating : recycler} /> : <ReccyclerAlertIcon />}
    </div>
};

export default Lysosome;
