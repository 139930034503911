import {call, takeEvery} from 'redux-saga/effects';
import {sendGraphQLSaga} from "../../DataService";
import {ADD_INTERACTABLE_TO_TASK} from "../../../store/curriculumTaskItems/actions";
import {ADD_ITEM_TO_SCROLLING_TASK} from "../../../store/scrollingTaskItemPositions/actions";
import {setScrollingItemPositions} from "./updateScrollingItem";

const query = `mutation AddItemToTask($input: AddItemToTaskInput!) {
    addItemToTask(input: $input) {
        task {
            id
        }
    }
}`;

function* executeInteractable(action) {
    const variables = {
        input: {
            curriculum: action.curriculumId,
            task: action.taskId,
            item: action.interactableId,
            type: action.interactableType,
        },
    };
    yield call(sendGraphQLSaga, query, variables);
}

function* executeScrolling(action) {
    const {curriculumId: curriculum, itemId: item} = action;
    const addItemQuery = {
        input: {
            curriculum,
            task: action.taskId,
            item,
            type: action.itemType,
        },
    };
    yield call(sendGraphQLSaga, query, addItemQuery);
    yield call(setScrollingItemPositions, action);
}

export default [
    takeEvery(ADD_INTERACTABLE_TO_TASK, executeInteractable),
    takeEvery(ADD_ITEM_TO_SCROLLING_TASK, executeScrolling),
];
