import * as styles from './section-style';
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getScrollerSectionBackgroundColor} from "../../../../../store/scrollerSections/selectors";
import {isItemEditing} from "../../../../../store/itemSelection/selector";
import {setScrollerSectionBackgroundColor} from "../../../../../store/scrollerSections/actions";
import {useParams} from "@reach/router";
import {css} from "@emotion/core";
import {ChromePicker} from 'react-color';

export default ({id}) => {
    const {curriculumId} = useParams();
    const backgroundColor = useSelector(state => getScrollerSectionBackgroundColor(state, id));
    const editing = useSelector(state => isItemEditing(id, state));
    const dispatch = useDispatch();

    const style = css(styles.section, {
        backgroundColor,
    });

    const setBackground = color => dispatch(setScrollerSectionBackgroundColor(curriculumId, id, color.hex));

    return (
        <div css={style}>
            {editing && <ChromePicker onChange={setBackground} color={backgroundColor} />}
        </div>
    );
};
