import * as styles from './section-task-styles';
import React from 'react';
import {shallowEqual, useSelector} from "react-redux";
import {getCurriculumTaskContent, getCurriculumTaskItemIds} from "../../store/curriculumTask/selectors";
import RichTextDisplay from "./RichTextDisplay";
import {
    CENTRAL_DOGMA_WIDGET,
    CPR,
    DRAGGABLE_BANK,
    INPUT_TABLE,
    LONG_TEXT_INPUT,
    MRNA_ACTIVITY,
    MULTIPLE_CHOICE_INPUT,
    SCROLLER_EXPLAINER,
    SCROLLER_EXPLAINER_PREDICTION,
    UTILITY_BUTTON,
} from "../../constants/TaskTypes";
import LongTextInput from "./Interactables/LongTextInput";
import MultipleChoiceInput from "./Interactables/MultipleChoiceInput";
import InputTable from "./Interactables/InputTable";
import DraggableBank from "./Interactables/DraggableBank";
import CPRView from './Interactables/CPR';
import {useNavigate, useParams} from "@reach/router";
import {getNextTaskInCurriculum, getPreviousTaskInCurriculum} from "../../store/curriculumCases/selectors";
import {getCurriculumTaskItemType} from "../../store/curriculumTaskItems/selectors";
import ExplainerInteractable from "./Interactables/ExplanationBuilder/Explainer";
import ExplainerPrediction from "./Interactables/ExplanationBuilder/ExplainerPrediction";
import MrnaActivity from './Interactables/MrnaActivity';
import CentralDogmaWidget from "./Interactables/CentralDogmaWidget";
import UtilityButton from "./Interactables/UtilityButton";
import {useTheme} from "@emotion/react";
import {themedBodyButton} from "../../style-variables";

const InteractableTags = {
    [LONG_TEXT_INPUT]: LongTextInput,
    [MULTIPLE_CHOICE_INPUT]: MultipleChoiceInput,
    [INPUT_TABLE]: InputTable,
    [DRAGGABLE_BANK]: DraggableBank,
    [CPR]: CPRView,
    [SCROLLER_EXPLAINER]: ExplainerInteractable,
    [SCROLLER_EXPLAINER_PREDICTION]: ExplainerPrediction,
    [MRNA_ACTIVITY]: MrnaActivity,
    [CENTRAL_DOGMA_WIDGET]: CentralDogmaWidget,
    [UTILITY_BUTTON]: UtilityButton,
};

const SectionTaskLearner = ({taskId}) => {
    const {curriculumId} = useParams();
    const content = useSelector(state => getCurriculumTaskContent(state, taskId));
    const interactables = useSelector(state => getCurriculumTaskItemIds(state, taskId).filter(id => InteractableTags.hasOwnProperty(getCurriculumTaskItemType(state, id))), shallowEqual);
    const interactableTypes = useSelector(state => interactables.map(i => getCurriculumTaskItemType(state, i)), shallowEqual);
    const nextTask = useSelector(state => getNextTaskInCurriculum(state, curriculumId, taskId));
    // const previousTask = useSelector(state => getPreviousTaskInCurriculum(state, curriculumId, taskId));
    const navigate = useNavigate();
    const theme = useTheme();

    const navigateForward = () => navigate(`./${nextTask}`);
    // const navigateBack = () => navigate(`./${previousTask}`);

    return (
        <div css={styles.task}>
            <div css={styles.themedTaskContentContainer(theme)}>
                <RichTextDisplay content={content} />
                {interactables.map((id, i) => {
                    const Tag = InteractableTags[interactableTypes[i]];
                    return (
                        <div key={id} css={styles.interactableContainer}>
                            <Tag id={id} />
                        </div>
                    );
                })}
            </div>
            {nextTask && <button onClick={navigateForward} css={[themedBodyButton(theme), styles.continueButton]}>
                Continue
            </button>}
            {/*{previousTask && <button onClick={navigateBack} css={styles.themePreviousButton(theme)}>*/}
            {/*    <Arrow />*/}
            {/*    Previous*/}
            {/*</button>}*/}
        </div>
    )
};

export default SectionTaskLearner;
