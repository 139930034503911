import * as styles from './button-bar-styles';
import React from 'react';
import {Editor, Transforms} from "slate";
import combineStyles from "../../Utility/combineStyles";
import {useSlate} from "slate-react";
import HeaderType from "./HeaderType";
import TextColorButton from "./TextColorButton";

const isMarkActive = (editor, format) => {
    try {
        const marks = Editor.marks(editor);
        return marks ? marks[format] === true : false;
    } catch(exception) {
        return false;
    }
};
const toggleInlineStyle = (editor, format, toggle) => {
    if(toggle) {
        editor.addMark(format, true);
    } else {
        editor.removeMark(format);
    }
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const toggleBlockType = (editor, blockType, toggle) => {
    const isList = LIST_TYPES.includes(blockType);

    Transforms.unwrapNodes(editor, {
        match: n => LIST_TYPES.includes(n.type),
        split: true,
    });

    Transforms.setNodes(editor, {
        type: !toggle ? 'paragraph' : isList ? 'list-item': blockType,
    });

    if(toggle && isList) {
        Transforms.wrapNodes(editor, {
            type: blockType,
            children: [],
        });
    }
};
const blockTypeActive = (editor, blockType) => {
    const [match] = Editor.nodes(editor, {
        match: n => n.type === blockType,
    });
    return !!match;
};
const ScrollingRTEditor = ({editorId}) => {
    return (
        <div css={styles.bar}>
            <BarButton styleType="bold" styleTag="boldButton" isActive={isMarkActive} onToggle={toggleInlineStyle} />
            <BarButton styleType="italic" styleTag="italicButton" isActive={isMarkActive} onToggle={toggleInlineStyle} />
            <HeaderType />
            <TextColorButton />
        </div>
    )
}

const BarButton = props => {
    const editor = useSlate();
    const active = props.isActive(editor, props.styleType);

    const toggle = e => {
        e.preventDefault();
        e.stopPropagation();
        props.onToggle(editor, props.styleType, !active);
    };

    return (
        <button onMouseDown={toggle} css={combineStyles(styles,{
            'button': true,
            [props.styleTag]: true,
            'buttonActive': active,
        })} />
    )
};

export default ScrollingRTEditor;
