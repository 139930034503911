import {SET_AUTHENTICATION_STATE} from "../identity/actions";
import {NOT_LOGGED_IN} from "../../constants/AuthenticationState";
import {EMPTY_INTERACTIONS} from "../interactions/actions";
import {ADVANCE_TUTORIAL_STRAND} from "./actions";

const defaultTutorialData = {
    currentItem: 1,
};

export default (state = {}, action) => {
    const {strandId} = action;
    if(action.type === ADVANCE_TUTORIAL_STRAND) {
        if(!state[strandId]) {
            return {
                ...state,
                [strandId]: {
                    ...defaultTutorialData,
                },
            };
        }
        return {
            ...state,
            [strandId]: {
                ...state[strandId],
                currentItem: state[strandId].currentItem + 1,
            },
        };
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    if(action.type === EMPTY_INTERACTIONS) {
        return {};
    }
    return state;
};
