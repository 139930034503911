import {CREATE_STICKY_NOTE, SET_STICKY_NOTE_TEXT, STICKY_NOTE_TEXT_UPDATED, STICKY_NOTES_LOADED} from "./actions";
import {SET_AUTHENTICATION_STATE} from "../identity/actions";
import {NOT_LOGGED_IN} from "../../constants/AuthenticationState";
import {CLONE_WORK_BOARD_ITEM} from "../workBoards/actions";

const stickyNotePrototype = {
    text: "",
};

export default (state = {}, action) => {
    if(action.type === CREATE_STICKY_NOTE) {
        return Object.assign({}, state, {
            [action.id]: Object.assign({}, stickyNotePrototype, {id: action.id}),
        });
    }
    if(action.type === CLONE_WORK_BOARD_ITEM) {
        const original = state[action.itemId];
        if(!original) { return state; }
        return {
            ...state,
            [action.newId]: {
                ...original,
                id: action.newId,
            }
        }
    }
    if(action.type === SET_STICKY_NOTE_TEXT || action.type === STICKY_NOTE_TEXT_UPDATED) {
        return Object.assign({}, state, {
            [action.id]: Object.assign({}, state[action.id], {
                text: action.text,
            }),
        });
    }
    if(action.type === STICKY_NOTES_LOADED) {
        const newNotes = action.notes.reduce((agg, note) => {
            agg[note.id] = note;
            return agg;
        }, {});
        return Object.assign({}, state, newNotes);
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return {};
    }
    return state;
}
