export const HEIGHT = "height";
export const FLOWER_COLOR = "flowerColor";
export const FLOWER_POSITION = "flowerPosition";
export const SEED_COLOR = "seedColor";
export const SEED_SHAPE = "seedShape";
export const POD_COLOR = "podColor";
export const POD_SHAPE = "podShape";

export const TRAIT_DISPLAY_NAMES = {
    [HEIGHT]: "Height",
    [FLOWER_COLOR]: "Flower color",
    [FLOWER_POSITION]: "Flower position",
    [SEED_COLOR]: "Pea color",
    [SEED_SHAPE]: "Pea shape",
    [POD_COLOR]: "Pod color",
    [POD_SHAPE]: "Pod shape",
};
