import {css, keyframes} from "@emotion/core";
import {inlineBlockTop} from "../../../../style-variables";
// import iconImage from './Assets/mRNAIcon.png';
import {
    dogmashBlue,
} from "./dogma-dash-styles";

export const slotContainer = css(inlineBlockTop, {
    marginRight: "10px",

    "&:last-of-type": {
        marginRight: 0,
    },
});

export const slot = css({
    width: "40px",
    height: "24px",
    position: "relative",
    borderRight: `3px solid ${dogmashBlue}`,
    borderLeft: `3px solid ${dogmashBlue}`,
    margin: "13px 0",

    "&:before,&:after": {
        content: '""',
        display: "block",
        width: "23.1px",
        height: "23.1px",
        transform: "rotate(45deg) skew(-15deg, -15deg)",
        transformOrigin: "0 0",
        position: "absolute",
        left: "20px",
    },
    "&:before": {
        borderTop: `3px solid ${dogmashBlue}`,
        borderLeft: `3px solid ${dogmashBlue}`,
        top: "-13px",
    },
    "&:after": {
        borderRight: `3px solid ${dogmashBlue}`,
        borderBottom: `3px solid ${dogmashBlue}`,
        bottom: "-13px",
    },
});
// export const hoveringSlot = css(slot, {
//     borderColor: dropSlotHover,
//     transform: `scale(${slotHoverScale})`,
// });
// export const slotDropAnimation = css(slot, {
//     animation: `${dropKeyFrames} 0.5s`,
//     animationTimingFunction: "cubic-bezier(0.4, 0, 0.65, 1.2)",
// });
//
// export const heldName = css({
//     position: "absolute",
//     bottom: "100%",
//     left: "50%",
//     transform: "translateX(-50%)",
//     textTransform: "uppercase",
//     fontSize: "1.25em",
//     color: "#fff",
//     whiteSpace: "nowrap",
// });
//
// export const mrnaIcon = css({
//     width: "100%",
//     height: "100%",
//     backgroundImage: `url(${iconImage})`,
//     backgroundSize: "51px 69px",
//     backgroundPosition: "center",
//     backgroundRepeat: "no-repeat",
// });
