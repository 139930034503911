import {call, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {sendGraphQLSaga} from "../../DataService";
import {CREATE_STICKY_NOTE, SET_STICKY_NOTE_TEXT} from "../../../store/stickyNotes/actions";
import {getActiveLearner} from "../../../store/navigation/selectors";

const createStickyNoteQuery = `mutation CreateStickyNote($input: CreateStickyNoteInput!) {
    createStickyNote(input: $input) {
        stickyNote {
            id
        }
    }
}`;

export function* executeCreateStickyNote(action) {
    const learnerId = yield select(state => getActiveLearner(state));
    if(!learnerId) { return; }

    const variables = {
        input: {
            id: action.id
        },
    };

    yield call(sendGraphQLSaga, createStickyNoteQuery, variables);
}

const setStickyNoteTextQuery = `mutation SetStickyNoteText($input: SetStickyNoteTextInput!) {
    setStickyNoteText(input: $input) {
        stickyNote {
            id
        }
    }
}`;
export function* executeSetStickyNoteText(action) {
    const learnerId = yield select(state => getActiveLearner(state));
    if(!learnerId) { return; }

    const variables = {
        input: {
            id: action.id,
            text: action.text,
        },
    };

    yield call(sendGraphQLSaga, setStickyNoteTextQuery, variables);
}

export default [
    takeEvery(CREATE_STICKY_NOTE, executeCreateStickyNote),
    takeLatest(SET_STICKY_NOTE_TEXT, executeSetStickyNoteText),
];
