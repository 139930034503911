import {
    getDogmaCentrioles,
    getDogmaDashAvailableProteins,
    getDogmaDashCytoplasm,
    getDogmaDashLevelUnlockingProtein,
    getDogmaGolgiBodyIds,
    getDogmaLysosomeIds,
    getDogmaMicrotubuleIds,
    getDogmaMrnaSlotIds,
    getDogmaRibosomeIds,
    getLysosomeTargetOrganelleId,
    getLysosomeTargetStartTime,
    getNumberOfCargo,
    getObjectForm,
    getObjectsInOrganelle,
    getObjectType,
    getOrganelleTimerDuration,
    getOrganelleTimerStart,
    getOrganelleTimerType
} from "../../interactions/dogmaDash/selectors";
import {
    DefaultCargo,
    Motor,
    ProteinForm_Denatured,
    ProteinForm_Protein,
    ProteinForm_ProteinInVesicle
} from "../../../constants/DogmaDash";
import {mrnaInSlot, proteinFormAtOrganelle, proteinTypeAtOrganelle} from "./utility";
import {MRNA_INVENTORY_PROTEIN_TUTORIAL} from "./dogma-strand-antibody-unlock";

export const DOGMA_DASH_DASHBOARD = "dogmashboard";
export const DOGMA_DASH_ALERT_LIST = "dogmash alert list";
export const DOGMA_DASH_MRNA_INVENTORY = "dogmash signal list";
export const DOGMA_DASH_NUCLEUS = "dogmash nucleus";
export const DOGMA_DASH_ORGANELLE_DEBRIS = "dogmash organelle debris";
export const DOGMA_DASH_CARGO_METER_TUTORIAL = "dogmash cargo meter tutorial";

export default {
    id: "a9d63406-7145-4869-9240-5de2ea32177d",
    items: [
        {
            condition: (state, {location, dogmaDashId}) => {
                if(!dogmaDashId) { return false; }
                const availableProteins = getDogmaDashAvailableProteins(state, dogmaDashId);
                const unlockingProtein = getDogmaDashLevelUnlockingProtein(state, dogmaDashId);
                return location === DOGMA_DASH_DASHBOARD && availableProteins.length === 1 && !unlockingProtein;
            },
            header: "Mission Control",
            text: "This control panel connects you to Cell X, so you can help the drone pilot accomplish missions.",
            forceCompletion: (state, {dogmaDashId}) => getDogmaLysosomeIds(state, dogmaDashId).some(l => !!getLysosomeTargetOrganelleId(state, l))
        },
        {
            condition: (state, {location, organelleId}) => {
                if(location !== DOGMA_DASH_ORGANELLE_DEBRIS) { return false; }
                return getObjectsInOrganelle(state, organelleId).filter(o => getObjectForm(state, o) === ProteinForm_Denatured).length > 0;
            },
            header: "Clear Debris",
            text: "You have control of the Lysosome, the cell’s recycler system.",
            callToAction: "Click debris to break it down",
            completionCondition: (state, {organelleId}) => {
                return getObjectsInOrganelle(state, organelleId).filter(o => getObjectForm(state, o) === ProteinForm_Denatured).length === 0;
            },
        },
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== DOGMA_DASH_ALERT_LIST || !dogmaDashId) { return false; }
                const centrioles = getDogmaCentrioles(state, dogmaDashId);
                const withAlerts = centrioles.filter(oId => !!getOrganelleTimerDuration(state, oId) && getOrganelleTimerType(state, oId) === "Alert");
                return withAlerts.length > 0;
            },
            header: "Alert List",
            text: "Cargo is building up. The cell needs a delivery protein to remove cargo from the cell.",
            forceCompletion: (state, {dogmaDashId}) => mrnaInSlot(state, dogmaDashId, Motor),
        },
        {
            condition: (state, {location}) => {
                return location === DOGMA_DASH_MRNA_INVENTORY;
            },
            header: "mRNA Inventory",
            text: "mRNA holds the code used to build proteins. Right now, you have the code for <b>Delivery Protein</b>.",
            forceCompletion: (state, {dogmaDashId}) => mrnaInSlot(state, dogmaDashId, Motor),
        },
        {
            condition: (state, {location}) => {
                return location === DOGMA_DASH_NUCLEUS;
            },
            header: "RNA Drop Sites",
            text: "You can send mRNA codes to your drone pilot by dropping the mRNA into these slots.",
            callToAction: "Drag and drop Delivery mRNA",
            completionCondition: (state, {dogmaDashId}) => {
                if(!dogmaDashId) {return false;}
                return mrnaInSlot(state, dogmaDashId, Motor);
            },
        },
        {
            condition: (state, {location}) => location === DOGMA_DASH_NUCLEUS,
            header: "mRNA is in the Portal",
            callToAction: "Check in with The Pilot, tell them to grab the mRNA from the portal in front of the nucleus",
            completionCondition: (state, {location, dogmaDashId}) => {
                if(location !== DOGMA_DASH_NUCLEUS || !dogmaDashId) {return false;}
                const mrnaSlots = getDogmaMrnaSlotIds(state, dogmaDashId);
                return !mrnaSlots.some(oId => getObjectsInOrganelle(state, oId).length > 0);
            },
        },
        { // try to avoid skips... really should track that down...
            condition: state => true,
            forceCompletion: () => true,
        },
        {
            condition: (state, {location, dogmaDashId, organelleId}) => {
                if(location === DOGMA_DASH_ORGANELLE_DEBRIS || getDogmaRibosomeIds(state, dogmaDashId).indexOf(organelleId) < 0) {return false;}
                return getObjectsInOrganelle(state, organelleId).length > 0;
            },
            header: "Translation in Progress",
            text: "The pilot has inserted the Delivery Protein mRNA into the <b>ribosome</b. Now it’s being translated into a protein.",
            callToAction: "Keep track of translation timer",
            completionCondition: (state, {organelleId}) => {
                if(!organelleId) { return false; }
                return proteinFormAtOrganelle(state, organelleId, ProteinForm_Protein);
            },
        },
        {
            condition: (state, {location, dogmaDashId, organelleId}) => {
                if(location === DOGMA_DASH_ORGANELLE_DEBRIS || getDogmaRibosomeIds(state, dogmaDashId).indexOf(organelleId) < 0) {return false;}
                return getObjectsInOrganelle(state, organelleId).length > 0;
            },
            header: "Translation Complete",
            callToAction: "Make sure the pilot grabs the protein before it gets broken down by the cell.",
            completionCondition: (state, {organelleId}) => {
                if(!organelleId) {return false;}
                return getObjectsInOrganelle(state, organelleId).filter(o => getObjectForm(state, o) !== ProteinForm_Denatured).length === 0;
            },
            alert: true,
        },
        {
            condition: (state, {location, dogmaDashId, organelleId}) => {
                if(location === DOGMA_DASH_ORGANELLE_DEBRIS || getDogmaGolgiBodyIds(state, dogmaDashId).indexOf(organelleId) < 0) {return false;}
                return getObjectsInOrganelle(state, organelleId).length > 0;
            },
            header: "Packaging in Progress",
            text: "The pilot took the protein to the golgi. It is getting folded and packaged into its final form.",
            forceCompletion: (state, {organelleId}) => getOrganelleTimerType(state, organelleId) === "Burning",
        },
        {
            condition: (state, {location, dogmaDashId, organelleId}) => {
                if(location === DOGMA_DASH_ORGANELLE_DEBRIS || getDogmaGolgiBodyIds(state, dogmaDashId).indexOf(organelleId) < 0) {return false;}
                return proteinFormAtOrganelle(state, organelleId, ProteinForm_ProteinInVesicle);
            },
            header: "Protein Ready",
            callToAction: "Make sure the pilot grabs the delivery protein to the microtubules before the cell breaks it down!",
            completionCondition: (state, {organelleId}) => {
                if(!organelleId) {return false;}
                return getObjectsInOrganelle(state, organelleId).filter(o => getObjectForm(state, o) !== ProteinForm_Denatured).length === 0;
            },
            alert: true,
        },
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== DOGMA_DASH_CARGO_METER_TUTORIAL) {return false;}
                return getDogmaMicrotubuleIds(state, dogmaDashId).some(m => proteinTypeAtOrganelle(state, m, DefaultCargo));
            },
            forceCompletion: () => true,
        },
        {
            condition: (state, {location}) => location === DOGMA_DASH_CARGO_METER_TUTORIAL,
            header: "Delivery Protein Working!",
            text: "The cargo level will decrease as the pilot loads cargo onto the delivery proteins.  Below halfway, the alert wil clear.",
        },
        {
            condition: (state, {location, dogmaDashId, protein}) => {
                if(location !== MRNA_INVENTORY_PROTEIN_TUTORIAL || protein !== Motor || !dogmaDashId) { return false; }
                const microtubules = getDogmaMicrotubuleIds(state, dogmaDashId);
                const numberOfMotors = microtubules.reduce((agg, oId) => agg + getObjectsInOrganelle(state, oId).filter(o => getObjectType(state, o) === Motor).length, 0);
                if(numberOfMotors === 0 || numberOfMotors > 2) {return  false;} // THIS MIGHT BE STRANGE - MAY NEED A WAY TO SKIP TUTORIALS - check complete to move forward when looking for data?
                return getNumberOfCargo(state) >= 2;
            },
            width: 460,
            header: "Make Another Delivery Protein",
            text: "With more delivery proteins, the pilot can manage the cargo levels more easily.",
            callToAction: "Drag and drop Delivery mRNA",
            completionCondition: (state, {dogmaDashId}) => {
                if(getNumberOfCargo(state) < 1) { return true; }
                const mrnaSlots = getDogmaMrnaSlotIds(state, dogmaDashId);
                return mrnaSlots.some(oId => getObjectsInOrganelle(state, oId).filter(o => getObjectType(state, o) === Motor).length > 0);
            },
        },
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== DOGMA_DASH_ALERT_LIST) {return false;}
                const centriole = getDogmaCentrioles(state, dogmaDashId);
                return !centriole.some(c => !!getOrganelleTimerStart(state, c) && getOrganelleTimerType(state, c) === "Alert");
            },
            header: "Alert Cleared!",
            text: "Cargo levels under control. Each time you clear an alert before the time runs out, you’ll get points.",
        }
    ]
};
