import {useEffect, useRef} from "react";

export default (scrollerRef, itemRef, itemPositions, locked, resizeData = {}, yOffset = 0) => {
    let animation = useRef();
    useEffect(() => {
        const updatePosition = () => {
            const currentScroll = scrollerRef.current.scrollTop;
            const startWidth = itemPositions[0].width || 100;
            const startHeight = resizeData.y !== 0 ? itemPositions[0].height || 100 : itemRef.current.getBoundingClientRect().height; // itemPositions[0].height || 100;
            if(resizeData && locked && !itemPositions[1].scroll) {
                if(resizeData.x) {
                    itemRef.current.style.width = `${startWidth}px`;
                }
                if(resizeData.y) {
                    itemRef.current.style.height = `${startHeight}px`;
                }
                return;
            }
            const startX = itemPositions[0].x;
            const endX = itemPositions[1].x !== null ? itemPositions[1].x : itemPositions[0].x;
            const startScroll = (itemPositions[0].scroll !== null ? itemPositions[0].scroll : currentScroll) + yOffset;
            const startY = itemPositions[0].y + yOffset;
            const endWidth = itemPositions[1].width || startWidth;
            const endHeight = itemPositions[1].height || startHeight;
            let endY = (itemPositions[1].y || itemPositions[0].y) + yOffset;
            let endScroll = (itemPositions[1].scroll || startScroll) + yOffset;
            if(!locked) {
                endY += Math.max(0, currentScroll - endScroll);
                endScroll = Math.max(currentScroll, endScroll);
            } else if(resizeData.variableHeight) {
                endY -= endHeight;
            }
            const percent = endScroll === startScroll
                ? 0
                : Math.max(0, Math.min(1, (currentScroll - startScroll) / (endScroll - startScroll)));
            const deltaX = percent * (endX - startX);
            const deltaY = percent * (endY - startY);
            const width = startWidth + percent * (endWidth - startWidth);
            const height = startHeight + percent * (endHeight - startHeight);
            itemRef.current.style.transform = `translate(${deltaX}px, ${deltaY}px)`;
            if(resizeData) {
                if(resizeData.x) {
                    itemRef.current.style.width = `${width}px`;
                }
                if(resizeData.y) {
                    itemRef.current.style.height = `${height}px`;
                }
            }
            animation.current = requestAnimationFrame(updatePosition);
        };
        updatePosition();
        itemRef.current.style.opacity = 1; // in case the page loads scrolled down.

        return () => animation.current && cancelAnimationFrame(animation.current);
    }, [scrollerRef.current, itemRef.current, itemPositions, locked]);
}
