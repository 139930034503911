import {DOGMA_DASH_ALERT_LIST, DOGMA_DASH_MRNA_INVENTORY, DOGMA_DASH_NUCLEUS} from "./dogma-strand-1";
import {
    getCurrentDogmaDashLevel,
    getDogmaDashAvailableProteins,
    getDogmaDashCytoplasm,
    getDogmaDashLevelUnlockingProtein,
    getDogmaGolgiBodyIds,
    getDogmaMicrotubuleIds,
    getDogmaRibosomeIds,
    getOrganelleTimerStart,
    getOrganelleTimerType
} from "../../interactions/dogmaDash/selectors";
import {MRNA_INVENTORY_PROTEIN_TUTORIAL, VIRAL_LOAD_TUTORIAL} from "./dogma-strand-antibody-unlock";
import {mrnaInSlot, proteinTypeAtOrganelle} from "./utility";
import {Antibody, DefaultCargo, Motor} from "../../../constants/DogmaDash";

export default {
    id: "744d2c57-fdf6-43d6-b1fe-5592b888fb54",
    items: [
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== DOGMA_DASH_ALERT_LIST) {return false;}
                const cyto = getDogmaDashCytoplasm(state, dogmaDashId);
                return !!cyto
                    && getOrganelleTimerStart(state, cyto)
                    && getOrganelleTimerType(state, cyto) === "Alert"
                    && getDogmaDashAvailableProteins(state, dogmaDashId).length === 5
                    && !getDogmaDashLevelUnlockingProtein(state, dogmaDashId)
                    && getCurrentDogmaDashLevel(state, dogmaDashId) === 1;
            },
            header: "New Alert Type",
            text: "The Pandoravirus has been detected in the blood.",
            forceCompletion: (state, {dogmaDashId}) => mrnaInSlot(state, dogmaDashId, Antibody),
        },
        {
            condition: (state, {location, protein}) => location === MRNA_INVENTORY_PROTEIN_TUTORIAL && protein === Antibody,
            width: 460,
            header: "Antibody mRNA",
            text: "The pilot needs to send antibodies out of the cell to fight off the virus.",
            callToAction: "Send the antibody mRNA to the pilot",
            completionCondition: (state, {dogmaDashId}) => mrnaInSlot(state, dogmaDashId, Antibody),
        },
        {
            condition: (state, {location, dogmaDashId}) => location === DOGMA_DASH_NUCLEUS
                && !mrnaInSlot(state, dogmaDashId, Antibody),
            header: "Delivery mRNA",
            text: "Antibodies need to be sent out of the cell to neutralize viruses in your bloodstream. You need a <b>Delivery Protein</b> to carry them out.",
            callToAction: "Send your pilot a delivery protein mRNA",
            completionCondition: (state, {dogmaDashId}) => mrnaInSlot(state, dogmaDashId, Motor)
                || getDogmaRibosomeIds(state, dogmaDashId).some(r => proteinTypeAtOrganelle(state, r, Motor))
                || getDogmaGolgiBodyIds(state, dogmaDashId).some(g => proteinTypeAtOrganelle(state, g, Motor))
                || getDogmaMicrotubuleIds(state, dogmaDashId).some(m => proteinTypeAtOrganelle(state, m, Motor)),
        },
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== VIRAL_LOAD_TUTORIAL) {return false;}
                return getDogmaMicrotubuleIds(state, dogmaDashId).some(m => proteinTypeAtOrganelle(state, m, DefaultCargo));
            },
            forceCompletion: () => true,
        },
        {
            condition: (state, {location}) => location === VIRAL_LOAD_TUTORIAL,
            header: "Viral Load",
            text: "Every time the pilot sends an antibody out of the cell the viral load decreases. <b>Keep the viral load as low as possible by producing antibodies</b>."
        },
    ],
};
