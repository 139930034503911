import {ADD_INTERACTABLE_TO_TASK, REMOVE_INTERACTABLE_FROM_TASK} from "../actions";
import {DRAGGABLE_BANK, SCROLLER_EXPLAINER_PREDICTION} from "../../../constants/TaskTypes";
import {omit} from "lodash";
import {SET_EXPLAINER_FOR_PREDICTION} from "./actions";
import {CURRICULA_LOADED} from "../../curricula/actions";

const defaultItem = {
    explainer: null,
};

export default (state = {}, action) => {
    if(action.type === CURRICULA_LOADED) {
        const newPredictions = action.curricula.reduce((agg, c) => ({
            ...agg,
            ...(c.tasks || []).reduce((taskAgg, t) => ({
                ...taskAgg,
                ...t.items.filter(i => i.__typename === SCROLLER_EXPLAINER_PREDICTION).reduce((itemsAgg, i) => ({
                    ...itemsAgg,
                    [i.id]: {
                        ...defaultItem,
                        explainer: i.explainer ? i.explainer.id : null,
                    },
                }), {}),
            }), {}),
        }), {});
        return {
            ...state,
            ...newPredictions,
        };
    }
    if(action.type === ADD_INTERACTABLE_TO_TASK && action.interactableType === SCROLLER_EXPLAINER_PREDICTION) {
        return {
            ...state,
            [action.interactableId]: {
                ...defaultItem,
            },
        };
    }
    if(action.type === REMOVE_INTERACTABLE_FROM_TASK) {
        return omit(state, action.interactableId);
    }
    if(action.type === SET_EXPLAINER_FOR_PREDICTION) {
        return {
            ...state,
            [action.predictionId]: {
                ...state[action.predictionId],
                explainer: action.explainerId,
            },
        };
    }
    return state;
};
