import './multiple-choice-input.scss';
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import RichEditor from "../../../../RichText/RichEditor";
import {
    getMultipleChoiceInputCorrectSelection,
    getMultipleChoiceInputMultiselect,
    getMultipleChoiceInputOptions,
    getMultipleChoiceInputPrompt
} from "../../../../store/curriculumTaskItems/multipleChoiceInput/selectors";
import {
    addMultipleChoiceInputOption,
    removeMultipleChoiceInputOption,
    setMultipleChoiceInputCorrect,
    setMultipleChoiceInputMultiselect,
    setMultipleChoiceInputOptionText,
    setMultipleChoiceInputPrompt
} from "../../../../store/curriculumTaskItems/multipleChoiceInput/actions";

export default ({curriculum, id}) => {
    const prompt = useSelector(state => getMultipleChoiceInputPrompt(state, id));
    const options = useSelector(state => getMultipleChoiceInputOptions(state, id));
    const correctSelections = useSelector(state => getMultipleChoiceInputCorrectSelection(state, id));
    const multiselect = useSelector(state => getMultipleChoiceInputMultiselect(state, id));
    const dispatch = useDispatch();

    const correctInputType = multiselect ? "checkbox" : "radio";

    const setPrompt = newPrompt => dispatch(setMultipleChoiceInputPrompt(curriculum, id, newPrompt));
    const setMultiselect = e => {
        if(!e.target.checked) {
            correctSelections.slice(1).forEach(i => dispatch(setMultipleChoiceInputCorrect(curriculum, id, i, false)));
        }
        dispatch(setMultipleChoiceInputMultiselect(curriculum, id, e.target.checked));
    };
    const setOptionText = (optionIndex, e) => dispatch(setMultipleChoiceInputOptionText(curriculum, id, optionIndex, e.target.value));
    const setOptionCorrect = (optionIndex, e) => {
        if(!multiselect) {
            dispatch(setMultipleChoiceInputCorrect(curriculum, id, correctSelections[0], false));
        }
        dispatch(setMultipleChoiceInputCorrect(curriculum, id, optionIndex, e.target.checked));
    };
    const addOption = () => dispatch(addMultipleChoiceInputOption(curriculum, id));
    const removeOption = optionIndex => dispatch(removeMultipleChoiceInputOption(curriculum, id, optionIndex));

    return (
        <div className="admin-task--multiple-choice-input">
            <div className="multiple-choice-input--prompt-prompt">Prompt:</div>
            <div className="multiple-choice-input--prompt-container">
                <RichEditor content={prompt} setContent={setPrompt} id={id} />
            </div>
            <div className="multiple-choice-input--multiselect-container">
                <label htmlFor={`${id}-multiselect`} className="multiple-choice-input--multiselect-label">Multiselect?</label>
                <input id={`${id}-multiselect`} className="multiple-choice-input--multiselect-input" type="checkbox" onChange={setMultiselect} checked={multiselect} />
            </div>
            <div className="multiple-choice-input--options-container">
                <div className="multiple-choice-input--options-prompt-container">
                    <h4 className="multiple-choice-input--options-prompt">Options</h4>
                    <h4 className="multiple-choice-input--correct-prompt">Correct?</h4>
                </div>
                {options.map((o, i) => (
                    <div key={`option-${i}-${o}`} className="multiple-choice-input--option">
                        <button onClick={removeOption.bind(null, i)} className="multiple-choice-input--remove-option" />
                        <input onBlur={setOptionText.bind(null, i)} defaultValue={o} placeholder="Option value..." className="multiple-choice-input--option-text" />
                        <input onChange={setOptionCorrect.bind(null, i)} checked={correctSelections.includes(i)} className="multiple-choice-input--option-correct" type={correctInputType} />
                    </div>
                ))}
            </div>
            <button className="multiple-choice-input--add-option" onClick={addOption}>+ option</button>
        </div>
    );
}
