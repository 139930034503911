import {SET_AUTHENTICATION_STATE} from "../../identity/actions";
import {NOT_LOGGED_IN} from "../../../constants/AuthenticationState";
import {EMPTY_INTERACTIONS} from "../actions";
import {ADD_PENDING_SCORING_EVENT, PENDING_SCORING_EVENT_COMPLETED} from "./actions";
import {DOGMA_DASH_ORGANELLE_TIMER_CHANGED, REFRESH_DOGMA_DASH_INTERACTION_DATA} from "../dogmaDash/actions";

export default (state = [], action) => {
    if(action.type === ADD_PENDING_SCORING_EVENT) {
        return [...state, {
            scorer: action.scorerId,
            deltaScore: action.deltaScore,
        }];
    }
    if(action.type === PENDING_SCORING_EVENT_COMPLETED) {
        return state.filter(e => e.scorer !== action.scorerId);
    }
    if(action.type === DOGMA_DASH_ORGANELLE_TIMER_CHANGED) {
        const {organelleId, start} = action;
        if(!start || !state.some(p => p.scorer === organelleId)) { return state; }
        return state.filter(p => p.scorer !== organelleId);
    }
    if(action.type === REFRESH_DOGMA_DASH_INTERACTION_DATA) {
        const organelles = getAllOrganelleIdsFromDogmaDash(action.interactionData);
        return state.filter(p => organelles.indexOf(p.scorer) < 0);
    }
    if(action.type === SET_AUTHENTICATION_STATE && action.authenticationState === NOT_LOGGED_IN) {
        return [];
    }
    if(action.type === EMPTY_INTERACTIONS) {
        return [];
    }
    return state;
};

const getAllOrganelleIdsFromDogmaDash = interaction => [
    ...interaction.dashMrnaSlots.map(o => o.id),
    ...interaction.dashMitochondria.map(o => o.id),
    ...interaction.dashCellMembrane.map(o => o.id),
    ...interaction.dashLysosome.map(o => o.id),
    ...interaction.dashGolgiBody.map(o => o.id),
    ...interaction.dashRibosome.map(o => o.id),
    ...interaction.dashMicrotubules.map(o => o.id),
    ...interaction.dashCentrioles.map(o => o.id),
    interaction.dashCytoplasm.id,
];
