import {takeEvery, call} from 'redux-saga/effects';
import {REMOVE_MATERIAL_FROM_WORK_BOARD} from "../../../store/curriculumTaskItems/workBoardDefinition/actions";
import {sendGraphQLSaga} from "../../DataService";

function* execute(action) {
    const variables = {
        input: {
            curriculum: action.curriculumId,
            workBoardDefinition: action.workBoardDefinitionId,
            material: action.materialId,
        },
    };

    yield call(sendGraphQLSaga, removeQuery, variables);
}

export default [takeEvery(REMOVE_MATERIAL_FROM_WORK_BOARD, execute)];
