export const IMPORT_POLAROIDS_ACTION = 'Import many polaroids';
export const importPolaroids = polaroids => ({
    type: IMPORT_POLAROIDS_ACTION,
    polaroids
});

export const CREATE_POLAROID_ACTION = 'Create polaroid';
export const createPolaroid = dispatch => (id, imageUrl) => dispatch({
    type: CREATE_POLAROID_ACTION,
    id,
    imageUrl,
});

export const CHANGE_POLAROID_BORDER_COLOR = 'Change polaroid border color';
export const changePolaroidBorderColor = dispatch => (id, color) => dispatch({
    type: CHANGE_POLAROID_BORDER_COLOR,
    id,
    color
});

export const CHANGE_POLAROID_NOTES = 'Change polaroid nodes';
export const changePolaroidNotes = dispatch => (id, notes) => dispatch({
    type: CHANGE_POLAROID_NOTES,
    id,
    notes
});

export const ADD_POLAROID_ATTRIBUTE = 'Add polaroid attribute';
export const addPolaroidAttribute = dispatch => (polaroidId, text) => dispatch({
    type: ADD_POLAROID_ATTRIBUTE,
    polaroidId,
    text: text || '',
});

export const UPDATE_POLAROID_ATTRIBUTE = 'Update polaroid attribute';
export const updatePolaroidAttribute = dispatch => (polaroidId, attributeNumber, text) => dispatch({
    type: UPDATE_POLAROID_ATTRIBUTE,
    polaroidId,
    attributeNumber,
    text,
});

export const DELETE_POLAROID_ATTRIBUTE = 'Delete polaroid attribute';
export const deletePolaroidAttribute = dispatch => (polaroidId, attributeNumber) => dispatch({
    type: DELETE_POLAROID_ATTRIBUTE,
    polaroidId,
    attributeNumber,
});
