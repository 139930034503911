import {getAllCurriculumTasks, getCurriculumTaskItemIds} from "../curriculumTask/selectors";
import {SCROLLER_EXPLAINER} from "../../constants/TaskTypes";

const getCurriculumTaskItem = (state, id) => state.curriculumTaskItems[id];
export const getCurriculumTaskItemType = (state, id) => getCurriculumTaskItem(state, id).type;

export const getCurriculumTasksWithExplainers = (state) => {
    return getAllCurriculumTasks(state).filter(t =>
        getCurriculumTaskItemIds(state, t).some(i => getCurriculumTaskItemType(state, i) === SCROLLER_EXPLAINER));
};
