import {IMPORT_BOUNTIES} from "./actions";

export default (state = {}, action) => {
    if(action.type === IMPORT_BOUNTIES) {
        const newBounties = action.bounties.reduce((agg, bounty) => {
            agg[bounty.id] = bounty;
            return agg;
        }, {});
        return Object.assign({}, state, newBounties);
    }
    return state;
}
