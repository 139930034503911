import {css, keyframes} from "@emotion/core";
import {bbPadding} from "../../../../style-variables";
import {dogmashFailColor, dogmashOrange, dogmashRed, dogmashSuccessScoreColor} from "./dogma-dash-styles";
import dotBackground from "./Assets/dot-background.png";
import {darken} from "color2k";

export const container = css({
    flex: "0 0 auto",
    width: "128px",
    position: "relative",
});

export const tutorial = css({
    width: 0,
    height: 0,
    position: "absolute",
    left: "50%",
    top: "225px",
    zIndex: 20,
});

export const titleText = css({
    width: "100%",
    fontFamily: "Rajdhani, sans-serif",
    fontWeight: 400,
    fontSize: "0.9375rem",
    textTransform: "lowercase",
    textAlign: "center",
    padding: 0,
    margin: 0,
    position: "absolute",
    bottom: "calc(100% + 6px)",
    left: "50%",
    transform: "translateX(-50%)",
});

export const alertsContainer = css(bbPadding("10px"), {
    width: "100%",
    height: "420px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: `2px solid ${dogmashOrange}`,
    borderRadius: "5px",
    background: `url(${dotBackground})`,
    overflow: "hidden",
});

export const alertContainer = css({
    flex: "0 0 auto",
    width: "100%",
    height: "102px",
    marginBottom: "23px",
    border: `1px solid ${dogmashRed}`,
    borderRadius: "11px",
    backgroundColor: darken(dogmashRed, 0.34),
    position: "relative",
    transition: "border-color 0.2s, background-color 0.2s",
});
const containerShrink = keyframes({
    "80%": {height: "102px", fontSize: "1rem"},
    "100%": {height: "14px", fontSize: "0rem"},
});
export const alertContainerSuccess = css(alertContainer, {
    borderColor: dogmashSuccessScoreColor,
    backgroundColor: darken(dogmashSuccessScoreColor, 0.34),
    animation: `${containerShrink} 2.5s`,
});
export const alertContainerFailure = css(alertContainer, {
    borderColor: dogmashFailColor,
    backgroundColor: darken(dogmashFailColor, 0.34),
    animation: `${containerShrink} 2.5s`,
});

export const clippingContainer = css({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    borderRadius: "11px",
    position: 'relative',
    overflow: "hidden",
});

export const iconContainer = css({
    width: "38px",
    height: "38px",
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: "translate(-50%, 50%)",

    "& > svg": {
        fill: dogmashRed,
        stroke: dogmashRed,
        width: "100%",
        height: "100%",
        transition: "fill 0.2s, stroke 0.2s",
    },
});
export const successIcon = css(iconContainer, {
    "& > svg": {
        fill: dogmashSuccessScoreColor,
        stroke: dogmashSuccessScoreColor,
    },
});
export const failureIcon = css(iconContainer, {
    "& > svg": {
        fill: dogmashFailColor,
        stroke: dogmashFailColor,
    },
});

export const textContainer = css({
    flex: "1 0 auto",
    height: "100%",
    borderRight: `1px solid ${dogmashRed}`,
    position: "relative",
});
export const alertText = css({
    width: '100%',
    position: 'absolute',
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
    textAlign: 'center',
    fontFamily: "Rajdhani, sans-serif",
    fontSize: '0.9375rem',
    fontWeight: 400,
    color: "#fff",
    textTransform: "lowercase",
});
export const scoringText = css(alertText, {
    fontSize: "1.875em",
    fontWeight: 700,
});

export const timerContainer = css({
    flex: "0 0 auto",
    width: "6px",
    height: "100%",
    backgroundColor: "#292929",
    borderRadius: "0 6px 6px 0",
    position: "relative",
    transition: "width 0.4s",
    overflow: 'hidden',
});
export const scoringTimerContainer = css(timerContainer, {
    width: "0",
});

export const timerBar = css({
    width: "100%",
    borderRadius: "0 6px 0 0",
    backgroundColor: dogmashOrange,
    position: 'absolute',
    left: 0,
    bottom: 0,
});
