import * as styles from './microtubule-motors-styles';
import React, {useEffect, useRef} from 'react';
import {
    getMotorProteinDestination, getMotorProteinMovementDuration, getMotorProteinMovementStartTime,
    getObjectsInOrganelle,
    getObjectType
} from "../../../../store/interactions/dogmaDash/selectors";
import {Motor} from "../../../../constants/DogmaDash";
import {shallowEqual, useSelector} from "react-redux";

const getMotorObjects = (state, organelleId) => getObjectsInOrganelle(state, organelleId).filter(id => getObjectType(state, id) === Motor);
const calculatePosition = (start, destination, startTime, duration) => {
    const positionDelta = destination - start;
    const timePercent = Math.min(1, Math.max(0, (Date.now() - startTime) / duration));
    return start + timePercent * positionDelta;
};

const MotorProtein = ({id}) => {
    const destination = useSelector(state => getMotorProteinDestination(state, id));
    const startTime = useSelector(state => getMotorProteinMovementStartTime(state, id));
    const travelTime = useSelector(state => getMotorProteinMovementDuration(state, id));
    const ref = useRef();
    const animationRef = useRef();

    useEffect(() => {
        if(!startTime || !travelTime) {
            ref.current.style.left = `${destination * 100}%`;
            return;
        }
        const match = ref.current.style.left.match(/(\d+)%/);
        const start = match ? parseFloat(match[1]) / 100 : 0;
        const movementAnimation = () => {
            const newPosition = calculatePosition(start, destination, startTime, travelTime);
            ref.current.style.left = `${newPosition * 100}%`;
            animationRef.current = requestAnimationFrame(movementAnimation);
        }
        animationRef.current = requestAnimationFrame(movementAnimation);
        return () => cancelAnimationFrame(animationRef.current);
    },[destination]);

    return <div ref={ref} css={styles.motorProtein} />
};

const MicrotubuleMotors = ({id}) => {
    const motorObjects = useSelector(state => getMotorObjects(state, id), shallowEqual);

    return (
        <div css={styles.container}>
            {motorObjects.map(id => <MotorProtein key={id} id={id} />)}
        </div>
    )
};

export default MicrotubuleMotors;
