import * as styles from './text-color-button-styles';
import React, {useEffect, useRef, useState} from 'react';
import {ReactEditor, useSlate} from "slate-react";
import {ChromePicker} from 'react-color';
import {css} from "@emotion/core";
import {Editor, Transforms} from "slate";
import {TEXT_COLOR_MARK} from "../ScrollerItem/TextColorButton";

const TextColorButton = () => {
    const [showSelector, setShowSelector] = useState(false);
    const [selection, setSelection] = useState();
    const editor = useSlate();
    const editorRef = useRef();
    let color = "#000000";
    try {
        const marks = Editor.marks(editor);
        if(marks && marks[TEXT_COLOR_MARK]) {
            color = marks[TEXT_COLOR_MARK];
        }
    } catch {}
    const [editorColor, setEditorColor] = useState(color);

    useEffect(() => {
        if(showSelector) {
            editorRef.current.focus();
        }
    }, [showSelector])

    const focusEditor = () => {
        ReactEditor.focus(editor);
        Transforms.select(editor, selection);
    };

    const setColor = () => {
        focusEditor();
        if(editorColor === "#000000") {
            editor.removeMark(TEXT_COLOR_MARK);
        } else {
            editor.addMark(TEXT_COLOR_MARK, editorColor.hex);
        }
        setShowSelector(false);
    };

    const toggleShowSelector = e => {
        if(e.defaultPrevented) { return; }
        if(!capture(e)) { return; }
        if(!showSelector) {
            setSelection(editor.selection);
            setEditorColor(color);
        } else {
            setColor();
        }
        setShowSelector(!showSelector) // very specifically want to use this form because of the blur/mouse down ordering
    };
    const capture = e => {
        if(editorRef.current && editorRef.current.contains(e.target)) {
            return false;
        }
        e.stopPropagation();
        e.preventDefault();
        return true;
    };

    const textStyle = css(styles.buttonText, {
        borderColor: color
    });

    return (
        <button css={styles.textColorButton} onMouseDown={toggleShowSelector} onClick={capture}>
            <span css={textStyle}>A</span>
            {showSelector && <div ref={editorRef} css={styles.colorSelectorContainer} onMouseDown={capture} tabIndex={-1}>
                <ChromePicker color={editorColor} onChange={c => setEditorColor(c)} />
            </div> }
        </button>
    )
};

export default TextColorButton;
