import {css} from "@emotion/core";
import {AVENIR_FONT, bbPadding, inlineBlockTop} from "../../style-variables";

export const outerContainer = css(inlineBlockTop, {
    width: "100%",
    height: "100%",
    position: "relative",
});

export const container = css({
    width: "100%",
    height: "100%",
    overflowY: "auto",
    fontFamily: `${AVENIR_FONT}, sans-serif`,
});

export const contentContainer = css({
    width: "100%",
    position: "relative",
});

export const openWorkBoardContainer = css({
    display: "flex",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
}, bbPadding("4px"));
