import * as styles from './plant-display-styles';
import React from 'react';
import {
    getPeaPlantFlowerColorPhenotype,
    getPeaPlantFlowerPositionPhenotype,
    getPeaPlantHeightPhenotype,
    getPeaPlantName,
    getPeaPlantParentAName,
    getPeaPlantParentBName,
    getPeaPlantPodColorPhenotype,
    getPeaPlantPodShapePhenotype,
    getPeaPlantSeedColorPhenotype,
    getPeaPlantSeedShapePhenotype
} from "../../../store/peaPlants/selectors";
import {useSelector} from "react-redux";
import classNames from "class-names";
import combineStyles from "../../../Utility/combineStyles";
import {DOMINANT_TRAIT, RECESSIVE_TRAIT} from "../../../constants/Zygosities";

const PlantDisplay = React.memo(({plantId}) => {
    const name = useSelector(state => getPeaPlantName(state, plantId));
    const height = useSelector(state => getPeaPlantHeightPhenotype(state, plantId));
    const flowerColor = useSelector(state => getPeaPlantFlowerColorPhenotype(state, plantId));
    const flowerPosition = useSelector(state => getPeaPlantFlowerPositionPhenotype(state, plantId));
    const seedColor = useSelector(state => getPeaPlantSeedColorPhenotype(state, plantId));
    const seedShape = useSelector(state => getPeaPlantSeedShapePhenotype(state, plantId));
    const podColor = useSelector(state => getPeaPlantPodColorPhenotype(state, plantId));
    const podShape = useSelector(state => getPeaPlantPodShapePhenotype(state, plantId));
    const parentAName = useSelector(state => getPeaPlantParentAName(state, plantId));
    const parentBName = useSelector(state => getPeaPlantParentBName(state, plantId));

    return (
        <div css={combineStyles(styles, {
            plantDisplayContainer: true,
            plantDisplayShort: height === RECESSIVE_TRAIT && flowerPosition === DOMINANT_TRAIT,
            plantDisplayTerminal: flowerPosition === RECESSIVE_TRAIT && height === DOMINANT_TRAIT,
            plantDisplayShortTerminal: flowerPosition === RECESSIVE_TRAIT && height === RECESSIVE_TRAIT,
        })}>
            <div css={styles.labelContainer}>
                <div css={styles.label}>{name}</div>
                { parentAName && parentBName && (
                    <div css={styles.parentLabel}>
                        <span>{parentAName}</span>
                        <span>x</span>
                        <span>{parentBName}</span>
                    </div>
                )}
            </div>
            <div css={styles.trait} className="pea-plant-card--plant" />
            <div css={styles.flowerDisplayTrait} className={classNames({
                "pea-plant-card--flower": true,
                "pea-plant-card--flower__color-recessive": flowerColor === RECESSIVE_TRAIT,
            })} />
            <div css={styles.podDisplayTrait} className={classNames({
                "pea-plant-card--pod": true,
                "pea-plant-card--pod__color-recessive": podColor === RECESSIVE_TRAIT,
                "pea-plant-card--pod__shape-recessive": podShape === RECESSIVE_TRAIT,
            })}>
                <div css={styles.trait} className={classNames({
                    "pea-plant-card--seeds": true,
                    "pea-plant-card--seeds__color-recessive": seedColor === RECESSIVE_TRAIT,
                    "pea-plant-card--seeds__shape-recessive": seedShape === RECESSIVE_TRAIT,
                })} />
            </div>
        </div>
    );
});

PlantDisplay.displayName = "plantDisplay";
export default PlantDisplay;
