import {COMPLETE, NOT_STARTED} from "../../constants/TaskStatus";
import {takeWhile} from 'lodash';
import {getBountyStatus} from "../learners/selectors";
import {getActiveLearner} from "../navigation/selectors";

export const getCurriculumTopics = state => state.curriculum && state.curriculum.topicAssignments ? state.curriculum.topicAssignments.map(t => t.topic.id) : [];
export const getCurriculumId = state => state.curriculum && state.curriculum.curriculum ? state.curriculum.curriculum.id : null;

const getTopicAssignment = (state, topicId) => state.curriculum.topicAssignments.find(t => t.topic.id === topicId);
export const getTopicTitle = (state, topicId) => getTopicAssignment(state, topicId).topic.title;
export const getTopicSubtitle = (state, topicId) => getTopicAssignment(state, topicId).topic.subtitle;
export const getActivitiesForTopic = (state, topicId) => getTopicAssignment(state, topicId).activityAssignments.map(a => a.activity.id);

const getActivityAssignment = (state, activityId) => state.curriculum.topicAssignments
    .reduce((activity, topic) => activity || topic.activityAssignments.find(a => a.activity.id === activityId), null);
export const getActivityTitle = (state, activityId) => activityId ? getActivityAssignment(state, activityId).activity.title : "";
export const getActivitySubtitle = (state, activityId) => getActivityAssignment(state, activityId).activity.subtitle;
export const getTasksForActivity = (state, activityId) => activityId
    ? getActivityAssignment(state, activityId).taskAssignments.map(t => t.task.id)
    : [];
export const getSkipVRBlock = (state, activityId) => getActivityAssignment(state, activityId).activity.skipVRBlock;

const getTaskAssignment = (state, taskId) => state.curriculum.topicAssignments
    .reduce((innerTask, topic) => innerTask || topic.activityAssignments
        .reduce((task, activity) => task || activity.taskAssignments.find(t => t.task.id === taskId), null)
    , null);
export const getTaskDescription = (state, taskId) => getTaskAssignment(state, taskId).task.description;
export const getTaskAdditionalText = (state, taskId) => getTaskAssignment(state, taskId).task.additionalText;
export const getTaskType = (state, taskId) => getTaskAssignment(state, taskId).task.type;
export const getTaskUrl = (state, taskId) => getTaskAssignment(state, taskId).url;
export const getTaskHidden = (state, taskId) => getTaskAssignment(state, taskId).task.hide || false;
export const getTaskStatus = (state, taskId) => {
    const activeLearner = getActiveLearner(state);
    if(!activeLearner) { return NOT_STARTED; }
    const bounty = getTaskBounty(state, taskId);
    if(getTaskType(state, taskId).toUpperCase() === "VR" && bounty) {
        const status = getBountyStatus(state, activeLearner, bounty);
        return status;
    }
    return getTaskAssignment(state, taskId).status;
};
export const getTaskBounty = (state, taskId) => {
    const task = getTaskAssignment(state, taskId).task;
    if(task.bounty) {
        return task.bounty.id;
    }
    return null;
};

export const isActivityComplete = (state, activityId) => activityId
    ? getTasksForActivity(state, activityId).every(task => getTaskStatus(state, task) === COMPLETE)
    : false;
export const allWebTasksComplete = (state, activityId) => {
    if(!activityId) { return false; }
    return getTasksForActivity(state, activityId)
        .filter(task => getTaskType(state, task) === "Web")
        .every(task => getTaskStatus(state, task) === COMPLETE);
};

export const getFirstIncompleteActivity = state => {
    let activity;
    const topics = getCurriculumTopics(state);
    for(let t = 0; t < topics.length; ++t) {
        const activities = getActivitiesForTopic(state, topics[t]);
        for(let a = 0; a < activities.length; ++a) {
            activity = activities[a];
            if(!isActivityComplete(state, activity)) {
                return activity;
            }
        }
    }
    return activity;
};

export const getNextAndPreviousActivity = (state, activityId) => {
    const topics = getCurriculumTopics(state);
    const data = { next: null, previous: null };
    for(let t = 0; t < topics.length; ++t) {
        const activities = getActivitiesForTopic(state, topics[t]);
        for(let a = 0; a < activities.length; ++a) {
            if(activities[a] !== activityId) { continue; }
            if(a !== 0) {
                data.previous = activities[a-1];
            } else if(a === 0 && t !== 0) {
                const previousActivities = getActivitiesForTopic(state, topics[t-1]);
                data.previous = previousActivities[previousActivities.length-1];
            }
            if(a !== activities.length - 1) {
                data.next = activities[a+1];
            } else if(a === activities.length - 1 && t !== topics.length - 1) {
                const nextActivities = getActivitiesForTopic(state, topics[t+1]);
                data.next = nextActivities[0];
            }
            return data;
        }
    }
    return data;
};

export const getTopicForActivity = (state, activityId) => {
    const topics = getCurriculumTopics(state);
    return topics.find(t => getActivitiesForTopic(state, t).indexOf(activityId) >= 0);
};

export const shouldBlockForVR = (state, activityId) => {
    if(!activityId || getSkipVRBlock(state, activityId)) { return 0; }
    const complete = isActivityComplete(state, activityId);
    if(complete) {
        const {next} = getNextAndPreviousActivity(state, activityId);
        if(!next || getSkipVRBlock(state, next)) { return 0; }
        const tasks = getTasksForActivity(state, next);
        const shouldBlock = takeWhile(tasks, t => getTaskType(state, t) === "VR").reduce((agg, t) => agg || getTaskStatus(state, t) !== COMPLETE, false);
        if(shouldBlock) return -1;
        return 0;
    }
    const tasks = getTasksForActivity(state, activityId);
    const shouldBlock = getTaskType(state, tasks.find(t => getTaskStatus(state, t) !== COMPLETE)) === "VR";
    if(shouldBlock) return 1;
    return 0;
};

export const getReferenceCardUrl = (state) => {
    if(!state.curriculum || !state.curriculum.topicAssignments) { return ""; }
    for(let t = 0; t < state.curriculum.topicAssignments.length; ++t) {
        const topic = state.curriculum.topicAssignments[t];
        for(let a = 0; a < topic.activityAssignments.length; ++a) {
            const activity = topic.activityAssignments[a];
            for(let i = 0; i < activity.taskAssignments.length; ++i) {
                const task = activity.taskAssignments[i];
                if(task.task.description.toLowerCase().indexOf("reference card") >= 0) {
                    return task.url;
                }
            }
        }
    }
    return "";
};
