import * as styles from './template-item-style';
import React, {useRef} from 'react';
import {WORK_BOARD_CROSS_CARD, WORK_BOARD_DROP_SLOT} from "../../../../../constants/TaskTypes";
import DropSlot from "../DropSlot";
import {useDispatch, useSelector} from "react-redux";
import {getWorkBoardTemplateItemType} from "../../../../../store/workBoardTemplate/selectors";
import {useDrag} from "react-dnd";
import {WORK_BOARD_TEMPLATE_DRAG_ITEM} from "./index";
import CrossCard from "./CrossCard/CrossCard";
import {closeModalType, openModalFor} from "../../../../../store/navigation/actions";
import {TEMPLATE_ITEM_CONTEXT, TEMPLATE_MENU} from "../../../../../constants/modalTypes";

const TypeTagMap = {
    [WORK_BOARD_DROP_SLOT]: DropSlot,
    [WORK_BOARD_CROSS_CARD]: CrossCard,
};

export default props => {
    const {id, workBoard} = props;
    const type = useSelector(state => getWorkBoardTemplateItemType(workBoard, id, state));
    const dispatch = useDispatch();
    const ref = useRef();

    const [_, dragRef] = useDrag({
        item: {
            type: WORK_BOARD_TEMPLATE_DRAG_ITEM,
            itemId: id,
            ref,
        },
    });
    const setRef = r => {
        if(r) { r.setAttribute("draggable", true); } // TODO: ACK! would love to not need this.
        dragRef(r);
        ref.current = r;
    };

    const openContextMenu = e => {
        e.preventDefault();
        e.stopPropagation();
        const {left, top} = ref.current.parentElement.getBoundingClientRect();
        dispatch(closeModalType(TEMPLATE_ITEM_CONTEXT));
        dispatch(closeModalType(TEMPLATE_MENU));
        dispatch(openModalFor(TEMPLATE_ITEM_CONTEXT, id, {
            x: e.clientX - left,
            y: e.clientY - top,
            type,
        }));
    };

    const ItemTag = TypeTagMap[type];
    return (
        <div css={styles.templateItem} onContextMenu={openContextMenu} ref={setRef}>
            <ItemTag id={id} />
        </div>
    );
};
