import {
    absoluteCenter,
    bbPadding,
    modalAcceptButton,
    modalBlocker,
    modalButtonContainer, modalCancelButton
} from "../../../style-variables";
import {css} from "@emotion/core";

export const blocker = modalBlocker;

export const modalContainer = css(absoluteCenter, bbPadding("20px"), {
    backgroundColor: "#fff",
});

export const modalHeader = css({
    margin: "0 0 5px 0",
    padding: 0,
    fontSize: "1em",
    fontWeight: 400,
});

export const taskContainer = css(bbPadding("0 0 0 15px"), {
    width: "100%",
    maxHeight: "120px",
    overflowY: "auto",
});

export const task = css({
    width: "100%",
    margin: "0 0 1px 0",

    "&:last-of-type": {
        marginBottom: "0",
    },
});

export const selectableLabel = css(bbPadding("2px"), {
    display: "block",
    width: "100%",
    borderRadius: "3px",
    backgroundColor: "#fff",
    fontSize: "0.875em",
    transition: "backgroundColor 0.2s",
    cursor: "pointer",
});
export const selectableCheck = css({
    display: "none",

    "&:checked + label": {
        backgroundColor: "#46B084FF"
    }
});

export const interactableContainer = css({
    width: "100%",
    margin: "5px 0 0 0",
    maxHeight: 0,
    overflowY: "auto",
    transition: "max-height 0.3s",
});
export const interactableContainerShown = css(interactableContainer, {
    maxHeight: "120px",
});

export const noInteractables = css(bbPadding("0 0 0 15px"), {
    fontSize: "0.75em",
    color: "#333",
});

export const interactableList = css(bbPadding("0 0 0 15px"), {
    width: "100%",
});

export const interactable = css({
    width: "100%",
    margin: "0 0 1px 0",

    "&:last-of-type": {
        marginBottom: 0,
    },
});

export const buttonContainer = modalButtonContainer;

export const selectButton = modalAcceptButton;

export const cancelButton = modalCancelButton;
