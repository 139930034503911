import * as styles from "./resize-handle-style";
import React from 'react';
import combineStyles from "../../../../../Utility/combineStyles";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getNextPosition, getScrollingItemPositions} from "../../../../../store/scrollingTaskItemPositions/selectors";
import {setScrollingItemHeight, setScrollingItemWidth} from "../../../../../store/scrollingTaskItemPositions/actions";
import {useParams} from "@reach/router";
import {isItemSelected} from "../../../../../store/itemSelection/selector";

export default ({item, itemRef, scrollerRef, resizeDirection}) => {
    const {curriculumId} = useParams();
    const positions = useSelector(getScrollingItemPositions.bind(null, item), shallowEqual);
    const selected = useSelector(isItemSelected.bind(null, item), shallowEqual);
    const dispatch = useDispatch();

    const handleStyle = combineStyles(styles, {
        handle: true,
        rightHandle: resizeDirection.x === 1 && !resizeDirection.y,
        bottomHandle: !resizeDirection.x && resizeDirection.y === 1,
        cornerHandle: resizeDirection.x === 1 && resizeDirection.y === 1,
        selected,
    });

    const startResizing = e => {
        e.stopPropagation();
        e.preventDefault();
        const {clientX: startX, clientY: startY} = e;
        const {width: startWidth, height: startHeight} = itemRef.current.getBoundingClientRect();
        const positionBreakpoint = positions.indexOf(getNextPosition(positions, scrollerRef.current.scrollTop));

        const calculate = e => {
            const {clientX: currentX, clientY: currentY} = e;
            const maintainAspect = e.ctrlKey;
            let deltaX = currentX - startX;
            let deltaY = currentY - startY;
            if(maintainAspect) {
                const aspectRatio = startWidth / startHeight;
                if(deltaX <= deltaY) {
                    deltaY = deltaX / aspectRatio;
                } else if(deltaY < deltaX) {
                    deltaX = deltaY * aspectRatio;
                }
            }
            return {
                deltaX: deltaX * (resizeDirection.x || 0),
                deltaY: deltaY * (resizeDirection.y || 0),
            };
        };

        let animation;
        const dragHandler = e => {
            const {deltaX, deltaY} = calculate(e);

            if(animation) {
                cancelAnimationFrame(animation);
            }
            animation = requestAnimationFrame(() => {
                if(resizeDirection.x > 0) { itemRef.current.style.width = `${startWidth + deltaX}px`; }
                if(resizeDirection.y > 0) { itemRef.current.style.height = `${startHeight + deltaY}px`; }
            });
        };
        document.body.addEventListener("mousemove", dragHandler);

        const endHandler = e => {
            document.body.removeEventListener("mousemove", dragHandler);
            document.body.removeEventListener("mouseup", endHandler);
            const {deltaX, deltaY} = calculate(e);
            if(deltaX !== 0) {
                dispatch(setScrollingItemWidth(curriculumId, item, positionBreakpoint, startWidth + deltaX));
            }
            if(deltaY !== 0) {
                dispatch(setScrollingItemHeight(curriculumId, item, positionBreakpoint, startHeight + deltaY));
            }
        };
        document.body.addEventListener("mouseup", endHandler);
    };

    return (<div css={handleStyle}
                 onMouseDown={startResizing}
    />);
}
