import {getCurriculumTaskIds} from "../../curricula/selectors";
import {getCurriculumTaskItemIds, getCurriculumTaskTitle} from "../../curriculumTask/selectors";
import {getWorkBoardTemplate} from "../../workBoardTemplate/selectors";

const getWorkBoardDefinition = (state, definitionId) => state.workBoardDefinitions[definitionId];
export const getWorkBoardTitle = (curriculumId, definitionId, state) => {
    const tasks = getCurriculumTaskIds(state, curriculumId);
    const task = tasks.find(t => getCurriculumTaskItemIds(state, t).includes(definitionId));
    return getCurriculumTaskTitle(state, task);
};
export const getWorkBoardIntroduction = (definitionId, state) => getWorkBoardDefinition(state, definitionId).introduction;
export const getWorkBoardMaterials = (definitionId, state) => {
    const definition = getWorkBoardDefinition(state, definitionId);
    if(!definition) { return []; }
    return definition.materials;
};
export const getWorkBoardUsesTemplate = (definitionId, state) => getWorkBoardDefinition(state, definitionId).template;
export const getWorkBoardTemplateItemIds = (definitionId, state) => {
    if(!definitionId) { return []; }
    const template = getWorkBoardUsesTemplate(definitionId, state);
    if(!template) { return []; }
    return getWorkBoardTemplate(definitionId, state).map(i => i.id);
};
