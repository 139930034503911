import React from 'react';
import GenericStyleButton from "./editorButtons/GenericStyleButton";
import Link from "./editorButtons/Link";
import TaskReference from "./editorButtons/TaskReference";
import {Editor, Transforms} from "slate";
import Video from "./editorButtons/Video";
import Image from "./editorButtons/Image";
import HeaderType from "./editorButtons/HeaderType";
import TextColorButton from "./editorButtons/TextColorButton";
import TextSize from "./editorButtons/TextSize";

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};
const toggleInlineStyle = (editor, format, toggle) => {
    if(toggle) {
        editor.addMark(format, true);
    } else {
        editor.removeMark(format);
    }
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const toggleBlockType = (editor, blockType, toggle) => {
    const isList = LIST_TYPES.includes(blockType);

    Transforms.unwrapNodes(editor, {
        match: n => LIST_TYPES.includes(n.type),
        split: true,
    });

    Transforms.setNodes(editor, {
        type: !toggle ? 'paragraph' : isList ? 'list-item': blockType,
    });

    if(toggle && isList) {
        Transforms.wrapNodes(editor, {
            type: blockType,
            children: [],
        });
    }
};
const blockTypeActive = (editor, blockType) => {
    const [match] = Editor.nodes(editor, {
        match: n => n.type === blockType,
    });
    return !!match;
};
const EDITOR_STYLES = [
    [
        { buttonType: "bold", style: "bold", toggle: toggleInlineStyle, isActive: isMarkActive },
        { buttonType: "italic", style: "italic", toggle: toggleInlineStyle, isActive: isMarkActive },
        { buttonType: "underline", style: "underline", toggle: toggleInlineStyle, isActive: isMarkActive },
    ],
    [
        { buttonType: "ordered-list", style: LIST_TYPES[0], toggle: toggleBlockType, isActive: blockTypeActive },
        { buttonType: "unordered-list", style: LIST_TYPES[1], toggle: toggleBlockType, isActive: blockTypeActive },
    ]
];

export default props => {
    const {editorId} = props;
    return (
        <div className="rich-editor-bar">
            {EDITOR_STYLES.map((buttons, i) => (
                <React.Fragment key={`button-bar-section-${i}`}>
                    {buttons.map(s => <GenericStyleButton
                        key={`editor-button-${s.style}`}
                        isActive={s.isActive}
                        buttonType={s.buttonType}
                        onToggle={s.toggle}
                        style={s.style}
                    />)}
                    {(i !== EDITOR_STYLES.length - 1) && <span className="rich-editor-bar--separator" />}
                </React.Fragment>
            ))}
            <HeaderType />
            <TextColorButton />
            <span className="rich-editor-bar--separator" />
            <TextSize />
            <span className="rich-editor-bar--separator" />
            <Link editorId={editorId} />
            <Image editorId={editorId} />
            <Video editorId={editorId} />
            <span className="rich-editor-bar--separator" />
            <TaskReference editorId={editorId} />
        </div>
    );
}
