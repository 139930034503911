import {DOGMA_DASH_ALERT_LIST, DOGMA_DASH_MRNA_INVENTORY, DOGMA_DASH_NUCLEUS} from "./dogma-strand-1";
import {
    getDogmaDashAvailableProteins,
    getDogmaDashCurrentView,
    getDogmaDashCytoplasm,
    getDogmaDashLevelUnlockingProtein,
    getDogmaMicrotubuleIds,
    getObjectsInOrganelle,
    getObjectType,
    getOrganelleTimerDuration,
    getOrganelleTimerType
} from "../../interactions/dogmaDash/selectors";
import {CELL_VIEW, ProteinForm_ProteinInVesicle, Structural} from "../../../constants/DogmaDash";
import {mrnaInSlot, proteinFormAtOrganelle, proteinTypeAtOrganelle} from "./utility";
import {MRNA_INVENTORY_PROTEIN_TUTORIAL} from "./dogma-strand-antibody-unlock";

export const CYTOPLASM_TUTORIAL = "Cytoplasm tutorial";

export default {
    id: "4ecc03e7-d8e8-4ebc-b30e-a9fd4bfb0d9f",
    items: [
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== DOGMA_DASH_ALERT_LIST) {return false;}
                const unlockingProtein = getDogmaDashLevelUnlockingProtein(state, dogmaDashId);
                const microtubuleHasAlert = getDogmaMicrotubuleIds(state, dogmaDashId).filter(oId => !!getOrganelleTimerDuration(state, oId) && getOrganelleTimerType(state, oId) === "Alert").length > 0;
                const unlockedProteins = getDogmaDashAvailableProteins(state, dogmaDashId);
                return microtubuleHasAlert && unlockedProteins.indexOf(Structural) < 0 && unlockingProtein === Structural;
            },
            header: "New Alert Type",
            text: "The cell’s highway system and central support, the <b>microtubules</b>, have been damaged. Your pilot needs to build a protein that will fix the hole in the microtubule.",
            forceCompletion: (state, {dogmaDashId}) => dogmaDashId && getDogmaDashCurrentView(state, dogmaDashId) !== CELL_VIEW,
        },
        {
            condition: (state, {location}) => location === DOGMA_DASH_MRNA_INVENTORY,
            header: "Mission mRNA",
            text: "You do not have mRNA that codes for the protein the Microtubules need. <b>Your team needs to enter the cell nucleus to unlock the genetic code you need.</b>",
            forceCompletion: (state, {dogmaDashId}) => dogmaDashId && (getDogmaDashCurrentView(state, dogmaDashId) !== CELL_VIEW || getDogmaDashAvailableProteins(state, dogmaDashId).indexOf(Structural) >= 0),
        },
        {
            condition: (state, {location}) => location === DOGMA_DASH_NUCLEUS,
            header: "Enter the Nucleus",
            text: "Click on the nucleus to get access to the cell’s DNA",
            completionCondition: (state, {dogmaDashId}) => dogmaDashId && getDogmaDashCurrentView(state, dogmaDashId) !== CELL_VIEW,
        },
        {
            condition: (state, {location, dogmaDashId, protein}) => {
                if(location !== MRNA_INVENTORY_PROTEIN_TUTORIAL || protein !== Structural) {return false;}
                const unlockedProteins = getDogmaDashAvailableProteins(state, dogmaDashId);
                return unlockedProteins?.indexOf(Structural) >= 0;
            },
            header: "Structural Protein mRNA",
            text: "You have unlocked a new mRNA! You can drop the mRNA to the pilot whenever membrane passages get blocked.",
            forceCompletion: (state, {dogmaDashId}) => mrnaInSlot(state, dogmaDashId, Structural),
            width: 460,
        },
        {
            condition: (state, {location, dogmaDashId}) => {
                if(location !== CYTOPLASM_TUTORIAL) {return false;}
                const cyto = getDogmaDashCytoplasm(state, dogmaDashId);
                return !!cyto && proteinTypeAtOrganelle(state, cyto, Structural) && proteinFormAtOrganelle(state, cyto, ProteinForm_ProteinInVesicle);
            },
            header: "Deliver the Structural Protein",
            text: "The broken <b>microtubules</b> are out of reach of the Pilot. You need to deliver the Structural Protein.",
            callToAction: "Drag and drop the protein to the microtubule slot",
            completionCondition: (state, {dogmaDashId}) => {
                if(!dogmaDashId) { return false; }
                return getDogmaMicrotubuleIds(state, dogmaDashId).some(m => getObjectsInOrganelle(state, m).some(o => getObjectType(state, o) === Structural));
            },
            width: 460,
        },
    ],
};
