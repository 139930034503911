import {css} from "@emotion/core";
import x from '../../../../assets/black-square-x.svg';
import {
    bbPadding,
    FONT_BOLD_WEIGHT, greenButton,
    HEADER_HEIGHT,
    inlineBlockMiddle,
    upDownExpander,
    upDownExpanderCollapsed
} from "../../../../style-variables";
import {
    continueButtonBackground,
    continueButtonBorderColor,
    continueButtonColor,
    explainerWidgetPrimaryColor, explainerWidgetSecondaryColor
} from "../../../themes/selectors";

export const container = css({
    display: "flex",
    flexDirection: "column",
    width: "95%",
    height: "90%",
    position: "fixed",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    background: "#fafafa",
    boxShadow: "0 4px 24px rgba(0,0,0,0.25)",
    borderRadius: "16px 16px 0 0",
    overflow: "auto",
    zIndex: 1,
});

export const title = css({
    flex: "0 0 auto",
    width: "100%",
    fontSize: "1.75em",
    fontWeight: FONT_BOLD_WEIGHT,
    margin: "0 0 46px 0",
    borderBottom: "4px solid #fff"
}, bbPadding("24px 24px 22px 24px"));
export const themedTitle = theme => theme ? css(title, {
    backgroundColor: `${explainerWidgetSecondaryColor(theme)}20`,
    borderBottomColor: explainerWidgetPrimaryColor(theme),
}) : title;

export const closeButton = css({
    width: "17px",
    height: "17px",
    position: "absolute",
    top: "33px",
    right: "31px",
    backgroundImage: `url(${x})`,
    backgroundColor: "transparent",
    backgroundSize: "100%",
    border: "none",
    cursor: "pointer",
    outline: "none",
});

export const caseContainer = css({
    border: "1px solid #333",
    borderRadius: "12px",
    margin: "0 0 16px 0",
    backgroundColor: "#FAF5F6",
    boxShadow: "0px 4px 4px 0 rgba(102,102,102, 0.25)",
    transition: "background-color 0.3s, border-color 0.3s",
    position: "relative",
}, bbPadding("16px 16px 16px 54px"));
export const themedCaseContainer = theme => theme ? css(caseContainer, {
    borderColor: `${explainerWidgetPrimaryColor(theme)}20`,
}) : caseContainer;
export const caseContainerCollapsed = css({
    borderColor: "transparent",
    backgroundColor: "#fff",
});

export const casesContainer = css({
    flex: "1 1 auto",
    width: "100%",
    overflow: "auto",
}, bbPadding("0 30px"));

export const evidenceExpander = css({
    margin: "9px 0 0 0",
    transition: "height 0.3s, margin 0.3s",
    overflow: "hidden",
}, bbPadding("0 0 0 2px"));
export const evidenceExpanderCollapsed = css({
    height: "0!important",
    margin: "0",
});

export const expander = css({
    width: "10px",
    height: "10px",
    backgroundColor: "transparent",
    border: "none",
    borderTop: "2px solid black",
    borderRight: "2px solid black",
    borderTopRightRadius: "3px",
    position: "absolute",
    left: "23px",
    top: "22px",
    transform: "rotate(135deg)",
    transition: "transform 0.3s",
    padding: 0,
    cursor: "pointer",
    outline: "none",

    "&:before": {
        content: '""',
        display: "block",
        width: "20px",
        height: "20px",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "50%",
    }
});
export const expanderCollapsed = css({
    transform: "rotate(45deg)",
});

export const caseTitle = css(inlineBlockMiddle, {
    width: "100%",
    fontSize: "1.125em",
    fontWeight: 400,
    padding: 0,
    margin: 0,
    cursor: "pointer",
});
export const caseTitleExpanded = css({
    fontWeight: FONT_BOLD_WEIGHT,
});

export const buttonContainer = css({
    flex: "0 0 auto",
    width: "100%",
}, bbPadding("20px 30px"));

export const doneButton = css(greenButton, {
    float: "right",
    width: "200px",
    height: "56px",
    borderRadius: "28px",
    outline: "none",
});
export const themedDoneButton = theme => css(doneButton, {
    borderColor: continueButtonBorderColor(theme),
    backgroundColor: continueButtonBackground(theme),
    color: continueButtonColor(theme),
    boxShadow: `0 0 0 0 ${continueButtonBorderColor(theme)}`,

    "&:hover": {
        boxShadow: `-4px 6px 0 1px ${continueButtonBorderColor(theme)}`,
    },
    "& svg": {
        fill: continueButtonColor(theme),
    }
});
