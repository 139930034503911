import {css} from "@emotion/core";

export const container = css({
    width: "100%",
    height: "100%",
    position: "relative",
});

export const motorProtein = css({
    width: "15px",
    height: "15px",
    // backgroundImage: `url(${icon})`,
    // backgroundSize: "100% 100%",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    border: "2px solid #fff",
    borderRadius: '50%',
});
