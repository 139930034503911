import {call, takeEvery} from 'redux-saga/effects';
import {CREATE_CURRICULUM_TASK} from "../../../store/curriculumTask/actions";
import {sendGraphQLSaga} from "../../DataService";

const addTaskToCurriculumQuery = `mutation AddTaskToCurriculum($input: AddTaskToCurriculumInput!) {
    addTaskToCurriculum(input: $input) {
        curriculum {
            id
        }
    }
}`;

function* execute(action) {
    const variables = {
        input: {
            curriculum: action.curriculumId,
            task: action.taskId,
            type: action.taskType,
        },
    };
    yield call(sendGraphQLSaga, addTaskToCurriculumQuery, variables);
}

export default [takeEvery(CREATE_CURRICULUM_TASK, execute)];
