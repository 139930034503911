export const OPEN_ADD_LEARNER_TO_COHORT_MODAL = "Open add learner to cohort modal";
export const openAddLearnerToCohortModal = () => ({
    type: OPEN_ADD_LEARNER_TO_COHORT_MODAL,
});

export const CLOSE_ADD_LEARNER_MODAL = "Close add learner modal";
export const closeAddLearnerModal = () => ({
    type: CLOSE_ADD_LEARNER_MODAL,
});

export const ADD_EMAIL_TO_LEARNER_LIST = "Add email to learner list";
// TODO: validate email format.
export const addEmailToLearnerList = email => ({
    type: ADD_EMAIL_TO_LEARNER_LIST,
    emails: email.split(/[,\s]/).filter(e => e.length > 0).map(e => e.trim()),
});

export const SET_MODAL_LOADING = "Set modal loading";
export const setModalLoading = loading => ({
    type: SET_MODAL_LOADING,
    loading,
});
